import { faArrowLeft, faArrowRight, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import { ArrayHelpers, ErrorMessage, Field, FieldArray, FormikProps } from 'formik';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import ReactQuill from 'react-quill';
import { Prompt } from 'react-router';
import * as Yup from 'yup';
import { getLicensedClientsForVendor, getMarketplaceCategories } from '../../../api';
import { AffinityClient, Category, MarketplaceProduct, MarketplaceSettings } from '../../../shared';
import { IOptionGroup } from '../Products/OptionGroupInput';

interface IProps {
  product: MarketplaceProduct;
  settings: MarketplaceSettings;
  formikProps: FormikProps<IGalleryFormValues>;
  orgChange?: (org: AffinityClient) => void;
}

interface IState {
  licensedCategories: any[];
  otherCategories: any[];
  loadingCategories: boolean;
  organizations: AffinityClient[];
  primaryImagePreview: string | null;
  secondaryImagePreviews: {preview: string}[];
}

export const GalleryFormValidation = Yup.object({
  name: Yup.string().required('Product Name is required.'),
  organization: Yup.mixed().test(
    'is-valid-org',
    'Organization is required.',
    (org: any) =>  org && org.id,
  ).required('Organization is required.'),
  category: Yup.mixed().test(
    'is-valid-category',
    'Category is required.',
    (category: any) => category && category.id ,
  ).required('Category is required.'),
  // price: Yup.number().required('Regular price is required.'),
});

export interface IGalleryFormValues {
  organization: AffinityClient;
  category: Category | undefined;
  name: string;
  description: string;
  vendorProductId: string;
  externalURL: string;
  minimumQuantity: number;
  defaultMinimumQuantity: boolean;
  price: string;
  salePrice: string;
  isOnSale: boolean;
  shippingFirst: string;
  shippingAdditional: string;
  fulfillmentDuration: number;
  fulfillmentTerm: number;
  defaultShippingPrice: boolean;
  shippingPolicy: string;
  returnsPolicy: string;
  defaultShippingPolicy: boolean;
  defaultReturnPolicy: boolean;
  defaultFulfillment: boolean;
  options: IOptionGroup[];
  primaryImage: any;
  secondaryImages: any[];
}

export class GalleryForm extends React.Component <IProps, IState> {
  private _getCategories: CancelTokenSource;

  private secondaryImagesDropzoneRef: any;
  private primaryImageDropzoneRef: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      licensedCategories: [],
      otherCategories: [],
      loadingCategories: false,
      organizations: [],
      primaryImagePreview: null,
      secondaryImagePreviews: [],
    };

    this.getCategories = this.getCategories.bind(this);
    this.getOrganizations = this.getOrganizations.bind(this);
    this.organizationOnChange = this.organizationOnChange.bind(this);
  }

  componentDidMount() {
    this.getOrganizations();
    if (this.props.product.organization.id && this.props.product.vendor.id) {
      this.getCategories(this.props.product.organization.id, this.props.product.vendor.id);
    }
  }

  componentWillUnmount(): void {
    if (this.state.primaryImagePreview) {
      URL.revokeObjectURL(this.state.primaryImagePreview);
    }
    this.state.secondaryImagePreviews.forEach(((entry) => {
      URL.revokeObjectURL(entry.preview);
    }));
  }

  getCategories(organization: number, vendor: number) {
    this.setState({ loadingCategories: true });
    if (this._getCategories) {
      this._getCategories.cancel('Cancelled getCategories() due to new XHR');
    }
    this._getCategories = Axios.CancelToken.source();
    getMarketplaceCategories(organization, vendor, this._getCategories)
      .then((response) => {
        const licensedCategories = response.data.data.licensed;
        const allCategories = response.data.data.all;
        const licensedCategoryIds = licensedCategories.map((category: any) => category.id);
        licensedCategories.sort((a: any, b: any) => a.name > b.name);

        const otherCategories = allCategories.filter((category: any) => licensedCategoryIds.indexOf(category.id) === -1);

        this.setState({
          licensedCategories,
          otherCategories,
          loadingCategories: false,
        });
      });

  }

  getOrganizations() {
    getLicensedClientsForVendor(this.props.product.vendor.id)
      .then((licensors) => {
        const organizations = licensors.map((org: any) => new AffinityClient(org));
        this.setState({ organizations });
      });
  }

  organizationOnChange(event: any, formikProps: FormikProps<IGalleryFormValues>) {
    this.getCategories(event.target.value, this.props.product.vendor.id);
    const organization = this.state.organizations.find(org => Number(org.id) === Number(event.target.value));
    if (this.props.orgChange && organization) {
      this.props.orgChange(organization);
    }
    formikProps.setFieldValue('organization', organization);
    formikProps.setFieldValue('category', '');
  }

  categoryOnChange(event: any, formikProps: FormikProps<IGalleryFormValues>) {
    const allCat = [...this.state.licensedCategories, ...this.state.otherCategories];
    const cat = allCat.find(category => Number(category.id) === Number(event.target.value));
    formikProps.setFieldValue('category', cat);
  }

  secondaryImages(images: any[], arrayHelpers: ArrayHelpers) {
    return images.map((image) => {
      if (image.urls) {
        return { image: image.urls.sm ? image.urls.sm : image.urls.or, isNew: false };
      }
      return { image: URL.createObjectURL(image), isNew: true };
    })
    .map((image, index) => (
      <div
        key={index}
        style={{ margin: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
        <a href={image.isNew ? '' : image.image} target="_blank">
          <img
            className="img-responsive"
            key={image.image}
            src={image.image}
            style={{
              width: 140,
              borderRadius: 8,
              border: '1px solid #eee',
            }}
          />
        </a>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }} >
          <button
            disabled={index === 0}
            onClick={(event) => {
              event.preventDefault();
              arrayHelpers.move(index, index - 1);
            }}
            type="button"
            style={{ marginRight: 5 }}
            className="btn btn-xs btn-default"
          >
            <FontAwesomeIcon icon={faArrowLeft} />

          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              arrayHelpers.remove(index);
            }}
            type="button"
            className="btn btn-xs btn-default"
          >
            <FontAwesomeIcon className="text-danger" icon={faTimes} />

          </button>
          <button
            disabled={index === images.length - 1 }
            onClick={(event) =>  {
              event.preventDefault();
              arrayHelpers.move(index, index + 1);
            }}
            type="button"
            style={{ marginLeft: 5 }}
            className="btn btn-xs btn-default"
          >
            <FontAwesomeIcon icon={faArrowRight} />

          </button>

        </div>
      </div>
    ),
    );

  }

  render() {
    const product = this.props.product;
    const modules = {
      syntax: false,
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      ],
    };

    const orgOptions = this.state.organizations.map(org => <option key={org.id} value={org.id}>{org.name}</option>);
    const licensedCategories = this.state.licensedCategories.map(category =>
      <option key={category.id} value={category.id}>{category.name}</option>,
    );
    const formikProps = this.props.formikProps;
    let image: string;
    if (this.state.primaryImagePreview) {
      image = this.state.primaryImagePreview;
    } else {
      image = product.getImage('or');
    }
    return (
      <div>
        <Prompt when={formikProps.dirty && !formikProps.isSubmitting} message="Are you sure you want to leave" />

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Organization</label>
              <select
                onBlur={formikProps.handleBlur}
                onChange={event => this.organizationOnChange(event, formikProps)}
                value={formikProps.values.organization.id}
                defaultValue={''}
                name="organization"
                className="form-control"
              >
                <option value={''}>Select Organization</option>
                {orgOptions}
              </select>
              <p className="text-danger">
                <ErrorMessage name="organization" />
              </p>

            </div>

          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Product Category</label>
              <select
                onChange={event => this.categoryOnChange(event, formikProps)}
                value={formikProps.values.category ? formikProps.values.category.id : undefined}
                defaultValue={''}
                disabled={this.state.loadingCategories}
                name="category"
                className="form-control"
              >
                <option value={''}>{this.state.loadingCategories ? 'Loading...' : 'Select Category'}</option>
                  {licensedCategories}
              </select>
              <p className="text-danger">
                <ErrorMessage name="category" />
              </p>
            </div>

          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Product Name</label>
              <Field name="name" type="text" className="form-control" />
              <p className="text-danger">
                <ErrorMessage name="name" />
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Product ID</label>
              <Field name="vendorProductId" type="text" className="form-control" />
            </div>

          </div>

        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <label>Regular Price</label>
              <Field name="price" type="text" className="form-control" />
              <div className="text-danger">
                <ErrorMessage name="price" />
              </div>

            </div>

          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Sale Price</label>
              <Field name="salePrice" type="text" className="form-control" />
              <div className="text-danger">
                <ErrorMessage name="salePrice" />
              </div>
            </div>

          </div>
          <div className="col-md-4">
            <label>Current Pricing</label>
            <div className="checkbox">
              <label>
                <Field type="checkbox" name="isOnSale" checked={formikProps.values.isOnSale} />
                Product is on sale
                  </label>
            </div>

          </div>

        </div>
        <hr />
        <div className="row">

          <div className="col-md-12">
            <Dropzone
              onDrop={(files) => {
                formikProps.setFieldValue('primaryImage', files[0]);
                this.setState({ primaryImagePreview: URL.createObjectURL(files[0]) });
              }}
              ref ={(ref: any) => this.primaryImageDropzoneRef = ref}
              noClick={true}
            >
              {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <h5 className="text-center">Primary Image</h5>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                  <a href={image} target="_blank"><img style={{ maxHeight: 300 }} src={image} /></a>
                  <button
                    onClick={() => this.primaryImageDropzoneRef.open()}
                    style={{ marginTop: 15 }}
                    type="button"
                    className="btn btn-primary"
                  >
                    Upload Primary Image
                  </button>
                </div>
              </div>
              )}
            </Dropzone>
            <div>

              <p className="text-danger">
                <ErrorMessage name="primaryImage" />
              </p>
            </div>
          </div>

        </div>

        <hr />
        <div className="row">
          <div className="col-md-12">

            <FieldArray name="secondaryImages" render={arrayHelpers => (
              <Dropzone
                ref={(ref: any) => this.secondaryImagesDropzoneRef = ref}
                onDrop={(files) => {
                  const values = formikProps.values.secondaryImages.concat(files);
                  formikProps.setFieldValue('secondaryImages', values);
                  const newPreviews = files.map(file => ({ preview: URL.createObjectURL(file) }));
                  const previews = this.state.secondaryImagePreviews.concat(newPreviews);
                  this.setState({ secondaryImagePreviews: previews });
                }}
                noClick={true}
              >
              {({ getRootProps, getInputProps }) => (
                <div
                    {...getRootProps()}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      padding: 20,
                    }}
                >
                  <input {...getInputProps()} />
                  <h5>Secondary Images</h5>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                    {this.secondaryImages(formikProps.values.secondaryImages, arrayHelpers)}
                  </div>
                  <button
                    type="button"
                    onClick={() => this.secondaryImagesDropzoneRef.open()}
                    className="btn btn-primary"
                  >
                    Upload Secondary Image(s)
                  </button>

                </div>
              )}
              </Dropzone>
            )} />
          </div>

        </div>

        <hr />

        <div className="form-group">
          <label>Description</label>
          <ReactQuill
            style={{ background: 'white' }}
            value={formikProps.values.description}
            onChange={body => formikProps.setFieldValue('description', body)}
            preserveWhitespace={true}
            placeholder="Enter Message"
            modules={modules}
            formats={['bold', 'italic', 'underline', 'list', 'align']}
          />
          <p className="text-danger">
            <ErrorMessage name="description" />
          </p>
        </div>
      </div>

    );
  }

}
