import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { getLicensors } from '../../../../api';
import { AffinityClient, ProductCategory, Vendor } from '../../../../shared';
import { LoadingSpinner } from '../../../shared';

interface SitePageProductFields {
  singlePage: number;
  sidebar: number;
  keyword: string;
  clients: number[];
  vendors: number[];
  tags: number[];
  categories: number[];
  priceMax: number | string;
  priceMin: number | string;
  insigniaIds?: number[];
  insigniaIdString?: string;
  limit: number | string;
}
interface MarketplaceTag {
  id: number;
  name: string;
}

interface IProps {
  onSubmit: (values: string) => void;
  initialValues?: SitePageProductFields;
}

export const SitePageProductsForm = (props: IProps) => {
  const [clients, setClients] = React.useState<AffinityClient[]>([]);
  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [tags, setTags] = React.useState<MarketplaceTag[]>([]);
  const [categories, setCategories] = React.useState<ProductCategory[]>([]);
  const [loading, setLoading] = React.useState(true);
  const params = useParams();

  React.useEffect(() => {
    setup();
  },              []);

  const setup = async () => {
    const siteID = params['siteId'];
    const getClients = getLicensors();
    const getVendors = Axios.get(`/api/marketplace/vendors?per_page=999&site_id=${siteID}&order_by=alpha`);
    const getTags = Axios.get(`/api/marketplace/tags?site_id=${siteID}`);
    const getCategories = Axios.get('/api/product_categories');

    const all = await Axios.all([getClients, getVendors, getTags, getCategories]);
    setClients(all[0].data.data.map((c: any) => new AffinityClient(c)));
    setVendors(all[1].data.data.map((v: any) => Vendor.fromApi(v)));
    setTags(all[2].data.data);
    setCategories(all[3].data.data.map((p: any) => ProductCategory.fromApi(p)));
    setLoading(false);
  };

  const selectedCategories = (ids: number[]) => {
    return categories.filter(c => ids.includes(Number(c.id)));
  };

  const selectedClients = (ids: number[]) => {
    return clients.filter(c => ids.includes(Number(c.id)));
  };

  const selectedTags = (ids: number[]) => {
    return tags.filter(c => ids.includes(c.id));
  };

  const selectedVendors = (ids: number[]) => {
    return vendors.filter(c => ids.includes(c.id));
  };

  const initialValues: SitePageProductFields = props.initialValues ? props.initialValues : {
    singlePage: 0,
    sidebar: 0,
    keyword: '',
    clients: [],
    vendors: [],
    tags: [],
    categories: [],
    insigniaIds: [],
    priceMax: '',
    priceMin: '',
    limit: '',
  };

  const handleSubmit = (values: SitePageProductFields) => {
    const s = {
      is_single_page: Number(values.singlePage) ? true : false,
      has_sidebar: Number(values.sidebar) ? true : false,
      keyword: values.keyword ? values.keyword : null,
      client_ids: values.clients.length ? values.clients : null,
      vendor_ids: values.vendors.length ? values.vendors : null,
      tag_ids: values.tags.length ? values.tags : null,
      product_category_ids: values.categories.length ? values.categories : null,
      insignia_ids: values.insigniaIds && values.insigniaIds.length ? values.insigniaIds : null,
      price_max: values.priceMax ? Number(values.priceMax) : null,
      price_min: values.priceMin ? Number(values.priceMin) : null,
      limit: values.limit ? Number(values.limit) : null,
    };

    const cleaned = omitBy(s, isNil);

    props.onSubmit(JSON.stringify(cleaned));
  };

  if (loading) {
    return (<LoadingSpinner />);
  }

  const validationSchema = Yup.object({
    singlePage: Yup.number().when(
      'sidebar',
      {
        is: s => s === 1,
        then: Yup.number().oneOf([1], 'Must be single page when sidebar is active.'),
        otherwise: Yup.number(),
      },
    ),
    sidebar: Yup.number(),
    priceMin: Yup.number().typeError('Price must be a number.').notRequired(),
    priceMax: Yup.number().typeError('Price must be a number.').notRequired(),
    insigniaIds: Yup.string().notRequired(),
    limit: Yup.number().typeError('Page size must be a number').notRequired(),
  });
  return (
    <Formik initialValues={initialValues}
            onSubmit={(values, formProps) => {
              values.insigniaIds = values.insigniaIdString && values.insigniaIdString.length ? values.insigniaIdString.split(',').map(id => Number(id)) : [];
              handleSubmit(values);
            }}
            validationSchema={validationSchema}>
      {formProps => (
        <Form>
          <div className="form-group">
            <label>Single Page</label>
            <Field name="singlePage" component="select" className="form-control">
              <option value={0}>Not Single Page</option>
              <option value={1}>Single Page</option>
            </Field>
            <p className="text-danger">
              <ErrorMessage name="singlePage" />
            </p>
          </div>

          {Number(formProps.values.singlePage) === 1 ? <div className="form-group">
            <label>Has Sidebar</label>
            <Field name="sidebar" component="select" className="form-control">
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </Field>
          </div> : null}
          <div className="form-group">
            <label>Keyword</label>
            <Field placeholder="Keyword" name="keyword" className="form-control" />
          </div>
            <div className="form-group">
              <label>Insignia IDs</label>
              <Field placeholder="Insignia IDs" name="insigniaIdString" className="form-control" />
              <p className="text-danger">
                <ErrorMessage name="insigniaIds" />
              </p>
            </div>

          <div className="form-group">
            <label className="small">Organizations</label>
            <Typeahead
              id="insignia-typeahead"
              placeholder="Organizations"
              selected={selectedClients(formProps.values.clients)}
              onChange={c => formProps.setFieldValue('clients', c.map(n => Number(n.id)))}
              options={clients}
              multiple={true}
              labelKey={option => option.shortName}
            />
          </div>
          <div className="form-group">
            <label className="small">Vendors</label>
            <Typeahead
              id="insignia-typeahead"
              placeholder="Vendors"
              selected={selectedVendors(formProps.values.vendors)}
              onChange={c => formProps.setFieldValue('vendors', c.map(n => n.id))}
              options={vendors}
              multiple={true}
              labelKey={option => option.shortName}
            />
          </div>
          <div className="form-group">
            <label className="small">Product Categories</label>
            <Typeahead
              id="insignia-typeahead"
              placeholder="Product Categories"
              selected={selectedCategories(formProps.values.categories)}
              onChange={c => formProps.setFieldValue('categories', c.map(n => Number(n.id)))}
              options={categories}
              multiple={true}
              labelKey={option => option.name}
            />
          </div>
          <div className="form-group">
            <label className="small">Tags</label>
            <Typeahead
              id="insignia-typeahead"
              placeholder="Tags"
              selected={selectedTags(formProps.values.tags)}
              onChange={c => formProps.setFieldValue('tags', c.map(n => n.id))}
              options={tags}
              multiple={true}
              labelKey={option => option.name}
            />
          </div>
          <div className="form-group">
            <label>Price Minimum</label>
            <Field placeholder="0" name="priceMin" className="form-control" />
            <p className="text-danger">
              <ErrorMessage name="priceMin" />
            </p>
          </div>
          <div className="form-group">
            <label>Price Maximum</label>
            <Field placeholder="100" name="priceMax" className="form-control" />
            <p className="text-danger">
              <ErrorMessage name="priceMax" />
            </p>
          </div>
          <div className="form-group">
            <label>Page Size</label>
            <Field placeholder="36" name="limit" className="form-control" />
            <p className="text-danger">
              <ErrorMessage name="limit" />
            </p>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="btn btn-primary">Save Page Item</button>
          </div>
        </Form>
      )}

    </Formik>
  );
};
