import axios, { AxiosPromise, CancelTokenSource } from 'axios';
import { logIfCancelled } from '../../api';

export const fetchDesignResponses = (
  accountId: number | null,
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const url = '/api/design-responses';
  const requestParams = accountId ? { licensor_id: accountId } : null;
  return axios.get(url, { cancelToken: cancelTokenSource.token, params: requestParams })
    .catch((error: any) => {
      logIfCancelled(error, 'setStandardSchedule');
      return error;
    });
};

export const updateDesignResponse = (
  title: string,
  message: string,
  id: string,
): AxiosPromise => {

  const data = { title, body: message };
  return axios.put(`/api/design-responses/${id}`, data)
    .catch((error: any) => {
      logIfCancelled(error, 'updateDesignResponse');
      return error;
    });

};

export const createDesignResponse = (
  title: string,
  message: string,
  accountId: number,
): AxiosPromise => {

  const data = { title, body: message, account_id: accountId };
  return axios.post('/api/design-responses', data)
    .catch((error: any) => {
      logIfCancelled(error, 'updateDesignResponse');
      return error;
    });

};

export const deleteDesignResponse = (
  id: string,
): AxiosPromise => {
  return axios.delete(`/api/design-responses/${id}`)
    .catch((error: any) => {
      logIfCancelled(error, 'deleteDesignResponse');
      return error;
    });

};
