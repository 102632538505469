import { faDownload, faEye } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { deleteRoyaltyReport, getReportingAdmins, setRoyaltyReportAdmin, setRoyaltyReportPhase } from '../../../api';
import { RoyaltyReport, setReportType } from '../../../shared';
import User from '../../../shared/User';
import { GatewayModal, ModalType } from '../../shared/modals';
import { getVendorUrl } from './utils';

interface IUser {
  id: number;
  first_name: string;
  last_name: string;
}

interface IProps {
  user: User|null;
  report: RoyaltyReport;
  setReport: setReportType;
  resetReport?: () => void;
}

interface IState {
  loadingAdmins: boolean;
  submittingAdmin: boolean;
  changingPhase: boolean;
  deletingReport: boolean;
  deletedReport: boolean;
  assignableAdmins: User[];
  adminReviewModalShown: boolean;
  vendorReviewModalShown: boolean;
  vendorMessage: string;
  approvedModalShown: boolean;
  deleteModalShown: boolean;
  resetModalShown: boolean;
  resettingReport: boolean;
}

const changePhaseButtons = (currentPhase: number, onChangePhase: (phase: string) => void) => {

  const vendorReviewButton = (
    <button className="btn btn-default btn-block" onClick={() => (onChangePhase('vendor_review'))} >
      Send to Vendor Review
    </button>
  );
  const adminReviewButton = (
    <button className="btn btn-default btn-block" onClick={() => (onChangePhase('admin_review'))}>
      Send to Admin Review
    </button>
  );
  const reviewedButton = (
    <button className="btn btn-primary btn-block" onClick={() => (onChangePhase('approved'))}>
      Set to Reviewed
    </button>
  );
  if (currentPhase === 1) {
    return <div>{adminReviewButton}</div>;
  }
  if (currentPhase === 3) {
    return (
      <div>
        <div className="form-group">
          {vendorReviewButton}
        </div>
        <div>
          {reviewedButton}
        </div>
      </div>

    );
  }
  if (currentPhase === 14) {
    return <div>{adminReviewButton}</div>;
  }

  console.error('Unknown phase', currentPhase);
  return '';

};

const deleteButtonSection = (isDeleting: boolean, isDeleted: boolean, vendorId: number, onDelete: (() => void)) => {
  if (isDeleted) {
    return <Redirect push to={`/vendors/${vendorId}/royalties`} />;
  }

  if (isDeleting) {
    return <div>Deleting...</div>;
  }
  return <button className="btn btn-danger btn-block" onClick={onDelete}>Delete Report</button>;

};

class ReportAdminBox extends React.Component<IProps, IState> {

  protected _loadAdminsSource: CancelTokenSource;
  protected _submitAdminsSource: CancelTokenSource;
  protected _changePhaseSource: CancelTokenSource;
  protected _deleteReportSource: CancelTokenSource;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loadingAdmins: false,
      submittingAdmin: false,
      changingPhase: false,
      deletingReport: false,
      deletedReport: false,
      assignableAdmins: [],
      adminReviewModalShown: false,
      vendorReviewModalShown: false,
      vendorMessage: '',
      approvedModalShown: false,
      deleteModalShown: false,
      resetModalShown: false,
      resettingReport: false,
    };
    this.getAdmins = this.getAdmins.bind(this);
    this.selectAdmin = this.selectAdmin.bind(this);
    this.changePhase = this.changePhase.bind(this);
    this.deleteReport = this.deleteReport.bind(this);
    this.showPhaseModal = this.showPhaseModal.bind(this);
    this.closePhaseModal = this.closePhaseModal.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.showResetModal = this.showResetModal.bind(this);
    this.closeResetModal = this.closeResetModal.bind(this);
    this.resetReport = this.resetReport.bind(this);
  }

  componentDidMount() {
    this.getAdmins();
  }

  public componentWillUnmount() {
    if (this._loadAdminsSource) {
      this._loadAdminsSource.cancel('Cancelled ReportAdminBox:getAdmins XHR due to unmount.');
    }
  }

  selectAdmin(event: React.ChangeEvent<HTMLSelectElement>) {
    const adminId = event.target.value;
    if (this._submitAdminsSource) {
      this._submitAdminsSource.cancel('Cancelled ReportAdminBox:selectAdmin XHR due to new request.');
    }
    this._submitAdminsSource = axios.CancelToken.source();
    this.setState({ submittingAdmin: true });
    setRoyaltyReportAdmin(this.props.report.id, adminId, this._submitAdminsSource)
      .then((response: any) => {
        this.props.setReport(new RoyaltyReport(response.data.data));
        this.setState({ submittingAdmin: false });
      });
  }

  getAdmins() {
    this.setState({ loadingAdmins: true });
    if (this._loadAdminsSource) {
      this._loadAdminsSource.cancel('Cancelled ReportAdminBox:getAdmins XHR due to new request.');
    }
    this._loadAdminsSource = axios.CancelToken.source();
    getReportingAdmins(this._loadAdminsSource).then((response) => {
      this.setState({
        assignableAdmins: response.assignable,
        loadingAdmins: false,
      });
      this.setState({ loadingAdmins: false });
    });
  }

  updateReports() {
    const createThreadEvent = new CustomEvent('update-report', {
      bubbles: true,
    });
    document.body.dispatchEvent(createThreadEvent);
  }

  showPhaseModal(phase: string) {
    switch (phase) {
      case 'approved':
        this.setState({ approvedModalShown: true });
        break;

      case 'vendor_review':
        this.setState({ vendorReviewModalShown: true });
        break;

      case 'admin_review':
        this.setState({ adminReviewModalShown: true });
        break;

      default:
        break;
    }

  }

  closePhaseModal(phase: string) {
    switch (phase) {
      case 'approved':
        this.setState({ approvedModalShown: false });
        break;

      case 'vendor_review':
        this.setState({ vendorReviewModalShown: false });
        break;

      case 'admin_review':
        this.setState({ adminReviewModalShown: false });
        break;

      default:
        break;
    }

  }

  changePhase(phase: string, message?: string) {
    if (this._changePhaseSource) {
      this._changePhaseSource.cancel('Cancelled ReportAdminBox:changePhase XHR due to new request.');
    }
    this._changePhaseSource = axios.CancelToken.source();
    this.setState({ changingPhase: true });
    setRoyaltyReportPhase(this.props.report.id, phase, this._changePhaseSource, message)
      .then((response: any) => {
        this.props.setReport(new RoyaltyReport(response.data.data));
        this.updateReports();
        this.setState({ changingPhase: false });
      });
    this.closePhaseModal(phase);
  }

  showDeleteModal() {
    this.setState({ deleteModalShown: true });
  }

  closeDeleteModal() {
    this.setState({ deleteModalShown: false });
  }
  showResetModal() {
    this.setState({ resetModalShown: true });
  }
  closeResetModal() {
    this.setState({ resetModalShown: false });
  }

  deleteReport() {
    if (this._deleteReportSource) {
      this._deleteReportSource.cancel('Cancelled ReportAdminBox:deleteReport XHR due to new request.');
    }
    this._deleteReportSource = axios.CancelToken.source();
    this.setState({ deletingReport: true });
    deleteRoyaltyReport(this.props.report.id, this._deleteReportSource)
      .then((response: any) => {
        this.setState({ deletingReport: false, deletedReport: true });
      });
    this.closeDeleteModal();
  }

  async resetReport() {
    this.setState({ resettingReport: true });
    await Axios.delete(`/api/royalty-reports/${this.props.report.id}/line-items?type=sales-data`);
    this.updateReports();
    if (this.props.resetReport) {
      this.props.resetReport();
    }
    this.setState({ resettingReport: false, resetModalShown: false });
  }

  render() {

    const selectedId = this.props.user ? this.props.user.id : undefined;

    const adminOptions = this.state.assignableAdmins.map(user => (
        <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>
    ));

    const url = `/royalties/${this.props.report.id}/sales-data`;
    const vendorId = this.props.report.vendor.id;
    const salesDataLink = getVendorUrl(url, vendorId, 'admin');
    const salesDataFileLink = `/royalties/${this.props.report.id}/sales-data/download`;
    const auditFileLink = `/api/royalty-reports/${this.props.report.id}/xlsx-downloads`;
    const aggregateLink = getVendorUrl(`/royalties/${this.props.report.id}/aggregate`, this.props.report.vendor.id, 'admin');

    const disableSelect = this.state.submittingAdmin || this.state.loadingAdmins;

    const changePhaseMarkup = this.state.changingPhase ? null :
    changePhaseButtons(this.props.report.phase.id, this.showPhaseModal);

    const deleteButton = deleteButtonSection(
      this.state.deletingReport,
      this.state.deletedReport,
      vendorId,
      this.showDeleteModal,
    );

    const manageLinks = this.props.report.phase.id !== 14 ? (
      <div>
        <div className="form-group">
          <Link className="btn btn-primary btn-block" to={salesDataLink}>Manage Sales Data Reports</Link>
        </div>
        <div className="form-group">
          <Link className="btn btn-primary btn-block" to={aggregateLink}>Manage Aggregate Reports</Link>
        </div>
      </div>
    ) : null;

    const salesDataFileLinkSection = this.props.report.salesDataFile !== null ? (
      <div className="form-group">
        <Link className="btn btn-default btn-block" to={salesDataFileLink} target="_blank">
          <FontAwesomeIcon icon={faDownload} /> Sales Data File
        </Link>
        {this.props.report.firstSalesDataFile && this.props.report.salesDataFile.id !== this.props.report.firstSalesDataFile.id ?
          <div style={{ marginTop: 10 }}>
            <a className="btn btn-default btn-block" href={this.props.report.firstSalesDataFile.url} target="_blank">
              <FontAwesomeIcon icon={faDownload} /> First Sales Data File
            </a>
          </div>
          : null}

        <p className="help-block text-center">{this.props.report.firstSalesFileLabel}</p>
        <Link className="btn btn-default btn-block" to={auditFileLink} target="_blank">
          <FontAwesomeIcon icon={faEye} /> Audit Spreadsheet
        </Link>
      </div>
    ) : null;

    const adminSelect = this.props.report.phase.id !== 14 ? (
      <div className="form-group">
        <select className="form-control input-sm" value={selectedId} disabled={disableSelect} onChange={this.selectAdmin}>
          <option key="empty" value="">None Assigned</option>
          {adminOptions}
        </select>
      </div>
    ) : null;

    const vendorReviewSubmitButton = (this.state.vendorMessage.length > 0) ? (
      <button
        className="btn btn-primary"
        onClick={() => this.changePhase('vendor_review', this.state.vendorMessage)}
      >
        Send to vendor
      </button>
    ) : (
      <button
          className="btn btn-default"
          onClick={() => this.changePhase('vendor_review', this.state.vendorMessage)}
      >
          Send to vendor without message
      </button>

    );

    return (
      <div className="panel panel-portal">
        <div className="panel-heading" style={{ fontWeight: 'bold' }}>Admin Tools</div>
        <div className="panel-body">
          {adminSelect}
          {manageLinks}
          <div className="form-group">
            {changePhaseMarkup}
          </div>
          <div className="form-group">
            <a
              className="btn btn-default btn-block"
              href={`/api/royalty-reports/${this.props.report.id}/downloads`}
              target="_blank"
            >
              <FontAwesomeIcon icon={faDownload} /> Download All
            </a>
          </div>

          {salesDataFileLinkSection}
          <div className="form-group">
            <button onClick={this.showResetModal} className="btn btn-default btn-block">Reset Report</button>
            {deleteButton}
          </div>
        </div>

        <GatewayModal
          type={ModalType.Primary}
          title={'Send to Admin Review'}
          shown={this.state.adminReviewModalShown}
          onClose={() => this.closePhaseModal('admin_review')}
        >
          <Modal.Body>
            <p className="text-center">Please confirm to send report to Admin Review</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.closePhaseModal('admin_review')}
              className="btn btn-default pull-left"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => this.changePhase('admin_review')}
            >
              Mark as Admin Review
            </button>
          </Modal.Footer>
        </GatewayModal>

        <GatewayModal
          type={ModalType.Danger}
          title={'Delete Report'}
          shown={this.state.deleteModalShown}
          onClose={this.closeDeleteModal}
        >
          <Modal.Body>
            <h5 className="text-center"><strong>Are you sure you want to delete this royalty report?</strong></h5>
            <p className="strong text-center text-danger">
              <strong>This action will <u>not</u> associated invoices and payments.</strong>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.closeDeleteModal} className="btn btn-default pull-left">Cancel</button>
            <button className="btn btn-danger" onClick={this.deleteReport}>Yes, Delete Report</button>
          </Modal.Footer>
        </GatewayModal>

        <GatewayModal
          type={ModalType.Primary}
          title={'Send to Vendor'}
          shown={this.state.vendorReviewModalShown}
          onClose={() => this.closePhaseModal('vendor_review')}
        >
          <Modal.Body >
            <p>Please add a message that will appear in the email to the vendor.</p>
            <div className="form-group">
              <textarea
                onChange={event => this.setState({ vendorMessage: event.target.value })}
                value={this.state.vendorMessage}
                className="form-control"
              >
              </textarea>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.closePhaseModal('vendor_review')}
              className="btn btn-default pull-left"
            >
              Cancel
            </button>
            {vendorReviewSubmitButton}
          </Modal.Footer>
        </GatewayModal>

        <GatewayModal
          type={ModalType.Primary}
          title={'Mark as Reviewed?'}
          shown={this.state.approvedModalShown}
          onClose={() => this.closePhaseModal('approved')}
        >
          <Modal.Body>
            <p className="text-center">Please confirm you reviewed the report.</p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => this.closePhaseModal('approved')} className="btn btn-default pull-left">Cancel</button>
            <button className="btn btn-primary" onClick={() => this.changePhase('approved')}>Yes, Mark as Reviewed</button>
          </Modal.Footer>
        </GatewayModal>
        <GatewayModal
          type={ModalType.Danger}
          onClose={this.closeResetModal}
          shown={this.state.resetModalShown}
          title="Reset Report"
        >
          <Modal.Body>
            <p className="text-center">
              Are you sure you want to reset the report?
              <br />
              This will effectively reset the report back to it's initial state, while maintaining the license and royalty schedule data included in it's initial creation.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.closeResetModal}
              className="btn btn-default pull-left"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary pull-right"
              onClick={this.resetReport}
            >
              {this.state.resettingReport ? 'Resetting...' : 'Reset Report'}
            </button>
          </Modal.Footer>
        </GatewayModal>

      </div>
    );
  }
}

export default ReportAdminBox;
