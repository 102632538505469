import Axios from 'axios';
import { Form, Formik } from 'formik';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useHistory } from 'react-router';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { EnforcementTemplateForm } from './EnforcementTemplateForm';
2;
export const EnforcementTemplateNewPage = () => {
  const history = useHistory();

  const submit = async (values: any) => {
    const data = {
      name: values.name,
      description: values.description,
      content: values.content,
      platform_id: values.type,
      is_dmca: values.isDmca,
      subject: values.subject,
    };
    await Axios.post('/api/enforcement-templates', data);
    history.push('/enforce/templates');

  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Templates')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <Formik
            initialValues={{ name: '', description: '', isDmca: false, type: '', content: '', subject: '' }}
            onSubmit={submit}
          >
            {formProps => (
              <Form>
                <EnforcementTemplateForm {... formProps} />
                <button className="btn btn-primary pull-right">Create Template</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </FullContent>
  );
};
