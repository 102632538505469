import { faBadgeDollar, faChartPie, faHashtag, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import mapboxgl from 'mapbox-gl';
import * as React from 'react';
import { Chart } from 'react-google-charts';
import { useHistory, useLocation } from 'react-router';
import { states } from '../../../shared';
import { LayerInfo } from '../../Audience';
import { AffinityMap, LoadingSpinner, useCancelToken } from '../../shared';
import { ChartFooter } from '../ChartFooter';
import { ChartHeader } from '../ChartHeader';
import { ChartItemList } from '../ChartItemList';
import { CustomBarChart } from '../CustomBarChart';
import { usePerformanceQueryParams } from '../usePerformanceQueryParams';

interface IProps {
  large: boolean;
  link?: string;
  cities: GeoCityStats[];
  states: GeoStateStats[];
  loading: boolean;
  section?: 'states' | 'cities' | 'map' ;
  singleState? : string;
  isAggregate: boolean;
  onDashboard?: boolean;
}

export interface GeoStateStats {
  state: string;
  fullState: string | undefined;
  amount: number;
  percent: number;
  units: number;
  rpu: number;
  sales: number;
}

export interface GeoCityStats {
  city: string;
  amount: number;
  percent: number;
  units: number;
  rpu: number;
  sales: number;
}

export const GeolocationPerformance = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const performanceQP = usePerformanceQueryParams();
  const map = React.useRef<any>(null);
  const cancelToken = useCancelToken();
  const [mapData, setMapData] = React.useState<any>({
    sales: null,
    mapDefaults: null,
  });
  const [loadingMap, setLoadingMap] = React.useState(false);
  const [layers, setLayers] = React.useState<LayerInfo[]>([]);
  const countyClickRef = React.useRef<any>(null);
  const stateClickRef = React.useRef<any>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);

  React.useEffect(() => {
    if (props.section === 'map') {
      getSales();
      if (map.current.map) {
        setStateCenter();
      }
    }
  },              [location.search]);

  const setStateCenter = () => {
    if (performanceQP.params.state) {
      const foundState = states.find(s => s.shortName === performanceQP.params.state);
      if (foundState) {
        map.current.setCenter(foundState.lng, foundState.lat);
        map.current.setZoom(5);
      }
    } else {
      map.current.setCenter(-98.583333, 39.833333);
      map.current.setZoom(2.75);
    }
  };

  const onMapLoad = () => {
    if (map.current.map) {
      setStateCenter();
    }
  };

  const onStateClick = (e: any) => {
    const stateSalesPopup = new mapboxgl.Popup();
    const description = `<strong>${e.features[0].properties.NAME}</strong>
                                ${e.features[0].properties.net_sales ? `<br>Rank #${e.features[0].properties.rank}, ${e.features[0].properties.percentage < 1 ? '<1' : e.features[0].properties.percentage}% of royalties` : ''}
                                <br>Sales: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.net_sales)}
                                <br>Royalties: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.royalty_sales)}`;
    stateSalesPopup
      .setLngLat(e.lngLat)
      .setHTML(description)
      .addTo(map.current.map);
  };

  const onCountyClick = (e: any) => {
    const countySalesPopup = new mapboxgl.Popup();
    const description = `<strong>${e.features[0].properties.NAMELSAD}, ${e.features[0].properties.STUSPS}</strong>
                         ${e.features[0].properties.net_sales ? `<br>Rank #${e.features[0].properties.rank}, ${e.features[0].properties.percentage < 1 ? '<1' : e.features[0].properties.percentage}% of royalties` : ''}
                         <br>Sales: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.net_sales)}
                         <br>Royalties: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.royalty_sales)}`;
    countySalesPopup
      .setLngLat(e.lngLat)
      .setHTML(description)
      .addTo(map.current.map);
  };

  const setSalesLayer = (sales: any) => {
    layers.forEach((l) => {
      switch (l.name) {
        case 'state-sales':
          map.current.map.off('click', l.layerName, stateClickRef.current);
          stateClickRef.current = null;
          break;
        case 'county-sales':
          map.current.map.off('click', l.layerName, countyClickRef.current);
          countyClickRef.current = null;
          break;
        default:
          break;
      }
    });
    map.current.removeLayers(layers);
    const l: LayerInfo[] = [];
    const stateSalesInfo: LayerInfo = {
      id: 'state-sales-all',
      name: 'state-sales',
      sourceName: 'state-sales-source',
      layerName: 'state-sales-layer',
      isChecked: true,
      minZoom: 0,
      maxZoom: 5,
    };
    l.push(stateSalesInfo);
    map.current.map.addSource(stateSalesInfo.sourceName, {
      type: 'geojson',
      data: sales.state.salesJson,
    });
    addChoroplethLayer(stateSalesInfo, sales.state.max, sales.state.max2);

    stateClickRef.current = onStateClick;
    map.current.map.on('click', stateSalesInfo.layerName, stateClickRef.current);

    map.current.map.on('mouseenter', stateSalesInfo.layerName, () => {
      map.current.map.getCanvas().style.cursor = 'pointer';
    });
    map.current.map.on('mouseleave', stateSalesInfo.layerName, () => {
      map.current.map.getCanvas().style.cursor = '';
    });

    if (sales.county && sales.county.salesJson) {
      const countySalesInfo: LayerInfo = {
        id: 'county-sales-all',
        name: 'county-sales',
        sourceName: 'county-sales-source',
        layerName: 'county-sales-layer',
        isChecked: true,
        minZoom: 5,
        maxZoom: 23,
      };
      l.push(countySalesInfo);
      map.current.map.addSource(countySalesInfo.sourceName, {
        type: 'geojson',
        data: sales.county.salesJson,
      });
      addChoroplethLayer(countySalesInfo, sales.county.max, sales.county.max2);

      countyClickRef.current = onCountyClick;
      map.current.map.on('click', countySalesInfo.layerName, countyClickRef.current);

      map.current.map.on('mouseenter', countySalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = 'pointer';
      });
      map.current.map.on('mouseleave', countySalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = '';
      });
    }
    setLayers(l);
  };

  const addChoroplethLayer = (info: LayerInfo, max: number, max2: number) => {

    let colors;
    if (max2 === max || max2 === 0) {
      colors =  [
        'interpolate',
        ['exponential', 1],
        ['get', 'count'],
        0,
        'hsla(0, 0%, 0%, 0)',
        1,
        'hsl(210,22%,84%)',
        max,
        'hsl(201,56%,34%)',
      ];
    } else {
      colors =  [
        'interpolate',
        ['exponential', 1],
        ['get', 'count'],
        0,
        'hsla(0, 0%, 0%, 0)',
        1,
        'hsl(210,22%,84%)',
        max2,
        'hsl(198,39%,45%)',
        max,
        'hsl(201,56%,34%)',
      ];
    }

    map.current.map.addLayer(
      {
        id: info.layerName,
        source: info.sourceName,
        minzoom: info.minZoom,
        maxzoom: info.maxZoom,
        visibility: 'visible',
        type: 'fill',
        paint: {
          'fill-color': colors,
          'fill-opacity': 0.75,
          'fill-outline-color': '#FFFFFF',
        },
      },
      'waterway-label',
    );
  };

  const getSales = async () => {
    cancelToken.cancelToken();
    const newToken = Axios.CancelToken.source();
    cancelToken.setToken(newToken);

    setLoadingMap(true);

    try {
      const s = await Axios.get(`/api/audience/map?withSales=1&${performanceQP.toMapApi('geo')}`, {
        cancelToken: newToken.token,
      });

      setMapData({
        sales: s.data.sales,
        mapDefaults: s.data.default,
      });
      setSalesLayer(s.data.sales);
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching sales data:', error);
      }
    } finally {
      setLoadingMap(false);
    }
  };

  if (props.loading && props.section !== 'map') {
    return <LoadingSpinner />;
  }

  const cityData = props.cities.map((c) => {
    return {
      city: c.city,
      royalties: c.amount,
      percent: c.percent,
      rpu: c.rpu,
      units: c.units,
      sales: c.sales,
      avgUnitSales: (c.sales / c.units).toFixed(2),
      avgUnitRoyalties: (c.amount / c.units).toFixed(2),
    };
  });

  const stateData = props.states.map((s) => {
    return {
      state: s.fullState,
      royalties: s.amount,
      percent: s.percent,
      rpu: s.rpu,
      units: s.units,
      sales: s.sales,
      avgUnitSales: (s.sales / s.units).toFixed(2),
      avgUnitRoyalties: (s.amount / s.units).toFixed(2),
    };
  });

  const handleClick = (index: number) => {
    const state = props.states[index];
    if (state) {
      const queryString = performanceQP.toQueryString([{ value: state.state, label: 'state' }]);
      if (props.onDashboard) {
        history.push(`${location.pathname}/geolocation?${queryString}`);
      } else {
        history.push(`${location.pathname}?${queryString}`);
      }
    }
  };

  if (props.large) {
    switch (props.section) {
      case 'states':
        return (
          <div>
            { props.onDashboard ?
              <ChartHeader header="States" subHeader="Sales by state." />
              :
              <ChartHeader header="States" subHeader="Sales by state." tableRef={tableRef} />
            }
            <ChartItemList
              listVisualStyle="dot"
              items={stateData}
              itemLabels={[
                { state: 'State' },
                { percent: '%' },
                { units: 'Units' },
                { avgUnitSales: 'Avg Unit Sales' },
                { avgUnitRoyalties: 'Avg Unit Royalties' },
                { sales: 'Sales' },
                { royalties: 'Royalties' },
              ]}
              showLabels={true}
              useShowAll={true}
              onClick={handleClick}
              ref={tableRef}
            />
          </div>
        );
      case 'cities':
        return (
          <div>
            {props.onDashboard ?
              <ChartHeader header="Cities" subHeader="Sales by cities." />
              : <ChartHeader header="Cities" subHeader="Sales by cities." tableRef={tableRef} />

            }
            <ChartItemList
              listVisualStyle="dot"
              items={cityData}
              itemLabels={[
                { city: '' },
                { percent: '%' },
                { units: 'Units' },
                { avgUnitSales: 'Avg Unit Sales' },
                { avgUnitRoyalties: 'Avg Unit Royalties' },
                { sales: 'Sales' },
                { royalties: 'Royalties' },
              ]}
              showLabels={true}
              useShowAll={true}
              ref={tableRef}
            />
          </div>
        );

      case 'map':
        const newMap = (
          <div>
            <AffinityMap useDefaultCenter={true} fullParams={performanceQP.toFullMap()} isFullScreen={false} ref={map} height={500} width="100%" loading={loadingMap} onMapLoad={onMapLoad} />
          </div>
        );
        return <div>{newMap}</div>;
    }
  }

  if (props.section) {
    switch (props.section) {
      case 'states':
        const stateLabels: any = props.onDashboard
          ? [{ state: '' }, { percent: '%' }]
          : [
            { state: '' },
            { percent: '%' },
            { units: 'Units' },
            { avgUnitSales: 'Avg Unit Sales' },
            { avgUnitRoyalties: 'Avg Unit Royalties' },
            { sales: 'Sales' },
            { royalties: 'Royalties' },
          ];
        return (
          <div>
            <ChartHeader header="States" subHeader="Sales by state." tableRef={tableRef} />

            <ChartItemList
              listVisualStyle={props.onDashboard ? 'none' : 'dot'}
              items={stateData.slice(0, stateData.length >= 5 ? 5 : stateData.length)}
              itemLabels={stateLabels}
              showLabels={!props.onDashboard}
              onClick={handleClick}
              ref={tableRef}
            />
            {stateData.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('geolocation')} />}
          </div>
        );
      case 'cities':
        const cityLabels: any = props.onDashboard
          ? [{ city: '' }, { percent: '%' }]
          : [
            { city: '' },
            { percent: '%' },
            { units: 'Units' },
            { avgUnitSales: 'Avg Unit Sales' },
            { avgUnitRoyalties: 'Avg Unit Royalties' },
            { sales: 'Sales' },
            { royalties: 'Royalties' },
          ];
        return (
          <div>
            {props.onDashboard ?
              <ChartHeader header="Cities" subHeader="Sales by city." />
              :             <ChartHeader header="Cities" subHeader="Sales by city." tableRef={tableRef} />

            }
            <ChartItemList
              listVisualStyle={props.onDashboard ? 'none' : 'dot'}
              items={cityData.slice(0, cityData.length >= 5 ? 5 : cityData.length)}
              itemLabels={cityLabels}
              showLabels={!props.onDashboard}
              ref={tableRef}
            />
            {cityData.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('geolocation')} />}
          </div>
        );

      case 'map':
        return (
          <div>
            <ChartHeader header="Geolocation" subHeader="Sales by geolocation." />
            <AffinityMap useDefaultCenter={true} fullParams={performanceQP.toFullMap()} isFullScreen={false} ref={map} height={300} width="100%" loading={loadingMap} onMapLoad={onMapLoad} />
            {stateData.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('geolocation')} />}
          </div>
        );
    }
  }

  return (
    <div>
      <ChartHeader header="Geolocation" subHeader="Sales by geolocation." tableRef={tableRef} />
      <AffinityMap isFullScreen={false} useDefaultCenter={true} ref={map} height={300} width="100%" loading={loadingMap} onMapLoad={() => console.log('map loaded')} />

      <div className="row">
        <div className="col-md-6">
          <ChartItemList
            listVisualStyle="dot"
            items={stateData}
            itemLabels={[{ state: 'State' }, { royalties: 'Royalties' }]}
            showLabels={true}
            ref={tableRef}
          />
        </div>
        <div className="col-md-6">
          <ChartItemList
            listVisualStyle="dot"
            items={cityData}
            itemLabels={[{ city: 'City' }, { royalties: 'Royalties' }]}
            showLabels={true}
            ref={tableRef}
          />
        </div>
      </div>

      <ChartFooter isPartial={props.isAggregate} link={props.link ? props.link : ''} />
    </div>
  );
};
