import Axios from 'axios';
import { Form, Formik } from 'formik';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { EnforcementTemplateForm } from '.';
import { EnforcementTemplate } from '../../../shared/EnforcementTemplate';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';

export const EnforcementTemplateDetailPage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [template, setTemplate] = React.useState<EnforcementTemplate|null>(null);
  const [updating, setUpdating] = React.useState(false);

  React.useEffect(() => {
    getTemplate();

  },              []);

  const getTemplate = async () => {
    if (match) {
      const t = await Axios.get(`/api/enforcement-templates/${match.params['id']}`);
      setTemplate(EnforcementTemplate.fromApi(t.data.data));
    }
  };

  const submit = async (values: any) => {
    if (!updating) {
      setUpdating(true);
      const data = {
        name: values.name,
        description: values.description,
        content: values.content,
        platform_id: values.type,
        is_dmca: values.isDmca,
        subject: values.subject,
      };
      if (match) {
        await Axios.post(`/api/enforcement-templates/${match.params['id']}`, data);
        history.push('/enforce/templates');
      }
    }

  };

  const deleteTemplate = async () => {
    const c = confirm('Delete Tempalte?');
    if (c && match) {
      await Axios.delete(`/api/enforcement-templates/${match.params['id']}`);
      history.push('/enforce/templates');
    }
  };

  if (!template) {
    return <LoadingSpinner />;
  }

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Templates')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <Formik
            initialValues={{
              name: template.name,
              isDmca: template.isDmca,
              description: template.description,
              type: template.platformId,
              url: '',
              content: template.content,
              subject: template.subject,
            }}
            onSubmit={submit}
          >
            {formProps => (
              <Form>
                <EnforcementTemplateForm {... formProps} />
                <button type="button" onClick={deleteTemplate} className="btn btn-danger pull-left">Delete Template</button>
                <button className="btn btn-primary pull-right">{updating ? 'Saving...' : 'Save'}</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </FullContent>
  );
};
