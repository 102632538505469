import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { UserContext } from '../../contexts';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { AccountDesignResponseIndex } from './AccountDesignResponseIndex';
import { FilterableDesignResponseIndex } from './FilterableDesignResponseIndex';

export class DesignResponseIndexPage extends React.Component<any, any> {

  render() {

    return (
      <FullContent breadcrumbs={[{ name: 'Design Responses' }]}>
      <DocumentTitle title={getAffinityPageTitle('Design Responses')}>
        <div>
        <UserContext.Consumer>
          { (user) => {

            if (user.type === 'admin') {
              const accountId = this.props.match.params['licensorId'];
              if (accountId) {
                return (
                  <AccountDesignResponseIndex accountId={accountId}></AccountDesignResponseIndex>
                );
              }
              return (
                <FilterableDesignResponseIndex></FilterableDesignResponseIndex>
              );
            }
            return (
              <AccountDesignResponseIndex accountId={user.account.id}></AccountDesignResponseIndex>
            );
          }}
        </UserContext.Consumer>
      </div>
      </DocumentTitle>
      </FullContent>
    );
  }

}
