import Axios from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { AccountProfile } from '../../../shared';
import { GatewayModal, ModalType } from '../../shared/modals';

interface IProps {
  isShown: boolean;
  canExpedite: boolean;
  confirm: () => void;
  close: () => void;
}

export const ExpediteDesignModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const [expedite, setExpedite] = React.useState(false);
  const user = React.useContext(UserContext);
  const match = useRouteMatch();

  React.useEffect(() => {
    getPermission();

  },              []);

  const getPermission = async () => {
    const vendorId = user.type === 'vendor' ? user.account.id : match && match.params['vendorId'];
    const params = {
      vendor_id: vendorId,
    };
    const e = await Axios.get('/api/designs/permissions', { params });
    setExpedite(e.data.data.can_expedite);
  };

  return (
    <GatewayModal
      shown={props.isShown}
      title="Expedite Review"
      type={ModalType.Primary}
      onClose={props.close}>
      { expedite ? (
        <>
          <Modal.Body>
            <p>
              As a reminder, you will be charged $3.75 per design if the design is reviewed within the allotted time
              frame. The allotted time frame for designs that are selected Monday-Thursday for expedited review is 24
              hours. The allotted time frame for designs that are selected Friday-Sunday for expedited review is no
              later than Monday at 11:59 pm Pacific. The fee for designs selected for expedited review will be invoiced
              and automatically debit your account weekly.
            </p>
            <p className="text-primary">By continuing, you certify that you are authorized to expedite designs and
              accept the terms and fees associated with expedited review.</p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-default pull-left" onClick={props.close}>Cancel</button>
            <button className="btn btn-primary" onClick={props.confirm}>Yes, Expedite</button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body>
            <p>
              Expedited Designs needs to be enabled on your account before you can utilize this feature.
              The account owner must enable Expedited Design Review features on the 'Expedited Designs' tab of your
              account settings.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-toolbar">
              <button className="btn btn-default btn-wide" onClick={props.close}>Ok</button>
            </div>
          </Modal.Footer>
        </>
      )}
    </GatewayModal>
  );
};
