import { ErrorMessage, Field, FormikProps } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { NoteThreadCategory } from '../../../shared/Notes/NoteThreadCategory';
import { NoteThreadCategories } from '../NoteThreadCategories';

export interface ThreadFormValues {
  subject: string;
  categories: NoteThreadCategory[];
  code: string;
  pinned: boolean;
  flagged: boolean;
}

export const ThreadFormValidation = Yup.object().shape({
  subject: Yup.string().required('A subject is required'),
  categories: Yup.array().min(1, 'A tag is required').required('A tag is required'),
});

export const NoteThreadForm = (props: FormikProps<ThreadFormValues>) => {

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Tags</label>
            <NoteThreadCategories
              id="thread-categories"
              selectedCategories={props.values.categories}
              selectCategoryFn={v => props.setFieldValue('categories', v)}
            />
            <p className="text-danger">
              <ErrorMessage name="categories" />
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Subject</label>
            <Field className="form-control" name="subject" />
            <p className="text-danger">
              <ErrorMessage name="subject" />
            </p>
          </div>

        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Code (optional)</label>
            <Field className="form-control" name="code" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Options</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="checkbox">
                <label>
                  <Field type="checkbox" name="pinned" checked={props.values.pinned} /> Pinned
              </label>
              </div>
              <div style={{ marginTop: 10, marginLeft: 15 }} className="checkbox">
                <label>
                  <Field type="checkbox" name="flagged" checked={props.values.flagged} /> Flagged
                </label>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  );

};
