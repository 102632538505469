import { Chart, ChartOptions } from 'chart.js';
import { sum } from 'lodash';
import * as React from 'react';

interface IProps {
  id: string;
  data: number[];
}

export const CustomBarChart = (props: IProps) => {
  React.useEffect(() => {
    setup();

  },              []);

  const setup = () => {

    const filteredValues = props.data.filter(v => v >= 1);
    const currentTotal = sum(filteredValues);
    let otherData = 100 - currentTotal;
    if (otherData < 0) {
      otherData = 0;
    }

    const horizonalData: any = {
      datasets: filteredValues.map((d, index) => {
        return { data: [d] };
      }),
    };
    horizonalData.datasets.push({
      data: [otherData],
      backgroundColor:  'lightgray',
    });
    const fakeHorizontal: ChartOptions = {
      maintainAspectRatio: false,
      layout: {
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{

          barThickness: 0.5,
          stacked: true,
          display: false,
        }],
        yAxes: [{

          display: false,
          barThickness: 100,
          stacked: true,
        }],
      },
      tooltips: {
        enabled: false,
        mode: 'point',
      },
      plugins: {
        colorschemes: {
          scheme: 'tableau.MillerStone11',
        },
      },
    };

    const ele = document.getElementById(props.id) as HTMLCanvasElement;
    const chart = new Chart(ele, {
      type: 'horizontalBar',
      data: horizonalData,
      options: fakeHorizontal,
    });
    chart;

  };

  return (
    <div style={{ margin: '20px 0',  position: 'relative', height: '20px', width: '100%' }}>
      <canvas id={props.id} width="100%" height="50px"></canvas>
    </div>
  );

};
