import { sum, uniqWith } from 'lodash';
import * as moment from 'moment';
import { MarketplaceAddress, MarketplaceAddressApiResponse } from './MarketplaceAddress';
import { MarketplaceOrderLineItem, MarketplaceOrderLineItemApiResponse } from './MarketplaceOrderLineItem';
import { MarketplaceOrderVendor, MarketplaceOrderVendorApiResponse } from './MarketplaceOrderVendor';
import { ISiteApiResponse, MarketplaceSite } from './MarketplaceSite';

interface MarketplaceOrderCustomer {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  phone: string;
  email: string;
}

export interface MarketplaceOrderApiResponse {
  id: number;
  created_at: string;
  updated_at: string;
  card_brand: string;
  card_last_four: string;
  customer: { id: number, first_name: string, last_name: string, name: string, phone: string, email: string};
  shipping_address: MarketplaceAddressApiResponse;
  billing_address: MarketplaceAddressApiResponse;
  line_items: MarketplaceOrderLineItemApiResponse[];
  site: ISiteApiResponse | null;
  order_vendors?: MarketplaceOrderVendorApiResponse[];
}

export class MarketplaceOrder {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  cardBrand: string;
  cardLastFour: string;
  customer: MarketplaceOrderCustomer;
  shippingAddress: MarketplaceAddress;
  billingAddress: MarketplaceAddress;
  lineItems: MarketplaceOrderLineItem[];
  site: MarketplaceSite | null;
  orderVendors: MarketplaceOrderVendor[];

  static fromApi(data: MarketplaceOrderApiResponse) {
    const order = new MarketplaceOrder();
    order.id = data.id;
    order.createdAt = moment(data.created_at);
    order.updatedAt = moment(data.updated_at);
    order.cardBrand = data.card_brand;
    order.cardLastFour = data.card_last_four;
    order.customer = {
      id: data.customer.id,
      firstName: data.customer.first_name,
      lastName: data.customer.last_name,
      name: data.customer.name,
      phone: data.customer.phone,
      email: data.customer.email,
    };
    order.shippingAddress = MarketplaceAddress.fromApi(data.shipping_address);
    order.billingAddress = MarketplaceAddress.fromApi(data.billing_address);
    order.lineItems = data.line_items.map(MarketplaceOrderLineItem.fromApi);
    order.site = data.site ? MarketplaceSite.fromApi(data.site) : null;
    order.orderVendors = data.order_vendors ? data.order_vendors.map(ov => MarketplaceOrderVendor.fromApi(ov)) : [];

    return order;

  }

  get subtotal() {
    return this.lineItems.reduce(
      (total, order) => {
        return total + (parseFloat(order.price));
      },
      0.0);
  }
  get shippingTotal() {
    return this.lineItems.reduce(
      (total, order) => {
        return total + parseFloat(order.shippingPrice);
      },
      0.0);
  }

  get taxesTotal() {
    return this.orderVendors.map(ov => ov.taxAmount).reduce((total, tax) => total + tax, 0);
  }

  get total () {
    return this.subtotal + this.shippingTotal + this.taxesTotal;
  }

  get groupedLineItems() {
    return this.uniqueVendors.map((v) => {
      return { vendor: v, lineItems: this.lineItems.filter(o => o.product.vendor.id === v.id) };
    });
  }

  get uniqueVendors() {
    return uniqWith(this.lineItems.map(l => l.product.vendor), (a, b) => a.id === b.id);
  }

  /**
   * All order-vendor refunds flattened into a array
   */
  get orderVendorRefunds() {
    return this.orderVendors.map(ov => ov.refunds).reduce((acc, refunds) => acc.concat(refunds), []);
  }

  get subTotalRefunded() {
    return sum(this.lineItems.map(l => l.subTotalRefunded));
  }

  get shippingRefunded() {
    return this.orderVendorRefunds.map(ovr => ovr.shippingAmount).reduce((acc, amt) => acc + amt, 0);
  }

  get percentShippingRefunded() {
    return this.shippingRefunded / this.shippingTotal;
  }

  get totalTaxRefunded() {
    return this.orderVendorRefunds.map(ovr => ovr.taxAmount).reduce((acc, amt) => acc + amt, 0);
  }

  get totalRefunded() {
    return this.shippingRefunded + this.subTotalRefunded + this.totalTaxRefunded;
  }

  get hasShopify() {
    return this.lineItems.reduce((prev, l) => {
      if (l.product.isShopify) {
        return true;
      }
      return prev;
    },                           false);
  }

  get isShopifyFulfilled() {
    return this.lineItems.reduce((prev, l) => {
      if (l.product.isShopify && !l.shopifyOrderId) {
        return false;
      }
      return prev;
    },                           true);
  }

  get vendorRefunds() {
    return this.lineItems.map((l) => {
      const vendor = l.product.vendor;
      const price = l.fullUnitPrice;
      const refunds = l.refunds;
      return { vendor, refunds, price };
    });
  }
}
