import * as React from 'react';

export interface Pill {
  id: string | number; // So we can use this as whatever for filtering
  label: string;
}

interface IProps {
  pills: Pill[];
  active: Pill | null;
  onSelect: (p: Pill) => void;

}

export const PillTabs = (props: IProps) => {

  const pills = props.pills.map(p => (
    <div key={p.id} onClick={() => props.onSelect(p)} className={`pill-tab ${props.active && p.id === props.active.id ? 'active' : ''}`}>
      {p.label}
    </div>
  ));

  return (
    <div className="pill-tabs">
      {pills}

    </div>
  );
};
