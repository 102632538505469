import { RoyaltyReport } from '../../../shared';

export const getRoyaltyReportingUrl = (report: RoyaltyReport): string => {
  const salesDataLink = `/royalties/${report.id}/sales-data`;
  const aggregateLink = `/royalties/${report.id}/aggregate`;

  const hasSalesReporting = report.salesDataLicensors.length > 0;
  const hasAggregateReporting = report.aggregateLicensors.length > 0;

  if (hasAggregateReporting && !hasSalesReporting) {
    return aggregateLink;
  }
  return salesDataLink;
};

export const getVendorUrl = (urlSuffix: string, vendorId: number, userType: string) => {

  if (userType === 'admin') {
    if (urlSuffix.indexOf('/') === 0) {
      urlSuffix = urlSuffix.substr(1);
    }
    return `/vendors/${vendorId}/${urlSuffix}`;
  }
  return urlSuffix;
};
