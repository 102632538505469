import { faDownload, faInfoCircle, faMinus, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as moment from 'moment';
import * as queryString from 'query-string';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { AffinityImage, Design, Filters } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FilterBar, GatewayModal, LoadingSpinner, ModalType, PaginationFooter } from '../../shared';

export const UpiPage = () => {
  const history = useHistory();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [loading, setLoading] = useState(true);
  const [designs, setDesigns] = useState<Design[] | []>([]);
  const [editModalShown, setEditModalShown] = useState(false);
  const [warningModalShown, setWarningModalShown] = useState(false);
  const [designToEdit, setDesignToEdit] = useState(new Design());
  const [submittingUpi, setSubmittingUpi] = useState(false);
  const [search, setSearch] = useState(query['search'] ? query['search'] : null);
  const [startDate, setStartDate] = useState(query['startDate'] ? query['startDate'] : null);
  const [endDate, setEndDate] = useState(query['endDate'] ? query['endDate'] : null);
  const [dropdownFilter, setDropdownFilter] = useState(query['upi'] ? query['upi'] : 'all');
  const [upiError, setUpiError] = useState('');
  const routeParams = useParams();
  const user = useContext(UserContext);
  const [lastPage, setLastPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [page, setPage] = useState(1);
  const filters = new Filters();
  filters.setQueryParamsFromLocation(location.search);

  useEffect(() => {
    getUpis();
  },        [location.search]);

  function getUpis() {
    setLoading(true);
    const currentParams = queryString.parse(location.search);
    const params = {
      account_id: getAccountID(),
      page: currentParams.page ? currentParams.page : 1,
      upi: currentParams.upi ? currentParams.upi : null,
      startDate: currentParams.startDate ? currentParams.startDate : null,
      endDate: currentParams.endDate ? currentParams.endDate : null,
      search: currentParams.search ? currentParams.search : null,
      limit: 10,
    };

    Axios.get('/api/upis', { params })
      .then((response) => {
        setDesigns(response.data.data.map((design: any) => Design.fromApi(design)));
        // setTotalResults(response.data.meta.pagination.total);
        // setLastPage(response.data.meta.pagination.total_pages);
        setHasMorePages(response.data.meta.pagination.has_more);
        setLoading(false);
      });
  }

  function exportDesignData() {
    if (startDate) {
      const currentParams = queryString.parse(location.search);

      const params = {
        account_id: getAccountID(),
        page: currentParams.page ? currentParams.page : 1,
        upi: currentParams.upi ? currentParams.upi : null,
        startDate: currentParams.startDate ? currentParams.startDate : null,
        endDate: currentParams.endDate ? currentParams.endDate : null,
        search: currentParams.search ? currentParams.search : null,
        limit: 10,
      };

      const cleaned = omitBy(params, isNil);

      window.open(`/api/upis/export?${queryString.stringify(cleaned)}`, '_blank');
    } else {
      showWarningModal();
    }
  }

  function generateRID(formProps: FormikProps<any>, field: string)  {
    let uid = uuidv4();
    uid = uid.split('-')[0];
    uid = uid.slice(0, 6).toUpperCase();
    const lid = designToEdit.primaryClient.id;
    const date = moment();
    const dateCode = date.format('MMDDYY');
    const combined = `${lid}-${uid}-${dateCode}`;
    formProps.setFieldValue(field, combined);
  }

  function updatePage(newPage: number) {
    setPage(newPage);
    const baseUrl = location.pathname;
    const qsParsed = queryString.parse(location.search);
    qsParsed.page = newPage;

    history.replace(`${baseUrl}?${queryString.stringify(qsParsed)}`);
  }

  function updateFilters(newFilters: Filters, performSearch: boolean) {

    if (typeof newFilters.search === 'string' && !performSearch) {
      setSearch(newFilters.search);
    }
    filters.setFilters(newFilters);

    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, ...newFilters, search };
    setStartDate(currentParams.startDate);
    setEndDate(currentParams.endDate);

    if (performSearch) {
      currentParams.page = 1;
      const cleaned = omitBy(currentParams, isNil);
      const baseUrl = location.pathname;
      history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
    }

  }

  function showEditModal() {
    setEditModalShown(true);
  }

  function hideEditModal() {
    setEditModalShown(false);
  }

  function showWarningModal() {
    setWarningModalShown(true);
  }

  function hideWarningModal() {
    setWarningModalShown(false);
  }

  function setEditID(e: any) {
    const id = parseInt(e.target.getAttribute('data-id'), 10);
    const design = designs.find(d => d.id === id);
    if (design) {
      setDesignToEdit(design);
    }
    showEditModal();
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  let pageContent;
  const backupImage = new AffinityImage();
  backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';

  const rows = (designs as any[]).map(design =>
      <tr key={ design.id }>
        <td style={{ verticalAlign: 'middle', minWidth:150 }}>
          <Link style={{ textDecoration: 'none' }} target="_blank" to={`/designs/${design.id}`}>
            <img style={{ maxHeight: 150, minHeight:150 }} className="img-responsive" src={design.primaryIteration && design.primaryIteration.image ? design.primaryIteration.image.getSize('sm') : backupImage.getSize('sm')}/>
          </Link>
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          { design.upi.length > 0 ? (design.upi as any[]).map((upi, index, upis) => {
            return(index < upis.length - 1 ? <>{upi.upi}<br/></> : upi.upi);
          }) : 'No UPI Found'
          }
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {design.created.format('MM/DD/YYYY')}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {design.primaryClient ? design.primaryClient.shortName : 'Not Set'}
        </td>
        <td style={{ verticalAlign: 'middle' }} className="text-ellipsis">
          { design.productCategories.length > 0 ? (design.productCategories as any[]).map((category, index, categories) =>
            index < categories.length - 1 ? `${category.name}, ` : category.name,
          ) : 'No Product Category'
          }
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          {design.phase.name}
        </td>
        <td style={{ verticalAlign: 'middle' }}>
          <button className="btn btn-link text-primary pull-right" data-id={design.id} onClick={setEditID}>
            Manage UPI
          </button>
        </td>
      </tr>,
  );

  if (loading) {
    pageContent = (
      <LoadingSpinner />
    );
  } else if (designs.length === 0 && ! loading) {
    pageContent =  (
      <em>No UPI</em>
    );
  } else {
    pageContent = (
      <table className="table">
        <thead>
        <tr>
          <th></th>
          <th>UPI</th>
          <th>Upload Date</th>
          <th>Primary Licensor Name</th>
          <th>Product Categories</th>
          <th>Status</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    );
  }

  return (
    <FullContent breadcrumbs={[{ name: 'Upis' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Unique Product Identifiers (UPI)</strong></h3>
        </div>
        <button onClick={exportDesignData} className="btn btn-default">
          <FontAwesomeIcon className="text-primary" icon={faDownload} />Export Design Data
        </button>
      </div>
        <FilterBar
          updateFilters={updateFilters}
          usePerPage={false}
          useQuarter={false}
          useSearch={true}
          useDates={true}
          startDate={startDate}
          endDate={endDate}
          search={search}
          perPage={10}
          fieldClassOverrides={{ search: 'address-search-bar col-lg-3 col-sm-3 col-xs-12' }}
        >
          <div className="col-lg-3 col-sm-3 col-xs-12">
          <div className="input-group">
            <select
              onChange={(e: any) => {
                const currentParams = queryString.parse(location.search);
                if (e.target.value === 'all') {
                  currentParams.upi = null;
                } else {
                  currentParams.upi = e.target.value;
                }
                const cleaned = omitBy(currentParams, isNil);
                const baseUrl = location.pathname;
                history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
                setDropdownFilter(e.target.value);
              }}
              value={dropdownFilter}
              className="form-control input-sm"
            >
              <option value={'all'}>Filter (All Designs)</option>
              <option value={'true'}>Only Designs with UPI</option>
              <option value={'false'}>Only Designs Missing UPI</option>
            </select>
          </div>
        </div>
        </FilterBar>
      <div className="panel panel-portal">
        { pageContent }
      </div>
      <PaginationFooter
        setPage={updatePage}
        currentPage={page}
        totalResults={totalResults}
        hasNext={hasMorePages} />
      <GatewayModal
        type={ModalType.Danger}
        shown={warningModalShown}
        title="Date Range Required"
        onClose={hideWarningModal}
      >
        <Modal.Body>
          <p>Please select a date range for this export.</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setWarningModalShown(false)} type="button" className="btn btn-danger pull-right">Close</button>
        </Modal.Footer>
      </GatewayModal>
        <GatewayModal
          type={ModalType.Primary}
          shown={editModalShown}
          title="Update UPI"
          onClose={hideEditModal}
        >
          <Formik validationSchema={Yup.object().shape({
            upi: Yup.array().of(
              Yup.string()
                .required('UPI is required')
                .max(255, 'Must be shorter than 255 characters')
                .test(
                  'non-allowed-chars',
                  'The following characters are not allowed: , ;',
                  value => !value.includes(',') && !value.includes(';'),
                ),
            ),
          })} initialValues={{ upi: designToEdit.upi.length ? designToEdit.upi.map(u => u.upi) : [''] }} onSubmit={async (v) => {
            setSubmittingUpi(true);
            const filtered = v.upi.filter(u => u.length);

            const d = await Axios.put(`/api/designs/${designToEdit.id}/upi`, { upi: filtered })
              .catch((e) => {

                setSubmittingUpi(false);
                setUpiError('A duplicate UPI was found');
                return null;
              });

            if (d) {
              setSubmittingUpi(false);
              setEditModalShown(false);
              setUpiError('');
              getUpis();
            }

          }} >
            {formProps => (
              <Form>
                <Modal.Body>
                  {upiError ? (
                    <div className="alert alert-danger">
                      {upiError}
                    </div>
                  ) : null}
                  <FieldArray
                    name="upi"
                    render={helpers => (
                      <div>
                        <label style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Unique Product Identifier (UPI) <a className="text-muted" target="_blank" href="https://help.affinitylicensing.com/en/articles/6008552-unique-product-identifiers-upi">
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </a></span>
                          <span className="text-primary" onClick={() => helpers.push('')}><FontAwesomeIcon icon={faPlusCircle} /> UPI</span>

                        </label>
                        {formProps.values.upi.map((u: string, index: number) => (
                          <div className="form-group">
                            <div className="input-group">
                              <Field className="form-control" name={`upi.${index}`} />
                              <div className="input-group-btn">
                                <button onClick={() =>  u.length ? formProps.setFieldValue(`upi.${index}`, '') : generateRID(formProps, `upi.${index}`)} type="button" className="btn btn-default">{u.length ? 'Clear' : 'Generate'}</button>
                                <button onClick={() => helpers.remove(index)} type="button" className="btn btn-default"><FontAwesomeIcon className="text-danger" icon={faMinus} /></button>
                              </div>
                            </div>
                            <p className="text-danger">
                              <ErrorMessage name={`upi.${index}`} />
                            </p>
                          </div>

                        ))}
                      </div>
                    )}

                  />
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={() => setEditModalShown(false)} type="button" className="btn btn-default pull-left">Cancel</button>
                  <button className="btn btn-primary pull-right">{submittingUpi ? 'Saving...' :  'Save Changes'}</button>
                </Modal.Footer>

              </Form>
            )}

          </Formik>

        </GatewayModal>
    </FullContent>

  );

};
