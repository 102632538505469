import Axios from 'axios';
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { RoyaltyReport } from '../../../shared';

export const useRoyaltyReport = () => {
  const match = useRouteMatch();
  const [report, setReport] = React.useState<RoyaltyReport | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [handlerEnabled, setHandlerEnabled] = React.useState(false);

  React.useEffect(() => {
    getReport(true);

    return () => {
      if (handlerEnabled) {
        document.body.removeEventListener('update-report', handle, false);
      }
    };

  },              []);

  const getReport = async (showLoad: boolean) => {
    if (match) {
      if (showLoad) {
        setLoading(true);
      }
      const id = match.params['id'];
      const r = await Axios.get(`/api/royalty-reports/${id}`);
      const rp = new RoyaltyReport(r.data.data);
      setReport(rp);
      if (showLoad) {
        setLoading(false);
      }
    }
  };

  const update = (load: boolean) => {
    getReport(load);

  };

  const enableHandler = () => {
    setHandlerEnabled(true);
    document.body.addEventListener('update-report', handle, false);
  };

  const handle = () => {
    update(true);
  };

  const updateAllReports = () => {
    if (!handlerEnabled) {
      const createThreadEvent = new CustomEvent('update-report', {
        bubbles: true,
      });
      document.body.dispatchEvent(createThreadEvent);
    }

  };

  const reportId = match && match.params['id'];

  return { report, loading, update, reportId, enableHandler, updateAllReports };

};
