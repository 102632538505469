import { MarketplaceSitePageItem } from './MarketplaceSitePageItem';

interface ISitePageResponse {
  id: string;
  title: string;
  description: string;
  path: string;
  page_items: any[];
}

export class MarketplaceSitePage {
  id: string;
  title: string;
  description: string;
  path: string;
  pageItems: MarketplaceSitePageItem[];

  static fromApi(data: ISitePageResponse): MarketplaceSitePage {
    const page = new MarketplaceSitePage();
    page.id = data.id;
    page.title = data.title;
    page.description = data.description;
    page.path = data.path;
    page.pageItems = data.page_items ? data.page_items.map(MarketplaceSitePageItem.fromApi) : [];

    return page;
  }
}
