import { faRadar, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikValues } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { AffinityClient, AffinityImage, Filters } from '../../../shared';
import User from '../../../shared/User';
import { WebBotSeller, WebBotSellerApiResponse } from '../../../shared/WebBotSeller';
import { FullContent } from '../../ContentFrame';
import {
  FilterBar, GatewayImage,
  GatewayModal,
  LicensorTypeahead,
  LoadingSpinner,
  ModalType,
  PaginationFooter,
} from '../../shared';

interface Paginator {
  total: number;
  page: number;
  lastPage: boolean;
  totalPages: number;
}

export const ScansIndexPage = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [search, setSearch] = useState(query['search'] ? query['search'] : null);
  const [sellers, setSellers] = React.useState<WebBotSeller[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [selectedSort, setSelectedSort] = React.useState(query['sort'] ? query['sort'] : 'recent');
  const [selectedFilter, setSelectedFilter] = React.useState(query['accounts'] ? query['accounts'] : 'all');
  const [sellerFilter, setSellerFilter] = React.useState(query['sellers'] ? query['sellers'] : '');
  const [adminFilter, setAdminFilter] = React.useState(query['admin'] ? query['admin'] : '');
  const [createScanModalShown, setCreateScanModalShown] = React.useState(false);
  const [licensor, setLicensor] = React.useState<AffinityClient[]>([]);
  const [adminUsers, setAdminUsers] = React.useState<User[]>([]);
  const [pagination, setPagination] = React.useState<Paginator>({
    total: 0,
    page: 0,
    lastPage: false,
    totalPages: 0,
  });
  const [disabledAdminSelects, setDisabledAdminSelects] = React.useState<number[]>([]);
  const filters = new Filters();
  const routeParams = useParams();
  const history = useHistory();
  const routeMatch = useRouteMatch<{vendorId: string, clientId: string}>();
  let firstLoad = true;
  React.useEffect(() => {
    if (firstLoad) {
      getAdminUsers();
      firstLoad = false;
    }
    getWebBotSellers();
  },              [location.search]);

  const setPage = (newPage: number) => {
    const temp = pagination;
    temp.page = newPage;
    setPagination(temp);
    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, page: newPage };
    const cleaned = omitBy(currentParams, isNil);

    const baseUrl = location.pathname;
    history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
  };

  const getAdminUsers = async () => {
    const u = await Axios.get('/api/users?account_id=1');
    const users = u.data.data.map((u: any) => User.fromApi(u));
    setAdminUsers(users);
  };

  function changeScanSort(e: any) {
    setLoading(true);
    setSelectedSort(e.target.value);
    const qsParsed = queryString.parse(location.search);
    qsParsed.sort = e.target.value;
    qsParsed.page = 1;
    history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  }

  function changeAccountFilter(e: any) {
    setLoading(true);
    setSelectedFilter(e.target.value);
    const qsParsed = queryString.parse(location.search);
    qsParsed.accounts = e.target.value !== '' ? e.target.value : null;
    qsParsed.page = 1;
    const cleaned = omitBy(qsParsed, isNil);

    history.push(`${location.pathname}?${queryString.stringify(cleaned)}`);
  }

  function changeAdminFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    setLoading(true);
    setAdminFilter(e.target.value);
    const qsParsed = queryString.parse(location.search);
    qsParsed.admin = e.target.value !== '' ? e.target.value : null;
    qsParsed.page = 1;
    const cleaned = omitBy(qsParsed, isNil);

    history.push(`${location.pathname}?${queryString.stringify(cleaned)}`);
  }

  function changeSellerFilter(e: React.ChangeEvent<HTMLSelectElement>) {
    setLoading(true);
    setSellerFilter(e.target.value);
    const qsParsed = queryString.parse(location.search);
    qsParsed.sellers = e.target.value !== '' ? e.target.value : null;
    qsParsed.page = 1;
    const cleaned = omitBy(qsParsed, isNil);

    history.push(`${location.pathname}?${queryString.stringify(cleaned)}`);
  }

  function updateFilters(newFilters: Filters, performSearch: boolean) {

    if (typeof newFilters.search === 'string' && !performSearch) {
      setSearch(newFilters.search);
    }
    filters.setFilters(newFilters);

    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, ...newFilters, search };

    if (performSearch) {
      currentParams.page = 1;
      const cleaned = omitBy(currentParams, isNil);
      const baseUrl = location.pathname;
      history.push(`${baseUrl}?${queryString.stringify(cleaned)}`);
    }

  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['clientId']) {
      return routeParams['clientId'];
    }
    return '';
  }

  function hideCreateModal() {
    setCreateScanModalShown(false);
  }

  function getScanHistoryPage() {
    if (routeParams['vendorId']) {
      history.push(`/vendors/${getAccountID()}/scans/history`);
    }
    if (routeParams['clientId']) {
      history.push(`/clients/${getAccountID()}/scans/history`);
    } else {
      history.push('/scans/history');
    }
  }

  function getScanSellerDetailPageLink(id:number) {
    return `/scans/sellers/${id}`;
  }

  const getWebBotSellers = async () => {
    setLoading(true);
    let clientId = null;
    if (routeMatch !== null && routeMatch.params['clientId']) {
      clientId = routeMatch.params['clientId'];
    }
    const query = queryString.parse(location.search);
    const params = {
      clientId,
      page: query.page ? query.page : null,
      search: query.search ? query.search : null,
      sort: query.sort ? query.sort : null,
      accounts: query.accounts ? query.accounts : null,
      admin: query.admin ? query.admin : null,
      sellers: query.sellers ? query.sellers : null,
    };
    const cleaned = omitBy(params, isNil);
    const s = await Axios.get('/api/enforcement-bots/sellers', { params: cleaned });
    const paginationResult = s.data.meta.pagination;
    setPagination({
      total: paginationResult.total,
      page: paginationResult.current_page,
      lastPage: paginationResult.total_pages === paginationResult.current_page,
      totalPages: paginationResult.total_pages,
    });
    const sellers = s.data.data.map((seller:WebBotSellerApiResponse) => WebBotSeller.fromApi(seller));
    setSellers(sellers);
    setLoading(false);
  };

  const saveScanRequest = async (values: FormikValues, formProps: FormikActions<FormikValues>) => {
    setSaving(true);
    let clientId = null;
    if (routeMatch !== null && routeMatch.params['clientId']) {
      clientId = routeMatch.params['clientId'];
    }
    const params = {
      clientId: values.scanType === 'licensor' ? (licensor && licensor[0] ? licensor[0].id : null) : clientId,
      url_type: values.platform,
      scan_type: values.scanType,
      keyword:  values.scanType === 'keyword' ? values.keywordSearch : null,
      seller_url: values.scanType === 'seller' ? values.sellerUrlSearch : null,
      licensor: values.scanType === 'licensor' ? (licensor && licensor[0] ? licensor[0].shortName : null) : null,
    };
    const cleaned = omitBy(params, isNil);
    const s = await Axios.get('/api/enforcement-bots/start-scan', { params: cleaned });
    if (s.data === 'Scan already in progress.') {
      alert('Scan already in progress');

    }
    getWebBotSellers();
    setSaving(false);
    setCreateScanModalShown(false);
  };

  const updateAdmin = async (seller: WebBotSeller, adminId: number) => {
    const sellerIndex = sellers.findIndex(v => v.id === seller.id);
    const admin = adminUsers.find(u => Number(u.id) === adminId);
    if (sellerIndex !== -1) {
      const newSellers = [...sellers];
      if (admin) {
        newSellers[sellerIndex].owner = admin;
      } else {
        newSellers[sellerIndex].owner = null;
      }
      setSellers(newSellers);
    }
    setDisabledAdminSelects(disabledAdminSelects.concat(seller.id));
    await Axios.post(`/api/enforcement-bots/sellers/${seller.id}/admin`, adminId ? { admin: adminId } : undefined);

    setDisabledAdminSelects(disabledAdminSelects.splice(disabledAdminSelects.indexOf(seller.id), 1));

  };

  const initialValues = {
    platform: 'all',
    scanType: '',
    keywordSearch: '',
    sellerUrlSearch: '',
    licensorSearch: new AffinityClient(),
  };

  const validation = Yup.object({
    platform: Yup.string().min(1).required('A platform name is required'),
    scanType: Yup.string().min(1).required('A scan type is required'),
    keywordSearch: Yup.string().when('scanType', { is: 'keyword', then: Yup.string().min(1).required('A keyword is required') }),
    sellerUrlSearch: Yup.string().when('scanType', { is: 'seller', then: Yup.string().min(1).required('A seller URL is required') }),
    licensorSearch: Yup.string().when('scanType', { is: 'licensor', then: Yup.string().min(1).required('A licensor is required') }),
  });

  const createScanModalText = saving ? 'Submitting...' : 'Request Scan';

  const statusColors = {
    1: 'black', // 'Potential'
    2: '#28a745', // 'Current'
    3: 'black', // 'Cancelled'
    4: 'black', // 'Registered'
    5: '#e06026', // 'Illegal'
    6: 'black', // 'Out of Business'
    7: 'black', // 'Non-Producing'
    8: 'black', // 'One-Time'
    10: 'black', // 'Declined'
    11: 'black', // 'Non-Issue'
    12: 'black', // 'Retailer'
  };

  const backupImage = new AffinityImage();
  backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';

  const html =
    <FullContent>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ maxWidth: '50%' }}>
        <h3><strong>Scans</strong></h3>
      </div>
      <div style={{ maxWidth: '50%' }}>
        <button className="btn btn-default" onClick={getScanHistoryPage}>Scan History</button>
        <button className="btn btn-primary" onClick={() => setCreateScanModalShown(true)} style={{ marginLeft: 5 }}>New Scan</button>
      </div>
    </div>
      <FilterBar
        updateFilters={updateFilters}
        usePerPage={false}
        useQuarter={false}
        useSearch={true}
        useDates={false}
        // startDate={startDate}
        // endDate={endDate}
        search={search}
        perPage={10}
        fieldClassOverrides={{ search: 'address-search-bar col-lg-3 col-sm-3 col-xs-12' }}
      >

        <div className="pull-right" style={{ marginRight: 10 }}>
          <select
            className="form-control"
            name="scanFilter"
            onChange={event => changeAccountFilter(event)}
            value={selectedFilter}
          >
            <option key="allaccounts" value="">Account Filter (All)</option>
            <option key="current"  value="current">Current Accounts</option>
            <option key="noncurrent"  value="noncurrent">Non-Current Accounts</option>
            <option key="unknown"  value="unknown">Unknown Accounts</option>
          </select>
        </div>
        <div className="pull-right" style={{ marginRight: 10 }}>
          <select
            className="form-control"
            name="scanSort"
            onChange={event => changeScanSort(event)}
            value={selectedSort}
          >
            <option key="recent" value="recent">Most Recent</option>
            <option key="alerts"  value="alerts">Licensor Alerts</option>
            <option key="total"  value="total">Total Products</option>
          </select>
        </div>
        <div className="pull-right" style={{ marginRight: 10 }}>
          <select
            className="form-control"
            name="sellerFiler"
            onChange={event => changeSellerFilter(event)}
            value={sellerFilter}
          >
            <option key="recent" value="">All Sellers</option>
            <option key="alerts"  value="open">Open Sellers</option>
            <option key="total"  value="closed">Closed Sellers</option>
          </select>
        </div>
        <div className="pull-right" style={{ marginRight: 10 }}>
          <select
            className="form-control"
            name="adminFilter"
            onChange={event => changeAdminFilter(event)}
            value={adminFilter}
          >
            <option value="">All Users</option>
            {adminUsers.map(u => <option key={u.id} value={u.id}>{u.fullName}</option>)}
          </select>
        </div>
      </FilterBar>

    {loading ? <LoadingSpinner /> : <div style={{ marginTop: 15 }} className="panel panel-portal">
      <div className="table-responsive">
        <table className="table table-portal table-hover">
          <thead>
            <tr>
              <th>Status</th>
              <th style={{ minWidth: 150 }}>Seller</th>
              <th style={{ minWidth: 150 }}>Account</th>
              <th>Admin</th>
              <th>Account Status</th>
              <th>Link</th>
              <th>Total Products</th>
              <th>Licensor Alerts</th>
              <th>Last Scan</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {sellers.map((s:WebBotSeller) => (
            <tr key={s.id}>
              <td>{s.closedAt ? <span className="label label-primary">Closed</span> : <span className="label label-danger">Open</span>}</td>
              <td>
                <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                  { s.image ?
                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                      <img style={{ width: 35, height: 35 }} src={s.image.getSize('sm')} />
                      <span style={{ marginLeft: 10 }}>
                        {s.platformUsername}
                      </span>
                    </div> : <span className="text-muted">Account Not Found</span>}
                </Link>
              </td>
              <td>
                <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                  <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <img style={{ width: 35, height: 35 }} src={s.linkedAccount ? s.linkedAccount.logo.getSize('sm') : backupImage.getSize('sm')} />
                    <span style={{ marginLeft: 10 }}>
                        {s.linkedAccount ? s.linkedAccount.accountName : <span className="text-muted">Account Not Found</span>}
                      </span>
                  </div>
                </Link>
              </td>
              <td style={{ minWidth: 125 }}>
                <select disabled={disabledAdminSelects.indexOf(s.id) !== -1} className="form-control" value={s.owner ? s.owner.id : 0} onChange={e => updateAdmin(s, Number(e.target.value))}>
                  <option value={0}>-</option>
                  {adminUsers.map(u => (
                    <option key={u.id} value={u.id}>
                      {u.firstName} {u.lastName}
                    </option>
                   ))}
                </select>
                {/* {s.owner ?
                  <OverlayTrigger
                    trigger="hover"
                    placement="top"
                    overlay={(
                      <Tooltip id={`owner-name-${s.owner.id}-${s.id}`}>
                        {s.owner.fullName}
                      </Tooltip>
                    )}
                  >
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <GatewayImage image={s.owner.image} size="th" width={30} height={30} borderRadius={30} />
                    </div>
                  </OverlayTrigger>
                  : null} */}
              </td>
              <td>
                <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                  { s.linkedAccount ? <span style={{ color: statusColors[s.linkedAccount.status.id] }}>{s.linkedAccount.status.display}</span> : '-' }
                </Link>
              </td>
              <td>
                <a target="_blank" href={s.sellerUrl}>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`${s.id}-platorm`}>{s.sellerUrl}</Tooltip>}
                  >
                    <span><i className="fab fa-etsy"></i></span>
                  </OverlayTrigger>
                </a>
              </td>
              <td>
                <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                  { s.scanRequest && s.scanRequest.startTime && s.scanRequest.endTime ? s.urlCount : <span>-</span> }
                </Link>
              </td>
              <td>
                <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                { s.scanRequest && s.scanRequest.startTime && s.scanRequest.endTime ? <span className={ s.licensorAlerts > 0 ? 'text-danger' : ''}>{s.licensorAlerts}</span> : <span>-</span> }
                </Link>
              </td>
              <td>
                <Link style={{ color: 'black' }} to={getScanSellerDetailPageLink(s.id)}>
                { s.scanRequest ? s.scanRequest.createdAt.format('MMM DD, YYYY') : '-' }
                </Link>
              </td>
              <td>
                {s.scanRequest && (s.scanRequest.isInProgress || !s.scanRequest.startTime) ?
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id={`${s.id}-progress`}>{s.scanRequest.isInProgress ? 'Scan In Progress' : 'Scan Not Started'}</Tooltip>}
                  >
                    <span>
                      <FontAwesomeIcon icon={faRadar} className={s.scanRequest.isInProgress ? 'text-primary' : 'text-muted'} />
                    </span>
                  </OverlayTrigger>
                : null}
              </td>

            </tr>
          ))}

          </tbody>
        </table>
      </div>

    </div>}

    {loading && pagination ? null : <PaginationFooter
      totalResults={pagination.total}
      hasNext={!pagination.lastPage}
      currentPage={pagination.page}
      setPage={setPage}
      totalPages={pagination.totalPages}
      showFirstLast={true}
    />}
      <GatewayModal shown={createScanModalShown} type={ModalType.Primary} onClose={hideCreateModal} title="New Scan" >
        <Modal.Body>
          <Formik
            onSubmit={ (values, formProps) => saveScanRequest(values, formProps)}
            validationSchema={validation}
            validateOnBlur={false}
            initialValues={initialValues}>
            {formProps =>
              (
                <div>
                  <Form id="scan-request-form">
                    <div className="form-group">
                      <label htmlFor="platform">Platform</label>
                      <Field component="select"
                             name="platform"
                             className="form-control"
                      >
                        <option value="all">All Platforms</option>
                        <option value="2">Etsy</option>
                      </Field>
                      <p className="text-danger">
                        <ErrorMessage name="platform" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="scanType">Scan Type</label>
                      <Field component="select"
                             name="scanType"
                             className="form-control"
                      >
                        <option value="" disabled={true}>Select Scan Type</option>
                        <option value="keyword">Keyword</option>
                        <option value="licensor">Licensor</option>
                        <option value="seller">Seller</option>
                      </Field>
                    </div>
                    { formProps.values.scanType === 'keyword' ?
                      <div className="form-group">
                        <label htmlFor="keywordSearch">Keyword
                        </label>
                        <Field placeholder="Keyword" name="keywordSearch" className="form-control"/>
                        <p className="text-danger">
                          <ErrorMessage name="keywordSearch" />
                        </p>
                      </div>
                      : null }
                    { formProps.values.scanType === 'seller' ?
                      <div className="form-group">
                        <label htmlFor="keywordSearch">Seller URL
                        </label>
                        <Field placeholder="Seller URL" name="sellerUrlSearch" className="form-control"/>
                        <p className="text-danger">
                          <ErrorMessage name="sellerUrlSearch" />
                        </p>
                      </div>
                      : null }

                    {formProps.values.scanType === 'licensor' ?
                      <div className="form-group">
                        <label htmlFor="licensorSearch">Licensor
                        </label>
                        <LicensorTypeahead selected={licensor} onChange={setLicensor} multiple={false} />
                        <p className="text-danger">
                          <ErrorMessage name="licensorSearch" />
                        </p>
                      </div>
                      : null}
                  </Form>
                </div>
              )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideCreateModal} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" form="scan-request-form" className="btn btn-primary pull-right" disabled={saving}>{createScanModalText}</button>
        </Modal.Footer>
      </GatewayModal>

    </FullContent>;

  return html;
};
