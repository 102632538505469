
export interface AffinityAddressAPIResponse {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
}

export class AffinityAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;

  get singleLine() {
    if (this.line1) {
      return `${this.line1}, ${this.line2 ? `${this.line2}, ` : ''}${this.city}, ${this.state} ${this.zip}`;
    }
    return '';
  }

  static fromApi(data: AffinityAddressAPIResponse) {
    const address = new AffinityAddress();
    address.line1 = data.line1;
    address.line2 = data.line2;
    address.city = data.city;
    address.state = data.state;
    address.zip = data.zip;
    address.phone = data.phone;
    return address;
  }

}
