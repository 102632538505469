import Axios from 'axios';
import * as queryString from 'query-string';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { BrandMarkAsset, Filters } from '../../shared';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { DateCell, FilterBar, LoadingSpinner, PaginationFooter, SortableHeader } from '../shared';
import { BrandListItemThumbnail } from './BrandListItemThumbnail';

interface IState {
  assets: BrandMarkAsset[];
  filters: Filters;
  totalResults: number;
  lastPage: number;
  loading: boolean;

}

export class BrandsListPage extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);
    const filters = new Filters();
    filters.setQueryParamsFromLocation(this.props.location.search);
    filters.sortBy = 'updated';
    this.state = {
      filters,
      assets: [],
      totalResults: 0,
      lastPage: 1,
      loading: true,
    };

    this.getBrands = this.getBrands.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.setPage = this.setPage.bind(this);
  }

  componentDidMount() {
    this.getBrands();
  }

  getBrands() {
    this.setState({ assets: [], loading: true });
    Axios.get(`/api/brand-assets?${queryString.stringify(this.state.filters.getQueryParams())}`)
      .then((response) => {
        this.setState({
          assets: response.data.data.map((data: any) => new BrandMarkAsset(data)),
          lastPage: response.data.meta.pagination.page_count,
          totalResults: response.data.meta.pagination.total_count,
          loading: false,
        });
      })
      .catch(error => this.setState({ loading: false }));
  }

  sort() {

  }

  updateFilters(filters: any, performSearch: boolean) {

    // const newFilters = {...this.state.filters, ...filters};
    const updatedFilters = this.state.filters;
    updatedFilters.setFilters(filters);
    this.setState({ filters: updatedFilters });

    if (performSearch) {
      const baseUrl = this.props.location.pathname;
      this.props.history.replace(`${baseUrl}?${queryString.stringify(updatedFilters.getQueryParams())}`);
      this.getBrands();
    }

  }

  setPage(page: number) {
    this.updateFilters({ page }, true);
  }

  render() {

    const rows = this.state.assets.map((asset) => {
      const accountImage = (asset.account && asset.account.image && asset.account.image.urls.th) || '';

      return <tr key={asset.id}>
        <td>
          <Link className="load-account" style={{ zIndex: 2 }} to={`/clients/${asset.account.id}/brand/assets`} >
            <img className="pull-left hidden-sm hidden-xs" src={accountImage} />
            <strong>{asset.account.name}</strong>
          </Link>
        </td>
        <td>
          <Link className="load-account" style={{ zIndex: 2 }} to={`/clients/${asset.account.id}/brand/assets`} >
            <BrandListItemThumbnail asset={asset} />
            <strong>{asset.title}</strong>
          </Link>
        </td>

        <td style={{ width: 115, padding: 0 }}>
          <DateCell date={asset.updatedAt}/>
        </td>

      </tr>;
    });
    let tableContent;
    if (this.state.loading) {
      tableContent = <LoadingSpinner /> ;

    } else {
      tableContent =
        <div className="table-responsive">
          <table className="table table-portal table-hover">
            <thead>
              <tr>
                <SortableHeader
                  title="Organization"
                  disableSort={true}
                  sortSelectFn={this.sort}
                  sortDir="desc"
                  sortKey="account"
                />
                <SortableHeader title="Item" disableSort={true} sortSelectFn={this.sort} sortDir="desc" sortKey="item" />
                <SortableHeader
                  title="Updated"
                  disableSort={true}
                  sortSelectFn={this.sort}
                  sortDir="desc"
                  sortKey="updated"
                />
              </tr>

            </thead>
            <tbody>
              {rows}
            </tbody>

          </table>

        </div>;

    }
    return <FullContent breadcrumbs={[{ name: 'Brand' }]}>
        <DocumentTitle title={getAffinityPageTitle('Brand')}>
        <div>
        <div className="panel panel-portal">
          <div className="panel-body" style={{ background: '#f9f9f9', padding: '5px' }}>

            <FilterBar
              updateFilters={this.updateFilters}
              usePerPage={true}
              useQuarter={false}
              useSearch={true}
              search={this.state.filters.search}
              perPage={this.state.filters.perPage}

            />
          </div>
        </div>
          <div className="panel panel-portal">

          {tableContent}

          </div>
          <PaginationFooter
            setPage={this.setPage}
            currentPage={this.state.filters.page}
            totalResults={this.state.totalResults}
            hasNext={this.state.lastPage > this.state.filters.page} />
        </div>

      </DocumentTitle>
    </FullContent>;
  }

}
