import Axios from 'axios';
import * as querystring from 'query-string';
import * as React from 'react';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { RetailerPerformance, usePerformanceQueryParams } from '.';
import { FullContent } from '../ContentFrame';
import { PaginationFooter, useCancelToken } from '../shared';
import { PerformanceSummaryRow } from './PerformanceSummaryRow';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { CategoryPerformance, ChannelPerformance, PerformanceStats, VendorPerformance } from './submodules';
import { GeolocationNoMapPerformance } from './submodules/GeolocationNoMapPerformance';
import { apiToPerformanceStats, DefaultPerformanceStats, VendorRetailerStats } from './submodules/shared';
import TopSellingDesignRow from './TopSellingDesignRow';

export const LicenseesPerformancePage = () => {
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState(true);
  const [loadingStats, setLoadingStats] = React.useState(true);
  const [totals, setTotals] = React.useState({
    sales: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    royalties: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    units: { previous: { total: 0, filtered: 0 }, current: { total: 0, filtered: 0 } },
    avgUnitSales: { current: 0, previous: 0 },
    avgUnitRoyalties: { current: 0, previous: 0 },
  });

  const location = useLocation();
  const routeParams = useParams();
  const query = querystring.parse(location.search);
  const performanceQP = usePerformanceQueryParams();
  const cancelToken = useCancelToken();
  const isFiltered = query['vendor'] ? true : false;

  React.useEffect(() => {
    getData();
    // We're gettingvendors automatically because page is also set
    // getVendors();

    performanceQP.scrollToTop();
  },              [location.search]);

  React.useEffect(() => {
    getVendors();
    performanceQP.scrollToTop();
  },              [page]);

  const getData = async () => {
    setLoadingStats(true);
    cancelToken.cancelToken();
    const newCancelToken = Axios.CancelToken.source();
    cancelToken.setToken(newCancelToken);

    const queryParams = performanceQP.toApi('licensee', [
      { label: 'year', value: null },
    ]);

    try {
      const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`, {
        cancelToken: newCancelToken.token,
      });

      if (analytics) {
        const data = analytics.data.data;
        const s = apiToPerformanceStats(data);
        setTotals({
          sales: {
            previous: { total: Number(data.totals.yoy_total_sales), filtered: Number(data.totals.yoy_total_sales) },
            current: { total: Number(data.totals.total_sales), filtered: Number(data.totals.total_sales) },
          },
          royalties: {
            previous: { total: Number(data.totals.yoy_total_royalties), filtered: Number(data.totals.yoy_total_royalties) },
            current: { total: Number(data.totals.total_royalties), filtered: Number(data.totals.total_royalties) },
          },
          units: {
            previous: { total: Number(data.totals.yoy_total_units), filtered: Number(data.totals.yoy_total_units) },
            current: { total: Number(data.totals.total_units), filtered: Number(data.totals.total_units) },
          },
          avgUnitSales: {
            previous: Number(data.totals.yoy_avg_unit_sales),
            current: Number(data.totals.avg_unit_sales),
          },
          avgUnitRoyalties: {
            previous: Number(data.totals.yoy_avg_unit_royalties),
            current: Number(data.totals.avg_unit_royalties),
          },
        });
        setStats(s);

        setLoadingStats(false);
      }
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching data:', error);
      }
    }
  };

  const getVendors = async () => {
    cancelToken.cancelToken();
    const newCancelToken = Axios.CancelToken.source();
    cancelToken.setToken(newCancelToken);

    setLoading(true);
    const queryParams = performanceQP.toApi('licensee', [
      { label: 'year', value: null },
      { label: 'page', value: page },
    ]);

    try {
      const analytics = await Axios.get(`/api/performance-reports/vendors?${queryParams}`, {
        cancelToken: newCancelToken.token,
      });

      const vendors: VendorRetailerStats[] = analytics.data.data.vendors.map((v: any) => ({
        name: v.title,
        id: v.id,
        image: v.image,
        amount: Number(v.amount),
        previousAmount: v.previous ? Number(v.previous.amount) : 0,
        percent: v.percent * 100,
        rpu: Number(v.rpu),
        units: Number(v.quantity),
        sales: Number(v.gross_sales),
      }));

      setStats(prevStats => ({
        ...prevStats,
        vendors,
        isCurrent: analytics.data.data.is_current,
      }));

      setTotalPages(analytics.data.data.totalPages);
      setLoading(false);
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log('Request canceled:', error.message);
      } else {
        console.error('Error fetching vendors:', error);
      }
    }
  };

  const getAccountID = () => {
    return routeParams['vendorId'] || routeParams['licensorId'] || '';
  };

  const calculatePercentage = (current: number, previous: number) => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  const salesPercentage = calculatePercentage(totals.sales.current.filtered, totals.sales.previous.filtered);
  const royaltiesPercentage = calculatePercentage(totals.royalties.current.filtered, totals.royalties.previous.filtered);
  const unitsPercentage = calculatePercentage(totals.units.current.filtered, totals.units.previous.filtered);
  const avgUnitSalesPercentage = calculatePercentage(totals.avgUnitSales.current, totals.avgUnitSales.previous);
  const avgUnitRoyaltiesPercentage = calculatePercentage(totals.avgUnitRoyalties.current, totals.avgUnitRoyalties.previous);

  const changePage = (p: number) => {
    setLoading(true);
    setPage(p);
  };

  const showTopSellingDesignRow = query.vendor || query.retailer || query.category || query.channel;

  let additionalGraphs;
  if (isFiltered) {
    additionalGraphs = (
      <div>
        {stats.salesDataDisplayable ? (
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <CategoryPerformance
                      categories={stats.categories}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <ChannelPerformance
                      channels={stats.channels}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <RetailerPerformance
                      retailers={stats.retailers}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <GeolocationNoMapPerformance/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <FullContent>
      <PerformanceTitleFilters
        title="Licensees"
        subTitle="Sales by reported licensee."
        isAggregate={stats.isAggregate}
        isCurrent={stats.isCurrent}
        hideQuarter={true}
        hideYear={true}
        loading={loading && loadingStats}
      />
      <PerformanceSummaryRow
        loading={loadingStats}
        totals={totals}
        salesPercentage={salesPercentage}
        royaltiesPercentage={royaltiesPercentage}
        unitsPercentage={unitsPercentage}
        avgUnitSalesPercentage={avgUnitSalesPercentage}
        avgUnitRoyaltiesPercentage={avgUnitRoyaltiesPercentage}
        isCurrent={stats.isCurrent}
      />
      {showTopSellingDesignRow && (
        <div className="row">
          <TopSellingDesignRow
            accountId={getAccountID()}
            hideIfNoResults={true}
          />
        </div>
      )}
      <div className="panel panel-portal">
        <div className="panel-body">
          <VendorPerformance
            vendors={stats.vendors}
            loading={loading || loadingStats}
            large={true}
            isAggregate={stats.isAggregate}
            page={page}
          />
        </div>
      </div>
      {!isFiltered ? <PaginationFooter hasNext={totalPages > page} currentPage={page} setPage={changePage} /> : null}
      {additionalGraphs}
    </FullContent>
  );
};
