import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import * as React from 'react';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { LoadingSpinner, ProductCategoryTypeahead } from '../../shared';
import { useRoyaltyReport } from '../royalties-shared/useRoyaltyReport';

export const RoyaltyReportItemTypeMappingPage = () => {
  const report = useRoyaltyReport();
  const match = useRouteMatch();
  const history = useHistory();
  const user = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [unmapped, setUnmapped] = React.useState<{ name: string, count: number }[]>([]);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    getUnmapped();

  },              []);

  if (!match) {
    return <Redirect to="/" />;
  }

  const getUnmapped = async () => {
    setLoading(true);
    const t = await Axios.get(`/api/royalty-reports/${report.reportId}/category-mappings`);
    setUnmapped(t.data.data);
    setLoading(false);

  };

  const getQuantity = (name: string) => {
    const q = unmapped.find(u => u.name === name);
    if (q) {
      return q.count;
    }
    return 0;

  };

  const onSubmit = async (values: any) => {
    setSubmitting(true);
    const accountId = user.type === 'vendor' ? user.account.id : match.params['vendorId'];
    const itemTypeMappings = values.itemTypeMappings.map((mapping: any) => ({
      product: mapping.product[0].id,
      identifier: mapping.identifier,
    }));

    const data = {
      itemTypeMappings,
      account_id: accountId,
      report_id: report.reportId,
    };

    await Axios.post('/api/item-types', data);
    history.push('sales-data');

  };

  if (loading) {
    return <LoadingSpinner />;
  }
  const validation = Yup.object({
    itemTypeMappings: Yup.array().of(
      Yup.object({
        identifier: Yup.string().min(1).required('An item type is required'),
        product: Yup.array().min(1, 'A category is required'),
      }),
    ).min(1),
  });

  return (
    <div className="panel panel-portal">
      <div className="panel-body">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15 }}>
          <Link to={`/vendors/${match.params['vendorId']}/royalties/${match.params['id']}`} className="btn btn-primary"><FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
          <h4><strong>Unrecognized Item Types</strong></h4>
          <div></div>

        </div>
        <p className="text-muted">
          The following Item Types are not recognized.
          If an Item Type can be linked to a single Affinity Product Category, you can save a new mapping rule.
          If an Item Type lacks sufficient detail, and could represent more than one Affinity Product Category, it is invalid.
          To edit invalid Item Types or provide an Affinity Product Category in your sales data, go back and upload a new sales data file.
        </p>

        <Formik
          onSubmit={onSubmit}
          validationSchema={validation}
          validateOnBlur={false}
          initialValues={{
            itemTypeMappings: unmapped.map((u) => {
              return { identifier: u.name, product: [] };
            },
            ),
          }}>
          {formProps =>
          (
            <div>
              <Form id="item-type-form">
                <FieldArray name="itemTypeMappings" render={helpers => (
                  <>
                    {formProps.values.itemTypeMappings.map((u: any, index: number) => (
                      <div className="row" key={index}>
                        {index > 0 ? <hr /> : null}
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="identifier">Item Type</label>
                            <Field disabled placeholder="Item Type" name={`itemTypeMappings.${index}.identifier`} className="form-control" />
                            <p className="text-muted">
                              {getQuantity(u.identifier)} order(s)
                            </p>
                            <p className="text-danger">
                              <ErrorMessage name={`itemTypeMappings.${index}.identifier`} />
                            </p>
                          </div>
                        </div>
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label>Category</label>
                            <ProductCategoryTypeahead
                              multiple={false}
                              selected={formProps.values.itemTypeMappings[index].product}
                              onChange={c => formProps.setFieldValue(`itemTypeMappings.${index}.product`, c)}
                              showMarketingMaterials={false}
                            />
                            <p className="text-danger">
                              <ErrorMessage name={`itemTypeMappings.${index}.product`} />
                            </p>
                          </div>
                        </div>
                      </div>

                    ))}

                    <div className="panel-footer" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
                      <button className="btn btn-primary" type="submit">{submitting ? 'Saving...' : 'Save and Continue'} <FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                  </>
                )}
                />
              </Form>
            </div>
          )}
        </Formik>

      </div>
    </div>
  );

};
