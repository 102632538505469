import Axios from 'axios';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { HiddenGeoDataModule, HiddenSalesDataModule } from '.';
import { getProductCategories } from '../../api';
import { ProductCategory, ProductCategoryParentMapping } from '../../shared';
import { formattedFiscalYear } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CategoryModal, StateModal, useCancelToken } from '../shared';
import { PerformanceTitleFilters } from './PerformanceTitleFilters';
import { CategoryPerformance, ChannelPerformance, DefaultPerformanceStats, GeolocationPerformance, PerformanceStats, RetailerPerformance,  VendorPerformance } from './submodules';
import { apiToPerformanceStats } from './submodules/shared';
import { AdditionalQP, usePerformanceQueryParams } from './usePerformanceQueryParams';

export const PerformanceExplorerPage = () => {
  const [stats, setStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [loading, setLoading] = React.useState(true);
  const [quarter, setQuarter] = React.useState('All');
  const [year, setYear] = React.useState(formattedFiscalYear((new Date).getFullYear()));
  const [category, setCategory] = React.useState<ProductCategory | null>(null);
  const [categories, setCategories] = React.useState<ProductCategoryParentMapping[]>([]);
  const [categoryModalShown, setCategoryModalShown] = React.useState(false);
  const [state, setState] = React.useState('');
  const [stateModalShown, setStateModalShown] = React.useState(false);
  const performanceQP = usePerformanceQueryParams();
  const history = useHistory();
  const cancelToken = useCancelToken();

  React.useEffect(() => {
    getData();
    performanceQP.scrollToTop();

  },              [location.search]) ;

  React.useEffect(() => {
    getCategories();

  },              []);

  const getData = async () => {

    if (cancelToken) {
      cancelToken.cancelToken();
    }
    setLoading(true);
    setStats(DefaultPerformanceStats);
    const token = Axios.CancelToken.source();
    cancelToken.setToken(token);
    const queryParams = performanceQP.toApi('geo');
    const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`, { cancelToken: token.token })
      .catch((e) => {
        setLoading(false);
        cancelToken.setToken(null);
      });
    if (analytics) {
      const s = apiToPerformanceStats(analytics.data.data);
      setStats(s);
      setLoading(false);
    }
    cancelToken.setToken(null);

  };

  const getCategories = async () => {
    const c = await getProductCategories();
    setCategories(c);

  };

  const showResults = () => {
    const params: AdditionalQP[] = [];
    if (category) {
      params.push({ value: category.id, label: 'category' });
    }

    if (state) {
      params.push({ value: state, label: 'state' });
    }
    if (quarter !== 'All') {
      params.push({ value: quarter, label: 'quarter' });
    }
    params.push({ value: year, label: 'year' });

    const qs = performanceQP.toQueryString(params);

    history.push(`${location.pathname}?${qs}`);

  };

  const stepText = (step: number, text: string) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <strong>
          {text}
        </strong>
      </div>
    );
  };
  console.log(performanceQP.isFiltered());

  if (performanceQP.isFiltered()) {

    return (
      <FullContent>
        <PerformanceTitleFilters
          title="Sales Data Explorer"
          subTitle="Review your royalty data to gain new insights."
          isAggregate={false}
          isCurrent={false}
          hideFilters={true}
        />
        <div>
          {/* {!stats.isAggregate ?
            <div className="row">
              <div className="col-md-6">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <RevenueOverTime
                      current={stats.revenueOverTime.current}
                      previous={stats.revenueOverTime.previous}
                      loading={loading}
                      type="sales"
                    />
                  </div>
                </div>

              </div>
              <div className="col-md-6">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <RevenueOverTime
                      current={stats.revenueOverTime.current}
                      previous={stats.revenueOverTime.previous}
                      loading={loading}
                      type="royalties"
                    />
                  </div>
                </div>

              </div>
            </div>
            : null} */}

          {stats.salesDataDisplayable ? <div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <VendorPerformance
                      vendors={stats.vendors}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <CategoryPerformance
                      categories={stats.categories}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <ChannelPerformance
                      channels={stats.channels}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <RetailerPerformance
                      retailers={stats.retailers}
                      loading={loading}
                      large={false}
                      isAggregate={stats.isAggregate}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div> :
            <div className="panel panel-portal">
              <div className="panel-body">
                <HiddenSalesDataModule />
              </div>
            </div>
          }
          {stats.geoDisplayable ? <div>

            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <GeolocationPerformance
                      large={false}
                      loading={loading}
                      states={stats.states}
                      cities={stats.cities}
                      section="map"
                      isAggregate={stats.isAggregate}
                    />

                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <GeolocationPerformance
                      large={false}
                      loading={loading}
                      states={stats.states}
                      cities={stats.cities}
                      section="states"
                      isAggregate={stats.isAggregate}
                    />

                  </div>
                </div>
              </div>
              </div>

            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <GeolocationPerformance
                      large={false}
                      loading={loading}
                      states={stats.states}
                      cities={stats.cities}
                      section="cities"
                      isAggregate={stats.isAggregate}
                    />

                  </div>
                </div>
              </div>
            </div>

          </div> :
            <div className="panel panel-portal">
              <div className="panel-body">
                <HiddenGeoDataModule />
              </div>
            </div>

          }

        </div>

      </FullContent>
    );
  }

  const years = [];
  const date = new Date();
  let y;
  if (date.getMonth() >= 9) {
    y = date.getFullYear();
  } else {
    y = date.getFullYear() - 1;
  }

  for (let i = 0; i <= (y - 2014); i++) {
    const yearToAdd = 2014 + i;
    const yearValue = formattedFiscalYear(yearToAdd);

    years.push(<MenuItem key={yearValue} eventKey={yearValue} >{yearValue}</MenuItem>);
  }

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          <PerformanceTitleFilters
            title="Sales Data Explorer"
            subTitle="Review your royalty data to gain new insights."
            isAggregate={false}
            isCurrent={false}
            hideFilters={true}
          />
        </div>
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">
          {stepText(1, 'Select the fiscal quarter and year')}
          <div style={{ marginBottom: 20 }}>
            <DropdownButton
              onSelect={(v: any) => setQuarter(v)}
              id="quarter-dropdown"
              bsStyle={'default'}
              title={quarter}
            >
              <MenuItem eventKey="All" > All </MenuItem>
              <MenuItem divider />
              <MenuItem eventKey="Q1"> Q1 </MenuItem>
              <MenuItem eventKey="Q2"> Q2 </MenuItem>
              <MenuItem eventKey="Q3"> Q3 </MenuItem>
              <MenuItem eventKey="Q4"> Q4 </MenuItem>

            </DropdownButton>
            <DropdownButton
              style={{ marginLeft: 10 }}
              id="year-dropdown"
              bsStyle={'default'}
              title={year}
              onSelect={(v: any) => setYear(v)}
            >
              {years}

            </DropdownButton>

          </div>
          {stepText(2, 'Select one or more of the filters')}
          <div style={{ display: 'flex', flexDirection:'column', maxWidth: 250 }}>
            <button style={{ marginBottom: 15 }} className="btn btn-default text-center">
               Licensee
            </button>
            <button style={{ marginBottom: 15 }} onClick={() => setCategoryModalShown(true)} className="btn btn-default text-center">
               {category ? category.name : 'Category'}
            </button>
            <button style={{ marginBottom: 15 }} className="btn btn-default text-center">
               Channel
            </button>
            <button style={{ marginBottom: 15 }} onClick={() => setStateModalShown(true)} className="btn btn-default text-center">
               {state ? state : 'State'}
            </button>
            <button style={{ marginBottom: 15 }} className="btn btn-default text-center">
               City
            </button>
            <button style={{ marginBottom: 15 }} className="btn btn-default text-center">
               Retailer
            </button>
          </div>
          <CategoryModal
            onClose={() => setCategoryModalShown(false)}
            onCategorySelect={(c) => {
              setCategory(c);
              setCategoryModalShown(false);
            }}
            shown={categoryModalShown}
            categories={categories}
          />
          <StateModal
            onClose={() => setStateModalShown(false)}
            onStateSelect={(s) => {
              setState(s);
              setStateModalShown(false);
            }}
            shown={stateModalShown}
          />
          {stepText(3, 'Submit your search to explorer the data')}
          <div>
            <button onClick={showResults} className="btn btn-primary">View Results</button>

          </div>
        </div>
      </div>

    </FullContent>
  );

};
