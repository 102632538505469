import * as moment from 'moment';
import { AccountWebUrlType } from './AccountWebUrl';
import { AffinityClient } from './AffinityClient';
import { WebBotSeller, WebBotSellerApiResponse } from './WebBotSeller';

export interface WebBotScanRequestApiResponse {
  scan_type: string;
  id: number;
  search: string;
  start_time: string | null;
  end_time: string | null;
  total_results: number;
  type: string;
  index_in_results: number;
  platform: AccountWebUrlType;
  progress: number;
  seller_count: number;
  licensor: { id: number; name: string; short_name: string; } | null;
  seller?: WebBotSellerApiResponse | null;
  created_at: string;
  deleted_at: string;
  updated_at: string;
}
export class WebBotScanRequest {

  id: number;
  search: string;
  startTime: moment.Moment | null;
  endTime: moment.Moment | null;
  totalResults: number;
  type: string;
  indexInResults: number;
  scanType: string;
  platform: AccountWebUrlType;
  progress: number;
  sellerCount: number;
  licensor: AffinityClient | null;
  seller: WebBotSeller | null;
  createdAt: moment.Moment;
  deletedAt: moment.Moment | null;
  updatedAt: moment.Moment;

  static fromApi(data: WebBotScanRequestApiResponse) {
    const s = new WebBotScanRequest();
    s.id = data.id;
    s.search = data.search;
    s.startTime = data.start_time ?  moment(data.start_time) : null;
    s.endTime = data.end_time ? moment(data.end_time) : null;
    s.totalResults = data.total_results;
    s.indexInResults = data.index_in_results;
    s.type = data.type;
    s.scanType = data.scan_type;
    s.platform = data.platform;
    s.progress = data.progress;
    s.sellerCount = data.seller_count;
    s.licensor = data.licensor ? new AffinityClient(data.licensor) : null;
    s.seller = data.seller ? WebBotSeller.fromApi(data.seller) : null;
    s.createdAt = moment(data.created_at);
    s.updatedAt = moment(data.updated_at);
    s.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;

    return s;

  }

  get isInProgress() {
    return this.startTime !== null && this.endTime === null;
  }
}
