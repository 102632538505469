import { faCircle, faInfoCircle, faMousePointer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { DistributionChannel } from '../../../shared';
import { toUSD } from '../../../utils';
import { CurrencyLabel } from '../../shared';
import { DistributionChannelsInfoModal } from './DistributionChannelsInfoModal';
import { DistributionChannelStats } from './models';
import { NoSalesDataPlaceholder } from './NoSalesDataPlaceholder';
import { PartialRepresentation } from './PartialRepresentation';

interface IProps {
  filterChannel: (channel: any) => void;
  distributionChannels: DistributionChannelStats[];
  isPartial: boolean;
}

interface IState {
  showAll: boolean;
  modalShown: boolean;
  hoveredChannel: number | null;
}

export class DistributionChannels extends React.Component <IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      showAll: false,
      modalShown: false,
      hoveredChannel: null,
    };
    this.showAll = this.showAll.bind(this);
    this.sliceClick = this.sliceClick.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.sliceHover = this.sliceHover.bind(this);
  }

  showAll() {
    this.setState({ showAll: true });
  }

  dotColor(index: number) {
    const colors = [
      '#4f6980',
      '#849db1',
      '#a2ceaa',
      '#638b66',
      '#bfbb60',
      '#f47942',
      '#fbb04e',
      '#b66353',
      '#d7ce9f',
      '#b9aa97',
      '#7e756d',
    ];

    return colors[index % colors.length];
  }

  sliceClick(element: any, channels: DistributionChannelStats[]) {
    if (element[0]) {
      const index = element[0]._index;
      if (index >= 0) {
        this.props.filterChannel(channels[index].channel);
      }
    }
  }

  sliceHover(event: MouseEvent, element: any, channels: DistributionChannelStats[]) {
    if (event.type === 'mousemove') {
      if (element[0]) {
        const index = element[0]._index;
        if (index >= 0) {
          const channelId = channels[index].channel.id;
          if (channelId !== this.state.hoveredChannel) {
            this.setState({ hoveredChannel: channelId });
          }
        }
      }

    }
    if (event.type === 'mouseout') {
      this.setState({ hoveredChannel: null });
    }

  }

  showModal() {
    this.setState({ modalShown: true });

  }

  hideModal() {
    this.setState({ modalShown: false });
  }

  rowClassName(channel: DistributionChannel) {
    if (this.state.hoveredChannel === channel.id) {
      return 'active';
    }
    return '';

  }

  render() {
    const channels = [... this.props.distributionChannels];
    channels.sort((a, b) => {
      if (a.royalties < b.royalties) {
        return 1;
      }
      if (a.royalties > b.royalties) {
        return -1;
      }
      return 0;
    });

    const pieData = {
      labels: channels.map(channel => channel.channel.name),
      datasets: [{
        data: channels.map(channel =>  channel.royalties),
      }],
    };

    const pieOptions: ChartOptions = {
      legend: {
        display: false,
      },
      onHover: (event, element) => this.sliceHover(event, element, channels),
      tooltips: {
        mode: 'single',
        callbacks: {
          label: (tooltip: any, data: any) => {
            if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
              const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              const category = data.labels[tooltip.index];
              return `${category}: ${toUSD(value)}`;
            }
            return '';
          },

          footer: (tooltipArray: any, data: any) => {
            const tooltip = tooltipArray[0];
            if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
              const totalData = data.datasets[tooltip.datasetIndex].data
                .reduce((total: number, d: number) => total += d, 0);
              const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              const percent = ((value / totalData) * 100).toFixed(2);
              return `${percent}%`;
            }
            return '';

          },
        },
      },

      plugins: {
        colorschemes: {
          scheme: 'tableau.MillerStone11',
        },
      },
    };
    const rowStyle = { cursor: 'pointer' };
    const rows = channels.map((channel, index) =>
      (

        <tr
          className={this.rowClassName(channel.channel)}
          onClick={() => this.props.filterChannel(channel.channel)}
          style={rowStyle}
        >
          <td><FontAwesomeIcon style={{ color: this.dotColor(index) }} icon={faCircle}/>&nbsp;{channel.channel.name}</td>
          <td>
            <NumberFormat  displayType={'text'} thousandSeparator={true} value={channel.units} />
        </td>
          <td><CurrencyLabel value={channel.royalties} /></td>
        </tr>
      ),
    );
    const footer = !this.state.showAll && this.props.distributionChannels.length > 10 ? (
      <div onClick={this.showAll} style={{ cursor: 'pointer' }} className="panel-footer">
        <p className="text-center text-muted small">Show More</p>

      </div>
    )
      : null;

    const content = this.props.distributionChannels.length ?
      (
        <div>
          <div>
            <Pie onElementsClick={event => this.sliceClick(event, channels)} options={pieOptions} data={pieData} />
          </div>
          <div style={!this.state.showAll ? { maxHeight: 407, overflowY: 'hidden' } : {}} className="table-responsive">
            <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Channel <span style={{ cursor: 'pointer' }} onClick={this.showModal}>
                      <FontAwesomeIcon className="text-primary" icon={faInfoCircle} />
                      </span>
                    </th>
                    <th>Units</th>
                    <th>Royalties</th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>

              </table>
            </div>
          {footer}
        </div>
      )
      :
      (
        <NoSalesDataPlaceholder />
      );
    const distChannels = channels.map(stats => stats.channel);

    const partialContent = this.props.isPartial && this.props.distributionChannels.length ?
      (<PartialRepresentation />) : null;

    return (
      <div className="panel panel-portal">
        <div className="panel-body">

          <div className="analytics-header text-primary">Distribution channels</div>
          <div >
            <FontAwesomeIcon icon={faMousePointer} /> <span className="text-muted">Click a channel to narrow analysis</span>
          </div>

          {partialContent}

        </div>
        <div className="panel-body">
          {content}
        </div>
        <DistributionChannelsInfoModal channels={distChannels} onClose={this.hideModal} shown={this.state.modalShown} />

      </div>
    );

  }

}
