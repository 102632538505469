import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import { GatewayModal,  ModalType } from '../shared/modals';
import { createDesignResponse, updateDesignResponse } from './api';
import { DesignResponse } from './DesignResponse';

interface IProps {
  onClose: (response?: DesignResponse) => any;
  shown: boolean;
  values?: DesignResponse;
  accountId:  number;

}

interface IDesignResponseForm {
  title: string;
  body: string;
}

export class DesignResponseModal extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values: IDesignResponseForm) {

    if (this.props.values) {
      updateDesignResponse(values.title, values.body, this.props.values.id)
        .then(response => this.props.onClose(response.data.data));
    } else {
      createDesignResponse(values.title, values.body, this.props.accountId)
        .then(response => this.props.onClose(response.data.data));

    }
  }

  closeModal() {
    this.props.onClose();
  }

  render() {
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      ],
    };

    const validation = Yup.object().shape({
      title: Yup.string().required(),
      body: Yup.string().required(),
    });

    const fields: IDesignResponseForm = {
      title: '',
      body: '',
    };
    if (this.props.values) {
      fields.title = this.props.values.title;
      fields.body = this.props.values.body;
    }

    return (
      <GatewayModal type={ModalType.Primary} onClose={this.closeModal} shown={this.props.shown} title="Design Responses">
        <Formik validationSchema={validation} initialValues={fields} onSubmit={this.submitForm}>
          { (form: FormikProps<IDesignResponseForm>) => <Form >
            <Modal.Body>
              <div className={`form-group ${form.touched.title && form.errors.title ? 'has-error' : ''}`}>
                <label>Title</label>
                <Field name="title" type="text" placeholder="Title" className="form-control" />
              </div>

              <div className="form-group">
                <label>Message</label>
                <ReactQuill
                  style={{ background: 'white' }}
                  value={form.values.body}
                  onChange={body => form.setFieldValue('body', body)}
                  placeholder="Enter Message"
                  modules={modules}
                  className="design-response-quill"
                />

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={this.closeModal} className="btn btn-default pull-left">Cancel</button>
            <button
              type="submit"
              disabled={form.isSubmitting}
              className="btn btn-primary pull-right">
              { form.isSubmitting ? 'Submitting ...' : 'Save'}
            </button>
          </Modal.Footer>
        </Form> }
      </Formik>
      </GatewayModal>
    );

  }

}
