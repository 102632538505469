import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface PerformanceSummaryProps {
  title: string;
  amount: number | string;
  previous: number | string;
  percentage: number;
  isPositive: boolean;
  totalPercentage: number;
  hidePanel?: boolean;
  isCurrent: boolean;
  difference: number | string;
}

export const PerformanceSummaryGridItem: React.FC<PerformanceSummaryProps> = ({ title, amount, previous, percentage, isPositive, totalPercentage, hidePanel, isCurrent, difference }) => {
  const description = `${totalPercentage.toFixed(1)}% of total ${title.toLowerCase()}`;
  const content = (
    <>
      <div className="text-primary performance-summary-title">{title}</div>
      <div style={{ fontSize: '24px', fontWeight: 500, lineBreak: 'anywhere' }}>
        {typeof amount === 'number' ? amount.toLocaleString() : amount}
      </div>
      <div className="text-muted">
        LY {previous}
      </div>
      {isCurrent ? null : (
        <div>
          {percentage !== 0 && (
            <div className={`text-${isPositive ? 'success' : 'danger'}`}>
              <FontAwesomeIcon icon={isPositive ? faCaretUp : faCaretDown} />{' '}
              {isNaN(percentage) ? 'N/A' : percentage.toFixed(1)}%
              {difference ? <span>, {difference}</span> : ''}
            </div>
          )}
          {totalPercentage < 100 && <div className="text-muted">{description}</div>}
        </div>
      )}

    </>
  );

  if (hidePanel) {
    return <div>{content}</div>;
  }

  return (
    <div className="panel panel-portal performance-summary-item">
      <div className="panel-body">
        {content}
      </div>
    </div>
  );
};
