import Axios, { CancelTokenSource } from 'axios';
import { ErrorMessage, Field, Formik, FormikActions, FormikProps } from 'formik';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getMarketplaceSettings, getPayoutAccount, updateMarketplaceSettings, updatePayoutAccount } from '../../../api';
import { MarketplaceSettings, StripeService } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { MarketplacePayoutAccount, PayoutAccount, PayoutAccountStatus, PayoutValidationSchema } from './PayoutAccount';

interface IState {
  settings: MarketplaceSettings;
  payoutAccount: MarketplacePayoutAccount;
  loading: boolean;
  savingDefaults: boolean;
  savingPayout: boolean;
  newPayoutAccount: boolean;
  stripeErrors: any;
}

interface SettingsValues {
  shippingFirst: number;
  shippingAdditional: number;
  fulfillmentDuration: number | string;
  fulfillmentTerm: number | string;
  shippingPolicy: string;
  returnPolicy: string;
}

export class MarketplaceSettingsPage extends React.Component<RouteComponentProps<any>, IState> {
  private _getSettings: CancelTokenSource;
  private _getPayoutSettings: CancelTokenSource;
  private _updateSettings: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      settings: new MarketplaceSettings(),
      payoutAccount: { bank: '', lastFour: 0 }  ,
      savingDefaults: false,
      savingPayout: false,
      newPayoutAccount: false,
      stripeErrors: null,
    };
    this.getCurrentSettings = this.getCurrentSettings.bind(this);
    this.updateDefaults = this.updateDefaults.bind(this);
    this.updatePayoutAccount = this.updatePayoutAccount.bind(this);
  }

  componentDidMount() {
    this.getCurrentSettings();
  }

  getCurrentSettings() {
    if (this._getSettings) {
      this._getSettings.cancel('Cancelled getMarketplaceSettings() due to new XHR');
    }
    if (this._getPayoutSettings) {
      this._getSettings.cancel('Cancelled getPayoutSettings()  due to new XHR');
    }

    this._getSettings = Axios.CancelToken.source();
    this._getPayoutSettings = Axios.CancelToken.source();
    let vendorId = null;
    if (this.props.match.params['vendorId']) {
      vendorId = this.props.match.params['vendorId'];
    }
    Axios.all([getPayoutAccount(vendorId, this._getPayoutSettings), getMarketplaceSettings(vendorId, this._getSettings)])
      .then(Axios.spread((payoutAccountResponse: any, defaultSettingsResponse: any) => {
        this.setState({
          loading: false,
          settings:  MarketplaceSettings.fromApi(defaultSettingsResponse.data.data),
          payoutAccount: payoutAccountResponse.payoutAccount,
        });
      }));
  }

  updateDefaults(values: SettingsValues) {
    const settings = this.state.settings;

    settings.defaultShippingPriceFirst = values.shippingFirst;
    settings.defaultShippingPriceAdditional = values.shippingAdditional;
    settings.defaultDurationQuantity = Number(values.fulfillmentDuration);
    settings.defaultDurationTermId = Number(values.fulfillmentTerm);
    settings.defaultShippingText = values.shippingPolicy;
    settings.defaultReturnText = values.returnPolicy;
    let vendorId = null;
    this.setState({ savingDefaults : true });
    if (this.props.match.params['vendorId']) {
      vendorId = this.props.match.params['vendorId'];
    }
    if (this._updateSettings) {
      this._updateSettings.cancel('Cancelled updateMarketplaceSettings() due to new XHR');
    }
    this._updateSettings = Axios.CancelToken.source();
    updateMarketplaceSettings(vendorId, settings, this._updateSettings)
      .then((response) => {

        this.setState({ savingDefaults: false });
      });
  }

  updatePayoutAccount(values: any, actions: FormikActions<any>) {
    const stripe = new StripeService();
    stripe.generateBankToken(
      {
        accountHolderType: values.businessType,
        accountHolderName: `${values.firstName} ${values.lastName}`,
        accountNumber: values.accountNumber,
        routingNumber: values.routingNumber,
      },
      (status: any, response: any) => {
        if (status === 200) {
          let vendorId = null;
          if (this.props.match.params['vendorId']) {
            vendorId = this.props.match.params['vendorId'];
          }
          updatePayoutAccount({ vendor_id: vendorId, external_account: response.id }, Axios.CancelToken.source())
            .then((response) => {
              this.setState({ payoutAccount: response.data.data, newPayoutAccount: false });
            });
        } else {
          const error = response.error.message;
          this.setState({ stripeErrors: error });
          actions.setSubmitting(false);
        }

      });

  }

  render() {
    const settings = this.state.settings;
    const payoutAccount = this.state.payoutAccount;
    const accountStatus = this.state.newPayoutAccount ? PayoutAccountStatus.NotSubmitted : PayoutAccountStatus.Submitted;

    let body;
    if (this.state.loading) {
      body = (<LoadingSpinner />);
    } else {
      const defaultSettingsInitialValues: SettingsValues = {
        shippingFirst: settings.defaultShippingPriceFirst,
        shippingAdditional: settings.defaultShippingPriceAdditional,
        fulfillmentDuration: settings.defaultDurationQuantity ? settings.defaultDurationQuantity : '',
        fulfillmentTerm: settings.defaultDurationTermId ? settings.defaultDurationTermId : '',
        shippingPolicy: settings.defaultShippingText,
        returnPolicy: settings.defaultReturnText,
      };
      const payoutAccountInitalValues = {
        accountNumber: '',
        verifyAccountNumber: '',
        routingNumber: '',
      };

      const payoutAccountBody = payoutAccount ? (
        <Formik
          validationSchema={PayoutValidationSchema}
          initialValues={payoutAccountInitalValues}
          onSubmit={this.updatePayoutAccount}
        >
          {(payoutFomikProps: FormikProps<any>) => (
            <form onSubmit={payoutFomikProps.handleSubmit}>
              <PayoutAccount
                error={this.state.stripeErrors}
                formProps={payoutFomikProps}
                account={payoutAccount} status={accountStatus}
              />
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }} className="button-row">
                {this.state.newPayoutAccount ? <button type="submit" className="btn btn-primary">Save Payout Account</button>
                  : <button
                      type="button"
                      onClick={() => this.setState({ newPayoutAccount: true })}
                      className="btn btn-primary"
                    >
                      Change Payout Account
                    </button>
                }
              </div>
            </form>

          )}
        </Formik>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <h4 className="text-primary">Marketplace Sign Up</h4>
          <p>You have not signed up for the Marketplace.</p>
          <Link to="/marketplace/signup" className="btn btn-lg btn-primary">Sign Up Here</Link>
        </div>
      ) ;

      const defaultSettingsBody = (
        <Formik initialValues={defaultSettingsInitialValues} onSubmit={this.updateDefaults}>
          {(formikProps: FormikProps<SettingsValues>) => (
            <form onSubmit={formikProps.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <label>Shipping Costs</label>
                </div>
                <div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>First Item</label>
                      <Field name="shippingFirst" type="text" className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name="shippingFirst" />
                      </p>

                    </div>

                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Additional Items</label>
                      <Field name="shippingAdditional" type="text" className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name="shippingAdditional" />
                      </p>

                    </div>

                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Fulfillment Expectations</label>
                <div className="row">
                  <div className="col-md-6">
                    <Field name="fulfillmentDuration" component="select" className="form-control" >
                      <option value={''} disabled>Select 1-6</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                    </Field>
                    <p className="text-danger">
                      <ErrorMessage name="fulfillmentDuration" />
                    </p>
                  </div>
                  <div className="col-md-6">
                    <Field name="fulfillmentTerm" component="select" className="form-control" >
                      <option value={''} disabled>Select day(s)/week(s)</option>
                      <option value={1}>Day(s)</option>
                      <option value={2}>Week(s)</option>

                    </Field>
                    <p className="text-danger">
                      <ErrorMessage name="fulfillmentTerm" />
                    </p>
                  </div>
                </div>

              </div>
              <div className="form-group">
                <label>Shipping Policy</label>
                <Field name="shippingPolicy" rows="5" className="form-control" component="textarea" > </Field>
                <p className="text-danger">
                  <ErrorMessage name="shippingPolicy" />
                </p>
              </div>
              <div className="form-group">
                <label>Return Policy</label>
                <Field name="returnPolicy" rows="5" className="form-control" component="textarea" > </Field>
                <p className="text-danger">
                  <ErrorMessage name="returnPolicy" />
                </p>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row-reverse' }} className="button-row">
                <button type="submit" className="btn btn-primary">{this.state.savingDefaults ? 'Saving...' : 'Save Marketplace Defaults'}</button>
              </div>

            </form>
          )

          }

        </Formik>
      );
      body = (
        <div>
          {payoutAccountBody}
          <hr />
          {defaultSettingsBody}
        </div>
      );
    }
    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            {body}

          </div>
        </div>

      </FullContent>
    );
  }

}
