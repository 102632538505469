import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faChevronRight, faCircle, faExclamationCircle, faLock, faQuestionCircle, faRedo, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import { getMarketplaceTags } from '../../../api';
import { UserContext } from '../../../contexts';
import { MarketplaceProduct, MarketplaceTag } from '../../../shared';
import { GatewayModal, LoadingSpinner, ModalType, VendorInfo } from '../../shared';

interface IProps {
  product: MarketplaceProduct;
  nextProduct: number;
  nextProductQueryString: string;
  archive: () => void;
  toggleStayArchived: () => void;
  review: (status: 'approve' | 'reject', message?: string) => void;
  setPublished: (isPublished: boolean) => void;
  setPendingReview: (isPendingReview: boolean) => void;
  addTag: (tag: MarketplaceTag) => void;
  removeTag: (tag: MarketplaceTag) => void;
  isGallery: boolean;
  error: string;
  setInternalRating: (rating: number) => void;
}

interface IState {
  message: string;
  isRejecting: boolean;
  tags: MarketplaceTag[];
  tagSearch: string;
  loadingTagSearch: boolean;
  tagModalShown: boolean;
}

export class ProductSidebar extends React.Component<IProps, IState> {
  private _getTags: CancelTokenSource;

  constructor(props: IProps) {
    super(props);
    this.state = {
      message: '',
      isRejecting: false,
      tags: [],
      tagSearch: '',
      loadingTagSearch: false,
      tagModalShown: false,
    };
    this.isPublished = this.isPublished.bind(this);
    this.isPendingReview = this.isPendingReview.bind(this);
    this.setRejection = this.setRejection.bind(this);
    this.getTags = this.getTags.bind(this);
    this.searchOnChange = this.searchOnChange.bind(this);
    this.toggleModalShown = this.toggleModalShown.bind(this);
    this.searchTagsKeypress = this.searchTagsKeypress.bind(this);
  }

  componentDidMount() {
    this.getTags();
  }

  isPublished() {
    return this.props.product.isApproved ? 1 : 2;
  }

  isPendingReview() {
    return this.props.product.isPendingReview ? 1 : 2;
  }

  setRejection(rejecting: boolean) {
    this.setState({ isRejecting: rejecting });
  }

  setPublished(published: any) {
    const isPublished = Number(published) === 1 ? true : false;
    this.props.setPublished(isPublished);

  }

  setPendingReview(pendingReview: any) {
    const isPendingReview = Number(pendingReview) === 1 ? true : false;
    this.props.setPendingReview(isPendingReview);

  }

  getTags() {
    this.setState({ loadingTagSearch: true });
    if (this._getTags) {
      this._getTags.cancel('Cancelled getTags() XHR due to new request');
    }
    this._getTags = Axios.CancelToken.source();
    getMarketplaceTags(this.state.tagSearch, this._getTags)
      .then(response => this.setState({ loadingTagSearch: false, tags: response.data.data }));
  }

  searchOnChange(event: any) {
    this.setState({ tagSearch: event.target.value });
  }

  toggleModalShown() {
    this.setState({ tagModalShown: !this.state.tagModalShown });
  }

  searchTagsKeypress(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      this.getTags();
    }
  }

  manageTag(tag: MarketplaceTag) {
    if (this.props.product.hasTag(tag) !== -1) {
      this.props.removeTag(tag);
    } else {
      this.props.addTag(tag);
    }
  }
  statusColor(status: number) {
    if (status ===  2) {
      return 'text-success';
    }
    if (status === 4 || status === 1) {
      return 'text-info';
    }
    if (status === 5) {
      return 'text-danger';
    }
    if (status === 3) {
      return 'text-warning';
    }
    return 'text-muted';

  }

  render() {
    const product = this.props.product;
    const modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
      ],
    };
    const tagList = product.tags.map(tag => (
      <span key={tag.id} style={{ margin: 5 }} className="label label-primary">
        {tag.name} <span onClick={() => this.props.removeTag(tag)}>
          <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faTimes} />
          </span>
      </span>
      ),
    );

    let publishedStatus;
    if (product.isApproved) {
      publishedStatus = (
        <div>
          <h5><FontAwesomeIcon className="text-success" icon={faCircle} /> <strong>Published</strong></h5>
          <p className="text-muted">This item is visible on the Marketplace.</p>
        </div>
      );
    } else {
      publishedStatus = (
        <div>
          <h5><FontAwesomeIcon className="text-danger" icon={faCircle} /> <strong>Not Published</strong></h5>
          <p className="text-muted">This item is not visible on the Marketplace.</p>
        </div>
      );
    }

    let reviewStatus;
    if (product.isPendingReview) {
      reviewStatus = (
        <div>
          <h5><FontAwesomeIcon className="text-danger" icon={faRedo} /> <strong>Not Reviewed</strong></h5>
          <p className="text-muted">This item is currently pending review.</p>
        </div>
      );
    } else {
      if (!product.isApproved) {
        reviewStatus = (
          <div dangerouslySetInnerHTML={{ __html: product.reviewMessage }} />
        );
      }

    }

    const archiveText = product.isArchived ? 'Unarchive' : 'Archive';

    const tagSearchList = this.state.tags
      .slice(0, 15)
      .map(tag => (
        <button key={tag.id} onClick={() => this.manageTag(tag)} className="list-group-item" type="button">
          {tag.name} { product.hasTag(tag) !== -1 ?
            <span className="text-primary pull-right"><FontAwesomeIcon icon={faCheck} /></span> : null
          }
        </button>
      ));
    const skip = this.props.isGallery ? 'gallery' : 'products';
    return (
      <div>
        <div className="panel panel-portal">
          <div className="panel-body">

            <UserContext.Consumer>
              {user => user.type === 'admin' ? (
                <VendorInfo vendor={product.vendor} link="/marketplace" />
              ) : null}
            </UserContext.Consumer>
            <div>
              <h5 className="small">
                {product.organization.shortName} <FontAwesomeIcon icon={faChevronRight} />
                {' '}{ product.category ? product.category.name : 'No Product Category'}
              </h5>
              <h5 className="text-primary"><strong>{product.name}</strong></h5>
              {product.price ? <h5>${product.price}</h5> : null}
            </div>
            <hr />
            {publishedStatus}
            {reviewStatus}

            {this.props.error ? (
              <div>
                <hr />
                <div className="alert alert-danger"><FontAwesomeIcon icon={faExclamationCircle} /> {this.props.error}</div>
              </div>
            ) : null}

          </div>
        </div>
        <UserContext.Consumer>
          {user => user.type === 'admin' ? (
            <div className="panel panel-portal">
              <div style={{ borderBottom: '1px solid #fafafa' }} className="panel-body">
                <h5><FontAwesomeIcon icon={faLock} /> Admin</h5>
                <div>
                  <AffinityStarRating rating={product.internalRating} onRating={this.props.setInternalRating} />
                </div>
                {product.internalRating ? <div style={{ marginBottom: 10 }}>
                  <button onClick={() => this.props.setInternalRating(0)} className="btn btn-default btn-xs btn-block">Reset Rating</button>
                </div> : null}
                <div className="form-group">
                  <select
                    onChange={event => this.setPublished(event.target.value)}
                    value={this.isPublished()}
                    className="form-control"
                  >
                    <option value={1}>Published</option>
                    <option value={2}>Not Published</option>
                  </select>

                </div>
                <div className="form-group">
                  <select
                    onChange={event => this.setPendingReview(event.target.value)}
                    value={this.isPendingReview()}
                    className="form-control"
                  >
                    <option value={1}>Pending Review</option>
                    <option value={2}>Reviewed</option>
                  </select>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

                  {tagList}

                </div>
                <button type="button" onClick={this.toggleModalShown} className="btn btn-block btn-default">
                  Add Tags
                </button>
              </div>
              { this.props.product.isPendingReview ?  <div className="panel-body">
                <h5><FontAwesomeIcon icon={faQuestionCircle} /> Review</h5>
                {this.state.isRejecting ? <ReactQuill
                  onChange={message => this.setState({ message })}
                  modules={modules}
                  placeholder="Optional Review Message"
                /> : null}
                <div style={{ marginTop: 10 }} className="row">
                  <div style={{ marginTop: 10 }} className="col-md-6">
                    {this.state.isRejecting ? (
                      <button
                        type="button"
                        onClick={() => this.setRejection(false)}
                        className="btn btn-block btn-default"
                      >
                        Cancel
                      </button>
                    ) :
                      (
                        <button
                          type="button"
                          onClick={() => this.props.review('approve', this.state.message)}
                          className="btn btn-block btn-primary"
                        >
                          Approve
                        </button>
                      )
                    }
                  </div>
                  <div style={{ marginTop: 10 }} className="col-md-6">
                    <button
                      type="button"
                      onClick={() => this.state.isRejecting ?
                        this.props.review('reject', this.state.message) : this.setRejection(true)
                      }
                      className="btn btn-block btn-danger"
                    >
                      Reject
                    </button>
                  </div>
                  <div style={{ marginTop: 10 }} className="col-md-12">
                    <Link
                      className="btn btn-block btn-default"
                      to={
                        this.props.nextProduct ?
                          `/marketplace/${skip}/${this.props.nextProduct}${this.props.nextProductQueryString}`
                          : `/marketplace/${skip}`
                      }
                    >
                      Skip
                    </Link>
                  </div>
                </div>

              </div> : null}
            </div>
          ) : null}
        </UserContext.Consumer>

        <div className="panel panel-portal">
          <div className="panel-body">
            <button type="button" onClick={this.props.archive} className="btn btn-default btn-block">{archiveText}</button>
            <button type="button" onClick={this.props.toggleStayArchived} className="btn btn-default btn-block">
              Stay Archived: {this.props.product.stayArchived ? 'Enabled' : 'Disabled'}
            </button>
            <button type="submit" className="btn btn-primary btn-block">Save</button>
          </div>
        </div>

        <GatewayModal
          title="Product Tags"
          type={ModalType.Primary}
          onClose={this.toggleModalShown}
          shown={this.state.tagModalShown}
        >
          <Modal.Body>
            <div className="input-group">
              <input
                onChange={this.searchOnChange}
                value={this.state.tagSearch}
                placeholder="Search for tags"
                className="form-control"
                onKeyPress={this.searchTagsKeypress}
              />
              <span className="input-group-btn">
                <button onClick={this.getTags} className="btn btn-default">Search</button>
              </span>
            </div>
            <br />
            {this.state.loadingTagSearch ?
              <LoadingSpinner /> :
              <div className="list-group">
                {tagSearchList}
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" onClick={this.toggleModalShown} className="btn btn-default pull-right"> Done </button>
          </Modal.Footer>

        </GatewayModal>

      </div>
    );
  }
}

interface AffinityStarRatingProps {
  rating: number | null;
  onRating: (rating: number) => void;
}

export const AffinityStarRating = (props: AffinityStarRatingProps) => {
  const [hovered, setHovered] = React.useState<null|number>(null);

  const padding = 3;

  const icon = (i: number) => {
    if (hovered) {
      return i <= hovered ?  faSolidStar : faStar;
    }
    if (props.rating) {
      return i <= props.rating ? faSolidStar : faStar;
    }
    return faStar;
  };

  const r = [1, 2, 3, 4, 5].map(i => (
    <span style={{ paddingLeft: padding, paddingRight: padding }} onMouseOver={() => setHovered(i)} onMouseLeave={() => setHovered(null)} onClick={() => props.onRating(i)} >
      <FontAwesomeIcon style={{ color: 'gold', fontSize: 26 }} icon={icon(i)} />
    </span>
  ));

  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 10 }}>
      {r}
    </div>
  );
};
