import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import withReport, { InjectedReportProps } from './withReport';

const asRoyaltyPage = compose(
  withRouter,
  withReport(),
);

export type RoyaltyPageProps = InjectedReportProps & RouteComponentProps<any>;

export default asRoyaltyPage;
