import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { AccountAlias, AccountAliasAPIResponse } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { deleteAlias, getAliases } from '../api';
import { AliasListItem } from '../shared';

interface IState {
  loading: boolean;
  aliases: AccountAlias[];
  deleteModalShown: boolean;
  idToDelete: number | null;
  deleting: boolean;
}

export class AliasPage extends React.Component<RouteComponentProps<any>, IState> {

  _getAliasesSource: CancelTokenSource;
  _deleteAliasSource: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      loading: true,
      aliases: [],
      deleteModalShown: false,
      idToDelete: null,
      deleting: false,
    };
    this.getAliases = this.getAliases.bind(this);
    this.editAlias = this.editAlias.bind(this);
    this.deleteAlias = this.deleteAlias.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.setDeleteID = this.setDeleteID.bind(this);
  }

  componentDidMount() {
    this.getAliases();
  }

  componentWillUnmount() {
    if (this._getAliasesSource) {
      this._getAliasesSource.cancel('Cancelled getAlias() XHR due to unmount');
    }
  }

  showDeleteModal() {
    this.setState({ deleteModalShown: true });

  }

  hideDeleteModal() {
    this.setState({ deleteModalShown: false });
  }

  setDeleteID(id: number) {
    this.setState({ idToDelete: id });
    this.showDeleteModal();
  }

  render() {
    let body;
    if (this.state.loading) {
      body = (<LoadingSpinner />);
    } else {
      const aliases = this.state.aliases.length ?  this.state.aliases.map(alias => (
        <AliasListItem showActions={true} key={alias.id} alias={alias} delete={this.setDeleteID} edit={this.editAlias} />
      )) : (
        <p className="text-muted text-center">This account currently has no aliases.</p>
      );

      body = (
        <div className="account-alias-list">

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <h3 className="no-margin"><strong>Also Known As</strong></h3>
            <Link to={`${this.props.location.pathname}/new`} className="btn btn-primary">
              <FontAwesomeIcon icon={faPlus} /> New Alias
            </Link>
          </div>
          <hr/>
          {aliases}
        </div>

      );
    }

    const deleteModalText = this.state.deleting ? 'Deleting...' : 'Yes, Delete Alias';

    return  (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            {body}
          </div>
          <GatewayModal shown={this.state.deleteModalShown} type={ModalType.Danger} onClose={this.hideDeleteModal} title="Delete Alias" >
            <Modal.Body>
              <p className="text-center">Are you sure you want to delete this alias?</p>

            </Modal.Body>
            <Modal.Footer>
              <button onClick={this.hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
              <button onClick={this.deleteAlias} className="btn btn-danger pull-right">{deleteModalText}</button>

            </Modal.Footer>
          </GatewayModal>
        </div>
      </FullContent>
    );
  }

  getAliases() {
    if (this._getAliasesSource) {
      this._getAliasesSource.cancel('Cancelled getAlias() XHR due to new request');
    }
    this._getAliasesSource = Axios.CancelToken.source();
    const params = {
      account_id: this.accountID,
    };
    getAliases(params, this._getAliasesSource)
      .then((response) => {
        let aliases = [];
        if (response.data.data) {
          aliases = response.data.data.map((alias: AccountAliasAPIResponse) => AccountAlias.fromApi(alias));
        }
        this.setState({ aliases, loading: false });
      });

  }

  deleteAlias() {
    if (this.state.idToDelete) {
      this.setState({ deleting: true });
      deleteAlias(this.state.idToDelete)
        .then((response) => {
          const aliases = this.state.aliases.filter(alias => alias.id !== this.state.idToDelete);
          this.setState({ aliases, deleting: false, idToDelete: null });
          this.hideDeleteModal();
        });
    }
  }

  editAlias(id: number) {
    this.props.history.push(`${this.props.location.pathname}/${id}`);
  }

  get accountID() {
    let id;
    if (this.props.match.params['vendorId']) {
      id = this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      id = this.props.match.params['licensorId'];
    }
    return id;
  }

}
