import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { AccountWebUrl, AccountWebUrlType } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';

interface IProps {
  aliasId?: number;
}

export const ExternalWebsitesPage = (props: IProps) => {
  const [urls, setUrls] = useState<AccountWebUrl[]>([]);
  const [types, setTypes] = useState<AccountWebUrlType[]>([]);
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(true);
  const match = useRouteMatch();

  React.useEffect(() => {
    getUrls();
    getUrlTypes();

  },              []);

  const accountId = () => {
    if (match && match.params['licensorId']) {
      return match.params['licensorId'];

    }
    if (match && match.params['vendorId']) {
      return match.params['vendorId'];
    }
    return '';
  };

  const getUrls = async () => {
    setLoading(true);
    const u = await Axios.get(`/api/account-web-urls?${accountId() ? `account_id=${accountId()}&` : ''}${props.aliasId ? `alias_id=${props.aliasId}` : ''}`);
    setUrls(u.data.data.map((u: any) => AccountWebUrl.fromApi(u)));
    setLoading(false);
  };

  const getUrlTypes = async () => {
    const t = await Axios.get('/api/account-web-url-types');
    setTypes(t.data.data.map((type: any) => AccountWebUrlType.fromApi(type)));
  };

  const removeURL = async (url: AccountWebUrl, index: number) => {
    const c = confirm('Are you sure you want to remove this link?');
    if (c) {
      const u = [... urls];
      u.splice(index, 1);
      setUrls(u);
      await Axios.delete(`/api/account-web-urls/${url.id}`);
    }

  };

  const submitUrl = async (values: any) => {
    const u = await Axios.post('/api/account-web-urls', {
      account_id: accountId(),
      alias_id: props.aliasId ? props.aliasId : undefined,
      url: values.url,
      type: values.type,
    });
    setUrls(u.data.data.map((u: any) => AccountWebUrl.fromApi(u)));
    setAdd(false);
  };

  const urlList = urls.map((u, i) => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: '1px solid #eee',
        padding: 20,
      }}
    >
      <div>
        <h4><strong>{u.type.name}</strong>  <i className={u.type.icon} /></h4>
        <a href={u.url} target="_blank" >{u.url}</a>
      </div>
      <button onClick={() => removeURL(u, i)} className="btn btn-danger btn-xs">Remove</button>
    </div>
  ));

  const formValidation = Yup.object().shape({
    type: Yup.mixed().required('Type is required.'),
    url: Yup.string().url('Please enter a valid url. It must start with https:// or http:// to be valid.').required('Url is required.') ,
  });

  const body = loading ? (<LoadingSpinner />) : (
    <div className="panel-body">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h3><strong>Web Links</strong></h3>
        <button onClick={() => setAdd(true)} className="btn btn-primary">Add Link</button>
      </div>
      {urlList.length ? urlList : <h5 className="text-muted text-center">No URLS</h5>}
    </div>
  );
  const panelContent = (
    <div>
      <div className="panel panel-portal">
        {body}

      </div>
      <GatewayModal shown={add} type={ModalType.Primary} title="Add Link" onClose={() => setAdd(false)}>
        <Formik validationSchema={formValidation} initialValues={{ type: null, url: '' }} onSubmit={submitUrl}>
          {formProps => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Type</label>
                  <Field component="select" name="type" className="form-control">
                    <option disabled selected>Select Type</option>
                    {types.map(t => <option value={t.id}>{t.name}</option>)}
                  </Field>
                  <p className="text-danger">
                    <ErrorMessage name="type" />
                  </p>
                </div>
                <div className="form-group">
                  <label>URL</label>
                  <Field placeholder="https://example.com" type="text" name="url" className="form-control" />
                  <p className="text-danger">
                    <ErrorMessage name="url" />
                  </p>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setAdd(false)} className="btn btn-default pull-left">Cancel</button>
                <button className="btn btn-primary pull-right">Submit</button>
              </Modal.Footer>
            </Form>

          )}

        </Formik>
      </GatewayModal>
    </div>
  );
  if (props.aliasId) {
    return panelContent;
  }

  return (
    <FullContent>
      {panelContent}

    </FullContent>
  );

};
