import * as React from 'react';
import StatusFilter from './StatusFilter';

interface IProps {
  labels: any;
  selected: string|null|number;
  selectFn: (sel: number) => any;
}

export class StatusFilterBar extends React.Component<IProps, any> {
  static defaultProps = {
    labels: [],
  };
  public constructor(props: any) {
    super(props);

    this.handleFilterSelect = this.handleFilterSelect.bind(this);
  }

  public render() {
    const filters = this.props.labels.map((filterItem:any) => {
      return <StatusFilter
        key={filterItem.name}
        selectFn={this.handleFilterSelect}
        {...filterItem}
        active={this.props.selected === filterItem.id}
        />;
    });
    return <ul className="nav nav-pills content-pills" style={{ padding: '10px 0', backgroundColor: 'transparent' }}>
      {filters}
    </ul>;
  }

  protected handleFilterSelect (sel: number) {
    this.props.selectFn(sel);
  }
}
