import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, FormikProps } from 'formik';
import * as React from 'react';
import { LicensorTypeahead, ProductCategoryTypeahead } from '../../shared';
import { IUploadedDesignEntry, UploadStatus } from './DesignUploadPage';
import { IUploadItemFormValues } from './UploadItem';

type OptionType = {id: number, name: string};
interface IProps {
  uploadItem: IUploadedDesignEntry;
  formikProps: FormikProps<IUploadItemFormValues>;
  productOptions: OptionType[];
  licensorOptions: OptionType[];
  isSavingAll: boolean;
  onClickExpedite: (event: any) => void;
  onClickDelete: (event: any) => void;
  onSavedAll: (designId: number) => void;
}

export const UploadItemForm: React.FunctionComponent<IProps> = (props: IProps) => {
  const { uploadItem, formikProps, productOptions, licensorOptions } = props;

  React.useEffect(
    () => {
      if (
        props.isSavingAll
        && formikProps.dirty
        && formikProps.isValid
        && uploadItem.status === UploadStatus.SUCCESS
      ) {
        formikProps.handleSubmit();
        if (uploadItem.designId) {
          props.onSavedAll(uploadItem.designId);
        }
      }
    },
    [props.isSavingAll],
  );

  return (
    <form onSubmit={formikProps.handleSubmit}>
      <div className="panel">
        <div className="panel-heading">
          <h4>{uploadItem.file.name}</h4>
        </div>
        <div className="panel-body">
          <div className="row">
            <div className="col-md-5">
              {uploadItem.previewUrl !== '' ? (
                <img style={{ maxWidth: '100%', maxHeight: 200 }} src={uploadItem.previewUrl} />
              ) : null}
            </div>
            <div className="col-md-7">
              <div className="form-group col-md-6">
                <label>Name</label>
                <Field name="title" className="form-control" type="text" />
                {formikProps.errors.title ? (
                  <div className="alert alert-danger"><ErrorMessage name="title" /></div>
                ) : null}
              </div>
              <div className="form-group col-md-6">
                <label>Product</label>
                <ProductCategoryTypeahead
                  multiple={true}
                  selected={formikProps.values.productEntry}
                  onChange={c => formikProps.setFieldValue('productEntry', c)}
                />
                {formikProps.errors.productEntry ? (
                  <div className="alert alert-danger"><ErrorMessage name="productEntry" /></div>
                ) : null}
              </div>
              <div className="form-group col-md-6">
                <label>Organization</label>
                <LicensorTypeahead
                  selected={formikProps.values.organization}
                  onChange={(l) => {
                    formikProps.setFieldValue('organization', l);
                  }}
                  multiple={false}
              />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="initial_note">Submission Note</label>
                <Field
                  name="description"
                  as="textarea"
                  className="form-control"
                />
                <ErrorMessage name="description" />
              </div>
              <div className="col-md-12">
                {formikProps.isValid && formikProps.dirty ? (
                  <button
                    disabled={formikProps.isSubmitting}
                    type="submit"
                    className="btn btn-primary btn-sm"
                  >
                    Save Changes
                  </button>
                ) : null}
                {uploadItem.status === UploadStatus.SUCCESS && !formikProps.dirty ? (
                  <div>
                    <FontAwesomeIcon icon={faCheckCircle} className="text-primary" />
                    <small className="text-muted">Your design has been submitted with the information above.</small>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <hr />
          {uploadItem.status === UploadStatus.SUCCESS ? (
            <div className="panel-body">
              <div className="col-md-5">
                {!uploadItem.isExpedited ? (
                  <div className="text-center">
                    <span>Expedited Review <span className="text-primary">$3.75</span></span><br />
                    <small className="text-muted">You can pay to expedite this design for faster review.
                      Expedited submissions are typically reviewed in less than 24 hours.
                    </small><br />
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-primary"
                      onClick={props.onClickExpedite}
                      disabled={uploadItem.isExpediting}
                    >
                      <span className="text-success">Expedited Review</span>
                    </button>
                  </div>
                ) : (
                  <div className="text-center">
                    This design will be expedited. <br />
                    <button
                      type="button"
                      className="btn btn-sm btn-default"
                      onClick={props.onClickExpedite}
                    >
                      <span className="text-danger">Undo</span>
                    </button>
                  </div>
                )}
              </div>

              <div className="col-md-7 text-center">
                <span>Change your mind?</span><br />
                <small className="text-muted">You can delete this submission.</small><br />
                <button
                  type="button"
                  className="btn btn-sm btn-default"
                  onClick={props.onClickDelete}>
                  <span className="text-danger">Delete Submission</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="panel-body">
              <div className="col-md-10">
                <div className="progress progress-striped active">
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={50}
                    style={{ width: '50%' }}
                  >
                  </div>
                  <span className=" sr-only">{50}% Complete</span>
                </div>
              </div>
              <div className="col-md-2">
                <strong className="text-success">
                  {uploadItem.status === UploadStatus.UPLOADING ? (
                    <span>Uploading...</span>
                  ) : null}
                  {uploadItem.status === UploadStatus.UPDATING ? (
                    <span>Processing...</span>
                  ) : null}
                </strong>
              </div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
