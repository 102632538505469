import { Field, FieldProps, FormikProps } from 'formik';
import { orderBy } from 'lodash';
import * as React from 'react';

interface IProps {
  formProps: FormikProps<any>;
  insignias: any[];
}
export class SharedFormFields extends React.Component<IProps,  any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const insignias = orderBy(this.props.insignias, i => i.title.toLowerCase(), 'asc')
      .filter(i => !i.deleted_at).map(insignia =>
        <option key={insignia.id} value={insignia.id}>{insignia.title}</option>,
      );
    const archivedInsignias = orderBy(this.props.insignias, i => i.title.toLowerCase(), 'asc')
      .filter(i => i.deleted_at).map(insignia =>
        <option disabled={true} key={insignia.id} value={insignia.id}>{insignia.title}</option>,
      );
    return(
      <div>
        <div
          className={
            `form-group
            ${this.props.formProps.errors.title && this.props.formProps.touched.title  ? 'has-error' : ''}`
          }
        >
          <label>Title</label>
          <Field name="title" type="text" placeholder="Title" className="form-control" />
        </div>
        <div className="form-group">
          <label>Description</label>
          <Field component="textarea" className="form-control" name="description" placeholder="Description" />
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>Primary Asset</label>
            <div className="checkbox">
              <label>
                <Field name="primary" render={ (field: FieldProps) => {
                  return <input type="checkbox" {... field.field} checked={field.field.value} />;

                }} />
                <span>This is a primary asset.</span>
              </label>
            </div>

          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Insignia</label>
              <Field component="select" name="insignia" className="form-control">
                <option>Select Insignia</option>
                <optgroup label="Current Insignia" />
                {insignias}
                {archivedInsignias.length ? <optgroup label="Archived Insignia" /> : null}
                {archivedInsignias}

              </Field>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
