import User from '../User';

export class AccountDesignReviewer {
  id: number;
  user: User;

  static fromApi(data: any) {
    const u = new AccountDesignReviewer();
    u.id = data.id;
    u.user = User.fromApi(data.user);
    return u;
  }

}
