import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikValues } from 'formik';
import * as queryString from 'query-string';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { Filters } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType, PaginationFooter } from '../../shared';

interface AudienceSegmentAPIData {
  id: number;
  name: string;
  sum: number;
  audienceData: [];
}

export const AudienceSegmentDetailPage = () => {
  const [loading, setLoading] = useState(true);
  const [audience, setAudience] = useState<AudienceSegmentAPIData | []>([]);
  const [title, setTitle] = useState('');
  const [isHeatmap, setIsHeatmap] = useState(true);
  const [isInclude, setIsInclude] = useState(true);
  const [isLocationPin, setIsLocationPin] = useState(false);
  const [mapColor, setMapColor] = useState('orange');
  const [sampleUrl, setSampleUrl] = useState('');
  const [lastPage, setLastPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [page, setPage] = useState(1);
  const [year, setYear] = useState(0);
  const [saving, setSaving] = useState(false);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [uploadModalShown, setUploadModalShown] = useState(false);
  const [editModalShown, setEditModalShown] = useState(false);
  const routeParams = useParams();
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);
  const [filters, setFilters] = React.useState(new Filters());
  filters.setQueryParamsFromLocation(location.search);

  useEffect(() => {
    getAudienceSegment();
  },        [year]);

  function getAudienceSegment(page = 1) {
    const params = {
      page,
      year,
      account_id: getAccountID(),
    };

    Axios.get(`/api/audience/segments/${routeParams['id']}`, { params })
      .then((response) => {
        setTitle(response.data.name);
        setIsHeatmap(response.data.isMapHeatmap);
        setIsInclude(response.data.isInclude);
        setIsLocationPin(response.data.isMapLocationPin);
        setMapColor(response.data.mapColor);
        setSampleUrl(response.data.sample_url);
        setAudience(response.data.audienceAggregate.data);
        setTotalResults(response.data.meta.pagination.total_count);
        setLastPage(response.data.meta.pagination.page_count);
        setPage(response.data.meta.pagination.page);
        setLoading(false);
        if (response.data.audienceAggregate.data.length === 0 && location.state.hasOwnProperty('openUploadModal') && location.state.openUploadModal === true) {
          location.state.openUploadModal = false;
          showUploadModal();
        }
      });
  }

  function saveAudienceSegment(values: FormikValues, formProps: FormikActions<FormikValues>) {
    if (values) {
      setSaving(true);

      const formData = {
        name: values.name,
        heatmap: values.heatmap,
        isInclude: values.includeInAll,
        pin: values.pin,
        mapColor: values.mapColor,
        account_id: getAccountID(),
      };

      Axios.post(`/api/audience/segments/${routeParams['id']}`, formData)
        .then((response) => {
          getAudienceSegment();
          hideEditModal();
          setSaving(false);
        })
        .catch((error) => {
          for (const index in error.response.data) {
            formProps.setFieldError(index, error.response.data[index][0]);
          }
          setSaving(false);
        });
    }
  }

  function uploadAudienceAggregate(values: FormikValues, formProps: FormikActions<FormikValues>) {
    if (values) {
      setSaving(true);

      const formData = new FormData();
      formData.append('file', values.file);
      formData.append('account_id', getAccountID());
      formData.append('year', values.year);

      Axios.post(`/api/audience/segments/${routeParams['id']}/upload`, formData)
        .then((response) => {
          getAudienceSegment();
          hideUploadModal();
          setSaving(false);
        })
        .catch((error) => {
          for (const index in error.response.data.errors) {
            formProps.setFieldError(index, error.response.data.errors[index][0]);
          }
          setSaving(false);
        });
    }
  }

  function deleteAudienceSegment(values: FormikValues, formProps: FormikActions<FormikValues>) {
    const params = {
      account_id: getAccountID(),
    };
    Axios.delete(`/api/audience/segments/${routeParams['id']}`, { params })
      .then((response) => {
        hideDeleteModal();
        if (user.type === 'admin') {
          history.push(getBackRoute());
        } else {
          history.push('/settings/audiences');
        }
      });
  }

  function updatePage(newPage: number) {
    setPage(newPage);
    const baseUrl = location.pathname;
    history.replace(`${baseUrl}?${queryString.stringify({ page: newPage })}`);
    getAudienceSegment(newPage);
  }

  function filterYear(e: any) {
    setYear(Number(e.target.value));
  }

  function showEditModal() {
    setEditModalShown(true);
  }

  function hideEditModal() {
    setEditModalShown(false);
  }

  function showDeleteModal() {
    setDeleteModalShown(true);
  }

  function hideDeleteModal() {
    setDeleteModalShown(false);
  }

  function showUploadModal() {
    setUploadModalShown(true);
  }

  function hideUploadModal() {
    setUploadModalShown(false);
  }

  function getDashboardPage() {
    if (user.type === 'admin') {
      if (routeParams['vendorId']) {
        history.push(`/vendors/${getAccountID()}/audiences`);
      } else {
        history.push(`/clients/${getAccountID()}/audiences`);
      }
    } else {
      history.push('audiences');
    }
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  function getBackRoute() {
    if (routeParams['vendorId']) {
      return `/vendors/${getAccountID()}/settings/audiences`;
    }
    if (routeParams['licensorId']) {
      return `/clients/${getAccountID()}/settings/audiences`;
    }

    return '';
  }

  let pageContent;

  if (loading) {
    return (
      <LoadingSpinner/>
    );
  }

  const rows = (audience as any[]).map(audience =>
    <tr key={ audience.id } className="licensor-mapping-row">
      <td style={{ verticalAlign: 'middle' }}>
        { audience.year ? audience.year : '-'}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {audience.label  ? audience.label : '-'}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {audience.full_address ? audience.full_address : '-'}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {audience.value ? audience.value : '-'}
      </td>
    </tr>,
  );

  if (audience.length === 0 && ! loading) {
    pageContent =  (
      <em>No Audience Data Found.</em>
    );
  } else {
    pageContent = (
          <table className="table">
            <thead>
            <tr>
              <th>Year</th>
              <th>Label</th>
              <th>Location</th>
              <th>Audience Total</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
          </table>
    );
  }

  const editInitialValues = {
    mapColor,
    name: title,
    pin: isLocationPin,
    heatmap: isHeatmap,
    includeInAll: isInclude,
    mapType: '',
  };

  const uploadInitialValues = {
    year: 0,
    file: null,
  };

  const validation = Yup.object({
    name: Yup.string().min(1).required('An audience name is required'),
    mapColor: Yup.string().min(1).required('A map color is required'),
    mapType: Yup
      .string()
      .when(['heatmap', 'pin'], {
        is: false,
        then: Yup.string().min(1).required('At least one map type must be selected below'),
      }),
    pin: Yup.boolean(),
    heatmap: Yup.boolean(),
    includeInAll: Yup.boolean(),
  });

  const editModalText = saving ? 'Saving...' : 'Edit Audience';
  const uploadModalText = saving ? 'Uploading...' : 'Upload Audience Data';
  const deleteModalText = saving ? 'Deleting...' : 'Delete Audience';
  const years = [];
  const currentYear = new Date().getFullYear();
  const startYear = 2014;

  for (let i = currentYear; i >= startYear; i--) {
    years.push(i);
  }

  return (
    <FullContent breadcrumbs={[{ name: 'audiences' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ maxWidth: '50%' }}>
          <h3><strong>{title}</strong></h3>
        </div>
        <div className="pull-right" >
          <button className="btn btn-primary" onClick={getDashboardPage} style={{ marginRight: 5 }}>
            View Dashboard
          </button>
          <DropdownButton
            className="btn-default"
            id="audience-options-dropdown"
            bsStyle={'default'}
            title={'Options'}
            pullRight
          >
            <MenuItem eventKey="edit" onClick={showEditModal}><i className="fa fa-pencil text-primary"/> Edit</MenuItem>
            <MenuItem eventKey="delete" onClick={showDeleteModal}><i className="fa fa-times text-danger" /> Delete</MenuItem>
            <MenuItem eventKey="upload" onClick={showUploadModal}><i className="fa fa-upload text-primary" /> Upload Aggregate Data</MenuItem>
          </DropdownButton>
        </div>
      </div>
      <div className="form-inline" style={{ marginBottom:10 }}>
        <select value={year} className="form-control" onChange={filterYear}>
          <option selected value={0}>Year</option>
          {years.map(year => (<option key={year} value={year}>{year}</option>))}
        </select>
      </div>
      <div className="panel panel-portal">
        { pageContent }
      </div>
      <PaginationFooter
        setPage={updatePage}
        currentPage={page}
        totalResults={totalResults}
        hasNext={lastPage > page} />

      <GatewayModal shown={editModalShown} type={ModalType.Primary} onClose={hideEditModal} title="Edit Audience" >
        <Modal.Body>
          <Formik
            onSubmit={ (values, formProps) => saveAudienceSegment(values, formProps)}
            validationSchema={validation}
            // validateOnBlur={false}
            initialValues={editInitialValues}>
            {formProps =>
              (
                <div>
                  <Form id="audience-segment-form">
                    <div className="form-group">
                      <label htmlFor="name">Audience Name</label>
                      <Field placeholder="Audience Name" name="name" className="form-control"/>
                      <p className="text-danger">
                        <ErrorMessage name="name" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="mapColor">Audience Map Color</label>
                      <Field component="select"
                             name="mapColor"
                             className="form-control"
                      >
                        <option value="" disabled={true}>Select Map Color</option>
                        <option value="blue">Blue</option>
                        <option value="gray">Gray</option>
                        <option value="green">Green</option>
                        <option value="orange">Orange</option>
                        <option value="pink">Pink</option>
                        <option value="purple">Purple</option>
                        <option value="red">Red</option>
                        <option value="yellow">Yellow</option>
                    </Field>
                    </div>
                    <label htmlFor="heatmap">Audience Map Type
                    </label>
                    <p className="text-danger">
                      <ErrorMessage name="mapType" />
                    </p>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="heatmap" defaultChecked={formProps.values.heatmap}
                        />
                        Heatmap
                    </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="pin" defaultChecked={formProps.values.pin} />
                        Pins
                      </label>
                    </div>
                    <label htmlFor="heatmap">Audience Dashboard
                    </label>
                    <p className="text-danger">
                      <ErrorMessage name="includeInAll" />
                    </p>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="includeInAll" defaultChecked={formProps.values.includeInAll}
                        />
                        Include in combined dashboard
                      </label>
                    </div>
                  </Form>
                </div>
              )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideEditModal} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" form="audience-segment-form" className="btn btn-primary pull-right" disabled={saving}>{editModalText}</button>
        </Modal.Footer>
      </GatewayModal>

      <GatewayModal shown={deleteModalShown} type={ModalType.Danger} onClose={hideDeleteModal} title="Delete Audience" >
        <Modal.Body>
          <p className="text-center">Are you sure you want to remove this audience and all aggregate data?</p>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
          <button onClick={deleteAudienceSegment} className="btn btn-danger pull-right" disabled={saving}>{deleteModalText}</button>
        </Modal.Footer>
      </GatewayModal>

      <GatewayModal shown={uploadModalShown} type={ModalType.Primary} onClose={hideUploadModal} title="Upload Audience" >
        <Modal.Body>
          <Formik
            onSubmit={ (values, formProps) => uploadAudienceAggregate(values, formProps)}
            initialValues={uploadInitialValues}>
            {formProps =>
              (
                <div>
                  <div className="well sample-cert-faq" style={{ width:'100%' }}>
                    <span className="fa fa-info-circle text-info"/> <strong>Sample Audience Data Template</strong>
                    <a href={sampleUrl} className="btn btn-info btn-xs pull-right" type="button" target="_blank">
                      View Sample
                    </a>
                  </div>
                  <Form id="audience-upload-form">
                    <label htmlFor="file">File Upload</label>
                    <input id="file" name="file" type="file" onChange={(e) => {
                      formProps.setFieldValue('file', e.target.files && e.target.files[0]);
                    }} className="form-control"/>
                    <p className="text-danger">
                      <ErrorMessage name="file" />
                    </p>
                    <label htmlFor="year">Year</label>
                    <select value={formProps.values.year} className="form-control" onChange={event => formProps.setFieldValue('year', Number(event.target.value))} >
                      <option selected value={0}>Year</option>
                      {years.map(year => (<option key={year} value={year}>{year}</option>))}
                    </select>
                    <p className="text-danger">
                      <ErrorMessage name="year" />
                    </p>
                  </Form>
                </div>
              )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideUploadModal} className="btn btn-default pull-left">Cancel</button>
          <button form="audience-upload-form" className="btn btn-primary pull-right" disabled={saving}>{uploadModalText}</button>
        </Modal.Footer>
      </GatewayModal>

    </FullContent>

  );

};
