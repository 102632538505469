
import * as moment from 'moment';
import { MarketplaceInquiry, MarketplaceInquiryApiResponse } from './MarketplaceInquiry';

export interface MarketplaceMessageApiResponse {
  id: number;
  message_id: number;
  type: 'user' | 'vendor';
  user_id: number;
  message: string;
  updated_at: string;
  inquiry?: MarketplaceInquiryApiResponse;
}
export class MarketplaceMessage {
  id: number;
  messageId: number;
  type : 'user' | 'vendor';
  userId: number;
  message: string;
  updatedAt: moment.Moment;
  inquiry: MarketplaceInquiry | null;

  static fromApi(data: MarketplaceMessageApiResponse) {
    const message = new MarketplaceMessage();
    message.id = data.id;
    message.messageId = data.message_id;
    message.type = data.type;
    message.userId = data.user_id;
    message.message = data.message;
    message.updatedAt = moment.unix(Number(data.updated_at));
    message.inquiry = data.inquiry ? MarketplaceInquiry.fromApi(data.inquiry) : null;

    return message;

  }

}
