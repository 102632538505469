import { faForward, faLock, faPlus, faSpinner, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import Dropzone from 'react-dropzone';
import { RouterProps } from 'react-router';
import { Link } from 'react-router-dom';
import { getAggregateFileUploads, getAggregateLicensors, getAggregateLineItems } from '../../../api';
import { UserContext } from '../../../contexts';
import { AffinityClient, RoyaltyReport, RoyaltyReportLineItem } from '../../../shared';
import asRoyaltyPage from '../../../shared/asRoyaltyPage';
import { getAffinityPageTitle } from '../../../utils';
import { ContentWithSidebar, FullContent } from '../../ContentFrame';
import ReportProgress from '../royalties-shared/ReportProgress';
import { AddAggregateReportModal } from './AddAggregateReportModal';
import AggregateRow from './AggregateRow';

interface SortedAggregates {
  licensor: AffinityClient;
  lineItems: RoyaltyReportLineItem[];
  fileUploaded: boolean;
  uploadingFile: boolean;
}
interface IState {
  aggregateModalShown: boolean;
  aggregates: SortedAggregates[];
  submitting: boolean;
  successfulFileUploadCount: number;
  failedFileUploadCount: number;
  loading: boolean;
  licensors: AffinityClient[];
  addingLicensor: boolean;
  uploadingFiles: boolean;

}

interface INewProps {
  report: RoyaltyReport;
}

class RoyaltyReportAggregatePage extends React.Component<INewProps & RouterProps, IState> {

  protected _submitAggregateDataSource: CancelTokenSource;
  protected _loadAggregateDataSource: CancelTokenSource;
  protected _uploadFileSource: CancelTokenSource;
  protected _uploadIndividualFileSource: CancelTokenSource;
  protected _getLicensorsSource:  CancelTokenSource;
  protected _addLicensorSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      aggregateModalShown: false,
      aggregates: [],
      submitting: false,
      successfulFileUploadCount: 0,
      failedFileUploadCount: 0,
      loading: false,
      licensors: [],
      addingLicensor: false,
      uploadingFiles: false,
    };
    this.showAggregateModal = this.showAggregateModal.bind(this);
    this.closeAggregateModal = this.closeAggregateModal.bind(this);
    this.setValueForLineItem = this.setValueForLineItem.bind(this);
    this.submitAggregateData = this.submitAggregateData.bind(this);
    this.sortLineItems = this.sortLineItems.bind(this);
    this.loadAggregateData = this.loadAggregateData.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.uploadIndividualFile = this.uploadIndividualFile.bind(this);
    this.canContinue = this.canContinue.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.getLicensors = this.getLicensors.bind(this);
    this.addLicensor = this.addLicensor.bind(this);
    this.setZeroValues = this.setZeroValues.bind(this);
    this.getAggregateIndex = this.getAggregateIndex.bind(this);
    this.canFillZeros = this.canFillZeros.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {
    this.loadAggregateData();
    this.getLicensors();
  }

  componentWillUnmount() {
    if (this._loadAggregateDataSource) {
      this._loadAggregateDataSource.cancel('Cancelled loadAggregateData() XHR due to unmount');
    }
    if (this._submitAggregateDataSource) {
      this._submitAggregateDataSource.cancel('Cancelled submitAggregateData() XHR due to unmount');
    }
    if (this._uploadFileSource) {
      this._uploadFileSource.cancel('Cancelled uploadFile() XHR due to unmount');
    }
    if (this._uploadIndividualFileSource) {
      this._uploadIndividualFileSource.cancel('Cancelled uploadIndividualFile() XHR due to unmount');
    }
    if (this._getLicensorsSource) {
      this._getLicensorsSource.cancel('Cancelled getLicensors() XHR due to unmount');
    }
    if (this._addLicensorSource) {
      this._addLicensorSource.cancel('Cancelled addLicensor() XHR due ot unmount');
    }
  }

  getLicensors() {
    if (this._getLicensorsSource) {
      this._getLicensorsSource.cancel('Cancelled getLicensors() due to new request');
    }
    this._getLicensorsSource = Axios.CancelToken.source();
    getAggregateLicensors()
      .then((response) => {
        this.setState({ licensors: response.data.data.map((licensor: any) => new AffinityClient(licensor)) });
      })
      .catch((error: any) => console.log(error));

  }

  showAggregateModal() {
    this.setState({ aggregateModalShown: true });
  }

  closeAggregateModal() {
    if (!this.state.addingLicensor) {
      this.setState({ aggregateModalShown: false });
    }
  }

  setValueForLineItem(lineItem: RoyaltyReportLineItem, field: 'gross' | 'quantity' | 'total', originalValue: string) {
    const value = originalValue.replace(',', '');
    if (isNaN(Number(value))) {
      return;
    }
    const aggregateIndex = this.state.aggregates.findIndex(a => a.licensor.id === lineItem.licensor.id);
    if (aggregateIndex !== -1) {
      const lineItemIndex = this.state.aggregates[aggregateIndex].lineItems
      .findIndex(l => l.schedule.id === lineItem.schedule.id);
      if (lineItemIndex !== -1) {
        const aggregates = this.state.aggregates;
        switch (field) {
          case 'gross':
            aggregates[aggregateIndex].lineItems[lineItemIndex].grossSales = Number(value);
            break;
          case 'quantity':
            aggregates[aggregateIndex].lineItems[lineItemIndex].quantity = Number(value);
            break;
          case 'total':
            aggregates[aggregateIndex].lineItems[lineItemIndex].royalty = Number(value);
            break;
          default:
            break;
        }
        this.setState({ aggregates });
      }
    }

  }

  sortLineItems(lineItems: any[]) {
    const sortedLicensors: SortedAggregates[] = [];
    lineItems.forEach((item) => {
      const lineItem = new RoyaltyReportLineItem(item);
      const licensor = new AffinityClient(item.client);
      const index = sortedLicensors.findIndex(sorted => sorted.licensor.id === licensor.id);
      if (index !== -1) {
        sortedLicensors[index].lineItems.push(lineItem);
      } else {
        sortedLicensors.push({ licensor, lineItems: [lineItem], fileUploaded: false, uploadingFile: false });
      }

    });

    sortedLicensors.forEach((item: SortedAggregates, index: number) => {
      item.lineItems.sort((a, b) => {
        if (a.schedule && a.schedule.title.toLowerCase().startsWith('standard ')) {
          return -1;
        }
        return 1;
      });
    });

    return sortedLicensors;
  }

  sortFiles(fileList: any[]) {
    const aggregates = this.state.aggregates;
    fileList.forEach((file) => {
      const index = aggregates.findIndex(a => Number(a.licensor.id) === Number(file.account_id));
      if (index !== -1) {
        aggregates[index].fileUploaded = (!!file.aggregate_file_id);
      }
    });
    this.setState({ aggregates });

  }

  loadAggregateData() {
    if (this._loadAggregateDataSource) {
      this._loadAggregateDataSource.cancel('Cancelled loadAggregateData() XHR due to new request');
    }
    this.setState({ loading: true });
    this._loadAggregateDataSource = Axios.CancelToken.source();
    Axios.all([getAggregateLineItems(this.props.report), getAggregateFileUploads(this.props.report)])
      .then(Axios.spread((lineItemsResponse: any, filesResponse: any) => {
        this.setState({ aggregates: this.sortLineItems(lineItemsResponse.data.data), loading: false });
        this.sortFiles(filesResponse.data.data);
      }));
  }

  submitAggregateData(nextStep: boolean) {
    if (this._submitAggregateDataSource) {
      this._submitAggregateDataSource.cancel('Cancelled submitAggregateDataSource() XHR due to new request.');
    }
    this.setState({ submitting: true });
    const data: {
      net_sales: any,
      royalty_report_id: number,
      royalty_schedule_id: number,
      quantity: number,
      amount_due: number,
    }[] = [];
    this.state.aggregates.forEach((aggregate: SortedAggregates) => {
      aggregate.lineItems.forEach((item: RoyaltyReportLineItem) => {
        if (!item.isReadOnly && item.isValidAggregateRow()) {
          data.push({
            net_sales: item.grossSales,
            quantity: item.quantity,
            amount_due: item.royalty,
            royalty_report_id: this.props.report.id,
            royalty_schedule_id: item.schedule.id,
          });
        }
      });
    });

    this._submitAggregateDataSource = Axios.CancelToken.source();
    Axios.post(`/api/royalty-reports/${this.props.report.id}/aggregates`, data)
      .then((response) => {

        const newAggregates = this.sortLineItems(response.data.data);
        newAggregates.forEach((licensor, i) => {
          const index = this.state.aggregates
          .findIndex(stateAggregate => stateAggregate.licensor.id === licensor.licensor.id);
          if (index !== -1) {
            newAggregates[i].fileUploaded = this.state.aggregates[index].fileUploaded;
          }
        });
        this.setState({ submitting: false, aggregates: newAggregates });
        if (nextStep) {
          this.props.history.push('submit');
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submitting: false });
      });
  }

  uploadIndividualFile(licensor: AffinityClient, file: any) {
    if (this._uploadIndividualFileSource) {
      this._uploadIndividualFileSource.cancel('Cancelled uploadIndividualFile() XHR due to new request');
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('licensor_id', String(licensor.id));
    this._uploadIndividualFileSource = Axios.CancelToken.source();
    const aggregateIndex = this.getAggregateIndex(licensor);
    if (aggregateIndex !== -1) {
      const aggregates = this.state.aggregates;
      aggregates[aggregateIndex].uploadingFile = true;
      this.setState({ aggregates });
    }
    Axios.post(`/api/royalty-reports/${this.props.report.id}/aggregates/uploads`, formData).then((response) => {
      this.sortFiles(response.data.data);
      if (aggregateIndex !== -1) {
        const aggregates = this.state.aggregates;
        aggregates[aggregateIndex].uploadingFile = false;
        this.setState({ aggregates });
      }
    });

  }

  removeFile(licensor: AffinityClient) {
    const aggregateIndex = this.getAggregateIndex(licensor);
    if (aggregateIndex !== -1) {
      const aggregates = this.state.aggregates;
      aggregates[aggregateIndex].uploadingFile = true;
      this.setState({ aggregates });
    }
    Axios.delete(`/api/royalty-reports/${this.props.report.id}/aggregates/uploads`, { data: { licensor_id: licensor.id } })
      .then((response) => {
        this.sortFiles(response.data.data);
        if (aggregateIndex !== -1) {
          const aggregates = this.state.aggregates;
          aggregates[aggregateIndex].uploadingFile = false;
          this.setState({ aggregates });
        }
      });

  }

  getAggregateIndex(licensor: AffinityClient) {
    return this.state.aggregates.findIndex(aggregate => Number(aggregate.licensor.id) === Number(licensor.id));
  }

  uploadFile(files: any[]) {
    // this.setState({ uploading: true });
    if (this._uploadFileSource) {
      this._uploadFileSource.cancel('Cancelled uploadFile() XHR due to new request');
    }
    const formData = new FormData();
    files.forEach((file)  => {
      formData.append('files[]', file);
    });
    this._uploadFileSource = Axios.CancelToken.source();
    this.setState({ uploadingFiles: true });
    Axios.post(`/api/royalty-reports/${this.props.report.id}/aggregates/uploads`, formData).then((response) => {
      this.sortFiles(response.data.data);
      this.setState({
        failedFileUploadCount: response.data.meta.failed_count,
        successfulFileUploadCount: response.data.meta.succeeded_count,
        uploadingFiles: false,
      });
    });
  }

  canContinue() {
    let canContinue = true;
    this.state.aggregates.forEach((aggregate) => {
      aggregate.lineItems.forEach((lineItem) => {
        if (!lineItem.isReadOnly) {
          if (!lineItem.isValidAggregateRow()) {
            canContinue = false;
          } else {
            if (lineItem.schedule.usesGrossSales()) {
              if (Number(lineItem.grossSales) !== 0 && !aggregate.fileUploaded) {
                canContinue = false;
              }
            }

            if (lineItem.schedule.usesQuantity()) {
              if (Number(lineItem.quantity) !== 0 && !aggregate.fileUploaded) {
                canContinue = false;
              }

            }
            if (lineItem.schedule.usesTotalRoyalties()) {
              if (Number(lineItem.royalty) !== 0 && !aggregate.fileUploaded) {
                canContinue = false;
              }
            }
          }

        }

      });
    });
    return canContinue;
  }

  canFillZeros() {
    let canFill = false;
    this.state.aggregates.forEach((aggregate) => {
      aggregate.lineItems.forEach((lineItem) => {
        if (!lineItem.isReadOnly && !lineItem.isValidAggregateRow()) {
          canFill = true;
        }

      });

    });

    return canFill;
  }

  saveAndContinue() {
    if (this.canContinue()) {
      this.submitAggregateData(true);
    }

  }

  addLicensor(licensor: AffinityClient) {
    if (this._addLicensorSource) {
      this._addLicensorSource.cancel('Canceled addLicensor() due to new request');
    }
    this._addLicensorSource = Axios.CancelToken.source();
    this.setState({ addingLicensor: true });
    Axios.post(`/api/royalty-reports/${this.props.report.id}/aggregates/add-licensor`, { licensor_id: licensor.id })
      .then((response) => {
        this.closeAggregateModal();
        const newAggregates = response.data.data.filter((aggregate: any) => aggregate.client.id === licensor.id);

        // sort alphabetically
        const updatedAggregates = this.state.aggregates.concat(this.sortLineItems(newAggregates))
          .sort((a: SortedAggregates, b: SortedAggregates) => {
            if (a.licensor.name.toLowerCase() < b.licensor.name.toLowerCase()) {
              return -1;
            }
            if (a.licensor.name.toLowerCase() > b.licensor.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });

        this.setState({ aggregates: updatedAggregates, loading: false, addingLicensor: false });
      })
      .catch(error =>  console.log(error));

  }

  setZeroValues() {
    const aggregates = this.state.aggregates;
    aggregates.forEach((aggregate: SortedAggregates, aggregateIndex: number) => {
      aggregate.lineItems.forEach((item: RoyaltyReportLineItem, lineItemIndex: number) => {
        if (!item.isReadOnly) {
          if (item.schedule.usesGrossSales() && !item.grossSales) {
            this.setValueForLineItem(item, 'gross', '0.00');
          }
          if (item.schedule.usesQuantity() && !item.quantity) {
            this.setValueForLineItem(item, 'quantity', '0');
          }
          if (item.schedule.usesTotalRoyalties() && !item.royalty) {
            this.setValueForLineItem(item, 'total', '0.00');
          }

        }
      });
    });
    this.setState({ aggregates });
  }

  render() {

    const salesDataIncompleteCount = this.props.report.salesDataIncompleteCount;
    const hasIncompleteSalesData = (salesDataIncompleteCount !== null && salesDataIncompleteCount > 0);
    const breadcrumbs = [{ name: 'Royalties' }];

    if (hasIncompleteSalesData) {
      return (
        <div>
          <div>
            <div className="panel panel-portal">
              <div className="panel-body">
                You have started the Sales Data reporting process, but have an incomplete Sales Data Report.
                You must either complete this report, or delete all Sales Data to continue reporting.
              </div>
              <div className="panel-footer">
                <Link to="sales-data" className="btn btn-primary">Review Sales Data Report</Link>
              </div>
            </div>
          </div>
        </div>
      );
      return <div>

      </div>;
    }

    let mainContent;
    if (this.state.loading) {
      mainContent = (
        <div className="react-loading-content">
          <span><FontAwesomeIcon icon={faSpinner} spin /></span>
        </div>
      );
    } else if (this.state.aggregates.length === 0) {
      mainContent = (
        <div>
          <div className="react-empty-content">
            <FontAwesomeIcon className="text-center text-primary" icon={faForward} />
            <h5 className="text-center">
              <strong>Your report does not include any licensors that accept aggregate reports.</strong>
            </h5>
            <p className="text-muted text-center"><strong>Please click continue to proceed to the next step.</strong></p>
          </div>

        </div>
      );

    } else {
      const rows = this.state.aggregates.map(aggregate =>
      <AggregateRow
        key={aggregate.licensor.id} uploadingFile={aggregate.uploadingFile}
        licensor={aggregate.licensor} lineItems={aggregate.lineItems}
        fieldValuesUpdated={this.setValueForLineItem}
        fileUploaded={aggregate.fileUploaded}
        uploadFile={this.uploadIndividualFile}
        submitting={this.state.submitting}
        deleteFile={this.removeFile}
       />,
      );

      mainContent = (

        <div>
          <div className="table-responsive">
            <table className="table royalties-table">
              <thead>
                <tr>
                  <th style={{ width: 350 }}>Licensor</th>
                  <th style={{ width: 175 }}>Schedule</th>
                  <th style={{ width: 150, textAlign: 'center' }}>Gross Sales</th>
                  <th style={{ width: 100, textAlign: 'center' }}>Quantity</th>
                  <th style={{ width: 150, textAlign: 'center' }}>Total Royalties</th>
                </tr>
              </thead>
              {rows}

            </table>
          </div>
        </div>

      );

    }

    let successStatus;
    let failureStatus;
    if (this.state.successfulFileUploadCount) {
      successStatus =
        <p className="text-primary text-center small">
          {this.state.successfulFileUploadCount} files uploaded and linked
        </p>;
    }
    if (this.state.failedFileUploadCount) {
      failureStatus =
        <p className="text-danger text-center small">
          {this.state.failedFileUploadCount} files not uploaded - unknown licensor
        </p>;
    }

    let saveAndContinue;
    let continueIcon;
    let errorText;
    if (this.canContinue()) {
      saveAndContinue = 'btn btn-primary btn-block';
      if (this.state.submitting) {
        saveAndContinue += ' disabled';
      }
      continueIcon = '';

    } else {
      saveAndContinue = 'btn btn-danger btn-block disabled';
      continueIcon = <FontAwesomeIcon icon={faLock} />;
      errorText =
        <p className="small text-danger text-center">
          You must report a value for each licensor and upload one sales detail files for each licensor
           with a non-zero aggregate report to continue.
        </p>;
    }

    const currentLicensors = this.state.aggregates.map(aggregate => aggregate.licensor);
    const mainContentFrame = (
      <div>
        <div className="panel panel-portal">
          <div className="panel-body">
            <h4 className="text-primary">Aggregate Reporting</h4>
            <p className="text-muted">
              Enter the necessary values and upload one aggregate detail file for each non-zero report.
            </p>
          </div>
          <div className="panel-body">
            {mainContent}

          </div>
        </div>
        { this.state.loading || this.state.aggregates.length === 0 || !this.canFillZeros() ?
          null : (<div className="panel panel-portal">
          <div className="aggregate-set-zero-info panel-body">
            <h5 className="text-center"><strong>Do you need to enter zero for all remaining line-items?</strong></h5>
            <p className="text-center text-muted small">
              Only select this option if you have reported all sales,
              and want to fill in all remaining line-items as zero sales.
            </p>
            <button onClick={this.setZeroValues} className="btn btn-default">
              <strong className="text-muted">Yes, report remaining line-items as zero sales.</strong>
            </button>
          </div>
        </div>
        )}
        <AddAggregateReportModal
          onClose={this.closeAggregateModal}
          shown={this.state.aggregateModalShown}
          addLicensor={this.addLicensor}
          licensors={this.state.licensors}
          currentReportLicensors={currentLicensors}
          loading={this.state.addingLicensor}
        />
      </div>

    );

    let sidebar;
    if (this.state.loading) {

      sidebar = (
        <div className="react-loading-content">
          <span><FontAwesomeIcon icon={faSpinner} spin /></span>
        </div>
      );

    } else if (this.state.aggregates.length === 0) {
      sidebar = (
        <div>
          <div className="panel-body">
            <p className="text-muted text-center">Please continue to submit your report.</p>
          </div>

          <div className="panel-footer">
            <button className={saveAndContinue} onClick={this.saveAndContinue}>{continueIcon} Save &amp; Continue</button>
            <button
              onClick={this.showAggregateModal}
              className="btn btn-default btn-block"
            >
              <FontAwesomeIcon className="text-primary" icon={faPlus}  /> Add Missing Licensor
            </button>
          </div>
        </div>
      );

    } else {
      let saveButtonText;
      let saveAndContinueText;
      let saveButtonClass;
      let saveButtonEnabled = true;
      if (this.state.submitting) {
        saveButtonText = (<FontAwesomeIcon icon={faSpinner} spin />);
        saveAndContinueText = (<FontAwesomeIcon icon={faSpinner} spin />);
        saveButtonClass = 'btn btn-primary btn-block disabled';
        saveButtonEnabled = false;
      } else {
        saveButtonText = 'Save Changes';
        saveAndContinueText = <span>{continueIcon} &nbsp; Save &amp; Continue</span>;
        saveButtonClass = 'btn btn-primary btn-block';
        saveButtonEnabled = true;
      }
      const fileUploadIcon = this.state.uploadingFiles ?
      <FontAwesomeIcon icon={faSpinner} spin /> :
      <FontAwesomeIcon icon={faUpload} className="text-muted" />;
      sidebar = (
        <div>
          <div className="panel-body">
            <Dropzone
              onDrop={this.uploadFile}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className="react-drag-drop-file-upload" >
                  <input {...getInputProps()} />
                  {fileUploadIcon}
                  <h5><strong>Drag-n-Drop Detail Files</strong></h5>
                    <p className="text-primary">
                      <small>
                        <strong>Separate detail files are required <u>for each</u> non-zero aggregate report.</strong>
                      </small>
                    </p>
                  <p className="text-muted">
                    <small>Your filename must include the licensor's name to be automatically linked.</small>
                  </p>
                </div>
              )}
            </Dropzone>
            {successStatus}
            {failureStatus}
          </div>

          <div className="panel-footer">
            <button
              style={{ marginRight: 20 }}
              onClick={() => this.submitAggregateData(false)}
              className={saveButtonClass}
              disabled={!saveButtonEnabled}
            >
              {saveButtonText}
            </button>
            <button
              className={saveAndContinue}
              onClick={this.saveAndContinue}
              disabled={!saveButtonEnabled}
            >
              {saveAndContinueText}
            </button>
            <br />
            {errorText}
          </div>
          <div className="panel-footer">
            <button
              onClick={this.showAggregateModal}
              className="btn btn-default btn-block"
            >
              <FontAwesomeIcon className="text-primary" icon={faPlus} /> Add Missing Licensor
            </button>
          </div>

        </div>
      );

    }

    const sidebarFrame = (
        <div className="panel panel-portal">
          {sidebar}

        </div>

    );

    return (
          <div >
            <ContentWithSidebar noPadding={true} main={mainContentFrame} sidebar={sidebarFrame} />
          </div>

    );

  }

}

const component = asRoyaltyPage(RoyaltyReportAggregatePage);
export { component as RoyaltyReportAggregatePage };
