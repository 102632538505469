import * as moment from 'moment';
export class CCPaymentMethod {
  id: number;
  brand: string;
  lastFour: string;
  expMonth: number;
  expYear: number;
  country: string;

  fees: { percent: string, fixed: number, calculated: number };
  createdAt: moment.Moment;

  static fromApi(data: CCPaymentMethodApiResponse) {
    const card = new CCPaymentMethod();
    card.id = data.id;
    card.brand = data.card_brand.toUpperCase();
    card.lastFour = data.account_number;
    card.expMonth = data.card_exp_month;
    card.expYear = data.card_exp_year;
    card.country = data.card_country;
    card.createdAt = moment(data.created_at);
    if (data.fees) {
      card.fees = {
        percent: data.fees.percent_fee,
        fixed: data.fees.fixed_fee,
        calculated: data.fees.calculated_fee,
      };
    }
    return card;
  }

}

interface CCPaymentMethodApiResponse {
  id: number;
  card_brand: string;
  card_exp_month: number;
  card_exp_year: number;
  card_country: string;
  account_number: string;
  fees?: { percent_fee: string, fixed_fee: number, calculated_fee: number};
  created_at: string;

}
