import Axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { AffinityClient } from '../../shared';
import { formattedFiscalYear, getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { AffinityDropzone } from '../Navigation';

interface AffinityPDFUploadStatus {
  client: AffinityClient | null;
  file: File;
  status: 'pending' | 'uploading' | 'success' | 'failure' | 'skipped';
}

export const AnnualReportUploads = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [uploading, setUploading] = React.useState<AffinityPDFUploadStatus[]>([]);
  const [licensors, setLicensors] = React.useState<AffinityClient[]>([]);
  const [year, setYear] = React.useState(0);

  React.useEffect(() => {
    getLicensors();
  },              []);

  const submitPdf = async (files: File[]) => {
    if (year && !submitting) {
      setSubmitting(true);
      const toUpload: AffinityPDFUploadStatus[] = files.map((f) => {
        const clientShortName = f.name.split('-')[0].trim();
        const c = findClient(clientShortName);
        return {
          client: c,
          file: f,
          status: 'pending',
        };

      });
      setUploading(toUpload);

      for (let i = 0; i < toUpload.length; i++) {
        if (toUpload[i].client) {

          toUpload[i].status = 'uploading';

          setUploading([...toUpload]);
          const u = toUpload[i];
          const formData = new FormData();
          formData.append('pdf', u.file, u.file.name);
          formData.append('year', `${year}`);
          const d = await Axios.post('/api/annual-report-uploads/pdf', formData)
            .catch((e) => {
              toUpload[i].status = 'failure';
            });

          if (toUpload[i].status !== 'failure') {
            toUpload[i].status = 'success';
          }
        } else {
          toUpload[i].status = 'skipped';
        }
        setUploading([...toUpload]);
      }

      setSubmitting(false);
    }
  };

  const getLicensors = async () => {
    const l = await Axios.get('/api/licensors?includes=profile');
    const lic = l.data.data.map((u: any) => new AffinityClient(u));
    setLicensors(lic);
  };

  const findClient = (shortName: string) => {
    const lic = licensors.find(l => l.shortName === shortName);
    if (lic) {
      return lic;
    }
    return null;

  };

  const statusColor = (pdf: AffinityPDFUploadStatus) => {
    switch (pdf.status)
    {
      case 'skipped':
        return 'text-danger';
      case 'failure':
        return 'text-danger';
      case 'pending':
        return 'text-warning';
      case 'uploading':
        return 'text-info';
      case 'success':
        return 'text-success';
    }
    return 'text-muted';

  };

  const date = new Date();
  const currentYear = date.getFullYear();
  const startYear = 2015;

  const availableYears = [...Array(currentYear - startYear)].map((y, i) => i + startYear).reverse();

  return (
    <FullContent breadcrumbs={[{ name: 'Annual Report Uploads' }]}>
      <DocumentTitle title={getAffinityPageTitle('Annual Report Uploads')}>
        <div>
          <div className="panel panel-portal">
            <div className="panel-heading">
              <h3>Annual Report Uploads</h3>
            </div>
            <div className="panel-body">
              <p>Please select the year and then drop Annual Report PDF files below.</p>
              <div style={{ maxWidth: 300 }} className="form-group">
                <label >Fiscal Year</label>
                <select disabled={submitting} onChange={e => setYear(Number(e.target.value))} value={year} className="form-control" name="year" >
                  <option value={0} selected disabled>Select Year</option>
                  {availableYears.map(y => (<option value={y}>{formattedFiscalYear(y)}</option>))}
                </select>
              </div>

              <AffinityDropzone
                onDropAccepted={submitPdf}
                accept="application/pdf" style={{ padding: 0, background: 'transparent' }}
              >
                <div style={{ width: '100%', display: 'flex', alignItems: 'flex-start', flexDirection:'column', minHeight: 300 }}>
                  {uploading.map(u => (<div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #eee', width: '100%' }}>
                    {u.client ? <img style={{ width: 100, height: 100 }} src={u.client.affinityImage.getSize('sm')} /> : <div style={{ width: 100, height: 100 }}></div>}
                    <h5>
                      <strong>
                      {u.client ? u.client.shortName : 'Client Unknown'} - File Name: {u.file.name} <span style={{ textTransform: 'capitalize' }} className={statusColor(u)}>{u.status}</span>
                      </strong>
                    </h5>
                    <div>
                    </div>

                  </div>))}

                </div>
              </AffinityDropzone>
            </div>
          </div>
        </div>
      </DocumentTitle>
    </FullContent>
  );
};
