import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { TrademarkUse } from '../../../shared';
import { getAffinityPageTitle } from '../../../utils';
import { ContentWithSidebar, FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { deleteTrademarkUse } from './api';
import { UsesForm } from './UsesForm';

export const UsesDetailPage = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [trademarkUse, setTrademarkUse] = React.useState<TrademarkUse | null>(null);
  const [saving, setSaving] = React.useState(false);
  const [resolutionModalShown, setResolutionModalShown] = React.useState(false);
  const [unresolovedModalShown, setUnresolvedModalShown] = React.useState(false);

  React.useEffect(() => {
    getUse();
  },              []);

  const getUse = async () => {
    const t = await Axios.get(`/api/trademark-uses/${match && match.params['useId']}`);
    setTrademarkUse(TrademarkUse.fromApi(t.data.data));

  };

  const deleteTrademark = async () => {
    const c = confirm('Are you sure you want to delete this trademark use?');
    if (c) {
      await deleteTrademarkUse(match && match.params['useId']);
      history.push(backRoute());

    }
  };

  const unresolve = async() => {

    setSaving(true);
    const t = await Axios.post(`/api/trademark-uses/${match && match.params['useId']}/unresolve`);
    setTrademarkUse(TrademarkUse.fromApi(t.data.data));
    setSaving(false);
    setUnresolvedModalShown(false);

  };

  const submit = async (values: any) => {
    if (saving) {
      return;
    }
    setSaving(true);
    const formData = new FormData();

    if (values.title) {
      formData.append('title', values.title);
    }
    if (values.description) {
      formData.append('description', values.description);
    }
    if (values.note) {
      formData.append('note', values.note);

    }
    formData.append('status_id', values.status);
    if (values.vendor.length) {
      formData.append('vendor_account_id', values.vendor[0].id);
    }
    if (values.trademark.length) {
      formData.append('client_trademark_id', values.trademark[0].id);
    }
    if (values.licensor.length) {
      formData.append('client_account_id', values.licensor[0].id);
    }
    if (values.licensors.length) {
      let count = 0;
      for (let i = 0; i < values.licensors.length; i++) {
        formData.append(`client_ids[${i}]`, values.licensors[i].id);
        for (let j = 0; j < values.licensors[i].trademarks.length; j++) {
          formData.append(`trademark_ids[${count}]`, values.licensors[i].trademarks[j].id);
          count++;
        }
      }
    }
    if (values.resolution) {
      formData.append('resolution_type_id', values.resolution);
    }
    if (values.price) {
      formData.append('price', values.price);
    }
    if (values.screenshot) {
      formData.append('screenshot', values.screenshot, values.screenshot.name);
    }
    if (values.preview) {
      formData.append('preview', values.preview, values.preview.name);
    }
    if (values.data) {
      formData.append('data', values.data, values.data.name);
    }

    if (trademarkUse) {
      const t = await Axios.post(`/api/trademark-uses/${trademarkUse.id}`, formData);
      setTrademarkUse(TrademarkUse.fromApi(t.data.data));

      setSaving(false);
    }
  };

  const requeue = async () => {
    if (trademarkUse) {
      await Axios.post(`/api/trademark-uses/${trademarkUse.id}/requeue`);
    }
  };

  if (!trademarkUse) {
    return <FullContent><LoadingSpinner /></FullContent>;
  }
  const iv = {
    id: trademarkUse.id,
    contentUrl: trademarkUse.url,
    description: trademarkUse.description,
    price: trademarkUse.price,
    title: trademarkUse.title,
    note: trademarkUse.note,
    vendor: trademarkUse.vendor ? [trademarkUse.vendor] : [],
    licensor: trademarkUse.licensor ? [trademarkUse.licensor] : [],
    licensors: trademarkUse.licensors ? trademarkUse.licensors : [],
    trademark: trademarkUse.trademark ? [trademarkUse.trademark] : [],
    screenshot: null,
    screenshotPreview: !trademarkUse.screenshot.isUsingDefault ? trademarkUse.screenshot.getSize('sm') : '',
    preview: null,
    data: null,
    dataPreview: trademarkUse.dataFile ? trademarkUse.dataFile.url : '',
    previewPreview: !trademarkUse.image.isUsingDefault ? trademarkUse.image.getSize('sm') : '',
    status: trademarkUse.statusId,
    resolution: trademarkUse.resolutionType ? trademarkUse.resolutionType.id : 0,
  };

  const backRoute = (): string => {
    let path = '';
    if (match && match.params['vendorId']) {
      path = `/vendors/${match.params['vendorId']}`;
    } else if (match && match.params['clientId']) {
      path = `/clients/${match.params['clientId']}`;
    }
    if (match && match.params['caseId']) {
      return `${path}/enforce/cases/${match.params['caseId']}`;
    }
    return `${path}/enforce/uses`;
  };

  return (
    <FullContent>
      <div>
        <Formik initialValues={iv} onSubmit={submit}>
          {formProps => (
            <Form>
              <ContentWithSidebar
                main={trademarkUse ? (
                  <div className="panel panel-portal">
                    <DocumentTitle title={getAffinityPageTitle('Uses')} />
                    <div className="panel-body">
                      <UsesForm isNew={false} {...formProps} />
                    </div>
                  </div>
                ) : <LoadingSpinner />}
                sidebar={(
                  <div>
                    {trademarkUse.case ? <div className="panel panel-portal">
                      <div className="panel-body">
                        <strong >Case #{trademarkUse.caseId}</strong>
                        <p style={{ marginTop: 10 }}><FontAwesomeIcon
                          className={trademarkUse.case.closedAt ? 'text-primary' : 'text-success'}
                          icon={faCircle}
                        /> <strong>{trademarkUse.case.closedAt ? 'Closed' : 'Open'}</strong></p>
                        <Link style={{ marginTop: 10 }} to={`/vendors/${trademarkUse.vendor.id}/enforce/cases/${trademarkUse.caseId}`} className="btn btn-primary btn-block" >
                          View Case
                        </Link>
                      </div>
                    </div> : null}
                    <div className="panel panel-portal">
                      <div className="panel-body">
                        <div className="form-group">
                          <label>Admin Note</label>
                          <Field
                            disabled={trademarkUse.resolutionType}
                            name="note"
                            className="form-control"
                            component="textarea"
                          />
                        </div>
                      </div>
                    </div>
                    {trademarkUse.resolutionType ?
                      <div className="panel panel-portal">
                        <div className="panel-body">
                          <p>
                            <FontAwesomeIcon
                              className={`text-${trademarkUse.statusClass}`}
                              icon={faCircle}
                            /> <strong>{trademarkUse.status}</strong>
                          </p>
                          <p className="text-muted">
                            {trademarkUse.resolutionType.name}
                          </p>
                          <button type="button" onClick={() => setUnresolvedModalShown(true)} className="btn btn-block btn-default">
                            Mark as Unresolved
                          </button>
                        </div>
                      </div>
                      : <div className="panel panel-portal">
                        <div className="panel-body">
                          <p>
                            <FontAwesomeIcon
                              className={`text-${trademarkUse.statusClass}`}
                              icon={faCircle}
                            /> <strong>{trademarkUse.status}</strong>
                          </p>
                          <button type="button" onClick={() => setResolutionModalShown(true)} className="btn btn-block btn-primary">
                            Mark as Resolved
                          </button>
                          <button type="button" onClick={deleteTrademark} className="btn btn-block btn-default">Delete</button>
                          <button className="btn btn-block btn-primary">{saving ? 'Saving...' : 'Save'}</button>
                          {trademarkUse.statusId !== 4 ? <button type="button" onClick={requeue} className="btn btn-block btn-default">Re-Scan</button> : null}

                        </div>
                      </div>}
                  </div>
                )}

              />
            </Form>
          )}

        </Formik>
        <GatewayModal
          shown={resolutionModalShown}
          onClose={() => setResolutionModalShown(false)}
          type={ModalType.Primary}
          title="Mark Resolved"
        >
          <Formik validationSchema={Yup.object().shape({ resolution: Yup.number().moreThan(0, 'Resolution type is required') })}  initialValues={{ resolution: 0 }} onSubmit={async (values) => {
            if (trademarkUse) {
              const v = {
                title: trademarkUse.title,
                description: trademarkUse.description,
                note: trademarkUse.note,
                status: 4,
                vendor: trademarkUse.vendor ? [trademarkUse.vendor] : [],
                trademark: trademarkUse.trademark ? [trademarkUse.trademark] : [],
                licensor: trademarkUse.licensor ? [trademarkUse.licensor] : [],
                licensors: trademarkUse.licensors ? trademarkUse.licensors : [],
                resolution: values.resolution,
                price: trademarkUse.price,
              };
              await submit(v);
              setResolutionModalShown(false);

            }

          }}>
            {(formProps: FormikProps<{ resolution: number }>) => (
              <Form>
                <Modal.Body>
                  <div className="form-group">
                    <label>
                      Resolution Type
                    </label>
                    <Field className="form-control" component="select" name="resolution">
                      <option value={0}>Select Resolution Type</option>
                      <option value={1}>Removed</option>
                      <option value={2}>Non-Infringing</option>
                      <option value={3}>Licensed</option>
                    </Field>
                    <p className="help">Choosing a resolution type will mark use as Resolved.</p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" onClick={() => setResolutionModalShown(false)} className="btn btn-default pull-left">
                    Cancel
                  </button>
                  <button className="btn btn-primary pull-right">Submit</button>

                </Modal.Footer>
              </Form>
            )}

          </Formik>

        </GatewayModal>
        <GatewayModal
          shown={unresolovedModalShown}
          onClose={() => setUnresolvedModalShown(false)}
          type={ModalType.Danger}
          title="Mark Unresolved"
        >
          <Modal.Body>
            <p className="text-center">Are you sure you want to mark this as unresolved?  This will open the existing case if it is closed.</p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={() => setUnresolvedModalShown(false)} className="btn btn-default pull-left">
              Cancel
            </button>
            <button onClick={() => unresolve()} className="btn btn-primary pull-right">Yes, Mark as Unresolved</button>

          </Modal.Footer>

        </GatewayModal>
      </div>
    </FullContent>
  );

};
