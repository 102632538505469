import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { matchPath } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { Header, PrimaryNavigation } from '../Navigation';
import { GatewayRoutes } from './GatewayRoutes';
import  NavigationErrorBoundary  from './NavigationErrorBoundary';

// interface IProps {
//   user: UserType;
// }

interface IState {
  isAccountRoute: boolean;
  secondaryAccount: any;
  loadingRoutes: boolean;
  routes: any[];
  adminRoutes: any[];
  showNav: boolean;
}

const DefaultAccount = {
  id: 0,
  image: {
    sm: 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/olp-logo-bw.jpg',
  },
};

export class GatewayContainer extends React.Component <any, IState> {
  protected _secondarAccountSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      isAccountRoute: false,
      routes: [],
      adminRoutes: [],
      secondaryAccount: DefaultAccount,
      loadingRoutes: false,
      showNav: false,
    };

    this.toggleNav = this.toggleNav.bind(this);
  }

  componentDidMount() {
    this.checkRouteType();
    this.props.history.listen((location: any, action: any) => {
      this.setState({ showNav: false });
      if (this.props.user.type === 'admin') {
        this.checkRouteType();
      }
    });
    this.getAccountRoutes = this.getAccountRoutes.bind(this);
    if (this.props.user.type === 'admin') {
      window['RouteChecker'] = this.checkRouteType.bind(this);
    }

  }

  checkRouteType() {
    if (this._secondarAccountSource) {
      this._secondarAccountSource.cancel('Cancelled getAccountRoutes() XHR due to route change');
      this.setState({ loadingRoutes: false });
    }
    const isVendorRoute = matchPath(location.pathname, { path: '/vendors/:id' });
    const isClientRoute = matchPath(location.pathname, { path: '/clients/:id' });
    if (isVendorRoute && this.props.user.type === 'admin') {
      const isInquiries = matchPath(location.pathname, { path: '/vendors/inquiries' });
      if (!isInquiries) {
        const isCurrentSecondary = Number(this.state.secondaryAccount.id) ===  Number(isVendorRoute.params['id']);
        const account = isCurrentSecondary ?
          this.state.secondaryAccount : DefaultAccount;
        this.setState({ isAccountRoute: true, secondaryAccount: account });
        if (!this.state.routes.length || !isCurrentSecondary) {
          this.getAccountRoutes(isVendorRoute.params['id']);
        }
      }
    } else if (isClientRoute && this.props.user.type === 'admin') {
      const isCurrentSecondary = Number(this.state.secondaryAccount.id) === Number(isClientRoute.params['id']);
      const account = isCurrentSecondary ?
        this.state.secondaryAccount : DefaultAccount;
      this.setState({ isAccountRoute: true, secondaryAccount: account });
      if (!this.state.routes.length || !isCurrentSecondary) {
        this.getAccountRoutes(isClientRoute.params['id']);
      }
    } else {
      this.setState({ isAccountRoute: false, routes: [], adminRoutes: [] });
    }
  }

  getAccountRoutes(id: number) {
    this.setState({ loadingRoutes: true });
    this._secondarAccountSource = Axios.CancelToken.source();
    Axios.get(`/api/secondary-nav/${id}`, { cancelToken: this._secondarAccountSource.token })
      .then((response) => {
        this.setState({
          routes: response.data.routes,
          adminRoutes: response.data.adminRoutes,
          secondaryAccount: response.data.account,
          loadingRoutes: false,
        });
      });

  }

  toggleNav() {
    this.setState({ showNav: !this.state.showNav });
  }

  render() {
    const isWelcome = this.props.location.pathname.indexOf('/welcome') !== -1;

    return (
      <div style={{ flexDirection: 'column' }} className="flex-container">
        <div className="flex-container">
          <div style={{ position: 'relative' }} className="flex-container">
            <UserContext.Provider value={this.props.user}>
              { !isWelcome ? <PrimaryNavigation
                className={this.state.showNav ? 'show-nav' : ''}
                routes={this.state.routes}
                secondaryAccount={this.state.secondaryAccount}
                isAccountRoute={this.state.isAccountRoute}
                user={this.props.user}
                loadingRoutes={this.state.loadingRoutes}
                adminRoutes={this.state.adminRoutes}
                {...this.props}
              /> : null }
              <div className="flex-container gateway-container" >

                {!isWelcome ? <Header
                  toggleNav={this.toggleNav}
                  isAccountRoute={this.state.isAccountRoute}
                  secondaryAccount={this.state.secondaryAccount}
                /> : null }
                <div className="main-content" >
                  <NavigationErrorBoundary>
                    <GatewayRoutes />
                  </NavigationErrorBoundary>
                </div>
              </div>
            </UserContext.Provider>
          </div>

        </div>

      </div>
    );

  }

}
