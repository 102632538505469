import Axios from 'axios';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { Trademark } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { UsesForm } from './UsesForm';

export const UsesManualNewPage = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const history = useHistory();
  const match = useRouteMatch<{clientId?: string, vendorId?: string, caseId?: string}>();
  if (!match) {
    return <Redirect to="/" />;
  }

  if (submitting) {
    return <FullContent>
      <LoadingSpinner />
    </FullContent>;
  }

  const submit = async (values: any) => {
    setSubmitting(true);
    const formData = new FormData();

    formData.append('web_url', values.contentUrl);
    if (values.title) {
      formData.append('title', values.title);
    }
    if (values.description) {
      formData.append('description', values.description);
    }
    if (values.price) {
      formData.append('price', values.price);
    }

    if (match.params.vendorId) {
      formData.append('vendor_account_id', match.params.vendorId);
    }
    if (values.trademark.length) {
      formData.append('client_trademark_id', values.trademark[0].id);
    }
    if (values.licensor.length) {
      formData.append('client_account_id', values.licensor[0].id);
    }
    if (values.licensors.length) {
      let count = 0;
      for (let i = 0; i < values.licensors.length; i++) {
        formData.append(`client_ids[${i}]`, values.licensors[i].id);
        for (let j = 0; j < values.licensors[i].trademarks.length; j++) {
          formData.append(`trademark_ids[${count}]`, values.licensors[i].trademarks[j].id);
          count++;
        }
      }
    }
    if (match.params.caseId) {
      formData.append('case_id', match.params.caseId);
    }
    if (values.screenshot) {
      formData.append('screenshot', values.screenshot, values.screenshot.name);
    }
    if (values.preview) {
      formData.append('preview', values.preview, values.preview.name);
    }
    if (values.data) {
      formData.append('data', values.data, values.data.name);
    }

    const u = await Axios.post('/api/trademark-uses', formData);
    if (u) {
      if (match) {
        let route = '';
        if (history.location.pathname.includes('vendors')) {
          if (match.params.caseId) {
            route = `/vendors/${match.params.vendorId}/enforce/cases/${match.params.caseId}`;
          } else {
            route = `/vendors/${match.params.vendorId}/enforce`;
          }
        } else {
          route = '/enforce';
        }
        history.push(route);
      }
    }

  };
  const iv = {
    id: null,
    contentUrl: '',
    description: '',
    price: '',
    title: '',
    vendor: [],
    licensor: [],
    licensors: [],
    trademark: [],
    screenshot: null,
    screenshotPreview: '',
    preview: null,
    previewPreview: '',
    data: null,
    dataPreview: '',
  };

  return (
    <FullContent>
      <h3><strong>Add Trademark Uses</strong></h3>
      <div className="panel panel-portal">
        <div className="panel-body">
          <Formik initialValues={iv}
                  validateOnBlur={false}
                  validateOnChange={false}
                  validationSchema={Yup.object().shape({
                    title: Yup.string().required('Title is required.'),
                    licensors: Yup.string().required('A trademark owner is required.'),
                  })}
                  onSubmit={submit}>
            {formProps => (
              <Form>
                <UsesForm isNew={true} {...formProps} />
                <button className="btn btn-primary btn-lg pull-right">Submit</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>

    </FullContent>
  );

};
