import { faFileInvoiceDollar, faUsdCircle } from '@fortawesome/pro-regular-svg-icons';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import Axios from 'axios';
import { orderBy, uniq } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useLocation, useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { ChartFooter, ChartHeader, DashboardSectionHeader, QuarterlyReportData, usePerformanceQueryParams, YearlyReportData } from '.';
import { formattedFiscalYear, toUSD, toUSDNoDecimal } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CurrencyLabel, LoadingSpinner } from '../shared';
import { HiddenGeoDataModule, HiddenInsignia, HiddenPayments, HiddenSalesDataModule } from './HiddenPerformanceModule';
import { CategoryPerformance, ChannelPerformance, GeolocationPerformance, InsigniaGraph, InsigniaStats, PerformanceStats, RetailerPerformance, VendorPerformance } from './submodules';
import { apiToPerformanceStats, DefaultPerformanceStats } from './submodules/shared';

interface PaymentStats {
  yearly: YearlyReportData[];
  quarterly: QuarterlyReportData[];
}

interface ProjectionStats {
  projections: {
    min: number;
    max: number;
    startMonth: number;
    endMonth: number;
  };
  current: {week: number, amount: number}[];
  previous: {week: number, amount: number}[];
  currentDisplayTotal: number;
  previousDisplayTotal: number;
  isNet: boolean;
}

interface AnnualReport {
  link: string;
  year: number;
}

interface DefaultDates {
  startMonth: number;
  startYear: number;
  endMonth: number;
  endYear: number;
}

export const PerformanceDashboard = () => {
  const [salesDataStats, setSalesDataStats] = React.useState<PerformanceStats>(DefaultPerformanceStats);
  const [paymentStats, setPaymentStats] = React.useState<PaymentStats>({ yearly: [], quarterly: [] });
  const [projectionStats, setProjectionStats] = React.useState<ProjectionStats>({
    projections: { min: 0, max: 0, startMonth: 1, endMonth: 3 },
    current: [],
    previous: [],
    currentDisplayTotal: 0,
    previousDisplayTotal: 0,
    isNet: false,
  });

  const [loadingProjections, setLoadingProjections] = React.useState(true);
  const [insignaStats, setInsigniaStats] = React.useState<InsigniaStats[]>([]);
  const [loadingInsignia, setLoadingInsignia] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [loadingPayments, setLoadingPayments] = React.useState(true);
  const [annualReport, setAnnualReport] = React.useState<AnnualReport | null>(null);
  const [defaultDates, setDefaultDates] = React.useState<DefaultDates | null>(null);
  const location = useLocation();
  const performanceQP = usePerformanceQueryParams();

  const getDefaultDates = async () => {
    const qs = performanceQP.toApi('');
    const response = await Axios.get(`/api/performance-reports/startEndDate?${qs}`);
    const { startMonth, startYear, endMonth, endYear } = response.data;
    return { startMonth, startYear, endMonth, endYear };
  };

  const getUrlParams = () => {
    const params = new URLSearchParams(location.search);
    const startMonth = params.get('startMonth');
    const startYear = params.get('startYear');
    const endMonth = params.get('endMonth');
    const endYear = params.get('endYear');
    return {
      startMonth: startMonth ? parseInt(startMonth, 10) : null,
      startYear: startYear ? parseInt(startYear, 10) : null,
      endMonth: endMonth ? parseInt(endMonth, 10) : null,
      endYear: endYear ? parseInt(endYear, 10) : null,
    };
  };

  React.useEffect(() => {
    const fetchDefaultDates = async () => {
      let dates = await getDefaultDates();
      const urlParams = getUrlParams();

      dates = {
        startMonth: urlParams.startMonth || dates.startMonth,
        startYear: urlParams.startYear || dates.startYear,
        endMonth: urlParams.endMonth || dates.endMonth,
        endYear: urlParams.endYear || dates.endYear,
      };

      setDefaultDates(dates);
      await Promise.all([
        getData(dates),
        getPaymentData(dates),
        getProjectionStats(dates),
        getInsiginaStats(dates),
        getAnnualReport(dates),
      ]);
    };

    fetchDefaultDates();
  },              []);

  const match = useRouteMatch();
  if (!match) {
    return <Redirect to="/" />;
  }

  const getData = async (dates: DefaultDates) => {
    setLoading(true);
    const queryParams = performanceQP.toApi('geo', [
      { label: 'startMonth', value: dates.startMonth },
      { label: 'startYear', value: dates.startYear },
      { label: 'endMonth', value: dates.endMonth },
      { label: 'endYear', value: dates.endYear },
      { label: 'year', value: null },
    ]);
    const analytics = await Axios.get(`/api/performance-reports/sales-data?${queryParams}`);
    const s = apiToPerformanceStats(analytics.data.data);

    setSalesDataStats(s);
    setLoading(false);
  };

  const getPaymentData = async (dates: DefaultDates) => {
    setLoadingPayments(true);
    const query = performanceQP.params.licensorId ? `?account_id=${performanceQP.params.licensorId}` : '';
    const performanceReports = await Axios.get(`/api/performance-reports/payments${query}`);
    const data = performanceReports.data.data;

    let yearlyData: YearlyReportData[] = orderBy(data.map((d: any) => {
      return {
        year: d.year,
        total: d.annual.current,
        previousYearTotal: d.annual.previous,
        quarters: d.quarters.map((q: any) => q.current),
      };
    }),                                          ['year'], ['asc']);

    const filteredYears = yearlyData.map(d => d.year).sort().reverse();
    const twoYears = filteredYears.length > 2 ? filteredYears.slice(0, 2) : filteredYears;
    yearlyData = yearlyData.filter(y => twoYears.includes(y.year));

    let quarterlyData: QuarterlyReportData[] = orderBy(data.reduce(
      (prev: QuarterlyReportData[], next: any) => {
        const year = Number(next.year);
        const quarters = next.quarters;
        const mappedQuarters: QuarterlyReportData[] = quarters.map((q: any) => {
          return {
            year,
            total: q.current,
            previousYearTotal: q.previous,
            quarter: q.quarter,
          };
        });
        return prev.concat(mappedQuarters);
      },
      []),
                                                       ['year'], ['asc']);

    const filteredQuarterYears = uniq(quarterlyData.map(d => d.year).sort().reverse());
    const twoQuarterYears = filteredQuarterYears.length > 2 ? filteredQuarterYears.slice(0, 2) : filteredQuarterYears;
    quarterlyData = quarterlyData.filter(y => twoQuarterYears.includes(y.year));

    setPaymentStats({ yearly: yearlyData, quarterly: quarterlyData });
    setLoadingPayments(false);
  };

  const getProjectionStats = async (dates: DefaultDates) => {
    const queryParams = performanceQP.toApi('geo', [
      { label: 'startMonth', value: dates.startMonth },
      { label: 'startYear', value: dates.startYear },
      { label: 'endMonth', value: dates.endMonth },
      { label: 'endYear', value: dates.endYear },
      { label: 'year', value: null },
    ]);
    const p = await Axios.get(`/api/performance-reports/projections?${queryParams}`);
    const data = p.data.data;
    const newProj = { ...projectionStats };
    newProj.projections.endMonth = data.projections.end_month;
    newProj.projections.startMonth = data.projections.start_month;
    newProj.projections.min = data.projections.min;
    newProj.projections.max = data.projections.max;
    newProj.current = data.quarter.current ? data.quarter.current : [];
    newProj.previous = data.quarter.previous ? data.quarter.previous : [];
    newProj.currentDisplayTotal = data.quarter.currentDisplayTotal;
    newProj.previousDisplayTotal = data.quarter.previousDisplayTotal;
    newProj.isNet = data.quarter.isNet;
    setProjectionStats(newProj);
    setLoadingProjections(false);
  };

  const getInsiginaStats = async (dates: DefaultDates) => {
    const queryParams = performanceQP.toApi('geo', [
      { label: 'startMonth', value: dates.startMonth },
      { label: 'startYear', value: dates.startYear },
      { label: 'endMonth', value: dates.endMonth },
      { label: 'endYear', value: dates.endYear },
      { label: 'year', value: null },
    ]);
    const i = await Axios.get(`/api/performance-reports/insignia?${queryParams}`);
    const data = i.data.data;
    setInsigniaStats(data);
    setLoadingInsignia(false);
  };

  const getAnnualReport = async (dates: DefaultDates) => {
    const accountId = performanceQP.params.licensorId ? `/${performanceQP.params.licensorId}` : '';
    const report = await Axios.get(`/api/v2/accounts${accountId}/annual-reports?startMonth=${dates.startMonth}&startYear=${dates.startYear}&endMonth=${dates.endMonth}&endYear=${dates.endYear}`);
    if (report.data.length) {
      const r = report.data[0];
      setAnnualReport({ year: Number(r.year), link: r.file.url });
    }
  };

  const yearBarData: Chart.ChartData = {
    datasets: [1, 2, 3, 4].map((quarter, index) => {
      return {
        data: paymentStats.yearly.map((d) => {
          if (d.quarters.length >= quarter) {
            return d.quarters[index];
          }
          return 0;
        }),
        label: `Q${quarter}`,
      };
    }),
    labels: paymentStats.yearly.map(d => formattedFiscalYear(d.year)),
  };

  const yearBarOptions: Chart.ChartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        footer: (tooltip, data: any) => {
          let sum = 0;
          tooltip.forEach((tooltipItem: any) => {
            sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          });
          return `Annual: ${toUSD(sum)}`;
        },
        label: (tooltip, data: any) => {
          if (tooltip.index !== undefined && tooltip.datasetIndex !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const quarter = data.datasets[tooltip.datasetIndex].label;
            return `${quarter}: ${toUSD(value)}`;
          }
          return '$';
        },
      },
    },
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) => {
            return `${toUSDNoDecimal(value)}`;
          },
        },
      }],
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  const availableYears = uniq(paymentStats.quarterly.map(d => d.year));
  const quarterlyDataSet = [1, 2, 3, 4].map((quarter, index) => {
    const data = availableYears.map((y) => {
      const quarterValue = paymentStats.quarterly.filter(d => d.quarter === quarter && d.year === y);
      if (quarterValue.length) {
        return quarterValue[0].total;
      }
      return 0;
    });

    return {
      data,
      label: `Q${quarter}`,
    };
  });

  const quarterlyBarData: Chart.ChartData = {
    datasets: quarterlyDataSet,
    labels: availableYears.map(d => formattedFiscalYear(d)),
  };

  const quarterlyBarOptions: Chart.ChartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        footer: (tooltip, data: any) => {
          let sum = 0;
          tooltip.forEach((tooltipItem: any) => {
            sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          });
          return `Annual: ${toUSD(sum)}`;
        },
        label: (tooltip, data: any) => {
          if (tooltip.index !== undefined && tooltip.datasetIndex !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const quarter = data.datasets[tooltip.datasetIndex].label;
            return `${quarter}: ${toUSD(value)}`;
          }
          return '$';
        },
      },
    },
    scales: {
      xAxes: [{
        stacked: false,
      }],
      yAxes: [{
        stacked: false,
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) => {
            return `${toUSDNoDecimal(value)}`;
          },
        },
      }],
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  let currentStart = 0;
  let currentEnd = 0;
  let previousStart = 0;
  let previousEnd = 0;
  if (projectionStats.current.length) {
    currentStart = projectionStats.current[0].week;
    currentEnd = projectionStats.current[projectionStats.current.length - 1].week;
  }
  if (projectionStats.previous.length) {
    previousStart = projectionStats.previous[0].week;
    previousEnd = projectionStats.previous[projectionStats.previous.length - 1].week;
  }
  const starts = [currentStart, previousStart].filter(n => n !== 0);
  const ends = [currentEnd, previousEnd].filter(n => n !== 0);

  let startWeek = starts[0] ? starts[0] : 0;
  let endWeek = ends[0] ? ends[0] : 0;
  if (starts.length > 1) {
    startWeek = Math.min(starts[0], starts[1]);
  }

  if (ends.length > 1) {
    endWeek = Math.max(ends[0], ends[1]);
  }

  const weeks = Array(endWeek - startWeek + 1).fill(1);
  const mappedWeeks = weeks.map((w, index) => startWeek + index);
  let currentTotal = 0;
  const mappedCurrentData = mappedWeeks.map((w) => {
    const data = projectionStats.current.find(v => v.week === w);
    if (data) {
      currentTotal = currentTotal + Number(data.amount);
      return currentTotal;
    }
    return null;
  });
  let previousTotal = 0;
  const mappedPreviousData = mappedWeeks.map((w) => {
    const data = projectionStats.previous.find(v => v.week === w);
    if (data) {
      previousTotal = previousTotal + Number(data.amount);
      return previousTotal;
    }
    return previousTotal;
  });

  const projectionsChartData = {
    labels: mappedWeeks.map((w) => {
      const date = moment().week(w).day(6);
      return date.format('MM/DD');
    }),
    datasets: [
      {
        data: mappedCurrentData,
        fill: false,
        backgroundColor: '#4f6980',
        borderColor: '#4f6980',
        lineTension: 0.1,
      },
      {
        data: mappedPreviousData,
        fill: false,
        backgroundColor: '#d3d3d3',
        borderColor: '#d3d3d3',
        lineTension: 0.1,
      },
    ],
  };

  const subHeader = `In progress ${projectionStats.isNet ? 'net' : 'gross'} payments compared with last year.`;
  const showQuarterComparisons = projectionStats.current.length > 1;

  return (
    <FullContent>
      {annualReport ? (
        <div className="panel panel-portal">
          <div className="panel-body">
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
              <strong style={{ fontSize: 15 }}>Annual Report</strong>
              <p className="text-muted">
                Please click below to download a digital copy of your {formattedFiscalYear(annualReport.year)} annual report.
              </p>
              <a href={`/api/v2/accounts${performanceQP.params.licensorId ? `/${performanceQP.params.licensorId}` : ''}/annual-reports/download?year=${annualReport.year}`} className="btn btn-primary">Download Report</a>
            </div>
          </div>
        </div>
      ) : null}
      <DashboardSectionHeader
        title="Payments"
        subText="Review payments collected and processed by Affinity."
        icon={faUsdCircle}
      />
      {(loadingProjections || showQuarterComparisons) ? (
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              {!loadingProjections ? (
                <div className="panel-body">
                  <ChartHeader header="This Quarter" subHeader={subHeader} />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <h4 style={{ color: '#4f6980' }}>In Progress</h4>
                      <h4>
                        <CurrencyLabel value={projectionStats.currentDisplayTotal} />
                      </h4>
                    </div>
                    <div>
                      <h4 className="text-muted">Previous Year</h4>
                      <h4 className="text-muted">
                        <CurrencyLabel value={projectionStats.previousDisplayTotal} />
                      </h4>
                    </div>
                  </div>
                  <div style={{ height: 300, maxHeight: 300 }} className="row">
                    <div className="col-md-12">
                      <Line
                        height={300}
                        options={{
                          legend: {
                            display: false,
                          },
                          tooltips: {
                            enabled: false,
                          },
                          animation: {
                            duration: 0,
                          },
                          scales: {
                            xAxes: [{
                              ticks: {},
                            }],
                            yAxes: [{
                              ticks: {
                                display: false,
                              },
                            }],
                          },
                          elements: {
                            point: {
                              radius: 0,
                            },
                          },
                          maintainAspectRatio: false,
                        }}
                        data={projectionsChartData}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <LoadingSpinner />
              )}
            </div>
          </div>
        </div>
      ) : null}
      {!loadingPayments && paymentStats.yearly.length === 0 ? (
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <HiddenPayments />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="panel panel-portal">
              {loadingPayments ? (
                <div className="panel-body"><LoadingSpinner /></div>
              ) : (
                <div className="panel-body">
                  <ChartHeader header="Fiscal Years" subHeader="Payments by fiscal year" />
                  <div style={{ height: 300, maxHeight: 300 }}>
                    <Bar options={yearBarOptions} data={yearBarData} />
                  </div>
                  <ChartFooter isPartial={false} link={`${location.pathname}/payments/fiscal-years`} />
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="panel panel-portal">
              {loadingPayments ? (
                <div className="panel-body"><LoadingSpinner /></div>
              ) : (
                <div className="panel-body">
                  <ChartHeader header="Fiscal Quarters" subHeader="Payments by fiscal quarter" />
                  <div style={{ height: 300, maxHeight: 300 }}>
                    <Bar options={quarterlyBarOptions} data={quarterlyBarData} />
                  </div>
                  <ChartFooter isPartial={false} link={`${location.pathname}/payments/fiscal-quarters`} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <br />
      <DashboardSectionHeader
        title="Sales Data"
        subText="Review sales data submitted by licenses and audited by Affinity."
        icon={faFileInvoiceDollar}
      />
      {salesDataStats.salesDataDisplayable ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <VendorPerformance
                    vendors={salesDataStats.vendors}
                    loading={loading}
                    large={false}
                    link={`${location.pathname}/licensees`}
                    isAggregate={salesDataStats.isAggregate}
                    onDashboard={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <CategoryPerformance
                    categories={salesDataStats.categories}
                    loading={loading}
                    large={false}
                    link={`${location.pathname}/categories`}
                    isAggregate={salesDataStats.isAggregate}
                    onDashboard={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <ChannelPerformance
                    channels={salesDataStats.channels}
                    loading={loading}
                    large={false}
                    link={`${location.pathname}/channels`}
                    isAggregate={salesDataStats.isAggregate}
                    onDashboard={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <RetailerPerformance
                    retailers={salesDataStats.retailers}
                    loading={loading}
                    large={false}
                    link={`${location.pathname}/retailers`}
                    isAggregate={salesDataStats.isAggregate}
                    onDashboard={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="panel panel-portal">
          <div className="panel-body">
            <HiddenSalesDataModule />
          </div>
        </div>
      )}
      {salesDataStats.geoDisplayable ? (
        <div>
          <div className="row">
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <GeolocationPerformance
                    large={false}
                    loading={loading}
                    states={salesDataStats.states}
                    cities={salesDataStats.cities}
                    link={`${location.pathname}/geolocation`}
                    section="states"
                    isAggregate={salesDataStats.isAggregate}
                    onDashboard={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="panel panel-portal">
                <div className="panel-body">
                  <GeolocationPerformance
                    large={false}
                    loading={loading}
                    states={salesDataStats.states}
                    cities={salesDataStats.cities}
                    link={`${location.pathname}/geolocation`}
                    section="cities"
                    isAggregate={salesDataStats.isAggregate}
                    onDashboard={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="panel panel-portal">
          <div className="panel-body">
            <HiddenGeoDataModule />
          </div>
        </div>
      )}
      <br />
      <DashboardSectionHeader
        title="Design Insignia"
        subText="Track insignia on product designs"
        icon={faImage}
      />
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-portal">
            <div className="panel-body">
              {!loadingInsignia && insignaStats.length === 0 ? <HiddenInsignia /> : <InsigniaGraph loading={loadingInsignia} insignia={insignaStats} />}
            </div>
          </div>
        </div>
      </div>
    </FullContent>
  );
};
