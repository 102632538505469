export class File {
  id: number;
  previousFileName: string;
  url: string;

  constructor(data?: any) {
    if (data) {
      this.id = Number(data.id);
      this.previousFileName = String(data.previous_file_name);
      this.url = String(data.url);
    }
  }
}
