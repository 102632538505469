import { faDownload, faFilePdf, faStickyNote } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { orderBy, uniq } from 'lodash';
import * as React from 'react';
import { useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { formattedFiscalYear, getCurrentReportQuarter, toUSD, toUSDNoDecimal } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CurrencyLabel } from '../shared';
import { PaymentReportsGuide } from './PaymentReportsGuide';
import ExportButton from './submodules/ExportButton';

export interface QuarterlyReportData {
  year: number;
  quarter: 1 | 2 | 3| 4;
  total: number;
  previousYearTotal: number;
  adjustments: string[];
  pdf: string;
  placeholder: boolean;
}

export const FiscalQuartersPage = () => {
  const [reportData, setReportData] = React.useState<QuarterlyReportData[]>([]);
  const user = React.useContext(UserContext);
  const params = useParams();
  const licensorId = params['licensorId'];
  const chartRef = useRef<any>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  React.useEffect(() => {
    getData();

  },              []);

  const getData = async () => {
    const query = licensorId ? `?account_id=${licensorId}` : '';
    const performanceReports = await Axios.get(`/api/performance-reports/payments${query}`);
    const data = performanceReports.data.data;
    const reportData: QuarterlyReportData[] = orderBy(data.reduce(
      (prev: QuarterlyReportData[], next: any) => {
        const year = next.year;
        const quarters = next.quarters;
        const mappedQuarters: QuarterlyReportData[] = quarters.map((q: any) => {
          return {
            year,
            total: q.current,
            previousYearTotal: q.previous,
            quarter: q.quarter,
            adjustments: q.adjustments,
            pdf: q.pdf,
            placeholder: q.placeholder,
          };
        });

        return prev.concat(mappedQuarters);
      },
      []),                                            ['year'], ['asc']);
    let foundNonZero = false;
    let removeCount = 0;

    reportData.forEach((v, index) => {
      if (!foundNonZero) {
        if (v.total === 0) {
          removeCount++;
        } else {
          foundNonZero = true;
        }
      }
    });
    reportData.splice(0, removeCount);
    setReportData(reportData);

  };

  const availableYears = uniq(reportData.map(d => d.year));

  const dataSets = [1, 2, 3, 4].map((quarter, index) => {
    const data = availableYears.map((y) => {
      const quarterValue = reportData.filter(d => d.quarter === quarter && d.year === y);
      if (quarterValue.length) {
        return quarterValue[0].total;
      }

      return 0;
    });

    return {
      data,
      label: `Q${quarter}`,
    };

  });

  const barData: Chart.ChartData = {
    datasets: dataSets,
    labels: availableYears.map(d => formattedFiscalYear(d)),
  };

  const barOptions: Chart.ChartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        footer: (tooltip, data: any) => {
          let sum = 0;
          tooltip.forEach((tooltipItem: any) => {
            sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          });
          return `Annual: ${toUSD(sum)}`;

        },
        label: (tooltip, data: any) => {
          if (tooltip.index !== undefined && tooltip.datasetIndex !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const quarter = data.datasets[tooltip.datasetIndex].label;
            return `${quarter}: ${toUSD(value)}`;
          }
          return '$';

        },

      },

    },
    scales: {
      xAxes: [{
        stacked: false,
      }],
      yAxes: [{
        stacked: false,
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) => {
            return `${toUSDNoDecimal(value)}`;
          },
        },
      }],
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  const adjustmentNotesTooltip = (adjustments: string[]) => {
    const note = adjustments.map(n => (<div>{n} <br /></div>));
    return <Tooltip placement="top">{note}</Tooltip>;
  };

  const rows = reportData
    .filter(d => !d.placeholder)
    .map((d: QuarterlyReportData) => {
      const comparisonDollarAmount = d.previousYearTotal !== 0 ? d.total - d.previousYearTotal : 0;
      const comparisonPercentage = d.previousYearTotal !== 0 ? ((d.total / d.previousYearTotal) * 100) - 100 : 0;

      let comparisonDollarIndicator = null;
      if (comparisonDollarAmount > 0) {
        comparisonDollarIndicator = <FontAwesomeIcon icon={faCaretUp} className="text-success" />;
      } else if (comparisonDollarAmount < 0) {
        comparisonDollarIndicator = <FontAwesomeIcon icon={faCaretDown} className="text-danger" />;
      }

      let comparisonPercentIndicator = null;
      if (comparisonPercentage > 0) {
        comparisonPercentIndicator = <FontAwesomeIcon icon={faCaretUp} className="text-success" />;
      } else if (comparisonPercentage < 0) {
        comparisonPercentIndicator = <FontAwesomeIcon icon={faCaretDown} className="text-danger" />;
      }

      return (
        <tr className="text-center">
          <td>
            <Link to={`fiscal-quarters/${d.year}/${d.quarter}`}>Q{d.quarter} {formattedFiscalYear(d.year)}</Link>
          </td>
          <td><CurrencyLabel value={d.total} /></td>
          <td>{d.previousYearTotal !== 0 ? <CurrencyLabel value={d.previousYearTotal} /> : '—'}</td>
          <td>
            {comparisonDollarIndicator} {comparisonDollarAmount !== 0 ? <CurrencyLabel value={Math.abs(comparisonDollarAmount)} /> : <span>—</span>}
          </td>
          <td>
            {comparisonPercentIndicator} {d.previousYearTotal !== 0 ? (
            <NumberFormat
              suffix="%"
              displayType="text"
              decimalScale={2}
              value={Math.abs(comparisonPercentage)}
            />
          ) : <span>—</span>}
          </td>
          <td>
            {d.adjustments && d.adjustments.length ? <OverlayTrigger placement="top" overlay={adjustmentNotesTooltip(d.adjustments)}><span><FontAwesomeIcon icon={faStickyNote} /></span></OverlayTrigger> : null}
            {d.pdf ? <OverlayTrigger placement="top" overlay={<Tooltip placement="top">Download</Tooltip>}><span style={{ marginLeft: d.adjustments && d.adjustments.length ? 10 : 0 }}>
              <a
                href={`/api/report_saved_royalties/${d.year}/${d.quarter}/pdf?${user.type === 'admin' ? `licensor=${params['licensorId']}` : ''}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faDownload} />
              </a>
            </span></OverlayTrigger> : null}
          </td>
        </tr>
      );
    }).reverse();

  const exportUrl = licensorId ? `/clients/${licensorId}/reports/royalties/csv` : '/reports/royalties/csv';

  return (
    <FullContent >
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3><strong>Fiscal Quarters</strong></h3>
          <div style={{ height: 500, maxHeight: 500 }}>
            <Bar
              data={barData}
              options={barOptions}
              ref={chartRef}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10, marginBottom: 10 }}>
            <ExportButton tableRef={tableRef} chartRef={chartRef}/>

          </div>
          <table className="table table-bordered table-striped" ref={tableRef}>
            <thead>
              <tr className="text-center">
                <th className="text-center">Quarter</th>
                <th className="text-center">Royalties</th>
                <th className="text-center">Previous Year</th>
                <th className="text-center">$ Comparison</th>
                <th className="text-center">% Comparison</th>
                <th className="text-center"><FontAwesomeIcon icon={faDownload} /></th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>

        </div>
      </div>
      <PaymentReportsGuide />
    </FullContent>
  );

};
