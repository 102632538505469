import * as moment from 'moment';
import { DistributionChannel } from './DistributionChannel';
import { Insignia } from './Insignia';
import { ProductCategory } from './ProductCategory';

export class RoyaltySchedule {
  id: number;
  deletedAt: moment.Moment | null;
  rateSalesStartingDate: moment.Moment | null;
  rateSalesEndingDate: moment.Moment | null;
  minimumStartingYear: number;
  minimumEndingYear: number;
  isDefaultSchedule: boolean;
  isExempt: boolean;
  isCustom: boolean;
  isVendorSpecific: boolean;
  isExclusive: boolean;
  title: string;
  description: string;
  royaltyRate: number;
  percentageDescription: string;
  minimumRoyaltyPerUnit: number;
  flatRoyaltyPerUnit: number;
  brandRestriction: string;
  minimumGuarantee: number;
  unitDefinition: string;
  productCategories: ProductCategory[];
  distributionChannels: DistributionChannel[];
  insignia: Insignia[];
  exclusiveVendors: any[];
  countMetadata: any;
  licenseIds: any[];

  constructor(data?: any) {
    if (data) {
      this.id = Number(data.id);
      this.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;
      this.isDefaultSchedule = data.is_default_schedule;
      this.isExempt = data.is_exempt;
      this.isCustom = data.is_custom;
      this.isVendorSpecific = data.is_vendor_specific;
      this.isExclusive = data.is_exclusive;
      this.title = data.title;
      this.description = data.description;
      this.royaltyRate = data.royalty_rate;
      this.percentageDescription = data.percentage_description;
      this.minimumRoyaltyPerUnit = data.minimum_royalty_per_unit;
      this.flatRoyaltyPerUnit = data.flat_royalty_per_unit;
      this.brandRestriction = data.brand_restriction;
      this.minimumGuarantee = data.minimum_guarantee;
      this.unitDefinition = data.unit_definition ? data.unit_definition : 'per unit';
      this.minimumStartingYear = data.minimum_starting_year;
      this.minimumEndingYear = data.minimum_ending_year;
      this.rateSalesStartingDate = data.rate_sales_starting_date ? moment(data.rate_sales_starting_date) : null;
      this.rateSalesEndingDate = data.rate_sales_ending_date ? moment(data.rate_sales_ending_date) : null;

      this.productCategories = data.product_categories ? (
        data.product_categories.map((category: any) => (new ProductCategory(category)))
      ) : (
        []
      );
      this.distributionChannels = data.distribution_channels ? (
          data.distribution_channels.map((channel: any) => (new DistributionChannel(channel)))
      ) : (
          []
      );
      this.insignia = data.brand_marks ? (
          data.brand_marks.map((mark: any) => (new Insignia(mark)))
      ) : (
          []
      );
      this.exclusiveVendors = data.exclusive_vendors ? data.exclusive_vendors : [];
      this.countMetadata = data.count_metadata ? data.count_metadata : null;
      this.licenseIds = data.license_ids ? data.license_ids : [];
    }
  }

  usesGrossSales() {
    return (this.minimumRoyaltyPerUnit > 0 || this.royaltyRate > 0) && !this.isCustom;
  }

  usesQuantity() {
    return (this.flatRoyaltyPerUnit > 0 || this.minimumRoyaltyPerUnit > 0) && !this.isCustom;
  }

  usesTotalRoyalties() {
    return this.isCustom;
  }

  labelText() {
    if (this.isCustom) {
      return 'Custom Rate';
    }
    if (this.isExempt) {
      return 'Royalty Exempt';
    }
    if (this.royaltyRate > 0) {
      if (this.minimumRoyaltyPerUnit > 0) {

        // tslint:disable-next-line:max-line-length
        return `${this.royaltyRate}% ${this.percentageDescription}, $${this.minimumRoyaltyPerUnit} minimum royalty ${this.unitDefinition}`;
      }
      return `${this.royaltyRate}% ${this.percentageDescription}`;
    }
    if (this.minimumRoyaltyPerUnit > 0) {
      return `$${this.minimumRoyaltyPerUnit} minimum royalty ${this.unitDefinition}`;

    }

    if (this.flatRoyaltyPerUnit > 0) {
      return `$${this.flatRoyaltyPerUnit} ${this.unitDefinition}`;

    }

    return 'Unknown Royalty Rate';
  }

}
