import { ImageOverlay, LatLngBounds } from 'leaflet';
import { MapLayer, MapLayerProps, withLeaflet } from 'react-leaflet';

interface IProps extends MapLayerProps {
  image: HTMLImageElement;
  url: string;
}

class DesignsOverlay extends MapLayer<IProps, ImageOverlay>{
  createLeafletElement(props: IProps): ImageOverlay {
    if (props.leaflet && props.leaflet.map) {
      const map = props.leaflet.map;
      const southWest = map.unproject([0, props.image.naturalHeight], map.getMaxZoom() - 1);
      const northEast = map.unproject([props.image.naturalWidth, 0], map.getMaxZoom() - 1);
      const bounds = new LatLngBounds(southWest, northEast);
      const overlay = new ImageOverlay(props.url, bounds, { interactive: true });
      // recenter image
      map.fitBounds(bounds, {
        padding: [5, 5],
        animate: false,
      });
      return overlay;
    }
    const fakeBounds = new LatLngBounds({ lat: 123, lng: 123 }, { lat: 123, lng: 123 });
    const el = new ImageOverlay('', fakeBounds);
    return el;
  }

}

export default withLeaflet<IProps>(DesignsOverlay);
