import * as React from 'react';
import { AffinityClient } from '../../../shared';
import { DesignAlert, LicensedAlert, LicensorCategoryAlert } from './DesignAlert';

interface IProps {
  licensedAlert: LicensedAlert;
  categoryAlert: LicensorCategoryAlert ;
  categories: any[];
  terms: any[];
  licensor: AffinityClient;
  loadingLicenses: boolean;
}

export class DesignLicensorInformation extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const terms = this.props.terms.sort((a, b) => a.type === 'approved' ? -1 : 1).map(term => (
      <div key={term.id} className="col-md-6">
        <h3>{term.title}</h3>
        <div dangerouslySetInnerHTML={{ __html: term.text }}></div>
      </div>
    ));
    return (
      <div>
        <h2>{this.props.licensor.name}</h2>
        <DesignAlert
          loading={this.props.loadingLicenses}
          category={[this.props.categoryAlert]}
          licensed={this.props.licensedAlert}
        />
        <div className="row">
          {terms}

        </div>
      </div>
    );
  }

}
