import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface FileAttachmentButtonProps {
  onFileSelect: ([]) => void;
  inputId?: string;
}

interface FileAttachmentButtonState {
  attachedFiles: File[];
}

class FileAttachmentButton extends React.Component<FileAttachmentButtonProps, FileAttachmentButtonState> {
  constructor(props: FileAttachmentButtonProps) {
    super(props);
    this.state = {
      attachedFiles: [],
    };
  }

  updateFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ attachedFiles: [event.target.files[0]] });
      this.props.onFileSelect([event.target.files[0]]);
    }
  }

  render() {
    const inputId = this.props.inputId || 'fileInput';

    return (
      <div>
        <button type="button" className="btn btn-default btn-block" style={{ marginBottom: 10, marginTop: 10 }} onClick={() => {
          const input = document.getElementById(inputId);
          if (input) {
            input.click();
          }
        }}>
          <span style={{
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
            <FontAwesomeIcon style={{ marginLeft: 10 }} className="text-primary" icon={faPaperclip} />
            {this.state.attachedFiles.length > 0 ? this.state.attachedFiles[0].name : 'Add Attachment'}
          </span>
        </button>
        <input
          type="file"
          id={inputId}
          style={{ display: 'none' }}
          onChange={this.updateFile}
          multiple={false}
        />
      </div>
    );
  }
}

export default FileAttachmentButton;
