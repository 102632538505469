import { faCaretDown, faCaretUp, faStickyNote } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { formattedFiscalYear, getCurrentReportQuarter, toUSD, toUSDNoDecimal } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CurrencyLabel } from '../shared';
import { PaymentReportsGuide } from './PaymentReportsGuide';
import ExportButton from './submodules/ExportButton';

export interface YearlyReportData {
  year: number;
  total: number;
  previousYearTotal: number;
  quarters: number[];
  hasAdjustments: boolean;
  adjustments: {quarter: number, adjustments: string[]}[];
}

export const FiscalYearsPage = () => {
  const [reportData, setReportData] = React.useState<YearlyReportData[]>([]);
  const params = useParams();
  const licensorId = params['licensorId'];
  const chartRef = useRef<any>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const user = React.useContext(UserContext);
  React.useEffect(() => {
    getData();

  },              []);

  const getData = async () => {

    const query = licensorId ? `?account_id=${licensorId}` : '';
    const performanceReports = await Axios.get(`/api/performance-reports/payments${query}`);
    const data = performanceReports.data.data;
    const reportData: YearlyReportData[] = orderBy(data.map((d: any) => {
      const hasAdjustments = d.quarters.reduce((prev: boolean, next: any) => {
        if (next.adjustments && next.adjustments.length) {
          return true;
        }
        return prev;
      },                                       false);
      return {
        hasAdjustments,
        year: d.year,
        total: d.annual.current,
        previousYearTotal: d.annual.previous,
        quarters: d.quarters.map((q: any) => q.current),
        adjustments: d.quarters.map((q: any) => {
          return { quarter: q.quarter, adjustments: q.adjustments };
        }),
      };
    }),                                            ['year'], ['asc']);

    let foundNonZero = false;
    let removeCount = 0;
    reportData.forEach((v, index) => {
      if (!foundNonZero) {
        if (v.total === 0) {
          removeCount++;

        } else {
          foundNonZero = true;
        }
      }
    });
    reportData.splice(0, removeCount);
    setReportData(reportData);
  };

  const barData: Chart.ChartData = {
    datasets: [1, 2, 3, 4].map((quarter, index) => {
      return {
        data: reportData.map((d) => {
          if (d.quarters.length >= quarter) {
            return d.quarters[index];
          }
          return 0;
        }),
        label: `Q${quarter}`,
      };

    }),
    labels: reportData.map(d => formattedFiscalYear(d.year)),
  };

  const barOptions: Chart.ChartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        footer: (tooltip, data: any) => {
          let sum = 0;
          tooltip.forEach((tooltipItem: any) => {
            sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          });
          return `Annual: ${toUSD(sum)}`;

        },
        label: (tooltip, data: any) => {
          if (tooltip.index !== undefined && tooltip.datasetIndex !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const quarter = data.datasets[tooltip.datasetIndex].label;
            return `${quarter}: ${toUSD(value)}`;
          }
          return '$';

        },

      },

    },
    scales: {
      xAxes: [{
        stacked: true,
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          beginAtZero: true,
          callback: (value, index, values) => {
            return `${toUSDNoDecimal(value)}`;
          },
        },
      }],
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  const currentFiscalQuarter = getCurrentReportQuarter();
  const currentYear = currentFiscalQuarter.year;
  const currentQuarter = currentFiscalQuarter.quarter;

  const adjustmentNotesTooltip = (adjustments: {quarter: number, adjustments: string[]}[]) => {
    const notes = adjustments.map((a) => {
      if (a.adjustments && a.adjustments.length) {
        return <div>
          <div style={{ display: 'flex' }}>
            <span><strong>Q{a.quarter}: </strong></span>
            <span style={{ marginLeft: 10 }}>{a.adjustments.map(n => <span>{n} <br /></span>)}</span>
          </div>
        </div>;

      }
      return <></>;
    });
    return <Tooltip placement="top">{notes}</Tooltip>;
  };

  const rows = reportData.map((d: YearlyReportData) => {
    const comparisonDollarAmount = d.previousYearTotal !== 0 ? d.total - d.previousYearTotal : 0;
    const comparisonPercentage = d.previousYearTotal !== 0 ? ((d.total / d.previousYearTotal) * 100) - 100 : 0;

    let comparisonDollarIndicator = null;
    if (comparisonDollarAmount > 0) {
      comparisonDollarIndicator = <FontAwesomeIcon icon={faCaretUp} className="text-success" />;
    } else if (comparisonDollarAmount < 0) {
      comparisonDollarIndicator = <FontAwesomeIcon icon={faCaretDown} className="text-danger" />;
    }

    let comparisonPercentIndicator = null;
    if (comparisonPercentage > 0) {
      comparisonPercentIndicator = <FontAwesomeIcon icon={faCaretUp} className="text-success" />;
    } else if (comparisonPercentage < 0) {
      comparisonPercentIndicator = <FontAwesomeIcon icon={faCaretDown} className="text-danger" />;
    }

    return (
      <tr className="text-center">
        <td>{formattedFiscalYear(d.year)}</td>
        <td><CurrencyLabel value={d.total} /></td>
        <td>{d.previousYearTotal !== 0 ? <CurrencyLabel value={d.previousYearTotal} /> : '—'}</td>
        {d.year < currentYear || (d.year === currentYear && currentQuarter === 4) ?
        <td>
          {comparisonDollarIndicator} {d.previousYearTotal !== 0 && comparisonDollarAmount !== 0 ? <CurrencyLabel value={Math.abs(comparisonDollarAmount)} /> : <span>—</span>}
        </td> : <td> - </td>}

        {d.year < currentYear || (d.year === currentYear && currentQuarter === 4) ?
        <td>
          {comparisonPercentIndicator} {d.previousYearTotal !== 0 && comparisonPercentage !== 0 ? (
          <NumberFormat
            suffix="%"
            displayType="text"
            decimalScale={2}
            value={Math.abs(comparisonPercentage)}
          />
        ) : <span>—</span>}
        </td> : <td> - </td>}
        <td>
          {d.hasAdjustments ? (
            <OverlayTrigger placement="top" overlay={adjustmentNotesTooltip(d.adjustments)}>
              <span style={{ marginLeft: 10 }}><FontAwesomeIcon icon={faStickyNote} /></span>
            </OverlayTrigger>
          ) : null}
        </td>
      </tr>
    );
  }).reverse();

  const exportUrl = licensorId ? `/clients/${licensorId}/reports/royalties/csv` : '/reports/royalties/csv';

  return (
    <FullContent >
      <div className="panel panel-portal">
        <div className="panel-body">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
            <h3><strong>Fiscal Years</strong></h3>
            {
              user.type === 'admin' ? <Link to={'fiscal-years/benchmark-report'} className="btn btn-primary">Benchmark Report</Link> : null
            }
          </div>
          <div style={{ height: 500, maxHeight: 500 }}>
            <Bar
              data={barData}
              options={barOptions}
              ref={chartRef}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row-reverse', marginTop: 10, marginBottom: 10 }}>
            <ExportButton chartRef={chartRef} tableRef={tableRef} />

          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped" ref={tableRef}>
              <thead>
                <tr className="text-center">
                  <th className="text-center">Year</th>
                  <th className="text-center">Royalties</th>
                  <th className="text-center">Previous Year</th>
                  <th className="text-center">$ Comparison</th>
                  <th className="text-center">% Comparison</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>

        </div>
      </div>

      <PaymentReportsGuide />
    </FullContent>
  );

};
