import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikActions, FormikBag, FormikValues } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { UserContext, UserType } from '../../../contexts';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner, ProductCategoryTypeahead } from '../../shared';

interface IState {
  loading: boolean;
  saving: boolean;
  error: string;
}

export class CreateItemType extends React.Component<RouteComponentProps<any>, IState> {

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      error: '',
    };
    this.saveItemTypes = this.saveItemTypes.bind(this);
  }

  componentDidMount() {

  }

  saveItemTypes(values: FormikValues, formProps: FormikActions<FormikValues>, user: UserType) {
    if (values) {

      const itemTypeMappings = values.itemTypeMappings.map((mapping: any) => ({
        product: mapping.product[0].id,
        identifier: mapping.identifier,
      }));

      const formData = {
        itemTypeMappings,
        account_id: this.accountID,
      };
      Axios.post('/api/item-types', formData)
        .then((response) => {
          if (user.type === 'admin') {
            this.props.history.push(this.backRoute);
          } else {
            this.props.history.push('/settings/item-types');
          }
        })
        .catch((error) => {
          for (const index in error.response.data) {
            formProps.setFieldError(index, error.response.data[index][0]);
          }
        });
    }
  }
  render() {

    const initialValues = {
      itemTypeMappings:[{ identifier: '', product:[] }],
      product: [],
    };

    const validation = Yup.object({
      itemTypeMappings: Yup.array().of(
        Yup.object({
          identifier: Yup.string().min(1).required('An item type is required'),
          product: Yup.array().min(1, 'A category is required'),
        }),
      ).min(1),
    });

    if (!this.state.loading) {

      return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            <div className="col-xs-12 col-sm-8 col-sm-push-2">
              <div style={{ paddingBottom: 10 }}>
                <h3><strong>Add Item Types</strong></h3>
                {this.state.error ? (
                  <div className="alert alert-danger">
                    {this.state.error}
                  </div>
                ) : null }
              </div>
              <UserContext.Consumer>
                { user => (
              <Formik
                onSubmit={ (values, formProps) => this.saveItemTypes(values, formProps, user)}
                validationSchema={validation}
                validateOnBlur={false}
                initialValues={initialValues}>
                {formProps =>
                  (
                    <div>
                      <Form id="item-type-form">
                        <FieldArray name="itemTypeMappings" render={helpers => (
                          <>
                            { formProps.values.itemTypeMappings.map((u: any, index: number) => (
                              <div className="row" key={index}>
                                { index > 0 ?  <hr />  : null }
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="identifier">Item Type</label>
                                    <Field placeholder="Item Type" name={`itemTypeMappings.${index}.identifier`} className="form-control"/>
                                    <p className="text-danger">
                                      <ErrorMessage name={`itemTypeMappings.${index}.identifier`} />
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-5">
                                  <div className="form-group">
                                    <label>Category</label>
                                    <ProductCategoryTypeahead
                                    multiple={false}
                                    selected={formProps.values.itemTypeMappings[index].product}
                                    onChange={c => formProps.setFieldValue(`itemTypeMappings.${index}.product`, c)}
                                    showMarketingMaterials={false}
                                    />
                                    <p className="text-danger">
                                      <ErrorMessage name={`itemTypeMappings.${index}.product`} />
                                    </p>
                                  </div>
                                </div>
                                <div className="col-sm-1">
                                  {formProps.values.itemTypeMappings.length > 1 ?
                                    <FontAwesomeIcon
                                      className="text-danger pull-left"
                                      onClick={() => helpers.remove(index)}
                                      icon={faTimes} />
                                    : null}
                                </div>
                              </div>

                              )) }

                            <div className="panel-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
                              <button
                                type="button"
                                onClick={() => helpers.push({ identifier: '', product: [] })}
                                className="btn btn-primary"
                              >
                                <FontAwesomeIcon icon={faPlus} /> Add Item Type
                              </button>
                                  <button className="btn btn-primary" type="submit">Submit</button>
                            </div>
                          </>
                        )}
                        />
                      </Form>
                    </div>
                  )}
              </Formik>
                  )}
              </UserContext.Consumer>

            </div>

          </div>
        </div>
      </FullContent>

      );

    }
    return (
      <FullContent>
         <div className="panel panel-portal">
           <div className="panel-body">
             <LoadingSpinner />
           </div>
         </div>
       </FullContent>
    );

  }

  get accountID() {
    if (this.props.match.params['vendorId']) {
      return this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      return this.props.match.params['licensorId'];
    }
    return '';
  }

  get backRoute() {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.accountID}/settings/item-types`;
    }
    if (this.props.match.params['licensorId']) {
      return `/clients/${this.accountID}/settings/item-types`;
    }

    return '';
  }

}
