import * as React from 'react';
import { ProductCategory } from '../../../../shared';
import { ProductCategoryTypeahead } from '../../../shared';

interface IProps {
  categories: ProductCategory[];
  isReadOnly: boolean;
  onChange: (category: ProductCategory[]) => void;
}
export class DesignReviewCategory extends React.Component <IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    if (this.props.isReadOnly) {
      const categories = this.props.categories.map(c => c.name).join(', ');

      return (
        <p className="text-center no-margin"><strong>{categories}</strong></p>
      );
    }
    return (
      <div className="form-group">
        <label className="small">Category</label>
        <ProductCategoryTypeahead
          multiple={true}
          selected={this.props.categories}
          onChange={this.props.onChange}
        />
      </div>
    );

  }

}
