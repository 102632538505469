import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { UserContext } from '../../../contexts';
import { AddProductPage } from './AddProductPage';

export class AddProductWrapper extends React.Component<RouteComponentProps<any>, any> {

  render() {
    return (
      <div>
        <UserContext.Consumer>
          {(user) => {
            let vendorId;
            if (user.type === 'vendor') {
              vendorId = user.account.id;
            } else {
              vendorId = this.props.match.params['vendorId'];
            }
            return <AddProductPage {... this.props} vendorId={vendorId} />;
          }}
        </UserContext.Consumer>
      </div>
    );
  }
}
