import { CancelTokenSource } from 'axios';
import * as React from 'react';

export const useCancelToken = () => {
  const [token, setToken] = React.useState<CancelTokenSource | null>(null);
  const tokenRef = React.useRef<CancelTokenSource | null>(null);

  React.useEffect(() => {

    return () => {
      if (tokenRef.current) {
        tokenRef.current.cancel();
      }
    };

  },              []);
  React.useEffect(() => {
    tokenRef.current = token;
  },              [token]);

  const cancelToken = () => {
    if (token) {
      token.cancel();
      setToken(null);
    }
  };

  return { token, setToken, cancelToken };
};
