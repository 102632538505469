import Axios from 'axios';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { UserContext } from '../../../contexts';
import User from '../../../shared/User';

interface IProps {
  selected: User[];
  onChange: (accounts: ({ firstName: string; lastName: string; email: any })[]) => void;
  multiple: boolean;
  accountId: number;
}

export const EmailTypeahead = (props: IProps) => {
  const [emails, setEmails] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const ref = React.useRef<any>();
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    getEmails('');
  },              [props.accountId]);

  const getEmails = async(query: string) => {
    let url = `/api/users?keyword=${query}`;
    if (user.type === 'admin') {
      url = `/api/users?account_id=${props.accountId}&keyword=${query}`;
    }
    const u = await Axios.get(url);
    const users = u.data.data.map((u: any) => User.fromApi(u));
    setEmails(users);
    setLoading(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();

      const value = (ref.current as any).getInput().value.trim();
      if (value) {
        const newUser = { email: value, firstName: '', lastName: '' };
        setEmails([...emails, newUser]);
        props.onChange([...props.selected, newUser]);
        (ref.current as any).clear();
      }
    }
  };

  return (
    <Typeahead
      ref={ref}
      id="email-typeahead"
      multiple={props.multiple}
      allowNew
      options={emails}
      selected={props.selected}
      onChange={(selected) => {
        const updatedSelected = selected.map((item) => {
          if (item.customOption) {
            return { email: item.label, firstName: '', lastName: '' };
          }
          return item;
        });
        props.onChange(updatedSelected);
      }}
      placeholder="Enter Recipients"
      labelKey={(item: User) => item.firstName ? `${item.firstName} ${item.lastName} (${item.email})` : `${item.email}`}
      clearButton={true}
      isLoading={loading}
      newSelectionPrefix={''}
      onKeyDown={handleKeyDown}
    />
  );
};
