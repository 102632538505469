import * as React from 'react';
import { AffinityClient, Trademark } from '../../shared';
import { LicensorTypeahead, TrademarkTypeahead } from './';

interface LicensorEditRowProps {
  licensor?: any;
  updateTrademark: (licensorId: string, trademarks: Trademark[]) => any;
  updateLicensor: (licensor: AffinityClient[]) => any;
  deleteTrademark: (licensorId: string) => any;
}
export const LicensorEditRow = (props: LicensorEditRowProps)  => {
  const [licensor, setLicensor] = React.useState(props.licensor ? [props.licensor] : []);
  const [trademarks, setTrademarks] = React.useState(props.licensor ? props.licensor.trademarks : []);
  const [isRemoving, setIsRemoving] = React.useState(false);

  let isUpdating = false;
  const updateTrademark = async (id:string, tms: Trademark[]) => {
    if (!isUpdating) {
      isUpdating = true;
      setTrademarks(tms);
      await props.updateTrademark(props.licensor.id, tms);
      setTrademarks(tms);
      isUpdating = false;
    }
  };

  const deleteTrademark = async (id: string) => {
    setIsRemoving(true);
    await props.deleteTrademark(id);
    setIsRemoving(false);
  };

  const removeText = isRemoving ? 'Removing' : 'Remove';

  return (
    <div className="row" style={{ marginBottom:10 }}>
      <div className="col-lg-5">
        {
          props.licensor ?
            <div style={{ display: 'flex', alignItems: 'center' }} >
              <img style={{ width: 75, height: 75 }} src={props.licensor.affinityImage.getSize('sm')} />
              <span style={{ marginLeft: 10 }}>
                {props.licensor.shortName}
              </span>
            </div>
            : <LicensorTypeahead  multiple={false} onChange={l => props.updateLicensor(l)} selected={licensor}/>
        }
      </div>
      <div className="col-lg-7">
        <div className="col-lg-9" style={{ paddingLeft: 0 }}>
          <TrademarkTypeahead delayLoad={true} selected={trademarks} onChange={t => updateTrademark(props.licensor.id, t)} multiple={false} accountId={licensor && licensor.length > 0 ? licensor[0].id : [] } />
        </div>
        <div className="col-lg-3">
          <button className="btn btn-danger" onClick={() => deleteTrademark(props.licensor ? props.licensor.id : null)}>{removeText}</button>
        </div>
      </div>
    </div>
  );
};
