import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { GatewayModal, ModalType } from './GatewayModal';

interface IProps {
  shown: boolean;
  isSubmitting: boolean;
  bankName: string;
  bankLastFour: string;
  amount: number;
  onAccept: () => any;
  onClose: () => any;
  title?: string;

}

interface IState {
  isApproved: boolean;
}

export class ConfirmPaymentModal extends React.Component <IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      isApproved: false,
    };
    this.accept = this.accept.bind(this);
  }

  accept() {
    this.props.onAccept();
  }

  render() {

    let body;
    if (this.props.isSubmitting) {
      body = (
        <div className="react-loading-content">
          <span><FontAwesomeIcon icon={faSpinner} spin /></span>
        </div>
      );
    } else {
      body = (
        <div>
          <Modal.Body className="text-center confirm-payment-modal">
            <h4 className="text-primary payment-value"><strong>Please confirm your <NumberFormat
                value={this.props.amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              /> payment.</strong></h4>
            <p>This amount will be debited from the following checking account.</p>

            <h4 className="text-center payment-name"><strong>{this.props.bankName}</strong></h4>
            <h4 className="payment-last-four">{this.props.bankLastFour}</h4>

            <p className="text-muted text-center payment-authorize">
              By clicking Submit and Pay, I authorize Affinity to debit my account for the amount indicated above.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.props.onClose} className="btn btn-default pull-left">
              Cancel
            </button>

            <button onClick={this.accept} className="btn btn-primary pull-right">
              Submit and Pay
            </button>
          </Modal.Footer>
        </div>
      );
    }
    const title = this.props.title ? this.props.title : 'Pay Invoice';
    return (
      <GatewayModal shown={this.props.shown} onClose={this.props.onClose} type={ModalType.Primary} title={title} >
        {body}
      </GatewayModal>
    );
  }

}
