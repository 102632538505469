import * as React from 'react';

export const useMainContainer = () => {
  const container = React.useRef<Element | null>(null);

  React.useEffect(() => {
    const m = document.getElementsByClassName('main-content');
    container.current = m[0];

    return () => {
      resetContainer();
    };

  },              []);

  const addClass = (c: string) => {
    if (container.current) {
      container.current.classList.add(c);
    }
  };

  const resetContainer = () => {
    if (container.current) {
      container.current.className = 'main-content';
    }

  };

  return { addClass, resetContainer, container: container.current };

};
