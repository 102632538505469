import * as React from 'react';
import { getLicensors } from '../../api';
import { AffinityClient } from '../../shared';
import { FilterBar } from '../shared';
import { DesignResponseGrid } from './DesignResponseGrid';

interface IState {
  accountId: number | null;
  licensors: AffinityClient[];
  licensor: AffinityClient | null;
}

export class FilterableDesignResponseIndex extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);

    this.state = {
      accountId: null,
      licensor: null,
      licensors: [],
    };

    this.loadLicensors = this.loadLicensors.bind(this);
    this.selectLicensor = this.selectLicensor.bind(this);
  }

  componentDidMount() {
    this.loadLicensors();
  }

  loadLicensors() {
    getLicensors().then((response: any) => {
      const licensors = response.data.data.map((licensor: any) => new AffinityClient(licensor));
      this.setState({ licensors });
    });
  }

  selectLicensor(value: { licensor: AffinityClient }) {
    this.setState({ licensor: value.licensor });
  }

  render() {

    const licensorId = this.state.licensor ? this.state.licensor.id : null;
    return (
      <div>
        <div className="panel panel-portal">
          <div className="panel-body" style={{ background: '#f9f9f9', padding: '5px' }}>
            <FilterBar
              licensors={this.state.licensors}
              licensor={this.state.licensor}
              updateFilters={this.selectLicensor}
              usePerPage={false}
              useQuarter={false}
              useLicensor={true}
              useSearch={false}
            />
          </div>
        </div>
        <DesignResponseGrid showAddButton={false} showLicensor={true} accountId={licensorId} />
      </div>
    );
  }

}
