import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

const MarketplaceDashboardPage = (props: RouteComponentProps<any>) => {
  let routePrefix = '';
  if (props.match.params['vendorId']) {
    routePrefix = `/vendors/${props.match.params['vendorId']}`;

  } else if (props.match.params['licensorId']) {
    routePrefix = `/clients/${props.match.params['licensorId']}`;
  }
  return (
    <Redirect to={{ pathname: `${routePrefix}/marketplace/products` }} />
  );
};

export { MarketplaceDashboardPage as MarketplaceDashboardPage };
