import * as React from 'react';
import { AffinityDropzone } from '../../Navigation';

interface IProps {
  title: string;
  imagePreview: string;
  onChange: (preview: string, file: File) => void;
  labelClass?: string;
  fieldClass?: string;
  imagePreviewHeight?: number;
  imagePreviewWidth?: number;
  showExternalLink?: boolean;
}

export class FormImageWithPreview extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind((this));
  }

  onChange(files: File[]) {
    const file = files[0];
    const preview = URL.createObjectURL(file);
    this.props.onChange(preview, file);
  }

  render() {
    const imageHeight = this.props.imagePreviewHeight ? this.props.imagePreviewHeight : 100;
    const imageWidth = this.props.imagePreviewWidth ? this.props.imagePreviewWidth : 100;
    const imagePreview = this.props.imagePreview ? (
      <div style={{
        width: imageWidth,
        height: imageHeight,
        borderRadius: 4,
        border: '1px solid #eee',
        backgroundImage: `url(${this.props.imagePreview})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }} />
    ) : (
      <div style={{ width: 100, height: 100, borderRadius: 4, border: '1px solid #eee', background: '#fafafa' }} />
    );
    return (
      <div className="form-group">
        <label className={`control-label ${this.props.labelClass ? this.props.labelClass : 'col-sm-2'}`}>
          {this.props.title} {this.props.showExternalLink && this.props.imagePreview ?
            (<a target="_blank"  href={this.props.imagePreview}>View</a>) : null
            }
        </label>
        <div className={this.props.labelClass ? this.props.labelClass : 'col-sm-4'}>
          <AffinityDropzone
            onDropAccepted={this.onChange}
            accept="image/*" style={{ padding: 0, background: 'transparent', border: 'none' }}
          >
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              {imagePreview}

              <button type="button" style={{ marginLeft: 10 }} className="btn btn-default">Upload {this.props.title}</button>
            </div>
          </AffinityDropzone>
        </div>

      </div>
    );
  }

}
