import { faBadgeDollar, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Form, Formik, FormikActions, FormikValues } from 'formik';
import * as queryString from 'query-string';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import * as Yup from 'yup';
import { Filters } from '../../../shared';
import { formattedFiscalYear } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType, PaginationFooter, VendorTypeahead } from '../../shared';
import { AccountAddressTypeahead } from '../../shared/forms/AccountAddressTypeahead';

interface RetailerAPIData {
  id: number;
  retailer_id: number;
  retailer_image_url: string;
  name: string;
  linked_addresses: [];
  total_locations_count: number;
  link_locations_count: number;
  last_sale: string;
}

export const RetailersPage = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [retailers, setRetailers] = useState<RetailerAPIData[]>([]);
  const [createModalShown, setCreateModalShown] = useState(false);
  const [editModalShown, setEditModalShown] = useState(false);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const routeParams = useParams();
  const history = useHistory();
  const location = useLocation();
  const [filters] = React.useState(new Filters());
  filters.setQueryParamsFromLocation(location.search);
  let query = queryString.parse(location.search);
  const [year, setYear] = useState(query['year']);
  const [quarter, setQuarter] = useState(query['quarter']);
  const [page, setPage] = query['page'] ? useState(Number(query['page'])) : useState(1);

  useEffect(() => {
    query = queryString.parse(location.search);
    getAccountRetailers();
  },        [selectedAddresses, quarter, year, page]);

  const filterQuarter = (quarter: any) => {
    const q = quarter !== 'All' ? quarter : null;
    setQuarter(q);
    setPage(1);
    setLoading(true);
    const qsParsed = queryString.parse(location.search);
    qsParsed.quarter = q;
    qsParsed.page = 1;
    history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  };

  const filterYear = (year: any) => {
    setYear(year);
    setPage(1);
    setLoading(true);
    const qsParsed = queryString.parse(location.search);
    qsParsed.year = year;
    qsParsed.page = 1;
    history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  };

  function getAccountRetailers() {
    const params = {
      page,
      year,
      quarter,
      account_id: getAccountID(),
    };

    Axios.get('/api/retailers', { params })
      .then((response) => {
        setRetailers(response.data.retailers.data);
        setTotalResults(response.data.meta.pagination.total_count);
        setLastPage(response.data.meta.pagination.page_count);
        setLoading(false);
      });
  }

  function createRetailer(values: { selectedVendor: any; }, formProps: FormikActions<FormikValues>) {
    setSaving(true);
    const postURL = '/api/retailers';
    const params = {
      account_id: getAccountID(),
      selectedVendorId: values.selectedVendor[0].id,
    };
    Axios.post(postURL, params)
      .then((response) => {
        getAccountRetailers();
        hideCreateModal();
        setSelectedId(0);
        setSaving(false);
      })
      .catch((error) => {
        setSaving(false);
        for (const index in error.response.data) {
          formProps.setFieldError('selectedVendor', error.response.data[index][0]);
        }
      });
  }

  function editRetailer(values: { selectedAddress: any; }, formProps: FormikActions<FormikValues>) {
    if (selectedId) {
      setSaving(true);

      const postURL = `/api/retailers/${values.selectedAddress[0].accountId}`;
      const params = {
        account_id: getAccountID(),
        selectedAddresses: values.selectedAddress,
      };
      Axios.post(postURL, params)
        .then((response) => {
          setSelectedAddresses(response.data.data);
          getAccountRetailers();
          setSaving(false);
        })
        .catch((error) => {
          for (const index in error.response.data) {
            formProps.setFieldError('selectedAddress', error.response.data[index][0]);
          }
          setSaving(false);
        });
    }
  }

  function deleteRetailer() {
    if (selectedId) {
      setSaving(true);
      const params = {
        account_id: getAccountID(),
        id: selectedId,
      };
      Axios.delete('/api/retailers', { params })
        .then((response) => {
          setSelectedId(0);
          getAccountRetailers();
          hideDeleteModal();
          setSaving(false);
        })
        .catch(() => setSaving(false));
    }
  }

  function deleteAddress(e: any) {
    const addressId = parseInt(e.target.getAttribute('data-id'), 10);
    setSaving(true);
    const params = {
      addressId,
      account_id: getAccountID(),
      retailerAccountId: selectedId,
    };
    Axios.delete('/api/retailers/address', { params })
      .then((response) => {
        if (response.data.data.length > 0) {
          setSelectedAddresses(response.data.data);
        } else {
          setSelectedAddresses([]);
        }
        getAccountRetailers();
        setSaving(false);
      })
      .catch(() => setSaving(false));
  }

  function showDeleteModal() {
    setDeleteModalShown(true);
  }

  function showCreateModal() {
    setCreateModalShown(true);
  }

  function showEditModal() {
    setEditModalShown(true);
  }

  function hideDeleteModal() {
    setDeleteModalShown(false);
    setSelectedId(0);
  }

  function hideCreateModal() {
    setCreateModalShown(false);
    setSelectedId(0);
  }

  function hideEditModal() {
    setEditModalShown(false);
    setSelectedId(0);
  }

  function setDeleteID(e: any) {
    const id = parseInt(e.target.getAttribute('data-id'), 10);
    setSelectedId(id);
    showDeleteModal();
  }

  function setEditID(e: any) {
    const id = parseInt(e.target.getAttribute('data-id'), 10);
    setSelectedId(id);
    const linkedRetailer = retailers.find(i => i.retailer_id === id);
    if (linkedRetailer) {
      setSelectedAddresses(linkedRetailer.linked_addresses);
    }

    showEditModal();
  }

  function updatePage(newPage: number) {
    setLoading(true);
    setPage(newPage);
    const qsParsed = queryString.parse(location.search);
    qsParsed.page = newPage;
    history.push(`${location.pathname}?${queryString.stringify(qsParsed)}`);
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  let pageContent;
  const rows = (retailers as any[]).map(retailer =>
    <tr key={ retailer.id }>
      <td style={{ verticalAlign: 'middle' }}>
        <img style={{ width:30 }} src={retailer.retailer_image_url}/>
        <strong>{`  ${retailer.short_name}`}</strong><br/>
      </td>
      <td style={{ verticalAlign: 'middle' }} className="text-right">
        ${retailer.net_sales}
      </td>
      <td style={{ verticalAlign: 'middle' }} className="text-right">
        ${retailer.royalty_sales}
      </td>
      <td style={{ verticalAlign: 'middle' }} className="text-center">
        {
          retailer.link_locations_count > 0
            ? <button className="btn btn-link" data-id={retailer.retailer_id} onClick={setEditID}>{retailer.link_locations_count <= 1 ? ` ${retailer.link_locations_count}  location` : ` ${retailer.link_locations_count} locations`}</button>
            : <button className="btn btn-link" data-id={retailer.retailer_id} onClick={setEditID}>No Matching Locations</button>
        }
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <button className="btn btn-link text-danger pull-right" data-id={retailer.id} onClick={setDeleteID}>
          Delete
        </button>
      </td>
    </tr>,
  );

  if (loading) {
    pageContent = (
      <LoadingSpinner />
    );
  } else if (retailers.length === 0 && ! loading) {
    pageContent =  (
      <em>No Retailers Found.</em>
    );
  } else {
    pageContent = (
      <table className="table">
        <thead style={{ textTransform: 'capitalize' }}>
          <tr>
            <th className="text-left">Retailer</th>
            <th className="text-right"><FontAwesomeIcon className="fa-fw text-muted" icon={faBadgeDollar}/> Sales</th>
            <th className="text-right"><FontAwesomeIcon className="fa-fw text-muted" icon={faSackDollar}/> Royalties</th>
            <th className="text-center">Locations</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
    );
  }

  const deleteModalText = saving ? 'Deleting...' : 'Delete Retailer' ;
  const editModalText = saving ? 'Saving...' : 'Add Locations' ;
  const createModalText = saving ? 'Saving...' : 'Save Retailer' ;

  const initialValues = {
    selectedVendor: [],
  };

  const initialValuesEdit = {
    selectedAddress: [],
  };

  const validation = Yup.object({
    selectedVendor: Yup.array().min(1, 'A retailer account is required'),
  });

  const validationEdit = Yup.object({
    selectedAddress: Yup.array().min(1, 'A retailer address is required'),
  });

  const linkedAddressList = selectedAddresses.length === 0
    ? <tr><td>No Matching Locations</td></tr>
    : (selectedAddresses as any[]).map(address =>
      <tr>
        <td style={{ verticalAlign: 'middle' }}>
          {address.full_address}
          <button className="btn-link text-danger pull-right" data-id={address.id} onClick={deleteAddress}>
          Delete
        </button>
        </td>
      </tr>,
  );

  const years = [];
  const date = new Date();
  let fiscalYear;
  if (date.getMonth() >= 9) {
    fiscalYear = date.getFullYear();
  } else {
    fiscalYear = date.getFullYear() - 1;
  }

  const currentYearFilter = query['year'] || formattedFiscalYear(fiscalYear);
  const currentQuarterFilter = query['quarter'] || 'All';

  for (let i = 0; i <= (fiscalYear - 2018); i++) {
    const yearToAdd = 2018 + i;
    const yearValue = formattedFiscalYear(yearToAdd);

    years.push(<MenuItem key={yearValue} eventKey={yearValue} data-year={yearToAdd} >{yearValue}</MenuItem>);
  }
  return (
    <FullContent breadcrumbs={[{ name: 'Retailer' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>

        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Retailers</strong></h3>
        </div>
        <button onClick={showCreateModal} className="btn btn-primary">Add Retailer</button>
      </div>
      <div style={{ marginBottom: 10 }}>
        <DropdownButton
          onSelect={filterQuarter}
          id="quarter-dropdown"
          bsStyle={'default'}
          title={currentQuarterFilter}
        >
          <MenuItem eventKey="All" > All </MenuItem>
          <MenuItem divider />
          <MenuItem eventKey="Q1"> Q1 </MenuItem>
          <MenuItem eventKey="Q2"> Q2 </MenuItem>
          <MenuItem eventKey="Q3"> Q3 </MenuItem>
          <MenuItem eventKey="Q4"> Q4 </MenuItem>

        </DropdownButton>
        <DropdownButton
          style={{ marginLeft: 10 }}
          id="year-dropdown"
          bsStyle={'default'}
          title={currentYearFilter}
          onSelect={filterYear}
        >
          {years}

        </DropdownButton>
      </div>

      <div className="panel panel-portal">
        { pageContent }
      </div>
      <PaginationFooter
        setPage={updatePage}
        currentPage={page}
        totalResults={totalResults}
        hasNext={lastPage > page} />

      <GatewayModal shown={deleteModalShown} type={ModalType.Danger} onClose={hideDeleteModal} title="Delete Retailer" >
        <Modal.Body>
          <p className="text-center">
            <strong>Are you sure you want to delete this retailer?</strong>
          </p>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
          <button onClick={deleteRetailer} className="btn btn-danger pull-right" disabled={saving}>{deleteModalText}</button>
        </Modal.Footer>
      </GatewayModal>
      <GatewayModal shown={createModalShown} type={ModalType.Primary} onClose={hideCreateModal} title="Create Retailer" >
        <Modal.Body>
          <Formik
            onSubmit={ (values, formProps) => createRetailer(values, formProps)}
            validationSchema={validation}
            validateOnBlur={false}
            initialValues={initialValues}>
            {formProps =>
              (
                <div>
                  <Form id="account-retailer-form">
                    <div className="form-group">
                      <label>Account Name</label>
                      <VendorTypeahead
                        selected={formProps.values.selectedVendor}
                        multiple={false}
                        onChange={v => formProps.setFieldValue('selectedVendor', v)}
                      />
                      <p className="text-danger">
                        <ErrorMessage name="selectedVendor" />
                      </p>
                    </div>
                  </Form>
                </div>
              )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideCreateModal} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" form="account-retailer-form" className="btn btn-primary pull-right" disabled={saving}>{createModalText}</button>
        </Modal.Footer>
      </GatewayModal>
      <GatewayModal shown={editModalShown} type={ModalType.Primary} onClose={hideEditModal} title="Add Locations" >
        <Modal.Body>
          <Formik
            onSubmit={ (values, formProps) => editRetailer(values, formProps)}
            validationSchema={validationEdit}
            validateOnBlur={false}
            initialValues={initialValuesEdit}>
            {formProps =>
              (
                <div>
                  <Form id="account-retailer-edit-form">
                    <div className="form-group">
                      <label>Add Location</label>
                      <AccountAddressTypeahead
                        selected={formProps.values.selectedAddress}
                        multiple={true}
                        onChange={v => formProps.setFieldValue('selectedAddress', v)}
                        addressAccountId={selectedId}
                        disabled={false}
                      />
                      <p className="text-danger">
                        <ErrorMessage name="selectedAddress" />
                      </p>
                    </div>
                    <button type="submit" form="account-retailer-edit-form" className="btn btn-primary pull-right" disabled={saving}>{editModalText}</button>
                  </Form>
                </div>
              )}
          </Formik>
          <div className="form-group">
            <table className="table">
              <tbody>
              <th>Linked Locations</th>
              {
                linkedAddressList
              }
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideEditModal} className="btn btn-default pull-right">Close</button>
        </Modal.Footer>
      </GatewayModal>
    </FullContent>

  );

};
