import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Field, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Trademark, TrademarkUse } from '../../../shared';
import { FormImageWithPreview, GatewayModal, ModalType } from '../../shared';
import { LicensorEditRow } from '../../shared/LicensorEditRow';

interface IProps {
  isNew: boolean;
}

export const UsesForm = (props: FormikProps<any> & IProps) => {
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [selectedTrademark, setSelectedTrademark] = React.useState<Trademark[]>([]);
  const [editModalLicensors, setEditModalLicensors] = React.useState<TrademarkUse[]>([]);
  const [useToEdit, setUseToEdit] = React.useState();
  const [showNewLicensorRow, setShowNewLicensorRow] = React.useState(false);

  const updateLicensorTrademark = async (licensorId:any, trademarks:Trademark[]) => {
    setSaving(true);

    const trademarkIds = [];
    const useId = useToEdit;

    trademarks.forEach(t => trademarkIds.push(t.id));
    const params = {
      licensorId,
      trademarkIds,
    };

    if (props.values.id) {
      const result = await Axios.post(`/api/trademark-uses/${useId}/trademarks`, params);

      if (result.data.data) {
        const trademarkUse = TrademarkUse.fromApi(result.data.data);
        props.setFieldValue('licensors', trademarkUse.licensors);
        setEditModalLicensors(trademarkUse.licensors);
      }
    } else {
      // const tmp = editModalLicensors;
      // tmp.push();
      // props.setFieldValue('licensors', tmp);
      const tmp = props.values.licensors.map((i) => {
        if (i.id === licensorId) {
          i.trademarks = trademarks;
        }
        return i;
      });
      props.setFieldValue('licensors', tmp);
      setEditModalLicensors(tmp);
    }

    setSaving(false);
  };

  const deleteLicensorTrademark = async (licensorId:number) => {
    if (!licensorId) {
      setShowNewLicensorRow(false);
      return;
    }
    const useId = useToEdit;

    const params = {
      licensorId,
    };

    const result = await Axios.delete(`/api/trademark-uses/${useId}/trademarks`, { params });
    if (result.data.data) {
      const trademarkUse = TrademarkUse.fromApi(result.data.data);
      props.setFieldValue('licensors', trademarkUse.licensors);
      setEditModalLicensors(trademarkUse.licensors);
    }
    return result.data;
  };

  function openEditModal(licensors:[], useId:number) {
    setShowNewLicensorRow(false);
    setEditModalLicensors(licensors);
    setUseToEdit(useId);
    setShowEditModal(true);
  }

  function addNewLicensorRow() {
    setShowNewLicensorRow(true);
  }

  function closeEditModal() {
    setShowEditModal(false);
    setSelectedTrademark([]);
  }

  function updateSelectedLicensor(l:any[]) {
    setShowNewLicensorRow(false);
    if (l && l.length > 0) {
      const tmp = editModalLicensors;
      l[0].trademarks = [];
      tmp.push(l[0]);
      setEditModalLicensors(tmp);
      props.setFieldValue('licensors', tmp);
      if (props.values.id) {
        updateLicensorTrademark(l[0].id, []);
      }
    }

  }

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-portal" style={{ cursor: 'pointer' }} onClick={e => openEditModal(props.values.licensors, props.values.id)}>
          <thead>
            <tr>
              <th>Trademark Owner</th>
              <th>Trademark</th>
            </tr>
          </thead>
          <tbody>
          { props.values.licensors.length > 0 ?
            props.values.licensors.map((l:any) => (
              <tr key={l.id}>
                <td>
                  <div style={{ display: 'flex', alignItems: 'center' }} >
                    <img style={{ width: 75, height: 75 }} src={l.affinityImage.getSize('sm')} />
                    <span style={{ marginLeft: 10 }}>
                      {l.shortName}
                    </span>
                  </div>
                </td>
                <td>
                  { l.trademarks && l.trademarks.length > 0 ?
                    <div style={{ display: 'flex', alignItems: 'center' }} >
                      {l.trademarks[0].image ? <img style={{ width: 75, height: 75 }} src={l.trademarks[0].image.getSize('sm')} /> : '' }
                      <span style={{ marginLeft: 10 }}>
                        { l.trademarks[0].title }
                      </span>
                    </div> : 'No Trademark'
                  }
                </td>
              </tr>
            ))
            :
            <tr key={0}>
              <td>
                No Licensors
              </td>
              <td>
                No Trademarks
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>

      <div className="form-group">
        <label>Title</label>
        <Field component="textarea" className="form-control" name="title" />
        {props.errors.title ? (
          <p className="text-danger">
            {props.errors.title}
          </p>
        ) : null }
      </div>

      <div className="form-group">
        <label>Description</label>
        <Field component="textarea" className="form-control" name="description" />
      </div>

      <div className="form-group">
        <label>Price</label>
        <Field className="form-control" name="price" />
      </div>

        <div className="form-group">
          <label>URL</label>
          {props.isNew ?
            <Field className="form-control" name="contentUrl" /> :
            <div className="input-group">
              <input type="text" disabled={!props.isNew} value={props.values.contentUrl} className="form-control" />
              <div className="input-group-btn text-primary">
                <button className="btn btn-default text-primary" onClick={() => window.open(props.values.contentUrl, '_blank')}>Open Link  <FontAwesomeIcon icon={faExternalLink} /></button>
              </div>
            </div>
          }
        </div>

      <div style={{ marginBottom: 10 }} className="row">
        <FormImageWithPreview
          showExternalLink={!props.isNew}
          title="Preview"
          imagePreview={props.values.previewPreview}
          onChange={(preview, file) => {
            props.setFieldValue('preview', file);
            props.setFieldValue('previewPreview', preview);
          }}
        />
      </div>

      <div style={{ marginBottom: 10 }} className="row">
        <FormImageWithPreview
          showExternalLink={!props.isNew}
          title="Screenshot"
          imagePreview={props.values.screenshotPreview}
          onChange={(preview, file) => {
            props.setFieldValue('screenshot', file);
            props.setFieldValue('screenshotPreview', preview);
          }}
        />
      </div>

      <div style={{ marginBottom: 10 }} className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>
              Data File {props.values.dataPreview ? <a target="_blank" href={props.values.dataPreview}>View</a> : null}
            </label>
            <input
            name="data"
            id="data"
            type="file"
            onChange={e => props.setFieldValue('data', e.target.files && e.target.files[0])} />
          </div>
        </div>
      </div>

      <GatewayModal
        type={ModalType.Primary}
        shown={showEditModal}
        onClose={() => closeEditModal()}
        title={'Edit'}
        size={'large'}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-lg-5" style={{ marginBottom: 10 }}>
              <strong>Licensor</strong>
            </div>
            <div className="col-lg-7" style={{ marginBottom: 10 }}>
              <strong>Trademarks</strong>
            </div>
          </div>
          {
            editModalLicensors && editModalLicensors.length > 0 ?
              editModalLicensors.map(l => (
                <LicensorEditRow licensor={l} updateLicensor={updateSelectedLicensor} updateTrademark={updateLicensorTrademark} deleteTrademark={deleteLicensorTrademark}/>
              ))
              : null
          }
          { showNewLicensorRow ?
            <LicensorEditRow licensor={null} updateLicensor={updateSelectedLicensor} updateTrademark={updateLicensorTrademark} deleteTrademark={deleteLicensorTrademark}/>
            : null}
          <div>
            <button onClick={() => addNewLicensorRow()} className="btn btn-primary">
              Add Licensor
            </button>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowEditModal(false)} className="btn btn-default">
            Close
          </button>
        </Modal.Footer>
      </GatewayModal>

    </div>
  );
};
