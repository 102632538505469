interface NoteMethodApiResponse {
  id: number;
  name: string;
}

export class NoteMethod {
  id: number;
  name: string;

  static fromApi(data: NoteMethodApiResponse): NoteMethod {
    return {
      id: data.id,
      name: data.name,
    };
  }
}
