import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { CurrencyLabel } from '../shared';

interface ListDataItem {
  image?: string;
  [field: string]: any;
}

interface IProps {
  onClick?: (index: number) => void;
  listVisualStyle: 'none' | 'dot' | 'image';
  items: ListDataItem[];
  itemLabels: { [field: string]: any }[];
  showLabels: boolean;
  rightAlignOffset?: number;
  useShowAll?: boolean;
  overrideIndex?: boolean;
  indexPage?: number;
}

export const ChartItemList = React.forwardRef<HTMLTableElement, IProps>((props, ref) => {
  const [showAll, setShowAll] = React.useState(false);

  const visual = (item: ListDataItem, index: number) => {
    switch (props.listVisualStyle) {
      case 'none':
        return null;
      case 'dot':
        return <FontAwesomeIcon style={{ fontSize: 12, color: dotColor(index) }} icon={faCircle} />;
      case 'image':
        const image = item.image ? item.image : 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/alp-icon.png';
        return <img className="img-responsive" src={image} />;
    }
  };

  const dotColor = (index: number) => {
    const colors = [
      '#4f6980',
      '#849db1',
      '#a2ceaa',
      '#638b66',
      '#bfbb60',
      '#f47942',
      '#fbb04e',
      '#b66353',
      '#d7ce9f',
      '#b9aa97',
      '#7e756d',
    ];

    return colors[index % colors.length];
  };

  const handleClick = (index: number) => {
    if (props.onClick) {
      props.onClick(index);
    }
  };

  const createTD = (item: ListDataItem, row: number) => {
    return props.itemLabels.map((l, index) => {
      const offset = props.rightAlignOffset ? props.rightAlignOffset : 0;
      const k = Object.keys(l)[0];
      return (
        <td key={index} style={{ textAlign: index > offset ? 'right' : undefined }}>
          {labelType(k, item[k], item, row, item.image)}
        </td>
      );
    });
  };

  const labelType = (key: string, value: string, item: ListDataItem, row: number, image?: string) => {
    switch (key) {
      case 'percent':
        if (Number(value) < 1) {
          return '<1%';
        }
        return <NumberFormat decimalScale={1} suffix="%" displayType="text" value={value} />;
      case 'percentComparison':
        if (Number(value) === 0) {
          return '--';
        }
        return <NumberFormat decimalScale={1} suffix="%" displayType="text" value={value} />;
      case 'rpu':
      case 'royalties':
        return <strong><CurrencyLabel value={value} /></strong>;
      case 'sales':
        return <CurrencyLabel value={value} />;
      case 'previousRoyalties':
      case 'royaltyComparison':
        return <strong><CurrencyLabel value={value} /></strong>;
      case 'units':
        return <NumberFormat thousandSeparator={true} decimalScale={0} displayType="text" value={value} />;
      case 'name':
      case 'state':
      case 'city':
        return (<strong>{value}</strong>);
      case 'vendor':
        const i = image ? image : 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/alp-icon.png';
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={i} className="img-responsive" />
            <strong>{value}</strong>
          </div>
        );
      case 'avgUnitSales':
        return <CurrencyLabel value={value} />;
      case 'avgUnitRoyalties':
        return <CurrencyLabel value={value} />;
      default:
        return value;
    }
  };

  const headers = props.itemLabels.map((h, index) => {
    const offset = props.rightAlignOffset ? props.rightAlignOffset : 0;
    const k = Object.keys(h)[0];
    return (<th key={index} style={{ textAlign: index > offset ? 'right' : 'center', fontWeight: 'normal', lineHeight: '35px' }} className="text-primary">{h[k]}</th>);
  });

  const header = props.showLabels ? (
    <thead style={{ textTransform: 'capitalize' }}>
    <tr>
      {props.listVisualStyle !== 'none' ? <th></th> : null}
      <th></th>
      {headers}
    </tr>
    </thead>
  ) : null;

  let rows: any[] = props.items.map((item, index) => (
    <tr key={index} style={{ cursor: 'pointer' }} onClick={() => handleClick(index)}>
      {props.listVisualStyle !== 'none' ? <td className={`visual-indicator-${props.listVisualStyle}`}>{visual(item, index)}</td> : null}
      <td className="position-rank">
        {index + 1 + (props.overrideIndex && props.indexPage ? (10 * (props.indexPage - 1)) : 0)}.
      </td>
      {createTD(item, index)}
    </tr>
  ));

  if (props.useShowAll) {
    if (!showAll && props.items.length > 5) {
      rows = rows.slice(0, rows.length >= 5 ? 5 : rows.length - 1);
    }
  }

  if (props.items.length) {
    return (
      <div className="table-responsive chart-item-list">
        <table className="table table-hover performance-table" ref={ref}>
          {header}
          <tbody>
          {rows}
          </tbody>
        </table>
        {props.useShowAll && (!showAll && props.items.length > 5) ?
          <div style={{ cursor: 'pointer' }} onClick={() => setShowAll(true)}>
            <p className="text-muted text-center">Show More</p>
          </div> : null}
      </div>
    );
  }

  return (
    <div>
      <p className="text-muted text-center">
        No Results.
      </p>
    </div>
  );
});
