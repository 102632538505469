import { faChartPie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export class NoSalesDataPlaceholder extends React.PureComponent {

  render() {
    return (
      <div className="no-sales-data-analytics" style={{  }}>
        <FontAwesomeIcon className="text-primary" icon={faChartPie} style={{ fontSize: 28 }} />
        <h4>Sales data not available</h4>
        <p className="text-muted">
          Analytics will become available as sales are reported
        </p>
      </div>

    );

  }

}
