import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { logIfCancelled } from '../../../api';
import { MarketplaceSite } from '../../../shared/MarketplaceSite';
import { MarketplaceSitePage } from '../../../shared/MarketplaceSitePage';
import { MarketplaceSitePageItem } from '../../../shared/MarketplaceSitePageItem';

export const getSitePages = (
  siteId: string,
  cancelTokenSource: CancelTokenSource,
): Promise<MarketplaceSite> => {
  const url = `api/marketplace/sites/${siteId}`;
  const params = {
    includes: 'navLinks,pages.pageItems',
  };
  return axios.get(url, { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getSitePages');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      return MarketplaceSite.fromApi(response.data.data);
    });
};

export const getSitePage = (
  pageId: string,
  cancelTokenSource: CancelTokenSource,
): Promise<MarketplaceSitePage> => {
  const url = `api/marketplace/sites/pages/${pageId}`;
  return axios.get(url, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getSitePage');
      throw error;
    })
    .then((response: AxiosResponse<any>) => {
      return MarketplaceSitePage.fromApi(response.data.data);
    });
};

export const updatePageItem = (
  pageItem: MarketplaceSitePageItem,
  cancelTokenSource: CancelTokenSource,
): Promise<{pageItem: MarketplaceSitePageItem | null, errors?: any}> => {
  const url = `api/marketplace/sites/pages/items/${pageItem.id}`;
  return axios.put(
    url,
    { type: pageItem.type, parameters: pageItem.params },
    { cancelToken: cancelTokenSource.token },
  ).catch((error) => {

    if (error.response && error.response.status === 422) {
      return error.response;
    }

    logIfCancelled(error, 'updatePageItem');
    throw error;
  })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 422) {
        return {
          pageItem: null,
          errors: response.data.errors,
        };
      }

      return {
        pageItem: MarketplaceSitePageItem.fromApi(response.data.data),
      };
    });
};

export const deletePageItem = (
  pageItemId: string,
  cancelTokenSource: CancelTokenSource,
): Promise<boolean> => {
  const url = `api/marketplace/sites/pages/items/${pageItemId}`;
  return axios.delete(
    url,
    { cancelToken: cancelTokenSource.token },
  ).catch((error) => {

    logIfCancelled(error, 'updatePageItem');
    throw error;
  })
    .then((response: AxiosResponse<any>) => {
      return true;
    });
};

export const createPageItem = (
  pageId: string,
  pageItem: { type: string, params: string },
  cancelTokenSource: CancelTokenSource,
): Promise<{pageItem: MarketplaceSitePageItem | null, errors?: any}> => {
  const url = 'api/marketplace/sites/pages/items';
  return axios.post(
    url,
    { page_id: pageId, type: pageItem.type, parameters: pageItem.params },
    { cancelToken: cancelTokenSource.token },
  ).catch((error) => {

    if (error.response && error.response.status === 422) {
      return error.response;
    }

    logIfCancelled(error, 'createPageItem');
    throw error;
  })
    .then((response: AxiosResponse<any>) => {
      if (response.status === 422) {
        return {
          pageItem: null,
          errors: response.data.errors,
        };
      }

      return {
        pageItem: MarketplaceSitePageItem.fromApi(response.data.data),
      };
    });
};
