import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { UserContext } from '../../contexts';
import { AccountProfile, LabelOrder, LabelSupply } from '../../shared';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { PaymentManager, PaymentSubmissionStatus } from '../Payments';
import { CurrencyLabel, GatewayModal, LoadingSpinner, ModalType } from '../shared';

interface LabelOrderAddressAPIData {
  ship_to_company: string;
  ship_to_name: string;
  ship_to_phone: string;
  ship_to_address_line1: string;
  ship_to_address_line2: string;
  ship_to_city: string;
  ship_to_state: string;
  ship_to_zip: string;
  ship_to_country: string;
  order: {jpatton_id: string, quantity: number}[];
  shipping_method: string | null;
  shipping_service_type: string | null;
  shipping_account_number: string;
}

export const LabelsOrderPage = () => {
  const [supplies, setSupplies] = useState<LabelSupply[]>([]);
  const [loading, setLoading] = useState(true);
  const [shipping, setShipping] = useState(0);
  const [address, setAddress] = useState<LabelOrderAddressAPIData | null>(null);
  const [calculatingShipping, setCalculatingShipping] = useState(false);
  const [paymentModalShown, setPaymentModalShown] = useState(false);
  const [adminPaymentModalShown, setAdminPaymentModalShown] = useState(false);
  const [countries, setCountries] = useState<any[]>([]);
  const [vendor, setVendor] = useState<AccountProfile | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState('');
  const [shippingError, setShippingError] = useState('');
  const routeParams = useParams();
  const history = useHistory();
  const user = useContext(UserContext);

  useEffect(() => {
    getAvailableSupplies();
    getCountries();
    getVendorProfile();
  },        []);

  const getAvailableSupplies = async () => {
    setLoading(true);
    const vendorString = routeParams['vendorId'] ? `vendors/${routeParams['vendorId']}/` : '';

    const jpattonSupplies = await Axios.get(`/api/v2/${vendorString}labels/products`)
      .then(response => response.data.data.map((s: any) => LabelSupply.fromApi(s)));
    setSupplies(jpattonSupplies);
    setLoading(false);
  };

  const getCountries = async () => {
    const c = await Axios.get('/api/countries');
    setCountries(c.data);
  };

  const getVendorProfile = async () => {
    const profile = await Axios.get(`/api/accounts${routeParams['vendorId'] ? `/${routeParams['vendorId']}` : ''}`);
    setVendor(AccountProfile.fromApi(profile.data.data));
  };

  const submitOrder = async (paymentMethodId?: number): Promise<PaymentSubmissionStatus> => {

    const vendorID = routeParams['vendorId'] ? routeParams['vendorId'] : null;
    const body = Object.assign(
      {
        account_id: vendorID,
        payment_method_id: paymentMethodId ? `${paymentMethodId}` : undefined,
      },
      address,
      );

    const order = await Axios.post('/api/label-orders', body)
      .then((response) => {
        const labelOrder = LabelOrder.fromApi(response.data.data);
        history.push(`/labels/order/${labelOrder.id}`);
        return { success: true };
      })
      .catch(() => {
        return { success: false, error: 'There was an error submitting your label order.' };
      });
    return order;

  };

  const submitAdminOrder = async () => {
    if (!submitting) {
      setSubmitting(true);
      const order = await submitOrder();
      if (order.error) {
        setSubmissionError(order.error);
      }

      setSubmitting(false);
    }

  };

  const updateQuantity = (index: number, quantity: number)  => {
    const s = [... supplies];
    if (quantity >= 0) {
      s[index].orderQuantity = quantity;
    }
    setShipping(0);
    setAddress(null);
    setSupplies(s);
  };

  const submitAddress = async (values: any) => {
    if (values.shippingThirdParty) {
      const body = {
        ship_to_company: values.businessName,
        ship_to_name: values.contact,
        ship_to_phone: values.phone,
        ship_to_address_line1: values.street1,
        ship_to_address_line2: values.street2,
        ship_to_city: values.city,
        ship_to_state: values.state,
        ship_to_zip: values.zip,
        ship_to_country: values.country,
        shipping_method: values.shippingCarrier,
        shipping_service_type: values.shippingType,
        shipping_account_number: values.accountNumber,
        shipping_third_party: values.shippingThirdParty,
        order: supplies.filter(s => s.orderQuantity > 0).map((s) => {
          return {
            jpatton_id: `${s.jPattonId}`,
            quantity: s.orderQuantity,
          };
        }),

      };
      setAddress(body);
      setShipping(0);
      showPaymentModal();
    } else {
      if (address) {
        showPaymentModal();
      } else {
        await calculateShipping(values);
      }
    }
  };

  const calculateShipping = async (values: any) => {
    if (!calculatingShipping) {
      setCalculatingShipping(true);
      const body = {
        ship_to_company: values.businessName,
        ship_to_name: values.contact,
        ship_to_phone: values.phone,
        ship_to_address_line1: values.street1,
        ship_to_address_line2: values.street2,
        ship_to_city: values.city,
        ship_to_state: values.state,
        ship_to_zip: values.zip,
        ship_to_country: values.country,
        shipping_method: 'Fedex',
        shipping_service_type: values.shippingType,
        shipping_account_number: '',
        shipping_third_party: false,
        order: supplies.filter(s => s.orderQuantity > 0).map((s) => {
          return {
            jpatton_id: `${s.jPattonId}`,
            quantity: s.orderQuantity,
          };
        }),

      };
      const shippingCosts = await Axios.post('/api/label-shipping', body).catch(e => null);
      if (shippingCosts) {
        setShippingError('');
        setShipping(Number(shippingCosts.data.cost));
        setAddress(body);
        setCalculatingShipping(false);
      } else {
        setCalculatingShipping(false);
        setShippingError('Thank you for your patience. We are working with our fulfillment partner to resolve order processing issues. Please try your order again later.');
      }

    }

  };

  const showPaymentModal = () => {
    if (user.type === 'admin') {
      setAdminPaymentModalShown(true);
    } else {
      setPaymentModalShown(true);
    }
  };

  const addressFormChange = (event: any, formProps: FormikProps<any>) => {
    setAddress(null);
    setShipping(0);
    formProps.handleChange(event);
  };

  const orderSubTotal = supplies.reduce((p, n) => p + (n.orderQuantity * n.price), 0);

  let body;
  if (loading) {
    body = (<LoadingSpinner />);
  } else {

    const formValidation = Yup.object().shape({
      businessName: Yup.string().required('Business Name is required'),
      contact: Yup.string().required('Contact Name is required.'),
      phone: Yup.string().required('Contact Phone is required.'),
      street1: Yup.string().required('Address Line 1 is required.').test(
          'no-po-box',
          'We cannot ship to a P.O Box.',
          value => value && value.toLowerCase().replace(/[^a-z]/gi, '').indexOf('pobox') === -1,
        ),
      street2: Yup.string().test(
          'no-po-box',
          'We cannot ship to a P.O Box.',
          value => !value || value.toLowerCase().replace(/[^a-z]/gi, '').indexOf('pobox') === -1,
        ),
      city: Yup.string().required('City is required.'),
      state: Yup.string().required('State is required'),
      zip: Yup.string().required('Zip is required.'),
      country: Yup.string().required('Country is required.'),
      shippingThirdParty: Yup.boolean().required('Shipping Method is required.'),
      shippingCarrier: Yup.string().when('shippingThirdParty', {
        is: true,
        then: Yup.string().required('Shipping Carrier is required.'),
      }),
      shippingType: Yup.string().required('Shipping Service Type is required.'),
      accountNumber: Yup.string().when(['shippingThirdParty', 'shippingCarrier'], (shippingThirdParty: boolean, shippingCarrier: string, schema: any) => {
        if (shippingThirdParty) {
          if (shippingCarrier === 'UPS') {
            return schema.matches(/^[0-9a-zA-Z]{6}$/, 'Account Number must be exactly 6 characters')
                .required('Account Number is required');
          }
          if (shippingCarrier === 'DHL' || shippingCarrier === 'Fedex') {
            return schema.matches(/^\d{9}$/, 'Account Number must be exactly 9 digits')
                .required('Account Number is required');
          }
        }
        return schema;
      }),
    });

    let intialValues = {
      businessName: '',
      contact: '',
      phone: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      shippingThirdParty: false,
      shippingCarrier: '',
      shippingType: '',
      accountNumber: '',
    };
    if (vendor) {
      intialValues = {
        businessName: vendor.accountName,
        contact: vendor.primaryConact.fullName,
        phone: vendor.businessAddress.phone ? vendor.businessAddress.phone : '',
        street1: vendor.businessAddress.line1 ? vendor.businessAddress.line1 : '',
        street2: vendor.businessAddress.line2 ? vendor.businessAddress.line2 : '',
        city: vendor.businessAddress.city,
        state: vendor.businessAddress.state,
        zip: vendor.businessAddress.zip,
        country: 'US',
        shippingThirdParty: false,
        shippingCarrier: '',
        shippingType: '',
        accountNumber: '',
      };
    }

    const supplyList = supplies.map((s, index) => (
      <div className="labels-order-item row">
        <div style={{ height: 150 }} className="hidden-xs col-sm-2">
          <img style={{ maxHeight: 150 }} src={s.file.url.sm} className="img-responsive" />
        </div>
        <div className="labels-product-information col-sm-4 col-xs-6">
          <h5><strong>{s.name}</strong></h5>
          <p className="small">{s.description}</p>
        </div>
        <div className="col-sm-2 col-xs-2">
          <h5 className="text-center"><strong><CurrencyLabel value={s.price} /></strong></h5>
        </div>
        <div className="labels-order-actions col-sm-offset-2 col-sm-2 col-xs-6">
          <div className="labels-buttons">

            <button onClick={() => updateQuantity(index, s.orderQuantity - 1)} className="btn btn-xs btn-default">
              <FontAwesomeIcon icon={faMinus} />
            </button>
            <span>{s.orderQuantity}</span>
            <button onClick={() => updateQuantity(index, s.orderQuantity + 1)} className="btn btn-xs btn-default">
              <FontAwesomeIcon icon={faPlus} />
            </button>

          </div>
          <div className="labels-order-details">
            <span className="small text-muted"><CurrencyLabel value={s.price * s.orderQuantity} /></span>
            {s.quantity ? <span className="small text-muted">
              <NumberFormat value={s.quantity * s.orderQuantity} displayType="text" thousandSeparator={true}  /> Labels
            </span> : null}
            {s.orderQuantity > 0 ?
            <button onClick={() => updateQuantity(index, 0)} className="btn btn-link small">Reset</button>
              :
            null}

          </div>

        </div>
      </div>

    ));
    const countryOptions = countries.map(country =>
      (<option key={country.abbr} value={country.abbr}>{country.name}</option>),
    );
    body = (
      <div>
        <div style={{ marginBottom: 15 }} className="row text-muted">
          <div className="hidden-xs col-sm-2"></div>
          <div className="col-sm-4 col-xs-6">
            Product Information
            </div>
          <div className="col-sm-2 col-xs-2 text-center">
            Price Per Unit
            </div>
          <div className="labels-order-actions col-sm-offset-2 col-sm-2 col-xs-6">

          </div>
        </div>
        {supplyList}
        <h4 className="text-primary text-center"><strong>Shipping Address</strong></h4>
        <Formik
          initialValues={intialValues}
          onSubmit={values =>  submitAddress(values)}
          validationSchema={formValidation}
          enableReinitialize={true}
        >
          {(formProps: FormikProps<any>) => (
            <Form>
              {formProps.touched.length}
              <div className="row">
                <div className="col-xs-12 col-md-8 col-md-offset-2">
                  <div className="form-group">
                    <label>Business Name</label>
                    <Field className="form-control" name="businessName" />
                    <p className="text-danger"><ErrorMessage name="businessName" /></p>
                  </div>
                  <div className="form-group">
                    <label>Contact Name</label>
                    <Field className="form-control" name="contact" />
                    <p className="text-danger"><ErrorMessage name="contact" /></p>
                  </div>
                  <div className="form-group">
                    <label>Contact Phone</label>
                    <Field className="form-control" name="phone" />
                    <p className="text-danger"><ErrorMessage name="phone" /></p>
                  </div>
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label>Address Line 1</label>
                        <input
                          value={formProps.values.street1}
                          className="form-control"
                          name="street1"
                          onChange={event => addressFormChange(event, formProps)}
                        />
                        <p className="text-danger"><ErrorMessage name="street1" /></p>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Address Line 2</label>
                        <input
                          value={formProps.values.street2}
                          className="form-control"
                          name="street2"
                          onChange={event => addressFormChange(event, formProps)}
                        />
                        <p className="text-danger"><ErrorMessage name="street2" /></p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          value={formProps.values.city}
                          className="form-control"
                          name="city"
                          onChange={event => addressFormChange(event, formProps)}
                        />
                        <p className="text-danger"><ErrorMessage name="city" /></p>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>State</label>
                        <input
                          value={formProps.values.state}
                          name="state"
                          onChange={event => addressFormChange(event, formProps)}
                          className="form-control"
                        />

                        <p className="text-danger"><ErrorMessage name="state" /></p>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label>Zip</label>
                        <input
                          value={formProps.values.zip}
                          className="form-control"
                          name="zip"
                          onChange={event => addressFormChange(event, formProps)}
                        />
                        <p className="text-danger"><ErrorMessage name="zip" /></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      value={formProps.values.country}
                      className="form-control"
                      name="country"
                      onChange={event => addressFormChange(event, formProps)}
                    >
                      {countryOptions}
                    </select>
                    <p className="text-danger"><ErrorMessage name="country" /></p>
                  </div>

                </div>
                <div style={{ borderBottom: '1px solid #eee', marginTop: 20, marginBottom: 20 }} className="col-md-12">
                </div>
                <div className="col-xs-12 col-md-8 col-md-offset-2">
                  <div className="form-group">
                    <h4 className="text-primary text-center">
                      <strong>Shipping Method</strong>
                    </h4>
                    <p className="text-danger">
                      <ErrorMessage name="shippingThirdParty" />
                    </p>
                    <div className="radio" style={{ border: '1px solid #e3e3e3', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                      <label>
                        <Field type="radio" name="shippingThirdParty" defaultChecked={true} onChange={() => {
                          formProps.setFieldValue('shippingThirdParty', false);
                          setShipping(0);
                          setAddress(null);
                        }}  />
                        <strong>Ship via FedEx with shipping fees billed on this invoice</strong><br />
                        Shipping fees will be calculated and included in your order total
                      </label>
                    </div>
                    <div className="radio" style={{ border: '1px solid #e3e3e3', padding: '20px', borderRadius: '4px', marginBottom: '20px' }}>
                      <label>
                        <Field type="radio" name="shippingThirdParty" onChange={() => {
                          formProps.setFieldValue('shippingThirdParty', true);
                          setShipping(0);
                        }} />
                        <strong>Provide third-party shipping account information</strong><br />
                        Shipping fees will be billed from your third-party shipping account<br />
                        FedEx, UPS and DHL accounts accepted
                      </label>
                    </div>
                  </div>
                  { formProps.values.shippingThirdParty ?
                    <>
                      <div className="form-group">
                        <label>Shipping Carrier</label>
                        <Field component="select"
                               name="shippingCarrier"
                               className="form-control"
                               onChange={(e:any) => {
                                 formProps.setFieldValue('shippingCarrier', e.target.value);
                                 setShipping(0);
                                 setAddress(null);
                               }}
                        >
                          <option value="" disabled>Select Shipping Carrier</option>
                          <option value="Fedex">FedEx</option>
                          <option value="UPS">UPS</option>
                          <option value="DHL">DHL</option>
                        </Field>
                        <p className="text-danger"><ErrorMessage name="shippingCarrier" /></p>
                      </div>
                      <div className="form-group">
                        <label>Account Number</label>
                        <Field className="form-control" name="accountNumber" placeholder="Enter Account Number" />
                        <p className="text-danger"><ErrorMessage name="accountNumber" /></p>
                      </div>
                      <div className="text-muted">By entering your shipping account you are authorizing Affinity and our labeling fulfillment partner to ship this order and bill shipping charges to your account.
                      </div>
                    </>
                    : null}
                  <br />
                    <div className="form-group">
                      <label>Shipping Service Type</label>
                      <Field
                        component="select"
                        className="form-control"
                        name="shippingType"
                        onChange={(e:any) => {
                          formProps.setFieldValue('shippingType', e.target.value);
                          setShipping(0);
                          setAddress(null);
                        }}
                      >
                        <option value="" disabled>Select Shipping Service Type</option>
                        <option value="Ground">Ground - Standard, Lowest cost</option>
                        <option value="Saver">Three Day - Faster</option>
                        <option value="2Day">Two Day - Faster</option>
                        <option value="Overnight">Standard Overnight - Fastest</option>
                        <option value="Priority">Priority Overnight - Fastest</option>
                      </Field>
                      <p className="text-muted">Shipping times are an approximate estimation and may vary based on the carrier and address.</p>
                      <p className="text-danger"><ErrorMessage name="shippingType" /></p>
                    </div>
                </div>
                <div style={{ borderBottom: '1px solid #eee', marginTop: 20, marginBottom: 20 }} className="col-md-12">
                </div>
                <div style={{ paddingBottom: 20 }} className="col-xs-12 col-md-8 col-md-offset-2">
                  {shippingError ? <div className="alert alert-danger">{shippingError}</div> : null}
                  <div className="labels-order-totals">
                    <div className="label-order-total">
                      <h4 className="text-muted"><strong>Subtotal</strong></h4>
                      <h4><strong><CurrencyLabel value={orderSubTotal} /></strong></h4>
                    </div>
                    { !formProps.values.shippingThirdParty ?
                    <div className="label-order-total">
                      <h4 className="text-muted"><strong>Shipping</strong></h4>
                      <h4><strong>{shipping ? <CurrencyLabel value={shipping} /> : '$-.--'}</strong></h4>
                    </div>
                    : null}
                    {user.type === 'admin' ? <div className="label-order-total">
                      <h4 className="text-muted"><strong>Admin Fee</strong></h4>
                      <h4><strong><CurrencyLabel value={25} /></strong></h4>
                    </div> : null}
                    <div style={{ paddingBottom: 20 }} className="label-order-total">
                      <h4 className="text-muted"><strong>Total</strong></h4>
                      <h4>
                        <strong>
                          {shipping != null ?
                            <CurrencyLabel value={Number(shipping) + orderSubTotal + (user.type === 'admin' ? 25 : 0)} />
                            : '$-.--'
                          }
                        </strong>
                      </h4>
                    </div>
                    {!address && !formProps.values.shippingThirdParty ? <button disabled={orderSubTotal === 0 || formProps.values.shippingThirdParty == null} className="btn btn-block btn-primary">
                      {calculatingShipping ? 'Calculating...' : 'Calculate Order Shipping'}

                    </button> : null}
                    {address || formProps.values.shippingThirdParty ? <button disabled={orderSubTotal === 0} className="btn btn-block btn-primary">
                      {user.type === 'admin' ? 'Submit and Invoice' : 'Submit and Pay'}

                    </button> : null}

                  </div>

                </div>

              </div>

            </Form>
          )}

        </Formik>

      </div>
    );

  }

  const orderTotalCents = Number(shipping) * 100 + Number(orderSubTotal) * 100 + (user.type === 'admin' ? 2500 : 0);

  return (
   <FullContent>
     <DocumentTitle title={getAffinityPageTitle('Labels')}  />
      <div style={{ paddingBottom: 70 }}>
        <div className="panel panel-portal">
          <div className="panel-heading">
            <h3><strong>Order Labeling Supplies</strong></h3>
          </div>
          <div className="panel-body">
            {body}

          </div>
          <GatewayModal
            title="Submit Label Order"
            shown={paymentModalShown}
            onClose={() => setPaymentModalShown(false)}
            type={ModalType.Primary}
          >
            <Modal.Body>
              <PaymentManager
                amount={orderTotalCents}
                onSuccess={() => console.log('success')}
                accountId={routeParams['vendorId'] ? routeParams['vendorId'] : ''}
                modalMode={true}
                paymentMethodSelected={submitOrder}
              />
            </Modal.Body>

          </GatewayModal>
          <GatewayModal
            title="Submit Label Order"
            shown={adminPaymentModalShown}
            onClose={() => setAdminPaymentModalShown(false)}
            type={ModalType.Primary}
          >
            <Modal.Body className="text-center">
              {submissionError ? <div className="alert alert-danger">{submissionError}</div> : null}
              <strong style={{ fontSize: 16 }}>
                An open invoice for <CurrencyLabel value={orderTotalCents / 100} /> will be created.
              </strong>
              <br />
              <span style={{ fontSize: 16 }} className="text-danger">This total includes a $25 administration fee.</span>

            </Modal.Body>
            <Modal.Footer>
              <button onClick={() => setAdminPaymentModalShown(false)} className="btn btn-default pull-left">Cancel</button>
              <button onClick={submitAdminOrder} className="btn btn-primary pull-right">{!submitting ? 'Submit Label Order' : 'Submitting...'}</button>
            </Modal.Footer>

          </GatewayModal>
        </div>
      </div>

   </FullContent>

  );

};
