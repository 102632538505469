import * as React from 'react';
import { IBreadcrumb } from '../Breadcrumbs';

interface Props {
  main: React.ReactNode;
  sidebar: React.ReactNode;
  noPadding?: boolean;
  breadcrumbs?: IBreadcrumb[];
  columnSizes?: ContentColumnSizes;

}
export interface ContentColumnSizes {
  sidebar: string;
  main: string;
}

export class ContentWithSidebar extends React.PureComponent<Props, any> {
  public render() {
    let sidebarClass = 'col-xl-2 col-lg-3 col-md-4';
    let mainClass = 'col-xl-10 col-lg-9 col-md-8';
    if (this.props.columnSizes) {
      sidebarClass = this.props.columnSizes.sidebar;
      mainClass = this.props.columnSizes.main;
    }

    return (
      <div>
        <div className="row" >
          <div className={mainClass}>
            {this.props.main}
          </div>
          <div className={sidebarClass}>
            {this.props.sidebar}
          </div>
        </div>
      </div>
    );
  }
}
