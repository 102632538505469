import { CCPaymentMethod } from './Payments';

export class Invoice {
  id: number;
  accountId: number;
  invoiceNumber: string;
  invoiceType: { id: number, display: string };
  isOpen: boolean;
  invoiceStatus: number;
  paymentStatusId: number;
  amountTotal: number;
  partialPayments: boolean;
  payments: any[];
  private _amountDue: number;

  constructor(data?: any) {
    if (data) {
      this.id = Number(data.id);
      this.accountId = Number(data.account_id);
      this.invoiceNumber = String(data.invoice_number);
      this.invoiceType = data.invoice_type;
      this.isOpen = data.is_open;
      this.invoiceStatus = Number(data.invoice_status);
      this.paymentStatusId = Number(data.payment_status_id);
      this.amountTotal = Number(data.amount_total);
      this._amountDue = Number(data.amount_due);
      this.payments = data.payments;
    }
  }

  static fromApi(data: InvoiceApiResponse) {
    const invoice = new Invoice();
    invoice.id = data.id;
    invoice.accountId = data.account_id;
    invoice.invoiceType = data.invoice_type;
    invoice.isOpen = data.is_open;
    invoice.invoiceStatus = data.invoice_status;
    invoice.paymentStatusId = data.payment_status_id;
    invoice.amountTotal = data.amount_total;
    invoice._amountDue = data.amount_due;
    invoice.partialPayments = data.partial_payments;
    invoice.payments = data.payments;
    return invoice;
  }

  get amountDue() {
    return this._amountDue / 100;
  }

  calculateTotalWithFee(paymentMethod: CCPaymentMethod) {
    return (this._amountDue + paymentMethod.fees.calculated) / 100;
  }
}

export interface InvoiceApiResponse {
  id: number;
  account_id: number;
  invoice_number: string;
  invoice_type: { id: number, display: string };
  is_open: boolean;
  invoice_status: number;
  payment_status_id: number;
  amount_total: number;
  amount_due: number;
  partial_payments: boolean;
  payments: any[];
}
