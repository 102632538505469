import * as moment from 'moment';
import { MarketplaceProduct, MarketplaceProductApiResponse } from './MarketplaceProduct';

export interface MarketplaceInquiryApiResponse {
  id: number;
  quantity: number;
  comments: string;
  product: MarketplaceProductApiResponse;
  due_date: string;
  share_email: boolean;
  share_phone: boolean;
  email: string;
  phone: string;
}

export class MarketplaceInquiry {
  id: number;
  quantity: number;
  message: string;
  product: MarketplaceProduct;
  dueDate: moment.Moment;
  shareEmail: boolean;
  sharePhone: boolean;
  email: string;
  phone: string;

  static fromApi(data: MarketplaceInquiryApiResponse) {
    const inquiry = new MarketplaceInquiry();
    inquiry.id = data.id;
    inquiry.quantity = data.quantity;
    inquiry.message = data.comments;
    if (data.product) {
      inquiry.product = MarketplaceProduct.fromApi(data.product);
    }
    inquiry.dueDate = moment(data.due_date);
    inquiry.shareEmail = data.share_email;
    inquiry.sharePhone = data.share_phone;
    inquiry.phone = data.phone;
    inquiry.email = data.email;

    return inquiry;

  }

}
