import * as moment from 'moment';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
// import Axios from 'axios';
// import Axios from 'axios';

interface IProps {
  routes: any[];
  account: any;
  slide: boolean;
}

export class SecondaryNavigation extends React.Component <IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.createSecondaryNavLink = this.createSecondaryNavLink.bind(this);
  }

  createSecondaryNavLink(route: any) {
    let navLink;
    if (route.title.toLowerCase() === 'designs') {
      navLink =
      <a href={`${this.props.account.url}/${route.path}`}>
        {route.title} <span dangerouslySetInnerHTML={{ __html: route.status }}></span>
      </a>;
    } else {
      navLink =
      <NavLink to={`${this.props.account.url}/${route.path}`} strict={false} exact={false}>
       {route.title} <span dangerouslySetInnerHTML={{ __html: route.status }}></span>
      </NavLink>;
    }
    return (<li key={route.title}>{navLink}</li>);
  }

  render() {
    let secondaryNavClassName;
    if (this.props.routes.length) {
      if (this.props.slide) {

        secondaryNavClassName = 'secondary-navigation fade-in slide-over';
      } else {

        secondaryNavClassName = 'secondary-navigation fade-in';
      }

    } else {
      secondaryNavClassName = 'secondary-navigation';

    }
    const links = this.props.routes.map(route => this.createSecondaryNavLink(route));
    let accountImage;
    let accountStatus;
    let accountURL = '#';
    let notesLink;
    if (this.props.account) {
      accountURL = this.props.account.url;
      accountImage = this.props.account.image.sm;
      accountStatus = (
        <li>
          <Link to={`${accountURL}/settings`}>
            <div dangerouslySetInnerHTML={{ __html: this.props.account.statusLabel }}></div>
          </Link>
        </li>
      );

      const flag = this.props.account.flag.id;
      if (flag > 0) {
        const flaggedDate = moment(this.props.account.flagged_at);
        notesLink = (
          <li>
            <Link to={`${accountURL}/notes`}>
              <span className={`label label-${ flag === 1 ? 'warning' : 'danger'} btn-block`}>
                Flagged {flaggedDate.format('M D, YY')}
              </span>
            </Link>
          </li>

        );

      }
    }
    return (
      <div className={secondaryNavClassName} id="secondary-navigation">
        <div className="navigation-account">
          <Link to={accountURL}>
            <img src={accountImage} />
          </Link>
        </div>
        <ul>
          {links}
          {accountStatus}
          {notesLink}
        </ul>
      </div>
    );
  }
}
