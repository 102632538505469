interface IProps {
  selected: boolean;
  onToggle: (isChecked: boolean) => void;
  children: React.ReactNode;

}

export const SelectableRow = (props: IProps) => {
  return (
    <tr style={{ backgroundColor: props.selected ? '#def3ff' : 'white' }}>
      <td style={{ width: 50 }}>
        <div className="checkbox selectable-row-checkbox">
          <label>
            <input checked={props.selected} type="checkbox" onChange={e => props.onToggle(e.target.checked)} />
          </label>
        </div>
      </td>
      {props.children}
    </tr>
  );
};
