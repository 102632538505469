import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import DocumentTitle from 'react-document-title';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { createNoteThread } from '../../../api';
import { UserContext } from '../../../contexts';
import { EnforcementTemplate } from '../../../shared/EnforcementTemplate';
import { TrademarkCase } from '../../../shared/TrademarkCase';
import User from '../../../shared/User';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { InlineNotesListView } from '../../Notes';
import { GatewayImage, GatewayModal, LoadingSpinner, ModalType, UrlTypeTooltip } from '../../shared';
import { UsesIndexPage } from '../Uses';
import { closeCase, sendDmcaEmail } from '../Uses/api';

export const CaseDetailPage = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const [trademarkCase, setTrademarkCase] = React.useState<TrademarkCase | null>(null);
  const [templates, setTemplates] = React.useState<EnforcementTemplate[]>([]);
  const [templatePreview, setTemplatePreview] = React.useState('');
  const [selectedTemplate, setSelectedTemplate] = React.useState('');
  const [loadingTemplate, setLoadingTemplate] = React.useState(false);
  const [templateModalShown, setTemplateModalShown] = React.useState(false);
  const [submitEmailCancelToken, setSubmitEmailCancelToken] = React.useState<Axios.CancelTokenSource | null>(null);
  const [counts, setCounts] = React.useState<any[]>([]);
  const [deleteShown, setDeleteShown] = React.useState(false);
  const [updateShown, setUpdateShown] = React.useState(false);
  const [closeShown, setCloseShown] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [adminUsers, setAdminUsers] = React.useState<User[]>([]);
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    getCase();
    getTemplates();
    getCounts();
    getAdminUsers();

  },              [match]);

  React.useEffect(() => {
    if (selectedTemplate) {
      getTemplatePreview();
    }

  },              [selectedTemplate]);

  const getCase = async () => {
    const t = await Axios.get(`/api/trademark-cases/${match && match.params['id']}`);
    setTrademarkCase(TrademarkCase.fromApi(t.data.data));

  };

  const getTemplates = async () => {
    const t = await Axios.get('/api/enforcement-templates');
    setTemplates(t.data.data.map((d: any) => EnforcementTemplate.fromApi(d)));
  };

  const getTemplatePreview = async () => {
    if (trademarkCase) {
      setLoadingTemplate(true);
      const template = await Axios.get('/api/enforcement-template-previews', {
        params: {
          case_id: trademarkCase.id,
          template_id: selectedTemplate,
        },
      });
      setTemplatePreview(template.data);
      setLoadingTemplate(false);
    }
  };

  const submitDmcaEmail = async () => {
    setUpdating(true);
    if (trademarkCase && selectedTemplate) {
      if (submitEmailCancelToken) {
        submitEmailCancelToken.cancel();
      }
      const cancelTokenSource = Axios.CancelToken.source();
      setSubmitEmailCancelToken(cancelTokenSource);

      const responseMessage = await sendDmcaEmail(
        { caseId: trademarkCase.id, templateId: selectedTemplate },
        cancelTokenSource,
      );
      const createThreadEvent = new CustomEvent('note-list-reload', {
        bubbles: true,
        detail: { thread: (new Date()).getTime() },
      });
      document.body.dispatchEvent(createThreadEvent);
      setTemplateModalShown(false);
      if (! trademarkCase.initialContact) {
        getCase();
      }
      setUpdating(false);
    }
  };

  const togglePin = async () => {
    if (trademarkCase) {
      const selectedCase = { ... trademarkCase };
      selectedCase.isPinned = !selectedCase.isPinned;
      setTrademarkCase(selectedCase);
      if (selectedCase.isPinned) {
        await Axios.post(`/api/trademark-cases/${selectedCase.id}/pin`, {});
      } else {
        await Axios.delete(`/api/trademark-cases/${selectedCase.id}/pin`);
      }
    }

  };

  const copyTemplateToClipboard = () => {
    const element = document.getElementById('template-content-area');
    if (element) {
      const range = document.createRange();
      range.selectNode(element);
      const selection = window.getSelection();
      if (selection) {
        selection.removeAllRanges(); // clear current selection
        selection.addRange(range); // to select text
        document.execCommand('copy');
        selection.removeAllRanges();
      }

    }

  };

  const getCounts = async () => {
    const filters = {
      case_id: match && match.params['id'],
    };
    const cleaned = omitBy(filters, isNil);
    const c = await Axios.get('/api/trademark-use-counts', { params: cleaned });
    const m = c.data.data.map((d: any) => {
      return {
        id: d.status_id,
        name: d.status_name,
        count: d.count,
      };
    });
    const all = {
      id: 0,
      name: 'All',
      count: m.reduce((p: number, n: any) => p + n.count, 0),
    };
    setCounts([all, ... m]);

  };

  const statusColors = (id: number) => {
    switch (id) {
      case 1:
        return 'info';

      case 2:
        return 'danger';

      case 3:
        return 'primary';

      case 4:
        return 'success';

      default:
        return 'primary';
    }
  };

  const templateSelected = templates.find(t => t.id === Number(selectedTemplate));

  const deleteEnforceCase = async () => {
    if (trademarkCase) {
      setUpdating(true);
      await Axios.delete(`/api/trademark-cases/${trademarkCase.id}`);
      history.push('/enforce/cases');
      setUpdating(false);
    }
  };

  const updateEnforceCase = async (values: any) => {
    if (trademarkCase) {
      setUpdating(true);
      const params = {
        owner: values.owner,
        can_auto_close: values.autoClose,
        initial_contact: values.initialContact ? values.initialContact.format('YYYY-MM-DD') : null,
      };
      const c = await Axios.post(`/api/trademark-cases/${trademarkCase.id}`, params);
      setTrademarkCase(TrademarkCase.fromApi(c.data.data));
      setUpdateShown(false);
      setUpdating(false);
    }

  };

  const closeEnforceCase = async (values: any) => {
    if (trademarkCase) {
      setUpdating(true);
      const token = Axios.CancelToken.source();
      const c = await closeCase(`${trademarkCase.id}`, values.resolution, values.note, token);
      const createThreadEvent = new CustomEvent('note-list-reload', {
        bubbles: true,
        detail: { thread: (new Date()).getTime() },
      });
      document.body.dispatchEvent(createThreadEvent);
      if (c) {
        setTrademarkCase(c);
      }
      setUpdating(false);
      setCloseShown(false);
    }

  };

  const createNote = async () => {
    setUpdating(true);
    const element = document.getElementById('template-content-area');
    const data = {
      accountId: trademarkCase && trademarkCase.vendor && trademarkCase.vendor.id || 1,
      caseId: trademarkCase && trademarkCase.id || 1,
      subject: templateSelected ? templateSelected.subject ? templateSelected.subject : templateSelected.name : '',
      noteCode: '',
      isPinned: false,
      noteWarn: false,
      noteBody: element && element.innerHTML || '',
      noteMethodId: templateSelected ? templateSelected.isDmca ? 2 : 7 : 2,
      userIds:  user && [user.id] || undefined,
      noteAttachments: undefined,
      categoryIds: [15],
      noteDate: '',
    };
    const t = await createNoteThread(data);
    const createThreadEvent = new CustomEvent('note-list-reload', {
      bubbles: true,
      detail: { thread: t.id },
    });
    document.body.dispatchEvent(createThreadEvent);
    if (trademarkCase && ! trademarkCase.initialContact) {
      getCase();
    }
    setTemplateModalShown(false);
    setUpdating(false);

  };

  const getAdminUsers = async () => {
    const u = await Axios.get('/api/users?account_id=1');
    const users = u.data.data.map((u: any) => User.fromApi(u));
    setAdminUsers(users);
  };

  if (!trademarkCase) {
    return <FullContent><LoadingSpinner /></FullContent>;
  }

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Case')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ marginRight: 20 }}><strong>Case #{trademarkCase.id}</strong></h4>
              {trademarkCase.owner ?
                <OverlayTrigger
                  trigger="hover"
                  placement="top"
                  overlay={(
                    <Tooltip>
                      {trademarkCase.owner.fullName}
                    </Tooltip>
                  )}

                >
                  <span>
                  <GatewayImage image={trademarkCase.owner.image} size="th" width={30} height={30} borderRadius={30} />
                  </span>
                </OverlayTrigger>
                      : null}
              {/* {trademarkCase.owner ? <NoteUserList users={[trademarkCase.owner]} /> : null} */}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <button className="btn btn-primary" onClick={() => setTemplateModalShown(true)}>
                Templates
              </button>
              {trademarkCase.closedAt ?
              null :
              <DropdownButton  bsStyle="default" id="case-action" title="Options" style={{ marginLeft: 10 }}>
                <MenuItem onClick={() => setCloseShown(true)}>Close Case</MenuItem>
                <MenuItem onClick={() => setUpdateShown(true)}>Update Case</MenuItem>
                <MenuItem onClick={() => setDeleteShown(true)}>Delete Case</MenuItem>

              </DropdownButton>}

            </div>
          </div>

          <div>
            <h5 className="text-muted"><strong>Status</strong> <div className="label label-primary">{trademarkCase.closedAt ? 'CLOSED' : 'OPEN'}</div></h5>
            {trademarkCase.createdBy ?
              <p className="small">Created by {trademarkCase.createdBy.fullName} on {trademarkCase.createdAt.format('MMM DD, YYYY')} ({trademarkCase.createdAt.fromNow()})</p>
            : null}
            <p className="small">Auto-close {trademarkCase.canAutoClose ? 'enabled' : 'disabled'}</p>

            {trademarkCase.vendor ? <div className="row">
              <div className="col-md-6">
                <h5 className="text-muted"><strong>Business Profile</strong></h5>
                <address>
                  <strong>{trademarkCase.vendor.legalName}</strong><br />
                  {trademarkCase.vendor.businessAddress.singleLine}<br />
                  {trademarkCase.vendor.businessAddress.phone}

                </address>
              </div>
              {trademarkCase.vendor.primaryConact ? <div className="col-md-6">
                <h5 className="text-muted"><strong>Primary Contact</strong></h5>
                <address>
                  <strong>{trademarkCase.vendor.primaryConact.fullName}</strong><br />
                  {trademarkCase.vendor.primaryConact.email}<br />
                  {trademarkCase.vendor.primaryConact.phone}

                </address>

              </div> : null}
            </div> : null}

            <div className="row">
              <div className="col-md-6">
                <h5 className="text-muted"><strong>Platforms</strong></h5>

                {trademarkCase.platforms.map(p => <UrlTypeTooltip type={p} />)}
              </div>
              <div className="col-md-6">
                <h5 className="text-muted"><strong>Property Owners</strong></h5>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {trademarkCase.propertyOwners.map(o => (
                    <OverlayTrigger
                      trigger="hover"
                      placement="top"
                      overlay={(
                        <Tooltip>
                          {o.accountName}
                        </Tooltip>
                      )}

                    >
                      <span>
                        <GatewayImage image={o.logo} size="th" width={30} height={30} borderRadius={30} />
                      </span>
                    </OverlayTrigger>
                  ))}
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>

      <div>
        <UsesIndexPage caseId={trademarkCase.id}
        showUseButton={true}
        useFullContent={false}/>
      </div>

      {trademarkCase.vendor ? <div className="panel panel-portal" style={{ marginTop:15 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }} className="panel-heading">
          <h3><strong>Recent Notes</strong></h3>
          <Link to={`/vendors/${trademarkCase.vendor.id}/notes`}>View All Notes</Link>
        </div>

        <InlineNotesListView accountId={trademarkCase.vendor.id} accountType="vendor" />

      </div> : null }
      <GatewayModal
        shown={templateModalShown}
        type={ModalType.Primary}
        onClose={() => setTemplateModalShown(false)}
        title="Case Action"
        size="large"
      >
        <Modal.Body>
          <p className="text-muted">Select Template to generate preview</p>
          <div className="form-group">
            <label>Template</label>
            <select value={selectedTemplate} onChange={e => setSelectedTemplate(e.target.value)} className="form-control">
              <option value="" disabled selected>Select Template</option>
              {templates.map(t => <option value={t.id}>{t.name}</option>)}
            </select>
          </div>

          <hr />

          {loadingTemplate ? <LoadingSpinner /> : <div style={{ maxHeight: 200, overflow: 'scroll', backgroundColor: 'transparent' }} className="well">
            <div
              id="template-content-area"
              dangerouslySetInnerHTML={{ __html: templatePreview }}
            />

          </div> }

          {templatePreview ? (
            <div>
              <button style={{ marginBottom: 10 }} onClick={copyTemplateToClipboard} className="btn btn-block btn-default">
                Copy Template
              </button>
              {templateSelected && templateSelected.isDmca ? (
                <div className="row">
                  <div className="col-md-6">
                    <a
                      href={templateSelected ?
                        templateSelected.platform &&
                        templateSelected.platform.dmca && templateSelected.platform.dmca.formUrl || '' : '' }
                      target="_blank"
                      className="btn btn-block btn-default"
                    >
                        View Web Form
                    </a>
                  </div>
                  <div className="col-md-6">
                    <button onClick={submitDmcaEmail} className="btn btn-block btn-primary">{updating ? 'Sending...' : 'Send Platform Notice'}</button>
                  </div>
                </div>
              ) : (<button onClick={createNote} className="btn btn-block btn-primary">{updating ? 'Saving...' : 'Save Account Note'}</button>)}

            </div>
          ) : null}

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setTemplateModalShown(false)} className="btn btn-default pull-left">Cancel</button>
        </Modal.Footer>

      </GatewayModal>
      <GatewayModal
        shown={updateShown}
        type={ModalType.Primary}
        title="Update Case"
        onClose={() => setUpdateShown(false)}
      >
        <Formik
          onSubmit={updateEnforceCase}
          initialValues={{
            owner: trademarkCase.owner ? trademarkCase.owner.id : null,
            autoClose: trademarkCase.canAutoClose ? 1 : 0,
            initialContact: trademarkCase.initialContact,
          }}
        >
          {(formProps: any) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Owner</label>
                  <Field className="form-control" component="select" name="owner">
                    <option>Select Owner</option>
                    {adminUsers.map(u => <option value={u.id}>{u.fullName}</option>)}
                  </Field>
                </div>
                <div className="form-group">
                  <label>Initial Contact</label>
                  <DatePicker
                    selected={formProps.values.initialContact}
                    onChange={date => formProps.setFieldValue('initialContact', date)}
                    customInput={(
                      <div>
                        <input
                          id="initial-contact"
                          value={formProps.values.initialContact ? formProps.values.initialContact.format('MM/DD/YYYY') : null}
                          onChange={formProps.handleChange}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    )}
                  />
                </div>
                <div className="form-group">
                  <label>Auto-Close</label>
                  <Field className="form-control" component="select" name="autoClose">
                    <option value={1}>Enabled</option>
                    <option value={0}>Disabled</option>
                  </Field>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => setUpdateShown(false)} type="button" className="btn btn-default pull-left">
                  Cancel
                </button>
                <button className="btn btn-primary pull-right">{updating ? 'Updating...' : 'Update Case'}</button>
              </Modal.Footer>
            </Form>

          )}

        </Formik>
      </GatewayModal>
      <GatewayModal
        shown={deleteShown}
        type={ModalType.Danger}
        title="Delete Case"
        onClose={() => setDeleteShown(false)}
      >
        <Modal.Body>
          <p className="text-center">
            Are you sure you want to delete this case? All trademark use records linked to this case will also be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setDeleteShown(false)} className="btn btn-default pull-left">Cancel</button>
          <button onClick={deleteEnforceCase} className="btn btn-danger pull-right">
            {updating ? 'Deleting...' : 'Yes, Delete Case'}
          </button>
        </Modal.Footer>
      </GatewayModal>
      <GatewayModal
        shown={closeShown}
        type={ModalType.Primary}
        title="Close Case"
        onClose={() => setCloseShown(false)}
      >
        <Formik validationSchema={Yup.object().shape({
          resolution: Yup.number().positive('Resolution type is required').required('Resolution type is required.'),
          note: Yup.string().required('Note is required.'),
        })} initialValues={{ resolution: 0, note: '' }} onSubmit={closeEnforceCase}>
          {formProps => (
            <Form>
              <Modal.Body>
                <p className="text-center">
                  Closing this case will automatically update all unresolved trademark use to be resolved.
                  Please select the resolution type:
                </p>
                <div className="form-group">
                  <label>Resolution Type</label>
                  <Field name="resolution" className="form-control" component="select" >
                    <option value={0}>Select Resolution Type</option>
                    <option value={1}>Removed</option>
                    <option value={2}>Non-Infringing</option>
                    <option value={3}>Licensed</option>
                  </Field>
                  {formProps.errors.resolution ? (
                    <p className="text-danger">
                      {formProps.errors.resolution}
                    </p>
                  ) : null }
                </div>
                <div className="form-group">
                  <label>Note</label>
                  <Field name="note" className="form-control" component="textarea" > </Field>
                  {formProps.errors.note ? (
                    <p className="text-danger">
                      {formProps.errors.note}
                    </p>
                  ) : null }
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setCloseShown(false)} className="btn btn-default pull-left">
                  Cancel
                </button>
                <button className="btn btn-primary pull-right">
                  {updating ? 'Closing...' : 'Close Case'}
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </GatewayModal>
    </FullContent >
  );

};
