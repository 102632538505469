import * as React from 'react';
import { RoyaltyReport } from '../../../shared';
import { getDueDateText, getSalesText } from '../../../utils';
import ReportActionLogItem from './ReportActionLogItem';

interface IProps {
  report: RoyaltyReport;
  onlyActions?: boolean;
}

const ReportSubmissionInformation = (props: IProps) => {

  const report = props.report;

  const salesText = getSalesText(report.quarter, report.year);
  const lastUpdatedTimeString = report.updatedAt.format('LLL');

  const dueDateText = getDueDateText(report.quarter, report.year);

  const dueDateBlock = dueDateText ? <p className="text-muted">Due on {dueDateText}</p> : <></>;

  const submittedOnBlock = report.submittedAt ? (
    <p className="text-muted">Submitted on {report.submittedAt.format('LLL')}</p>
  ) : (
    <></>
  );

  const submittedByBlock = report.submittedBy ? (
    <p className="text-muted">Submitted by {report.submittedBy.firstName} {report.submittedBy.lastName}</p>
  ) : (
    <></>
  );

  const reviewedByBlock = report.adminUser && report.phase.id === 14 ? (
    <p className="text-muted">Reviewed by {report.adminUser.firstName} {report.adminUser.lastName}</p>

  ) : null;

  const actions = report.actions;
  actions.sort((a, b) => {
    return b.actionAt.unix() - a.actionAt.unix();
  });

  const actionLogItems = actions.map(action => (
    <ReportActionLogItem action={action} />
  ));

  if (props.onlyActions) {
    return <div>{actionLogItems}</div>;
  }

  return (
    <div>
    <div style={{ padding: 20 }} className="panel panel-portal">
      <p className="text-muted">{salesText}</p>
      {dueDateBlock}
      <p className="text-muted">Last Updated {lastUpdatedTimeString}</p>
      {submittedOnBlock}
      {submittedByBlock}
      {reviewedByBlock}
    </div>

    {actionLogItems}
    </div>
  );
};

export default ReportSubmissionInformation;
