import * as React from 'react';
import { canadianProvinces, states, usTerritories } from '../../../shared';

interface IProps {
  value: any;
  short: boolean;
  onChange: (state: any) => void;
  style? : any;
  includeOtherStates: boolean;
}

export class StateField extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event: React.FormEvent<HTMLSelectElement>) {
    const state = event.currentTarget.value;
    this.props.onChange(state);
  }

  render() {
    const s = states.map(state => (
      <option key={state.shortName} value={state.shortName}>{state.state}</option>
    ));
    const t = this.props.includeOtherStates ? usTerritories.map(state => (
      <option key={state.shortName} value={state.shortName}>{state.state}</option>
    )) : null;
    const c = this.props.includeOtherStates ? canadianProvinces.map(state => (
      <option key={state.shortName} value={state.shortName}>{state.state}</option>
    )) :  null;
    return (
      <select
        style={this.props.style}
        value={this.props.value}
        name="state"
        className="form-control"
        onChange={this.onChange}
      >
        <option value="" selected disabled>Select a State</option>
        {s}
        {this.props.includeOtherStates ? <option disabled>-- US Territories --</option> : null}
        {t}
        {this.props.includeOtherStates ? <option disabled>-- Canadian Provinces --</option> : null}
        {c}
      </select>
    );
  }
}
