import Axios from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useHistory, useParams } from 'react-router';
import { MarketplaceSitePage } from '../../../shared/MarketplaceSitePage';
import { FullContent } from '../../ContentFrame';

export const NewSitePagePage = () => {
  const [saving, setSaving] = React.useState(false);
  const params = useParams();
  const history = useHistory();

  const pageFormValues = {
    name: '',
    path: '',
    description: '',
  };

  const createPage = async (values: any) => {
    setSaving(true);
    const data = {
      site_id: params['siteId'],
      title: values.name,
      path: values.path,
      description: values.description,
    };
    const create = await Axios.post('/api/marketplace/sites/pages', data);
    if (create) {
      const page = MarketplaceSitePage.fromApi(create.data.data);
      history.push(`/marketplace/sites/${params['siteId']}/pages/${page.id}`);
    }

  };

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3><strong>New Page</strong></h3>
          <hr />
          <Formik initialValues={pageFormValues} onSubmit={createPage}>
            {(props: FormikProps<any>) => (
              <Form>
                <div className="form-group">
                  <label>Name</label>
                  <Field className="form-control" name="name" />
                </div>
                <div className="form-group">
                  <label>Path</label>
                  <div className="input-group">
                    <span className="input-group-addon">/</span>
                    <Field className="form-control" name="path" />
                  </div>
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <Field component="textarea" className="form-control" name="description" />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button className="btn btn-primary">
                    {saving ? 'Creating...' : 'Create Page'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </div>
    </FullContent>
  );
};
