import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, FormikActions } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import * as Yup from 'yup';
import { states } from '../../../shared';

export enum VerificationStatus {
  NotSubmitted,
  Submitted,
  Rejected,
  Verified,
}

interface IProps {
  formProps: any;
  verificationStatus: VerificationStatus;
  onSubmit: (values: any) => any;
}

export interface IFormValues {
  businessType: string;
  businessName: string;
  businessTaxId: string;
  businessAddress: {
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zip: string;
  };
  firstName: string;
  lastName: string;
  birthday: {
    month: string;
    day: string;
    year: string;
  };
  ssn: string;
  url: string;
}

export const VerificationDefaultValues: IFormValues = {
  businessType: '',
  businessName: '',
  businessTaxId: '',
  businessAddress: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  firstName: '',
  lastName: '',
  birthday: {
    month: '',
    day: '',
    year: '',
  },
  ssn: '',
  url: '',
};

export const VerificationValidationSchema = Yup.object().shape({
  businessType: Yup.string().required('Business Type is required.'),
  businessName: Yup.string().when(
    'businessType',
    {
      is: 'company',
      then: Yup.string().required('Business Name is required.'),
      otherwise: Yup.string().notRequired(),
    },
  ),
  businessTaxId: Yup.string().when(
    'businessType',
    {
      is: 'company',
      then: Yup.string().required('Business Tax ID is required.'),
      otherwise: Yup.string().notRequired(),
    },
  ),
  businessAddress: Yup.object().shape({
    address1: Yup.string().required('Address Line 1 is required.'),
    address2: Yup.string(),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
    zip: Yup.string().length(5, 'Must be exactly 5 characters.').required('Zip is required'),
  }),
  url: Yup.string().url().required('Business Website is required.'),
  firstName: Yup.string().required('First Name is required.'),
  lastName: Yup.string().required('Last name is required.'),
  birthday: Yup.object().shape({
    month: Yup.number().required('Month is required.').typeError('Month is required.'),
    day: Yup.number().required('Day is required.').typeError('Day is required.'),
    year: Yup.number().required('Year is required.').typeError('Year is required.'),
  }),
  ssn: Yup.string().when(
    'businessType',
    {
      is: 'company',
      then: Yup.string().notRequired(),
      otherwise: Yup.string().length(4, 'Must be exactly 4 characters').required('Last 4 of SSN is required.'),
    },
  ),

});

export class VerificationSettings extends React.Component <IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.submitVerification = this.submitVerification.bind(this);
  }

  status(type: VerificationStatus) {
    let status;
    switch (type) {
      case VerificationStatus.Submitted:
        status = (
            <div className="text-success text-center">
                <h3 ><FontAwesomeIcon icon={faCheckCircle} /></h3>
                <p>Your business verification has been submitted.</p>
            </div>
        );
        break;
      case VerificationStatus.Rejected:
        status = (
          <div className="text-danger text-center">
            <h3 ><FontAwesomeIcon icon={faExclamationTriangle} /></h3>
            <p>We were not able to verify your business. We'll email you with more information.</p>
          </div>
        );
        break;
      case VerificationStatus.Verified:
        status = (
          <div className="text-success text-center">
            <h3 ><FontAwesomeIcon icon={faCheckCircle} /></h3>
            <p>Your business has been verified.</p>
          </div>
        );
        break;
      default:
        status = null;
        break;
    }

    return status;

  }

  formFieldClassName(isTouched: boolean | undefined, error: any) {
    if (isTouched && error) {
      return 'form-group has-error';
    }
    return 'form-group';

  }

  submitVerification(values: IFormValues, actions: FormikActions<IFormValues>) {
    // this.props.onSubmit(values);
  }

  render() {

    // const verificationSettingsSchema = VerificationValidationSchema;

    // const initialValues = { ... VerificationDefaultValues };

    let body;
    switch (this.props.verificationStatus) {
      case VerificationStatus.NotSubmitted:
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        const monthOptions = months.map((month, i) => <option key={i + 1} value={i + 1}>{month}</option>);
        const days = Array(31).fill(1).map((x, i) => 1 + i);
        const dayOptions = days.map(day => <option key={day}>{day}</option>);
        const years = Array((moment().year() - 1899)).fill(1).map((x, i) => 1900 + i).reverse();
        const yearOptions = years.map(year => <option key={year}>{year}</option>);

        const stateOptions = states.map(state =>
          <option key={state.shortName} value={state.shortName}>{state.state}</option>,
        );

        const formProps = this.props.formProps;
        body = (

                <div className="form-horizontal">
                  <div className="row">

                    <div className="col-md-9">
                      <div
                        className={
                          this.formFieldClassName(formProps.touched.businessType, formProps.errors.businessType)
                        }
                        >
                        <label htmlFor="" className="control-label col-sm-4">Your Business Type</label>
                        <div className="col-sm-8">
                          <Field name="businessType" component="select" className="form-control" >
                            <option value="" disabled>
                              Select Business Type
                          </option>
                            <option value="individual">Individual</option>
                            <option value="company">Company</option>
                          </Field>
                          <ErrorMessage name="businessType" />
                        </div>
                      </div>
                      { formProps.values.businessType === 'company' ?
                        <div
                          className={
                            this.formFieldClassName(formProps.touched.businessName, formProps.errors.businessName)
                          }
                        >
                          <label htmlFor="" className="control-label col-sm-4">Business Name</label>
                          <div className="col-sm-8">
                            <Field placeholder="Business Name" name="businessName" type="text" className="form-control" />
                            <ErrorMessage name="businessName" />
                          </div>
                        </div> : null
                      }
                      { formProps.values.businessType === 'company' ?
                        <div
                          className={
                            this.formFieldClassName(formProps.touched.businessTaxId, formProps.errors.businessTaxId)
                          }
                        >
                          <label htmlFor="" className="control-label col-sm-4">Business Tax ID</label>
                          <div className="col-sm-8">
                            <Field name="businessTaxId" placeholder="Business Tax ID" type="text" className="form-control" />
                            <ErrorMessage name="businessTaxId" />
                          </div>
                        </div> : null
                      }
                      <div className="row">
                        <label htmlFor="" className="control-label col-sm-4">Business Address</label>
                        <div className="col-sm-8">
                          <div
                            style={{ marginLeft: 0, marginRight: 0 }}
                            className={
                              this.formFieldClassName(
                                formProps.touched.businessAddress && formProps.touched.businessAddress.address1,
                                formProps.errors.businessAddress && formProps.errors.businessAddress.address1,
                              )
                            }
                          >
                            <Field
                              name="businessAddress.address1"
                              placeholder="Address Line 1"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage name="businessAddress.address1" />
                          </div>
                          <div
                            style={{ marginLeft: 0, marginRight: 0 }}
                            className={
                              this.formFieldClassName(
                                formProps.touched.businessAddress && formProps.touched.businessAddress.address2,
                                formProps.errors.businessAddress && formProps.errors.businessAddress.address2,
                              )
                            }
                          >
                            <Field
                              name="businessAddress.address2"
                              placeholder="Address Line 2"
                              type="text"
                              className="form-control"
                            />
                            <ErrorMessage name="businessAddress.address2" />
                          </div>
                          <div  className="row">
                            <div className="col-lg-4">
                              <div
                                style={{ marginLeft: 0, marginRight: 0 }}
                                className={
                                  this.formFieldClassName(
                                    formProps.touched.businessAddress && formProps.touched.businessAddress.city,
                                    formProps.errors.businessAddress && formProps.errors.businessAddress.city,
                                  )
                                }
                              >
                                <Field name="businessAddress.city" placeholder="City" type="text" className="form-control" />
                                <ErrorMessage name="businessAddress.city" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div
                                style={{ marginLeft: 0, marginRight: 0 }}
                                className={
                                  this.formFieldClassName(
                                    formProps.touched.businessAddress && formProps.touched.businessAddress.state,
                                    formProps.errors.businessAddress && formProps.errors.businessAddress.state,
                                  )
                                }
                              >
                                <Field component="select" name="businessAddress.state" className="form-control">
                                  <option value="" disabled>Select State</option>
                                  {stateOptions}
                                </Field>
                                <ErrorMessage name="businessAddress.state" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div
                                style={{ marginLeft: 0, marginRight: 0 }}
                                className={
                                  this.formFieldClassName(
                                    formProps.touched.businessAddress && formProps.touched.businessAddress.zip,
                                    formProps.errors.businessAddress && formProps.errors.businessAddress.zip,
                                  )
                                }
                              >
                                <Field
                                  name="businessAddress.zip"
                                  placeholder="Zip Code"
                                  type="text"
                                  className="form-control"
                                />
                                <ErrorMessage name="businessAddress.zip" />
                              </div>
                            </div>
                          </div>
                          <p className="text-muted">
                            Your business must be located in the United States.
                        </p>
                        </div>

                      </div>

                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-9">
                      <div className={this.formFieldClassName(formProps.touched.url, formProps.errors.url)}>
                        <label htmlFor="" className="control-label col-sm-4">Business Website</label>
                        <div className="col-sm-8">
                          <Field placeholder="https://example.com" name="url" type="text" className="form-control" />
                          <ErrorMessage name="url" />
                        </div>
                      </div>
                      <div className="row" >
                        <label htmlFor="" className="control-label col-sm-4">Contact Name</label>
                        <div className="col-sm-8">
                          <div className="row">
                            <div className="col-lg-6">
                              <div
                                style={{ marginLeft: 0, marginRight: 0 }}
                                className={this.formFieldClassName(formProps.touched.firstName, formProps.errors.firstName)}
                              >
                                <Field name="firstName" placeholder="First Name" type="text" className="form-control" />
                                <ErrorMessage name="firstName" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                style={{ marginLeft: 0, marginRight: 0 }}
                                className={this.formFieldClassName(formProps.touched.lastName, formProps.errors.lastName)}
                              >
                                <Field name="lastName" placeholder="Last Name" type="text" className="form-control" />
                                <ErrorMessage name="lastName" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div className="row">
                          <label htmlFor="" className="control-label col-sm-4">Birthdate</label>
                          <div className="col-sm-8">
                            <div className="row form-group">
                              <div className="col-sm-4">
                                <div
                                  style={{ marginLeft: 0, marginRight: 0 }}
                                  className={
                                    this.formFieldClassName(
                                      formProps.touched.birthday && formProps.touched.birthday.month,
                                      formProps.errors.birthday && formProps.errors.birthday.month,
                                    )
                                  }
                                >
                                  <Field component="select" className="form-control" name="birthday.month">
                                    <option value="" disabled>Month</option>
                                    {monthOptions}
                                  </Field>

                                  <ErrorMessage name="birthday.month" />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div
                                  style={{ marginLeft: 0, marginRight: 0 }}
                                  className={
                                    this.formFieldClassName(
                                      formProps.touched.birthday && formProps.touched.birthday.day,
                                      formProps.errors.birthday && formProps.errors.birthday.day,
                                    )
                                  }
                                >
                                  <Field component="select" className="form-control" name="birthday.day">
                                    <option value="" disabled>Day</option>
                                    {dayOptions}
                                  </Field>

                                  <ErrorMessage name="birthday.day" />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div
                                  style={{ marginLeft: 0, marginRight: 0 }}
                                  className={
                                    this.formFieldClassName(
                                      formProps.touched.birthday && formProps.touched.birthday.year,
                                      formProps.errors.birthday && formProps.errors.birthday.year,
                                    )
                                  }
                                >
                                  <Field component="select" className="form-control" name="birthday.year">
                                    <option value="" disabled>Year</option>
                                    {yearOptions}
                                  </Field>
                                  <ErrorMessage name="birthday.year" />
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        {
                          formProps.values.businessType === 'individual' ?
                          (
                            <div className={this.formFieldClassName(formProps.touched.ssn, formProps.errors.ssn)}>
                              <label htmlFor="" className="control-label col-sm-4">Last 4 of SSN</label>
                              <div className="col-sm-8">
                                <Field
                                  name="ssn"
                                  style={{ minWidth: 75, maxWidth: 75 }}
                                  placeholder="XXXX"
                                  type="text"
                                  className="form-control"
                                />
                                <ErrorMessage name="ssn" />
                                <p className="text-muted ssn-help ">
                                    We only need the last four digits to verify your identity.
                                  </p>
                              </div>
                            </div>
                          ) : null
                      }

                    </div>

                  </div>

                </div>

              );

        break;
      default:
        body = this.status(this.props.verificationStatus);
        break;
    }

    return (
      <div>
        <div className="row">
          <div className="col-xs-10 col-xs-offset-1">
            <h4 className="text-center text-primary">Verification</h4>
            <p className="text-center">
              Our banking partner requires that we verify your information before we can transfer funds to your account.
              This information is only used for verification purposes.
            </p>
            <br />
          </div>
        </div>

        {body}

      </div>

    );
  }
}
