import { AffinityImage, ImageApiResponse } from './Image';

export interface UserApiResponse {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  image?: ImageApiResponse;
  type: string;
  deleted_at?: string;
  archived_at?: string;
  account_name: string;

}
export default class User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountName: string;
  image: AffinityImage = new AffinityImage();
  type: string;
  isArchived: boolean;
  isDeleted: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.firstName = data.first_name;
      this.lastName = data.last_name;
      this.email = data.email;
      this.phone = data.phone;
      this.accountName = data.account_name;
    }
  }

  static fromApi(data: UserApiResponse) {
    const u = new User();
    u.id = data.id;
    u.firstName = data.first_name;
    u.lastName = data.last_name;
    u.email = data.email;
    u.phone = data.phone;
    if (data.image) {
      u.image = AffinityImage.fromApi(data.image);
    } else {
      u.image.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/user-icon.png';
    }
    u.type = data.type;
    u.isArchived = data.archived_at ? true : false;
    u.isDeleted = data.deleted_at ? true : false;
    u.accountName = data.account_name;

    return u;

  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
