import Axios from 'axios';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MarketplacePostTag } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: MarketplacePostTag[];
  onChange: (users: MarketplacePostTag[]) => void;
}

type Status = LoadableStatuses<MarketplacePostTag[], string>;

export const PostTagTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });

  React.useEffect(() => {
    getTags();

  },              []);

  const getTags = async () => {
    setStatus({ state: 'loading' });
    const t = await Axios.get('/api/marketplace/posts/tags');
    const tags = t.data.data;
    setStatus({ content: tags, state: 'success' });

  };
  const options = status.state === 'success' ? status.content : [];
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      id="tag-typeahead"
      placeholder="Select Tags"
      options={options}
      selected={props.selected}
      onChange={props.onChange}
      multiple={true}
      paginate={false}
      isLoading={isLoading}
      maxResults={options.length}
      minLength={0}
      labelKey={(item: MarketplacePostTag) => item.name}

    />

  );

};
