import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface IProps {
  title: string;
  subitle?: string;
  action?: React.ReactNode;
  icon: IconProp;
  children: React.ReactNode;
}

export const SettingsPanel = (props: IProps) => {
  return (
    <div className="panel panel-portal settings-panel">
      <div style={{ padding: 10 }} className="panel-heading">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', paddingRight: 20 }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <div style={{ paddingLeft: 10 }}>
              <FontAwesomeIcon className="text-muted" icon={props.icon} />
            </div>
            <div style={{ marginLeft: 10 }}>
              <h5 className="no-margin"><strong>{props.title}</strong></h5>
              <p className="text-muted small no-margin">{props.subitle}</p>
            </div>
          </div>
          {props.action}

        </div>
      </div>
      <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20, paddingBottom: 20 }} className="panel-body">
        {props.children}
      </div>
    </div>
  );
};
