import Axios from 'axios';
import * as queryString from 'query-string';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AffinityImage, Design } from '../../shared';
import { RoyaltiesTab } from '../Designs';
import { GatewayModal, LoadingSpinner, ModalType } from '../shared';
import { TopSellingDesignGridItem } from '../shared/grid/TopSellingDesignGridItem';
import { usePerformanceQueryParams } from './usePerformanceQueryParams';

interface TopSellingDesignRowProps {
  accountId: number | null;
  hideIfNoResults?: boolean;
}

const backupImage = new AffinityImage();
backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';

const TopSellingDesignRow: React.FC<TopSellingDesignRowProps> = ({ accountId, hideIfNoResults = false }) => {
  const [topSellingDesigns, setTopSellingDesigns] = React.useState<Design[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [salesModalShown, setSalesModalShown] = React.useState(false);
  const [salesDesignId, setSalesDesignId] = React.useState(0);
  const [salesDesign, setSalesDesign] = React.useState<Design>(new Design());
  const [showViewMore, setShowViewMore] = React.useState<boolean>(false);
  const performanceQP = usePerformanceQueryParams();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const routeParams = useParams();

  React.useEffect(() => {
    getTopSellingDesigns();
  },              [location.search]);

  const createTopSellingLink = (params: string) => {
    if (routeParams['vendorId']) {
      return `/vendors/${routeParams['vendorId']}/performance/designs/top-selling?${params}`;
    }
    if (routeParams['licensorId']) {
      return `/clients/${routeParams['licensorId']}/performance/designs/top-selling?${params}`;
    }
    return `/performance/designs/top-selling?${params}`;
  };

  const getTopSellingDesigns = async () => {
    setLoading(true);
    const qs = performanceQP.toTopSellingDesignsApi([
      { label: 'limit', value: 4 },
      { label: 'sort', value: query.sort ? query.sort : 'royalties' },
    ]);
    const response = await Axios.get(`/api/performance-reports/designs/top-selling?${qs}`);
    const topDesigns = response.data.data.data.map((design: any) => Design.fromApi(design));
    setTopSellingDesigns(topDesigns);
    setShowViewMore(topDesigns.length >= 4);
    setLoading(false);
  };

  const openSalesModal = (id: number) => {
    setSalesDesignId(id);
    const selectedDesign = topSellingDesigns.find(d => d.id === Number(id));
    if (selectedDesign) {
      setSalesDesign(selectedDesign);
    }
    setSalesModalShown(true);
  };

  const queryParams = queryString.stringify(query);

  if (hideIfNoResults && topSellingDesigns.length === 0) {
    return null;
  }

  return (
    <div className="col-md-12">
      {loading ? <LoadingSpinner /> : (
        <>
          {topSellingDesigns.length === 0 ? (
            <em>No Designs Found</em>
          ) : (
            <>
              <div style={{ marginTop: 10, marginBottom: 10, fontSize: 15 }}>
                <strong>Top Selling</strong>
              </div>
              <div className="row">
                {topSellingDesigns.map((design: Design, index) => (
                  <div key={design.id} className="col-xl-3 col-lg-3 col-md-3 col-sm-3">
                    <TopSellingDesignGridItem
                      url={`/designs/${design.id}`}
                      image={design.primaryIteration && design.primaryIteration.image ? design.primaryIteration.image.getSize('sm') : backupImage.getSize('sm')}
                      vendorImage={design.vendor && design.vendor.image ? design.vendor.image.getSize('sm') : backupImage.getSize('sm')}
                      netSales={design.netSales ? design.netSales : 0}
                      royaltySales={design.royaltySales ? design.royaltySales : 0}
                      quantity={design.quantity ? design.quantity : 0}
                      design={design}
                      salesOnClick={openSalesModal}
                    />
                  </div>
                ))}
              </div>
              <div>
                {  showViewMore ?
                  <div className="text-right" style={{ marginBottom: 20 }}>
                    <Link target="_blank"  to={createTopSellingLink(queryParams)} ><strong>View More</strong></Link>
                  </div>
                  : null
                }

              </div>
            </>
          )}
          <GatewayModal
            type={ModalType.Primary}
            shown={salesModalShown}
            onClose={() => setSalesModalShown(false)}
            title={'Sales Data'}
            size={'large'}
          >
            <Modal.Body>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                flexDirection: 'column',
              }}>
                <h4 className="text-center">{salesDesign && salesDesign.vendor ? salesDesign.vendor.shortName : null}</h4>
                {salesDesign && salesDesign.vendor && salesDesign.vendor.image ?
                  <img style={{ minHeight: 50, maxHeight: 50 }} className="img-responsive"
                       src={salesDesign.vendor.image.getSize('sm')} /> :
                  <img style={{ minHeight: 50, maxHeight: 50 }} className="img-responsive" src={backupImage.getSize('sm')} />}
              </div>
              <RoyaltiesTab designId={salesDesignId} clientId={accountId} />
            </Modal.Body>
            <Modal.Footer>
              <button onClick={() => setSalesModalShown(false)} className="btn btn-default">
                Close
              </button>
            </Modal.Footer>
          </GatewayModal>
        </>
      )}
    </div>
  );
};

export default TopSellingDesignRow;
