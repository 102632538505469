import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import * as queryString from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { getMarketplaceOrders, getMarketplaceOrdersParams } from '../../api';
import { Filters, getInitialFilters } from '../../shared';
import { MarketplaceOrder } from '../../shared/MarketplaceOrder';
import { LineItemStatus } from '../../shared/MarketplaceOrderLineItem';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CurrencyLabel, FilterBar, LoadingSpinner, PaginationFooter, SortableHeader } from '../shared';

interface IState {
  // TODO: Orders type
  orders: any[];
  filters: Filters;
  totalResults: number;
  lastPage: number;
  loading: boolean;
}

class OrderIndexPage extends React.Component<any, IState> {

  protected _getOrdersSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    const filters = getInitialFilters(this.props.location.search, 'created');

    if (props.match.params['vendorId']) {
      filters.vendorId = props.match.params['vendorId'];
    }
    this.state = {
      filters,
      orders: [],
      totalResults: 0,
      lastPage: 1,
      loading: false,
    };

    this.getOrders = this.getOrders.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.setPage = this.setPage.bind(this);
    this.sort = this.sort.bind(this);
    this.selectExport = this.selectExport.bind(this);
  }

  componentDidMount() {
    this.getOrders();
  }

  componentWillUnmount() {
    if (this._getOrdersSource) {
      this._getOrdersSource.cancel('Cancelled getOrders() XHR due to unmount');
    }
  }

  getOrders() {
    this.setState({ orders: [], loading: true });
    if (this._getOrdersSource) {
      this._getOrdersSource.cancel('Cancelled getOrders() XHR due to new request');
    }
    this._getOrdersSource = Axios.CancelToken.source();
    getMarketplaceOrders(this.state.filters, this._getOrdersSource)
      .then((response) => {
        this.setState({
          orders: response.orders,
          totalResults: response.total,
          lastPage: response.totalPages,
          loading: false,
        });
      });

  }

  sort() {

  }

  updateFilters(filters: any, performSearch: boolean) {
    const updatedFilters = this.state.filters;
    if (performSearch && !filters.page) {
      filters.page = 1;
    }
    updatedFilters.setFilters(filters);
    this.setState({ filters: updatedFilters });

    if (performSearch) {
      const baseUrl = this.props.location.pathname;
      this.props.history.replace(`${baseUrl}?${queryString.stringify(updatedFilters.getQueryParams())}`);
      this.getOrders();
    }
  }

  setPage(page: number) {
    this.updateFilters({ page }, true);
  }

  setStatus(status: number) {
    this.updateFilters({ status }, true);
  }

  selectExport(eventKey: any): void {

    const allowedKeys = [
      'order_fulfillment',
      'royalty_report',
      'state_tax',
      'payouts',
    ];

    if (allowedKeys.indexOf(eventKey) !== -1) {
      const path = '/api/marketplace/marketplaceOrders/csv';
      const filters = Object.assign(
        {
          export_type: eventKey,
        },
        getMarketplaceOrdersParams(this.state.filters),
      );
      const queryStr = Object.keys(filters)
        .filter(x => x)
        .filter(x => filters[x])
        .map((key) => {
          return `${key}=${filters[key]}`;
        })
        .join('&');
      window.open(`${path}?${queryStr}`, '_blank');
    }
  }

  render() {

    const rows = this.state.orders.map((order: MarketplaceOrder) => {

      const totalPrice = order.lineItems.reduce(
        (total, order) => {
          return total + (parseFloat(order.price) + parseFloat(order.shippingPrice));
        },
        0.0);

      const totalTaxes = order.taxesTotal;

      const total = totalPrice + totalTaxes;

      const status = order.lineItems
        .map(li => li.status)
        .reduce(
          (acc, value) => {
            if (value === LineItemStatus.Pending || acc === LineItemStatus.Pending) {
              return LineItemStatus.Pending;
            }
            return value;
          },
          '',
        );

      return (
        <tr key={order.id}>
          <td><Link to={`orders/${order.id}`}>{ order.id }</Link></td>
          <td>{ order.createdAt.format('YYYY-MM-DD') }</td>
          <td><CurrencyLabel value={total}/></td>
          <td>{ order.customer.name }</td>
          <td >
            <div style={{ display: 'flex', alignItems: 'center' }}>

              <span style={{ flexBasis: 70 }}>
                {status}
              </span>
              {order.hasShopify ?
                <FontAwesomeIcon
                  icon={faShopify}
                  className={order.isShopifyFulfilled ? 'text-success' : 'text-muted'}
                  style={{ fontSize: 28, marginLeft: 20 }}
                />
                : null
              }

            </div>
          </td>
        </tr>
      );
    });

    let tableContent;
    if (this.state.loading) {
      tableContent = <LoadingSpinner /> ;

    } else {
      tableContent =
        <div className="table-responsive">
          <table className="table table-portal table-hover">
            <thead>
              <tr>
                <SortableHeader
                  title="Order ID"
                  disableSort={true}
                  sortSelectFn={this.sort}
                  sortDir="desc"
                  sortKey="id"
                />
                <SortableHeader
                  title="Order Date"
                  disableSort={true}
                  sortSelectFn={this.sort}
                  sortDir="desc"
                  sortKey="account"
                />
                <SortableHeader
                  title="Amount"
                  disableSort={true}
                  sortSelectFn={this.sort}
                  sortDir="desc"
                  sortKey="amount"
                />
                <SortableHeader
                  title="Customer Name"
                  disableSort={true}
                  sortSelectFn={this.sort}
                  sortDir="desc"
                  sortKey="name"
                />
                <SortableHeader
                  title="Status"
                  disableSort={true}
                  sortSelectFn={this.sort}
                  sortDir="desc"
                  sortKey="status"
                />
              </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>

          </table>

        </div>;

    }
    return <FullContent breadcrumbs={[{ name: 'Marketplace Orders' }]}>
      <DocumentTitle title={getAffinityPageTitle('Marketplace Orders')}>
        <div>
          <div style={{ paddingTop: 20, paddingBottom: 20 }}>
            <FilterBar
              updateFilters={this.updateFilters}
              usePerPage={true}
              useQuarter={false}
              useSearch={true}
              useDates={true}
              startDate={this.state.filters.startDate}
              endDate={this.state.filters.endDate}
              search={this.state.filters.search}
              perPage={this.state.filters.perPage}
              fieldClassOverrides={{
                search: 'col-lg-2 col-sm-2 col-xs-12',
              }}
            >
              <div className="col-lg-2 col-sm-2 col-xs-12">
                <select
                  onChange={event => this.setStatus(Number(event.target.value))}
                  value={this.state.filters.status}
                  className="form-control input-sm"
                >
                  <option value={0}>Status Filter (All)</option>
                  <option value={1}>Pending</option>
                  <option value={2}>Shipped</option>
                  <option value={3}>Refunded</option>
                </select>
              </div>
              <div className="col-lg-2 col-sm-2 col-xs-12">
                <DropdownButton
                  onSelect={this.selectExport}
                  className="btn-sm"
                  id="order-export-dropdown"
                  bsStyle={'default'}
                  title={'Export '}
                >
                  <MenuItem eventKey="order_fulfillment">Fulfillment Export</MenuItem>
                  <MenuItem eventKey="payouts">Payout Export</MenuItem>
                  <MenuItem eventKey="royalty_report">Royalty Report Export</MenuItem>
                  <MenuItem eventKey="state_tax">State Tax Export</MenuItem>
                </DropdownButton>
              </div>
            </FilterBar>
          </div>
          <div className="panel panel-portal">

            {tableContent}

          </div>
          <PaginationFooter
            setPage={this.setPage}
            currentPage={this.state.filters.page}
            totalResults={this.state.totalResults}
            hasNext={this.state.lastPage > this.state.filters.page} />
        </div>

      </DocumentTitle>
    </FullContent>;
  }
}

export { OrderIndexPage as OrderIndexPage };
