import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export type SortDirection = 'asc' | 'desc' | null;

interface ISortableHeaderProps {
  sortSelectFn: (sortKey: string, sortDirection: SortDirection) => void;
  title: string;
  sortDir: SortDirection;
  sortKey: string;
  disableSort?: boolean;
}

export const SortableHeader = (props: ISortableHeaderProps) => {

  const getNextSortDirection = (currentDir: SortDirection) : SortDirection => {
    return currentDir === 'asc' ? 'desc' : 'asc';
  };

  const sortSelectFn = (): void => {
    props.sortSelectFn(props.sortKey, getNextSortDirection(props.sortDir));
  };

  let sortIcon = <FontAwesomeIcon icon={faSort} />;

  if (props.sortDir === 'asc') {
    sortIcon = <FontAwesomeIcon icon={faSortUp} />;
  } else if (props.sortDir === 'desc') {
    sortIcon = <FontAwesomeIcon icon={faSortDown} />;
  }

  if (props.disableSort) {
    sortIcon = <></>;
  }

  return (
    <th>
      <span> {props.title}</span>
      <button className="table-sort-portal text-center pull-right" onClick={sortSelectFn}>
        {sortIcon}
      </button>
    </th>
  );
};
