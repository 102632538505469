import { faPencil, faSort, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MarketplaceSitePage } from '../../../shared/MarketplaceSitePage';
import { MarketplaceSitePageItem } from '../../../shared/MarketplaceSitePageItem';
import { FullContent } from '../../ContentFrame';
import { AffinityDropzone } from '../../Navigation';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { getSitePage } from './api';
import { SitePageHTMLForm, SitePageImageForm, SitePageImageGridForm, SitePagePostsForm, SitePageProductsForm, SitePageTextForm, SitePageVendorsForm } from './page-item-forms';

type IProps = RouteComponentProps<{
  siteId?: string;
  pageId: string;
}>;

interface IState {
  loading: boolean;
  page: MarketplaceSitePage | null;
  modifingItem: MarketplaceSitePageItem | null;
  editModalShown: boolean;
  createModalShown: boolean;
  savingItem: boolean;
  savingPage: boolean;
  creatingItem: boolean;
  creationType: string;
  uploadingImage: boolean;
  images: string[];
}

interface SortablePageItemProps  {
  item: MarketplaceSitePageItem;
  onDelete: (id: string) => void;
  onEdit: (item: MarketplaceSitePageItem) => void;
}
interface DragHandleProps {
  title: string;
}

const DragHandle = SortableHandle((props: DragHandleProps) => (
  <span>
    <FontAwesomeIcon style={{ marginRight: 10 }} icon={faSort} />
    {props.title}

  </span>
));

const SortablePageItem = SortableElement((props: SortablePageItemProps) => (
  <div style={{ padding: 20 }} className="row page-item-row" key={props.item.id}>
    <div className="col-xs-9">
      <DragHandle title={props.item.typeToTitle()} />

    </div>
    <div className="col-xs-3 text-right">
      <FontAwesomeIcon
        style={{ cursor: 'pointer' }}
        onClick={() => props.onEdit(props.item)}
        icon={faPencil}
      />
      <FontAwesomeIcon
        style={{ marginLeft: 15, cursor: 'pointer' }}
        onClick={() => props.onDelete(props.item.id)}
        icon={faTrash}
      />
    </div>

  </div>
));

interface SortablePageItemListProps {
  items: MarketplaceSitePageItem[];
  onDelete: (id: string) => void;
  onEdit: (item: MarketplaceSitePageItem) => void;
}

const SortablePageItemList = SortableContainer((props: SortablePageItemListProps) => (
  <div className="page-item-row-container">
    {props.items.map((v, index) => (
      <SortablePageItem key={v.id} item={v} index={index} onDelete={props.onDelete} onEdit={props.onEdit} />
    ))}
  </div>
));

export class SitePagePage extends React.Component <IProps, IState> {

  private _getPageSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      page: null,
      modifingItem: null,
      editModalShown: false,
      savingItem: false,
      savingPage: false,
      creatingItem: false,
      createModalShown: false,
      creationType: '',
      uploadingImage: false,
      images: [],
    };

    this.getPage = this.getPage.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.hideEditModal = this.hideEditModal.bind(this);
    this.setEditng = this.setEditng.bind(this);
    this.modalForm = this.modalForm.bind(this);
    this.savePageDetails = this.savePageDetails.bind(this);
    this.savePageItemChanges = this.savePageItemChanges.bind(this);
    this.showCreateModal = this.showCreateModal.bind(this);
    this.hideCreateModal = this.hideCreateModal.bind(this);
    this.createPageItem = this.createPageItem.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.deletePageItem = this.deletePageItem.bind(this);
  }

  componentDidMount() {
    this.refreshPage();
  }

  refreshPage() {
    this.getPage(this.props.match.params.pageId);
  }

  getPage(pageId: string) {
    this.setState({ page: null, loading: true });
    if (this._getPageSource) {
      this._getPageSource.cancel('Cancelled getPages() XHR due to new request.');
    }
    this._getPageSource = Axios.CancelToken.source();

    getSitePage(pageId, this._getPageSource)
      .then((page) => {
        this.setState({ page, loading: false });
      });
  }

  async savePageDetails(values: any) {
    if (this.state.page) {
      this.setState({ savingPage: true });
      const page = await Axios.post(`/api/marketplace/sites/pages/${this.state.page.id}`, values);
      console.log(page);
      this.setState({ savingPage: false });
    }

  }

  showEditModal() {
    this.setState({ editModalShown: true });
  }

  hideEditModal() {
    this.setState({ editModalShown: false });
  }

  showCreateModal() {
    this.setState({ createModalShown: true });

  }

  hideCreateModal() {
    this.setState({ createModalShown: false });
  }

  setEditng(item: MarketplaceSitePageItem) {
    this.setState({ modifingItem: item });
    this.showEditModal();
  }

  async onDrag(oldIndex: number, newIndex: number) {
    console.log(oldIndex, newIndex);
    const page = this.state.page;
    if (page) {
      page.pageItems = arrayMove(page.pageItems, oldIndex, newIndex);
      this.setState({ page });
      const data = {
        sorting: page.pageItems.map((item, index) => {
          return {
            id: item.id,
            position: index,
          };
        }),
      };

      const update = await Axios.post(`/api/marketplace/sites/pages/${page.id}`, data);
      console.log(update);
    }

  }

  newItemModalForm(type: string) {
    switch (type) {
      case 'image':
        return (<SitePageImageForm onSubmit={v => this.createPageItem(v, type)} />);
      case 'text':
        return (<SitePageTextForm onSubmit={v => this.createPageItem(v, type)} />);
      case 'html':
        return (<SitePageHTMLForm onSubmit={v => this.createPageItem(v, type)} />);
      case 'products':
        return (<SitePageProductsForm onSubmit={v => this.createPageItem(v, type)} />);
      case 'vendors':
        return (<SitePageVendorsForm onSubmit={v => this.createPageItem(v, type)} />);
      case 'gallery':
        return (<SitePageProductsForm onSubmit={v => this.createPageItem(v, type)} />);
      case 'image_grid':
        return (<SitePageImageGridForm onSubmit={v => this.createPageItem(v, type)} />);
      case 'posts':
        return (<SitePagePostsForm onSubmit={v => this.createPageItem(v, type)} />);
      default:
        return null;
        break;
    }

  }

  modalForm() {
    if (this.state.modifingItem) {
      const item = this.state.modifingItem;
      switch (item.type) {
        case 'image':
          const imageValues = JSON.parse(item.params);
          return (<SitePageImageForm initialValues={imageValues}  onSubmit={this.savePageItemChanges} />);
        case 'text':
          const textValues = JSON.parse(item.params);
          return (<SitePageTextForm initialValues={textValues}  onSubmit={this.savePageItemChanges} />);
        case 'html':
          const htmlValues = JSON.parse(item.params);
          return (<SitePageHTMLForm initialValues={htmlValues} onSubmit={this.savePageItemChanges} />);
        case 'products':
          const parsedProductValues = JSON.parse(item.params);

          const initialProductValues = {
            singlePage: parsedProductValues.is_single_page ? 1 : 0,
            sidebar: parsedProductValues.has_sidebar ? 1 : 0,
            keyword: parsedProductValues.keyword,
            clients: parsedProductValues.client_ids ? parsedProductValues.client_ids : [],
            vendors: parsedProductValues.vendor_ids ? parsedProductValues.vendor_ids : [],
            tags: parsedProductValues.tag_ids ? parsedProductValues.tag_ids : [],
            categories: parsedProductValues.product_category_ids ? parsedProductValues.product_category_ids : [],
            insigniaIds: parsedProductValues.insignia_ids ? parsedProductValues.insignia_ids : [],
            insigniaIdString: parsedProductValues.insignia_ids ? parsedProductValues.insignia_ids.join(',') : [],
            priceMax: parsedProductValues.price_max ? parsedProductValues.price_max : '',
            priceMin: parsedProductValues.price_min ? parsedProductValues.price_min : '',
            limit: parsedProductValues.limit ? parsedProductValues.limit : '',
          };
          return (<SitePageProductsForm initialValues={initialProductValues}  onSubmit={this.savePageItemChanges} />);
        case 'vendors':
          const parsedVendorValues = JSON.parse(item.params);
          const initialVendorValues = {
            singlePage: parsedVendorValues.is_single_page ? 1 : 0,
            sidebar: parsedVendorValues.has_sidebar ? 1 : 0,
            keyword: parsedVendorValues.keyword,
            clients: parsedVendorValues.client_ids ? parsedVendorValues.client_ids : [],
            vendors: parsedVendorValues.vendor_ids ? parsedVendorValues.vendor_ids : [],
            limit: parsedVendorValues.limit ? parsedVendorValues.limit : '',
          };
          return (<SitePageVendorsForm initialValues={initialVendorValues} onSubmit={this.savePageItemChanges} />);
        case 'gallery':
          const parsedGalleryValues = JSON.parse(item.params);

          const initialGalleryValues = {
            singlePage: parsedGalleryValues.is_single_page ? 1 : 0,
            sidebar: parsedGalleryValues.has_sidebar ? 1 : 0,
            keyword: parsedGalleryValues.keyword,
            clients: parsedGalleryValues.client_ids ? parsedGalleryValues.client_ids : [],
            vendors: parsedGalleryValues.vendor_ids ? parsedGalleryValues.vendor_ids : [],
            tags: parsedGalleryValues.tag_ids ? parsedGalleryValues.tag_ids : [],
            categories: parsedGalleryValues.product_category_ids ? parsedGalleryValues.product_category_ids : [],
            insigniaIds: parsedGalleryValues.insignia_ids ? parsedGalleryValues.insignia_ids : [],
            priceMax: parsedGalleryValues.price_max ? parsedGalleryValues.price_max : '',
            priceMin: parsedGalleryValues.price_min ? parsedGalleryValues.price_min : '',
            limit: parsedGalleryValues.limit ? parsedGalleryValues.limit : '',
          };
          return (<SitePageProductsForm initialValues={initialGalleryValues}  onSubmit={this.savePageItemChanges} />);
        case 'image_grid':
          const parsedGridValues = JSON.parse(item.params);
          const initialGridValues = {
            items: parsedGridValues.items ? parsedGridValues.items.map((item: any) => {
              return {
                column: item.column_size,
                image: item.image_url,
                buttonText: item.button_text,
                link: item.link,
                internalLink: item.internal_link ? 1 : 0,
              };

            }) : [],
          };
          return (<SitePageImageGridForm initialValues={initialGridValues} onSubmit={this.savePageItemChanges} />);
        case 'posts':
          const parsedPostValues = JSON.parse(item.params);
          const initialPostValues = {
            keyword: parsedPostValues.keyword,
            clients: parsedPostValues.licensor_ids ? parsedPostValues.licensor_ids : [],
            tags: parsedPostValues.tag_ids ? parsedPostValues.tag_ids : [],
          };
          return (<SitePagePostsForm initialValues={initialPostValues}  onSubmit={this.savePageItemChanges}/>);
        default:
          return (<LoadingSpinner />);
          break;
      }
    }
    return (<LoadingSpinner />);

  }

  async savePageItemChanges(params: string) {

    if (this.state.modifingItem && this.state.page) {
      this.setState({ savingItem: true });
      const data = {
        parameters: params,
        type: this.state.modifingItem.type,
      };
      const update = await Axios.put(`/api/marketplace/sites/pages/items/${this.state.modifingItem.id}`, data);
      const item = MarketplaceSitePageItem.fromApi(update.data.data);
      const page = this.state.page;
      const index = page.pageItems.findIndex(i => i.id === item.id);
      if (index !== -1) {
        page.pageItems[index] = item;

      }
      this.setState({ page, savingItem: false });
      this.hideEditModal();

    }

  }

  async createPageItem(params: string, type: string) {
    console.log(params, type);
    if (this.state.page) {
      this.setState({ creatingItem: true });
      const data = {
        type,
        parameters: params,
        page_id: this.state.page.id,
      };
      const create = await Axios.post('/api/marketplace/sites/pages/items', data);
      const item = MarketplaceSitePageItem.fromApi(create.data.data);
      const page = this.state.page;
      page.pageItems.push(item);

      this.setState({ page, creatingItem: false });
      this.hideCreateModal();
    }

  }

  async deletePageItem(id: string) {
    const r = confirm('Are you sure you want to delete?');
    if (r && this.state.page) {
      const page = this.state.page;
      const d = await Axios.delete(`/api/marketplace/sites/pages/items/${id}`);
      if (d) {
        const index = page.pageItems.findIndex(i => i.id === id);
        if (index > -1) {
          page.pageItems.splice(index, 1);
        }
        this.setState({ page });
      }
    }
  }

  async uploadImage(files: File[]) {
    if (!this.state.uploadingImage) {

      this.setState({ uploadingImage: true });
      const file = files[0];
      const site = this.props.match.params['siteId'];
      const formData = new FormData();
      formData.append('image', file);
      const upload = await Axios.post(`/api/marketplace/sites/${site}/images`, formData);
      const images = [... this.state.images];
      images.push(upload.data.data);
      this.setState({ images, uploadingImage: false });
    }

  }

  render() {

    if (this.state.loading || this.state.page === null) {
      return <LoadingSpinner />;
    }

    const page = this.state.page;

    const pageFormValues = {
      title: page.title,
      path: page.path,
      description: page.description,
    };

    const imageList = this.state.images.map(i => (
      <a target="_blank" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} key={i} href={i}>{i}</a>
    ));

    const imageUploadSection = (
      <div>
        <h4><strong>Image Uploads</strong></h4>
        <p>If you need to upload an image for this item type you can drag a file here.
          The link will be displayed below to copy.
        </p>
        <AffinityDropzone
          onDropAccepted={this.uploadImage}
          accept="image/*"
        >
          <div>
            {this.state.uploadingImage ? 'Uploading Image...' : 'Drag Image Here'}
          </div>

        </AffinityDropzone>
        <div style={{ overflow: 'hidden', width: '100%', textOverflow: 'ellipsis', marginTop: 20, display: 'flex', flexDirection: 'column', gap: 10 }}>
          {imageList}
          {this.state.images.length ? <button onClick={() => this.setState({ images: [] }) } className="btn btn-default">Clear Image list</button> : null}
        </div>
        <hr />
      </div>
      );

    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body" >
            <h1>{page.title} ({page.path})</h1>
            <hr />

            <Formik initialValues={pageFormValues} onSubmit={this.savePageDetails}>
              {(props: FormikProps<any>) => (
                <Form>
                  <div className="form-group">
                    <label>Name</label>
                    <Field className="form-control" name="title" />
                  </div>
                  <div className="form-group">
                    <label>Path</label>
                    <div className="input-group">
                      <span className="input-group-addon">/</span>
                      <Field className="form-control" name="path" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <Field component="textarea" className="form-control" name="description" />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button className="btn btn-primary">
                      {this.state.savingPage ? 'Saving...' : 'Save Changes'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <hr />
            <h3>Page Items</h3>

            <SortablePageItemList
              items={page.pageItems}
              onEdit={this.setEditng}
              onDelete={this.deletePageItem}
              useDragHandle
              onSortEnd={({ oldIndex, newIndex }) => this.onDrag(oldIndex, newIndex)}
            />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button onClick={this.showCreateModal} className="btn btn-primary">Add Section</button>
            </div>

          </div>
          <GatewayModal
            onClose={this.hideEditModal}
            title="Edit Item"
            shown={this.state.editModalShown}
            type={ModalType.Primary}
          >
            <Modal.Body>
              {this.state.modifingItem && (this.state.modifingItem.type === 'image' || this.state.modifingItem.type === 'image_grid') ? imageUploadSection : null }
              {this.modalForm()}

            </Modal.Body>
            {/* <Modal.Footer>
              <button onClick={this.hideEditModal} className="btn btn-primary pull-right">Done</button>
            </Modal.Footer> */}

          </GatewayModal>

          <GatewayModal
            onClose={this.hideCreateModal}
            title="Create Item"
            shown={this.state.createModalShown}
            type={ModalType.Primary}
          >
            <Modal.Body>
              <div className="form-group">
                <label>Select Item Type</label>
                <select onChange={e => this.setState({ creationType: e.target.value })} className="form-control">
                  <option selected disabled>Select Type</option>
                  <option value="image">Image</option>
                  <option value="text">Text</option>
                  <option value="html">HTML</option>
                  <option value="products">Product Grid</option>
                  <option value="gallery">Gallery Grid</option>
                  <option value="vendors">Vendor Grid</option>
                  <option value="image_grid">Custom Image Grid</option>
                  <option value="posts">Posts Grid</option>
                </select>
              </div>

              <hr />

              {this.state.creationType === 'image' || this.state.creationType === 'image_grid' ? imageUploadSection : null}
              {this.newItemModalForm(this.state.creationType)}

            </Modal.Body>
            {/* <Modal.Footer>
              <button onClick={this.hideCreateModal} className="btn btn-primary pull-right">Done</button>
            </Modal.Footer> */}

          </GatewayModal>
        </div>

      </FullContent>
    );
  }
}
