import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { FullContent } from '../../ContentFrame';
import { LicensorTypeahead, LoadingSpinner, TrademarkTypeahead, VendorTypeahead } from '../../shared';
import { submitUrlsToScrape } from './api';
import { MassUploadButton } from './MassUploadButton';

export const UsesNewPage = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const history = useHistory();
  const match = useRouteMatch<{vendorId?: string, clientId?: string, caseId?:string}>();
  let formik: Formik<any> | null;

  if (submitting) {
    return <FullContent>
      <LoadingSpinner />
    </FullContent>;
  }

  const submitUrl = async (values: any) => {
    setSubmitting(true);
    if (match) {
      const urlsToScrape = values.urls.map((url: any) => ({
        url: url.url,
        vendorId: url.vendor && url.vendor.length ? url.vendor[0].id : match.params.vendorId,
        clientId: url.client && url.client.length ? url.client[0].id : match.params.clientId,
        trademarkIds: url.trademark.length ? [url.trademark[0].id] : [],
        clientIds: url.client && url.client.length ? [url.client[0].id] : [match.params.clientId],
      })).map((u: any) => omitBy(u, isNil));
      console.log(urlsToScrape);

      const u = await submitUrlsToScrape(urlsToScrape, Axios.CancelToken.source());
      console.log(u);

      if (u !== 'Duplicate URL') {
        let route = '';
        if (history.location.pathname.includes('vendor')) {
          if (match.params.caseId) {
            route = `/vendors/${match.params.vendorId}/enforce/cases/${match.params.caseId}`;
          } else {
            route = `/vendors/${match.params.vendorId}/enforce`;
          }
        } else {
          route = '/enforce';
        }
        history.push(route);
      } else {
        setErrorMessage('One or more of the submitted URLS is a duplicate');
        setSubmitting(false);
      }

    }
  };

  const setUrls = (urls: string[]) => {
    const u = urls.map((u: string) => {
      return { url: u, client: [], vendor: [], trademark: [] };
    });
    if (formik) {
      formik.resetForm({ urls: u });
    }

  };

  const urls = [{ url: '',  client: [], vendor: [], trademark: [] }];
  const validation = Yup.object({
    urls: Yup.array().of(
      Yup.object({
        url: Yup.string().url('Must be a valid URL.').required('URL is required'),
      }),
    ).min(1),
  });

  return (
    <FullContent>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginRight: 10,
        marginBottom: 20,
      }}>
        <h3 className="no-margin"><strong>Add Trademark Uses</strong></h3>
        <div>
          <MassUploadButton onTextSubmit={setUrls}  statusUpdateFn={console.log} />
        </div>
      </div>
      <div className="panel panel-portal">
        <Formik validationSchema={validation} ref={r => formik = r} initialValues={{ urls }} onSubmit={submitUrl}>
          {formProps => (
            <Form>
              {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : null}
              <FieldArray name="urls" render={helpers => (
                <div className="">
                  {formProps.values.urls.map((u: any, index: number) => (
                    <div className="panel-body">
                      {index > 0 ?  <hr />  :null }
                      <div style={{ paddingTop: 10 }} key={index} className="row">
                        <div className="col-md-3">

                          <Field
                            rows={3} component="textarea" className="form-control" name={`urls.${index}.url`} placeholder="Content URL" />

                            <p className="text-danger">
                              <ErrorMessage name={`urls.${index}.url`} />
                            </p>
                        </div>
                        <div className="col-md-3">
                          {(match && !match.params.vendorId) ? (
                            <div style={{ marginBottom: 10 }}>
                              <VendorTypeahead
                                selected={formProps.values.urls[index].vendor}
                                onChange={v => formProps.setFieldValue(`urls.${index}.vendor`, v)}
                                multiple={false}
                              />
                            </div>
                          ) : null}
                          {(!match || !match.params.clientId) ? (
                            <LicensorTypeahead
                              selected={formProps.values.urls[index].client}
                              onChange={v => formProps.setFieldValue(`urls.${index}.client`, v)}
                              multiple={false}
                            />
                          ) : null}
                        </div>
                        <div className="col-md-5">
                          <TrademarkTypeahead
                            selected={formProps.values.urls[index].trademark}
                            onChange={v => formProps.setFieldValue(`urls.${index}.trademark`, v)}
                            multiple={false}
                            accountId={formProps.values.urls[index].client[0] && formProps.values.urls[index].client[0].id}

                          />
                        </div>
                        <div className="col-md-1">
                          {formProps.values.urls.length > 1 ?
                            <FontAwesomeIcon
                              className="text-danger"
                              onClick={() => helpers.remove(index)}
                            icon={faTimes} />
                           : null}
                        </div>

                      </div>
                    </div>

                  ))}
                  <div className="panel-footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
                    <button
                      type="button"
                      onClick={() => helpers.push({ url: '', client: [], trademark: [] })}
                      className="btn btn-primary"
                    >
                      <FontAwesomeIcon icon={faPlus} /> Add URL
                    </button>
                    <button className="btn btn-primary">Submit</button>
                  </div>

                </div>
              )}

              />

            </Form>
          )}
        </Formik>
      </div>

    </FullContent>
  );

};
