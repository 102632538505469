import Axios from 'axios';
import { Form, Formik, FormikActions, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { BrandCategoryType, BrandMarkAsset } from '../../shared';
import { GatewayModal,  ModalType } from '../shared/modals';
import { ColorTypeForm } from './ColorTypeForm';
import { FontTypeForm } from './FontTypeForm';
import { ImageTypeForm } from './ImageTypeForm';
import { SharedFormFields } from './SharedFormFields';
import { TextTypeForm } from './TextTypeForm';

interface IProps {
  shown: boolean;
  onClose: () => any;
  onSuccess?: (asset: BrandMarkAsset) => any;
  title: string;
  asset?: BrandMarkAsset | null;
  category?: any;
  categories: any[];
  licensorId: any;
  insignias: any[];

}

interface IState {
  error: string;
}

export class BrandAssetModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      error: '',
    };

    this.resetModal = this.resetModal.bind(this);
    this.createInitialValues = this.createInitialValues.bind(this);
    this.submitBrand = this.submitBrand.bind(this);
    this.createModalFormContent = this.createModalFormContent.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate() {

  }

  resetModal() {
    this.props.onClose();
  }

  createValidation() {
    return Yup.object().shape({
      title: Yup.string().required(),
      category: Yup.object(),
      font: Yup.string(),
      brandText: Yup.string().when('category', {
        is: (category: any) => category.type === BrandCategoryType.TEXT,
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired(),
      }),
      file: Yup.mixed().when('category', {
        is: (category: any) => {
          return category.type === BrandCategoryType.IMAGE && !this.props.asset;
        },
        then: Yup.mixed().required(),
        otherwise: Yup.mixed().notRequired(),
      }),
      hex: Yup.string(),
      pantone: Yup.string(),
      rgbR: Yup.string(),
      rgbG: Yup.string(),
      rgbB: Yup.string(),
      cmykC: Yup.string(),
      cmykM: Yup.string(),
      cmykY: Yup.string(),
      cmykK: Yup.string(),
    });

  }

  createInitialValues() {
    const values = {
      title: '',
      description: '',
      primary: false,
      insignia: '',
      font: '',
      pantone: '',
      rgbR: '',
      rgbG: '',
      rgbB: '',
      cmykC: '',
      cmykM: '',
      cmykY: '',
      cmykK: '',
      hex: '',
      brandText: '',
      file: '',
      previewFile: '',
      category: this.props.category,
    };

    if (this.props.asset) {
      const asset: BrandMarkAsset = this.props.asset;
      values.title = asset.title;
      values.description = asset.description || '';
      values.primary = asset.isPrimary;
      if (asset.colors) {
        if (asset.colors.cmyk) {
          values.cmykC = asset.colors.cmyk.c.toString();
          values.cmykM = asset.colors.cmyk.m.toString();
          values.cmykY = asset.colors.cmyk.y.toString();
          values.cmykK = asset.colors.cmyk.k.toString();

        }
        if (asset.colors.rgb) {
          values.rgbR = asset.colors.rgb.r.toString();
          values.rgbG = asset.colors.rgb.g.toString();
          values.rgbB = asset.colors.rgb.b.toString();

        }
        if (asset.colors.pms) {
          values.pantone = asset.colors.pms;

        }
        if (asset.colors.hex) {
          values.hex = asset.colors.hex;
        }

      }

      if (asset.category.type === BrandCategoryType.FONT) {
        values.font = asset.value ? asset.value : '';
      }

      if (asset.category.type === BrandCategoryType.TEXT) {
        values.brandText = asset.value;
      }
      if (asset.insignia) {
        values.insignia = String(asset.insignia.id);
      }

    }

    return values;

  }

  submitBrand(values: any, actions: FormikActions<any>) {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('is_primary', values.primary ? '1' : '0');
    if (values.insignia && values.insignia.length) {
      formData.append('insignia_id', values.insignia);
    }
    let category;
    if (this.props.category) {
      category = this.props.category;

    } else {
      return;
    }
    switch (category.type) {
      case BrandCategoryType.COLOR:
        const colors = {
          rgb: { r: values.rgbR, g: values.rgbG, b: values.rgbB },
          hex: values.hex,
          pms: values.pantone,
          cmyk: { c: values.cmykC, m: values.cmykM, y: values.cmykY, k: values.cmykK },
        };
        formData.append('value', JSON.stringify(colors));
        break;

      case BrandCategoryType.FONT:
        formData.append('value', values.font);
        if (values.previewFile) {
          formData.append('preview_file', values.previewFile);
        }
        if (values.file) {
          formData.append('file', values.file);
        }
        break;

      case BrandCategoryType.IMAGE:
        if (values.previewFile) {
          formData.append('preview_file', values.previewFile);
        }
        if (values.file) {
          formData.append('file', values.file);
        }
        break;

      case BrandCategoryType.TEXT:
        formData.append('value', values.brandText);
        break;

      default:
        actions.setSubmitting(false);
        break;
    }
    if (this.props.asset) {
      Axios.post(`/api/brand-assets/${this.props.asset.id}`, formData)
        .then((response) => {
          actions.setSubmitting(false);
          if (this.props.onSuccess) {
            this.props.onSuccess(new BrandMarkAsset(response.data.data));

          }
          this.resetModal();
        }).catch((e) => {
          actions.setSubmitting(false);
          let message = 'There was an error when submitting.';
          if (e.response.status === 422) {
            if (e.response.data.errors && e.response.data.errors.insignia_id)  {
              message = 'A current insignia is required.';
            }
          }
          this.setState({ error: message });
        });
    } else {
      formData.append('category_id', `${category.id}`);
      formData.append('licensor_id', this.props.licensorId);
      Axios.post('/api/brand-assets', formData)
        .then((response) => {
          actions.setSubmitting(false);
          if (this.props.onSuccess) {
            this.props.onSuccess(new BrandMarkAsset(response.data.data));

          }
          this.resetModal();
        }).catch((e) => {
          actions.setSubmitting(false);
          let message = 'There was an error when submitting.';
          if (e.response.status === 422) {
            if (e.response.data.errors && e.response.data.errors.insignia_id)  {
              message = 'A current insignia is required.';
            }
          }
          this.setState({ error: message });
        });

    }
  }

  createModalFormContent(props: FormikProps<any>) {
    let modalContent: any;
    if (this.props.category) {
      let otherFields;
      switch (this.props.category.type) {
        case BrandCategoryType.COLOR:
          otherFields = <ColorTypeForm formProps={props} />;
          break;

        case BrandCategoryType.FONT:
          otherFields = <div>
            <FontTypeForm formProps={props} />
            <ImageTypeForm
              previewFile={this.props.asset ? this.props.asset.previewFile : null}
              file={this.props.asset ? this.props.asset.file : null}
              formProps={props}
            />
          </div>;
          break;

        case BrandCategoryType.IMAGE:
          otherFields =
          <ImageTypeForm
            previewFile={this.props.asset ? this.props.asset.previewFile : null}
            file={this.props.asset ? this.props.asset.file : null}
            formProps={props}
          />;
          break;

        case BrandCategoryType.TEXT:
          otherFields = <TextTypeForm formProps={props} />;
          break;

        default:
          otherFields = null;
          break;

      }
      modalContent = (
        <div><SharedFormFields insignias={this.props.insignias} formProps={props} /> {otherFields}</div>
      );

    }

    return modalContent;

  }

  render() {
    let modalContent;
    if (this.props.category) {
      modalContent = (
        <Formik
          initialValues={this.createInitialValues()}
          validationSchema={this.createValidation()}
          onSubmit={this.submitBrand}
        >
          {(props: FormikProps<any>) =>
            <Form>
              <Modal.Body>
                {this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : null}
                {this.createModalFormContent(props)}

              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => this.resetModal()} type="button" className="btn btn-default pull-left">Close</button>
                <button className="btn btn-primary pull-right" type="submit" disabled={props.isSubmitting} >
                  {props.isSubmitting ? 'Submitting ...' : this.props.asset ? 'Update' : 'Create'}
                </button>

              </Modal.Footer>
            </Form>
          }

        </Formik >
      );

    }
    return (
      <GatewayModal shown={this.props.shown} title={this.props.title} type={ModalType.Primary} onClose={this.resetModal}>
        { modalContent }
      </GatewayModal>

    );

  }

}
