import * as React from 'react';
import { AffinityClient } from '../../../../shared';
import { LicensorSelect } from '../../../shared';

interface IProps {
  licensor: AffinityClient;
  availableLicensors: AffinityClient[];
  isReadOnly: boolean;
  onChange: (licensor: AffinityClient) => void;
}

export class DesignReviewPrimaryLicensor extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(licensors: AffinityClient[]) {
    if (licensors.length) {
      this.props.onChange(licensors[0]);
    }
  }

  render() {
    if (this.props.isReadOnly) {
      const name = this.props.licensor ? this.props.licensor.name : null;
      return (
        <p className="text-center"><strong>{name}</strong></p>
      );
    }
    const selected = this.props.licensor ? [this.props.licensor] : [];

    return (
      <div className="form-group">
        <label className="small">Primary Licensor</label>
        <LicensorSelect
          id="primary-licensor-typeahead"
          licensors={this.props.availableLicensors}
          selectedLicensors={selected}
          multiple={false}
          onChange={this.handleChange}
        />
      </div>
    );

  }
}
