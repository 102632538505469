import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { states } from '../../../shared';
import { GatewayModal, ModalType } from './GatewayModal';

interface IProps {
  onClose: () => any;
  onStateSelect: (state: any)  => any;
  shown: boolean;
}

export const StateModal = (props: IProps) => {
  const [search, setSearch] = React.useState('');

  const stateList = states
    .filter(s =>
      s.shortName.toLowerCase().includes(search.toLowerCase()) ||
      s.state.toLowerCase().includes(search.toLowerCase()))
      .map(s => (
        <div onClick={() => props.onStateSelect(s.shortName)} key={s.shortName} className="list-group-item">
          {s.state}
        </div>
      ));

  return (
    <GatewayModal
      type={ModalType.Primary}
      onClose={props.onClose}
      title="Select a State"
      shown={props.shown}
    >
      <Modal.Body>

        <input
          onChange={e => setSearch(e.target.value)}
          type="text"
          className="form-control"
          placeholder="Search States"
          value={search}
        />
        <br />

        <div style={{ overflowY: 'scroll', maxHeight: 500, height: 500, padding: 10 }}>
          <div className="list-group">
            {stateList}

          </div>

        </div>

      </Modal.Body>
    </GatewayModal>
  );

};
