import { faCircle, faMousePointer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { ProductCategory } from '../../../shared';
import { toUSD } from '../../../utils';
import { CurrencyLabel } from '../../shared';
import { ProductCategoryStats } from './models';
import { NoSalesDataPlaceholder } from './NoSalesDataPlaceholder';
import { PartialRepresentation } from './PartialRepresentation';

interface IProps {
  categories: ProductCategoryStats[];
  filterCategory: (category: any) => void;
  isPartial: boolean;
}

interface IState {
  showAll: boolean;
  hoveredCategory: number | null;

}

export class ProductCategories extends React.Component <IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      showAll: false,
      hoveredCategory: null,
    };
    this.showAll = this.showAll.bind(this);
    this.sliceClick = this.sliceClick.bind(this);
    this.sliceHover = this.sliceHover.bind(this);
    this.rowClassName = this.rowClassName.bind(this);
  }

  showAll() {
    this.setState({ showAll: true });
  }

  sliceClick(element: any, categories: ProductCategoryStats[]) {
    if (element[0]) {
      const index = element[0]._index;
      if (index >= 0) {
        this.props.filterCategory(categories[index].category);
      }
    }
  }

  dotColor(index: number) {
    const colors = [
      '#4f6980',
      '#849db1',
      '#a2ceaa',
      '#638b66',
      '#bfbb60',
      '#f47942',
      '#fbb04e',
      '#b66353',
      '#d7ce9f',
      '#b9aa97',
      '#7e756d',
    ];

    return colors[index % colors.length];
  }

  sliceHover(event: MouseEvent, element: any, categories: ProductCategoryStats[]) {
    if (event.type === 'mousemove') {
      if (element[0]) {
        const index = element[0]._index;
        if (index >= 0) {
          const categoryId = categories[index].category.id;
          if (categoryId !== this.state.hoveredCategory) {
            this.setState({ hoveredCategory: categoryId });
          }
        }
      }

    }
    if (event.type === 'mouseout') {
      this.setState({ hoveredCategory: null });
    }

  }

  rowClassName(category: ProductCategory) {
    if (this.state.hoveredCategory === category.id) {
      return 'active';
    }
    return '';

  }

  render() {
    // need to combine same categories
    const categories = [... this.props.categories];
    categories.sort((a, b) => {
      if (a.royalties < b.royalties) {
        return 1;
      }
      if (a.royalties > b.royalties) {
        return -1;
      }
      return 0;
    });
    const pieData = {
      labels: categories.map(stats => stats.category.name),
      datasets: [{
        data: categories.map(stats => stats.royalties),
      }],
    };

    const pieOptions: ChartOptions = {
      legend: {
        display: false,
      },
      onHover: (event, element) => this.sliceHover(event, element, categories),
      tooltips: {
        mode: 'single',
        callbacks: {
          label: (tooltip: any, data: any) => {
            if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
              const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              const category = data.labels[tooltip.index];
              return `${category}: ${toUSD(value)}`;
            }
            return '';
          },

          footer: (tooltipArray: any, data: any) => {
            const tooltip = tooltipArray[0];
            if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
              const totalData = data.datasets[tooltip.datasetIndex].data
                .reduce((total: number, d: number) => total += d, 0);
              const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              const percent = ((value / totalData) * 100).toFixed(2);
              return `${percent}%`;
            }
            return '';

          },
        },
      },
      plugins: {
        colorschemes: {
          scheme: 'tableau.MillerStone11',
        },
      },
    };

    const rowStyle = { cursor: 'pointer' };

    const rows = categories.map((stats, index) => (
      <tr
        className={this.rowClassName(stats.category)}
        onClick={() => this.props.filterCategory(stats.category)}
        style={rowStyle}
      >
        <td><FontAwesomeIcon style={{ color: this.dotColor(index) }} icon={faCircle}/>&nbsp;{stats.category.name}</td>
        <td>
          <CurrencyLabel value={stats.averageRPU} />
        </td>
        <td>
            <NumberFormat  displayType={'text'} thousandSeparator={true} value={stats.units} />
        </td>
        <td><CurrencyLabel value={stats.royalties} /></td>
      </tr>

    ));

    const footer = !this.state.showAll && this.props.categories.length > 10 ? (
      <div onClick={this.showAll} style={{ cursor: 'pointer' }} className="panel-footer">
        <p className="text-center text-muted small">Show More</p>

      </div>
    )
    : null;

    const content = this.props.categories.length ?
      (
        <div>
          <div>
            <Pie onElementsClick={event => this.sliceClick(event, categories)} options={pieOptions} data={pieData} />
          </div>
          <div style={!this.state.showAll ? { maxHeight: 407, overflowY: 'hidden' } : {}} className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Avg. RPU</th>
                  <th>Units</th>
                  <th>Royalties</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>

            </table>
          </div>
          {footer}

        </div>
      )
      :
      (
        <NoSalesDataPlaceholder />
      );

    const partialData = this.props.isPartial && this.props.categories.length ? (<PartialRepresentation />) : null;

    return (

      <div className= "panel panel-portal" >
        <div className="panel-body">

          <div className="analytics-header text-primary">Product Categories</div>
          <div >
            <FontAwesomeIcon icon={faMousePointer} /> <span className="text-muted">Click a category to narrow analysis</span>
          </div>
          {partialData}

        </div>
        <div className="panel-body">
          {content}
        </div>
      </div >
    );

  }

}
