import Axios, { CancelTokenSource } from 'axios';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { getMarketplaceSettings } from '../../../api';
import { UserContext } from '../../../contexts';
import { AffinityClient, MarketplaceProduct, MarketplaceSettings } from '../../../shared';
import { ContentWithSidebar } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { GalleryForm, GalleryFormValidation, IGalleryFormValues } from './GalleryForm';

interface IProps {
  vendorId: number;
}

interface IState {
  loading: boolean;
  saving: boolean;
  settings: MarketplaceSettings;
}

export class AddGalleryItemPage extends React.Component<IProps & RouteComponentProps<any>, IState> {
  private _getSettings: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      settings: new MarketplaceSettings(),
    };
    this.createProduct = this.createProduct.bind(this);
    this.getMarketplaceSettings = this.getMarketplaceSettings.bind(this);
  }

  componentDidMount() {
    this.getMarketplaceSettings();
  }

  getMarketplaceSettings() {

    this.setState({ loading: true });
    if (this._getSettings) {
      this._getSettings.cancel('Cancelled getSettings XHR due to new request');
    }
    this._getSettings = Axios.CancelToken.source();
    getMarketplaceSettings(this.props.vendorId, this._getSettings)
      .then(response => this.setState({ loading: false, settings: MarketplaceSettings.fromApi(response.data.data) }));
  }

  createProduct(values: IGalleryFormValues) {
    const product = new MarketplaceProduct();
    product.setValuesFromGallery(values, true);
    const formData = product.generateGalleryFormData(true);
    formData.append('vendor_account_id', `${this.props.vendorId}`);
    formData.append('buyable', '0');
    this.setState({ saving: true });

    // Wait until ready to create
    Axios.post('/api/marketplace/products', formData)
      .then(response => this.props.history.push('/marketplace/gallery'));
  }

  render() {
    const initialValues = {
      organization: new AffinityClient(),
      category: { id: 0, name: '', type: '' },
      name: '',
      description: '',
      vendorProductId: '',
      externalURL: '',
      minimumQuantity: 0,
      defaultMinimumQuantity: false,
      price: '',
      salePrice: '',
      isOnSale: false,
      shippingFirst: '',
      shippingAdditional: '',
      fulfillmentDuration: 1,
      fulfillmentTerm: 1,
      defaultShippingPrice: this.state.settings.defaultShippingPriceFirst ? true : false,
      shippingPolicy: this.state.settings.defaultShippingText || '',
      returnsPolicy: this.state.settings.defaultReturnText || '',
      defaultShippingPolicy: this.state.settings.defaultShippingText ? true : false,
      defaultReturnPolicy: this.state.settings.defaultReturnText ? true : false,
      defaultFulfillment: this.state.settings.defaultDurationTermId ? true : false,
      options: [],
      primaryImage: null,
      secondaryImages: [],
    };
    const AddProductValidation = Yup.object().concat(Yup.object().shape({
      primaryImage: Yup.mixed().required('A product image is required'),
    })).concat(GalleryFormValidation);
    const buttonText = this.state.saving ? 'Submitting...' : 'Create Gallery Item';

    return this.state.loading ? <LoadingSpinner /> : (
      <Formik
        validationSchema={AddProductValidation}
        initialValues={initialValues}
        onSubmit={this.createProduct}
      >

        {(formikProps: FormikProps<IGalleryFormValues>) =>
          (

            <form onSubmit={formikProps.handleSubmit}>
              <ContentWithSidebar
                main={(
                  <div className="panel panel-portal">
                    <div className="panel-body">
                      <UserContext.Consumer>
                        {(user) => {
                          const product = new MarketplaceProduct();
                          product.vendor = user.account;
                          product.vendor.id = this.props.vendorId;

                          return <GalleryForm settings={this.state.settings} formikProps={formikProps} product={product} />;
                        }}

                      </UserContext.Consumer>

                    </div>

                  </div>
                )}
                sidebar={(
                  <div className="panel panel-portal">
                    <div className="panel-body">
                      <button
                        disabled={formikProps.isSubmitting}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {buttonText}
                      </button>
                    </div>
                  </div>
                )}
              />

            </form>
          )
        }
      </Formik>
    );
  }

}
