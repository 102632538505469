import { faCalendar, faInfoCircle, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { CancelTokenSource } from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { getRoyaltyAnalytics, getRoyaltyClients, getRoyaltyVendors } from '../../../api';
import { UserContext } from '../../../contexts';
import { formattedFiscalYear, getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { AboutModal } from './AboutModal';
import { ClientList } from './ClientList';
import { DistributionChannels } from './DistributionChannels';
import { ClientStats, DistributionChannelStats, ProductCategoryStats, QuarterStats, VendorStats } from './models';
import { ShareStats } from './models/ShareStats';
import { ProductCategories } from './ProductCategories';
import { QuarterOverQuarter } from './QuarterOverQuarter';
import { ShareOfRoyalties } from './ShareOfRoyalties';
import { VendorList } from './VendorList';
import { YearOverYear } from './YearOverYear';

interface AnalyticsParams {
  quarter?: 'All' | 'Q1' | 'Q2' | 'Q3' | 'Q4';
  year?: any | string;
  vendor?: any | null;
  category?: any | null;
  channel?: any | null;
  licensor?: any | null;
}

interface IState {
  params: AnalyticsParams;
  quarters: QuarterStats[];
  productCategories: ProductCategoryStats[];
  distributionChannels: DistributionChannelStats[];
  vendors: VendorStats[];
  clients: ClientStats[];
  share: ShareStats;
  totalVendors: number;
  totalClients: number;
  currentVendorPage: number;
  currentClientPage: number;
  hasNextVendors: boolean;
  hasNextClients: boolean;
  loading: boolean;
  loadingVendors: boolean;
  loadingClients: boolean;
  isCurrent: boolean;
  aboutModalShow: boolean;
  isPartialSales: boolean;
  isSalesData: boolean;
}

const getYearParam = (yearParam: AnalyticsParams['year']): string => {
  return yearParam.split('-')[0];
};

const getQuarterParam = (quarterParam: AnalyticsParams['quarter']): string | null => {
  switch (quarterParam) {
    case 'Q1':
      return '1';
    case 'Q2':
      return '2';
    case 'Q3':
      return '3';
    case 'Q4':
      return '4';
  }
  return null;
};

const getVendorReportExportSection = (vendor: any | null, year: string, licensorId: any | null) => {

  if (!vendor) {
    return null;
  }

  const yearParam = year.indexOf('-') === -1 ? (
    year
  ) : (
    year.substr(0, year.indexOf('-'))
  );

  let paramString = `?year=${yearParam}`;
  if (licensorId) {
    paramString += `&client_id=${licensorId}`;
  }

  return (
    <div className="panel panel-portal">
      <div className="panel-body" style={{ textAlign: 'center' }}>
        <h4>Royalty Report Data Export</h4>
        <p className="text-muted">Export royalty report data for this vendor.</p>
        <Link target="_blank" to={`/api/royalty-analytics/vendors/${vendor.id}/downloads${paramString}`}>
          <button className="btn btn-default">
            <i className="fa fa-download" /> Download Export
          </button>
        </Link>
      </div>
    </div>
  );
};

export class RoyaltyAnalyticsPage extends React.Component <any, IState> {

  protected _getAnalyticsSource: CancelTokenSource;
  protected _getVendorAnalyticsSource: CancelTokenSource;
  protected _getClientAnalyticsSource: CancelTokenSource;
  protected _routeWatch: any;

  defaultParams: AnalyticsParams = {
    quarter: 'All',
    year: this.createDefaultLabel(),
    vendor: null,
    category: null,
    channel: null,
    licensor: null,

  };

  constructor(props: any) {
    super(props);

    const params = this.paramsFromQuery(Object.assign({}, this.defaultParams), this.props.location.search);
    this.state = {
      params,
      quarters: [],
      productCategories: [],
      distributionChannels: [],
      vendors: [],
      clients: [],
      totalVendors: 0,
      totalClients: 0,
      currentVendorPage: 1,
      currentClientPage: 1,
      hasNextVendors: true,
      hasNextClients: true,
      loading: true,
      share: {
        total: 0,
        share: 0,
        units: 0,
        rpu: 0,
      },
      loadingVendors: true,
      loadingClients: true,
      isCurrent: true,
      aboutModalShow: false,
      isPartialSales: false,
      isSalesData: false,
    };

    this.filterAnalytics = this.filterAnalytics.bind(this);
    this.filterDistributionChannel = this.filterDistributionChannel.bind(this);
    this.filterVendor = this.filterVendor.bind(this);
    this.filterProductCategory = this.filterProductCategory.bind(this);
    this.filterClient = this.filterClient.bind(this);
    this.getAnalytics = this.getAnalytics.bind(this);
    this.removeCategoryFilter = this.removeCategoryFilter.bind(this);
    this.removeChannelFilter = this.removeChannelFilter.bind(this);
    this.removeVendorFilter = this.removeVendorFilter.bind(this);
    this.removeLicensorFilter = this.removeLicensorFilter.bind(this);
    this.getVendors = this.getVendors.bind(this);
    this.filterQuarter = this.filterQuarter.bind(this);
    this.filterYear = this.filterYear.bind(this);
    this.loadData = this.loadData.bind(this);
    this.setVendorsPage = this.setVendorsPage.bind(this);
    this.setClientsPage = this.setClientsPage.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
    this.updateQueryString = this.updateQueryString.bind(this);
    this.showAboutModal = this.showAboutModal.bind(this);
    this.hideAboutModal = this.hideAboutModal.bind(this);
  }

  componentDidMount() {

    this.loadData();
    this._routeWatch = this.props.history.listen((location: any, action: any) => {
      const params = this.paramsFromQuery(Object.assign({}, this.defaultParams), location.search);
      this.setState({ params });
      this.loadData();
    });
  }

  componentWillUnmount() {
    this._routeWatch();
  }

  createDefaultLabel() {
    const date = new Date();
    if (date.getMonth() >= 9) {
      return formattedFiscalYear(date.getFullYear());
    }
    return formattedFiscalYear(date.getFullYear() - 1);
  }

  paramsFromQuery(p: AnalyticsParams, search: string) {
    const queryValues = queryString.parse(search);
    const params = p;
    if (queryValues.channel) {
      params.channel = { id: Number(queryValues.channel), name: 'Loading' };
    }

    if (queryValues.category) {
      params.category = { id: Number(queryValues.category), name: 'Loading' };

    }

    if (queryValues.vendor) {
      params.vendor = { id: Number(queryValues.vendor), name: 'Loading' };
    }

    if (queryValues.licensor) {
      params.licensor = { id: Number(queryValues.licensor), name: 'Loading' };
    }

    if (queryValues.year) {
      params.year = queryValues.year;
    }
    if (queryValues.quarter) {
      params.quarter = queryValues.quarter;
    }

    if (this.props.match.params.licensorId) {
      params.licensor = { id: Number(this.props.match.params.licensorId) };
    }

    if (this.props.match.params.vendorId) {
      params.vendor = { id: Number(this.props.match.params.vendorId) };
    }

    return params;

  }

  filterAnalytics(params: AnalyticsParams) {
    this.setState({ params });
    this.updateQueryString(params);
    this.loadData();
  }

  filterDistributionChannel(channel: any) {
    const params = this.state.params;
    params.channel = channel;
    this.filterAnalytics(params);

  }

  filterQuarter(quarter: any) {
    const params = this.state.params;
    params.quarter = quarter;
    this.filterAnalytics(params);
  }

  filterYear(year: any) {
    const params = this.state.params;
    params.year = year;
    this.filterAnalytics(params);
  }

  filterVendor(vendor: any) {
    const params = this.state.params;
    params.vendor = vendor;
    this.filterAnalytics(params);
  }

  filterClient(client: any) {
    const params = this.state.params;
    params.licensor = client;
    this.filterAnalytics(params);
  }

  filterProductCategory(category: any) {
    const params = this.state.params;
    params.category = category;
    this.filterAnalytics(params);
  }

  removeCategoryFilter() {
    const params = this.state.params;
    params.category = null;
    this.filterAnalytics(params);

  }

  removeChannelFilter() {
    const params = this.state.params;
    params.channel = null;
    this.filterAnalytics(params);

  }

  removeVendorFilter() {
    const params = this.state.params;
    params.vendor = null;
    this.filterAnalytics(params);
  }

  removeLicensorFilter() {
    const params = this.state.params;
    params.licensor = null;
    this.filterAnalytics(params);
  }

  clearAllFilters() {
    const params = this.state.params;
    const onClientPage = this.props.match.params.licensorId;
    const onVendorPage = this.props.match.params.vendorId;

    if (!onVendorPage) {
      params.vendor = null;
    }
    if (!onClientPage) {
      params.licensor = null;
    }

    params.channel = null;
    params.category = null;

    this.setState({ totalVendors: 0, hasNextVendors: true, currentVendorPage: 1 });
    this.filterAnalytics(params);

  }

  loadData() {
    this.setState({
      loading: true,
      currentVendorPage: 1,
    });
    this.getAnalytics();
    this.getVendors(1);
    this.getLicensors(1);
  }

  updateQueryString(params: AnalyticsParams) {
    const baseUrl = this.props.location.pathname;
    const onClientPage = this.props.match.params.licensorId;
    const onVendorPage = this.props.match.params.vendorId;
    const queryParams = {
      quarter: params.quarter !== 'All' ? params.quarter : null,
      year: params.year !== this.defaultParams.year ? params.year : null,
      category: params.category && params.category.id,
      channel: params.channel && params.channel.id,
      vendor: !onVendorPage ? params.vendor && params.vendor.id : null,
      licensor: !onClientPage ? params.licensor && params.licensor.id : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    this.props.history.push(`${baseUrl}?${queryString.stringify(cleanParams)}`);
  }

  getRequestParams() {

    const onClientPage = this.props.match.params.licensorId;
    const onVendorPage = this.props.match.params.vendorId;
    let asParam = null;
    if (onVendorPage) {
      asParam = 'vendor';
    } else if (onClientPage) {
      asParam = 'client';
    }

    return {
      year: getYearParam(this.state.params.year),
      quarter: getQuarterParam(this.state.params.quarter),
      vendor_id: this.state.params.vendor && this.state.params.vendor.id,
      product_category_id: this.state.params.category && this.state.params.category.id,
      distribution_channel_id: this.state.params.channel && this.state.params.channel.id,
      client_id: this.state.params.licensor && this.state.params.licensor.id,
      as: asParam,
    };
  }

  getAnalytics() {

    if (this._getAnalyticsSource) {
      this._getAnalyticsSource.cancel();
    }
    this._getAnalyticsSource = axios.CancelToken.source();

    const params = this.getRequestParams();

    getRoyaltyAnalytics(params, this._getAnalyticsSource)
      .then((data) => {

        const stateParams = Object.assign({}, this.state.params);

        ['category', 'channel', 'vendor', 'licensor'].forEach((label) => {
          if (data.filterLabels[label] && stateParams[label]) {
            stateParams[label].name = data.filterLabels[label];
          }
        });

        this.setState({
          quarters: data.quarters,
          productCategories: data.productCategories,
          distributionChannels: data.distributionChannels,
          share: data.share,
          loading: false,
          isCurrent: data.isCurrent,
          isPartialSales: data.isPartialSales,
          isSalesData: data.isSalesData,
        });
      });
  }

  getLicensors(newPage: number) {
    this.setState({ loadingClients: true });

    if (this._getClientAnalyticsSource) {
      this._getClientAnalyticsSource.cancel();
    }

    this._getClientAnalyticsSource = axios.CancelToken.source();

    const params = this.getRequestParams();

    getRoyaltyClients(params, newPage, this._getClientAnalyticsSource)
      .then((data) => {
        this.setState({
          clients: data.clients,
          totalClients: data.pagination.total,
          hasNextClients: data.pagination.hasNext,
          loadingClients: false,
        });
      });
  }

  getVendors(newPage: number) {
    this.setState({ loadingVendors: true });

    if (this._getVendorAnalyticsSource) {
      this._getVendorAnalyticsSource.cancel();
    }

    this._getVendorAnalyticsSource = axios.CancelToken.source();

    const params = this.getRequestParams();
    getRoyaltyVendors(params, newPage, this._getVendorAnalyticsSource)
      .then((data) => {
        this.setState({
          vendors: data.vendors,
          totalVendors: data.pagination.total,
          hasNextVendors: data.pagination.hasNext,
          loadingVendors: false,
        });
      });
  }

  setVendorsPage(page: number) {
    this.setState({ currentVendorPage: page });
    this.getVendors(page);
  }

  setClientsPage(page: number) {
    this.setState({ currentClientPage: page });
    this.getLicensors(page);
  }

  royaltyVendorsLink(): string {
    const baseUrl = '/api/royalty-analytics/vendors';
    const requestParams = omitBy(this.getRequestParams(), isNil);
    requestParams['export'] = '1';
    return `${baseUrl}?${queryString.stringify(requestParams)}`;
  }

  topSectionShown() {
    const filteredToVendor = (!this.props.match.params.vendorId && !!this.state.params.vendor);
    const filteredToClient = (!this.props.match.params.licensorId && !!this.state.params.licensor);
    const filteredToBoth = (!!this.state.params.vendor && this.state.params.licensor);

    return (
      filteredToVendor
      || filteredToClient
      || filteredToBoth
      || this.state.params.channel
      || this.state.params.category
      || this.state.isSalesData
    );
  }

  showAboutModal() {
    this.setState({ aboutModalShow: true });

  }

  hideAboutModal() {
    this.setState({ aboutModalShow: false });

  }

  render() {

    const currentQuarter = this.state.params.quarter;
    const currentYear = this.state.params.year;

    const vendors = this.state.vendors;

    const categories = this.state.productCategories;
    const channels = this.state.distributionChannels;

    const onClientPage = this.props.match.params.licensorId;
    const onVendorPage = this.props.match.params.vendorId;

    const activeCategoryFilter = this.state.params.category ? (

      <button style={{ marginLeft: 10 }} onClick={this.removeCategoryFilter} className="btn btn-primary">
         <FontAwesomeIcon icon={faTimes} /> &nbsp; {this.state.params.category.name}
      </button>
    ) : null;

    const activeChannelFilter = this.state.params.channel ? (

      <button style={{ marginLeft: 10 }} onClick={this.removeChannelFilter} className="btn btn-primary">
        <FontAwesomeIcon icon={faTimes} /> &nbsp; {this.state.params.channel.name}
      </button>
    ) : null;

    const activeVendorFilter = this.state.params.vendor && !onVendorPage ? (
      <button style={{ marginLeft: 10 }} onClick={this.removeVendorFilter} className="btn btn-primary">
        <FontAwesomeIcon icon={faTimes} /> &nbsp; {this.state.params.vendor.name}
      </button>
      ) : null;

    const activeLicensorFilter = this.state.params.licensor && !onClientPage ? (
      <button style={{ marginLeft: 10 }} onClick={this.removeLicensorFilter} className="btn btn-primary">
        <i className="fa fa-close"></i> &nbsp; {this.state.params.licensor.name}
      </button>
      ) : null;
    const removeAllFilters = activeCategoryFilter || activeChannelFilter || activeVendorFilter || activeLicensorFilter ?
      (
        <button onClick={this.clearAllFilters} style={{ marginLeft: 10 }} className="btn btn-link">
          Remove All Filters
        </button>
        ) :
      null;

    const isFilteredPartial = this.state.isPartialSales && (this.state.params.category || this.state.params.channel);

    const isFiltered = this.state.params.category || this.state.params.channel || this.state.params.vendor;

    const vendorList  = !this.state.params.vendor && !onVendorPage ? (
      <div>
        <VendorList
          setPage={this.setVendorsPage}
          page={this.state.currentVendorPage}
          totalVendors={this.state.totalVendors}
          filterVendor={this.filterVendor}
          hasNext={this.state.hasNextVendors}
          vendors={vendors}
          exportUrl={this.royaltyVendorsLink()}
          loading={this.state.loadingVendors}
          isCurrent={this.state.isCurrent}
          isPartial={isFilteredPartial}
        />
      </div>
    ) : null;

    const licensorId = this.state.params.licensor && this.state.params.licensor.id;
    const vendorSalesExport = getVendorReportExportSection(this.state.params.vendor, this.state.params.year, licensorId);

    const clientList = !this.state.params.licensor && !onClientPage ? (
      <div>
        <ClientList
          setPage={this.setClientsPage}
          page={this.state.currentClientPage}
          totalClients={this.state.totalClients}
          filterClient={this.filterClient}
          hasNext={this.state.hasNextClients}
          clients={this.state.clients}
          loading={this.state.loadingClients}
          isCurrent={this.state.isCurrent}
        />
      </div>
    ) : null;

    const comparisonChart = this.state.params.quarter === 'All' ?
      (
        <YearOverYear
          year={this.state.params.year}
          isPartial={isFilteredPartial}
          quarter={currentQuarter}
          quarters={this.state.quarters}
          hasFilters={isFiltered}
        />
      )
      :
      (

        <QuarterOverQuarter
          isPartial={isFilteredPartial}
          quarter={this.state.params.quarter}
          quarters={this.state.quarters}
          hasFilters={isFiltered}
          year={this.state.params.year}
        />
      );

    const firstSection = this.topSectionShown() ? (
      <div className="row">
        <div className="col-md-7">
          {comparisonChart}

        </div>
        <div className="col-md-5">
          <ShareOfRoyalties isPartial={this.state.isPartialSales} stats={this.state.share} />

        </div>
      </div>

    )
    :
   null;

    const years = [];
    const date = new Date();
    let year;
    if (date.getMonth() >= 9) {
      year = date.getFullYear();
    } else {
      year = date.getFullYear() - 1;
    }

    for (let i = 0; i <= (year - 2018); i++) {
      const yearToAdd = 2018 + i;
      // const yearValue = `${yearToAdd}-${(yearToAdd + 1).toString().substring(2)}`;
      const yearValue = formattedFiscalYear(yearToAdd);

      years.push(<MenuItem key={yearValue} eventKey={yearValue} >{yearValue}</MenuItem>);
    }

    const partialSalesWarning = false ? (
      <div className="panel-body" style={{ padding: 8 }}>
        <p className="text-muted small" style={{ margin: 0 }}>
          <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
          This report is a partial representation of sales from vendors who reported sales data.
          &nbsp;
          <a
            style={{ padding: 0, lineHeight: 'inherit', cursor: 'pointer' }}
            onClick={this.showAboutModal}
          >
            Learn more
          </a>
        </p>

      </div>
    ) : null;

    const partialData = this.state.isCurrent ?
    (
      <div className="panel-footer small" style={{ color: 'white', padding: 8, background: '#e06026' }}>
        <FontAwesomeIcon icon={faCalendar} /> &nbsp;
        The selected period is in progress. Analytics will adjust as sales are reported,
        and comparisons are hidden until the period is complete.
      </div>
    )
    :
    null;

    const content = this.state.loading ?
      (
        <div className="panel panel-portal">
          <div className="panel-body" style={{ textAlign: 'center', paddingBottom: 10 }}>
            <LoadingSpinner />
            <h4>One moment please...</h4>
            <h4>We're calculating your analytics now.</h4>
          </div>
        </div>
      )
      :
      (
        <div>
          <div className="panel panel-portal">
            <div className="panel-body">
              <h3 style={{ marginTop: 0, marginBottom: 5 }}>Royalty Analytics</h3>
              <p className="text-muted ">
                Review gross royalty performance by year or quarter, and filter by product category,
                distribution channel, and/or license to dive in to deeper performance metrics.
              </p>

              <div>
                <DropdownButton
                  onSelect={this.filterQuarter}
                  id="quarter-dropdown"
                  bsStyle={'default'}
                  title={currentQuarter}
                >
                  <MenuItem eventKey="All" > All </MenuItem>
                  <MenuItem divider />
                  <MenuItem eventKey="Q1"> Q1 </MenuItem>
                  <MenuItem eventKey="Q2"> Q2 </MenuItem>
                  <MenuItem eventKey="Q3"> Q3 </MenuItem>
                  <MenuItem eventKey="Q4"> Q4 </MenuItem>

                </DropdownButton>
                <DropdownButton
                  style={{ marginLeft: 10 }}
                  id="year-dropdown"
                  bsStyle={'default'}
                  title={currentYear}
                  onSelect={this.filterYear}
                >
                  {years}

                </DropdownButton>
                {activeCategoryFilter}
                {activeChannelFilter}
                {activeVendorFilter}
                {activeLicensorFilter}
                {removeAllFilters}

                </div>
              </div>
              {partialData}
              {partialSalesWarning}
            </div>

            {firstSection}

            <div className="row">
              <div className="col-md-6">
                <ProductCategories
                  isPartial={this.state.isPartialSales}
                  filterCategory={this.filterProductCategory}
                  categories={categories}
                />

              </div>
              <div className="col-md-6">
                <DistributionChannels
                  isPartial={this.state.isPartialSales}
                  distributionChannels={channels}
                  filterChannel={this.filterDistributionChannel}
                />

              </div>

            </div>

            <UserContext.Consumer>
              {user => (user.type !== 'vendor' ? vendorSalesExport : null)}
            </UserContext.Consumer>

            <UserContext.Consumer>
              {user => (user.type !== 'vendor' ? vendorList : null) }
            </UserContext.Consumer>
            <UserContext.Consumer>
              {user => (user.type !== 'client' ? clientList : null)}
            </UserContext.Consumer>
            <AboutModal shown={this.state.aboutModalShow} onClose={this.hideAboutModal} />

        </div>

      );

    return (
      <DocumentTitle title={getAffinityPageTitle('Royalties')}>
        <FullContent breadcrumbs={[{ name: 'Royalties' }]}>

          {content}

        </FullContent>
      </DocumentTitle>
    );

  }

}
