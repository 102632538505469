import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { Link } from 'react-router-dom';
import { UserContext, UserType } from '../../../contexts';
import { AffinityClient, DesignPhase } from '../../../shared';
import { DesignResponse } from '../../DesignResponses/DesignResponse';
import FileAttachmentButton from './FileAttachmentButton';

interface IProps {
  status: DesignPhase;
  onSubmit?: (status: DesignPhase, message: string, notify: boolean, attachedFiles: File[]) => void;
  isReadOnly: boolean;
  user: UserType;
  responses: DesignResponse[];
  setCanPin: (canPin: boolean) => void;
  primaryLicensor: AffinityClient;
}

interface IState {
  message: string;
  messageShown: boolean;
  selectedPhase: DesignPhase | null;
  notify: boolean;
  attachedFiles: File[];
}

export class DesignReviewActions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      message: '',
      messageShown: false,
      selectedPhase: null,
      notify: true,
      attachedFiles: [],
    };
    this.setMessage = this.setMessage.bind(this);
    this.updateFile = this.updateFile.bind(this);
    this.pickStatus = this.pickStatus.bind(this);
    this.submitStatus = this.submitStatus.bind(this);
    this.cancelStatus = this.cancelStatus.bind(this);
    this.selectPreWrittenResponse = this.selectPreWrittenResponse.bind(this);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const hadPrevious = prevProps.primaryLicensor;
    const hasCurrent = this.props.primaryLicensor;
    const shouldUpdate = (hadPrevious && hasCurrent && prevProps.primaryLicensor.id !== this.props.primaryLicensor.id)
      || (!hadPrevious && hasCurrent);

    if (shouldUpdate) {
      const isExclusive = this.props.primaryLicensor.profile && this.props.primaryLicensor.profile.exclusivelyReviewsDesigns;
      if (this.state.messageShown && this.state.selectedPhase) {
        const phase = this.state.selectedPhase;
        this.setState({ message: phase.getDefaultReviewText(isExclusive) });
      }

    }
  }

  setMessage(message: string) {
    this.setState({ message });

  }

  updateFile = (file: []) => {
    this.setState({ attachedFiles: file });
  }

  pickStatus(phase: DesignPhase, notify: boolean) {
    this.props.setCanPin(true);
    const isExclusive = this.props.primaryLicensor &&
      this.props.primaryLicensor.profile &&
      this.props.primaryLicensor.profile.exclusivelyReviewsDesigns;
    this.setState({
      notify,
      messageShown: true,
      selectedPhase: phase,
      message: phase.getDefaultReviewText(isExclusive),
    });
  }

  cancelStatus() {
    this.props.setCanPin(false);
    this.setState({ message: '' , messageShown: false });
  }

  submitStatus() {
    if (this.props.onSubmit  && this.state.selectedPhase) {
      if ((Number(this.state.selectedPhase.id) === 7 && this.state.message.length > 0)
        || Number(this.state.selectedPhase.id !== 7)) {
        this.props.onSubmit(this.state.selectedPhase, this.state.message, this.state.notify, this.state.attachedFiles);
        this.setState({ messageShown: false });
      }
    }
  }

  selectPreWrittenResponse(event: React.ChangeEvent<HTMLSelectElement>) {
    const responses = this.props.responses;
    const response = responses.find(r => Number(r.id) === Number(event.target.value));
    if (response) {
      this.setState({ message: response.body });
    }

  }

  render() {
    const status = this.props.status;
    if (this.props.isReadOnly) {
      return (
        <div>
          <button
            style={{ cursor: 'default' }}
            className={`btn btn-block btn-${status.buttonClass}`}
            disabled
          >
              {status.name}
          </button>
        </div>
      );
    }
    const quillModules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
      ],
    };
    const selectedPhase = this.state.selectedPhase;
    const messageRequired = selectedPhase && this.state.messageShown && selectedPhase.id === 7 ?
      (<p className="text-muted small">A rejection message is required.</p>) : null;

    const messageBox = this.state.messageShown && selectedPhase ?
      (
        <div>
          { this.state.notify ? <ReactQuill
            style={{ marginTop: 10 }}
            modules={quillModules}
            value={this.state.message}
            onChange={this.setMessage}
          /> : null }
          {messageRequired}
          { this.state.messageShown ?
            <FileAttachmentButton onFileSelect={this.updateFile} inputId={'reviewFile'}/>
            : null
          }
          <div style={{ marginTop: 10 }}>
            <button onClick={this.submitStatus} className={`btn btn-lg btn-block btn-${selectedPhase.buttonClass}`}>
              {selectedPhase.name}
            </button>
            <button onClick={this.cancelStatus} style={{ marginRight: 10 }} className="btn btn-block btn-default">Cancel</button>
          </div>
        </div>
      ) : null;

    const userType = this.props.user.type || '';
    const messageOptions = DesignPhase.availablePhases(userType, status).map(phase => (
      <MenuItem onClick={() => this.pickStatus(phase, true)} key={phase.id} >
        <FontAwesomeIcon className={phase.labelClass} icon={phase.icon} /> {phase.shortName}
      </MenuItem>
    ));

    const adminOptions = userType === 'admin' ?  DesignPhase.availablePhases('all', status).map(phase => (
      <MenuItem onClick={() => this.pickStatus(phase, false)} key={phase.id} >
        Set To {phase.shortName}
      </MenuItem>
    )) : null;

    const designResponses = this.props.responses.length && this.state.messageShown ? (
      <UserContext.Consumer>
        {
          user => user.type !== 'vendor' ? (
          <div style={{ marginTop: 10 }} className="form-group">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label>Pre-written Response</label>
              <Link target="_blank" to="/settings/designs">Manage</Link>
            </div>
            <select onChange={this.selectPreWrittenResponse} className="form-control">
              <option selected disabled>Select a response</option>
              {this.props.responses.map(r => (<option key={r.id} value={r.id}>{r.title}</option>))}
            </select>
          </div>
          ) : null
        }

      </UserContext.Consumer>
    ) : null;

    return (
      <div className="design-review-actions">
        <DropdownButton className="btn-block btn-lg" bsStyle={status.buttonClass} id="status-buttton" title={status.name}>
          {messageOptions}
          <hr style={{ margin: 5 }} />
          {adminOptions}
        </DropdownButton>
        {designResponses}
        {messageBox}
      </div>
    );

  }

}
