import * as React from 'react';
import { UserContext } from '../../contexts';
import AngularWrapper from '../AngularWrapper/AngularWrapper';
import { SerialSearchPage } from '../Labels/SerialSearchPage';

export class LabelsIndex extends React.Component<any, any> {

  render() {

    return (
      <UserContext.Consumer>
        {(user) => {
          if (user.type === 'client') {
            return (<SerialSearchPage />);
          }
          return (<AngularWrapper {... this.props} />);
        }}

      </UserContext.Consumer>
    );

  }

}
