import { CancelTokenSource } from 'axios';
import { ChangeEvent, SyntheticEvent } from 'react';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps, withRouter } from 'react-router';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';
import { getSerialSearch, ISerialSearchResult } from './api';

interface IState {
  searchText: string;
  loading: boolean;
  errorText: string;
  searchResult: ISerialSearchResult | null;
}

type IProps = RouteComponentProps<{
  clientId?: string;
}>;

function licensedText(isLicensed: boolean | null) {
  if (isLicensed === null) {
    return null;
  }

  const icon = isLicensed ? (
    <div className="text-success"><i className="fa fa-check" /> Licensed</div>
  ) : (
    <div className="text-danger"><i className="fa fa-times" /> Not Licensed</div>
  );

  const text = isLicensed ? (
    <div>
      The vendor is licensed. However, if you suspect that this product was not
      approved or is otherwise not in compliance, please let us know.
    </div>
  ) : (
    <div>
      The vendor is not currently licensed. If another party is reselling this product,
      it's possible that the product was sold while the vendor ​may have been​ licensed.
      If you suspect that this product is not licensed or is not in compliance, please
      let us know.
    </div>
  );

  return (
    <div>
      <div style={{ marginBottom: 15 }}>
        {icon}
      </div>
      <div style={{ marginBottom: 15 }} className="text-muted">
        {text}
      </div>
      <div><a target="_blank" href="mailto:help@affinitylicensing.com">Contact Us</a></div>
    </div>
  );
}

class SerialSearchPage extends React.Component<IProps, IState> {

  protected _getSerialSearchSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      searchText: '',
      loading: false,
      errorText: '',
      searchResult: null,
    };

    this.searchSerial = this.searchSerial.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentWillUnmount() {
    if (this._getSerialSearchSource) {
      this._getSerialSearchSource.cancel('Cancelled getSerialSearchSource() XHR due to unmount');
    }
  }

  searchSerial() {
    this.setState({
      loading: true,
      errorText: '',
    });
    getSerialSearch(this.state.searchText, this.props.match.params.clientId).then((searchResult) => {
      this.setState({
        searchResult,
        loading: false,
      });
    }).catch((error) => {
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 404) {
          this.setState({
            loading: false,
            searchResult: null,
            errorText: error.response.data.message,
          });
        }
      } else {
        this.setState({
          loading: false,
          searchResult: null,
          errorText: 'An unspecified error occurred',
        });
      }
      console.log('catch', error);
    });
  }

  handleSubmit(event: SyntheticEvent) {
    this.searchSerial();
    event.preventDefault();
  }

  handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      searchText: event.target.value,
    });
  }

  render() {
    const pageTitle = 'Serial Number Lookup';

    const searchResult = this.state.searchResult;
    const resultMarkup = searchResult !== null ? (
      <div>
        <div style={{ alignItems: 'center' }}>
          <img
            style={{ width: 100 }}
            src={searchResult.vendorImageUrl}
            alt={searchResult.vendorName}
          />
          <div>
            <h3>{searchResult.vendorName}</h3>
          </div>
        </div>
        {licensedText(searchResult.isLicensed)}
      </div>
    ) : (
      <h3>{this.state.errorText}</h3>
    );

    const serialSearchResultArea = this.state.loading ? (
      <LoadingSpinner />
    ) : (
      <div style={{ marginTop: 10, marginBottom: 15 }}>
        {resultMarkup}
      </div>
    );

    return (
      <FullContent breadcrumbs={[{ name: pageTitle }]}>
        <DocumentTitle title={getAffinityPageTitle(pageTitle)}>
          <div className="row">
            <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
              <div className="panel panel-portal">
                <div className="panel-body">

                  <div style={{ textAlign: 'center', maxWidth: 450, margin: '0 auto 30px auto' }}>
                    <img src="/assets/images/gateway/alp-search.png" alt="" style={{ margin: '0 auto', maxWidth: 150 }} />
                    <h3 style={{ marginTop: 0, marginBottom: 20 }}>Affinity Licensed Product<br />Hologram Label Search</h3>
                    <p className="text-primary">
                      Enter a valid Affinity Licensed Product hologram label serial number.
                      Please include the prefix letters (e.g., GR, GRZ).
                    </p>
                  </div>

                  <form onSubmit={this.handleSubmit} className="form">

                    <div className="input-group">
                      <input
                        type="search"
                        value={this.state.searchText}
                        onChange={this.handleSearchChange}
                        placeholder="Serial Number"
                        className="form-control"
                      />
                      <div className="input-group-btn">
                        <input type="submit" value="Search" className="btn btn-primary" />
                      </div>
                    </div>
                  </form>

                </div>
              </div>

              <div className="panel panel-portal">
                <div className="panel-body" style={{ textAlign: 'center' }}>
                  {serialSearchResultArea}
                </div>
              </div>
            </div>
          </div>
        </DocumentTitle>
      </FullContent>
    );
  }
}

const component = withRouter(SerialSearchPage);
export { component as SerialSearchPage };
