import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { FullContent } from '../ContentFrame';

interface IState {
  hasError: boolean;
}

class NavigationErrorBoundary extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error);
  }

  componentDidMount() {
    this.props.history.listen((location: any, action: any) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return <FullContent>
        <div className="panel panel-site">
          <div className="panel-body">

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                style={{ height: 75, width: 75, marginBottom: 20 }}
                src="https://30bb6119d39f6f91289e-ed70f357adee86eb9b203fa348595c03.ssl.cf1.rackcdn.com/accounts/1/profile/sm-e621589d2dd25dd976f50bd99f1edc71-783.png"
              />
            </div>

            <p className="text-center">
              <strong>
                Uh, oh! This page has encountered an error.
              </strong>
            </p>

            <p className="text-center">
              We apologize for the inconvenience.
              Please refresh your page or try again later. If you continue to experience this issue please contact us.
            </p>
            <p className="text-center">

              <a href="mailto:help@affinitylicensing.com" className="text-center">help@affinitylicensing.com</a>
            </p>
          </div>
        </div>
      </FullContent>;
    }
    return this.props.children;
  }

}

export default withRouter(NavigationErrorBoundary);
