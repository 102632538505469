import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { Link, Redirect, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { AffinityClient, RoyaltyReportLineItem } from '../../../shared';
import { LicensorTypeahead, LoadingSpinner, PaginationFooter } from '../../shared';
import { useRoyaltyReport } from '../royalties-shared/useRoyaltyReport';
import { SalesDataTable } from './SalesDataTable';

export const RoyaltyReportSalesDataPage = () => {
  const match = useRouteMatch();
  const report = useRoyaltyReport();
  const [salesData, setSalesData] = React.useState<RoyaltyReportLineItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [invalidSalesData, setInvalidSalesData] = React.useState<RoyaltyReportLineItem[]>([]);
  const [loadingInvalid, setLoadingInvalid] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [licensor, setLicensor] = React.useState<AffinityClient[]>([]);
  const [page, setPage] = React.useState(1);
  const [paginationDetails, setPaginationDetails] = React.useState({
    totalPages: 1,
    totalResults: 0,
  });
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    getInvalidSalesData();

  },              []);

  React.useEffect(() => {
    applyFilters();

  },              [licensor]);

  React.useEffect(() => {
    getSalesData();

  },              [page]);

  const applyFilters = async () => {
    if (page !== 1) {
      await setPage(1);
    } else {
      getSalesData();
    }
  };

  if (!match) {
    return (<Redirect to="/" />);
  }

  const getSalesData = async () => {
    setLoading(true);
    const r = await Axios.get(`/api/royalty-reports/${match.params['id']}/line-items?filter=sales&page=${page}${search ? `&keyword=${search}` : ''}${licensor.length ? `&licensor=${licensor[0].id}` : ''}`);
    const l = r.data.data.map((lineItem: any) => new RoyaltyReportLineItem(lineItem));
    const pagination = { totalResults: r.data.meta.pagination.total, totalPages: r.data.meta.pagination.total_pages };
    setPaginationDetails(pagination);
    setSalesData(l);
    setLoading(false);

  };

  const getInvalidSalesData = async () => {
    setLoadingInvalid(true);
    const r = await Axios.get(`/api/royalty-reports/${match.params['id']}/line-items?filter=sales-invalid&page=${page}${search ? `&keyword=${search}` : ''}${licensor.length ? `&licensor=${licensor[0].id}` : ''}`);
    const l = r.data.data.map((lineItem: any) => new RoyaltyReportLineItem(lineItem));
    setInvalidSalesData(l);
    setLoadingInvalid(false);
  };

  if (!report.report || report.loading || loadingInvalid) {
    return <LoadingSpinner />;
  }
  const r = report.report;

  if (invalidSalesData.length) {

    return (
      <div className="panel panel-site">
        <div className="panel-body">

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{ height: 75, width: 75, marginBottom: 20 }}
              src="https://30bb6119d39f6f91289e-ed70f357adee86eb9b203fa348595c03.ssl.cf1.rackcdn.com/accounts/1/profile/sm-e621589d2dd25dd976f50bd99f1edc71-783.png"
            />
          </div>

          <p className="text-center">
            <strong>
              There is an error with processing your sales data upload.
            </strong>
          </p>

          <p className="text-center">
            We apologize for the inconvenience.
            Please contact the accounting team to resolve this issue.
          </p>
          <p className="text-center">

            <a href="mailto:accounting@affinitylicensing.com" className="text-center">accounting@affinitylicensing.com</a>
          </p>
        </div>
      </div>
    );

  }

  return (
    <div className="panel panel-portal">
      <div className="panel-body">
        <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', paddingBottom: 15 }}>
          <Link to={`${user.type === 'admin' ? `/vendors/${match.params['vendorId']}` : ''}/royalties/${match.params['id']}`} className="btn btn-primary"><FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
          <h4><strong>Sales Data Review</strong></h4>
          {r.phase.id === 1 || (r.phase.id === 3 && user.type === 'admin') ? <Link to={`${user.type === 'admin' ? `/vendors/${match.params['vendorId']}` : ''}/royalties/${match.params['id']}/submit`} className="btn btn-primary">Continue <FontAwesomeIcon icon={faArrowRight} /></Link> : <div></div>}

        </div>
        <div style={{ background: '#FCFCFC', padding: '15px 0 0', borderTop: '1px solid #eee', borderBottom: '1px solid #eee' }} className="row">
          <div className="col-md-3">
            <div className="form-group">
              <input onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  applyFilters();

                }
              }} onChange={e => setSearch(e.target.value)} value={search} placeholder="Search" type="text" className="form-control" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <LicensorTypeahead selected={licensor} onChange={setLicensor} multiple={false} />
            </div>
          </div>
        </div>
        {loading ? <LoadingSpinner /> : <SalesDataTable
          report={r}
          salesData={salesData}
          updateItem={(l, index) => {
            const items = salesData;
            items[index] = l;
            setSalesData(items);

          }}
          hideDesignPreview={false}
          showCityState={false}
        />}
        <PaginationFooter
          setPage={setPage}
          showFirstLast={true}
          totalPages={paginationDetails.totalPages}
          totalResults={paginationDetails.totalResults}
          currentPage={page}
          hasNext={page < paginationDetails.totalPages}
        />

      </div>

    </div>

  );

};
