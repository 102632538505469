export interface MarketplaceOrderLineItemTrackingApiResponse {
  carrier: {
    id: number;
    name: string;
  };
  tracking_code: string;
  url: string;
}

export class MarketplaceOrderLineItemTracking {
  carrier: {
    id: number;
    name: string;
  };
  trackingCode: string;
  url: string;

  static fromApi(data: MarketplaceOrderLineItemTrackingApiResponse) {
    const tracking = new MarketplaceOrderLineItemTracking();
    tracking.carrier = {
      id: data.carrier.id,
      name: data.carrier.name,
    };
    tracking.trackingCode = data.tracking_code;
    tracking.url = data.url;
    return tracking;
  }
}
