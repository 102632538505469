import { faArrowLeft, faCaretDown, faCaretRight, faClipboard, faDownload, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as moment from 'moment';
import * as React from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { AffinityImage, ReportProcessingStatus, RoyaltyReport } from '../../../shared';
import { getSalesDateRange } from '../../../utils';
import { CurrencyLabel, GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { useRoyaltyReport } from '../royalties-shared/useRoyaltyReport';

interface ITableProps {
  rows: any[];
  showAggregateDownload: boolean;
}

export const ReportDataTable = (props: ITableProps) => {
  const report = useRoyaltyReport();
  const overlay = React.useRef<any>(null);

  const items = props.rows.map(r => r.items).reduce((p: any[], n: any[]) => p.concat(n), []);

  const totalSales = items.map((i: any) => Number(i.sales)).reduce((p: number, n: number) => p + n, 0);
  const totalUnits = items.map((i: any) => Number(i.quantity)).reduce((p: number, n: number) => p + n, 0);
  const totalRoyalties = items.map((i: any) => Number(i.amount_due)).reduce((p: number, n: number) => p + n, 0);

  const totalRowsWithAggregateFiles = props.rows.reduce((p: any, n: any) => {
    if (n.licensor.aggregate_file_id) {
      p.push(n);
    }
    return p;

  },                                                    []);

  const copyTable = () => {
    let table =  '<table className="table">';
    table +=  `
      <tr>
        <th>Licensor</th>
        <th>Sales</th>
        <th>Units</th>
        <th>Royalties</th>
      </tr>
    `;
    props.rows.forEach((row) => {
      const totalSales = row.items.map((i: any) => Number(i.sales)).reduce((p: number, n: number) => p + n, 0);
      const totalUnits = row.items.map((i: any) => Number(i.quantity)).reduce((p: number, n: number) => p + n, 0);
      const totalRoyalties = row.items.map((i: any) => Number(i.amount_due)).reduce((p: number, n: number) => p + n, 0);
      table += `
        <tr>
          <td width="200px">${row.licensor.licensor_name}</td>
          <td>$${totalSales.toFixed(2)}</td>
          <td>${totalUnits}</td>
          <td>$${totalRoyalties.toFixed(2)}</td>
        </tr>
      `;

    });
    table += '</table>';
    const blob = new Blob([table], { type: 'text/html' });
    navigator.clipboard.write([new ClipboardItem({ [blob.type]: blob })]);
    if (overlay.current) {
      console.log(overlay.current);
      setTimeout(() => {
        overlay.current.hide();

      },         1500);
    }

  };

  const copyToolTip = <Tooltip id="table-copied" placement="top">Table Copied</Tooltip>;

  return (
    <div>
      <div className="report-overview-header text-muted">
        <div className="report-row-title">Report Overview <OverlayTrigger ref={ r => overlay.current = r} rootClose placement="top" trigger="click" overlay={copyToolTip}>
          <span style={{ paddingLeft: 20, paddingRight: 20 }} onClick={copyTable}><FontAwesomeIcon icon={faClipboard} /></span>
          </OverlayTrigger></div>
        <div className="report-row-sales">Sales</div>
        <div className="report-row-units">Units</div>
        <div className="report-row-royalties">Royalties</div>
        {props.showAggregateDownload && totalRowsWithAggregateFiles.length ? <div className="report-row-download"></div> : null}
      </div>
      {props.rows.map(r => <ExpandableReportRow report={report.report} showAggregateDownload={props.showAggregateDownload && totalRowsWithAggregateFiles.length} row={r} />)}
      <div className="report-overview-footer text-muted">
        <div className="report-row-title">Totals</div>
        <div className="report-row-sales text-muted"><CurrencyLabel value={totalSales} /></div>
        <div className="report-row-units text-muted">{totalUnits !== 0 ? <NumberFormat displayType="text" value={totalUnits} thousandSeparator={true} /> : '-'}</div>
        <div className="report-row-royalties"><CurrencyLabel value={totalRoyalties} /></div>
        {props.showAggregateDownload && totalRowsWithAggregateFiles.length ? <div className="report-row-download"></div> : null}
      </div>
    </div>
  );
};

interface IRowProp {
  row: any;
  report: RoyaltyReport | null;
  showAggregateDownload: boolean;

}

const ExpandableReportRow = (props: IRowProp) => {
  const [expanded, setExpanded] = React.useState(false);

  const row = props.row;

  const totalSales = row.items.map((i: any) => Number(i.sales)).reduce((p: number, n: number) => p + n, 0);
  const totalUnits = row.items.map((i: any) => Number(i.quantity)).reduce((p: number, n: number) => p + n, 0);
  const totalRoyalties = row.items.map((i: any) => Number(i.amount_due)).reduce((p: number, n: number) => p + n, 0);

  const icon = !expanded ? <span style={{ marginLeft: 5 }}><FontAwesomeIcon icon={faCaretRight} /></span> : <span style={{ marginLeft: 5 }}><FontAwesomeIcon icon={faCaretDown} /></span>;

  const scheduleLabel = (schedule: any) => {
    let label = '';
    if (schedule.royalty_rate) {
      label = `${schedule.royalty_rate}%`;
    }
    if (parseFloat(schedule.flat_royalty_per_unit)) {
      label = `${label}${label.length ? ', ' : ''}$${schedule.flat_royalty_per_unit}/unit`;
    }
    if (parseFloat(schedule.minimum_royalty_per_unit)) {
      label = `${label}${label.length ? ', ' : ''}$${schedule.minimum_royalty_per_unit} MRU`;
    }
    return label;

  };

  return (
        <div>
            <div className="report-row" style={{ display: 'flex', alignItems: 'center' }}>
                <div className="report-row-image">
                  <img className="img-responsive" src={row.licensor.image.getSize('sm')} />
                </div>
                <div className="report-row-title" onClick={() => setExpanded(!expanded)}>{row.licensor.licensor_name} {icon}</div>
                <div className="report-row-sales"><CurrencyLabel value={totalSales} /></div>
                <div className="report-row-units">{totalUnits ? <NumberFormat displayType="text" value={totalUnits} thousandSeparator={true} /> : '-'}</div>
                <div className="report-row-royalties"><CurrencyLabel value={totalRoyalties} /></div>
                {props.showAggregateDownload ? <div className="report-row-download">
                  {row.licensor.aggregate_file_id ? <a href={`/api/royalty-reports/${row.licensor.royalty_report_id}/aggregates/uploads/${row.licensor.account_id}`} target="_blank">
                  <FontAwesomeIcon className="text-primary" icon={faDownload} />
                  </a> : null}
                  </div> : null}

            </div>

            {expanded ?
                <div className="report-row-collapse" >
                    {props.row.items.filter((i: any) => {
                      if (props.report) {
                        const salesEndingDate = moment(i.royalty_schedule.rate_sales_ending_date);
                        const startDate = getSalesDateRange(props.report.quarter, props.report.year).start;
                        if (!parseFloat(i.sales) && !parseFloat(i.quantity) && salesEndingDate.isBefore(startDate)) {
                          return false;
                        }
                      }

                      return true;
                    }).map((i: any) => <>
                    <div className="report-row text-muted">
                        <div className="report-row-image"></div>
                        <div className="report-row-title">
                          <span className="row-title">{i.royalty_schedule.title}</span>
                          <span className="row-schedule-details text-muted">{scheduleLabel(i.royalty_schedule)}</span>
                        </div>
                        <div className="report-row-sales text-muted"><CurrencyLabel value={i.sales} /></div>
                        <div className="report-row-units text-muted">{Number(i.quantity) ? <NumberFormat displayType="text" value={i.quantity} thousandSeparator={true} /> : '-'}</div>
                        <div className="report-row-royalties"><CurrencyLabel value={i.amount_due} /></div>
                        {props.showAggregateDownload ? <div className="report-row-download"></div> : null}
                      </div>
                    </>)}

                </div>
            : null}

        </div>

  );

};

export const RoyaltyReportSubmissionPage = () => {
  const match = useRouteMatch();
  const report = useRoyaltyReport();
  const history = useHistory();
  const user = React.useContext(UserContext);
  const [rows, setRows] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [complianceInfo, setComplianceInfo] = React.useState(false);
  const [processingStatus, setProcessingStatus] = React.useState<ReportProcessingStatus>(new ReportProcessingStatus());

  React.useEffect(() => {
    getRollup();
    getProcessingStatus();

  },              []);

  if (!match) {
    return (<Redirect to="/" />);
  }

  const submit = async () => {
    if (!submitting) {
      setSubmitting(true);
      const r = await Axios.patch(`/api/royalty-reports/${report.reportId}`, { submitted: true, pay_invoice: false });
      if (report.report) {
        const hasPayments = report.report.invoice && report.report.invoice.payments && report.report.invoice.payments.length > 0;
        const invoiceId = hasPayments ? undefined : r.data.data.invoice_id;
        redirect(invoiceId);
      }
      report.update(true);
      setSubmitting(false);
    }

  };

  const redirect = (invoiceId?: number) => {
    const r = `${user.type === 'admin' ? `/vendors/${match.params['vendorId']}` : ''}${invoiceId ? `/invoices/${invoiceId}` : `/royalties/${report.reportId}?showResubmission=1`}`;
    history.replace(r);
  };

  const getRollup = async () => {
    setLoading(true);
    const r = await Axios.get(`/api/royalty-reports/${report.reportId}/rollups?type=schedule`);
    const rollups = r.data.data.map((d: any) => {
      if (d.licensor.image) {
        d.licensor.image = AffinityImage.fromApi(d.licensor.image);
      } else {
        d.licensor.image = new AffinityImage();
      }
      return d;
    });
    setRows(rollups);
    setLoading(false);
  };
  const getProcessingStatus = async () => {
    const d = await Axios.get(`/api/royalty-reports/${report.reportId}/upload-status`);
    const data = d.data.data;
    setProcessingStatus(ReportProcessingStatus.fromApi(data));

  };

  if (loading || !report.report) {
    return <LoadingSpinner />;
  }

  const r = report.report;

  const items = rows.map(r => r.items).reduce((p: any[], n: any[]) => p.concat(n), []);

  const totalSales = items.map((i: any) => Number(i.sales)).reduce((p: number, n: number) => p + n, 0);
  const totalUnits = items.map((i: any) => Number(i.quantity)).reduce((p: number, n: number) => p + n, 0);
  const totalRoyalties = items.map((i: any) => Number(i.amount_due)).reduce((p: number, n: number) => p + n, 0);

  const hasPartialPayments = r.invoice ? Number(r.totalDue) !== r.invoice.amountDue : false;
  const submissionButton = `${r.invoice ? 'Resubmit' : 'Submit'} Report ${!hasPartialPayments ? 'and View Invoice' : ''}`;

  const submissionContainer = (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', lineHeight: 2.2 }}>

      {r.invoice && hasPartialPayments ? <p className="text-center">By selecting Resubmit Report, you are confirming that the report is complete and accurate.
        Resubmit Report will complete the resubmission process. If the resubmission requires any changes to the royalty invoice, a member of our team will contact you with the revised invoice.</p> : null}
      {r.invoice && !hasPartialPayments ? <p className="text-center" >
        By selecting “Resubmit Report and View Invoice”, you are confirming that the report is complete and accurate. This will complete the resubmission process and take you to the revised version of the royalty invoice.
        <br />
        A member of our team will review your report and contact you if there are any questions.
      </p> : null}
      {!r.invoice ? <p className="text-center">
        By selecting “Submit Report and View Invoice”, you are confirming that the report is complete and accurate.
        <br />
        This will complete report submission and take you to the royalty invoice to view and pay.
        <br />
        A member of our team will review your report and contact you if there are any questions.

      </p> : null}

      <button style={{ marginTop: 20, marginBottom: 20 }} onClick={submit} className="btn btn-lg btn-primary">{ submitting ? 'Submitting...' : submissionButton }</button>
    </div>

  );

  return (
    <div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
            <Link to={`${user.type === 'admin' ? `/vendors/${match.params['vendorId']}` : ''}/royalties/${match.params['id']}`} className="btn btn-primary"><FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
            <h4><strong>Review and Submit</strong></h4>
            <div></div>
            {/* <button onClick={submit} className="btn btn-primary">{submissionButton}</button> */}
          </div>
          <div style={{ backgroundColor: '#fcfcfc', marginLeft: -15, marginRight: -15, padding: 15, borderTop: '1px solid #eee', borderBottom: '1px solid #eee', marginBottom: 30 }}>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className="text-muted" style={{ width: 150, fontWeight: 'bold', textAlign: 'center' }}>Licensors</div>
              <div className="text-muted" style={{ width: 150, textAlign: 'center', fontWeight: 'bold' }}>Sales</div>
              <div className="text-muted" style={{ width: 150, textAlign: 'center', fontWeight: 'bold' }}>Units</div>
              <div className="text-muted" style={{ width: 200, textAlign: 'center', fontWeight: 'bold' }}>Royalties</div>
              {r.dueDate.isSameOrAfter('4/30/2024') ?
                <div className="text-muted" style={{ width: 200, textAlign: 'center', fontWeight: 'bold' }}>
                  Compliance Fees <span style={{ cursor: 'pointer' }} onClick={() => setComplianceInfo(true)} className="text-muted"><FontAwesomeIcon icon={faInfoCircle} /></span>
                </div> : null}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ width: 150, fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}>{rows.length}</div>
              <div style={{ width: 150, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}><CurrencyLabel value={totalSales} /></div>
              <div style={{ width: 150, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}>{totalUnits !== 0 ? <NumberFormat displayType="text" value={totalUnits} thousandSeparator={true} /> : '-'}</div>
              <div style={{ width: 200, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}><CurrencyLabel value={totalRoyalties} /></div>
              {r.dueDate.isSameOrAfter('4/30/2024') ? <div style={{ width: 200, textAlign: 'center', fontWeight: 'bold', fontSize: 20 }}><CurrencyLabel value={r.feeTotal} /></div> : null}
            </div>

          </div>
          {submissionContainer}
        </div>
      </div>
      <div className="panel panel-portal">
        <ReportDataTable showAggregateDownload={false} rows={rows} />
      </div>
      <div className="panel panel-portal">
        <div className="panel-body">

          {submissionContainer}
        </div>

      </div>
      <GatewayModal
        shown={complianceInfo}
        onClose={() => setComplianceInfo(false)}
        type={ModalType.Primary}
        title="Compliance Fees"
      >
        <Modal.Body>
          <p>The specific transactions associated with these fees can be identified by downloading the compliance review CSV on the initial page of this report.</p>
          <div style={{ paddingLeft: 40, paddingRight: 40, marginTop: 20 }}>
            <h4><strong><CurrencyLabel value={r.feeTotal} /> total compliance fees</strong></h4>
            <hr />
            {processingStatus.warningIssues.length ? (
              <div style={{ marginTop: 20 }}>
                <div style={{ marginTop: 20 }}>
                  {processingStatus.rejectedDesignIssues.length ? (<div style={{ marginTop: 10 }}>
                    <strong><CurrencyLabel value={processingStatus.rejectedDesignIssuesFee} /> Rejected design fees</strong>
                    <p className="text-muted no-margin">
                      <NumberFormat thousandSeparator={true} displayType="text" value={processingStatus.rejectedDesignIssues[0].count} /> transaction(s)
                    </p>
                  </div>) : null}
                  {processingStatus.doubleRoyaltyIssues.length ? (<div style={{ marginTop: 10 }}>
                    <strong><CurrencyLabel value={processingStatus.doubleRoyaltyIssuesFee} /> UPI compliance fees</strong>
                    <p className="text-muted no-margin">
                      <NumberFormat thousandSeparator={true} displayType="text" value={processingStatus.doubleRoyaltyIssues.reduce((p, n) => p + n.count , 0)} /> transaction(s)
                    </p>
                    {processingStatus.doubleRoyaltyIssues.map(i => (<p className="text-muted no-margin small">
                      <NumberFormat thousandSeparator={true} displayType="text" value={i.count} /> {i.issue.name}
                    </p>))}

                  </div>) : null}

                </div>
              </div>
            ) : null}
          </div>
          <p style={{ marginTop: 20 }}>
            After the report is submitted, a separate invoice will be generated for compliance fees.
            If you are resubmitting a report, your compliance fee invoice will be adjusted to reflect the updated totals.
            If you have previously paid compliance fees for this report a member of our team will contact you with a revised invoice.
          </p>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setComplianceInfo(false)} className="btn btn-default pull-right">Close</button>

        </Modal.Footer>
      </GatewayModal>
    </div>
  );
};
