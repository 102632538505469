import * as React from 'react';
import { RoyaltyReportAction } from '../../../shared/RoyaltyReportAction';

interface IProps {
  action: RoyaltyReportAction;
}

const getLabelClass = (phaseId: number): string => {

  // Vendor Review
  if (phaseId === 1) {
    return 'label-info';
  }

  // Admin Review
  if (phaseId === 3) {
    return 'label-primary';
  }

  // Reviewed
  if (phaseId === 14) {
    return 'label-success';
  }

  return '';
};

const getLabel = (phaseModel: any) => {
  const labelClass = getLabelClass(phaseModel.id);
  const classes = `phase-label label ${labelClass}`;
  return (
    <div className={classes}>{phaseModel.phase}</div>
  );
};

const ReportActionLogItem = (props: IProps) => {

  const action = props.action;
  const actionAt = action.actionAt;
  const user = action.user;
  const phaseModel = action.phase;
  const message = action.message;

  const messageStyle = {
    fontSize: '85%',
    paddingTop: 10,
  };
  const messageSection = message.length > 0 ? (
    <div style={messageStyle}>
      {message}
    </div>
  ) : (
    null
  );

  const dateTimeCss = {
    color: '#999999',
    fontSize: '85%',
    marginTop: 10,
  };

  const label = phaseModel ? getLabel(phaseModel) : null;
  const userName = user ? `${user.firstName} ${user.lastName}` : 'System';

  return (
    <div style={{ padding: 10 }} className="panel panel-portal" key={action.id}>
      <div style={{ fontWeight: 'bold' }}>{userName}</div>
      {label}
      {messageSection}
      <div style={dateTimeCss}>{actionAt.format('dddd, MMMM Do YYYY, h:mm:ss a')}</div>
    </div>
  );
};

export default ReportActionLogItem;
