import * as React from 'react';
import { BrandMarkAsset } from '../../shared';
import { File } from '../../shared/File';

interface IProps {
  asset: BrandMarkAsset;
}

function getImgSrc(file: File | null, previewImage: File | null) {
  if (previewImage) {
    return previewImage.url;
  }

  if (file && file.url.match(/\.(jpg|jpeg|gif|png|bmp)$/)) {
    return file.url;
  }

  return null;
}

export class BrandListItemThumbnail extends React.Component<IProps, any> {
  render() {

    const asset = this.props.asset;
    const previewImage = asset.previewFile;
    const file = asset.file;

    const imageUrl = getImgSrc(file, previewImage);

    if (imageUrl) {
      return <img className="pull-left hidden-sm hidden-xs" src={imageUrl} />;
    }

    return null;
  }
}
