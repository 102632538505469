import { faAt, faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import linkifyHtml  from 'linkifyjs/html';
import * as moment from 'moment';
import { File } from '../File';
import User, { UserApiResponse } from '../User';
import { NoteMethod } from './NoteMethod';

export interface NoteApiResponse {
  id: number;
  created_at: string;
  updated_at: string;
  occurred_at: string;
  subject: string;
  note: string;
  note_method_descriptor: string;
  attachments?: any[];
  method: { id: number, name: string};
  tagged_users?: UserApiResponse[];
  contact_origin: number;
}
export class Note {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  occurredAt: moment.Moment;
  subject: string;
  note: string;
  attachments: File[] = [];
  contactMethod: NoteMethod;
  contactOrigin: number;
  users: User[] = [];

  static fromApi(data: NoteApiResponse) {
    const n = new Note();
    n.id = data.id;
    n.createdAt = moment.utc(data.created_at).local();
    n.updatedAt = moment(data.updated_at);
    n.occurredAt = moment(data.occurred_at);
    n.subject = data.subject;
    n.note = data.note;
    n.contactOrigin = data.contact_origin;
    if (data.attachments) {
      n.attachments = data.attachments.map(a => new File(a));
    }
    if (data.tagged_users) {
      n.users = data.tagged_users.map(u => User.fromApi(u));
    }
    if (data.method) {
      n.contactMethod = NoteMethod.fromApi(data.method);
    }

    return n;
  }

  get adminUsers() {
    return this.users.filter(u => u.type === 'admin');
  }

  get accountUsers() {
    return this.users.filter(u => u.type !== 'admin');
  }

  get noteIcon() {
    if (!this.contactMethod) {
      return null;
    }
    switch (this.contactMethod.id) {
      case 1:
        return faPhone;
      case 2:
        return faAt;
      case 3:
        return faEnvelope;
      default:
        return null;
    }
  }

  get linkify() {
    return linkifyHtml(this.note);
  }

}
