import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../../shared';

interface IProps {
  shown: boolean;
  onClose: () => void;
}

export class AboutModal extends React.Component<IProps, any> {

  render() {
    // tslint:disable-next-line:max-line-length
    const p1 = 'Royalty analytics will display as much detail as possible, but not all of your vendors report in the necessary format for detailed category and channel segment reports.';
    // tslint:disable-next-line:max-line-length
    const p2 = 'Filtering to a particular quarter, year, and/or vendor segment will display accurate royalties and totals, but reports filtered to specific product categories and distribution channels will only include sales from vendors who reported with the sales data reporting method. We\'re working hard to make the sales data reporting method the primary reporting method used by all vendors.';

    return (
      <GatewayModal
        type={ModalType.Primary}
        title={'About Royalty Analytics'}
        shown={this.props.shown}
        onClose={this.props.onClose}
      >
        <Modal.Body>
          <p>
            <strong>
              {p1}
            </strong>
          </p>
          <p className="text-primary">
            {p2}
          </p>
          <p>
            Please contact us if you have any questions!
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.props.onClose} className="btn btn-default">
            Close

          </button>
        </Modal.Footer>
      </GatewayModal>

    );

  }

}
