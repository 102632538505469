import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { RoyaltyAnalyticsPage } from './RoyaltyAnalytics';
import { RoyaltyReportsIndexPage } from './RoyaltyReportsIndexPage';

export class RoyaltiesIndex extends React.Component<any, any> {

  render() {
    return (
      <UserContext.Consumer>
        { (user) => {
          if (user.scope && !user.scope.includes('royalties')) {
            return <Redirect to="/" />;
          }

          if (user.type === 'client') {
            return (<RoyaltyAnalyticsPage { ...this.props} />);
          }

          return <RoyaltyReportsIndexPage user={user} { ... this.props} />;

        }}

        </UserContext.Consumer>
    );

  }

}
