import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { updateAccount } from '../../../api';
import { AccountProfile, AccountProfileUpdate } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { closeUpdateAccountRequest, getCurrentAccountUpdate, getNewAccount } from '../api';
import { AccountFormValues, AccountProfileForm } from './AccountProfileForm';

interface IState {
  account: AccountProfile | null;
  updateRequest: AccountProfileUpdate | null;
  saving: boolean;
  error: string;
  confirmShown: boolean;
  updatedValues: AccountFormValues | null;
}

export class EditAccount extends React.Component<RouteComponentProps<any>, IState> {

  _getAccountCancelToken:CancelTokenSource;
  _getUpdateRequestCancelToken: CancelTokenSource;
  _saveAccountCancelToken:CancelTokenSource;
  _endAccountUpdateCancelToken: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      account: null,
      updateRequest: null,
      saving: false,
      error: '',
      confirmShown: false,
      updatedValues: null,
    };
    this.getAccount = this.getAccount.bind(this);
    this.saveAccount = this.saveAccount.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
    this.hideConfirmModal = this.hideConfirmModal.bind(this);
  }

  componentDidMount() {
    this.getAccount();
  }

  showConfirmModal(values: AccountFormValues) {
    this.setState({ confirmShown: true, updatedValues: values });
  }

  hideConfirmModal() {
    this.setState({ confirmShown: false });
  }

  render() {
    if (this.state.account) {
      const account = this.state.account;

      const updateRequest = this.state.updateRequest ? this.state.updateRequest : undefined;

      return (
        <FullContent>
          <div className="panel panel-portal">
            <div className="panel-body">
              <h3><strong>Edit Profile</strong></h3>
              <hr />
            {this.state.error ? (
              <div className="alert alert-danger">
                {this.state.error}
              </div>
            ) : null }
             <AccountProfileForm
              account={account}
              onSubmit={this.showConfirmModal}
              saving={this.state.saving}
              isUpdateRequest={false}
              updateRequest={updateRequest}
             />
              <GatewayModal
                onClose={this.hideConfirmModal}
                shown={this.state.confirmShown}
                title="Confirm Account Update"
                type={ModalType.Primary}
              >
               <Modal.Body>
                 <p className="text-center">
                   Are you sure that you want to save these changes? This will close all current update requests.
                 </p>
               </Modal.Body>
               <Modal.Footer>
                 <button onClick={this.hideConfirmModal} className="btn btn-default pull-left">Cancel</button>
                 <button onClick={this.saveAccount} className="btn btn-primary pull-right">Yes, Save Changes</button>

               </Modal.Footer>

             </GatewayModal>

            </div>
          </div>
        </FullContent>
      );

    }
    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>
      </FullContent>
    );

  }

  getAccount() {
    this._getAccountCancelToken = Axios.CancelToken.source();
    this._getUpdateRequestCancelToken = Axios.CancelToken.source();

    const getAccount = getNewAccount(this._getAccountCancelToken, this.accountID);
    const getUpdateRequest = getCurrentAccountUpdate(this._getUpdateRequestCancelToken, this.accountID);
    Axios.all([getAccount, getUpdateRequest])
      .then(([accountResponse, updateResponse]) => {
        let updateRequest;
        if (updateResponse.data && updateResponse.data.data) {
          updateRequest = AccountProfileUpdate.fromApi(updateResponse.data.data);
        } else {
          updateRequest = null;
        }
        this.setState({ updateRequest, account: AccountProfile.fromApi(accountResponse.data.data) });
      });
  }

  saveAccount() {
    const values = this.state.updatedValues;
    if (values) {
      this.setState({ saving: true });
      this.hideConfirmModal();
      const formData = AccountProfile.formValuesToFormData(values);
      if (values.logo) {
        formData.append('image', values.logo);
      }

      this._saveAccountCancelToken = Axios.CancelToken.source();
      this._endAccountUpdateCancelToken = Axios.CancelToken.source();

      const accountUpdate = updateAccount(this.accountID, formData, this._saveAccountCancelToken);
      const closeUpdateRequest = this.state.updateRequest ?
        closeUpdateAccountRequest(this.state.updateRequest.id, this._endAccountUpdateCancelToken) : null;

      Axios.all([accountUpdate, closeUpdateRequest])
        .then(([accountUpdateResponse, closeUpdateResponse]) => {

          this.props.history.push(this.backRoute);
          this.setState({ saving: false, updateRequest: null, error: '' }); // should always close so we hide this
        }).catch(error => this.setState({ saving: false, error: 'There was an error updating the profile.' }));

    }

  }

  get accountID() {
    if (this.props.match.params['vendorId']) {
      return this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      return this.props.match.params['licensorId'];
    }
    return '';
  }

  get backRoute() {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.props.match.params['vendorId']}/settings`;
    }
    if (this.props.match.params['licensorId']) {
      return `/clients/${this.props.match.params['licensorId']}/settings`;
    }
    return '/';
  }
}
