import * as React from 'react';
import { Link } from 'react-router-dom';
import { DateCell } from '../shared/tables';
import { DesignResponse } from './DesignResponse';

interface IProps {
  item: DesignResponse;
  showModal: (values: any) => any;
  showDeleteModal: (response: DesignResponse) => any;
  showLicensor: boolean;
}

export class DesignResponseListItem extends React.PureComponent<IProps, any> {

  render() {
    return (
      <tr >
        { this.props.showLicensor ? <td>
          <Link
            className="load-account"
            style={{ zIndex: 2 }}
            to={`/clients/${this.props.item.account.id}/design-responses`}
          >
            <img className="pull-left hidden-sm hidden-xs" src={this.props.item.account.image.th} />
            <strong>{this.props.item.account.name}</strong>
          </Link>
          </td> : <></> }
        <td style={{ cursor: 'pointer' }} onClick={() => this.props.showModal(this.props.item)}>
          <span>
            {this.props.item.title}
          </span>
        </td>
        <td style={{ width: 115, padding: 0 }}>
          <DateCell date={this.props.item.updatedAt}/>
        </td>
        <td style={{ width: 50 }}>
          <button
            onClick={() => this.props.showDeleteModal(this.props.item)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>

        </td>

      </tr>
    );
  }

}
