import * as React from 'react';
import DocumentTitle from 'react-document-title';
import Media from 'react-media';
import { Redirect, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { getAffinityPageTitle } from '../../utils';
import { AccountStatusPanel } from '../shared';
// import { MassUploadButton, MassUploadStatus, MassUploadStatusAlert } from './MassUploadButton';
import { NewNoteThreadPage } from './NewNoteThreadPage';
import { NotePlaceholder } from './NotePlaceholder';
import { NotesDetail } from './NotesDetail';
import { NotesList } from './NotesList';

export const NotesContainer = () => {
  const match = useRouteMatch();
  const user = React.useContext(UserContext);
  if (!match || user.type !== 'admin') {
    return (<Redirect to="/" />);
  }
  // const [massUploadStatus, setMassUploadStatus] = React.useState<MassUploadStatus>({ state: 'unsubmitted' });

  return (
    <div style={{ height: '100%' }} className="content-frame-padding">
      <style dangerouslySetInnerHTML={{__html:
        `
          .main-content {
            padding-bottom: 25px !important;
          }
        `,
      }} />

      <DocumentTitle title={getAffinityPageTitle('Notes')} />
      <div className="notes-container">
        <AccountStatusPanel />

        <div style={{ overflow: 'hidden' }} className="row">
          <Media query={{ minWidth: 992 }}>
            {matches => matches ?
              <div style={{ overflowY: 'hidden', overflowX: 'hidden', height: '100%', paddingTop: 5 }} className="col-md-5 hidden-sm hidden-xs">
                <NotesList />
              </div>
              : null}
          </Media>

          <Media query={{ minWidth: 992 }}>
            {matches => matches ?
              <div style={{ height: '100%' }} className="col-md-7 hidden-sm hidden-xs">
                {/* <MassUploadStatusAlert status={massUploadStatus} /> */}
                <Switch>
                  <Route exact={true} path={match.path}>
                    <NotePlaceholder />
                  </Route>
                  <Route path={`${match.path}/new`} component={NewNoteThreadPage} />
                  <Route path={`${match.path}/:noteId`} component={NotesDetail} />
                </Switch>

              </div>

              : null}
          </Media>
          <Media query={{ maxWidth: 991 }}>
            {matches => matches ?
              <div style={{ height: '100%', paddingTop: 5 }} className="col-sm-12 visible-sm visible-xs">
                <Switch>
                  <Route exact={true} path={match.path}>
                    <NotesList />
                  </Route>
                  <Route path={`${match.path}/new`} component={NewNoteThreadPage} />
                  <Route path={`${match.path}/:noteId`} component={NotesDetail} />
                </Switch>

              </div>

              : null}
          </Media>
        </div>

      </div>

    </div>
  );
};
