import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { AffinityClient } from '../../../shared';
import { GatewayModal, ModalType } from '../../shared';

interface IProps {
  onClose: () => any;
  shown: boolean;
  addLicensor: (licensor: AffinityClient) => void;
  licensors: AffinityClient[];
  currentReportLicensors: AffinityClient[];
  loading: boolean;
}

interface IState {
  licensor: number;
}

export class AddAggregateReportModal extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      licensor: 0,
    };
    this.addLicensor = this.addLicensor.bind(this);
  }

  addLicensor() {
    const licensor = this.props.licensors.find(l => Number(l.id) === this.state.licensor);
    if (licensor) {
      this.props.addLicensor(licensor);
    }

  }

  render() {

    const licensors = this.props.licensors.filter(licensor =>
      this.props.currentReportLicensors.findIndex(l => l.id === licensor.id) === -1)
        .map(licensor =>
          <option key={licensor.id} value={licensor.id}>{licensor.name}</option>,
        );
    let modalBody;

    if (this.props.loading) {
      modalBody = (
        <div className="react-loading-content">
          <span><FontAwesomeIcon icon={faSpinner} spin /></span>
        </div>

      );

    } else {
      modalBody =  (
        <div>
          <Modal.Body>
            <form>
              <div className="form-group">
                <label>Affinity Licensor</label>
                <select
                  value={this.state.licensor}
                  onChange={event => this.setState({ licensor: Number(event.target.value) })} className="form-control"
                >
                  <option>Select Affinity Licensor</option>
                  {licensors}
                </select>
                <p className="help-block">You can only report aggregate sales for licensors that allow it.</p>
              </div>

            </form>

          </Modal.Body>

          <Modal.Footer>
            <button onClick={this.props.onClose} className="btn btn-default pull-left">Cancel</button>
            <button onClick={this.addLicensor} className="btn btn-primary">Add Licensor</button>
          </Modal.Footer>
        </div>
      );

    }

    return (
      <GatewayModal
        type={ModalType.Primary}
        onClose={this.props.onClose}
        title="Add Missing Licensor"
        shown={this.props.shown}
      >
          {modalBody}
      </GatewayModal>
    );
  }

}
