// A simple list of states.  We may want to uncomment some of the extras
export const states = [
  { state: 'Alabama', shortName: 'AL', lat: 32.318230, lng: -86.902298 },
  { state: 'Alaska', shortName: 'AK', lat: 66.160507, lng: -153.369141 },
  // { 'state': 'American Samoa', 'shortName': 'AS' },
  { state: 'Arizona', shortName: 'AZ', lat: 34.048927, lng: -111.093735 },
  { state: 'Arkansas', shortName: 'AR', lat: 34.799999, lng: -92.199997 },
  { state: 'California', shortName: 'CA', lat: 36.778259, lng: 	-119.417931 },
  { state: 'Colorado', shortName: 'CO', lat: 39.113014, lng: -105.358887 },
  { state: 'Connecticut', shortName: 'CT', lat: 41.599998, lng: -72.699997 },
  { state: 'Delaware', shortName: 'DE', lat: 39.000000, lng: -75.500000 },
  { state: 'District Of Columbia', shortName: 'DC', lat: 1, lng: 1 },
  // { 'state': 'Federated States Of Micronesia', 'shortName': 'FM' },
  { state: 'Florida', shortName: 'FL', lat: 27.994402, lng: -81.760254 },
  { state: 'Georgia', shortName: 'GA', lat: 33.247875, lng: -83.441162 },
  // { 'state': 'Guam', 'shortName': 'GU' },
  { state: 'Hawaii', shortName: 'HI', lat: 19.741755, lng: -155.844437 },
  { state: 'Idaho', shortName: 'ID', lat: 44.068203, lng: -114.742043 },
  { state: 'Illinois', shortName: 'IL', lat: 40.000000, lng: -89.000000 },
  { state: 'Indiana', shortName: 'IN', lat: 40.273502, lng: -86.126976 },
  { state: 'Iowa', shortName: 'IA', lat: 42.032974, lng: -93.581543 },
  { state: 'Kansas', shortName: 'KS', lat: 38.500000, lng: -98.000000 },
  { state: 'Kentucky', shortName: 'KY', lat: 37.839333, lng: -84.270020 },
  { state: 'Louisiana', shortName: 'LA', lat: 30.391830, lng: -92.329102 },
  { state: 'Maine', shortName: 'ME', lat: 45.367584, lng: -68.972168 },
  // { 'state': 'Marshall Islands', 'shortName': 'MH' },
  { state: 'Maryland', shortName: 'MD', lat: 39.045753, lng: -76.641273 },
  { state: 'Massachusetts', shortName: 'MA', lat: 42.407211, lng: -71.382439 },
  { state: 'Michigan', shortName: 'MI', lat: 44.182205, lng: -84.506836 },
  { state: 'Minnesota', shortName: 'MN', lat: 46.392410, lng: -94.636230 },
  { state: 'Mississippi', shortName: 'MS', lat: 33.000000, lng:	-90.000000 },
  { state: 'Missouri', shortName: 'MO', lat: 38.573936, lng: -92.603760 },
  { state: 'Montana', shortName: 'MT', lat: 46.965260, lng: -109.533691 },
  { state: 'Nebraska', shortName: 'NE', lat: 41.500000, lng: -100.000000 },
  { state: 'Nevada', shortName: 'NV', lat: 39.876019, lng: -117.224121 },
  { state: 'New Hampshire', shortName: 'NH', lat: 44.000000, lng: -71.500000 },
  { state: 'New Jersey', shortName: 'NJ', lat: 39.833851, lng: -74.871826 },
  { state: 'New Mexico', shortName: 'NM', lat: 34.307144, lng: -106.018066 },
  { state: 'New York', shortName: 'NY', lat: 43.000000, lng: -75.000000 },
  { state: 'North Carolina', shortName: 'NC', lat: 35.782169, lng: 	-80.793457 },
  { state: 'North Dakota', shortName: 'ND', lat: 47.650589, lng: -100.437012 },
  // { 'state': 'Northern Mariana Islands', 'shortName': 'MP' },
  { state: 'Ohio', shortName: 'OH', lat: 40.367474, lng: -82.996216 },
  { state: 'Oklahoma', shortName: 'OK', lat: 36.084621, lng: -96.921387  },
  { state: 'Oregon', shortName: 'OR', lat: 44.000000, lng: -120.500000 },
  // { 'state': 'Palau', 'shortName': 'PW' },
  { state: 'Pennsylvania', shortName: 'PA', lat: 41.203323, lng: -77.194527 },
  // { 'state': 'Puerto Rico', 'shortName': 'PR' },
  { state: 'Rhode Island', shortName: 'RI', lat: 41.742325, lng: -71.742332 },
  { state: 'South Carolina', shortName: 'SC', lat: 33.836082, lng: -81.163727 },
  { state: 'South Dakota', shortName: 'SD', lat: 44.500000, lng: 100.000000 },
  { state: 'Tennessee', shortName: 'TN', lat: 35.860119, lng: -86.660156 },
  { state: 'Texas', shortName: 'TX', lat: 31, lng: -100 },
  { state: 'Utah', shortName: 'UT', lat: 39.419220, lng: -111.950684 },
  { state: 'Vermont', shortName: 'VT', lat: 44.000000, lng: -72.699997 },
  // { 'state': 'Virgin Islands', 'shortName': 'VI' },
  { state: 'Virginia', shortName: 'VA', lat: 37.926868, lng: -78.024902 },
  { state: 'Washington', shortName: 'WA', lat: 47.751076, lng: -120.740135 },
  { state: 'West Virginia', shortName: 'WV', lat: 39.000000, lng: -80.500000 },
  { state: 'Wisconsin', shortName: 'WI', lat: 44.500000, lng: -89.500000 },
  { state: 'Wyoming', shortName: 'WY', lat: 43.075970, lng: -107.290283 },
];

export const usTerritories = [
  { state: 'American Samoa', shortName: 'AS' },
  { state: 'Guam', shortName: 'GU' },
  { state: 'Northern Mariana Islands', shortName: 'MP' },
  { state: 'Puerto Rico', shortName: 'PR' },
  { state: 'United States Minor Outlying Islands', shortName: 'UM' },
  { state: 'Virgin Islands', shortName: 'VI' },
];

export const canadianProvinces = [

  { state: 'Alberta', shortName: 'AB' },
  { state: 'British Columbia', shortName: 'BC' },
  { state: 'Manitoba', shortName: 'MB' },
  { state: 'New Brunswick', shortName: 'NB' },
  { state: 'Newfoundland and Labrador', shortName: 'NL' },
  { state: 'Northwest Territories', shortName: 'NT' },
  { state: 'Nova Scotia', shortName: 'NS' },
  { state: 'Nunavut', shortName: 'NU' },
  { state: 'Prince Edward Island', shortName: 'PE' },
  { state: 'Saskatchewan', shortName: 'SK' },
  { state: 'Ontario', shortName: 'ON' },
  { state: 'Quebec', shortName: 'QC' },
  { state: 'Yukon', shortName: 'YT' },
];
