import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import { useHistory, useLocation } from 'react-router';
import { toUSD } from '../../../utils';
import { LoadingSpinner } from '../../shared';
import { ChartFooter } from '../ChartFooter';
import { ChartHeader } from '../ChartHeader';
import { ChartItemList } from '../ChartItemList';
import { usePerformanceQueryParams } from '../usePerformanceQueryParams';
import ExportButton from './ExportButton';

interface IProps {
  large: boolean;
  link?: string;
  categories: CategoryPerformanceStats[];
  loading: boolean;
  isAggregate: boolean;
  onDashboard?: boolean;
}

interface CategoryPerformanceStats {
  category: {
    id: number,
    name: string,
  };
  averageRPU: number;
  units: number;
  royalties: number;
  percent: number;
  sales: number;
}

export const CategoryPerformance = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const performanceQp = usePerformanceQueryParams();
  const tableRef = React.useRef<HTMLTableElement>(null);
  const chartRef = React.useRef<any>(null);

  const chartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'single',
      callbacks: {
        label: (tooltip: any, data: any) => {
          if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const category = data.labels[tooltip.index];
            return `${category}: ${toUSD(value)}`;
          }
          return '';
        },

        footer: (tooltipArray: any, data: any) => {
          const tooltip = tooltipArray[0];
          if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
            const totalData = data.datasets[tooltip.datasetIndex].data
              .reduce((total: number, d: number) => total + d, 0);
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const percent = ((value / totalData) * 100).toFixed(1);
            return `${percent}%`;
          }
          return '';
        },
      },
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  if (props.loading) {
    return (<LoadingSpinner />);
  }

  const list = props.categories.map((c) => {
    return {
      percent: c.percent,
      units: c.units,
      avgUnitSales: (c.sales / c.units).toFixed(2),
      avgUnitRoyalties: (c.royalties / c.units).toFixed(2),
      sales: c.sales,
      royalties: c.royalties,
      name: c.category.name,
    };
  });

  const handleClick = (index: number) => {
    const category = props.categories[index];
    if (category) {
      const queryString = performanceQp.toQueryString([{ value: category.category.id, label: 'category' }]);
      if (props.onDashboard) {
        history.push(`${location.pathname}/categories?${queryString}`);
      } else {
        history.push(`${location.pathname}?${queryString}`);
      }
    }
  };

  const labels: any = props.onDashboard ? [{ name: 'Name' }, { percent: '%' }] :
  [
      { name: '' },
      { percent: '%' },
      { units: 'Units' },
      { avgUnitSales: 'Avg Unit Sales' },
      { avgUnitRoyalties: 'Avg Unit Royalties' },
      { sales: 'Sales' },
      { royalties: 'Royalties' },
  ];

  if (props.large) {
    const pieData = {
      labels: props.categories.map(stats => stats.category.name),
      datasets: [{
        data: props.categories.map(stats => stats.royalties),
      }],
    };

    return (
      <div>
        {list.length > 1 ?
          <div style={{ height: 300, maxHeight: 300 }}>
            <Pie
              options={chartOptions}
              data={pieData}
              ref={chartRef}
              onElementsClick={e => e && e[0] && handleClick(e[0]._index)}
            />
          </div>
          : null}

        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 10 }}>
          <ExportButton tableRef={tableRef} chartRef={chartRef} />
        </div>

        <ChartItemList
          listVisualStyle="dot"
          items={list}
          itemLabels={labels}
          showLabels={true}
          useShowAll={true}
          onClick={handleClick}
          ref={tableRef}
        />
      </div>
    );
  }

  return (
    <div>
      { props.onDashboard ?
        <ChartHeader header="Categories" subHeader="Sales by product/service category."/>
        :       <ChartHeader header="Categories" subHeader="Sales by product/service category." tableRef={tableRef}/>

      }
      <ChartItemList
        listVisualStyle={props.onDashboard ? 'none' : 'dot'}
        items={list}
        itemLabels={labels}
        showLabels={!props.onDashboard}
        onClick={handleClick}
        ref={tableRef}
      />

      {props.categories.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQp.createLink('categories')} />}

    </div>
  );
};
