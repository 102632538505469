import { faAmazon, faEtsy, faFacebook, faInstagram, faPinterest, faTwitter, faYelp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faBlog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { AccountAlias } from '../../../shared';

interface IProps {
  alias: AccountAlias;
  showActions: boolean;
  edit?: (id: number) => void;
  delete?: (id: number) => void;
}

export class AliasListItem extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
  }

  edit() {
    if (this.props.edit) {
      this.props.edit(this.props.alias.id);
    }
  }

  delete() {
    if (this.props.delete) {
      this.props.delete(this.props.alias.id);
    }
  }

  render() {
    const alias = this.props.alias;
    const actions = this.props.showActions ? (
      <div className="alias-actions">
        <button onClick={this.edit} className="btn btn-default">Edit</button>
        <button onClick={this.delete} className="btn btn-default">Delete</button>
      </div>
    ) : null;

    return (
      <div className="account-alias-list-item">
        <img className="img-responsive" src={alias.logo.getSize('sm')} />
        <div className="alias-details">
          <strong className="text-ellipsis">{alias.name}</strong>
          <a className="text-ellipsis" href={alias.url} target="_blank">{alias.url}</a>
          <span>{alias.phone}</span>
          <div className="settings-social-media">
            { alias.facebook ? <a href={alias.facebook} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a> : null}
            { alias.instagram ? <a href={alias.instagram} target="_blank"><FontAwesomeIcon icon={faInstagram} /></a> : null}
            { alias.twitter ? <a href={alias.twitter} target="_blank"><FontAwesomeIcon icon={faTwitter} /></a> : null}
            { alias.etsy ? <a href={alias.etsy} target="_blank"><FontAwesomeIcon icon={faEtsy} /></a> : null}
            { alias.amazon ? <a href={alias.amazon} target="_blank"><FontAwesomeIcon icon={faAmazon} /></a> : null}
            { alias.youtube ? <a href={alias.youtube} target="_blank"><FontAwesomeIcon icon={faYoutube} /></a> : null}
            { alias.pinterest ? <a href={alias.pinterest} target="_blank"><FontAwesomeIcon icon={faPinterest} /></a> : null}
            { alias.yelp ? <a href={alias.yelp} target="_blank"><FontAwesomeIcon icon={faYelp} /></a> : null}
            { alias.blog ? <a href={alias.blog} target="_blank"><FontAwesomeIcon icon={faBlog} /></a> : null}

          </div>
        </div>

        {actions}
      </div>
    );

  }

}
