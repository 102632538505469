interface ISitePageItemResponse {
  id: string;
  type: string;
  params: object;
}

export class MarketplaceSitePageItem {
  id: string;
  type: string;
  params: string;

  static fromApi(data: ISitePageItemResponse) {
    const item = new MarketplaceSitePageItem();
    item.id = data.id;
    item.type = data.type;
    item.params = JSON.stringify(data.params);

    return item;
  }

  typeToTitle() {
    switch (this.type) {
      case 'image':
        return 'Banner Image';
      case 'text':
        return 'Text';
      case 'html':
        return 'Custom HTML';
      case 'products':
        return 'Product Grid';
      case 'vendors':
        return 'Vendor Grid';
      case 'gallery':
        return 'Gallery Grid';
      case 'image_grid':
        return 'Custom Image Grid';
      case 'posts':
        return 'Posts Grid';
      default:
        return '';
        break;
    }
  }
}
