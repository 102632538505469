import { faBoxUp, faExclamationCircle, faExclamationTriangle, faFilePlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Vendor } from '../../shared';

interface IProps {
  includeButton: boolean;
  isCurrent: boolean;
  hasPermissions: boolean;
  canMassUpload: boolean;
  vendorId?: number;
}

export class DesignUploadPanel extends React.Component<IProps, {vendor: null | Vendor}> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      vendor: null,
    };
    this.getVendor = this.getVendor.bind(this);
  }

  componentDidMount() {
    this.getVendor();
  }

  async getVendor() {
    if (this.props.vendorId) {
      const v = await Axios.get(`/api/vendors/${this.props.vendorId}?include=profile`);
      this.setState({ vendor: Vendor.fromApi(v.data.data) });
    }

  }

  render() {
    let route;
    if (this.props.vendorId) {
      route = `/vendors/${this.props.vendorId}/designs/upload`;
    } else {
      route = '/designs/upload';
    }
    const singleUpload = this.props.includeButton && this.props.isCurrent && this.props.hasPermissions ?
    (
      <Link style={{ marginBottom: 10, marginLeft: 10 }} to={`${route}`} className="btn btn-primary">
        <FontAwesomeIcon icon={faFilePlus} /> New Submission
      </Link>
    ) : null;
    const notLicesned = (
      <div className="panel-body">
        <div className="text-center">
          <FontAwesomeIcon style={{ fontSize: 24 }} className="text-danger" icon={faExclamationCircle} />
          <h4 className="no-margin"><strong>Not Licensed</strong></h4>
          <p className="text-muted">You need an active license to upload designs.</p>
        </div>
      </div>
    );

    const noPermission = (
      <div className="panel-body">
        <div className="text-center">
          <FontAwesomeIcon style={{ fontSize: 24 }} className="text-warning" icon={faExclamationTriangle} />
          <h4 className="no-margin"><strong>Permission Needed</strong></h4>
          <p className="text-muted">You need permissions to upload designs.</p>
        </div>
      </div>
    );
    const allowedAndLicensed = (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="panel-body">
        <h5><strong>Designs must be approved prior to production or use in marketing</strong></h5>

        <p className="text-muted text-center">
          Color variations can be included within the same submission.
            <br />
          Accepted file types include JPEG, PNG, and a single-page PDF.
          </p>
          <div style={{ display : 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {singleUpload}
          </div>

      </div>
    );
    let body;
    if (this.props.isCurrent && this.props.hasPermissions) {
      body = allowedAndLicensed;
    } else if (!this.props.isCurrent) {
      body = notLicesned;
    } else if (!this.props.hasPermissions) {
      body = noPermission;
    }

    return (
      <div className="panel panel-portal">
        {body}
      </div>
    );
  }
}
