import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../shared';

interface IProps {
  isShown: boolean;
  onClose: () => void;

}

export const ProcessingFeeModal = (props: IProps) => {

  // tslint:disable:max-line-length
  return (
    <GatewayModal shown={props.isShown} onClose={props.onClose} title="Processing Fees" type={ModalType.Primary} >
      <Modal.Body>
        <p>
          <strong>Our payment processor charges processing fees for credit card payments. Affinity does not keep any of the fees collected.</strong>
          The fee is paid directly to our payment processor. The processing fee is variable based on the payment amount. You can preview the at-cost processing fee and the updated total payment amount prior to remitting payment.
        </p>
        <p>
          There are no processing fees for payments made from a US checking account. To use this payment method, please add and pay with a US checking account.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onClose} className="btn btn-default pull-right">Close</button>
      </Modal.Footer>
    </GatewayModal>
  );

};
