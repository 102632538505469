import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { formattedFiscalYear, toUSD } from '../../../utils';

interface ExportButtonProps {
  chartRef?: React.RefObject<any>;
  tableRef?: React.RefObject<HTMLTableElement>;
}

const ExportButton: React.FC<ExportButtonProps> = ({ chartRef, tableRef }) => {
  const formatCellValue = (cell: string, header: string) => {
    const hasCaratDown = cell.includes('fa-caret-down');
    const text = cell.replace(/<\/?[^>]+(>|$)/g, '').trim();
    const h = header.replace(/["']/g, '').trim();

    switch (h.toLowerCase()) {
      case 'year':
        const year = parseInt(text, 10);
        return isNaN(year) ? '—' : formattedFiscalYear(year);
      case '%':
        const val = parseFloat(text.replace(/[^\d.-]/g, ''));
        return text.includes('&lt;') ? `<${val}%` : isNaN(val) ? '—' : `${val.toFixed(1)}%`;
      case 'royalties':
      case 'benchmark average':
      case '$ comparison':
        let value = parseFloat(text.replace(/[^\d.-]/g, ''));
        if (hasCaratDown) {
          value = -value;
        }
        return isNaN(value) ? '—' : toUSD(value);
      case '% comparison':
        let percentValue = parseFloat(text.replace(/[^\d.-]/g, ''));
        if (hasCaratDown) {
          percentValue = -percentValue;
        }
        return isNaN(percentValue) ? '—' : `${percentValue.toFixed(2)}%`;
      default:
        return text;
    }
  };

  const handleCopyData = () => {
    if (!tableRef || !tableRef.current) return;

    const headers = Array.from(tableRef.current.rows[0].cells).map(cell => cell.innerText.trim());
    const validHeaderIndices = headers.reduce((acc: number[], header: string, index: number) => {
      const columnCells = Array.from(tableRef.current.rows).slice(1).map(row => row.cells[index].innerText.trim());
      const allEmpty = columnCells.every(cell => cell === '');
      if (header !== '' || !allEmpty) {
        acc.push(index);
      }
      return acc;
    },                                        []);

    let plainText = `${validHeaderIndices.map(index => headers[index]).join('\t')}\n`;

    Array.from(tableRef.current.rows).slice(1).forEach((row: HTMLTableRowElement) => {
      const rowData = validHeaderIndices.map((index: number) => {
        const cellText = row.cells[index].innerHTML;
        return formatCellValue(cellText, headers[index]);
      }).join('\t');
      plainText += `${rowData}\n`;
    });

    navigator.clipboard.writeText(plainText)
      .then(() => console.log('Data copied to clipboard'))
      .catch(err => console.error('Error copying data to clipboard:', err));
  };

  const handleCSVDownload = () => {
    if (!tableRef || !tableRef.current) return;

    const headers = Array.from(tableRef.current.rows[0].cells).map(cell => cell.innerText.trim());
    const validHeaderIndices = headers.reduce((acc: number[], header: string, index: number) => {
      const columnCells = Array.from(tableRef.current.rows).slice(1).map(row => row.cells[index].innerText.trim());
      const allEmpty = columnCells.every(cell => cell === '');
      if (header !== '' || !allEmpty) {
        acc.push(index);
      }
      return acc;
    },                                        []);

    let csvContent = `${validHeaderIndices.map(index => `"${headers[index]}"`).join(',')}\n`;

    Array.from(tableRef.current.rows).slice(1).forEach((row: HTMLTableRowElement) => {
      const rowData = validHeaderIndices.map((index: number) => {
        const cellText = row.cells[index].innerHTML;
        return `"${formatCellValue(cellText, headers[index])}"`;
      }).join(',');
      csvContent += `${rowData}\n`;
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.setAttribute('download', 'exported_data.csv');
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  };

  const handleChartDownload = () => {
    if (chartRef && chartRef.current) {
      const canvas = chartRef.current.chartInstance.canvas;
      const imageUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = imageUrl;
      downloadLink.download = 'chart.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <DropdownButton title="Export" id="export-dropdown" pullRight>
      {tableRef && <MenuItem onClick={handleCopyData}>Copy Data</MenuItem>}
      {tableRef && <MenuItem onClick={handleCSVDownload}>CSV Data Download</MenuItem>}
      {chartRef && <MenuItem onClick={handleChartDownload}>Chart Image Download</MenuItem>}
    </DropdownButton>
  );
};

export default ExportButton;
