import Axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

interface IProps {
  selected: any[];
  onChange: (keys: MetadataKeyAPIData[]) => void;
  disabled: boolean;
}

interface MetadataKeyAPIData {
  id: number;
  key_id: number;
  name: string;
  description:string;
}

export const MetadataKeyTypeahead = (props: IProps) => {

  const [allKeys, setAllKeys] = useState<MetadataKeyAPIData[] | []>([]);
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState('');
  const ref = React.useRef<any>();

  React.useEffect(() => {
    getMetadataKeys();
  },              []);

  const getMetadataKeys = async () => {
    Axios.get('/api/metadata/keys')
      .then((response) => {
        setAllKeys(response.data);
        setLoading(false);
      });
  };

  return (
    <>
      <Typeahead
        ref={ref}
        id={'metadata-key-typeahead'}
        placeholder={'Select Metadata Key'}
        options={allKeys}
        isLoading={loading}
        selected={props.selected}
        onChange={(v) => {
          props.onChange(v);
          if (v.length > 0) {
            setDescription(v[0].description);
          } else {
            setDescription('');
          }
          if (!v.length) {
            ref.current.focus();
            ref.current._showMenu();
          }
        }}
        paginate={true}
        maxResults={100}
        labelKey={(item: MetadataKeyAPIData) => item.name}
        paginationText="Load More Metadata Keys"
        clearButton={true}
        disabled={props.disabled}
      />
      <p className="text-primary">
        {description}
      </p>
    </>

  );
};
