import Axios from 'axios';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { AdForm } from './AdForm';

export const NewAdPage = () => {

  const [creatingAd, setCreatingAd] = React.useState(false);

  const history = useHistory();
  const user = React.useContext(UserContext);

  if (user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  const createAd = async (values: any) => {
    setCreatingAd(true);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('url', values.url);
    formData.append('url_text', values.url_text);
    if (values.site) {
      formData.append('site_id', values.site);
    }
    if (values.licensor) {
      formData.append('licensor_id', values.licensor);
    }
    if (values.image) {
      formData.append('image', values.image);
    }

    const ad = await Axios.post('/api/marketplace/ads', formData);
    if (ad.data.data) {
      history.push('/marketplace/ads');
    }

  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('New Ad')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3><strong>New Ad</strong></h3>
          <hr />
          <AdForm submitting={creatingAd} onSubmit={createAd}  />
        </div>
      </div>

    </FullContent>
  );
};
