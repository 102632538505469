import axios, { CancelTokenSource } from 'axios';
import Linkify from 'linkifyjs/react';
import * as moment from 'moment';
import * as React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { getMessageThread, getOlderMessages, sendMessage } from '../../../api';
import { UserContext } from '../../../contexts';
import { MarketplaceMessage, MarketplaceMessageThread } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { MessageInquiry } from './MessageInquiry';

interface IState {
  thread: MarketplaceMessageThread | null;
  message: string;
}

export class MessageThreadPage extends React.Component<any, IState> {

  protected _getThreadSource: CancelTokenSource;
  protected _sendMessageSource: CancelTokenSource;
  protected _getOlderMessages: CancelTokenSource;

  threadEnd: HTMLDivElement | null;

  constructor(props: any) {
    super(props);
    this.state = {
      thread: null,
      message: '',
    };

    this.sendMessage = this.sendMessage.bind(this);
    this.getThread = this.getThread.bind(this);
    this.resetView = this.resetView.bind(this);
    this.getOlderMessages = this.getOlderMessages.bind(this);

  }

  componentDidMount() {
    this.getThread();
  }

  componentDidUpdate(prevProps: any, prevState: IState) {
    if (prevProps.match.params['id'] !== this.props.match.params['id']) {
      this.resetView();
    }

  }

  resetView() {
    this.setState({ thread: null, message: '' });
    this.getThread();

  }

  getThread() {

    if (this._getThreadSource) {
      this._getThreadSource.cancel('Cancelled getThread() XHR due to new request.');
    }
    this._getThreadSource = axios.CancelToken.source();
    const id = this.props.match.params['id'];
    const params = {
      vendorId: null,
    };
    if (this.props.user.type === 'vendor') {
      params.vendorId = this.props.user.account.id;
    } else if (this.props.match.params['vendorId']) {
      params.vendorId = this.props.match.params['vendorId'];
    }

    getMessageThread(id, params, this._getThreadSource)
      .then((response) => {
        const thread = MarketplaceMessageThread.fromApi(response.data.data);

        this.setState({ thread });
        if (this.threadEnd) {
          this.threadEnd.scrollTop = this.threadEnd.scrollHeight;

        }
      });
  }

  getOlderMessages() {
    if (this.state.thread) {
      const thread = this.state.thread;
      const oldestMessage = this.state.thread.messages[0];
      const id = this.props.match.params['id'];
      if (this._getOlderMessages) {
        this._getOlderMessages.cancel('Cancelled getOlderMessages() XHR due to new requests.');
      }
      this._getOlderMessages = axios.CancelToken.source();
      getOlderMessages(id, { message_id: oldestMessage.messageId }, this._getOlderMessages)
        .then((response) => {
          const messages = response.data.data.reverse().map((message: any) => MarketplaceMessage.fromApi(message));
          thread.messages = messages.concat(thread.messages);
          this.setState({ thread });

        });
    }
  }

  sendMessage() {
    if (this.state.thread && this.state.message.length) {
      const thread = this.state.thread;
      const message = this.state.message;
      const id = this.props.match.params['id'];
      const vendorId = this.props.user.type === 'vendor' ? this.props.user.account.id : null;
      this._sendMessageSource = axios.CancelToken.source();
      const currentDate = new Date();
      sendMessage(id, { message, vendorId }, this._sendMessageSource)
        .then((response) => {
        });
      thread.messages.push({
        message,
        id: 0,
        messageId: currentDate.getTime(),
        type: 'vendor',
        updatedAt: moment(currentDate),
        userId: this.props.user.id,
        inquiry: null,
      });

      this.setState({
        thread,
        message: '',
      });

    }
  }

  render() {
    let body = <></>;
    if (this.state.thread) {

      const user = this.state.thread && this.state.thread.users.length ? this.state.thread.users[0] : null;

      const messages = this.state.thread ? this.state.thread.messages
        .map((message) => {
          const side = message.type !== 'vendor';
          const messageUser = this.state.thread ? this.state.thread.users.find(user => message.userId === user.id) : null;
          const inquiry = message.inquiry ? <MessageInquiry user={messageUser} inquiry={message.inquiry} /> : null;
          return (
            <div
              key={message.messageId}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: side ? 'flex-start' : 'flex-end',
                marginBottom: 10,
              }}
              className="col-md-12"
            >
                <div className={`marketplace-message ${ side ? 'left' : 'right'}`} >
                  {inquiry ? inquiry : <Linkify><span>{message.message}</span></Linkify>}
                </div>

              <p className="text-muted message-date">{message.updatedAt.format('M/DD/YY h:mm a')}</p>
            </div>
          );
        }) : null;
      const showMore = this.state.thread.messages.length >= 20 ?  (
        <div onClick={this.getOlderMessages} style={{ textAlign: 'center', cursor: 'pointer' }}>
          Get Older Messages
              <hr></hr>
        </div>
      ) : null;
      body = (

          <div className="panel panel-portal">
            <div className="panel-body">
              <UserContext.Consumer>
                {
                  currentUser => currentUser.type === 'vendor' ?
                    <h4>{user ? user.name : ''}</h4> :
                    <h4>{this.state.thread ? this.state.thread.users.map(u => u.name).join(', ') : null}</h4>
              }
              </UserContext.Consumer>
              <hr></hr>
              <div ref={ref => this.threadEnd = ref} style={{ maxHeight: 400, overflow: 'scroll' }} className="row">
                {showMore}

                {messages}

              </div>

              <UserContext.Consumer>
                {user => user.type === 'vendor' ? (
                  <div style={{ marginTop: 15, width: '100%' }} className="input-group">
                    <textarea
                      onChange={event => this.setState({ message: event.target.value })}
                      value={this.state.message}
                      rows={3}
                      style={{ width: '100%' }}
                      className="form-control"
                    />
                    <button
                      onClick={this.sendMessage}
                      className="btn btn-default pull-right"
                      style={{ marginTop: 10 }}
                      type="button"
                    >
                      Send Message
                    </button>
                  </div>
                  ) : null
                }
              </UserContext.Consumer>

            </div>
          </div>

      );
    } else {
      body = (
        <div className="panel panel-portal">
          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>
    );

    }

    return (
      <Media query={{ minWidth: 992 }}>

        {matches => matches ?
           body
          : <FullContent>
            <div className="row flex-row button-row">
              <div className="panel panel-portal button-panel">
                <div className="panel-body">
                  <Link to="/marketplace/messages" className="btn btn-default btn-sm">Messages</Link>
                </div>
              </div>
            </div>
            {body}
          </FullContent>
        }
      </Media>

    );

  }
}
