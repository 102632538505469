import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import { Filters } from '../../../shared';

export class CaseFilters extends Filters {
  caseStatus = '';
  platform = '';
  closedStartDate: String = '';
  closedEndDate: String = '';
  constructor(location?: string) {
    super();
    if (location) {
      this.setQueryParamsFromLocation(location);
    }

  }

  setQueryParamsFromLocation(location: string) {
    super.setQueryParamsFromLocation(location);
    const queryValues = queryString.parse(location);
    this.sortBy = queryValues.sort ? queryValues.sort : this.sortBy;
    this.market = queryValues.market ? queryValues.market : this.market;
    this.caseStatus = queryValues.status ? queryValues.status : this.caseStatus;
    this.platform = queryValues.platform ? queryValues.platform : this.platform;
    this.closedStartDate = queryValues.closed_start_date ? queryValues.closed_start_date : this.closedStartDate;
    this.closedEndDate = queryValues.closed_end_date ? queryValues.closed_end_date : this.closedEndDate;
  }

  generateQueryParamString() {
    const queryParams = {
      search: this.search.length ? this.search : null,
      start_date: this.startDate.length ? this.startDate : null,
      end_date: this.endDate.length ? this.endDate : null,
      closed_start_date: this.closedStartDate.length ? this.closedStartDate : null,
      closed_end_date: this.closedEndDate.length ? this.closedEndDate : null,
      page: this.page !== 1 ? this.page : null,
      market: this.market.length ? this.market : null,
      user: this.userId ? this.userId : null,
      sort: this.sortBy.length ? this.sortBy : null,
      status: this.caseStatus.length ? this.caseStatus : null,
      platform: this.platform.length ? this.platform : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  setFilters(filters: any) {
    super.setFilters(filters);
    const newFilters = {
      caseStatus: this.caseStatus,
      platform: this.platform,
      closedStartDate: this.closedStartDate,
      closedEndDate: this.closedEndDate,
      ...filters,
    };
    this.sortBy = newFilters.sortBy;
    this.page = newFilters.page;
    this.caseStatus = newFilters.caseStatus;
    this.platform = newFilters.platform;
  }

  get filters() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      search: this.search,
      startDate: this.startDate,
      endDate: this.endDate,
      closedStartDate: this.closedStartDate,
      closedEndDate: this.closedEndDate,
      market: this.market,
      userId: this.userId,
      caseStatus: this.caseStatus,
      platform: this.platform,
    };
  }
}
