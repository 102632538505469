
import * as moment from 'moment';
import { DistributionChannel, ProductCategory, ProductCategoryApiResponse } from '..';
import { IReviewItemStatus } from '../../components/shared';
import { AffinityClient } from '../AffinityClient';
import { Insignia } from '../Insignia';
import { InsigniaApiResponse } from '../Insignia';
import User from '../User';
import { Vendor } from '../Vendor';
import { DesignActivity } from './DesignActivity';
import { DesignInterationApiResponse, DesignIteration } from './DesignIteration';
import { DesignPhase, DesignPhaseApiResponse } from './DesignPhase';

interface DesignApiResponse {
  quantity?: number;
  net_sales?: number;
  royalty_sales?: number;
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  expedited_at: string;
  decision_at: string;
  title: string;
  description: string;
  internal_code: string;
  is_autotagged_client: boolean;
  phase: DesignPhaseApiResponse;
  vendor: {
    id: number;
    name: string;
    short_name: string;
  };
  product_category:  {
    id: number;
    name: string;
    type: string;
  };
  product_categories: ProductCategoryApiResponse[];
  primary_client : {
    id: number;
    name: string;
    short_name: string;
    market: any;
  };
  iterations: DesignInterationApiResponse[];
  secondary_clients: {
    id: number;
    name: string;
    short_name: string;
  }[];
  insignia: InsigniaApiResponse[];
  upload_user?: any;
  // vendor_identifier: string;
  distribution_channel?: any;
  retailer_map: string;
  customer: string;
  unit_estimation: string;
  channel_retail: boolean;
  channel_direct: boolean;
  channel_internal: boolean;
  upi: { upi: string }[];
  not_for_resale: boolean;
  university_funds: boolean;
  qualifies_for_exemption: boolean;
}

export class Design {
  id: number;
  created: moment.Moment;
  updated: moment.Moment;
  deleted: moment.Moment;
  expedited: moment.Moment;
  decision: moment.Moment;
  title: string;
  description: string;
  internalCode: string;
  autoTagged: boolean;
  phase: DesignPhase;
  vendor: Vendor;
  productCategories: ProductCategory[];
  primaryClient: AffinityClient;
  secondayClients: AffinityClient[];
  iterations: DesignIteration[];
  insignia: Insignia[] = [];
  uploadUser: User;
  distributionChannel: DistributionChannel;
  retailerMap: string;
  customer: string;
  unitEstimation: string;
  channelRetail: boolean;
  channelInternal: boolean;
  channelDirect: boolean;
  upi: { upi: string }[] = [];
  netSales?: number;
  royaltySales?: number;
  quantity?: number;
  notForResale: boolean;
  universityFunds: boolean;
  qualifiesForExemption: boolean;

  static fromApi(data: DesignApiResponse) {
    const design =  new Design();
    design.id = data.id;
    design.created = moment.utc(data.created_at).local();
    design.updated = moment.utc(data.updated_at).local();
    if (data.deleted_at) {
      design.deleted = moment.utc(data.deleted_at).local();
    }
    design.decision = moment.utc(data.decision_at).local();
    design.decision = moment.utc(data.decision_at).local();
    if (data.expedited_at) {
      design.expedited = moment.utc(data.expedited_at).local();
    }
    design.title = data.title;
    design.description = data.description;
    design.internalCode = data.internal_code;
    design.autoTagged = data.is_autotagged_client;
    design.phase = DesignPhase.fromApi(data.phase);
    design.vendor = new Vendor(data.vendor);
    if (data.product_categories) {
      design.productCategories = data.product_categories.map(c => ProductCategory.fromApi(c));
    } else {
      design.productCategories = [];
    }

    if (data.primary_client)  {
      design.primaryClient = new AffinityClient(data.primary_client);
    }

    if (data.secondary_clients) {
      design.secondayClients = data.secondary_clients.map(client => new AffinityClient({
        id: client.id,
        short_name: client.short_name,
      }));
    }
    design.iterations = data.iterations.map(iteration => DesignIteration.fromApi(iteration));
    if (data.upload_user) {
      design.uploadUser = new User(data.upload_user);
    }
    if (design.description) {
      const descriptionActivity = new DesignActivity();
      descriptionActivity.created = design.created;
      descriptionActivity.message = design.description;
      descriptionActivity.group = 'Shared with all users.';
      if (design.uploadUser) {
        descriptionActivity.user = design.uploadUser;
      } else {
        // Legacy design support.  This will show no name the activity
        descriptionActivity.user = new User({ first_name: '', last_name: '' });
      }
      descriptionActivity.user.accountName = design.vendor.shortName;
      if (design.primaryIteration) {
        design.iterations[0].activity.push(descriptionActivity);
      }
    }
    if (data.insignia) {
      design.insignia = data.insignia.map(i => Insignia.fromApi(i));
    }

    if (data.net_sales) {
      design.netSales = data.net_sales;
    }

    if (data.royalty_sales) {
      design.royaltySales = data.royalty_sales;
    }

    if (data.quantity) {
      design.quantity = data.quantity;
    }

    design.upi = data.upi;
    design.retailerMap = data.retailer_map;
    design.customer = data.customer;
    design.unitEstimation = data.unit_estimation;
    design.channelDirect = data.channel_direct;
    design.channelRetail = data.channel_retail;
    design.channelInternal = data.channel_internal;
    if (data.distribution_channel) {
      design.distributionChannel = new DistributionChannel(data.distribution_channel);
    }
    design.universityFunds = data.university_funds;
    design.notForResale = data.not_for_resale;
    design.qualifiesForExemption = data.qualifies_for_exemption;

    return design;
  }

  hasLicensor(id: number) {
    const c = [this.primaryClient].concat(this.secondayClients).map(l => l.id);
    return c.includes(id);
  }

  get status(): IReviewItemStatus {
    switch (this.phase.id) {
      case 1:
        return { colorClass: 'text-primary', label: 'On Hold' };
      case 2:
        return { colorClass: 'text-purple', label: 'Licensor Review' };

      case 3:
        return { colorClass: 'text-primary', label: 'Pending Review' };
      case 7:
        return { colorClass: 'text-danger', label: 'Licensor Rejected' };
      case 8:
      case 9:
        return { colorClass: 'text-danger', label: 'Rejected' };
      case 12:
      case 13:
        return { colorClass: 'text-success', label: 'Approved with Changes' };
      case 14:
      case 15:
        if (this.phase.shortName === 'Client') {
          return { colorClass: 'text-success' , label: 'Licensor Approved' };
        }
        return { colorClass: 'text-success', label: 'Approved' };

        // return { colorClass: 'text-success', label: this.phase }
      default: return { colorClass: 'text-warning', label: 'Unknown' };
    }
  }

  get primaryIteration() {
    return this.iterations[this.iterations.length - 1];
  }

  get otherIterations() {
    if (this.iterations.length > 1) {
      return this.iterations.slice(0, this.iterations.length - 1);
    }
    return [];
  }

  get isResubmission() {
    return this.iterations.length > 1;
  }

  get reviewDuration() {
    return this.created.from(this.decision);
  }

  get isExpedited() {
    if (this.expedited) {
      return true;
    }
    return false;
  }

  get isPending() {
    return this.phase.id === 3 || this.phase.id === 2;
  }

  get channelBooleanLabel() {
    const c = [];
    if (this.channelDirect) {
      c.push('Direct to Consumer');
    }
    if (this.channelRetail) {
      c.push('Business to Business (Wholesale/Retail)');
    }
    if (this.channelInternal) {
      c.push('Internal Consumption');
    }

    return c.join(', ');
  }

  get canClientReview() {
    if (this.phase.id === 2) {
      return true;
    }

    const date = moment();
    if (this.primaryIteration.decision) {
      const eligible = [7, 13, 15];
      if (date.subtract(48, 'hours').isBefore(this.primaryIteration.decision) && eligible.includes(this.phase.id)) {
        return true;
      }
      return false;
    }
    return false;
  }

  get canRoyaltyExempt() {
    return this.qualifiesForExemption || this.qualifiesForExemption === null;
  }

}
