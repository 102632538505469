import Axios, { CancelTokenSource } from 'axios';
import * as moment from 'moment';
import * as queryString from 'query-string';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { getAccountProfile } from '../../../api';
import { AccountAddress, AccountAlias, AccountAliasAPIResponse, AccountProfile, Filters } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FilterBar, GatewayModal, LoadingSpinner, ModalType, PaginationFooter } from '../../shared';
import { deleteAccountAddress, getAliases, getNewAccountAddress, updateAccountAddress } from '../api';
import { AccountAddressForm, AccountAddressFormValues } from './AccountAddressForm';

interface IState {
  loading: boolean;
  accountAddresses: AccountAddress[];
  accountName: string;
  aliases: AccountAlias[];
  accountAddressToEdit: AccountAddress | null;
  deleteModalShown: boolean;
  deleteOldBusiness: boolean;
  idToDelete: number;
  editModalShown: boolean;
  idToEdit: number;
  saving: boolean;
  updatedValues: AccountAddressFormValues | null;
  businessModalShown: boolean;
  idToBusiness: number;
  oldBusinessAddress: string | null;
  newBusinessAddress: string | null;
  filters: Filters;
  totalResults: number;
  lastPage: number;
}

export class AccountAddressPage extends React.Component<RouteComponentProps<any>, IState>  {

  _getAccountAddressesSource: CancelTokenSource;
  _updateBusinessCancelToken: CancelTokenSource;
  _saveAccountAddressCancelToken: CancelTokenSource;
  _getAccountAliasesSource: CancelTokenSource;
  _getAccountSource: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    const account = new AccountProfile();
    account.id = this.accountID;
    const filters = new Filters();
    filters.setQueryParamsFromLocation(this.props.location.search);
    this.state = {
      filters,
      loading: true,
      updatedValues: null,
      accountAddresses: [],
      aliases: [],
      deleteModalShown: false,
      idToDelete: 0,
      saving: false,
      editModalShown: false,
      idToEdit: 0,
      businessModalShown: false,
      idToBusiness: 0,
      oldBusinessAddress: null,
      newBusinessAddress: null,
      accountAddressToEdit: null,
      totalResults: 0,
      lastPage: 1,
      deleteOldBusiness: true,
      accountName: '',
    };
    this.getAccountAddresses = this.getAccountAddresses.bind(this);
    this.getAccountAliases = this.getAccountAliases.bind(this);
    this.deleteAccountAddress = this.deleteAccountAddress.bind(this);
    this.saveAccountAddress = this.saveAccountAddress.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.setDeleteID = this.setDeleteID.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.hideEditModal = this.hideEditModal.bind(this);
    this.setEditID = this.setEditID.bind(this);
    this.showBusinessModal = this.showBusinessModal.bind(this);
    this.hideBusinessModal = this.hideBusinessModal.bind(this);
    this.toggleDeleteOldBusiness = this.toggleDeleteOldBusiness.bind(this);
    this.setBusinessID = this.setBusinessID.bind(this);
    this.updateBusinessAddress = this.updateBusinessAddress.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.setPage = this.setPage.bind(this);
  }

  componentDidMount() {
    this.getAccountAddresses();
    this.getAccountAliases();
  }

  render() {

    const rows = () => {
      if (this.state.loading) {
        return <LoadingSpinner />;
      }
      if (this.state.accountAddresses.length === 0 && ! this.state.loading) {
        return (
          <div className="panel panel-portal">
            <div className="panel-body">
              <em>No Addresses Found.</em>
            </div>
          </div>
        );
      }
      return (
        this.state.accountAddresses.map((address) => {

          const createdAt = moment(address.createdAt.date).format('MM/DD/YYYY');

          return <div key={address.id} className="panel panel-portal">

          <div className="panel-body row">

            <div className="col-md-2 col-lg-1">
              <img style={{ width:60 }} src={address.logo.getSize('sm')}/>
            </div>
            <div className="col-md-10 col-lg-11">
              <div className="row">

                <div className="col-sm-6 text-left">
                  {address.name}{address.uniqueIdentifier && `, ${address.uniqueIdentifier}`} { (address.name || address.uniqueIdentifier) ? <br/> : ''}
                  {address.fullAddress}<br/>
                  {address.phoneNumber}<br/>
                </div>
                <div className="col-sm-6 text-right">
                  {address.isBusiness &&
                    <span className="label label-primary address-label">Business Address</span>
                  }
                  {address.isRetail &&
                    <span className="label label-default address-label">Retail</span>
                  }
                  {address.isManufacturing &&
                    <span className="label label-default address-label">Manufacturing</span>
                  }
                  {address.isFulfillment &&
                    <span className="label label-default address-label">Fulfillment</span>
                  }
                  {address.isOffice &&
                    <span className="label label-default address-label">Office</span>
                  }
                </div>

              </div>

              <div className="row">
                <div className="col-xs-6">
                  <button className="btn btn-link address-button" data-id={address.id} onClick={this.setEditID}>
                    Edit
                  </button>
                  {!address.isBusiness &&
                    <button className="btn btn-link address-button" data-id={address.id} onClick={this.setDeleteID}>
                      Remove</button>
                  }
                  {!address.isBusiness &&
                    <button className="btn btn-link address-button" data-id={address.id} onClick={this.setBusinessID}>
                      Set as Business Address</button>
                  }
                </div>

                <div className="col-xs-6 text-right">
                  {address.createdBy &&
                  <span className="help-block">
                    <em>Added by {address.createdBy.first_name} {address.createdBy.last_name} on {createdAt}</em>
                  </span>
                  }
                </div>
              </div>
            </div>

          </div>
        </div>;
        })
      );
    };

    const deleteModalText = this.state.saving ? 'Deleting...' : 'Remove Address';
    const editModalText = this.state.saving ? 'Saving...' : 'Save Address';
    const businessModalText = this.state.saving ? 'Changing Business Address...' : 'Change Business Address';

    return <FullContent breadcrumbs={[{ name: 'Addresses' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Addresses</strong></h3>
        </div>
        <Link to={'addresses/add'} className="btn btn-primary">Add Address</Link>

      </div>
      <FilterBar
        updateFilters={this.updateFilters}
        usePerPage={false}
        useQuarter={false}
        useSearch={true}
        search={this.state.filters.search}
        perPage={this.state.filters.perPage}
        fieldClassOverrides={{ search: 'address-search-bar col-lg-3 col-sm-3 col-xs-12' }}
      />

      {rows()}

      <PaginationFooter
        setPage={this.setPage}
        currentPage={this.state.filters.page}
        totalResults={this.state.totalResults}
        hasNext={this.state.lastPage > this.state.filters.page} />

      <GatewayModal shown={this.state.deleteModalShown} type={ModalType.Danger} onClose={this.hideDeleteModal} title="Delete Address" >
        <Modal.Body>
          <p className="text-center">Are you sure you want to remove this address?</p>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
          <button onClick={this.deleteAccountAddress} className="btn btn-danger pull-right">{deleteModalText}</button>

        </Modal.Footer>
      </GatewayModal>

      <GatewayModal shown={this.state.businessModalShown} type={ModalType.Primary} onClose={this.hideBusinessModal} title="Set as Business Address" >
        <Modal.Body>
          <p className="text-center">Please review your request to change your business address.<br/>
            <br/>
            <span style={{ fontWeight: 'bold', color: 'red' }}>Your business address must be the legal address of your
              business, as shown on business documents, tax forms, etc. This change typically requires updates to your
              existing license agreements.</span>
            <br/>
            <br/>
            { this.state.oldBusinessAddress &&
              <>
                <strong>Old Business Address</strong><br/>
                {this.state.oldBusinessAddress}
                <br/>
                <br/>
              </>
            }
            <strong>New Business Address</strong><br/>
            {this.state.newBusinessAddress}
            <br/>
            <br/>
          </p>
            { this.state.oldBusinessAddress &&
              <>
                <div className="checkbox text-center">
                  <label>
                    <input type="checkbox" name="deleteOldBusiness" checked={this.state.deleteOldBusiness} onChange={this.toggleDeleteOldBusiness} />
                    <strong>Remove the old business address from your account?</strong>
                  </label>
                </div>
              </>
            }

        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.hideBusinessModal} className="btn btn-default pull-left">Cancel</button>
          <button onClick={this.updateBusinessAddress} className="btn btn-primary pull-right">{businessModalText}</button>

        </Modal.Footer>
      </GatewayModal>

      <GatewayModal shown={this.state.editModalShown} type={ModalType.Primary} onClose={this.hideEditModal} title="Edit Address" >
        <Modal.Body>
          <span className="text-center text-muted">You may edit the following settings for this address.
            The actual address cannot be updated. Please add or remove the address if the location has changed.</span>
          <AccountAddressForm
            accountAddress={this.state.accountAddressToEdit}
            onSubmit={this.saveAccountAddress}
            saving={this.state.saving}
            isUpdate={true}
            aliases={this.state.aliases}
            isForcedBusiness={ this.state.accountAddressToEdit ? this.state.accountAddressToEdit.isBusiness : false}
            accountName={this.state.accountName}
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.hideEditModal} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" form="account-address-form" className="btn btn-primary pull-right">{editModalText}</button>

        </Modal.Footer>
      </GatewayModal>
    </FullContent>;
  }

  getAccountAddresses() {
    this.setState({ loading: true });
    this._getAccountAddressesSource = Axios.CancelToken.source();

    const params = this.state.filters.getQueryParams();
    params['account_id'] = this.accountID;

    getNewAccountAddress(params, this._getAccountAddressesSource)
      .then((response) => {
        const data = response.data.data;

        let oldBusinessAddress = data.find(i => i.is_business_address);
        oldBusinessAddress = oldBusinessAddress ? oldBusinessAddress.full_address : null;

        this.setState({
          oldBusinessAddress,
          loading: false,
          accountAddresses: data.map((u: any) => AccountAddress.fromApi(u)),
          lastPage: response.data.meta.pagination.page_count,
          totalResults: response.data.meta.pagination.total_count,
        });
      });
  }

  getAccountAliases() {
    const params = {
      account_id: this.accountID,
    };
    this._getAccountAliasesSource = Axios.CancelToken.source();
    this._getAccountSource = Axios.CancelToken.source();

    const getAliasesRequest = getAliases(params, this._getAccountAliasesSource);
    const getAccountRequest = getAccountProfile(params['account_id'], this._getAccountSource);

    Axios.all([getAliasesRequest, getAccountRequest])
      .then(([aliasesResponse, accountResponse]) => {
        let aliases = [];
        if (aliasesResponse.data.data) {
          aliases = aliasesResponse.data.data.map((alias: AccountAliasAPIResponse) => AccountAlias.fromApi(alias));
        }
        const accountName = accountResponse.data.short_name;
        this.setState({ accountName, aliases });
      });

  }

  get accountID() {
    if (this.props.match.params['vendorId']) {
      return this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      return this.props.match.params['licensorId'];
    }
    return '';
  }

  deleteAccountAddress() {
    if (this.state.idToDelete) {
      this.setState({ saving: true });
      deleteAccountAddress(this.state.idToDelete)
        .then((response) => {
          const accountAddresses = this.state.accountAddresses.filter(address => address.id !== this.state.idToDelete);
          this.setState({ accountAddresses, saving: false, idToDelete: null });
          this.getAccountAddresses();
          this.hideDeleteModal();
        });
    }
  }

  saveAccountAddress(values: AccountAddressFormValues) {
    // const values = this.state.updatedValues;
    if (values) {
      this.setState({ saving: true });

      const addressID = values.id;
      const formData = new FormData();
      formData.append('account_dba_profile_id', values.dbaProfileId ? values.dbaProfileId : '');
      if (values.name) {
        formData.append('name', values.name);
      }
      if (values.uniqueIdentifier) {
        formData.append('unique_identifier', values.uniqueIdentifier);
      }
      formData.append('phone_number', values.phoneNumber ? values.phoneNumber : '');
      formData.append('is_manufacturing_factory', values.isManufacturing ? '1' : '0');
      formData.append('is_fulfillment_center', values.isFulfillment ? '1' : '0');
      formData.append('is_retail_storefront', values.isRetail ? '1' : '0');
      formData.append('is_office', values.isOffice ? '1' : '0');
      formData.append('account_id', this.accountID);

      this._saveAccountAddressCancelToken = Axios.CancelToken.source();

      updateAccountAddress(addressID, formData, this._saveAccountAddressCancelToken)
      .then((accountAddressUpdateResponse) => {
        this.getAccountAddresses();
        this.hideEditModal();
        this.setState({ saving: false });
      }).catch(error => this.setState({ saving: false }));

    }

  }

  showDeleteModal() {
    this.setState({ deleteModalShown: true });
  }

  hideDeleteModal() {
    this.setState({ deleteModalShown: false });
  }

  setDeleteID(e: any) {
    this.setState({ idToDelete: Number(e.target.getAttribute('data-id')) });
    this.showDeleteModal();
  }

  updateBusinessAddress() {
    this.setState({ saving: true });
    const formData = new FormData();
    formData.append('account_id', this.accountID);
    formData.append('is_business_address', '1');
    formData.append('delete_old_business', this.state.deleteOldBusiness ? '1' : '0');

    this._updateBusinessCancelToken = Axios.CancelToken.source();

    const accountAddressUpdate = updateAccountAddress(this.state.idToBusiness, formData, this._updateBusinessCancelToken);

    Axios.all([accountAddressUpdate])
      .then(([accountAddressUpdateResponse]) => {
        this.getAccountAddresses();
        this.hideBusinessModal();
        this.setState({ saving: false });
      }).catch(error => this.setState({ saving: false }));

  }

  showBusinessModal() {
    this.setState({ businessModalShown: true });

  }

  hideBusinessModal() {
    this.setState({ businessModalShown: false });
  }

  setBusinessID(e: Object) {
    const businessId = Number(e.target.getAttribute('data-id'));
    const addresses = this.state.accountAddresses;
    const newBusinessAddress = addresses.find(i => i.id === businessId);
    this.setState({
      idToBusiness: businessId,
      newBusinessAddress: newBusinessAddress.fullAddress,
    });
    this.showBusinessModal();
  }

  showEditModal() {
    this.setState({ editModalShown: true });

  }

  hideEditModal() {
    this.setState({ editModalShown: false });
  }

  setEditID(e: any) {
    const editId = Number(e.target.getAttribute('data-id'));
    const addresses = this.state.accountAddresses;
    const addressToEdit = addresses.find(i => i.id === editId);
    this.setState({ idToEdit: editId, accountAddressToEdit: addressToEdit });
    this.showEditModal();
  }

  updateFilters(filters: any, performSearch: boolean) {

    const updatedFilters = this.state.filters;
    if (filters.search) {
      filters.page = 1;
    }
    updatedFilters.setFilters(filters);
    this.setState({ filters: updatedFilters });

    if (performSearch) {
      const baseUrl = this.props.location.pathname;
      this.props.history.replace(`${baseUrl}?${queryString.stringify(updatedFilters.getQueryParams())}`);
      this.getAccountAddresses();
    }

  }

  setPage(page: number) {
    this.updateFilters({ page }, true);
  }

  getAccountName() {
    Axios.get(`/api/accounts/${this.accountID}`)
      .then((response) => {
        const account = response.data;
        this.setState({ accountName: account['name'] });
      });
  }

  toggleDeleteOldBusiness(event: any) {
    this.setState({
      deleteOldBusiness: event.target.checked,
    });
  }

}
