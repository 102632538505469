import axios from 'axios';
import * as React from 'react';
import { ChangeEvent } from 'react';
import * as Autosuggest from 'react-autosuggest';
import { SuggestionSelectedEventData, SuggestionsFetchRequestedParams } from 'react-autosuggest';
import { Modal } from 'react-bootstrap';
import { RoyaltySchedule } from '../../shared';
import { GatewayModal, ModalType } from '../shared/modals';

interface IProps {
  shown: boolean;
  hideModal: () => void;
  currentSchedule: RoyaltySchedule | null;
  addSelection: (selection: ISelectOption) => void;
  removeSelection: (selection: ISelectOption) => void;
  submit: () => void;
  submitting: boolean;
  selections: ISelectOption[];
}

export interface ISelectOption {
  id: number;
  name: string;
}

interface IState {
  allSuggestions: ISelectOption[];
  suggestions: ISelectOption[];
  inputValue: string;
}

const getSuggestionValue = (suggestion: ISelectOption) => suggestion.name;

const renderSuggestion = (suggestion: ISelectOption) => (
  <div>
    {suggestion.name}
  </div>
);

class UpdateVendorsModal extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      allSuggestions: [],
      suggestions: [],
      inputValue: '',
    };
    this.removeSelection = this.removeSelection.bind(this);
    this.search = this.search.bind(this);
    this.clearSuggestions = this.clearSuggestions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectSelection = this.selectSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/api/vendors?minimal=1&order_by=short_name&status%5B%5D=1&status%5B%5D=2&status%5B%5D=3&status%5B%5D=4')
      .then((response: any) => {
        const allSuggestions = response.data.data.map((vendor: any) => ({
          id: vendor.id,
          name: vendor.name,
        }));
        this.setState({ allSuggestions });
      });
  }

  removeSelection(sel: ISelectOption) {
    this.props.removeSelection(sel);
  }

  selectSelection(event: React.FormEvent<any>, data: SuggestionSelectedEventData<ISelectOption>) {
    this.setState({ inputValue: '' });
    this.props.addSelection(data.suggestion);
  }

  search(params: SuggestionsFetchRequestedParams) {
    const value = params.value.trim().toLowerCase();

    if (!value) {
      this.setState({ suggestions: [] });
    }

    const filteredSuggestions = this.state.allSuggestions.filter((s) => {
      return s.name.toLowerCase().indexOf(value) !== -1;
    }).filter((s) => {
      return this.props.selections.map(sel => sel.id).indexOf(s.id) === -1;
    }).slice(0, 10);

    this.setState({ suggestions: filteredSuggestions });
  }

  clearSuggestions() {
    this.setState({ suggestions: [] });
  }

  onChange(event: ChangeEvent<HTMLSelectElement>) {
    this.setState({
      inputValue: event.target.value,
    });
  }

  public render() {

    const selectionLabels = this.props.selections.map((sel: ISelectOption) => (
      <span key={sel.id} className="label label-default">
        {sel.name}
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Remove"
          onClick={this.removeSelection.bind(this, sel)}
        >x</button>
      </span>
    ));

    const inputProps = {
      placeholder: 'Search for a vendor',
      value: this.state.inputValue,
      onChange: this.onChange,
    };

    return (
      <GatewayModal
        type={ModalType.Primary}
        onClose={this.props.hideModal}
        shown={this.props.shown}
        title={this.props.currentSchedule && this.props.currentSchedule.title}
      >
        <Modal.Body>
          <div className="text-center" style={{ fontSize: '16px', display: 'block', marginTop: '15px', fontWeight: 'bold' }}>
            <strong>You can update the vendors attached to this schedule here</strong>
          </div>
          <Autosuggest
            suggestions={this.state.suggestions}
            onSuggestionsFetchRequested={this.search}
            onSuggestionsClearRequested={this.clearSuggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.selectSelection}
          />

          <div>
            {selectionLabels}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.props.hideModal} className="btn btn-default pull-left">Cancel</button>
          <button className="btn btn-primary" disabled={this.props.submitting} onClick={this.props.submit}>Update</button>
        </Modal.Footer>
      </GatewayModal>
    );
  }
}

export default UpdateVendorsModal;
