import { faSort, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import * as Yup from 'yup';
import { AffinityClient, MarketplaceAdvertisement, MarketplacePost } from '../../../shared';
import { MarketplaceSite } from '../../../shared/MarketplaceSite';
import { PikadayReact } from '../../shared';
import { AvailableAdModal } from '../Ads';
import { PostTagTypeahead } from './PostTagTypeahead';

interface IProps {
  post?: MarketplacePost;
  submitting: boolean;
  onSubmit: (values: any) => void;
}

interface SortableAdvertisementProps  {
  ad: MarketplaceAdvertisement ;
  onDelete: () => void;
}
interface DragHandleProps {
  title: string;
}

const DragHandle = SortableHandle((props: DragHandleProps) => (
  <span>
    <FontAwesomeIcon style={{ marginRight: 10 }} icon={faSort} />
    {props.title}

  </span>
));

const SortableAdvertisement = SortableElement((props: SortableAdvertisementProps) => (
  <div style={{ padding: 20 }} className="row sortable-ad-row" key={props.ad.id}>
    <div className="col-xs-9">
      <DragHandle title={props.ad.title} />

    </div>
    <div className="col-xs-3 text-right">
      <FontAwesomeIcon
        style={{ marginLeft: 15, cursor: 'pointer' }}
        onClick={props.onDelete}
        icon={faTrash}
      />
    </div>

  </div>
));

interface SortableAdvertisementListProps {
  ads: MarketplaceAdvertisement[];
  onDelete: (index: number) => void;
}

const SortableAdvertisementList = SortableContainer((props: SortableAdvertisementListProps) => (
  <div className="sortable-ad-row-container">
    {props.ads.map((v, index) => (
      <SortableAdvertisement key={v.id} ad={v} index={index} onDelete={() => props.onDelete(index)} />
    ))}
  </div>
));

export const PostForm = (props: IProps) => {

  const [sites, setSites] = React.useState<MarketplaceSite[]>([]);
  const [licensors, setLicensors] = React.useState<AffinityClient[]>([]);
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const [adModalShown, setAdModalShown] = React.useState(false);

  const quill = React.useRef<ReactQuill | null>(null);

  React.useEffect(() => {
    getSites();
    getLicensors();

  },              []);

  const initialValues = {
    description: props.post ? props.post.description : '',
    image: null,
    licensor: props.post && props.post.licensor ? props.post.licensor.id : null,
    site: props.post && props.post.site ? props.post.site.id : null,
    title: props.post ? props.post.title : '',
    published: props.post && props.post.publishedAt ? props.post.publishedAt.format('YYYY-MM-DD')  : '',
    ads: props.post ? props.post.ads : [],
    tags: props.post ? props.post.tags : [],
  };

  const validation = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    description: Yup.string().required('Content is required.'),
    image: props.post ? Yup.mixed().notRequired() : Yup.mixed().required('Image is required.'),
  });

  const getSites = async () => {
    const s = await Axios.get('/api/marketplace/sites');
    const siteLists = s.data.data.map((i: any) => MarketplaceSite.fromApi(i));
    setSites(siteLists);
  };

  const getLicensors = async () => {
    const l = await Axios.get('/api/licensors');
    const licensorList = l.data.data.map((i: any) => new AffinityClient(i));
    setLicensors(licensorList);
  };

  const appendImage = async (e: any) => {
    setUploadingImage(true);
    const current = quill.current;
    const files = e.target.files;
    if (current && files && files[0]) {
      const editor: Quill = current.getEditor();
      const selection = editor.getSelection();
      let position = editor.getLength() - 1;
      if (selection) {
        position = selection.index;

      }
      const formData = new FormData();
      formData.append('image', files[0]);
      const upload = await Axios.post('/api/marketplace/posts/images', formData);
      editor.insertEmbed(position, 'image', upload.data.data);
    }
    setUploadingImage(false);
  };

  const siteOptions = sites.map(s => (<option key={s.id} value={s.id}>{s.title}</option>));
  const licensorOptions = licensors.map(l => (<option key={l.id} value={l.id}>{l.shortName}</option>));

  const quillModules = {
    toolbar: {
      container: [
        [{ header: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list : 'bullet' }, { align: [] }],
        ['blockquote', 'link', 'image'],
      ],
      handlers: {
        image: async (handler: any, action: any) => {
          const current = quill.current;
          if (current) {
            const fileInput = document.createElement('input');
            fileInput.setAttribute('type', 'file');
            fileInput.setAttribute(
              'accept',
              'image/png, image/gif, image/jpeg, image/bmp, image/x-icon',
            );

            fileInput.addEventListener('change', appendImage);
            fileInput.click();
          }
        },
      },
    },
  };

  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit} validationSchema={validation}>
      {(formProps: FormikProps<any>) => (
        <Form>
          <div className="form-group">
            <label>Title</label>
            <Field placeholder="Post Title" className="form-control" name="title" />
            <p className="text-danger">
              <ErrorMessage name="title" />
            </p>
          </div>
          {props.post ? (
            <div className="form-group">
              <label>Current Image</label>
              <img style={{ maxHeight: 200 }} src={props.post.image && props.post.image.getSize('sm')} className="img-responsive" />
            </div>
          ) : null}
          <div className="form-group">
            <label>Post Image</label>
            <input
              type="file"
              name="image"
              onChange={e => formProps.setFieldValue('image', e.target.files && e.target.files[0])}
            />
            <p className="text-danger">
              <ErrorMessage name="image" />
            </p>
          </div>
          <div className="form-group">
            <label>Site</label>
            <Field component="select" className="form-control" name="site" >
              <option selected disabled>Select Site</option>
              {siteOptions}
            </Field>
          </div>
          <div className="form-group">
            <label>Licensor</label>
            <Field component="select" className="form-control" name="licensor" >
              <option selected disabled>Select Licensor</option>
              {licensorOptions}
            </Field>
          </div>
          <div className="form-group">
            <label>Published Date</label>
            <PikadayReact
              value={formProps.values.published}
              placeholder="Published Date"
              onChange={date => formProps.setFieldValue('published', date)}
            />

          </div>
          <div className="form-group">
            <label>Tags</label>
            <PostTagTypeahead
              selected={formProps.values.tags}
              onChange={t => formProps.setFieldValue('tags', t)}
            />

          </div>
          <div className="form-group">
            <label>Ads</label>
            <FieldArray
              name="ads"
              render={arrayHelpers => (
                <div>
                  <SortableAdvertisementList
                    ads={formProps.values.ads}
                    useDragHandle
                    onDelete={arrayHelpers.remove}
                    onSortEnd={({ oldIndex, newIndex }) => arrayHelpers.swap(oldIndex, newIndex)}
                  />
                  <button className="btn btn-default" type="button" onClick={() => setAdModalShown(true)}>Select Ads</button>
                  <AvailableAdModal
                    site={formProps.values.site}
                    licensor={formProps.values.licensor}
                    shown={adModalShown}
                    onClose={() => setAdModalShown(false)}
                    selectedAds={props.post ? props.post.ads : []}
                    selectAd={arrayHelpers.push}
                  />
                </div>

              )}

            />

          </div>

          <div className="form-group">
            <label>Content {uploadingImage ? ' - Uploading Image...' : null}</label>
            <ReactQuill
              ref={quill}
              style={{ background: 'white' }}
              value={formProps.values.description}
              onChange={body => formProps.setFieldValue('description', body)}
              modules={quillModules}

              placeholder="Enter Message"
            />
            <p className="text-danger">
              <ErrorMessage name="description" />
            </p>

          </div>

          <button type="submit" className="btn btn-primary">{

            props.submitting ?
              'Submitting...' :
              `${props.post ? 'Update' : 'Create' } Post`
          }</button>
        </Form>
      )}

    </Formik>

  );

};
