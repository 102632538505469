
export interface ImageApiResponse {
  id: number;
  previous_file_name: string;
  urls: {
    th: string;
    sm: string;
    md: string;
    or: string;
  };
}

interface ImageURLS {
  th: string;
  sm: string;
  md: string;
  or: string;
}

export type ImageSize = 'th'| 'sm' | 'md' | 'or';

export class AffinityImage {
  id: number;
  previousFileName: string;
  urls: ImageURLS;
  defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/alp-icon.png';

  static fromApi(data: ImageApiResponse) {
    const image = new AffinityImage();
    image.id = data.id;
    image.previousFileName = data.previous_file_name;
    image.urls = data.urls;
    return image;
  }

  getSize(size: ImageSize) {
    if (this.urls) {
      if (this.urls[size]) {
        return this.urls[size];
      }
      return this.urls.or;
    }
    return this.defaultImage;
  }

  get isUsingDefault() {
    return this.urls ? false : true;
  }

}
