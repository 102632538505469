import Axios from 'axios';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { MarketplacePost, MarketplacePostTag } from '../../../shared';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { PostForm } from './PostForm';

export const PostDetailPage = () => {

  const [post, setPost] = React.useState<MarketplacePost>();
  const [updating, setUpdating] = React.useState(false);
  const match = useRouteMatch();
  const user = React.useContext(UserContext);

  if (user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    getPost();
  },              []);

  const getPost = async () => {
    const id = match && match.params['id'];
    const p = await Axios.get(`/api/marketplace/posts/${id}`);
    setPost(MarketplacePost.fromApi(p.data.data));
  };

  const updatePost = async (values: any) => {
    setUpdating(true);
    const id = match && match.params['id'];
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('licensor_id', values.licensor);
    formData.append('site_id', values.site);
    formData.append('published_at', values.published);
    if (values.image) {
      formData.append('image', values.image);
    }
    formData.append('ads', values.ads.map((a: any) => a.id));
    if (values.tags && values.tags.length) {
      values.tags.forEach((t: MarketplacePostTag) => formData.append('tags[]', `${t.id}`));
    }

    const p = await Axios.post(`/api/marketplace/posts/${id}`, formData);
    setPost(MarketplacePost.fromApi(p.data.data));
    setUpdating(false);
  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Post')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3><strong>Marketplace Post</strong></h3>
          <hr />
          {post ? <PostForm post={post} submitting={updating} onSubmit={updatePost}  /> : <LoadingSpinner />}

        </div>
      </div>
    </FullContent>
  );
};
