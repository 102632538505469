import { faCheckCircle, faExclamationCircle, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { AffinityClient, RoyaltyReportLineItem } from '../../../shared';

interface IAggregateRowProps {
  lineItems: RoyaltyReportLineItem[];
  licensor: AffinityClient;
  fieldValuesUpdated: (lineItem: RoyaltyReportLineItem, field: 'gross' | 'quantity' | 'total', value: string) => any;
  submitting: boolean;
  fileUploaded: boolean;
  uploadFile: (client: AffinityClient, file: any) =>  any;
  uploadingFile: boolean;
  deleteFile: (client: AffinityClient) => void;

}

interface IAggregateFormValues {
  grossSales?: any;
  quantity?: any;
  totalRoyalties?: any;
}

class AggregateRow extends React.Component<IAggregateRowProps, any> {

  public constructor(props: IAggregateRowProps) {
    super(props);
    this.uploadFile = this.uploadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }

  setValueForLineItem(lineItem: RoyaltyReportLineItem, field: 'gross' | 'quantity' | 'total', value: string) {

  }

  uploadFile(files: any) {
    this.props.uploadFile(this.props.licensor, files[0]);
  }

  deleteFile() {
    this.props.deleteFile(this.props.licensor);
  }

  requiresFile() {
    let requiresFile = false;
    this.props.lineItems.forEach((lineItem) => {
      if (!lineItem.isReadOnly) {
        if (lineItem.schedule.usesGrossSales()) {
          if (Number(lineItem.grossSales) !== 0 && !this.props.fileUploaded) {
            requiresFile = true;
          }
        }
        if (lineItem.schedule.usesQuantity()) {
          if (Number(lineItem.quantity) !== 0 && !this.props.fileUploaded) {
            requiresFile = true;
          }

        }
        if (lineItem.schedule.usesTotalRoyalties()) {
          if (Number(lineItem.royalty) !== 0 && !this.props.fileUploaded) {
            requiresFile = true;
          }
        }

      }

    });
    return requiresFile;
  }

  public render() {

    const subrows = this.props.lineItems.map((lineItem, index) => {
      let licensorColumn;
      if (index === 0) {
        let fileUploadedStatus;
        let fileUploadRequiredIcon;
        let fileUploadSuccessIcon;
        if (this.props.uploadingFile) {
          fileUploadRequiredIcon = <FontAwesomeIcon icon={faSpinner} spin />;
          fileUploadSuccessIcon = <FontAwesomeIcon icon={faSpinner} spin />;

        } else {
          fileUploadRequiredIcon = <FontAwesomeIcon className="text-danger" icon={faExclamationCircle} />;
          fileUploadSuccessIcon = <FontAwesomeIcon className="text-success" icon={faCheckCircle} />;

        }

        if (this.props.fileUploaded) {
          fileUploadedStatus = (
            <div style={{ display: 'flex' }}>
              <span className="text-muted">
                {fileUploadSuccessIcon} Detail file uploaded - &nbsp;
              </span>
              <div style={{ display : 'flex' }}>
                <Dropzone onDrop={this.uploadFile}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} style={{ cursor: 'pointer' }} className="link-drop-zone">
                      <input {...getInputProps()} />
                      <a>Replace </a>
                    </div>
                  )}
                </Dropzone>
                <span style={{ marginLeft: 10, cursor: 'pointer' }} className="text-danger" onClick={this.deleteFile}>
                  Delete
                </span>
              </div>
            </div>
          );
        } else {
          if (this.requiresFile()) {
            fileUploadedStatus = (
              <div style={{ display: 'flex' }}>
                <span className="text-muted">
                  {fileUploadRequiredIcon} Detail file required - &nbsp;
                </span>
                <Dropzone onDrop={this.uploadFile}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} style={{ cursor: 'pointer' }}>
                      <input {...getInputProps()} />
                      <a>Upload </a>
                    </div>
                  )}
                </Dropzone>
              </div>
            );
          }
        }
        licensorColumn = (
            <td style={{ verticalAlign: 'top', background: 'white' }} rowSpan={this.props.lineItems.length}>
              <h5>{this.props.licensor.name}</h5>
              {fileUploadedStatus}
            </td>
          );
      } else {
        licensorColumn = null;
      }

      let grossSalesColumn;
      if (lineItem.isReadOnly) {

        const grossSalesValue = lineItem.grossSales ?
          <p style={{ margin: 0 }} className="text-center">
            <strong>
              <NumberFormat
                value={lineItem.grossSales}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </strong>
          </p> :
          <hr></hr>;
        grossSalesColumn = (
            <td style={{ verticalAlign: 'middle' }}>
              {grossSalesValue}
            </td>
          );

      } else {
        if (lineItem.schedule.usesGrossSales()) {
          const grossInitialValues: IAggregateFormValues = {
            grossSales: lineItem.grossSales !== null && lineItem.grossSales !== undefined ?
            Number(lineItem.grossSales).toFixed(2) : '',
          };
          const grossValidationSchema = Yup.object().shape({
            grossSales: Yup.number().required(),
          });
          grossSalesColumn = (
              <td style={{ minWidth: 150, verticalAlign: 'middle' }}>
                <Formik
                  initialValues={grossInitialValues}
                  enableReinitialize={true}
                  validationSchema={grossValidationSchema}
                  onSubmit={(values: any) => console.log(values)}
                >

                  {(formProps: FormikProps<IAggregateFormValues>) => {
                    let validationClassName;
                    if (formProps.errors.grossSales) {
                      validationClassName = 'form-group has-error';
                    } else {
                      validationClassName = 'form-group';
                    }
                    return (
                      <div style={{ margin: 0 }} className={validationClassName}>
                        <div className="input-group">
                          <span className="input-group-addon">$</span>
                          <input
                            autoComplete="off"
                            name="grossSales"
                            value={formProps.values.grossSales}
                            onChange={formProps.handleChange}
                            onBlur={event =>
                              this.props.fieldValuesUpdated(lineItem, 'gross', event.target.value)
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </td>
            );
        } else {
          grossSalesColumn = (
              <td style={{ verticalAlign: 'middle' }}>
                <hr></hr>
              </td>
            );

        }

      }

      let quantityColumn;
      if (lineItem.isReadOnly) {
        const quantityValue = lineItem.quantity ?
        <p style={{ margin: 0 }} className="text-center"><strong>{lineItem.quantity}</strong></p> : <hr></hr>;
        quantityColumn = (
            <td style={{ verticalAlign: 'middle' }}>
            {quantityValue}
          </td>
        );

      } else {
        if (lineItem.schedule.usesQuantity()) {
          const quantityInitialValues: IAggregateFormValues = {
            quantity: lineItem.quantity !== null && lineItem.quantity !== undefined ? lineItem.quantity : '',
          };
          const quantityValidationSchema = Yup.object().shape({
            quantity: Yup.number().integer().required(),
          });
          quantityColumn = (
              <td style={{ verticalAlign: 'middle' }}>
                <Formik
                  initialValues={quantityInitialValues}
                  enableReinitialize={true}
                  validationSchema={quantityValidationSchema}
                  onSubmit={(values: any) => console.log(values)}
                >

                  {(formProps: FormikProps<IAggregateFormValues>) => {
                    let validationClassName;
                    if (formProps.errors.quantity) {
                      validationClassName = 'form-group has-error';
                    } else {
                      validationClassName = 'form-group';
                    }
                    return (
                      <div className="aggregate-royalty-table-input">
                        <div style={{ margin: 0 }} className={validationClassName} >
                          <input
                            autoComplete="off"
                            className="form-control"
                            name="quantity"
                            value={formProps.values.quantity}
                            onChange={formProps.handleChange}
                            onBlur={event =>
                              this.props.fieldValuesUpdated(lineItem, 'quantity', event.target.value)
                            }
                            ></input>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </td>

            );

        } else {
          quantityColumn = (
              <td style={{ verticalAlign: 'middle' }}>
                <hr></hr>
              </td>
            );

        }

      }

      let totalColumn;
      if (lineItem.isReadOnly) {
        const totalValue = lineItem.royalty ?
          <p style={{ margin: 0 }} className="text-right">
            <strong>
              <NumberFormat
                value={lineItem.royalty}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </strong>
          </p>
          : <hr></hr>;
        totalColumn = (
            <td style={{ verticalAlign: 'middle' }}>
              {totalValue}
            </td>
          );

      } else {
        if (lineItem.schedule.usesTotalRoyalties()) {
          const totalInitialValues: IAggregateFormValues = {
            totalRoyalties: lineItem.royalty !== null && lineItem.royalty !== undefined ?
             Number(lineItem.royalty).toFixed(2) : '',
          };
          const totalValidationSchema = Yup.object().shape({
            totalRoyalties: Yup.number().required(),
          });

          totalColumn = (
              <td style={{ minWidth: 150, verticalAlign: 'middle' }}>
                <Formik
                  initialValues={totalInitialValues}
                  enableReinitialize={true}
                  validationSchema={totalValidationSchema}
                  onSubmit={(values: any) => console.log(values)}
                >
                  {(formProps: FormikProps<IAggregateFormValues>) => {
                    let validationClassName;
                    if (formProps.errors.totalRoyalties) {
                      validationClassName = 'form-group has-error';
                    } else {
                      validationClassName = 'form-group';
                    }
                    return (
                      <div className={validationClassName}>
                        <div className="input-group">
                          <span className="input-group-addon">$</span>
                          <input
                            autoComplete="off"
                            name="totalRoyalties"
                            value={formProps.values.totalRoyalties}
                            onChange={formProps.handleChange}
                            onBlur={event =>
                              this.props.fieldValuesUpdated(lineItem, 'total', event.target.value)
                            }
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    );
                  }}
                </Formik>

              </td>
            );

        } else {
          let totalValue;

          if (this.props.submitting) {

            totalValue = (
              <p className="text-center" style={{ paddingLeft: 20, margin: 0 }}>
                <FontAwesomeIcon icon={faSpinner} spin />
              </p>
            );
          } else {
            if (lineItem.royalty) {
              totalValue = (
              <p style={{ margin: 0 }} className="text-right">
                  <NumberFormat
                    value={lineItem.royalty}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
              </p>
              );

            } else {
              totalValue = <hr></hr>;
            }
          }

          totalColumn = (
              <td style={{ verticalAlign: 'middle' }}>
                {totalValue}
              </td>
            );

        }

      }

      return (
          <tr key={lineItem.schedule.id}>
            {licensorColumn}
            <td style={{ verticalAlign: 'middle', maxWidth: 200 }} >
              <p
                title={lineItem.schedule.title}
                style={{ margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
              {lineItem.schedule.title}
              </p>
              <p
                title={lineItem.schedule.labelText()}
                style={{ margin: 0, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                className="text-muted"
              >
                <small>{lineItem.schedule.labelText()}</small>
              </p>
            </td>
            {grossSalesColumn}
            {quantityColumn}
            {totalColumn}
          </tr>
      );
    });

    return (
        <tbody>
          {subrows}
        </tbody>
    );
  }
}

export default AggregateRow;
