import * as React from 'react';
import User from '../../shared/User';

interface IProps {
  users: User[];
}
export const NoteUserList = (props: IProps) => {

  return (
    <div className="note-user-list">
      <div className="note-user-images">
        {props.users.map((u, i) =>
          <img
            key={i}
            style={{ zIndex: props.users.length - i }}
            className="note-user-image"
            src={u.image.getSize('th')}
          />,
        )}
      </div>
      <div className="note-user-names small">
        <strong>
          {props.users.map(u => `${u.firstName} ${u.lastName.slice(0, 1)}`).join(', ')}
        </strong>
      </div>
    </div>
  );

};
