interface MarketplaceSettingsApiResponse {
  default_returns: string;
  default_delivery: string;
  default_minimum_quantity: number;
  default_duration_quantity: number;
  default_duration_term_id: number;
  default_shipping_price_first: number;
  default_shipping_price_additional: number;
  is_upi_required: boolean;
}

export class MarketplaceSettings {
  defaultReturnText: string;
  defaultShippingText: string;
  defaultMinimumQuantity: number;
  defaultDurationQuantity: number;
  defaultDurationTermId: number;
  defaultShippingPriceFirst: number;
  defaultShippingPriceAdditional: number;
  isUpiRequired: boolean;

  static fromApi(data: MarketplaceSettingsApiResponse) {
    const settings = new MarketplaceSettings();
    settings.defaultReturnText = data.default_returns;
    settings.defaultShippingText = data.default_delivery;
    settings.defaultMinimumQuantity = data.default_minimum_quantity;
    settings.defaultDurationQuantity = data.default_duration_quantity;
    settings.defaultDurationTermId = data.default_duration_term_id;
    settings.defaultShippingPriceFirst = data.default_shipping_price_first;
    settings.defaultShippingPriceAdditional = data.default_shipping_price_additional;
    settings.isUpiRequired = data.is_upi_required;
    return settings;
  }
}
