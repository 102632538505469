// Payment requests need an invoice, and:
// PaymentMethod (optionally with Amount)
// OR:
// if admin:
//   PaymentType (optionally with Amount)
//   or:
//   PaymentType & Amount & Line Items

type InvoiceId = string;
type PaymentMethodId = string;
enum PaymentType {
  ACH = 'ach',
  Check = 'check',
  EFT = 'eft',
  Card = 'card',
}

interface PaymentMethodRequest {
  invoiceId: InvoiceId;
  paymentMethodId: PaymentMethodId;
  amount?: number;
}

interface PaymentTypeRequest {
  invoiceId: InvoiceId;
  paymentType: PaymentType;
  amount?: number;
}

type InvoiceLineItemId = string;
interface PaymentLineItem {
  invoiceLineItem: InvoiceLineItemId;
  amount: number;
}

interface PaymentTypeWithLineItemsRequest {
  invoiceId: InvoiceId;
  paymentType: PaymentType;
  invoiceLineItems: PaymentLineItem[];
}

export type PayInvoiceRequest = PaymentMethodRequest | PaymentTypeRequest | PaymentTypeWithLineItemsRequest;
