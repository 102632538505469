import * as React from 'react';
import DocumentTitle from 'react-document-title';
import NumberFormat from 'react-number-format';
import { getVendorRoyaltyMinimumProgress } from '../../api';
import { formattedFiscalYear, getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';

interface IProps {
  vendorId: string;
}

export interface IMinimumEntry {
  licensorName: string;
  licensorImage: string | null;
  minimumGuarantee: number;
  royaltiesReported: number;
  minimumRemaining: number;
}

interface IState {
  isLoading: boolean;
  minimumEntries: IMinimumEntry[];
  royaltiesReportedPercentage: number;
  royaltiesRemaining: number;
  year: number | null;
}

const getPercent = (entry: IMinimumEntry): number => {

  if (entry.minimumGuarantee < 1) {
    return 0;
  }

  const gatedReported = (entry.royaltiesReported < entry.minimumGuarantee) ? (
    entry.royaltiesReported
  ) : (
    entry.minimumGuarantee
  );

  return (gatedReported / entry.minimumGuarantee * 100);
};

const getDollarFormatted = (amount: number) => {
  return <NumberFormat
    value={amount}
    displayType={'text'}
    thousandSeparator={true}
    prefix={'$'}
    decimalScale={2}
    fixedDecimalScale={true}
  />;
};

const getProgressBar = (entry: IMinimumEntry) => {
  const gatedReported = (entry.royaltiesReported < entry.minimumGuarantee) ? (
    entry.royaltiesReported
  ) : (
    entry.minimumGuarantee
  );

  const percent = getPercent(entry).toFixed(0);
  const width = `${percent}%`;

  const progressClass = (percent === '100') ? 'progress-bar-success' : 'progress-bar-info';

  return (
    <div className="progress">
      <div
        className={ `progress-bar ${progressClass}` }
        role="progressbar"
        aria-valuenow={gatedReported}
        aria-valuemin={0}
        aria-valuemax={100}
        style={{ width }}
      >
        <span className="sr-only">{percent}% Complete</span>
      </div>
    </div>
  );
};

const getProgressCell = (entry: IMinimumEntry) => {

  if (!entry.minimumGuarantee) {
    return null;
  }

  const bar = getProgressBar(entry);
  const percent = getPercent(entry);

  const percentMarkup = (
    <div>{ percent.toFixed(0) }%</div>
  );

  return (
    <div>
      {bar}
      {percentMarkup}
    </div>
  );
};

const getRemainingCell = (entry: IMinimumEntry) => {
  if (!entry.minimumGuarantee) {
    return (
      <div className="text-muted">
        <i className="fa fa-check-circle" /> No Minimum
      </div>
    );
  }

  if (entry.royaltiesReported >= entry.minimumGuarantee) {
    return (
      <div className="text-success">
        <i className="fa fa-check-circle text-muted" />
        {' '}
        Minimum Met
      </div>
    );
  }

  return (
    <div className="text-primary">
      { getDollarFormatted(entry.minimumRemaining) }
      {' '}
      Remaining
    </div>
  );
};

export class VendorRoyaltyMinimumsPage extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      minimumEntries: [],
      royaltiesReportedPercentage: 0,
      royaltiesRemaining: 0,
      year: null,
    };
  }

  componentDidMount(): void {
    this.setState({
      isLoading: true,
    });
    getVendorRoyaltyMinimumProgress(this.props.vendorId).then((data) => {

      const minimumEntries = data.minimumEntries;

      const minimumTotal = minimumEntries.reduce(
        (acc, current) => {
          return acc + current.minimumGuarantee;
        },
        0);

      const royaltiesRemaining = minimumEntries.reduce(
        (acc, current) => {
          return acc + current.minimumRemaining;
        },
        0);

      const royaltiesReportedPercentage = minimumTotal > 0 ? ((minimumTotal - royaltiesRemaining) / minimumTotal) : 1;

      this.setState({
        minimumEntries,
        royaltiesReportedPercentage,
        royaltiesRemaining,
        year: data.year,
        isLoading: false,
      });
    });
  }

  render() {

    const breadcrumbs = [{ name: 'Minimum Royalties' }];

    const rows = this.state.minimumEntries.map((entry) => {

      const imageTag = entry.licensorImage ? (
        <img className="pull-left hidden-sm hidden-xs" src={entry.licensorImage} />
      ) : (
        null
      );

      const remainingCell = getRemainingCell(entry);

      const progressCell = getProgressCell(entry);

      return (
        <tr>
          <td style={{ fontWeight: 'bold' }}>
            <a className="load-account">
              { imageTag }
              <strong>{entry.licensorName}</strong>
            </a>
          </td>
          <td>
            <NumberFormat
              value={entry.minimumGuarantee}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </td>
          <td>
            <NumberFormat
              value={entry.royaltiesReported}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </td>
          <td>{progressCell}</td>
          <td>
            {remainingCell}
          </td>
        </tr>
      );
    });

    const descriptionSection = !this.state.isLoading ? (
      <p>
        { (this.state.royaltiesReportedPercentage * 100).toFixed(0) }% of minimum royalties reported and
        {' '}{ getDollarFormatted(this.state.royaltiesRemaining) } remaining this year.
      </p>
    ) : (
      null
    );

    const table = this.state.isLoading ? (
        <LoadingSpinner/>
      ) : (
      <div className="table-responsive">
        <table className="table table-hover table-portal">
        <thead>
        <tr>
          <th scope="col" style={{ minWidth: '200px' }}>Licensor</th>
          <th scope="col">Minimum Royalties</th>
          <th scope="col">Royalties Reported</th>
          <th scope="col">Progress</th>
          <th scope="col">Status</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
        </table>
      </div>
    );

    const pageTitle = this.state.year ? (
      `${formattedFiscalYear(this.state.year)} Minimum Royalties`
    ) : (
      'Minimum Royalties'
    );

    return (
      <DocumentTitle title={getAffinityPageTitle('Minimum Royalties')}>
        <FullContent breadcrumbs={breadcrumbs} >
          <div>
            <div className="row">
              <div className="content-frame-padding">
                <div className="panel panel-portal">
                  <div className="panel-body">
                    <h3>{ pageTitle }</h3>
                    {descriptionSection}
                    {table}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FullContent>
      </DocumentTitle>
    );

  }

}
