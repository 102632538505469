import Axios, { CancelTokenSource } from 'axios';
import { Field, Formik, FormikActions, FormikProps } from 'formik';
import * as React from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { getPayoutAccount, submitPayoutAccount } from '../../../api';
import { StripeService } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { MarketplacePayoutAccount, PayoutAccount, PayoutAccountStatus, PayoutDefaultValues, PayoutValidationSchema } from './PayoutAccount';
import {
  VerificationDefaultValues,
  VerificationSettings,
  VerificationStatus,
  VerificationValidationSchema,
} from './VerificationSettings';

interface IState {
  vendorId: number | null;
  verificationStatus: VerificationStatus;
  payoutAccountStatus: PayoutAccountStatus;
  payoutAccount: MarketplacePayoutAccount | null;
  stripeErrors: string;
  validationErrors: string;
  loading: boolean;
}

export class MarketplaceSignup extends React.Component <any, IState> {

  protected _getPayoutAccountSource: CancelTokenSource;
  protected _submitPayoutAccountSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      vendorId: this.props.match.params['vendorId'] ? this.props.match.params['vendorId'] : null,
      verificationStatus: VerificationStatus.NotSubmitted,
      payoutAccountStatus: PayoutAccountStatus.NotSubmitted,
      payoutAccount: null,
      validationErrors: '',
      stripeErrors: '',
      loading: true,
    };
    this.getSignupStatus = this.getSignupStatus.bind(this);
    this.submitVerification = this.submitVerification.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.getSignupStatus();
  }

  componentWillUnmount() {
    if (this._getPayoutAccountSource) {
      this._getPayoutAccountSource.cancel('Cancelled getPayoutAccount() XHR due to unmount');
    }
    if (this._submitPayoutAccountSource) {
      this._getPayoutAccountSource.cancel('Cancelled getPayoutAccount() XHR due to unmount');
    }
  }

  getSignupStatus() {
    if (this._getPayoutAccountSource) {
      this._getPayoutAccountSource.cancel('Cancelled getOrders() XHR due to new request');
    }
    this._getPayoutAccountSource = Axios.CancelToken.source();
    getPayoutAccount(this.state.vendorId, this._getPayoutAccountSource).then((resp) => {
      this.setState({
        loading: false,
        verificationStatus: resp.verificationStatus,
        payoutAccountStatus: resp.payoutAccountStatus,
        payoutAccount: resp.payoutAccount,
      });
    });

  }

  submitVerification(values: any): Promise<boolean> {
    if (this._submitPayoutAccountSource) {
      this._submitPayoutAccountSource.cancel('Cancelled getOrders() XHR due to new request');
    }
    this._submitPayoutAccountSource = Axios.CancelToken.source();
    return submitPayoutAccount(values, this.state.vendorId, this._submitPayoutAccountSource).then((response) => {
      this.setState({
        verificationStatus: response.verificationStatus,
        payoutAccountStatus: response.payoutAccountStatus,
        payoutAccount: response.payoutAccount,
      });

      return true;
    }).catch((err) => {
      if (err.response && err.response.status === 422) {
        const errorMap = err.response.data.errors;
        this.setState({ validationErrors: errorMap[Object.keys(errorMap)[0]][0] });
      }

      return false;
    });
  }

  submitForm(values: any, actions: FormikActions<any>) {
    const stripe = new StripeService();
    stripe.generateBankToken(
      {
        accountHolderType: values.businessType,
        accountHolderName: `${values.firstName} ${values.lastName}`,
        accountNumber: values.accountNumber,
        routingNumber: values.routingNumber,
      },
      (status: any, response: any) => {
        if (status === 200) {
          const valuesWithToken = { stripeToken: response.id, ... values };
          this.submitVerification(valuesWithToken).then((success) => {
            if (!success) {
              actions.setSubmitting(false);
              document.getElementsByClassName('verification-validation-errors')[0].scrollTo(0, 0);
            }
          });
        } else {
          const error = response.error.message;
          this.setState({ stripeErrors: error });
          actions.setSubmitting(false);
        }

      });

  }

  render() {

    const verificationStatus = this.state.verificationStatus;
    const payoutAccountStatus = this.state.payoutAccountStatus;

    const initialValues = { agree: false, ... VerificationDefaultValues, ... PayoutDefaultValues };

    const validationSchema = Yup.object().concat(Yup.object().shape({
      agree: Yup.boolean().required().oneOf([true]),
    })).concat(VerificationValidationSchema).concat(PayoutValidationSchema);

    const verificationForm = this.state.loading ? (
      <div className="panel panel-portal">
        <div className="panel-body">
          <LoadingSpinner />
        </div>
      </div>
    ) : (
      <div className="panel panel-portal">
        <div className="panel-body">
          <Formik
            validationSchema={validationSchema}
            onSubmit={this.submitForm}
            initialValues={initialValues}
          >
           {(formikProps: FormikProps<any>) =>
              <form onSubmit={formikProps.handleSubmit}>
                <VerificationSettings
                  formProps={formikProps}
                  verificationStatus={verificationStatus}
                  onSubmit={this.submitVerification}
                />

                <div className="verification-validation-errors">
                { this.state.validationErrors ? (
                  <div style={{ color: 'red' }}>{this.state.validationErrors}</div>
                ) : null }
                </div>

                <hr />

                <PayoutAccount
                  formProps={formikProps}
                  account={this.state.payoutAccount}
                  status={payoutAccountStatus}
                  error={this.state.stripeErrors}
                />

                 {verificationStatus === VerificationStatus.NotSubmitted ? (
                    <div>

                      <hr />
                        <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                        }}
                        >
                          <h4 className="text-center text-primary">Review and Submit</h4>
                          <img
                            style={{ maxWidth: '100%', height: 270 }}
                            src="/assets/images/affinity-marketplace-pricing.jpg"
                          />
                          <div className={`checkbox text-center ${formikProps.errors.agree ? 'has-error' : ''}`}>
                            <label>
                              <Field type="checkbox" name="agree" />
                              I agree to the Marketplace&nbsp;
                              <Link to="/marketplace/terms-of-use" target="_blank">Terms of Use</Link>
                              , <Link to="/marketplace/seller-policy" target="_blank">Seller Policy</Link>
                              , and the <a
                                href="https://stripe.com/us/connect-account/legal"
                                target="_blank"
                                >
                                  Stripe Connected Account Agreement
                                </a>.
                          </label>
                          </div>

                        <br />
                        <button
                            disabled={formikProps.isSubmitting}
                            className="btn btn-primary btn-lg"
                            type="submit"
                          >
                            { formikProps.isSubmitting ? 'Submitting ...' : 'Sign Up' }
                          </button>
                          <br />
                        </div>

                    </div>
                  ) : null}
            </form>
            }

          </Formik>
        </div>
      </div >
    );

    return (
      <FullContent>
        <div>
          <div className="panel panel-portal">
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-10 col-xs-offset-1">
                  <h4 className="text-center text-primary">Marketplace Sign Up</h4>
                  <p className="text-center">
                    Complete the form below to setup your Affinity Marketplace account.
                        </p>
                </div>
              </div>
            </div>
          </div>

          {verificationForm}
        </div>

      </FullContent>

    );
  }
}
