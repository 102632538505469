import { Field, FormikProps } from 'formik';
import * as React from 'react';

interface IProps {
  formProps: FormikProps<any>;
}
export class FontTypeForm extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <div
          className={
            `form-group ${this.props.formProps.errors.font && this.props.formProps.touched.font ? 'has-error' : ''}`
          }
        >
          <label>Web Font</label>
          <Field name="font" type="text" placeholder="http://fonts.googleapis.com/css?family=..." className="form-control" />
        </div>

      </div>
    );
  }
}
