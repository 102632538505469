import { faFileArchive, faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { RoyaltyReport } from '../../../shared';
import { File } from '../../../shared/File';

interface IProps {
  report: RoyaltyReport;
}

const ReportOldFiles = (props: IProps) => {

  const report = props.report;

  if (report.oldFiles.length === 0) {
    return <></>;
  }

  const files = report.oldFiles;
  const zipUrl = `/royalties/${report.id}/zip`;

  return (
    <div className="list-group">
      <div className="list-group-item">
        Old Files
      </div>
      <div className="list-group-item" style={{ padding: 8 }}>
        <div className="btn-group full-buttons">
          <a href={zipUrl} target="_blank" className="btn btn-default btn-sm btn-sm text-center">
            <FontAwesomeIcon icon={faFileArchive} />Download All
          </a>
        </div>
      </div>
      {files.map((file: File) => (
        <div className="list-group-item">
          <a target="_blank" href={file.url}>
            <FontAwesomeIcon icon={faFilePdf} fixedWidth style={{ float: 'left', marginTop: 3 }}/>
            <div className="text-ellipsis">{file.previousFileName}</div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default ReportOldFiles;
