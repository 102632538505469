import Axios, { CancelTokenSource } from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { isArray, isString } from 'lodash';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { AccountAlias, AccountWebUrlType } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { FormImageWithPreview } from '../../shared/forms';
import { getAlias } from '../api';
import { ExternalWebsitesPage } from './ExternalWebsitesPage';

interface IState {
  loading: boolean;
  saving: boolean;
  alias: AccountAlias | null;
  urlTypes: AccountWebUrlType[];
  error: string;
}

interface AliasFormValues {
  logo: File | null;
  logoPreview: string;
  name: string;
  phone: string;
  bannerImage: File | null;
  bannerPreview: string;
  webUrls: {type: AccountWebUrlType, url: string}[];
  currentType: AccountWebUrlType | null;
}

export class AliasFormPage extends React.Component<RouteComponentProps<any>, IState> {

  private _getAliasSource: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      loading: !this.isNew,
      saving: false,
      alias: null,
      urlTypes: [],
      error: '',
    };
    this.getAlias = this.getAlias.bind(this);
    this.getUrlTypes = this.getUrlTypes.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.createAlias = this.createAlias.bind(this);
    this.updateAlias = this.updateAlias.bind(this);
  }

  componentDidMount() {
    if (!this.isNew) {
      this.getAlias();
    } else {
      this.getUrlTypes();
    }

  }

  render() {
    let body;
    if (this.state.loading) {
      body = (<LoadingSpinner />);
    } else {
      const initialValues: AliasFormValues = {
        logo: null,
        logoPreview: '',
        name: '',
        phone: '',
        bannerImage: null,
        bannerPreview: '',
        webUrls: [],
        currentType: null,

      };
      if (this.state.alias) {
        const alias = this.state.alias;
        initialValues.name = alias.name;
        initialValues.phone = alias.phone;
        initialValues.logoPreview = alias.logo.getSize('sm');
        initialValues.bannerPreview = alias.banner.getSize('sm');
      }

      const validation = Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        webUrls: Yup.array().of(
          Yup.object({
            url: Yup.string().url('Please enter a valid url. It must start with https:// or http:// to be valid.').required('Url is required'),
          }),
        ),
      });
      body = (
        <div>
          <h2><strong>{this.isNew ? 'New Alias' : 'Edit Alias'}</strong></h2>
          <hr />
          <div className="row">
            <div className="col-sm-offset-4 col-sm-8">
              {this.state.error ? <div className="alert alert-danger">{this.state.error}</div> : null}
            </div>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={this.onSubmit}
            validationSchema={validation}
            validateOnChange={false}
          >

            {(formProps: FormikProps<AliasFormValues>) =>
              (
                <Form className="form-horizontal">

                  <FormImageWithPreview imagePreview={formProps.values.logoPreview} onChange={(preview, file) => {
                    formProps.setFieldValue('logo', file);
                    formProps.setFieldValue('logoPreview', preview);
                  }} title="Logo" labelClass="col-sm-4" fieldClass="col-sm-8" />
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Name</label>
                    <div className="col-sm-8">
                      <Field placeholder="Name" name="name" className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name="name" />
                      </p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Phone</label>
                    <div className="col-sm-8">
                      <Field placeholder="Phone" name="phone" className="form-control" />
                    </div>
                  </div>

                  <FormImageWithPreview imagePreview={formProps.values.bannerPreview} onChange={(preview, file) => {
                    formProps.setFieldValue('bannerImage', file);
                    formProps.setFieldValue('bannerPreview', preview);
                  }} title="Banner" labelClass="col-sm-4" fieldClass="col-sm-8" imagePreviewWidth={150}  />

                  {this.isNew ? <FieldArray
                    name="webUrls"
                    render={(arrayHelpers) => {

                      return (
                        <div>
                          <div className="form-group">
                            <label className="col-sm-4 control-label">Web Link</label>
                            <div style={{ display: 'flex', alignItems: 'center' }} className="col-sm-8">
                              <select
                                value={formProps.values.currentType ? formProps.values.currentType.id : ''}
                                onChange={e =>
                                  formProps.setFieldValue(
                                    'currentType', this.state.urlTypes.find(t => t.id === Number(e.target.value)))
                                }
                                name="type"
                                className="form-control"
                              >
                                <option value="" disabled selected>Select Type</option>
                                {this.state.urlTypes.map(t => <option value={t.id}>{t.name}</option>)}
                              </select>
                              <button
                                type="button"
                                onClick={() => {
                                  if (formProps.values.currentType) {
                                    arrayHelpers.push({ url: '', type: formProps.values.currentType });
                                    formProps.setFieldValue('currentType', null);
                                  }

                                }}
                                className="btn btn-primary"
                                style={{ marginLeft: 20 }}
                              >
                                Add Link Type
                              </button>
                            </div>
                          </div>
                          {formProps.values.webUrls.map((g, index) => (
                            <div style={{ marginBottom: 15 }}>
                              <div className="form-group">
                                <label className="col-sm-4 control-label">{g.type.name} Link</label>
                                <div className="col-sm-8">
                                  <div style={{ display: 'flex', alignItems: 'center' }} >
                                    <Field placeholder="https://example.com" name={`webUrls.${index}.url`} className="form-control" />
                                    <button
                                      type="button"
                                      style={{ marginLeft: 10 }}
                                      onClick={() => arrayHelpers.remove(index)}
                                      className="btn btn-danger btn-sm"
                                    >
                                      Remove
                                    </button>
                                  </div>
                                  <p className="text-danger">
                                    <ErrorMessage name={`webUrls.${index}.url`} />
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}

                        </div>

                      );
                    }}

                  /> : null}

                  {!this.isNew ? <button
                    onClick={() => formProps.resetForm()}
                    className="btn btn-default btn-lg pull-left"
                    type="button">
                    Reset
                    </button>
                    : null}
                  <button className="btn btn-primary btn-lg pull-right" type="submit">
                    {this.state.saving ? 'Saving...' : 'Save'}
                  </button>

                </Form>

              )}

          </Formik>

      </div >
      );

    }

    return (
      <div>

        <FullContent>
          <div className="panel panel-portal">
            <div className="panel-body">
              {body}
            </div>
          </div>
        {!this.isNew ? <ExternalWebsitesPage aliasId={this.aliasID} /> : null}
        </FullContent>
      </div>
    );
  }

  onSubmit(values: AliasFormValues) {
    if (this.isNew) {
      this.createAlias(this.formValuesToAPI(values));
    } else {
      this.updateAlias(this.formValuesToAPI(values));
    }
  }

  getAlias() {
    this.setState({ loading: true });
    if (this._getAliasSource) {
      this._getAliasSource.cancel('Cancelled getAlias() XHR due to new request.');
    }
    this._getAliasSource = Axios.CancelToken.source();
    getAlias(this.aliasID, this._getAliasSource)
      .then(response => this.setState({ alias: AccountAlias.fromApi(response.data.data), loading: false }));
  }
  async getUrlTypes() {
    const t = await Axios.get('/api/account-web-url-types');
    this.setState({ urlTypes: t.data.data.map((type: any) => AccountWebUrlType.fromApi(type)) });

  }

  async updateAlias(params: any) {
    this.setState({ saving: true });
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await Axios.post(`/api/account-aliases/${this.aliasID}`, params, { headers })
      .catch((error) => {
        const message = `There was an error updating the alias: ${this.errorMessageHandler(error.response.data)}`;
        this.setState({ error: message, saving: false });
      });

    if (response && response.data.data) {
      this.props.history.push(this.backRoute);
      this.setState({ saving: false, error: '' });
    }

  }

  async createAlias(params: any) {
    this.setState({ saving: true });

    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const create = await Axios.post('/api/account-aliases', params, { headers })
      .catch((error) => {
        const message = `There was an error saving the alias: ${this.errorMessageHandler(error.response.data)}`;
        this.setState({ error: message, saving: false });
      });
    if (create && create.data) {
      this.props.history.push(this.backRoute);
      this.setState({ saving: false, error: '' });

    }

  }

  errorMessageHandler(error: any) {
    const errorList = Object.values(error);
    const stringErrors = errorList.map((e) => {
      if (isString(e)) {
        return e;
      }
      if (isArray(e)) {
        return e.join(' ');
      }
      return '';
    });
    return stringErrors.join(' ');
  }

  formValuesToAPI(values: AliasFormValues) {
    // TODO add images
    const formData = new FormData();
    formData.append('name', values.name);
    if (values.phone) {
      formData.append('phone', values.phone);
    }
    formData.append('account_id', this.accountID);
    if (values.logo) {
      formData.append('logo', values.logo, values.logo.name);
    }
    if (values.bannerImage) {
      formData.append('banner', values.bannerImage, values.bannerImage.name);
    }
    values.webUrls.forEach((l) => {
      formData.append('url_types[]', `${l.type.id}`);
      formData.append('urls[]', `${l.url}`);
    });
    return formData;

  }

  smValidation() {

  }

  get aliasID() {
    return this.props.match.params['id'];
  }

  get isNew() {
    return this.props.location.pathname.indexOf('new') > -1;
  }

  get backRoute() {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.accountID}/settings/alias`;
    }
    if (this.props.match.params['licensorId']) {
      return `/clients/${this.accountID}/settings/alias`;
    }
    return '';
  }

  get accountID() {
    let id;
    if (this.props.match.params['vendorId']) {
      id = this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      id = this.props.match.params['licensorId'];
    }
    return id;
  }

}
