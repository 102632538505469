import { faBars, faCircle, faCog, faQuestionCircle, faSignOut, faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';
import * as React from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { GatewayModal, ModalType } from '../shared';
// import { UserContext, UserType } from '../../contexts';
// import { Notifications } from './Notifications';

interface IState {
  showLogout: boolean;
}

interface IProps {
  isAccountRoute: boolean;
  secondaryAccount: any;
  toggleNav: () => any;

}
export class Header extends React.Component<IProps, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      showLogout: false,
    };
    this.showLogout = this.showLogout.bind(this);
    this.closeLogout = this.closeLogout.bind(this);
  }

  logout() {
    localStorage.removeItem('currentUser');
    if (typeof window['Intercom'] === 'function') {
      window['Intercom']('shutdown');
    }
  }

  showLogout() {
    this.setState({ showLogout: true });

  }

  closeLogout() {
    this.setState({ showLogout: false });
  }

  statusColor(status: number) {
    if (status ===  2) {
      return 'text-success';
    }
    if (status === 4 || status === 1) {
      return 'text-info';
    }
    if (status === 5) {
      return 'text-danger';
    }
    if (status === 3) {
      return 'text-warning';
    }
    return 'text-muted';

  }

  render() {
    const secondary = this.props.secondaryAccount;
    const accountName = this.props.isAccountRoute && secondary.id ?
      (<h5 className="status-account-name">
        <OverlayTrigger placement="bottom" overlay={(<Tooltip id="account-status">{secondary.status.display}</Tooltip>)}>
          <FontAwesomeIcon
            className={this.statusColor(Number(secondary.status.id))}
            icon={faCircle}
          />
        </OverlayTrigger>
        <Link className="text-primary" to={secondary.url}>{getDisplayAccountName(secondary)}</Link>
        </h5>)
      : (<h5></h5>);

    const helpTooltip = (<Tooltip id="help-tooltip">Help</Tooltip>);
    const profileTooltip = (<Tooltip id="profile-tooltip"> Profile </Tooltip>);
    const settingsTooltip = (<Tooltip id="settings-tooltip"> Settings </Tooltip>);

    const logoutTooltip = (<Tooltip id="logout-tooltip"> Log Out </Tooltip>);
    const settingsLink = secondary.id ? `${secondary.url}/settings` : '/settings';
    const notice = this.props.isAccountRoute && secondary.flag && Number(secondary.flag.id) === 2 ?
      (
        <OverlayTrigger placement="bottom" overlay={
          (<Tooltip>{moment(secondary.flag.at).add(30, 'day').format('MMMM Do YYYY')}</Tooltip>)
        }>
          <span className="label label-danger thirty-day-notice">30 Day Notice</span>
        </OverlayTrigger>
      )
      : null;

    const settings = (
      <UserContext.Consumer>
        {(user) => {
          if (user.type === 'admin' && !this.props.isAccountRoute) {
            return null;
          }
          return (
            <OverlayTrigger placement="bottom" overlay={settingsTooltip}>
              <Link style={{ marginLeft: 15 }} to={settingsLink}>
                  <FontAwesomeIcon style={{ color: '#777', cursor: 'pointer' }} icon={faCog} />
              </Link>
            </OverlayTrigger>
          );
        }}

      </UserContext.Consumer>
    );

    return (
      <nav className="" id="header">
        <div className="header-container" style={{}}>
          <div className="navbar-account" style={{ display: 'flex', alignItems: 'center' }}>
            <span className="nav-toggle" onClick={this.props.toggleNav}>
              <FontAwesomeIcon style={{ color: '#999', cursor: 'pointer' }} icon={faBars} />
            </span>
            {accountName}
            {notice}
            {settings}
          </div>
          <div className="header-actions">
            <OverlayTrigger placement="bottom" overlay={helpTooltip}>
              <Link to="/help"><FontAwesomeIcon icon={faQuestionCircle} /></Link>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={profileTooltip}>
              <Link to="/profile"><FontAwesomeIcon icon={faUser} /></Link>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={logoutTooltip}>
              <span onClick={this.showLogout} style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faSignOut} /></span>
            </OverlayTrigger>
          </div>

        </div>

        <GatewayModal
          type={ModalType.Primary}
          title="Confirm Log Out"
          shown={this.state.showLogout}
          onClose={this.closeLogout}
        >
          <Modal.Body>
            <p style={{ margin: 0 }} className="text-center">
              <strong>Are you sure you want to end your Affinity Gateway session and log out?</strong>
            </p>

          </Modal.Body>
          <Modal.Footer>
            <a onClick={this.logout} href="/logout" className="btn btn-primary">Yes, Log Out</a>
            <button onClick={this.closeLogout} className="btn btn-default">Cancel</button>
          </Modal.Footer>

        </GatewayModal>
      </nav>

    );
  }
}

const getDisplayAccountName = (account: any) => {
  if (account.materialized_aliases) {
    return `${account.short_name} (${account.materialized_aliases})`;
  }
  return account.short_name;
};
