import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { AccountAddress, AccountAlias } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner, StateField } from '../../shared';

interface IProps {
  accountAddress: AccountAddress | null;
  onSubmit: (values: AccountAddressFormValues) => void;
  saving: boolean;
  isUpdate: boolean;
  isForcedBusiness: boolean;
  aliases: AccountAlias[];
  accountName: string;
}

interface IState {
  countries: [];
  showAdvancedOptions: boolean;
}

export interface AccountAddressFormValues {
  dbaProfileId: number;
  accountId: number;
  city: string;
  countryId: number;
  fullAddress: string;
  id: number;
  isBusiness: boolean | null;
  isFulfillment: boolean;
  isOffice: boolean;
  isManufacturing: boolean;
  isRetail: boolean;
  latitude: string;
  longitude: string;
  name: string;
  phoneNumber: string;
  primaryLine: string;
  recipient: string;
  secondaryLine: string;
  stateId: string;
  uniqueIdentifier: string | null;
  zip: string;
  locationType: string;
}

export class AccountAddressForm extends React.Component<IProps, any, IState> {

  constructor(props: IProps) {
    super(props);
    this.getCountries = this.getCountries.bind(this);
    this.hideAlias = this.hideAlias.bind(this);
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.state = {
      countries: [],
      showAdvancedOptions: false,
      accountName: '',
    };
  }

  componentDidMount() {
    this.getCountries();
  }

  render() {

    const aliases = this.props.aliases;
    const countryOptions = this.state.countries;
    const advancedOptionsText = this.state.showAdvancedOptions ? 'Hide Advanced Options' : 'Show Advanced Options';

    const initialValues: AccountAddressFormValues = {
      accountId: 0,
      countryId: 231, // USA
      dbaProfileId: 0,
      fullAddress: '',
      id: 0,
      latitude: '',
      longitude: '',
      name: '',
      recipient: '',
      uniqueIdentifier: '',
      city: '',
      stateId: '',
      phoneNumber: '',
      primaryLine: '',
      secondaryLine: '',
      isManufacturing: false,
      isFulfillment: false,
      isOffice: false,
      isRetail: false,
      isBusiness: this.props.isForcedBusiness ? this.props.isForcedBusiness : null,
      zip: '',
      locationType: '',
    };

    if (this.props.accountAddress !== null) {
      const accountAddress = this.props.accountAddress;
      initialValues.countryId = accountAddress.countryId;
      initialValues.dbaProfileId = accountAddress.dbaProfileId;
      initialValues.id = accountAddress.id;
      // initialValues.latitude = "";
      // initialValues.longitude = "";
      initialValues.name = accountAddress.name;
      initialValues.recipient = accountAddress.recipient;
      initialValues.uniqueIdentifier = accountAddress.uniqueIdentifier;
      initialValues.city = accountAddress.city;
      initialValues.stateId = accountAddress.stateId;
      initialValues.phoneNumber = accountAddress.phoneNumber;
      initialValues.primaryLine = accountAddress.primaryLine;
      initialValues.secondaryLine = accountAddress.secondaryLine;
      initialValues.isManufacturing = accountAddress.isManufacturing;
      initialValues.isFulfillment = accountAddress.isFulfillment;
      initialValues.isOffice = accountAddress.isOffice;
      initialValues.isRetail = accountAddress.isRetail;
      initialValues.isBusiness = accountAddress.isBusiness;
      initialValues.zip = accountAddress.zip;
      initialValues.locationType = '';
    }

    let validation = Yup.object().shape({
    });
    if (this.props.isUpdate) {
      validation = validation.concat(Yup.object().shape({
        name: Yup.string().min(3).nullable(true),
        uniqueIdentifier: Yup.string().nullable(true),
        phoneNumber: Yup.string().min(10, 'A phone number must have at least 10 digits').nullable(true),
        isManufacturing: Yup.boolean(),
        isFulfillment: Yup.boolean(),
        isOffice: Yup.boolean(),
        isRetail: Yup.boolean(),
        locationType: Yup
          .string()
          .when(['isManufacturing', 'isOffice', 'isRetail', 'isFulfillment'], {
            is: false,
            then: Yup.string().required('At least one location type must be selected below'),
          }),
      }));
    } else {
      validation = validation.concat(Yup.object().shape({
        name: Yup.string().min(3).nullable(true),
        primaryLine: Yup.string().min(3).required('Address is required.'),
        city: Yup.string().min(3).required('A city is required.'),
        stateId: Yup.string().required('A state is required.'),
        zip: Yup.string().min(3).required('A zip code is required.'),
        countryId: Yup.string().required('A country is required.'),
        uniqueIdentifier: Yup.string().nullable(true),
        phoneNumber: Yup.string().min(10, 'A phone number must have at least 10 digits').nullable(true),
        isBusiness: Yup.boolean().nullable().required('Please select an option below'),
        isManufacturing: Yup.boolean(),
        isFulfillment: Yup.boolean(),
        isOffice: Yup.boolean(),
        isRetail: Yup.boolean(),
        locationType: Yup
          .string()
          .when(['isManufacturing', 'isOffice', 'isRetail', 'isFulfillment'], {
            is: false,
            then: Yup.string().required('At least one location type must be selected below'),
          }),
      }));
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.props.onSubmit}
        validationSchema={validation}
      >
        {(formProps: FormikProps<AccountAddressFormValues>) =>
          (
            <div>
              <Form id="account-address-form">

                <div className="form-group">
                  <label>Location Type</label>
                  <p className="text-danger">
                    <ErrorMessage name="locationType" />
                  </p>
                  <div className="checkbox">
                    <label>
                      <Field
                        name="isOffice"
                        type="checkbox"
                        defaultChecked={formProps.values.isOffice}
                      /> Office
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field
                        name="isRetail"
                        type="checkbox"
                        defaultChecked={formProps.values.isRetail}
                      /> Retail Store
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field
                        name="isFulfillment"
                        type="checkbox"
                        defaultChecked={formProps.values.isFulfillment}
                      /> Fulfillment / Distribution
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <Field
                        name="isManufacturing"
                        type="checkbox"
                        defaultChecked={formProps.values.isManufacturing}
                      /> Manufacturing / Factory
                    </label>
                  </div>
                </div>

                { this.props.aliases.length > 0 && !this.props.isForcedBusiness &&
                  <div className="form-group">
                    <label htmlFor="dbaProfileId">Account Alias</label>
                    <Field component="select"
                      name="dbaProfileId"
                      className="form-control"
                      disabled={formProps.values.isBusiness}
                    >
                      <option value="" disabled={true}>Select Alias</option>
                      <option value="0">{this.props.accountName}</option>
                      {aliases.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                    </Field>
                    <p className="text-danger">
                      <ErrorMessage name="dbaProfileId" />
                    </p>
                  </div>
                }

                { ! this.props.isUpdate &&
                  <>
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="form-group">
                          <label htmlFor="primaryLine">Address Line 1</label>
                          <Field placeholder="Line 1" name="primaryLine" className="form-control"/>
                          <p className="text-danger">
                            <ErrorMessage name="primaryLine" />
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="secondaryLine">Address Line 2</label>
                          <Field placeholder="Line 2" name="secondaryLine" className="form-control"/>
                          <p className="text-danger">
                            <ErrorMessage name="secondaryLine" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="city">City</label>
                          <Field placeholder="City" name="city" className="form-control"/>
                          <p className="text-danger">
                            <ErrorMessage name="city" />
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="state">State</label>
                          <StateField
                            // style={{ marginBottom: 15 }}
                            value={formProps.values.stateId}
                            short={true}
                            includeOtherStates={true}
                            onChange={(state: string) => formProps.setFieldValue('stateId', state)}
                          />
                          <p className="text-danger">
                            <ErrorMessage name="stateId" />
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="zip">Zip</label>
                          <Field placeholder="Zip" name="zip" className="form-control"/>
                          <p className="text-danger">
                            <ErrorMessage name="zip" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Country</label>
                      <Field component="select"
                             name="countryId"
                             className="form-control"
                      >
                        <option value="">Select Country</option>
                        {countryOptions.map((t: any) => <option key={t.id} value={t.id}>{t.name}</option>)}
                      </Field>
                      <p className="text-danger">
                        <ErrorMessage name="countryId" />
                      </p>
                    </div>
                  </>
                }

                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <Field placeholder="Phone Number" name="phoneNumber" className="form-control"/>
                  <p className="text-danger">
                    <ErrorMessage name="phoneNumber" />
                  </p>
                </div>

                <button type="button" className="btn btn-link" style={{ paddingLeft: 0, marginBottom: 15 }} onClick={event => this.toggleAdvancedOptions(event, formProps)}>{advancedOptionsText}</button>
                { this.state.showAdvancedOptions &&
                  <div className="row advanced-options">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="uniqueIdentifier">Store Number</label>
                        <Field placeholder="Store Number" name="uniqueIdentifier" className="form-control"/>
                        <p className="text-danger">
                          <ErrorMessage name="uniqueIdentifier" />
                        </p>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="name">Location Name</label>
                        <Field placeholder="Location Name" name="name" className="form-control"/>
                        <p className="text-danger">
                          <ErrorMessage name="name" />
                        </p>
                      </div>
                    </div>
                  </div>
                }

                { !(this.props.isUpdate || this.props.isForcedBusiness) &&
                  <div className="form-group">
                      <strong>Do you want to make this your new primary legal business address?</strong>
                    <p className="text-danger">
                      <ErrorMessage name="isBusiness" />
                    </p>
                    <div className="radio">
                      <label>
                        <Field type="radio" name="isBusiness" onChange={() => this.hideAlias(formProps, true)} defaultChecked={this.props.isForcedBusiness} disabled={this.props.isForcedBusiness} />
                        Yes
                      </label>
                    </div>
                    <div className="radio">
                      <label>
                      <Field type="radio" name="isBusiness" onChange={() => this.hideAlias(formProps, false)} disabled={this.props.isForcedBusiness} />
                        No
                      </label>
                    </div>
                  </div>
                }

                {(! this.props.isUpdate && this.props.isForcedBusiness) &&
                  <div className="form-group">
                    <strong>Your first address will be set as the primary legal business address.</strong>
                  </div>
                }

                { ! this.props.isUpdate &&
                  <div className="settings-save-button-container">
                    <button disabled={this.props.saving} className="btn btn-primary btn-lg" type="submit">
                      {this.props.saving ? 'Saving...' : 'Save Address'}
                    </button>
                  </div>
                }

              </Form>
            </div>
          )}
      </Formik>
    );

    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>
      </FullContent>
    );

  }

  getCountries() {
    Axios.get('/api/countries')
      .then((response) => {
        const countries = response.data;
        this.setState({ countries });
      });
  }

  hideAlias(formProps: FormikProps<AccountAddressFormValues>, checked: boolean) {
    if (checked) {
      formProps.setFieldValue('dbaProfileId', 0);
    }
    formProps.setFieldValue('isBusiness', checked);
  }

  toggleAdvancedOptions(event: any, formProps: FormikProps<AccountAddressFormValues>) {
    if (this.state.showAdvancedOptions) {
      this.setState({ showAdvancedOptions: false });
      if (!this.props.isUpdate) {
        formProps.setFieldValue('uniqueIdentifier', '');
        formProps.setFieldValue('name', '');
      }
    } else {
      this.setState({ showAdvancedOptions: true  });
    }
  }

}
