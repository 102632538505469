import * as React from 'react';
import { UserContext } from '../../contexts';
import AngularWrapper from '../AngularWrapper/AngularWrapper';
import { ClientVendorList } from './ClientVendorList';

export class VendorIndex extends React.Component<any, any> {

  render() {

    return (
      <UserContext.Consumer>
        {(user) => {

          if (user.type === 'client') {
            return (<ClientVendorList user={user} />);
          }
          return (<AngularWrapper {... this.props} />);
        }}

    </UserContext.Consumer>
    );

  }

}
