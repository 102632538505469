import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, ModalType, StateField } from '../../shared';

interface TaxNexus {
  id: number;
  state: string;
  postalCode: string;
}

export const TaxNexusPage =  (props: RouteComponentProps<any>) => {
  const [nexus, setNexus] = React.useState<TaxNexus[]>([]);
  const [addModalShown, setAddModalShown] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState('');
  const accountId = props.match.params['vendorId'];

  React.useEffect(() => {
    getData();
  },              []);

  React.useEffect(() => {
    setError('');
  },              [addModalShown]);

  const rows = nexus.map((n, index) => (
    <tr key={n.postalCode}>
      <td>{n.state}</td>
      <td>{n.postalCode}</td>
      <td><span onClick={() => removeNexus(index)} style={{ cursor: 'pointer' }} className="text-danger">Delete</span></td>

    </tr>
  ));

  const getData = async () => {

    const account = accountId ? `account_id=${accountId}` : '';
    const data = await Axios.get(`/api/tax-nexus?${account}`);

    const n = data.data.data.map((d: any) => {
      return {
        id: d.id,
        state: d.state,
        postalCode: d.postal_code,
      };
    });

    setNexus(n);

  };

  const addNexus = async (n: TaxNexus) => {
    setSubmitting(true);
    const params = {
      account_id: accountId ? accountId : null,
      state: n.state,
      postal_code: n.postalCode,
    };
    const d = await Axios.post('/api/tax-nexus', params)
      .catch((e) => {
        setError('We did not recognize your zip code. Please update the zip code and try again. Contact us if you continue to receive this error.');
      });
    if (d) {
      const data = d.data.data;
      setNexus(nexus.concat({
        id: data.id,
        state: data.state,
        postalCode: data.postal_code,
      }));
      setAddModalShown(false);
    }

    setSubmitting(false);
  };

  const removeNexus = async (index: number) => {
    const c = confirm('Are you sure you want to delete?');
    if (!c) {
      return;

    }
    const n = [... nexus];
    const removed = n.splice(index, 1);
    const account = accountId ? `account_id=${accountId}` : '';
    await Axios.delete(`/api/tax-nexus/${removed[0].id}?${account}`);
    setNexus(n);
  };

  const validation = Yup.object().shape({
    state: Yup.string().required(),
    postalCode: Yup.string().notRequired()
      .max(5, 'This is an invalid format. Please enter a 5-digit zip code.')
      .min(4, 'This is an invalid format. Please enter a 5-digit zip code.'),
  });

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Sales Tax Nexus')} />

      <div className="panel panel-portal">
        <div className="panel-body">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h3 className="no-margin"><strong>Sales Tax Nexus</strong></h3>
            <p className="text-muted text-center">
              You usually need to add a nexus for states where you have a physical or economic presence.
              For example,
              if you have an office in California and a warehouse in Texas,
              you would add both California and Texas as physical presences.
              Taxes are automatically calculated by the Affinity Marketplace.

            </p>
            <button onClick={() => setAddModalShown(true)} className="btn btn-primary">
              Add Nexus
            </button>
          </div>
          <hr />
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>State</th>
                  <th>Postal Code</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>

        </div>
      </div>
      <GatewayModal
        title="Add Nexus"
        type={ModalType.Primary}
        onClose={() => setAddModalShown(false)}
        shown={addModalShown}
      >
        <Formik
          initialValues={{
            id: 0,
            state: '',
            postalCode: '',
          }}
          onSubmit={addNexus}
          validationSchema={validation}
        >
          {(formProps: FormikProps<TaxNexus>) => (
            <Form>

              <Modal.Body>
                {error ? (<div className="alert alert-danger">{error}</div>) : null}

                  <div className="form-group">
                    <label>State</label>
                    <StateField
                      value={formProps.values.state}
                      short={false}
                      includeOtherStates={false}
                      onChange={s => formProps.setFieldValue('state', s)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Postal Code</label>
                    <Field name="postalCode" className="form-control" />
                    <p className="text-danger">
                      <ErrorMessage name="postalCode" />
                    </p>
                  </div>

              </Modal.Body>
              <Modal.Footer>
                <button onClick={() => setAddModalShown(false)} type="button" className="btn pull-left btn-default">
                  Cancel
                </button>
                <button type="submit" className="btn pull-right btn-primary">
                  {submitting ? 'Submitting...' : 'Add Nexus'}
                </button>
              </Modal.Footer>

            </Form>
            )}
        </Formik>
      </GatewayModal>
    </FullContent>
  );

};
