import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { UserContext } from '../../contexts';
import { AffinityClient, Insignia, ProductCategory, Vendor } from '../../shared';
import { GatewayModal, InsigniaTypeahead, LicensorTypeahead, ModalType, ProductCategoryTypeahead, UserTypeahead, VendorTypeahead } from '../shared';
import { DesignFilters } from './DesignFilters';

interface IProps {
  shown: boolean;
  onClose: () => void;
  filters: DesignFilters;
  filter: (changes: any) => void;
}

interface AdvancedFormFields {
  vendor: any[];
  category: any[];
  insignia: any[];
  licensor: any[];
  start: string;
  end: string;
  queue: number;
  market: number;
  reviewer: any[];
  decisionBy: any;
  untagged: boolean;
  sentToLicensor: boolean;
  perPage: number;
}

export const AdvancedDesignFiltersModal = (props: IProps) => {
  const form = useForm<AdvancedFormFields>(
    {
      defaultValues: {
        vendor: [],
        category: [],
        insignia: [],
        reviewer: [],
        licensor: [],
        decisionBy: [],
      },
    },
  );
  const match = useRouteMatch();
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    if (props.filters.vendorId) {
      form.setValue('vendor', [new Vendor({ id: props.filters.vendorId, name: 'Loading', short_name: 'Loading' })]);
    }
    if (props.filters.category) {
      form.setValue('category', [new ProductCategory({ id: props.filters.category, name: 'Loading' })]);
    }
    if (props.filters.insignia) {
      form.setValue('insignia', [new Insignia({ id: props.filters.insignia, title: 'Loading' })]);
    }
    if (props.filters.licensorId) {
      form.setValue('licensor', [new AffinityClient({ id: props.filters.licensorId, name: 'Loading', short_name: 'Loading' })]);

    }
    if (props.filters.reviewers.length) {
      form.setValue('reviewer', [...props.filters.reviewers]);
    }
    if (props.filters.decisionBy.length) {
      form.setValue('decisionBy', [...props.filters.decisionBy]);
    }
    if (props.filters.queue) {
      form.setValue('queue', props.filters.queue);
    }
    if (props.filters.market) {
      form.setValue('market', props.filters.market);
    }

    if (props.filters.startDate) {
      form.setValue('start', props.filters.startDate);
    }
    if (props.filters.endDate) {
      form.setValue('end', props.filters.endDate);
    }
    form.setValue('untagged', props.filters.untagged);
    form.setValue('sentToLicensor', props.filters.sentToLicensor);
    if (props.filters.perPage) {
      form.setValue('perPage', props.filters.perPage);
    }

  },              [props.shown]);

  const { shown, onClose, filters, filter  } = props;
  const isVendorFiltered = match && match.params['vendorId'] ? true : false;
  const isClientFiltered = match && match.params['licensorId'] ? true : false;
  const licensorId = user.type === 'client' ? user.account.id : filters.licensorId;

  return (
    <GatewayModal
      title="Filters"
      shown={shown}
      onClose={onClose}
      type={ModalType.Primary}

    >
      <form onSubmit={form.handleSubmit((d) => {

        const values = {
          vendorId: d.vendor.length ? d.vendor[0].id : null,
          licensorId: d.licensor.length ? d.licensor[0].id : null,
          category: d.category.length ? d.category[0].id : null,
          insignia: d.insignia.length ? d.insignia[0].id : null,
          startDate: d.start ? d.start : '',
          endDate: d.end ? d.end : '',
          queue: Number(d.queue) ? d.queue : null,
          market: Number(d.market) ?  d.market : null,
          untagged: d.untagged ? d.untagged : null,
          sentToLicensor: d.sentToLicensor ? d.sentToLicensor : null,
          perPage: d.perPage,
          reviewer: d.reviewer.length ? d.reviewer[0].id : null,
          decisionBy: d.decisionBy,
        };
        filter(values);
        onClose();

      })}>
        <Modal.Body>
          {user.type !== 'vendor' && !isVendorFiltered ? <div className="form-group">
            <label>Vendor</label>
            <VendorTypeahead clearButton={true} selected={form.watch('vendor')} onChange={(v) => {
              form.setValue('vendor', v);
            }} multiple={false} />
          </div> : null}
          {user.type !== 'client' && !isClientFiltered ? <div className="form-group">
            <label>Licensor</label>
            <LicensorTypeahead
              selected={form.watch('licensor')}
              onChange={l => form.setValue('licensor', l)}
              multiple={false}
            />
          </div> : null}
          <div className="form-group">
            <label>Product Category</label>
            <ProductCategoryTypeahead selected={form.watch('category')} onChange={c => form.setValue('category', c)} multiple={false} />
          </div>

          {licensorId ? <div className="form-group">
            <label>Insignia</label>
            <InsigniaTypeahead selected={form.watch('insignia')} onChange={i => form.setValue('insignia', i)} multiple={false} licensorId={licensorId} clearButton={true} />
          </div> : null}
          {user.type === 'admin' ? <div>
            <hr />
            <h5 className="text-center"><strong>Admin Filters</strong></h5>
            <div className="form-group">
              <label>Created Dates</label>
              <div className="row">
                <div className="col-md-6">
                  <input {... form.register('start')} type="date" className="form-control"
                  />
                </div>
                <div className="col-md-6">
                  <input {... form.register('end')} type="date" className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Queue</label>
              <select {... form.register('queue')} className="form-control" >
                <option value={0}>Queue Filter (All)</option>
                <option value={1}>Time Sensitive Review</option>
                <option value={2}>Standard Review</option>
              </select>

            </div>
            <div>
              {user.type === 'admin' && !isClientFiltered && !isVendorFiltered ? (
                <div className="form-group">
                  <label>Market</label>
                  <select {... form.register('market')} className="form-control " >
                    <option value={0}>Market Filter (All)</option>
                    <option value="1">Greek</option>
                    <option value="2">College</option>
                    <option value="3">Associations</option>
                    <option value="5">Charitable</option>
                  </select>

                </div>

              ) : null}
              <div className="form-group">
                <label>Tagged Reviewer</label>

                <UserTypeahead
                  onChange={(r) => {
                    form.setValue('reviewer', r);
                  }}
                  selected={form.watch('reviewer')}
                  multiple={false}
                  accountId={1}
                  placeholder="Reviewers"
                  clearButton={true}

                />

              </div>
              <div className="form-group">
                <label>Decision By</label>

                <UserTypeahead
                  onChange={(d) => {
                    form.setValue('decisionBy', d);
                  }}
                  selected={form.watch('decisionBy')}
                  multiple={false}
                  accountId={1}
                  placeholder="Decision By"
                  clearButton={true}
                />

              </div>
              <div className="checkbox">
                <label>
                  <input  {... form.register('untagged')}  type="checkbox" /> View designs not linked with a user
                </label>
              </div>
              {user.type === 'admin' ? (
                <div className="checkbox">
                  <label>
                    <input {... form.register('sentToLicensor')} type="checkbox" /> View designs that have been sent to licensor
                  </label>
                </div>
              ) : null}

              <div className="form-group">
                <label>Per Page</label>
                <select {... form.register('perPage')} style={{ lineHeight: 1.5 }} className="form-control" >
                  <option value={8}>8 per page</option>
                  <option value={24}>24 per page</option>
                  <option value={48}>48 per page</option>
                  <option value={96}>96 per page</option>
                </select>
              </div>
            </div>
          </div> : null}

        </Modal.Body>
        <Modal.Footer>
          <button onClick={onClose} type="button" className="btn btn-default pull-left">Cancel</button>
          <button type="submit" className="btn btn-primary">Filter</button>
        </Modal.Footer>
      </form>

    </GatewayModal>

  );
};
