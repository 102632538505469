import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { AccountProfile, AccountProfileUpdate } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { getCurrentAccountUpdate, getNewAccount, requestAccountUpdate } from '../api';
import { AccountFormValues, AccountProfileForm } from './AccountProfileForm';

interface IState {
  account: AccountProfile | null;
  reason: string;
  loading: boolean;
  saving: boolean;
  error: string;
}

export class RequestProfileUpdate extends React.Component<any, IState> {

  private _getCurrentRequestSource: CancelTokenSource;
  private _getAccountSource: CancelTokenSource;
  private _submitAccountUpdateSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      account: null,
      reason: '',
      loading: true,
      saving: false,
      error: '',
    };

    this.getDetails = this.getDetails.bind(this);
    this.submitRequest = this.submitRequest.bind(this);

  }

  componentDidMount() {
    this.getDetails();
  }

  componentWillUnmount() {
    if (this._getCurrentRequestSource) {
      this._getCurrentRequestSource.cancel('Cancelled getCurrentRequest() XHR due to unmount');

    }
    if (this._submitAccountUpdateSource) {
      this._submitAccountUpdateSource.cancel('Cancelled submitCurrentRequest() XHR due to unmount');

    }
  }

  render() {

    const body = !this.state.account ? (<LoadingSpinner />) : (
      <div>
        <h3><strong>Request Profile Update</strong></h3>
        <hr />
        {this.state.error ? (
          <div className="alert alert-danger">
            {this.state.error}
          </div>
        ) : null}
        <AccountProfileForm
          account={this.state.account}
          onSubmit={this.submitRequest}
          saving={this.state.saving}
          isUpdateRequest={true}
          reason={this.state.reason}
        />

      </div>

    );
    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            {body}

          </div>
        </div>
      </FullContent>
    );

  }

  getDetails() {
    this._getCurrentRequestSource = Axios.CancelToken.source();
    this._getAccountSource = Axios.CancelToken.source();
    const currentUpdate = getCurrentAccountUpdate(this._getCurrentRequestSource);
    const getAccount = getNewAccount(this._getAccountSource, this.accountID);

    Axios.all([currentUpdate, getAccount])
      .then(([updateResponse, accountResponse]) => {
        const currentAccount = AccountProfile.fromApi(accountResponse.data.data);
        let updateAccount;
        if (updateResponse.data) {
          updateAccount = AccountProfileUpdate.fromApi(updateResponse.data.data);
          this.setState({ account: updateAccount.diffedAccount(), loading: false, reason: updateAccount.reason });
        } else {
          this.setState({ account: currentAccount, loading: false });
        }

      });
  }

  submitRequest(values: AccountFormValues) {
    this.setState({ saving: true });
    this._submitAccountUpdateSource = Axios.CancelToken.source();
    const formData = AccountProfile.formValuesToFormData(values);
    formData.append('reason', values.reason);
    if (values.logo) {
      formData.append('image', values.logo);
    }

    requestAccountUpdate(formData, this._submitAccountUpdateSource)
      .then((response) => {

        this.props.history.push('/settings');
        this.setState({ saving: false });
      })
      .catch(error =>
        this.setState({ error: 'There was an error saving the update request. Please try again', saving: false }),
      );
  }

  get accountID() {
    if (this.props.match.params['vendorId']) {
      return this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      return this.props.match.params['licensorId'];
    }
    return '';
  }
}
