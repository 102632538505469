import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faComment,
  faEllipsisH,
  faExclamationCircle,
  faInfoCircle,
  faMinus,
  faPlusCircle,
  faShare,
  faSpinner,
  faTh,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { LatLng } from 'leaflet';
import * as moment from 'moment';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { AffinityClient, Design, DesignActivity, DesignPhase, Insignia, ProductCategory } from '../../../shared';
import { DesignResponse } from '../../DesignResponses/DesignResponse';
import { AffinityDropzone } from '../../Navigation';
import { GatewayModal, ModalType, ProductCategoryTypeahead, UserContactModal } from '../../shared';
import { EmailTypeahead } from '../../shared';
import { DesignFilters } from '../DesignFilters';
import { DesignActivityPanel } from './DesignActivityPanel';
import { DesignAlert, LicensedAlert, LicensorCategoryAlert } from './DesignAlert';
import { DesignComment } from './DesignComment';
import { DesignReviewActions } from './DesignReviewActions';
import { DesignReviewCategory, DesignReviewInsignia, DesignReviewPrimaryLicensor, DesignReviewSecondary } from './DesignReviewForm';
import FileAttachmentButton from './FileAttachmentButton';

interface IProps {
  design: Design;
  filters: DesignFilters;
  updateDesign: (design: Design, submit: boolean) => void;
  createComment: (comment: string, group: number, attachedFiles: File[]) => void;
  savingComment: boolean;
  reviewDesign: (phase: DesignPhase, comment: string, notify: boolean, advance: boolean, attachedFiles: File[]) => void;
  expediteDesign: (shouldExpedite: boolean) => void;
  availableLicensors: AffinityClient[];
  availableCategories: any[];
  availableInsignia: Insignia[];
  licensedAlert: LicensedAlert;
  categoryAlert: LicensorCategoryAlert[];
  loadingLicenses: boolean;
  responses: DesignResponse[];
  submittingIteration: boolean;
  submitNewIteration:  (file: File, categories: ProductCategory[]) => void;
  nextDesignId: number | null;
  previousDesignId: number | null;
  nextPage: number | null;
  previousPage: number | null;
  loadingNavigation: boolean;
  selectPin: (activity: DesignActivity) => void;
  setCanPin: (canPin: boolean) => void;
  savingDesign: boolean;
  errorMessage: string;
  backRoute: string;
  deletingDesign: boolean;
  deleteDesign: () => void;
}

interface IState {
  addingComment: boolean;
  pin: LatLng | null;
  shownActivity: DesignActivity | null;
  expediteModalShown: boolean;
  deleteAllShown: boolean;
  newIterationShown: boolean;
  newIterationFile: File | null;
  newIterationImagePreview: string | null;
  upiModalShown: boolean;
  submittingUpi: boolean;
  upiError: string;
  emailModalShown: boolean;
  submittingForwardEmail: boolean;
  shouldAutoAdvance: boolean;
  attachedFiles: File[];
  additionalCategories: ProductCategory[];
  designEditShown: boolean;
}

export class DesignSidebar extends React.Component<IProps, IState> {
  autoAdvanceKey: string = 'should-auto-advance-design-review';

  constructor(props: IProps) {
    super(props);
    const shouldAutoAdvance = localStorage.getItem(this.autoAdvanceKey);
    this.state = {
      addingComment: false,
      pin: null,
      shownActivity: null,
      expediteModalShown: false,
      deleteAllShown: false,
      newIterationShown: false,
      newIterationFile: null,
      newIterationImagePreview: null,
      upiModalShown: false,
      submittingUpi: false,
      upiError: '',
      emailModalShown: false,
      submittingForwardEmail: false,
      shouldAutoAdvance: shouldAutoAdvance != null ? shouldAutoAdvance === 'true' : true, // default to auto advance enabled
      attachedFiles: [],
      additionalCategories: [],
      designEditShown: false,
    };

    this.showComment = this.showComment.bind(this);
    this.cancelComment = this.cancelComment.bind(this);
    this.selectPin = this.selectPin.bind(this);
    this.selectedPinShown = this.selectedPinShown.bind(this);
    this.createComment = this.createComment.bind(this);
    this.showExpedited = this.showExpedited.bind(this);
    this.hideExpedited = this.hideExpedited.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.setInsignia = this.setInsignia.bind(this);
    this.setPrimaryLicensor = this.setPrimaryLicensor.bind(this);
    this.setSecondaryLicensors = this.setSecondaryLicensors.bind(this);
    this.showDeleteAll = this.showDeleteAll.bind(this);
    this.hideDeleteAll = this.hideDeleteAll.bind(this);
    this.expediteDesign = this.expediteDesign.bind(this);
    this.unexpediteDesign = this.unexpediteDesign.bind(this);
    this.showNewIteration = this.showNewIteration.bind(this);
    this.hideNewIteration = this.hideNewIteration.bind(this);
    this.iterationUploaded = this.iterationUploaded.bind(this);
    this.createNewIteration = this.createNewIteration.bind(this);
    this.generateRID = this.generateRID.bind(this);
    this.toggleAutoAdvance = this.toggleAutoAdvance.bind(this);
    this.reviewDesign = this.reviewDesign.bind(this);
  }
  componentWillUnmount() {
    if (this.state.newIterationImagePreview) {
      URL.revokeObjectURL(this.state.newIterationImagePreview);
    }
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.submittingIteration && !this.props.submittingIteration) {
      this.hideNewIteration();
    }
  }

  submitForwardEmail() {
    this.setState({ emailModalShown: true });
  }

  showComment() {
    this.props.setCanPin(true);
    this.setState({ addingComment: true });
  }

  cancelComment() {
    this.props.setCanPin(false);
    this.setState({ addingComment: false });
  }
  updateFile = (file: []) => {
    this.setState({ attachedFiles: file });
  }

  selectPin(activity: DesignActivity) {
    // this.setState({ shownActivity: activity });
    this.props.selectPin(activity);
  }

  selectedPinShown() {
    this.setState({ shownActivity: null });
  }

  createComment(message: string, group: number, attachedFiles: File[]) {
    this.setState({ addingComment: false });
    this.props.createComment(message, group, attachedFiles);
  }

  showExpedited() {
    this.setState({ expediteModalShown: true });
  }

  hideExpedited() {
    this.setState({ expediteModalShown: false });
  }

  showDeleteAll() {
    this.setState({ deleteAllShown: true });
  }

  hideDeleteAll() {
    this.setState({ deleteAllShown: false });
  }

  showNewIteration() {
    this.setState({ newIterationShown: true });
  }

  hideNewIteration() {
    this.setState({ newIterationShown: false });

  }

  setCategory(categories: ProductCategory[]) {
    const design = this.props.design;
    design.productCategories = categories;
    this.props.updateDesign(design, true);
  }

  setPrimaryLicensor(licensor: AffinityClient) {
    const design = this.props.design;
    design.primaryClient = licensor;
    design.insignia = [];
    this.props.updateDesign(design, true);

  }

  setInsignia(insignia: Insignia[]) {
    const design = this.props.design;
    design.insignia = insignia;
    this.props.updateDesign(design, true);

  }

  setSecondaryLicensors(licensors: AffinityClient[]) {
    const design = this.props.design;
    design.secondayClients = licensors;
    this.props.updateDesign(design, true);
  }

  expediteDesign() {
    this.props.expediteDesign(true);
    this.hideExpedited();
  }

  unexpediteDesign() { // better name..?
    this.props.expediteDesign(false);
    this.hideExpedited();
  }

  iterationUploaded(files: File[]) {
    if (files[0]) {
      this.setState({ newIterationFile: files[0], newIterationImagePreview: URL.createObjectURL(files[0]) });
    }
  }

  createNewIteration() {
    if (!this.props.submittingIteration && this.state.newIterationFile) {
      this.props.submitNewIteration(this.state.newIterationFile, this.state.additionalCategories);
    }
  }
  generateRID(formProps: FormikProps<any>, field: string)  {
    let uid = uuidv4();
    uid = uid.split('-')[0];
    uid = uid.slice(0, 6).toUpperCase();
    const lid = this.props.design.primaryClient.id;
    const date = moment();
    const dateCode = date.format('MMDDYY');
    const combined = `${lid}-${uid}-${dateCode}`;
    formProps.setFieldValue(field, combined);
  }

  toggleAutoAdvance(target: any) {
    const shouldAutoAdvance = !this.state.shouldAutoAdvance;
    this.setState({ shouldAutoAdvance });
    localStorage.setItem(this.autoAdvanceKey, shouldAutoAdvance.toString());

  }

  reviewDesign(phase: DesignPhase, comment: string, notify: boolean, attachedFiles: File[]) {
    this.props.reviewDesign(phase, comment, notify, this.state.shouldAutoAdvance, attachedFiles);
  }

  render() {
    const design = this.props.design;
    const canExpedite = this.props.design.vendor.profile.expedited_design_enabled_at;

    const iterationCount = design.iterations.length;
    const primaryActivity = design.primaryIteration ? design.primaryIteration.activity.map(activity => (
      <DesignActivityPanel key={activity.id} onClick={this.selectPin} activity={activity} />
    )) : [];

    const expeditedLabel = design.expedited ? (
      <span className="label label-success">Expedited</span>
    ) : null;

    const resubmissionLabel = design.isResubmission ? (
      <span className="label label-info">Resubmission</span>
    ) : null;

    const availableSecondaryLicensors = this.props.availableLicensors
      .filter(licensor => !design.primaryClient || licensor.id !== design.primaryClient.id);

    const submitIterationText = this.props.submittingIteration ? (
      <span>Submitting... <FontAwesomeIcon icon={faSpinner} spin /></span>
    ) : 'Submit New Version';

    let nextQp = this.props.filters.generateQueryParamString();
    let prevQp = this.props.filters.generateQueryParamString();

    if (this.props.nextPage) {
      nextQp = this.props.filters.generateQueryParamString({ page: this.props.nextPage });
    }
    if (this.props.previousPage) {
      prevQp = this.props.filters.generateQueryParamString({ page: this.props.previousPage });
    }
    if (this.props.filters.licensorId) {
      nextQp += `&licensor=${this.props.filters.licensorId}`;
      prevQp += `&licensor=${this.props.filters.licensorId}`;
    }

    const prevId = this.props.previousDesignId;
    const prevDesign = (
      <Link
        to={prevId ?  `/designs/${prevId}?${prevQp}` : ''}
        className={`btn btn-sm btn-default ${!prevId || this.props.savingDesign || this.props.savingDesign ? 'disabled' : ''}`}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Link>
    );

    const nextId = this.props.nextDesignId;
    const nextDesign = (
      <Link
        to={nextId ?  `/designs/${nextId}?${nextQp}` : ''}
        className={`btn btn-sm btn-default ${!nextId || this.props.savingComment || this.props.savingDesign ? 'disabled' : ''}`}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    );
    const middleIcon = this.props.loadingNavigation ?
      <FontAwesomeIcon spin icon={faSpinner} />
      : <FontAwesomeIcon icon={faTh} />;
    const grid = (
      <Link
        to={this.props.backRoute}
        className={'btn btn-sm btn-default'}
        >
          {middleIcon}
        </Link>
    );
    const savingMessage = this.props.savingComment || this.props.savingDesign ? (<span>&middot; Saving...</span>) : null;
    const error = this.props.errorMessage ? (
      <div className="alert alert-danger"><FontAwesomeIcon icon={faExclamationCircle} /> {this.props.errorMessage}</div>
    ) :  null;
    const vendorHeader = (
      <div className="design-sidebar-vendor-header">

        <img className="img-responsive" src={design.vendor.image.getSize('th')} />
        <h5><strong>{design.vendor.shortName}</strong></h5>
      </div>
    );

    const dateToEnableUPI = new Date('4/1/2022');
    const currentDate = new Date();

    return (
      <div>
          <div className="panel panel-portal">
          <div className="panel-body">
            <div className="designs-nav-button-row">
              <div style={{ display: 'flex' }} className="btn-group">
                {prevDesign}
                {grid}
                {nextDesign}
              </div>
              <UserContext.Consumer>
                {user => user.type !== 'client' ? (<button onClick={this.showNewIteration} className="btn btn-sm btn-primary btn-block new-design-version">
                <FontAwesomeIcon icon={faPlus} /> New Version
              </button>) : null}
              </UserContext.Consumer>

            </div>
            <UserContext.Consumer>
              {user => user.type !== 'vendor' ?
                (
                  <div style={{ display: 'flex', alignItems: 'center',  justifyContent: 'center', marginBottom: -10 }}>
                  <div style={{ marginBottom: 0 }} className="checkbox">
                    <label>
                      <input onChange={this.toggleAutoAdvance} checked={this.state.shouldAutoAdvance} type="checkbox" /> Auto-advance after review
                    </label>
                  </div>
                  </div>
                )

                : null}
            </UserContext.Consumer>
            <hr />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h4
                style={{ width: '100%', marginRight: -15 }}
                className="text-center"
              >
                <strong>{design.title}</strong>
              </h4>
              <UserContext.Consumer>
                {user => user.type === 'admin' || user.type === 'client'  ? (
                  <div style={{ display: 'flex', flexDirection: 'row-reverse', marginLeft: 5 }}>
                    <span style={{ cursor: 'pointer' }} className="text-muted" onClick={() => this.setState({ designEditShown: true })}> <FontAwesomeIcon icon={faEllipsisH} /> </span>
                  </div>
                ) : null}
              </UserContext.Consumer>
            </div>

            <UserContext.Consumer>
              {user => user.type !== 'client' ?
                currentDate.getTime() >= dateToEnableUPI.getTime() ? design.upi.length ? (
                  <h5 style={{ cursor: 'pointer' }} className="text-primary text-center" onClick={() => this.setState({ upiModalShown: true })}>Manage UPI</h5>
                ) : (
                  <h5 style={{ cursor: 'pointer' }} className="text-primary text-center" onClick={() => this.setState({ upiModalShown: true })}>Add UPI</h5>
                ) : null
              : null}
            </UserContext.Consumer>
            <p className="text-muted text-center">{design.upi.map(u => u.upi).join(', ')}</p>
            <UserContext.Consumer>
              {user => user.type === 'admin' ? (
                <Link to={`/vendors/${design.vendor.id}/designs`}>
                  {vendorHeader}

                </Link>
              ) : vendorHeader }
            </UserContext.Consumer>
            {design.uploadUser ? <div style={{ marginBottom: 10 }} className="text-center">
              <span className="text-muted">Uploaded by </span> <UserContactModal user={design.uploadUser} blockedUserTypes={['vendor']}>
                <strong>{design.uploadUser.fullName}</strong>
              </UserContactModal>
            </div> : null}

            <div className="design-sidebar-labels">
              {expeditedLabel}
              {resubmissionLabel}
            </div>
            <p className="text-muted text-center no-margin">
              {iterationCount > 1 ? `${iterationCount} versions` : '1 version'} {savingMessage}
            </p>
            <UserContext.Consumer>
              {user => (
                <div>

                  <DesignReviewCategory
                    categories={design.productCategories}
                    isReadOnly={user.type !== 'admin'}
                    onChange={this.setCategory}
                  />
                  <DesignReviewPrimaryLicensor
                    isReadOnly={user.type !== 'admin'}
                    licensor={design.primaryClient}
                    availableLicensors={this.props.availableLicensors}
                    onChange={this.setPrimaryLicensor}

                  />
                  <DesignReviewInsignia
                    selectedCategories={design.productCategories}
                    vendor={design.vendor}
                    primaryLicensor={design.primaryClient}
                    isReadOnly={user.type === 'vendor'}
                    insignia={design.insignia}
                    availableInsignia={this.props.availableInsignia.filter((ins) => {
                      const ids = design.insignia.map(i => i.id);
                      return !ids.includes(ins.id);
                    })}
                    onChange={this.setInsignia}
                  />
                  {user.type === 'admin' ? <DesignReviewSecondary
                    isReadOnly={user.type !== 'admin'}
                    licensors={design.secondayClients}
                    availableLicensors={availableSecondaryLicensors}
                    onChange={this.setSecondaryLicensors}
                  /> : null}

                  <hr />

                  {this.props.design.primaryClient && (user.type === 'admin' || user.type === 'client')  ? <DesignAlert
                    loading={this.props.loadingLicenses}
                    licensed={this.props.licensedAlert}
                    category={this.props.categoryAlert}
                  /> : null}
                  {this.props.design.channelInternal && (user.type === 'admin' || user.type === 'client') ?
                    <div className={`alert alert-${this.props.design.canRoyaltyExempt ? 'success' : 'warning'}`}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                          <FontAwesomeIcon icon={faExclamationCircle} /> Internal Consumption
                        </div>
                        <span onClick={() => this.setState({ designEditShown: true })} style={{ cursor: 'pointer' }} ><FontAwesomeIcon icon={faEllipsisH} /></span>
                      </div>
                    </div>
                    : null
                  }
                  {user.type === 'admin' &&
                    design.primaryClient &&
                    design.primaryClient.profile && design.primaryClient.profile.exclusivelyReviewsDesigns ?
                    <div className="alert alert-danger">
                      <FontAwesomeIcon icon={faExclamationCircle} /> Licensor reviews all designs
                    </div>
                    : null
                  }
                  <DesignReviewActions
                    user={user}
                    onSubmit={this.reviewDesign}
                    isReadOnly={
                      user.type === 'vendor' ||
                        (user.type === 'client' && !design.canClientReview)
                    }
                    status={design.phase}
                    responses={this.props.responses}
                    setCanPin={this.props.setCanPin}
                    primaryLicensor={design.primaryClient}
                  />

                  {error}

                  <hr />
                  {
                    !design.expedited && user.type !== 'client' && design.phase.id === 3  ?
                      <button onClick={this.showExpedited} className="btn btn-default btn-block">
                        <FontAwesomeIcon className="text-success" icon={faClock} />  Expedite Design
                      </button>
                      : null
                  }
                  <button onClick={() =>  this.setState({ emailModalShown: true }) } className="btn btn-default btn-block">
                    <FontAwesomeIcon className="text-primary" icon={faShare} />  Forward Email
                  </button>
                </div>

              )}

            </UserContext.Consumer>

            {
              !this.state.addingComment ?
                <button style={{ marginTop: 10, marginBottom: 10 }} onClick={this.showComment} className="btn btn-default btn-block">
                  <FontAwesomeIcon className="text-success" icon={faComment} /> Comment
                </button>
                : <DesignComment
                    saving={this.props.savingComment}
                    cancel={this.cancelComment}
                    submitComment={this.createComment}
                  />
            }

            <UserContext.Consumer>
              {user => user.type === 'admin' && design.phase.id === 3 && design.otherIterations.length === 0 ? (
                <div>
                  <hr />
                  <button onClick={this.showDeleteAll} className="btn btn-default btn-block">
                    <FontAwesomeIcon className="text-danger" icon={faTrash} /> Delete All Versions
                  </button>
                </div>
              ) : null}
            </UserContext.Consumer>

          </div>

          </div>

          {primaryActivity}
        <div>
          <GatewayModal
            type={ModalType.Primary}
            shown={this.state.emailModalShown}
            title="Forward Email"
            onClose={() => this.setState({ emailModalShown: false })}
          >
            <Formik
              validationSchema={Yup.object().shape({
                recipients: Yup.array()
               .of(
                 Yup.object().shape({
                   email: Yup.string()
                        .email('Invalid email')
                        .required('Email is required'),
                 }),
               )
               .required('At least one recipient required'),
                subject: Yup.string().required('Subject is required').min(1, 'Subject must contain at least one character'),
                bcc: Yup.boolean(),
              })}
              initialValues={{ subject: `Feedback on ${design.vendor.shortName} Design Submission`, recipients: [], currentEmail: '', message: '', bcc: true }}
              onSubmit={async (v:any) => {
                this.setState({ submittingForwardEmail: true });
                const formData = new FormData();

                v.recipients.forEach((r:any, index:any) => {
                  if (r && r.firstName.length > 0) {
                    formData.append(`recipients[${index}][address]`, r.email);
                    formData.append(`recipients[${index}][name]`, `${r.firstName} ${r.lastName}`);
                  } else {
                    formData.append(`recipients[${index}][address]`, r.email);
                    formData.append(`recipients[${index}][name]`, '');
                  }
                });
                formData.append('subject', v.subject);
                formData.append('bcc', v.bcc);
                formData.append('message', v.message);

                this.state.attachedFiles.forEach((file, index) => {
                  formData.append(`attachedFiles[${index}]`, file);
                });
                await Axios.post(`/api/designs/${this.props.design.id}/forward`, formData)
                  .then((response) => {
                    this.setState({ submittingForwardEmail: false, emailModalShown: false });
                  })
                  .catch((e) => {
                    this.setState({ submittingForwardEmail: false });

                  });

              }} >
              {formProps => (
                <Form>
                  <Modal.Body>
                    <div className="form-group">
                      <label>From:  </label>
                      <UserContext.Consumer>
                        { user => ` ${user.fullname} (${user.email})` }
                      </UserContext.Consumer>
                    </div>
                    <div className="form-group">
                      <label>Recipients</label>
                      <div className="form-group">
                        <UserContext.Consumer>
                          { user =>
                            <EmailTypeahead selected={formProps.values.recipients} onChange={u => formProps.setFieldValue('recipients', u)} multiple={true} accountId={user.account.id}/>
                          }
                        </UserContext.Consumer>
                      </div>
                      <p className="text-danger">
                        <ErrorMessage name="recipients">
                          {errorMessage =>
                            Array.isArray(errorMessage)
                              ? errorMessage.map((error, index) => <span key={index}>{error.email}<br /></span>)
                              : errorMessage
                          }
                        </ErrorMessage>
                      </p>
                    </div>

                    <div className="form-group">
                      <label>Subject</label>
                      <Field type="text" className="form-control" name="subject" id="subject"/>
                      <p className="text-danger">
                        <ErrorMessage name="subject" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Message</label>
                      <Field component="textarea" className="form-control" name="message" id="message" placeholder="Optional message..."/>
                      <p className="text-danger">
                        <ErrorMessage name="message" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label>
                        <Field type="checkbox" name="bcc" id="bcc" checked={formProps.values.bcc} />
                        <span style = {{ marginLeft: 5 }}>Send yourself a copy</span>
                      </label>
                      <p className="text-danger">
                        <ErrorMessage name="bcc" />
                      </p>
                      <FileAttachmentButton onFileSelect={this.updateFile} inputId={'forwardFile'}/>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button onClick={() => this.setState({ emailModalShown: false })} type="button" className="btn btn-default pull-left">Cancel</button>
                    <button className="btn btn-primary pull-right">{this.state.submittingForwardEmail ? 'Forwarding...' :  'Forward Email'}</button>
                  </Modal.Footer>

                </Form>
              )}

            </Formik>

          </GatewayModal>
          <GatewayModal
            type={ModalType.Primary}
            shown={this.state.upiModalShown}
            title="Update UPI"
            onClose={() => this.setState({ upiModalShown: false })}
          >
            <Formik validationSchema={Yup.object().shape({
              upi: Yup.array().of(
                Yup.string()
                  .required('UPI is required')
                  .max(255, 'Must be shorter than 255 characters')
                  .test(
                    'non-allowed-chars',
                    'The following characters are not allowed: , ;',
                    value => !value.includes(',') && !value.includes(';'),
                  ),
              ),
            })} initialValues={{ upi: design.upi.length ? design.upi.map(u => u.upi) : [''] }} onSubmit={async (v) => {
              this.setState({ submittingUpi: true });
              const filtered = v.upi.filter(u => u.length);

              const d = await Axios.put(`/api/designs/${this.props.design.id}/upi`, { upi: filtered })
                .catch((e) => {

                  this.setState({ submittingUpi: false, upiError: 'A duplicate UPI was found' });
                  return null;
                });

              if (d) {
                this.setState({ submittingUpi: false, upiModalShown: false, upiError: '' });
                this.props.updateDesign(Design.fromApi(d.data.data), false);
              }

            }} >
              {formProps => (
                <Form>
                  <Modal.Body>
                    {this.state.upiError ? (
                      <div className="alert alert-danger">
                        {this.state.upiError}
                      </div>
                    ) : null}
                    <FieldArray
                      name="upi"
                      render={helpers => (
                        <div>
                          <label style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span>Unique Product Identifier (UPI) <a className="text-muted" target="_blank" href="https://help.affinitylicensing.com/en/articles/6008552-unique-product-identifiers-upi">
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </a></span>
                            <span className="text-primary" onClick={() => helpers.push('')}><FontAwesomeIcon icon={faPlusCircle} /> UPI</span>

                          </label>
                          {formProps.values.upi.map((u: string, index: number) => (
                            <div className="form-group">
                              <div className="input-group">
                                <Field className="form-control" name={`upi.${index}`} />
                                <div className="input-group-btn">
                                  <button onClick={() =>  u.length ? formProps.setFieldValue(`upi.${index}`, '') : this.generateRID(formProps, `upi.${index}`)} type="button" className="btn btn-default">{u.length ? 'Clear' : 'Generate'}</button>
                                  <button onClick={() => helpers.remove(index)} type="button" className="btn btn-default"><FontAwesomeIcon className="text-danger" icon={faMinus} /></button>
                                </div>
                              </div>
                              <p className="text-danger">
                                <ErrorMessage name={`upi.${index}`} />
                              </p>
                            </div>

                          ))}
                        </div>
                      )}

                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <button onClick={() => this.setState({ upiModalShown: false })} type="button" className="btn btn-default pull-left">Cancel</button>
                    <button className="btn btn-primary pull-right">{this.state.submittingUpi ? 'Saving...' :  'Save Changes'}</button>
                  </Modal.Footer>

                </Form>
              )}

            </Formik>

          </GatewayModal>
          <GatewayModal
            type={ModalType.Primary}
            shown={this.state.expediteModalShown}
            onClose={this.hideExpedited}
            title="Confirm Expedite"
          >
            <Modal.Body>
              {canExpedite ? (
                  <div>
                    <p>
                      As a reminder,
                      you will be charged $3.75 per design if the design is reviewed within the allotted time frame.
                      The allotted time frame for designs that are selected Monday-Thursday for expedited review is 24 hours.
                      The allotted time frame for designs that are selected Friday-Sunday for expedited review
                      is no later than Monday at 11:59 pm Pacific.
                      The fee for designs selected for expedited
                      review will be invoiced and automatically debit your account weekly.
                    </p>
                    <p className="text-primary">
                      By continuing, you certify that you are authorized to expedite designs
                      and accept the terms and fees associated with expedited review.
                    </p>

                  </div>
                ) :
                (
                  <p className="text-center">
                    Expedited Designs needs to be enabled on your account before you can utilize this feature.
                    The account owner must enable Expedited Design Review features on the 'Expedited Designs'
                    tab of your account settings.
                  </p>
                )
              }
              <UserContext.Consumer>
                {user => user.type === 'admin' ? (

                  <p className="text-danger text-center">

                    <strong>
                      Only expedite a design on behalf of a vendor if you have received explicit
                      permission from a user authorized to submit designs.
                    </strong>

                  </p>

                ) : null }
              </UserContext.Consumer>

            </Modal.Body>
            <Modal.Footer>
              <button onClick={this.hideExpedited} className="btn btn-default pull-left">Cancel</button>
              {canExpedite ?
                <button onClick={this.expediteDesign} className="btn btn-primary pull-right">Expedite Design</button>
                :
                null
              }
            </Modal.Footer>
          </GatewayModal>
          <GatewayModal
            type={ModalType.Danger}
            shown={this.state.deleteAllShown}
            onClose={this.hideDeleteAll}
            title="Delete Design"
          >
            <Modal.Body>
              <p className="text-danger text-center">
                <strong>
                  Are you sure you want to delete this design?
                </strong>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button onClick={this.hideDeleteAll} className="btn btn-default pull-left">Cancel</button>
              <button
                disabled={this.props.deletingDesign}
                className="btn btn-danger pull-right"
                onClick={this.props.deleteDesign}
              >
                  {this.props.deletingDesign ? 'Deleting...' : 'Delete Design'}
                </button>
            </Modal.Footer>

          </GatewayModal>
          <GatewayModal
            type={ModalType.Primary}
            shown={this.state.newIterationShown}
            onClose={this.hideNewIteration}
            title="Submit New Version"
          >
            <Modal.Body>
              <p className="text-center">
                Please note, your resubmission must be a modified version of the original design. <br />
                If it is not, then the design will be <strong>rejected</strong>.
              </p>
              <AffinityDropzone
                onDrop={this.iterationUploaded}
                accept="image/*,application/pdf"
              >
                {
                  this.state.newIterationImagePreview ? (
                    <div>
                      <img className="img-responsive" src={this.state.newIterationImagePreview} />
                    </div>
                  ) : (
                      <div>Drag and drop the new version here.</div>
                  )
                }
            </AffinityDropzone>
              <br />
              <div className="form-group">
                <label>Additional Product Categories</label>
                <ProductCategoryTypeahead selected={this.state.additionalCategories} onChange={c => this.setState({ additionalCategories: c })} multiple={true}  />

              </div>

            </Modal.Body>
            <Modal.Footer>
              <button onClick={this.hideNewIteration} className="btn btn-default pull-left">Cancel</button>
              <button onClick={this.createNewIteration} className="btn btn-primary pull-right">{submitIterationText}</button>
            </Modal.Footer>
          </GatewayModal>

          <GatewayModal
            type={ModalType.Primary}
            shown={this.state.designEditShown}
            onClose={() => this.setState({ designEditShown: false })}
            title="Update Design"
          >
            <Formik
              initialValues={{
                title: design.title,
                customer: design.customer,
                units: design.unitEstimation,
                internalCustomerUnits: '',
                channelDirect: design.channelDirect,
                channelRetail: design.channelRetail,
                channelInternal: design.channelInternal,
                universityFunds: design.universityFunds === null ? null : design.universityFunds ? '1' : '0',
                notForResale: design.notForResale === null ? null : design.notForResale ? '1' : '0',
                qualifiesForExemption: design.qualifiesForExemption === null ? null : design.qualifiesForExemption ? '1' : '0',
              }}
              validationSchema={Yup.object().shape({
                channelRetail: Yup.boolean().notRequired(),
                channelInternal: Yup.boolean().notRequired(),
                internalCustomerUnits: Yup.mixed().when(['channelInternal', 'customer', 'units'], {
                  is: (channelInternal, customer, units) => {
                    return channelInternal && (!customer || !units);
                  },
                  then: Yup.mixed().required('Customer name and estimated units are required for internal orders.'),
                  otherwise: Yup.mixed().notRequired(),
                }),
                universityFunds: Yup.boolean().when(['channelInternal'], {
                  is: (channelInternal) => {
                    return channelInternal;
                  },
                  then: Yup.boolean().required('Answer is required.').typeError('Answer is required.'),
                  otherwise: Yup.mixed().notRequired(),
                }),
                notForResale: Yup.boolean().when(['channelInternal'], {
                  is: (channelInternal) => {
                    return channelInternal;
                  },
                  then: Yup.boolean().required('Answer is required.').typeError('Answer is required.'),
                  otherwise: Yup.mixed().notRequired(),
                }),
                qualifiesForExemption: Yup.boolean().when(['channelInternal'], {
                  is: (channelInternal) => {
                    return channelInternal;
                  },
                  then: Yup.boolean().required('Answer is required.').typeError('Answer is required.'),
                  otherwise: Yup.mixed().notRequired(),
                }),
              })}
              onSubmit={async (values: any, actions) =>  {
                const design = this.props.design;
                design.channelInternal = values.channelInternal;
                design.channelDirect = values.channelDirect;
                design.channelRetail = values.channelRetail;
                design.notForResale = values.notForResale === '1';
                design.universityFunds = values.universityFunds === '1';
                design.qualifiesForExemption = values.qualifiesForExemption === '1';
                design.unitEstimation = values.units;
                design.customer = values.customer;
                design.title = values.title;
                this.props.updateDesign(design, true);
                this.setState({ designEditShown : false });

              }}
            >
              {(formProps: FormikProps<any>) => (
                <Form>

                  <Modal.Body>
                    <div className="form-group">
                      <label>Title</label>
                      <Field placeholder="Title" className="form-control" name="title" />
                      <p className="text-danger">
                        <ErrorMessage name="title" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Please select the applicable distribution channels for this order:</label>
                      <p className="text-danger">
                        <ErrorMessage name="channelDirect" />
                      </p>
                      <div className="checkbox">
                        <label>
                          <Field checked={formProps.values.channelDirect} type="checkbox" name="channelDirect" /> Direct to Consumer
                        </label>
                      </div>
                      <div className="checkbox">
                        <label>
                          <Field checked={formProps.values.channelRetail} type="checkbox" name="channelRetail" /> Business to Business (Wholesale/Retail)
                        </label>
                      </div>
                      {this.props.design.primaryClient && this.props.design.primaryClient.market.id === 2 ?
                        <div className="checkbox">
                          <label>
                            <Field checked={formProps.values.channelInternal} type="checkbox" name="channelInternal" /> Internal Consumption
                          </label>
                        </div> : null}

                      <p className="text-danger">
                        <ErrorMessage name="channel" />
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-group">
                            <label>Customer Name</label>
                            <Field placeholder="Customer Name" name="customer" className="form-control" />
                            <p className="text-danger">
                              <ErrorMessage name="customer" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Estimated Units</label>
                          <Field placeholder="Estimated Units" name="units" className="form-control" />
                          <p className="text-danger">
                            <ErrorMessage name="units" />
                          </p>
                        </div>
                      </div>
                    </div>

                    <p className="text-danger">
                      <ErrorMessage name="internalCustomerUnits" />
                    </p>
                    {formProps.values.channelInternal ? <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Are university funds being used for this order?</label>
                            <div>
                              <label className="radio-inline">
                                <Field checked={formProps.values.universityFunds === '1'} type="radio" name="universityFunds" value={1} /> Yes
                              </label>
                              <label className="radio-inline">
                                <Field checked={formProps.values.universityFunds === '0'} type="radio" name="universityFunds" value={0} /> No
                              </label>
                            </div>
                          </div>
                          <p className="text-danger">
                            <ErrorMessage name="universityFunds" />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Is the product being resold?</label>
                            <div>
                              <label className="radio-inline">
                                <Field checked={formProps.values.notForResale === '0'} type="radio" name="notForResale" value={0} /> Yes
                              </label>
                              <label className="radio-inline">
                                <Field checked={formProps.values.notForResale === '1'} type="radio" name="notForResale" value={1} /> No
                              </label>
                            </div>
                          </div>
                          <p className="text-danger">
                            <ErrorMessage name="notForResale" />
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Does this qualify for a royalty exemption?</label>
                            <div>
                              <label className="radio-inline">
                                <Field checked={formProps.values.qualifiesForExemption === '1'} type="radio" name="qualifiesForExemption" value={1} /> Yes
                              </label>
                              <label className="radio-inline">
                                <Field checked={formProps.values.qualifiesForExemption === '0'} type="radio" name="qualifiesForExemption" value={0} /> No
                              </label>
                            </div>
                          </div>
                          <p className="text-danger">
                            <ErrorMessage name="qualifiesForExemption" />
                          </p>
                        </div>
                      </div>
                    </div> : null}
                  </Modal.Body>
                  <Modal.Footer>
                    <button type="button" onClick={() => this.setState({ designEditShown: false })} className="btn btn-default pull-left">Cancel</button>
                    <button
                      disabled={this.props.savingDesign}
                      className="btn btn-primary pull-right"
                    >
                      {this.props.savingDesign ? 'Updating...' : 'Update Design'}
                    </button>
                  </Modal.Footer>
                </Form>

              )}

            </Formik>

          </GatewayModal>
        </div>
        </div>
    );

  }
}
