import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useHistory, useLocation } from 'react-router';
import { Link, Redirect } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { MarketplaceAdvertisement } from '../../../shared';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { FilterBar, LoadingSpinner, PaginationFooter } from '../../shared';

export const AdsIndexPage = () => {
  const history = useHistory();
  const location = useLocation();
  const locationParams = queryString.parse(location.search);
  const [loading, setLoading] = React.useState(true);
  const [ads, setAds] = React.useState<MarketplaceAdvertisement[]>([]);
  const [perPage, setPerPage] = React.useState(locationParams.per_page ? Number(locationParams.per_page) : 24);
  const [keyword, setKeyword] = React.useState(locationParams.keyword ? locationParams.keyword : '');
  const [page, setPage] = React.useState(1);
  const [lastPage, setLastPage] = React.useState(1);
  const user = React.useContext(UserContext);

  if (user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    getAds();
  },              []);

  const getAds = async () => {
    setLoading(true);

    const params = {
      page,
      per_page: perPage === 24 ? null : perPage,
      keyword: keyword ? keyword : null,
    };

    const cleaned = omitBy(params, isNil);
    const a = await Axios.get(`/api/marketplace/ads?${queryString.stringify(cleaned)}`);
    const list = a.data.data.map((a: any) => MarketplaceAdvertisement.fromApi(a));
    setAds(list);
    setLastPage(Number(a.data.meta.pagination.total_pages));
    setLoading(false);

  };

  const updateFilters = (values: any, reload: boolean) => {
    if (values.perPage) {
      setPerPage(values.perPage);
    } else {
      if (values.search !== undefined) {
        setKeyword(values.search);
      }
      if (reload) {
        setPage(1);
        getAds();
        setQueryParams();
      }
    }

  };

  const changePage = (page: number) => {
    setPage(page);
    getAds();
    setQueryParams();
  };

  const setQueryParams = () => {
    const params = {
      page: page !== 1 ? page : null,
      per_page: perPage === 24 ? null : perPage,
      keyword: keyword ? keyword : null,
    };
    const cleaned = omitBy(params, isNil);
    history.push(`/marketplace/ads?${queryString.stringify(cleaned)}`);
  };

  const rows = ads.map(a => (
    <tr>
      <td><Link to={`/marketplace/ads/${a.id}`}>{a.title}</Link></td>
      <td>{a.site && a.site.title}</td>
      <td>{a.licensor && a.licensor.name}</td>
    </tr>
  ));

  const body = loading ? (<LoadingSpinner />) : ads.length ? (
   <div className="table-responsive">
      <table className="table table-portal table-hover">
        <thead>
          <tr>
            <th>
              Title
            </th>
            <th>
              Site
            </th>
            <th>
              Licensor
            </th>
          </tr>
        </thead>
        {rows}

      </table>

    </div>
  ) : (<p className="text-center text-muted"> No ads were found.</p>);

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Marketplace Ads')} />
      <div style={{ flexDirection: 'row-reverse' }} className="row flex-row button-row">
        <div className="panel panel-portal button-panel">
          <div className="panel-body">
            <Link to="/marketplace/ads/new" className="btn btn-primary">New Ad</Link>
          </div>
        </div>
      </div>

      <div className="panel panel-portal button-panel">
        <div className="panel-body">
          <FilterBar
            search={keyword}
            perPage={perPage}
            usePerPage={true}
            useQuarter={false}
            useSearch={true}
            useDates={false}
            updateFilters={updateFilters}
          />
        </div>
      </div>

      <div className="panel panel-portal">
        <div className="panel-body">
          {body}

        </div>
      </div>
      <PaginationFooter currentPage={page} hasNext={page > lastPage} setPage={changePage} />
    </FullContent>

  );
};
