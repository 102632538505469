import { faCaretRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartData, ChartOptions } from 'chart.js';
import { groupBy } from 'lodash';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { formattedFiscalYear, toUSD } from '../../../utils';
import { QuarterStats } from './models';
import { PartialRepresentation } from './PartialRepresentation';

interface IProps {
  quarters: QuarterStats[];
  quarter?: 'All' | 'Q1' | 'Q2' | 'Q3' | 'Q4';
  year: string;
  hasFilters: boolean;
  isPartial: boolean;
}

const mapRoyalties = (stat: QuarterStats | undefined): number => {
  if (stat) {
    return stat.totalRoyalties;
  }
  return 0;
};

export class YearOverYear extends React.Component <IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.quarterBackground = this.quarterBackground.bind(this);
  }

  mapRoyalties(stat: QuarterStats | undefined) {
    if (stat) {
      return stat.totalRoyalties;
    }
    return 0;
  }

  quarterBackground(quarter?: string) {
    if (this.props.quarter === 'All') {
      return undefined;
    }
    if (this.props.quarter === quarter) {
      return '#25658a';
    }
    return  '#e6e6e6';

  }
  render() {

    const zeroDollarFiltered = this.props.quarters.filter(quarter => quarter.totalRoyalties > 0);

    const years = groupBy(zeroDollarFiltered, 'year');

    const quarters =  this.props.quarters;

    const quarter = this.props.quarter;

    let datasets;
    if (quarter !== 'All') {
      const yearValues = Object.keys(years).map(key => years[key]);
      const yearSums = yearValues.map(qs =>
        qs.reduce(((total, q) => {
          if (q.name !== quarter) {
            total += q.totalRoyalties;
          }
          return total;
        }),       0),
      );

      datasets = [
        {
          label: quarter,
          data: quarters.filter(q => q.name === quarter).map(mapRoyalties),
          backgroundColor: this.quarterBackground(quarter),
        },
        {
          label: 'Other Quarters',
          data: yearSums,
          backgroundColor: this.quarterBackground(),
        },
      ];

    } else {

      datasets = ['Q1', 'Q2', 'Q3', 'Q4'].map((quarterLabel) => {
        const yearValues = Object.keys(years).map(key => years[key]);
        const data = yearValues.map((yearGroup) => {
          const q = yearGroup.filter(q => q.name === quarterLabel).map(mapRoyalties);
          if (q.length) {
            return q[0];
          }
          return 0;
        });

        return {
          data,
          label: quarterLabel,
          backgroundColor: this.quarterBackground(quarterLabel),
        };
      });

    }

    const barData: ChartData = {
      datasets,
      labels: Object.keys(years).map(year => formattedFiscalYear(Number(year))),
    };

    let barOptions: ChartOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          footer: (tooltip, data: any) => {
            let sum = 0;
            tooltip.forEach((tooltipItem: any) => {
              sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            });
            return `Annual: ${toUSD(sum)}`;

          },
          label: (tooltip, data: any) => {
            if (tooltip.index !== undefined && tooltip.datasetIndex !== undefined) {
              const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              const quarter = data.datasets[tooltip.datasetIndex].label;
              return `${quarter}: ${toUSD(value)}`;
            }
            return '$';

          },

        },

      },
      scales: {
        xAxes: [{
          stacked: false,
        }],
        yAxes: [{
          stacked: false,
          ticks: {
            beginAtZero: true,
            callback: (value, index, values) => {
              return `${toUSD(value)}`;

            },
          },

        }],
      },

    };

    if (this.props.quarter === 'All') {
      barOptions = Object.assign(barOptions, {
        plugins: {
          colorschemes: {
            scheme: 'tableau.MillerStone11',
          },
        },
      });

    }
    const partialContent = this.props.isPartial ? (<PartialRepresentation />) : null;
    const total = this.props.quarters.reduce(
      (sum, quarter) => {
        const year = Number(this.props.year.split('-')[0]);
        if (quarter.year === year) {
          return sum + quarter.totalRoyalties;
        }
        return sum;
      },
      0);
    const filteredText = this.props.hasFilters ? (
      <span>
        &nbsp;<FontAwesomeIcon icon={faCaretRight} className="text-muted" />
        &nbsp;Filtered
      </span>
    ) : null;

    return (
      <div className="panel panel-portal">
      <div className="panel-body">
        <div className="analytics-header">
          {this.props.year}{filteredText}:
          <span className="text-primary"> {toUSD(total)}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
          <span className="text-muted">Compare the selected year with YOY totals</span>
        </div>
        {partialContent}
      </div>
        <div className="panel-body">
          <div>
            <Bar height={300} options={barOptions} data={barData} />
          </div>
        </div>
      </div>
    );

  }

}
