import Axios from 'axios';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { DistributionChannel  } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: any[];
  onChange: (channels: DistributionChannel[]) => void;
  multiple: boolean;
  showMarketingMaterials?: boolean | null;
}

type Status = LoadableStatuses<DistributionChannel[], string>;

export const DistributionChannelTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });
  const ref = React.useRef<any>();

  React.useEffect(() => {
    getChannels();

  },              []);

  const getChannels = async () => {
    const c = await Axios.get('/api/distributionChannels');
    const channels = c.data.data.map((channel: any) => new DistributionChannel(channel));
    const selected = [... props.selected];
    let shouldUpdate = false;
    selected.forEach((c, i) => {
      if (c.name === 'Loading') {
        shouldUpdate = true;
        const foundChan = channels.find((ch: DistributionChannel) => Number(ch.id) === Number(c.id));
        if (foundChan) {
          selected[i].name = foundChan.name;
        }
      }
    });
    if (shouldUpdate) {
      props.onChange(selected);
    }
    setStatus({ content: channels , state: 'success' });
  };

  const options = status.state === 'success' ? status.content : [];
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      ref={ref}
      id={'distribution-channel-typeahead'}
      placeholder={`Select Distribution ${props.multiple ? 'Channels' : 'Channel'}`}
      options={options}
      selected={props.selected}
      onChange={(v) => {
        props.onChange(v);
        if (!v.length) {
          ref.current.focus();
          ref.current._showMenu();
        }
      }}
      multiple={props.multiple}
      paginate={false}
      isLoading={isLoading}
      maxResults={100}
      labelKey={(item: DistributionChannel) => item.name}
      clearButton={true}

    />

  );

};
