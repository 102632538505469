import * as React from 'react';
import { formattedFiscalYear } from '../../../utils';

interface IQuarterCell {
  year: number;
  quarter: number;
}

const QuarterCell = (props: IQuarterCell) => {

  const lastTwo = String(props.year).substr(-2);

  const yearStr = formattedFiscalYear(Number(lastTwo));

  return (
        <div className="table-date table-date-royalties">
            <span className="table-date-month text-primary">{yearStr}</span>
            <span className="table-date-day">Q{props.quarter}</span>
        </div>
  );
};

export default QuarterCell;
