import axios, { AxiosError, AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios';
import { logIfCancelled } from '../../api';

export interface ILicensorOverview {
  id: number;
  name: string;
  market: string;
  image: {urls: { th: string; sm: string; md: string; or: string }};
  applicationFee: number;
  renewalFee: number;
  lateFee: number;
  minimumGuarantee: number;
  standardRoyalty: number;
}

export const getLicensorOverviews = (cancelTokenSource: CancelTokenSource): AxiosPromise<ILicensorOverview[]> => {
  const url = '/api/licensor-overviews';
  return axios.get(url, { cancelToken: cancelTokenSource.token })
    .catch((error: AxiosError) => {
      logIfCancelled(error, 'createNewRoyaltyReport');
      throw error;
    })
    .then((response: AxiosResponse): AxiosPromise<ILicensorOverview[]> => {
      return response.data.data.map((item: any) => ({
        id: Number(item.id),
        name: item.short_name,
        market: item.market.name,
        image: item.image ? item.image : null,
        applicationFee: item.profile.application_fee,
        renewalFee: item.profile.renewal_fee,
        lateFee: item.profile.late_fee,
        minimumGuarantee: item.profile.default_royalty_schedule.minimum_guarantee,
        standardRoyalty: item.profile.default_royalty_schedule.standard_royalty,
      }));
    });
};
