import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../../shared';
import { MassUploadStatus, uploadMassUploadSpreadsheet } from './api';

type StatusUpdateFn = (status: MassUploadStatus) => any;

interface IProps {
  statusUpdateFn: StatusUpdateFn;
  onTextSubmit: (urls: string[]) => void;
  disabled?: boolean;
}

export const MassUploadButton = (props: IProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [modalShown, setModalShown] = React.useState(false);
  const [field, setField] = React.useState('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  const selectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      props.statusUpdateFn({ state: 'loading' });
      const resultStatus = await uploadMassUploadSpreadsheet(e.target.files[0]);

      if (hiddenFileInput.current !== null) {
        hiddenFileInput.current.value = '';
      }
      props.statusUpdateFn(resultStatus);
    }
  };

  const submitText = () => {
    setModalShown(false);
    props.onTextSubmit(field.split('\n'));
  };

  return (
    <>
      <button className="dropdown-toggle btn btn-default" onClick={() => setModalShown(true)}>Mass Upload</button>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        onChange={selectFile}
        accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
        />
      <GatewayModal
        shown={modalShown}
        title="Mass Upload URLS"
        type={ModalType.Primary}
        onClose={() => setModalShown(false)}

      >
        <Modal.Body>
          <p className="text-muted text-center">
            Paste your urls in the text box below. This will replace any manually entered data.
          </p>

          <textarea value={field} onChange={e => setField(e.target.value)} className="form-control">

          </textarea>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setModalShown(false)} className="btn btn-default pull-left">Cancel</button>
          <button onClick={submitText} className="btn btn-primary">Submit</button>

        </Modal.Footer>
      </GatewayModal>
    </>
  );
};

interface IStatusAlertProps {
  status: MassUploadStatus;
}

export const MassUploadStatusAlert = (props: IStatusAlertProps) => {

  if (props.status.state === 'failure') {
    return (
      <div className="alert alert-danger">
        Failed to upload trademark use URLs: { props.status.error }
      </div>
    );
  }

  if (props.status.state === 'loading') {
    return (
      <div className="alert alert-info">
        Uploading trademark use URLs...
      </div>
    );
  }

  if (props.status.state === 'success') {
    return (
      <div className="alert alert-success">
        { props.status.content }
      </div>
    );
  }

  return <></>;
};
