import { AccountAddressFormValues } from '../components/Account';
import { AccountAlias } from './AccountAlias';
import { AccountProfile } from './AccountProfile';
import { AffinityImage } from './Image';
import User from './User';

export interface AccountAddressAPIResponse {
  account_dba_profile_id: number;
  account_dba_profile: AccountAlias;
  account_id: number;
  account: AccountProfile;
  city: string;
  country_id: number;
  created_at: Date;
  created_by_user_id: User;
  deleted_by_user_id: number;
  full_address: string;
  id: number;
  is_business_address: boolean;
  is_fulfillment_center: boolean;
  is_office: boolean;
  is_manufacturing_factory: boolean;
  is_retail_storefront: boolean;
  latitude: string;
  logo: AffinityImage;
  longitude: string;
  name: string;
  phone_number: string;
  primary_line: string;
  recipient: string;
  secondary_line: string;
  state_id: string;
  unique_identifier: string;
  updated_at: Date;
  created_by: User;
  zip_code: string;
}

export class AccountAddress {
  dbaProfileId: number;
  accountId: number;
  city: string;
  countryId: number;
  createdAt: Date;
  createdBy: User;
  // created_by_user_id: number;
  // deleted_by_user_id: number;
  fullAddress: string;
  id: number;
  isBusiness: boolean;
  isFulfillment: boolean;
  isOffice: boolean;
  isManufacturing: boolean;
  isRetail: boolean;
  latitude: string;
  logo: AffinityImage;
  longitude: string;
  name: string;
  phoneNumber: string;
  primaryLine: string;
  recipient: string;
  secondaryLine: string;
  stateId: string;
  uniqueIdentifier: string;
  updatedAt: Date;
  zip: string;

  static fromApi(data: AccountAddressAPIResponse) {
    const address = new AccountAddress();
    address.dbaProfileId = data.account_dba_profile_id;
    address.accountId = data.account_id;
    address.city = data.city;
    address.countryId = data.country_id;
    address.fullAddress = data.full_address;
    address.id = data.id;
    address.isBusiness = data.is_business_address;
    address.isFulfillment = data.is_fulfillment_center;
    address.isOffice = data.is_office;
    address.isManufacturing = data.is_manufacturing_factory;
    address.isRetail = data.is_retail_storefront;
    address.latitude = data.latitude;
    address.longitude = data.longitude;
    address.name = data.name;
    address.phoneNumber = data.phone_number;
    address.primaryLine = data.primary_line;
    address.recipient = data.recipient;
    address.secondaryLine = data.secondary_line;
    address.stateId = data.state_id;
    address.uniqueIdentifier = data.unique_identifier;
    address.zip = data.zip_code;
    address.createdAt = data.created_at;
    address.updatedAt = data.updated_at;
    address.createdBy = data.created_by;
    if (data.hasOwnProperty('account_dba_profile') && data.account_dba_profile.hasOwnProperty('logo')) {
      address.logo = AffinityImage.fromApi(data.account_dba_profile.logo);
    } else if (data.account.hasOwnProperty('image')) {
      address.logo = AffinityImage.fromApi(data.account.image);
    } else {
      const image = new AffinityImage();
      image.urls = {
        th: 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png',
        sm: 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png',
        md: 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png',
        or: 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png',
      };
      address.logo = image;
    }

    return address;
  }

  static formValuesToFormData(values: AccountAddressFormValues) {
    const formData = new FormData();
    formData.append('city', values.city);
    formData.append('state_id', values.stateId); // Maybe rename stateid?
    formData.append('zip_code', values.zip);
    formData.append('phone_number', values.phoneNumber);
    formData.append('primary_line', values.primaryLine);
    formData.append('secondary_line', values.secondaryLine);
    formData.append('is_manufacturing_factory', values.isManufacturing ? '1' : '0');
    formData.append('is_fulfillment_center', values.isFulfillment ? '1' : '0');
    formData.append('is_business_address', values.isBusiness ? '1' : '0');
    formData.append('is_retail_storefront', values.isRetail ? '1' : '0');
    formData.append('is_office', values.isOffice ? '1' : '0');
    formData.append('account_dba_profile_id', values.dbaProfileId ? values.dbaProfileId : '');
    formData.append('country_id', values.countryId);
    if (values.name) {
      formData.append('name', values.name);
    }
    if (values.uniqueIdentifier) {
      formData.append('unique_identifier', values.uniqueIdentifier);
    }
    // formData.append('latitude', values.latitude);
    // formData.append('longitude', values.longitude);

    return formData;
  }
}
