import * as React from 'react';
import NumberFormat from 'react-number-format';

import { AccountCell, CurrencyLabel } from '../../shared';
import { ClientStats, VendorStats } from './models';

interface IProps {
  vendor? : VendorStats;
  client? : ClientStats;
  isCurrent: boolean;
  onClick: () => void;
}

export class AccountStatsRow extends React.PureComponent<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    let accountStats: ClientStats | VendorStats;
    let imageUrl = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/olp-logo-bw.jpg';
    let key: number;
    let name: string;
    if (this.props.vendor) {
      const vendor = this.props.vendor;
      accountStats = vendor;
      imageUrl = vendor.vendor.image.getSize('th');
      key = vendor.vendor.id;
      name = vendor.vendor.shortName;
    } else if (this.props.client) {
      const client = this.props.client;
      accountStats = this.props.client;
      const clientImage = client.client.image;
      if (clientImage && clientImage.urls) {
        imageUrl = clientImage.urls.th ? clientImage.urls.th : clientImage.urls.or;
      }
      key = client.client.id;
      name = client.client.shortName;
    } else {
      return null;
    }

    const directionClass = accountStats.comparison.direction === 'up' ? 'text-success' : 'text-danger';
    const directionIcon = accountStats.comparison.direction === 'up' ? 'fa-caret-up' : 'fa-caret-down';
    const rowStyle = { cursor: 'pointer' };

    const previousRoyaltiesCell = (!this.props.isCurrent && accountStats.comparison.previous) ? (
      <td style={{ verticalAlign: 'middle' }}>
        <NumberFormat
          value={accountStats.comparison.previous}
          displayType="text"
          thousandSeparator={true}
          prefix="$"
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </td>
    ) : (
      <td style={{ verticalAlign: 'middle' }}>
        -
      </td>
    );

    const comparisonPercentText = accountStats.comparison.percent ? `${accountStats.comparison.percent}%` : '-';
    const comparisonDifferenceText = accountStats.comparison.difference ? (
      <NumberFormat
        value={accountStats.comparison.difference}
        displayType="text"
        thousandSeparator={true}
        prefix="$"
        decimalScale={2}
        fixedDecimalScale={true}
      />
    ) : (
      '-'
    );

    const comparisonDiffCell = !this.props.isCurrent && comparisonDifferenceText !== '-' ? (
        <td className={directionClass} style={{ verticalAlign: 'middle' }}>
          <i className={`fa ${directionIcon}`}></i>  {comparisonDifferenceText}
        </td>
      )
      :
      (
        <td style={{ verticalAlign: 'middle' }}>
          -
        </td>
      );

    const comparisonPercentCell = !this.props.isCurrent && comparisonPercentText !== '-' ? (
        <td className={directionClass} style={{ verticalAlign: 'middle' }}>
          <i className={`fa ${directionIcon}`}></i>  {comparisonPercentText}
        </td>
      )
      :
      (
        <td style={{ verticalAlign: 'middle' }}>
           -
        </td>
      );

    return (
        <tr onClick={this.props.onClick}  key={key} style={rowStyle}>
          <td style={{ verticalAlign: 'middle' }}>{ accountStats.rank }</td>
          <td style={{ verticalAlign: 'middle' }}>
            <AccountCell image={imageUrl} name={name} />
          </td>
          <td style={{ verticalAlign: 'middle' }}><strong><CurrencyLabel value={accountStats.royalties} /></strong></td>
          {previousRoyaltiesCell}
          {comparisonDiffCell}
          {comparisonPercentCell}

        </tr>
    );

  }

}
