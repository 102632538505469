import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { RoyaltyReport } from '../../../shared';
import { getVendorUrl } from './utils';

interface IProps {
  report: RoyaltyReport;
  userType: string;
}

const getButton = (report: RoyaltyReport, userType: string) => {

  const isSubmitted = report.phase.id !== 1;

  const salesDataUrl = getVendorUrl(`/royalties/${report.id}/sales-data`, report.vendor.id, userType);
  const aggregateUrl = getVendorUrl(`/royalties/${report.id}/aggregate`, report.vendor.id, userType);

  if (!isSubmitted) {
    return (
      <div>
        {report.totalSalesDataLineItems ? <Link className="btn btn-default btn-block" to={salesDataUrl}>
          Sales Data Report <FontAwesomeIcon className="icon-right text-primary" icon={faArrowRight} />
        </Link> : null}
        {report.totalAggregateLineItems ? <Link className="btn btn-default btn-block" to={aggregateUrl}>
          Aggregate Report <FontAwesomeIcon className="icon-right text-primary" icon={faArrowRight} />
        </Link> : null}
      </div>
    );
  }
  const invoiceUrl =  `/invoices/${report.invoiceId}`;
  return <div>
    {report.totalSalesDataLineItems ? <Link className="btn btn-default btn-block" to={salesDataUrl}>
      Sales Data Report <FontAwesomeIcon className="icon-right text-primary" icon={faArrowRight} />
    </Link> : null}
    <a className="btn btn-primary btn-block" target="_blank" href={invoiceUrl}>View Invoice</a>
  </div>;
};

const getTopSection = (report: RoyaltyReport, isAdmin: boolean) => {
  const isSubmitted = report.phase.id !== 1;

  if (!isSubmitted && !isAdmin) {
    return <strong className="text-center text-primary">Select the report type below to continue.</strong>;
  }

  const invoice = report.invoice;

  let invoiceStatusMarkup = <></>;

  if (invoice) {
    if (invoice.isOpen) {
      invoiceStatusMarkup = (
        <div className="text-danger" style={{ marginBottom: 5 }}>
          <NumberFormat
            value={invoice.amountDue}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true}
          />
          <div className="label label-danger" style={{ marginLeft: 5 }}>Due</div>
        </div>
      );
    } else {
      invoiceStatusMarkup = (
      <div className="text-success" style={{ marginBottom: 5 }}>
        <NumberFormat
          value={invoice.amountTotal}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
        />
        <div className="label label-success" style={{ marginLeft: 5 }}>Paid</div>
      </div>
      );
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontWeight: 'bold', fontSize: 24, lineHeight: 1 }}>
        <NumberFormat
          value={report.totalDue}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'$'}
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </div>
      <div className="text-muted" style={{ fontSize: 12, marginBottom: 8 }}>Total Royalties</div>
      {invoiceStatusMarkup}
    </div>
  );
};

const ReportActionBox = (props: IProps) => {
  const report = props.report;

  const button = getButton(report, props.userType);
  const topSection = getTopSection(report, props.userType === 'admin');

  return (
    <div className="panel panel-portal">
      <div className="panel-body text-center">
        {topSection}
      </div>
      <div className="panel-footer">
        {button}
      </div>

    </div>
  );

};

export default ReportActionBox;
