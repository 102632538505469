import { ErrorMessage } from 'formik';
import * as React from 'react';
import { UserSetInput } from '../../shared/UserSetInput';

type Options = { name: string }[];

export interface IOptionGroup {
  name: string;
  options: Options;
}

interface IProps {
  id: number;
  groupName: string;
  options: Options;
  onUpdateSelections: (group: IOptionGroup) => unknown;
  onRemove: () => unknown;
}

export const OptionGroupInput = (props: IProps) => {

  const onUpdateOptions = (selected: ({ name: string }[])) => {
    if (selected !== props.options) {
      props.onUpdateSelections({
        name: props.groupName,
        options: selected,
      });
    }
  };

  const onUpdateGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
    const groupName = event.target.value;
    if (groupName !== props.groupName) {
      props.onUpdateSelections({
        name: groupName,
        options: props.options,
      });
    }
  };

  return (
    <div className="row" style={{ marginBottom: 10, width: '100%' }}>
      <div className="col-md-4">
        <div>
          <label style={{ margin: '0 0 2px 0' }}>Option {props.id + 1}</label>
        </div>
        <input
          className="form-control"
          type="text"
          placeholder="Option name (e.g. size, color)"
          value={props.groupName}
          onChange={onUpdateGroup}
        />
        <div className="text-danger">
          <ErrorMessage name={`options.${props.id}.name`} />
        </div>
      </div>
      <div className="col-md-8">
        <div style={{ textAlign: 'right' }}>
          <button
            type="button"
            className="btn btn-link"
            style={{ margin:0, padding: 0 }}
            onClick={props.onRemove}
          >
            Remove
          </button>
        </div>
        <UserSetInput
          id={`option-groups-${props.id}-options-id`}
          selected={props.options}
          onUpdateSelections={onUpdateOptions}
        />

        <div className="text-danger">
          <ErrorMessage name={`options.${props.id}.options`} />
        </div>
      </div>
    </div>
  );
};
