import * as moment from 'moment';
import { AffinityImage } from '.';
import { Trademark } from './Trademark';

export interface WebBotUrlApiResponse {
  licensors: any;
  id: number;
  platform_identifier: string;
  full_url: string;
  name: string;
  description: string;
  price:string;
  possible_licensed: boolean | null;
  image_url: string;
  status: { id: number, name: string };
  created_at: string;
  deleted_at: string;
  updated_at: string;
  tags: string[];
}
export class WebBotUrl {

  id: number;
  platformIdentifier: string;
  fullUrl: string;
  name: string;
  description: string;
  price: string;
  possibleLicensed: boolean | null;
  imageUrl: string;
  licensors: any;
  status: { id: number, name: string };
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  deletedAt: moment.Moment | null;
  tags: string[];

  static fromApi(data: WebBotUrlApiResponse) {
    const u = new WebBotUrl();
    u.id = data.id;
    u.platformIdentifier = data.platform_identifier;
    u.fullUrl = data.full_url;
    u.name = data.name;
    u.description = data.description;
    u.price = data.price;
    u.possibleLicensed = data.possible_licensed;
    u.imageUrl = data.image_url;
    u.status = data.status;
    u.licensors = data.licensors && data.licensors.length ? data.licensors.map((l:any) => {
      return {
        id: l.id,
        totalProducts: l.total_products,
        shortName: l.short_name,
        trademarks: l.trademark ? [Trademark.fromApi(l.trademark)] : [],
        defaultTrademark: l.default_trademark,
        affinityImage: l.image_id ? AffinityImage.fromApi({ id:0 , previous_file_name:'', urls:l.image }) : new AffinityImage(),
        image: l.image ? l.image : null,
      };
    }) : [];
    u.createdAt = moment(data.created_at);
    u.updatedAt = moment(data.updated_at);
    u.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;
    u.tags = data.tags;
    return u;

  }

}
