import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import { Filters } from '../../../shared';

export class ScanFilters extends Filters {
  authorized = '';

  constructor(location?: string) {
    super();
    this.defaultFilters.status = 0;
    this.status = 0;
    if (location) {
      this.setQueryParamsFromLocation(location);
    }

  }

  setQueryParamsFromLocation(location: string) {
    super.setQueryParamsFromLocation(location);
    const queryValues = queryString.parse(location);
    this.sortBy = queryValues.sort ? queryValues.sort : this.sortBy;
    this.status = queryValues.status ? queryValues.status : this.status;
    this.authorized = queryValues.authorized ? queryValues.authorized : this.authorized;
    this.licensorId = queryValues.licensorId ? queryValues.licensorId : this.licensorId;
  }

  generateQueryParamString() {
    const queryParams = {
      search: this.search.length ? this.search : null,
      page: this.page !== 1 ? this.page : null,
      user: this.userId ? this.userId : null,
      sort: this.sortBy.length ? this.sortBy : null,
      status: this.status ? this.status : null,
      authorized: this.authorized ? this.authorized : null,
      licensorId: this.licensorId ? this.licensorId : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  setFilters(filters: any) {
    super.setFilters(filters);
    const newFilters = {
      authorized: this.authorized,
      licensorId: this.licensorId,
      ...filters,
    };
    this.sortBy = newFilters.sortBy;
    this.page = newFilters.page;
    this.authorized = newFilters.authorized;
    this.licensorId = newFilters.licensorId;
  }

  get filters() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      search: this.search,
      startDate: this.startDate,
      endDate: this.endDate,
      market: this.market,
      userId: this.userId,
      authorized: this.authorized,
      licensorId: this.licensorId,
      status: this.status,
    };
  }
}
