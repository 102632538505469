import { faCog, faFilter } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as mapboxgl from 'mapbox-gl';
import * as querystring from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import { DistributionChannel, ProductCategory, Vendor } from '../../shared';
import { formattedFiscalYear, getYearFromFiscal } from '../../utils';
import { AffinityMap, DistributionChannelTypeahead, GatewayModal, ModalType, ProductCategoryTypeahead, VendorTypeahead } from '../shared';

export interface LayerInfo {
  id: string;
  name: string;
  sourceName: string;
  layerName: string;
  isChecked: boolean;
  minZoom?: number;
  maxZoom?: number;
  mapColor?: string;
  isMapHeatmap?: number;
  isMapLocationPin?: number;
  max?: number;
}

export interface LayerGroup {
  id: string;
  isChecked: boolean;
  name: string;
  mapColor?: string;
  layers: LayerInfo[];
}
let y: number;
const date = new Date();
if (date.getMonth() >= 9) {
  y = date.getFullYear();
} else {
  y = date.getFullYear() - 1;
}

interface MapViewerParams {
  categories: ProductCategory[];
  channels: DistributionChannel[];
  vendors: Vendor[];
  retailers: Vendor[];
  quarter: string;
  year: number;
  showSales: boolean;
  showAudience: boolean;
  showRetailers: boolean;
  showPotentialRetailers: boolean;
  audience: number[];
}

export const AudienceMapViewer = () => {
  const map = React.useRef<any>(null);
  const mapData = React.useRef<any>(null);
  const routeParams = useParams();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [defaultLng, setDefaultLng] = useState(-98.583333);
  const [defaultLat, setDefaultLat] = useState(39.833333);
  const [zoom, setZoom] = useState(3.75);
  const [audienceLayerNames, setAudienceLayerNames] = useState<LayerGroup[]>([]);
  const [retailersLayerNames, setRetailersLayerNames] = useState<LayerGroup[]>([]);
  const [potentialRetailersLayerNames, setPotentialRetailersLayerNames] = useState<LayerGroup[]>([]);
  const [salesLayerNames, setSalesLayerNames] = useState<LayerGroup[]>([]);
  const [allAudiencesCheckbox, setAllAudiencesCheckbox] = useState(true);
  const [savingDefaults, setSavingDefaults] = React.useState(false);
  const [viewerParams, setViewerParams] = React.useState<MapViewerParams>({
    categories: [],
    channels: [],
    vendors: [],
    retailers: [],
    quarter: 'All',
    year: y,
    showSales: 1,
    showAudience: 1,
    showRetailers: 1,
    showPotentialRetailers: 1,
    audience: [],
  });
  const [firstLoad, setFirstLoad] = React.useState(false);
  const countyClickRef = React.useRef<any>(null);
  const stateClickRef = React.useRef<any>(null);
  const audienceClickRef = React.useRef<any>(null);
  const retailerClickRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (firstLoad) {
      const filters = {
        channels: viewerParams.channels.length ? viewerParams.channels.map((c: any) => c.id).join(',') : null,
        categories: viewerParams.categories.length ? viewerParams.categories.map((c: ProductCategory) => c.id).join(',') : null,
        retailers: viewerParams.retailers.length ? viewerParams.retailers.map((v: Vendor) => v.id).join(',') : null,
        vendors: viewerParams.vendors.length ? viewerParams.vendors.map((v: Vendor) => v.id).join(',') : null,
        year: viewerParams.year === y ? null : viewerParams.year,
        quarter: viewerParams.quarter === 'All' ? null : viewerParams.quarter,
        withSales: viewerParams.showSales ? 1 : null,
        withAudience: viewerParams.showAudience ? 1 : null,
        withRetailers: viewerParams.showRetailers ? 1 : null,
        withPotentialRetailers: viewerParams.showPotentialRetailers ? 1 : null,
        audience: viewerParams.audience.length ? viewerParams.audience.join(',')  : null,
      };

      const cleaned = omitBy(filters, isNil);
      const qs = querystring.stringify(cleaned);

      history.replace(`${location.pathname}?${qs}`);
    }

  },              [viewerParams]);

  React.useEffect(() => {
    const locationParams = querystring.parse(location.search);
    const filters = {
      channel_ids: locationParams.channels ? locationParams.channels : null,
      category_ids: locationParams.categories ? locationParams.categories : null,
      retailer_ids: locationParams.retailers ? locationParams.retailers : null,
      vendor_ids: locationParams.vendors ? locationParams.vendors : null,
      year: locationParams.year ? locationParams.year : null,
      quarter: locationParams.quarter ? locationParams.quarter : null,
      withSales: locationParams.withSales ? 1 : null,
      withAudience: locationParams.withAudience ? 1 : null,
      withRetailers: locationParams.withRetailers ? 1 : null,
      withPotentialRetailers: locationParams.withPotentialRetailers ? 1 : null,
      audience_ids: locationParams.audience ? locationParams.audience : null,
    };

    const p = {
      categories: filters.category_ids ? filters.category_ids.split(',').map((c: any) => new ProductCategory({ id: c, name: 'Loading' })) : [],
      channels: filters.channel_ids ? filters.channel_ids.split(',').map((c: any) => new DistributionChannel({ id: c, name: 'Loading' })) : [],
      vendors: filters.vendor_ids ? filters.vendor_ids.split(',').map((v: any) => new Vendor({ id: v, short_name: 'Loading' })) : [],
      retailers: filters.retailer_ids ? filters.retailer_ids.split(',').map((r: any) => new Vendor({ id: r, short_name: 'Loading' })) : [],
      quarter: filters.quarter ? filters.quarter : 'All',
      year: filters.year ?  filters.year : y,
      showSales: filters.withSales ? true : false,
      showAudience: filters.withAudience ? true : false,
      showRetailers: filters.withRetailers ? true : false,
      showPotentialRetailers: filters.withPotentialRetailers ? true : false,
      audience: filters.audience_ids ? filters.audience_ids.split(',').map((a: any) => Number(a)) : [],

    };
    setViewerParams(p);
    setFirstLoad(true);

  },              []);

  React.useEffect(() => {
    getMapData();
  },              [location.search]);

  const getMapData = async () => {
    setLoading(true);
    const locationParams = querystring.parse(location.search);
    const filters = {
      account_id: getAccountID(),
      channel_ids: locationParams.channels ? locationParams.channels : null,
      category_ids: locationParams.categories ? locationParams.categories : null,
      retailer_ids: locationParams.retailers ? locationParams.retailers : null,
      vendor_ids: locationParams.vendors ? locationParams.vendors : null,
      year: locationParams.year ? locationParams.year : null,
      quarter: locationParams.quarter ? locationParams.quarter.charAt(1) : null,
      withSales: locationParams.withSales ? 1 : null,
      withAudience: locationParams.withAudience ? 1 : null,
      withRetailers: locationParams.withRetailers ? 1 : null,
      withPotentialRetailers: locationParams.withPotentialRetailers ? 1 : null,
      audience_ids: locationParams.audience ? locationParams.audience : null,
    };

    const cleaned = omitBy(filters, isNil);

    const response = await Axios.get('/api/audience/map', { params: cleaned });
    mapData.current = response.data;
    if (mapData.current.default == null) {
      mapData.current.default = { zoom, longitude: defaultLng, latitude: defaultLat };
    }
    addMapLayers();
  };

  const onStateClick = (e: any) => {
    closeAllPopups();
    const stateSalesPopup = new mapboxgl.Popup();
    const description = `<strong>${e.features[0].properties.NAME}</strong>
                                ${e.features[0].properties.net_sales ? `<br>Rank #${e.features[0].properties.rank}, ${e.features[0].properties.percentage < 1 ? '<1' : e.features[0].properties.percentage}% of royalties` : ''}
                                <br>Sales: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.net_sales)}
                                <br>Royalties: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.royalty_sales)}`;
    stateSalesPopup
      .setLngLat(e.lngLat)
      .setHTML(description)
      .addTo(map.current.map);
  };

  function closeAllPopups() {
    const popups = document.getElementsByClassName('mapboxgl-popup');
    if (popups.length > 0) {
      for (let i = 0; i < popups.length; i++) {
        popups[i].remove();
      }
    }
  }

  const onCountyClick = (e: any) => {
    closeAllPopups();
    const countySalesPopup = new mapboxgl.Popup();
    const description = `<strong>${e.features[0].properties.NAMELSAD}, ${e.features[0].properties.STUSPS}</strong>
                                ${e.features[0].properties.net_sales ? `<br>Rank #${e.features[0].properties.rank}, ${e.features[0].properties.percentage < 1 ? '<1' : e.features[0].properties.percentage}% of royalties` : ''}
                                <br>Sales: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.net_sales)}
                                <br>Royalties: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.features[0].properties.royalty_sales)}`;
    countySalesPopup
      .setLngLat(e.lngLat)
      .setHTML(description)
      .addTo(map.current.map);
  };

  const onAudienceClick = (e: any) => {
    closeAllPopups();
    const audiencePopup = new mapboxgl.Popup();
    const description = `<strong>${e.features[0].properties.label}</strong>
                                    <br>Audience Total: ${e.features[0].properties.value}`;
    audiencePopup
      .setLngLat(e.lngLat)
      .setHTML(description)
      .addTo(map.current.map);
  };

  const onRetailerClick = (e: any) => {
    closeAllPopups();

    const retailerPopup = new mapboxgl.Popup();
    let retailerHtml = `<strong>${e.features[0].properties.short_name}</strong><br>
                                ${e.features[0].properties.address}<br>`;
    if (e.features[0].properties.icon_url) {
      retailerHtml = `<div style="height: 100px"><img src="${ e.features[0].properties.icon_url}" style="height: 100px" class="center-block"></div>${retailerHtml}`;
    }
    if (e.features[0].properties.is_business_address === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-primary">Business Address</span>`;
    }
    if (e.features[0].properties.is_manufacturing_factory === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Manufacturing</span>`;
    }
    if (e.features[0].properties.is_retail_storefront === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Retail</span>`;
    }
    if (e.features[0].properties.is_fulfillment_center === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Fulfillment</span>`;
    }
    if (e.features[0].properties.is_office === '1') {
      retailerHtml = `${retailerHtml}<span style="display: block;margin: 5px;" class="label label-default">Office</span>`;
    }
    retailerHtml = `<div class="text-center">${retailerHtml}</div>`;
    retailerPopup
      .setLngLat(e.lngLat)
      .setHTML(retailerHtml)
      .addTo(map.current.map);
  };

  const toggleAllAudiences = () => {
    const al = [...audienceLayerNames];
    const visibility = ! allAudiencesCheckbox ? 'visible' : 'none';
    setAllAudiencesCheckbox(visibility === 'visible');

    al.forEach((lGroup:LayerGroup) => {
      lGroup.isChecked = visibility === 'visible';
      lGroup.layers.forEach((i:LayerInfo) => {
        map.current.map.setLayoutProperty(
          i.layerName,
          'visibility',
          visibility,
        );
      });
    });
  };

  const toggleLayers = (e: any, layerInfo: LayerGroup, type: string) => {

    let visibility = 'visible';
    if (layerInfo.isChecked) {
      visibility = 'none';
      layerInfo.layers.forEach((i:LayerInfo) => {
        map.current.map.setLayoutProperty(
          i.layerName,
          'visibility',
          'none',
        );
      });
    } else {
      visibility = 'visible';
      layerInfo.layers.forEach((i:LayerInfo) => {
        map.current.map.setLayoutProperty(
          i.layerName,
          'visibility',
          'visible',
        );
      });
    }
    if (type === 'audiences') {
      const al = [...audienceLayerNames];
      let allUnchecked = true;
      al.forEach((lGroup:LayerGroup) => {
        if (lGroup.id === layerInfo.id) {
          lGroup.isChecked = visibility === 'visible';
        }
        if (lGroup.isChecked) {
          allUnchecked = false;
        }
      });
      setAudienceLayerNames(al);
      if (visibility === 'visible') {
        setAllAudiencesCheckbox(true);
      } else if (allUnchecked) {
        setAllAudiencesCheckbox(true);
      }
    }

    if (type === 'retailers') {
      const rl = [...retailersLayerNames];
      rl.forEach((lGroup:LayerGroup) => {
        if (lGroup.id === layerInfo.id) {
          lGroup.isChecked = visibility === 'visible';
        }
      });
      setRetailersLayerNames(rl);
    }

    if (type === 'potentialRetailers') {
      const prl = [...potentialRetailersLayerNames];
      prl.forEach((lGroup:LayerGroup) => {
        if (lGroup.id === layerInfo.id) {
          lGroup.isChecked = visibility === 'visible';
        }
      });
      setPotentialRetailersLayerNames(prl);
    }

    if (type === 'sales') {
      const sl = [...salesLayerNames];
      sl.forEach((lGroup:LayerGroup) => {
        if (lGroup.id === layerInfo.id) {
          lGroup.isChecked = visibility === 'visible';
        }
      });
      setSalesLayerNames(sl);
    }
  };

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  const setNewDefaults = () => {
    if (!map.current.map) return;
    setSavingDefaults(true);
    const lng = map.current.map.getCenter().lng.toFixed(4);
    const lat = map.current.map.getCenter().lat.toFixed(4);
    const zoom = map.current.map.getZoom().toFixed(2);
    console.log(lng, lat, zoom);

    const params = {
      lng,
      lat,
      zoom,
      account_id: getAccountID(),
    };

    Axios.post('/api/audience/map', params)
      .then((response) => {
        setSavingDefaults(false);
      });
  };

  const addMapLayers = () => {
    const toRemove: LayerInfo[] = [];
    salesLayerNames.forEach((g) => {
      g.layers.forEach(l => toRemove.push(l));
    });
    audienceLayerNames.forEach((g) => {
      g.layers.forEach(l => toRemove.push(l));
    });
    retailersLayerNames.forEach((g) => {
      g.layers.forEach(l => toRemove.push(l));
    });
    potentialRetailersLayerNames.forEach((g) => {
      g.layers.forEach(l => toRemove.push(l));
    });
    map.current.removeLayers(toRemove);
    toRemove.forEach((l) => {
      map.current.map.off('click', l.layerName);
    });
    stateClickRef.current = null;
    countyClickRef.current = null;
    audienceClickRef.current = null;
    retailerClickRef.current = null;

    const audienceLayersGroup = [];

    // sales
    if (mapData.current.sales) {
      const stateSalesInfo: LayerInfo = {
        id: 'state-sales-all',
        name: 'state-sales',
        sourceName: 'state-sales-source',
        layerName: 'state-sales-layer',
        isChecked: true,
        minZoom: 0,
        maxZoom: 5,
      };
      map.current.map.addSource(stateSalesInfo.sourceName, {
        type: 'geojson',
        data: mapData.current.sales.state.salesJson,
      });
      map.current.addChoroplethLayer(stateSalesInfo, mapData.current.sales.state.max, mapData.current.sales.state.max2);

      stateClickRef.current = onStateClick;
      map.current.map.on('click', stateSalesInfo.layerName, stateClickRef.current);

      map.current.map.on('mouseenter', stateSalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = 'pointer';
      });
      map.current.map.on('mouseleave', stateSalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = '';
      });

      const countySalesInfo: LayerInfo = {
        id: 'county-sales-all',
        name: 'county-sales',
        sourceName: 'county-sales-source',
        layerName: 'county-sales-layer',
        isChecked: true,
        minZoom: 5,
        maxZoom: 23,
      };
      map.current.map.addSource(countySalesInfo.sourceName, {
        type: 'geojson',
        data: mapData.current.sales.county.salesJson,
      });
      map.current.addChoroplethLayer(countySalesInfo, mapData.current.sales.county.max, mapData.current.sales.county.max2);

      countyClickRef.current = onCountyClick;
      map.current.map.on('click', countySalesInfo.layerName, countyClickRef.current);

      map.current.map.on('mouseenter', countySalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = 'pointer';
      });
      map.current.map.on('mouseleave', countySalesInfo.layerName, () => {
        map.current.map.getCanvas().style.cursor = '';
      });
      const allSalesInfo: LayerGroup = {
        id: 'all-sales',
        name: 'all-sales-group',
        isChecked: true,
        layers: [stateSalesInfo, countySalesInfo],
      };
      setSalesLayerNames([allSalesInfo]);
    }

    if (mapData.current.audience) {
      for (const data of mapData.current.audience.data) {
        const audienceLayersInfo = [];

        const info: LayerInfo = {
          id: data.id,
          name: data.name,
          max: mapData.current.audience.metadata.max,
          sourceName: `audience-${data.id}`,
          layerName: `audience-layer-${data.id}`,
          isChecked: true,
          minZoom: 0,
          maxZoom: 23,
          isMapHeatmap: Number(data.isMapHeatmap),
          isMapLocationPin: Number(data.isMapLocationPin),
          mapColor: data.mapColor,
        };

        map.current.map.addSource(info.sourceName, {
          type: 'geojson',
          data: data.audienceJson,
        });
        // audiences
        if (info.isMapHeatmap) {
          map.current.addHeatmapLayer(info);
          audienceLayersInfo.push(info);
          audienceClickRef.current = onAudienceClick;
          map.current.map.on('click', info.layerName, audienceClickRef.current);

          map.current.map.on('mouseenter', info.layerName, () => {
            map.current.map.getCanvas().style.cursor = 'pointer';
          });
          map.current.map.on('mouseleave', info.layerName, () => {
            map.current.map.getCanvas().style.cursor = '';
          });
        }
        if (info.isMapLocationPin) {
          const pinInfo = { ...info };
          pinInfo.layerName = `${pinInfo.layerName}-marker`;
          pinInfo.id = `${pinInfo.id}-marker`;
          map.current.addMarkerLayer(pinInfo);
          audienceLayersInfo.push(pinInfo);
          audienceClickRef.current = onAudienceClick;
          map.current.map.on('click', pinInfo.layerName, audienceClickRef.current);

          map.current.map.on('mouseenter', pinInfo.layerName, () => {
            map.current.map.getCanvas().style.cursor = 'pointer';
          });
          map.current.map.on('mouseleave', pinInfo.layerName, () => {
            map.current.map.getCanvas().style.cursor = '';
          });
          map.current.map.moveLayer(pinInfo.layerName);
        }
        const audienceGroup: LayerGroup = {
          id: `${data.id}-group`,
          name: data.name,
          isChecked: true,
          mapColor: data.mapColor,
          layers: audienceLayersInfo,
        };
        audienceLayersGroup.push(audienceGroup);
      } // END audiences for loop
      setAudienceLayerNames(audienceLayersGroup);
    }

    if (mapData.current.retailers) {
      if (mapData.current.retailers.data.potential) {
        map.current.map.loadImage(
          '/assets/images/marker-icons/mapbox-marker-icon-retailer-grey.png',
          (error: any, defaultImage: any) => {
            if (error) throw error;
            const potentialRetailerLayerInfo = [];

            const retailersInfo: LayerInfo = {
              id: 'retailers-potential-marker',
              name: 'retailers-potential',
              sourceName: 'retailers-potential-source',
              layerName: 'retailers-potential-layer',
              isChecked: true,
            };

            map.current.map.addSource(retailersInfo.sourceName, {
              type: 'geojson',
              data: mapData.current.retailers.data.potential.retailerJson,
            });

            map.current.map.addImage(retailersInfo.id, defaultImage);
            map.current.map.addLayer({
              id: retailersInfo.layerName,
              type: 'symbol',
              source: retailersInfo.sourceName,
              visibility: 'visible',
              layout: {
                'icon-image': retailersInfo.id,
                'icon-allow-overlap': false,
              },
            });

            retailerClickRef.current = onRetailerClick;
            map.current.map.on('click', retailersInfo.layerName, retailerClickRef.current);

            map.current.map.on('mouseenter', retailersInfo.layerName, () => {
              map.current.map.getCanvas().style.cursor = 'pointer';
            });
            map.current.map.on('mouseleave', retailersInfo.layerName, () => {
              map.current.map.getCanvas().style.cursor = '';
            });

            potentialRetailerLayerInfo.push(retailersInfo);

            const potentialRetailersGroup: LayerGroup = {
              id: 'all-retailers-potential',
              name: 'all-retailers-group-potential',
              isChecked: true,
              layers: potentialRetailerLayerInfo,
            };
            setPotentialRetailersLayerNames([potentialRetailersGroup]);
          });
      }

      if (mapData.current.retailers.data.linked) {
        map.current.map.loadImage(
          '/assets/images/marker-icons/mapbox-marker-icon-retailer-blue.png',
          (error: any, defaultImage: any) => {
            if (error) throw error;
            const retailerLayerInfo = [];

            const retailersInfo: LayerInfo = {
              id: 'retailers-linked-marker',
              name: 'retailers-linked',
              sourceName: 'retailers-linked-source',
              layerName: 'retailers-linked-layer',
              isChecked: true,
            };

            map.current.map.addSource(retailersInfo.sourceName, {
              type: 'geojson',
              data: mapData.current.retailers.data.linked.retailerJson,
            });

            map.current.map.addImage(retailersInfo.id, defaultImage);
            map.current.map.addLayer({
              id: retailersInfo.layerName,
              type: 'symbol',
              source: retailersInfo.sourceName,
              visibility: 'visible',
              layout: {
                'icon-image': retailersInfo.id,
                'icon-allow-overlap': true,
              },
            });

            retailerClickRef.current = onRetailerClick;
            map.current.map.on('click', retailersInfo.layerName, retailerClickRef.current);

            map.current.map.on('mouseenter', retailersInfo.layerName, () => {
              map.current.map.getCanvas().style.cursor = 'pointer';
            });
            map.current.map.on('mouseleave', retailersInfo.layerName, () => {
              map.current.map.getCanvas().style.cursor = '';
            });

            retailerLayerInfo.push(retailersInfo);

            const retailersGroup: LayerGroup = {
              id: 'all-retailers',
              name: 'all-retailers-group',
              isChecked: true,
              layers: retailerLayerInfo,
            };
            setRetailersLayerNames([retailersGroup]);
          });
      }
    }
    setLoading(false);

  };

  return (
    <div style={{ height: '100%', width: '100%', position: 'relative' }}>
      <AudienceMapOptions onSetDefaults={setNewDefaults}
                          onToggleLayer={toggleLayers}
                          onToggleAllAudiences={toggleAllAudiences}
                          allAudiencesCheckbox={allAudiencesCheckbox}
                          audienceLayerNames={audienceLayerNames}
                          potentialRetailersLayerNames={potentialRetailersLayerNames}
                          retailersLayerNames={retailersLayerNames}
                          salesLayerNames={salesLayerNames}
                          savingDefaults={savingDefaults}
                          viewerParams={viewerParams}
                          onParamChange={setViewerParams}
                          />
      <AffinityMap useDefaultCenter={true} isFullScreen={true} ref={map} height="100%" width="100%" loading={loading} />

    </div>
  );
};

interface MapOptionProps {
  onSetDefaults: () => void;
  onToggleLayer: (e: any, layerInfo: LayerGroup, type: string) => void;
  onToggleAllAudiences: (e: any) => void;
  allAudiencesCheckbox: boolean;
  audienceLayerNames: LayerGroup[] | [];
  potentialRetailersLayerNames: LayerGroup[] | [];
  retailersLayerNames: LayerGroup[] | [];
  salesLayerNames: LayerGroup[] | [];
  savingDefaults: boolean;

  viewerParams: MapViewerParams;
  onParamChange: (params: MapViewerParams) => void;

}

const AudienceMapOptions = (props: MapOptionProps) => {
  const routeParams = useParams();
  const [modalShown, setModalShown] = React.useState(false);
  const [audienceSegments, setAudienceSegments] = React.useState<any[]>([]);

  React.useEffect(() => {
    getAvailableAudience();

  },              []);

  const onSubmit = (values: MapViewerParams) => {
    const v = Object.assign({}, values);
    if (!v.showAudience) {
      v.audience = [];
    }
    props.onParamChange(v);
    setModalShown(false);

  };

  const getAvailableAudience = async () => {
    const params = {
      account_id: getAccountID(),
    };
    const s = await Axios.get('/api/audience/segments', { params });
    setAudienceSegments(s.data.data);
  };

  const getAccountID = () => {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  };

  const setDefaultTextMsg = props.savingDefaults ? 'Saving...' : 'Set Default Zoom and Location';

  const years: any[] = [];
  let year;
  const date = new Date();
  if (date.getMonth() >= 9) {
    year = date.getFullYear();
  } else {
    year = date.getFullYear() - 1;
  }
  for (let i = 0; i <= (year - 2018); i++) {
    const yearToAdd = 2018 + i;
    const yearValue = formattedFiscalYear(yearToAdd);
    years.push(<MenuItem key={yearToAdd} eventKey={yearValue} >{yearValue}</MenuItem>);
  }

  const dashboardAud = audienceSegments.filter((u: any) => u.onDashboard).map((u: any) => u.id);

  return (
    <div className="audience-map-options">
      <button onClick={() => setModalShown(true)} className="btn btn-primary"><FontAwesomeIcon icon={faCog} /> Map Options</button>

      <GatewayModal shown={modalShown} type={ModalType.Primary} title="Map Settings" onClose={() => setModalShown(false)}>

        <Formik initialValues={props.viewerParams} onSubmit={onSubmit}>

          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="map-actions-container">
                    <div className="checkbox">
                      <label>
                        <input
                          name="showAudiences"
                          onChange={(e) => {
                            if (formProps.values.showAudience) {
                              formProps.setFieldValue('audience', []);
                            }
                            formProps.setFieldValue('showAudience', !formProps.values.showAudience);
                          }}
                          checked={formProps.values.showAudience}
                          type="checkbox"
                        /> Audiences
                      </label>
                    </div>
                    { formProps.values.showAudience ? audienceSegments.map((s: any) => (
                      <div style={{ marginLeft: 20 }} className="checkbox">
                        <label>
                          <input
                            onChange={(e) => {
                              const aud = [... formProps.values.audience];
                              const d = aud.length ? aud : [... dashboardAud];
                              if (d.includes(Number(s.id))) {
                                const i = d.findIndex(a => Number(a) === Number(s.id));
                                if (i !== -1) {
                                  d.splice(i, 1);
                                }
                                if (d.length === 0) {
                                  formProps.setFieldValue('showAudience', false);
                                }
                              } else {
                                d.push(Number(s.id));
                              }
                              formProps.setFieldValue('audience', d);
                            }}
                            checked={formProps.values.audience.includes(Number(s.id)) || (formProps.values.audience.length === 0 && dashboardAud.includes(Number(s.id)))}
                            type="checkbox"
                          /> {s.name}
                        </label>
                      </div>
                    )) : null}

                  <hr />
                  <div className="checkbox">
                    <label>
                      <input
                        name="showRetailers"
                        onChange={e => formProps.setFieldValue('showRetailers', !formProps.values.showRetailers)}
                        checked={formProps.values.showRetailers}
                        type="checkbox"
                      /> Retailers
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <input
                        name="showPotentialRetailers"
                        onChange={e => formProps.setFieldValue('showPotentialRetailers', !formProps.values.showPotentialRetailers)}
                        checked={formProps.values.showPotentialRetailers}
                        type="checkbox"
                      /> Potential Retailers
                    </label>
                  </div>

                  <hr />
                  <div className="checkbox">
                    <label>
                      <input
                        name="showSales"
                        onChange={e => formProps.setFieldValue('showSales', !formProps.values.showSales)}
                        checked={formProps.values.showSales}
                        type="checkbox"
                      /> Sales
                    </label>
                  </div>

                  <hr />
                  <h4 style={{ marginBottom: 20 }}>Filters <FontAwesomeIcon className="text-muted" icon={faFilter} /></h4>
                  <DropdownButton
                    onSelect={q => formProps.setFieldValue('quarter', q)}
                    id="quarter-dropdown"
                    bsStyle={'default'}
                    title={formProps.values.quarter}
                  >
                    <MenuItem eventKey="All" > All </MenuItem>
                    <MenuItem divider />
                    <MenuItem eventKey="Q1"> Q1 </MenuItem>
                    <MenuItem eventKey="Q2"> Q2 </MenuItem>
                    <MenuItem eventKey="Q3"> Q3 </MenuItem>
                    <MenuItem eventKey="Q4"> Q4 </MenuItem>

                  </DropdownButton>
                  <DropdownButton
                    style={{ marginLeft: 10 }}
                    id="year-dropdown"
                    bsStyle={'default'}
                    title={formattedFiscalYear(Number(formProps.values.year))}
                    onSelect={e => formProps.setFieldValue('year', getYearFromFiscal(e))}
                  >
                    {years}

                  </DropdownButton>

                  <div className="form-group">
                    <label>Product Category</label>
                    <ProductCategoryTypeahead selected={formProps.values.categories} onChange={c => formProps.setFieldValue('categories', c)} multiple={true} />
                  </div>
                  <div className="form-group">
                    <label>Distribution Channel</label>
                    <DistributionChannelTypeahead selected={formProps.values.channels} onChange={c => formProps.setFieldValue('channels', c)} multiple={true} />
                  </div>
                  <div className="form-group">
                    <label>Vendor</label>
                    <VendorTypeahead selected={formProps.values.vendors} onChange={v => formProps.setFieldValue('vendors', v)} multiple={true} />
                  </div>
                  <div className="form-group">
                    <label>Retailer</label>
                    <VendorTypeahead selected={formProps.values.retailers} onChange={r => formProps.setFieldValue('retailers', r)} multiple={true} />
                  </div>

                  <div className="text-center">
                    <button type="button" onClick={props.onSetDefaults} className="btn btn-link">{setDefaultTextMsg}</button>
                  </div>

                </div>

              </Modal.Body>
              <Modal.Footer>
                <button type="submit" onClick={() => setModalShown(false)} className="btn btn-default">Done</button>
              </Modal.Footer>

            </Form>

          )}

        </Formik>

      </GatewayModal>
    </div>

  );
};
