import * as React from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { formattedFiscalYear, getDueDateText, getSalesText } from '../../utils';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';
import { useRoyaltyReport } from './royalties-shared/useRoyaltyReport';
import { RoyaltyReportAggregatePage } from './RoyaltyReportAggregatePage';
import { RoyaltyReportIndexPage } from './RoyaltyReportIndexPage';
import { RoyaltyReportItemTypeMappingPage, RoyaltyReportSalesDataPage } from './RoyaltyReportSalesData';
import { RoyaltyReportSubmissionPage } from './RoyaltyReportSubmissionPage';

export const RoyaltyReportContainer = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const report = useRoyaltyReport();
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    report.enableHandler();

  },              []);

  React.useEffect(() => {
    const blocker = history.block((location, action) => {
      if (report.report) {
        const r = report.report;
        const nextRoute = location.pathname;
        if (r.phase.id === 1 && user.type === 'vendor' && !(nextRoute.includes('invoice') || nextRoute.includes(`${r.id}`))) {
          return 'Your report has not been submitted. Are you sure you want to leave?';
        }
      }
      return undefined;
    });

    return () => {
      blocker();
    };

  },              [JSON.stringify(report.report)]);

  if (!match) {
    return (<Redirect to="/" />);
  }

  if (user.scope && !user.scope.includes('royalties')) {
    return <Redirect to="/" />;
  }

  const routes = [
    <Route exact={true} path={match.path} component={RoyaltyReportIndexPage} />,
    <Route exact={true} path={`${match.path}/sales-data`} component={RoyaltyReportSalesDataPage} />,
    <Route exact={true} path={`${match.path}/item-types`} component={RoyaltyReportItemTypeMappingPage} />,
    <Route exact={true} path={`${match.path}/aggregate`} component={RoyaltyReportAggregatePage} />,
    <Route exact={true} path={`${match.path}/submit`} component={RoyaltyReportSubmissionPage} />,
  ];

  const r = report.report;
  if (!r) {
    return <LoadingSpinner />;
  }

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <h4 className="text-primary"><strong>Quarterly Report #{r.id}</strong></h4>
              <h5><strong>Q{r.quarter} {formattedFiscalYear(r.year)}</strong></h5>
              <h5><strong>{getSalesText(r.quarter, r.year)}</strong></h5>
            </div>
            <div>
              <div className={`label ${r.statusLabelClass}`}>{r.statusLabel}</div>
              {r.phase.id === 1 ? <h5 className="text-danger"><strong>Report Due {getDueDateText(r.quarter, r.year)}</strong></h5> : null}
            </div>
          </div>
        </div>
      </div>

      <Switch>
        {routes}

      </Switch>
    </FullContent>
  );
};
