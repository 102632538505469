import Axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AccountAddress } from '../../../shared';

interface IProps {
  selected: any[];
  onChange: (keys: AccountAddress[]) => void;
  disabled: boolean;
  addressAccountId: number;
  multiple: boolean;
}

export const AccountAddressTypeahead = (props: IProps) => {

  const [allAddresses, setAllAddresses] = useState<AccountAddress[] | []>([]);
  const [loading, setLoading] = useState(true);
  const ref = React.useRef<any>();

  React.useEffect(() => {
    getAccountAddresses();
  },              []);

  const getAccountAddresses = async () => {
    const params = {
      account_id: props.addressAccountId,
      limit: 0,
    };
    Axios.get('/api/account-addresses', { params })
      .then((response) => {
        const data = response.data.data;
        if (data.length > 0) {
          setAllAddresses(data.map((u: any) => AccountAddress.fromApi(u)));
        }

        setLoading(false);
      });
  };

  return (
    <>
      <Typeahead
        ref={ref}
        id={'address-typeahead'}
        placeholder={'Select Address'}
        options={allAddresses}
        isLoading={loading}
        selected={props.selected}
        multiple={props.multiple}
        onChange={(v) => {
          props.onChange(v);
          if (!v.length) {
            ref.current.focus();
            ref.current._showMenu();
          }
        }}
        paginate={true}
        maxResults={100}
        labelKey={(item: AccountAddress) => item.fullAddress}
        paginationText="Load More Addresses"
        clearButton={true}
        disabled={props.disabled}
      />
    </>

  );
};
