import * as React from 'react';

interface IProps {
  name: string;
  image?: string;
  subtext?: string;

}

export class AccountCell extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const subtext = this.props.subtext ?
      <span className="account-subtext small text-muted">{this.props.subtext}</span>
      : null;
    return (
      <div className="account-cell">
        {this.props.image ? <img src={this.props.image} /> : null}
          <div className="account-name-subtext">
            <span className="account-name">{this.props.name}</span>
            {subtext}

          </div>
      </div>

    );

  }

}
