import { Invoice, InvoiceApiResponse } from './Invoice';

export class LabelOrder {
  id: number;
  shippingCost: number;
  trackingNumbers: any;
  totalProductsCost: number;
  status: number;
  jpattonStatus: string;
  jpattonOrderId: string;
  adminFee: number;
  total: number;
  invoice: Invoice;

  static fromApi(data: LabelOrderApiResponse) {
    const order = new LabelOrder();
    order.id = data.id;
    order.shippingCost = data.shipping_cost;
    order.trackingNumbers = data.tracking_numbers;
    order.totalProductsCost = data.total_products_cost;
    order.status = data.status;
    order.jpattonStatus = data.jpatton_status;
    order.jpattonOrderId = data.jpatton_order_id;
    order.adminFee = data.admin_fee;
    order.total = data.total;
    order.invoice = Invoice.fromApi(data.invoice);
    return order;
  }

}

interface LabelOrderApiResponse {
  id: number;
  shipping_cost: number;
  tracking_numbers: string;
  total_products_cost: number;
  jpatton_status: string;
  status: number;
  jpatton_order_id: string;
  admin_fee: number;
  total: number;
  invoice: InvoiceApiResponse;

}
