import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  image: string;
  url: string;
  status: IReviewItemStatus;
  statusIconElement?: any;

}

export interface IReviewItemStatus {
  colorClass: string;
  label: string;
}

export class ReviewGridItem extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div className="panel panel-portal panel-portal-hover">
        <Link style={{ textDecoration: 'none' }} to={this.props.url}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              flexDirection: 'column',
            }}
            className="panel-body"
          >
            <div className={`review-item-status ${this.props.status.colorClass} text-ellipsis`} >
              {this.props.statusIconElement ?
              this.props.statusIconElement : <FontAwesomeIcon icon={faCircle} />} {this.props.status.label}
            </div>
            <img style={{ minHeight: 150, maxHeight: 150 }} className="img-responsive" src={this.props.image} />
          </div>
          {this.props.children}

        </Link>
      </div>
    );

  }

}
