import * as React from 'react';
import { UserContext } from '../../../contexts';
import {  RoyaltyReport, RoyaltyReportLineItem } from '../../../shared';
import { SalesDataOrderDetailsModalUpdate } from './SalesDataOrderDetailsModal';
import { SalesDataRow } from './SalesDataRow';

interface IProps {
  salesData: RoyaltyReportLineItem[];
  report?: RoyaltyReport;
  updateItem: (l: RoyaltyReportLineItem, index: number) => void;
  hideDesignPreview: boolean;
  showCityState: boolean;
}

export const SalesDataTable = (props: IProps) => {
  const [editing, setEditing] = React.useState<{lineItem: RoyaltyReportLineItem, index: number} | null>(null);
  const [shown, setShown] = React.useState(false);
  const user = React.useContext(UserContext);

  React.useEffect(() => {

  },              []);

  const canEdit = user.type === 'admin' && props.report !== undefined && (props.report.phase.id === 1 || props.report.phase.id === 3);

  const rows = props.salesData.map((s, index) =>
    <SalesDataRow
      key={s.id}
      position={index}
      onEdit={(p) => {
        setEditing({ index, lineItem: props.salesData[p] });
        setShown(true);
      }}
      lineItem={s}
      canEdit={canEdit}
      hideDesignPreview={props.hideDesignPreview}
      showCityState={props.showCityState}
    />);

  return (
    <div className="table-responsive">
      <table className="table royalties-table">
        <thead>
          <tr>
            {canEdit ? <th style={{ width: 50 }}></th> : null}
            {!props.hideDesignPreview ? <th style={{ width: 80 }}></th> : null}
            <th style={{ width: 200 }}>Item</th>
            <th style={{ width: 150 }}>Invoice</th>
            <th style={{ width: 150 }}>Customer</th>
            {props.showCityState ?  <th style={{ width: 100 }}>Location</th> : null}
            <th style={{ width: 150 }} className="text-right">Sales</th>
            <th style={{ width: 150 }} className="text-right">Royalty</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>

      </table>
      {editing && props.report ?
        <SalesDataOrderDetailsModalUpdate
          shown={shown}
          onClose={() => setShown(false)}
          salesData={editing.lineItem}
          update={l => props.updateItem(l, editing.index)}
          report={props.report}
        />
        : null}
    </div>
  );

};
