import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { ProductCategory, ProductCategoryParentMapping } from '../../../shared';
import { GatewayModal, ModalType } from './';

interface IProps {
  onClose: () => any;
  onCategorySelect: (category: ProductCategory) => any;
  shown: boolean;
  categories: ProductCategoryParentMapping[];
  usedCategories?: ProductCategory[];
  isUsedLabel?: string;
}

interface IState {
  expandedCategories: number[];
  search: string;
}

export class CategoryModal extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      search: '',
      expandedCategories: [],
    };
    this.categorySearch = this.categorySearch.bind(this);
    this.categoryMappingToRow = this.categoryMappingToRow.bind(this);
    this.isUsed = this.isUsed.bind(this);
  }

  componentDidMount() {

  }

  isUsed(id: number) {
    if (this.props.usedCategories) {
      const index = this.props.usedCategories.findIndex(c => id === c.id);
      return index !== -1;
    }

    return false;
  }

  categorySearch(event: any) {
    this.setState({ search: event.target.value });
  }

  selectCategory(category: ProductCategory) {
    if (category.isParent) {
      const expanded = this.state.expandedCategories;
      const index = expanded.indexOf(category.id);
      if (index !== -1) {
        expanded.splice(index, 1);

      } else {
        expanded.push(category.id);
      }
      this.setState({ expandedCategories: expanded });
    } else {
      if (this.props.usedCategories) {
        if (!this.isUsed(category.id)) {
          this.props.onCategorySelect(category);
        }

      } else {
        this.props.onCategorySelect(category);
      }
    }

  }

  categoryMappingToRow(mapping: ProductCategoryParentMapping) {

    return (
      <div key={mapping.category.id}>
        <button
          onClick={() => this.selectCategory(mapping.category)}
          className="list-group-item"
          type="button"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {mapping.category.isParent ? (<strong>{mapping.category.name}</strong>) : this.categoryTree(mapping.category)}
            <span className="text-muted">
              {this.isUsed(mapping.category.id) && !mapping.category.isParent ? this.props.isUsedLabel : null}
            </span>
          </div>
        </button>
        <div>
          {this.state.expandedCategories.includes(mapping.category.id) ? (mapping.children.map(this.categoryMappingToRow))
          : null}
        </div>
      </div>
    );

  }
  categoryTree(category: ProductCategory) {
    let c: ProductCategory | null = category;
    const tree = [];
    while (c != null) {
      tree.unshift(c);
      c = c.getParent();
    }
    const names = tree.map(cat => cat.name).map((n, index) => {
      return (
        <span>
          {index > 0 ? <FontAwesomeIcon style={{ marginRight: 5, marginLeft: 5 }} icon={faCaretRight} /> : null}
          {n}
        </span>
      );

    });

    return (<div> {names} </div>);
  }

  searchFiltered(mapping: ProductCategoryParentMapping) {
    let categories: ProductCategoryParentMapping[] = [];

    mapping.children.forEach((c) => {
      if (c.category.isParent) {
        const allChildren: ProductCategoryParentMapping[] = c.children.reduce(
          (prev, next) => {
            let m = prev;
            if (next.category.isParent) {
              m = prev.concat(this.searchFiltered(next));

            } else {
              m.push(next);
            }

            return m;
          },
          [] as ProductCategoryParentMapping[]);

        categories = categories.concat(allChildren);

      } else {
        categories = categories.concat(c);
      }
    });

    return categories;

  }

  render() {

    let filtered: ProductCategoryParentMapping[];

    if (this.state.search.length > 1) {
      filtered = this.props.categories.reduce(
        (prev, current) => {
          return prev.concat(this.searchFiltered(current));
        }
        ,
        [] as ProductCategoryParentMapping[]);
      filtered = filtered.filter((c) => {
        const lowerCaseSearch = this.state.search.toLowerCase();
        const nameContains = c.category.name.toLowerCase().includes(lowerCaseSearch);
        const parentConains = ProductCategory.parentCategoryTree(c.category).toLowerCase().includes(lowerCaseSearch);
        return nameContains || parentConains;
      });
      filtered = filtered.sort((a, b) => {
        if (a.category.name < b.category.name) {
          return -1;
        }
        if (a.category.name > b.category.name) {
          return 1;
        }
        return 0;
      });
    } else {
      filtered = this.props.categories;
    }

    const rows = filtered.map(this.categoryMappingToRow);

    return (
    <GatewayModal
        type={ModalType.Primary}
        onClose={this.props.onClose}
        title="Select an Affinity Category"
        shown={this.props.shown}
      >
        <Modal.Body>

          <input
            onChange={this.categorySearch}
            type="text"
            className="form-control"
            placeholder="Search Categories"
            value={this.state.search}
          />
          <br />

        <div style={{ overflowY: 'scroll', maxHeight: 500, height: 500, padding: 10 }}>
          <div className="list-group">
            {rows}

          </div>

        </div>

      </Modal.Body>
    </GatewayModal>
    );
  }

}
