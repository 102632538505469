import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { UserContext } from '../../../contexts';
import User from '../../../shared/User';
import { GatewayModal, ModalType } from './GatewayModal';

interface IProps {
  user: User;
  blockedUserTypes: ('admin' | 'vendor' | 'client' | string)[];
  children: React.ReactNode;
}

export const UserContactModal = (props: IProps) => {

  const [modalShown, setModalShown] = React.useState(false);

  const userContext = React.useContext(UserContext);

  const canShow = props.blockedUserTypes.includes(userContext.type || '') ?  false : true;

  const user = props.user;

  const showModal = () => {
    if (canShow) {
      setModalShown(true);
    }
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <div style={{ cursor: canShow ? 'pointer' : 'initial' }} onClick={showModal}>
        {props.children}
      </div>
      <GatewayModal
        shown={modalShown}
        onClose={() => setModalShown(false)}
        type={ModalType.Primary}
        size="small"
        title="Contact Info"
      >
        <Modal.Body>
          <div className="text-center">
            <h4 style={{ marginBottom: 30 }}><strong>{props.user.fullName}</strong></h4>

            {user.email ?
              <div style={{ marginBottom: 20 }}>
                <h5 className="no-margin"><strong>Email</strong></h5>
                <a target="_blank" href={`mailTo:${user.email}`}>{user.email}</a>
              </div>
            : null}
            {user.phone ?
              <div style={{ marginBottom: 20 }}>
                <h5 className="no-margin"><strong>Phone</strong></h5>
                <p>{user.phone}</p>
              </div>
              : null}

          </div>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setModalShown(false)} className="btn btn-default">Done</button>
        </Modal.Footer>

      </GatewayModal>
    </div>
  );
};
