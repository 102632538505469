import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';
import * as Pikday from 'pikaday';
import * as React from 'react';

interface IProps {
  onChange: (date: string) => any;
  placeholder: string;
  value?: string;
}

export class PikadayReact extends React.Component<IProps, any> {
  _ref: any;
  picker: Pikday;

  constructor(props: IProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.picker = new Pikday({
      field: this._ref,
      onSelect: this.onChange,

    });

  }

  onChange(date: Date) {
    const m = moment(date);
    this.props.onChange(m.format('YYYY-MM-DD'));
  }

  render() {
    return (
      <div className="input-group">

        <span className="input-group-addon"><FontAwesomeIcon icon={faCalendar} /></span>

        <input
          placeholder={this.props.placeholder}
          ref={ref => this._ref = ref}
          value={this.props.value}
          type="text"
          className="form-control input-sm"
        />
      </div>

    );

  }

}
