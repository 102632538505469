import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { BrandMarkAsset } from '../../shared';
import { GatewayModal, ModalType } from '../shared/modals';

interface IProps {
  shown: boolean;
  onClose: () => any;
  onDelete: (asset: BrandMarkAsset) => any;
  asset: BrandMarkAsset | null;
}

export class BrandAssetDeleteModal extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.close = this.close.bind(this);
    this.delete = this.delete.bind(this);
  }

  close() {
    this.props.onClose();
  }

  delete() {
    if (this.props.asset) {
      this.props.onDelete(this.props.asset);
      this.props.onClose();
    }
  }

  render() {

    if (this.props.asset === null) {
      return null;
    }

    return (
      <GatewayModal shown={this.props.shown} title="Confirm Delete" type={ModalType.Danger} onClose={this.close}>
        <Modal.Body>
          Are you sure you want to delete this asset? This cannot be undone.

        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.close} className="btn btn-default pull-left">Close</button>
          <button className="btn btn-danger pull-right" onClick={this.delete}>Delete</button>

        </Modal.Footer>

      </GatewayModal>

    );

  }

}
