import * as React from 'react';
import { SortableHeader, SortDirection } from '../../shared';
import IndexTableRow from './IndexTableRow';

interface IIndexTableProps {
  rows: any[];
  selectedSort: any;
  sortDir: SortDirection;
  showVendorCol: boolean;
  showAssignedAdminCol: boolean;
  showQuarterRangeCol: boolean;
  sortSelectFn: (sortKey: string, sortDirection: any) => void;
  history: any;
}

const defaultProps = {
  rows: [],
  selectedSort: null,
  sortDir: null,
  showVendorCol: true,
  showAssignedAdminCol: true,
  showQuarterRangeCol: true,
};

export class IndexTable extends React.Component<IIndexTableProps, any> {
  public static defaultProps = defaultProps;
  public constructor(props: IIndexTableProps) {
    super(props);
    this.sortSelect = this.sortSelect.bind(this);
  }

  public sortSelect(sortKey: string, sortDirection: SortDirection) {
    this.props.sortSelectFn(sortKey, sortDirection);
  }

  public render() {

    const rows = this.props.rows.map((row: any) => {
      return <IndexTableRow
        key={row.id}
        row={row}
        history={this.props.history}
        showVendorCol={this.props.showVendorCol}
        showAssignedAdminCol={this.props.showAssignedAdminCol}
        showQuarterRangeCol={this.props.showQuarterRangeCol}
      />;
    });

    return (
      <div className="table-responsive">
      <table className="table table-hover table-portal table-with-links" style={{ margin: '0 auto' }}>
        <thead>
        <tr>
          {this.props.showVendorCol ? (
            <SortableHeader
              title="Vendor"
              sortSelectFn={this.sortSelect}
              sortDir={this.props.selectedSort === 'vendor' ? this.props.sortDir : null}
              sortKey="vendor"
            />
          ) : (
            null
          )}
          <SortableHeader
            title="Quarter"
            sortSelectFn={this.sortSelect}
            sortDir={this.props.selectedSort === 'quarter' ? this.props.sortDir : null}
            sortKey="quarter"
          />
          {this.props.showQuarterRangeCol ? (
            <th scope="col">Sales From</th>
          ) : (
            null
          )}
          <SortableHeader
            title="Updated"
            sortSelectFn={this.sortSelect}
            sortDir={this.props.selectedSort === 'updated_at' ? this.props.sortDir : null}
            sortKey="updated_at"
          />
          <SortableHeader
            title="Submitted"
            sortSelectFn={this.sortSelect}
            sortDir={this.props.selectedSort === 'submitted_at' ? this.props.sortDir : null}
            sortKey="submitted_at"
          />
          <th>Due</th>
          {this.props.showAssignedAdminCol ? (
            <th>Admin</th>
          ) : (
            null
          )}
          <th colSpan={2} scope="col">Status</th>
        </tr>
        </thead>
        <tbody>
        {rows}
        </tbody>
      </table>
      </div>
    );
  }
}
