import * as React from 'react';
import { AffinityImage, ImageSize } from '../../shared';

interface IProps {
  image: AffinityImage | null;
  size: ImageSize;
  height: number;
  width: number;
  borderRadius?: number;
}

export const GatewayImage = (props: IProps) => {
  if (props.image) {
    return (
      <img
        style={{
          height: props.height,
          width: props.width,
          borderRadius:  props.borderRadius ? props.borderRadius : undefined,
        }}
        className="img-responsive"
        src={props.image.getSize(props.size)}
      />
    );
  }

  return (
    <div style={{ height: props.height, width: props.width, backgroundColor: 'white' }} />
  );

};
