import { faArrowDown, faArrowUp, faCaretRight, faCheck, faLock, faTimes, faUserShield } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { debounce, orderBy, uniqBy } from 'lodash';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { Insignia } from '../../shared';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';

export const InsigniaIndexPage = () => {
  const [insignia, setInsignia] = React.useState<Insignia[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const firstLoad = React.useRef(true);
  const debounceMove = React.useRef(debounce((i: Insignia[]) => submitOrder(i), 1000));
  const { licensorId } = useParams<any>();
  const user = React.useContext(UserContext);
  React.useEffect(() => {
    getInsignia();

  },              []);

  React.useEffect(() => {
    if (insignia.length) {
      if (!firstLoad.current) {
        debounceMove.current(insignia);

      } else {
        firstLoad.current = false;
      }
    }

  },              [insignia]);

  const getInsignia = async () => {

    setLoading(true);
    const i = await Axios.get(`/api/brandmarks/tree?client_id=${licensorId}`);
    setInsignia(orderBy(i.data.data.map((ins: any) => Insignia.fromApi(ins)), 'order', 'asc'));
    setLoading(false);

  };

  const submitOrder = async (sortedInsignia: Insignia[]) => {
    setSaving(true);
    const order: Insignia[] = [];

    const loopChildren = (ins: Insignia[]) => {
      ins.forEach((i) => {
        order.push(i);
        loopChildren(i.children);
      });
    };

    loopChildren(sortedInsignia);
    const count = order.map((ins, index) => {
      return { insignia: ins.id, order: index + 1 };
    });

    await Axios.post(`/api/brandmarks/tree?client_id=${licensorId}`, { order: count });
    setSaving(false);

  };

  const moveWithinChildren = (children: Insignia[], ins: Insignia, direction: 'up' | 'down') => {

    children.forEach((i) => {
      if (ins.parent && i.id === ins.parent.id) {
        const current = i.children.findIndex(ci => ci.id === ins.id);
        if (current !== -1) {
          const to = direction === 'up' ? current - 1 : current + 1;
          if (to !== -1 && to !== i.children.length) {
            const toMove = i.children.splice(current, 1)[0];
            i.children.splice(to, 0, toMove);
          }

        }
      } else {
        moveWithinChildren(i.children, ins, direction);
      }
    });

  };

  const moveInsignia = async (ins: Insignia, direction: 'up' | 'down') => {
    if (saving) {
      return;
    }

    if (!ins.parent) {
      const current = insignia.findIndex(i => i.id === ins.id);
      if (current !== -1) {
        const to = direction === 'up' ? current - 1 : current + 1;
        if (to !== -1 && to !== insignia.length) {
          const ins = [...insignia];
          const toMove = ins.splice(current, 1)[0];

          ins.splice(to, 0, toMove);

          setInsignia(ins);
        }

      }
    } else {
      const updateIns = [...insignia];
      moveWithinChildren(updateIns, ins, direction);

      setInsignia(updateIns);
    }

  };

  const allInsignia: Insignia[] = uniqBy(insignia.reduce((p: Insignia[], c) => {
    return p.concat(Insignia.createDescendantTree(c, c.children));
  },                                                     []),
                                         (i => i.id));

  const createListItem = (ins: Insignia, index: number) => {
    const ancestors = Insignia.createAncestorTree(ins, allInsignia, true);
    return (
      <div>
        <div className="insignia-list-item">
          {user.type === 'admin' ? <div className="move-arrows">
            <FontAwesomeIcon onClick={() => moveInsignia(ins, 'up')} className="text-muted" icon={faArrowUp} />
            <FontAwesomeIcon onClick={() => moveInsignia(ins, 'down')} className="text-muted" icon={faArrowDown} />

          </div> : null}
          {user.type === 'admin' ?
            <Link className="insignia-list-content" style={{ color: 'black', textDecoration: 'none' }} to={`brand/${ins.id}/edit`}>

              {ins.image.isUsingDefault ? <div className="ins-empty-ph" /> : <img src={ins.image.getSize('sm')} />}

              <div className="title-subtitle">
                <strong style={{ marginBottom: 10 }}>{ins.title} {ins.code !== ins.title ? `(${ins.code})` : ''}</strong>
                <p className="text-muted">
                  {ancestors.length > 1  ?
                    ancestors.reverse().map((a, i) =>
                      <span key={a.id}>
                        {a.title} {i < ancestors.length - 1 ?
                          <FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} icon={faCaretRight} /> : null}
                      </span>,
                    )

                  : 'Top-level'}
                </p>
              </div>
              <div className="users-vendors">
                {ins.designReviewUsers.length ? <p className="text-muted">
                  <FontAwesomeIcon className="text-primary" icon={faUserShield} />
                  {ins.designReviewUsers.length} linked user{ins.designReviewUsers.length > 1 ? 's' : '' }
                  </p> : null}
                {ins.isVendorSpecific ? <p className="text-muted">
                  <FontAwesomeIcon className="text-primary" icon={faLock} />
                  {ins.vendors.length} exclusive vendor{ins.vendors.length > 1 ? 's' : ''}
                </p> : null}

              </div>
              <div className="ins-flags">
                <p className="text-muted">{ins.enabledForDesigns ?
                  <FontAwesomeIcon className="text-success" icon={faCheck} /> :
                  <FontAwesomeIcon className="text-danger" icon={faTimes} />} Design tags</p>
                <p className="text-muted">{ins.royalties ?
                  <FontAwesomeIcon className="text-success" icon={faCheck} /> :
                  <FontAwesomeIcon className="text-danger" icon={faTimes} />} Sales Data</p>
              </div>
            </Link> :
            <div className="insignia-list-content" style={{ color: 'black', textDecoration: 'none' }}>
              {ins.image.isUsingDefault ? <div className="ins-empty-ph" /> : <img src={ins.image.getSize('sm')} />}
              <div className="title-subtitle">
                <strong style={{ marginBottom: 10 }}>{ins.title}</strong>
                <p className="text-muted">
                  {ancestors.length > 1  ?
                    ancestors.reverse().map((a, i) =>
                      <span key={a.id}>
                        {a.title} {i < ancestors.length - 1 ?
                          <FontAwesomeIcon style={{ marginLeft: 5, marginRight: 5 }} icon={faCaretRight} /> : null}
                      </span>,
                    )

                  : 'Top-level'}
                </p>
              </div>
            </div>}
        </div>
        {ins.children.map(createListItem)}
      </div>

    );

  };

  const ins = insignia.map((i, index) => (
    <div key={index} className="panel panel-portal">
      {createListItem(i, index)}
    </div>
  ));

  if (loading) {
    return <FullContent >
      <LoadingSpinner />
    </FullContent>;
  }

  return (
    <FullContent>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Brand Insignia</strong></h3>
          <p className="text-muted">
            Manage brand identity and architecture by adding brands,
            sub-brands, names, logos, slogans, symbols, mascots, and other insignia.
          </p>
        </div>
        {user.type === 'admin' ? <Link to={'brand/add'} className="btn btn-primary">Add Insignia</Link> : null}

      </div>
        {ins}

    </FullContent>
  );

};
