import { faBook, faComment, faEnvelope, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FullContent } from '../ContentFrame';

export class Help extends React.Component {

  showChat() {
    if (window['Intercom']) {
      window['Intercom']('show');
    }
  }

  render() {

    return (
      <FullContent >
        <div>
          <div className="page-title">
            <h4>Help</h4>
            <div className="page-title-actions">
              <a href="https://help.affinitylicensing.com" target="_blank">
                Go to the Help Center
                <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faExternalLink} />

              </a>
            </div>
          </div>
          <div className="panel panel-portal">
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 40 }}
              className="panel-body"
            >
              <img style={{ maxHeight: 100 }} className="img-responsive" src="/assets/images/favicon.png" />
              <h5><strong>Have a question for us?</strong></h5>
              <h5 className="text-primary">
                <strong>
                  Most common questions are answered in our Help Center.
                </strong>
              </h5>

              <a
                href="https://help.affinitylicensing.com"
                target="_blank"
                style={{ marginTop: 20 }}
                className="btn btn-lg btn-primary"
              >
                  Go to the Help Center
                </a>

            </div>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: -15, marginRight: -15 }}>
            <div className="col-md-4 flex-col">
              <div className="panel panel-portal">
                <div className="panel-body help-panel">
                  <div>
                    <span className="text-primary">
                      <FontAwesomeIcon  size={'2x'} icon={faEnvelope} />
                    </span>
                    <h5>Email us</h5>
                  </div>
                  <p className="text-muted">
                    We'll get back to you as soon as possible. You can also start a chat for a faster response.
                  </p>
                  <a href="mailto:help@affinitylicensing.com" target="_blank" className="btn btn-default">Send email</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-col">
              <div className="panel panel-portal">
                <div className="panel-body help-panel">
                  <div>
                    <span className="text-primary">
                      <FontAwesomeIcon size={'2x'} icon={faBook} />
                    </span>
                    <h5>Resources</h5>
                  </div>
                  <p className="text-muted">
                    View our license compliance guides, templates, reference materials and more.
                  </p>
                  <a href="https://help.affinitylicensing.com" target="_blank" className="btn btn-default">
                    View resources
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4 flex-col">
              <div className="panel panel-portal">
                <div className="panel-body help-panel">
                  <div>
                    <span className="text-primary">
                      <FontAwesomeIcon  size={'2x'} icon={faComment} />
                    </span>
                    <h5>Chat with us</h5>
                  </div>
                  <p className="text-muted">
                    We'll typically respond within a few minutes during our regular business hours.
                  </p>
                  <button onClick={this.showChat} className="btn btn-default">Start a chat</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FullContent>
    );
  }

}
