import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { faPencil, faSort, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikProps, FormikValues } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import * as Yup from 'yup';
import { MarketplaceNavLink, MarketplaceSite } from '../../../shared/MarketplaceSite';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { getSitePages } from './api';

type IProps = RouteComponentProps<{
  siteId: string;
}>;

interface IState {
  loading: boolean;
  site: MarketplaceSite | null;
  editModalShown: boolean;
  createModalShown: boolean;
  linkType: string;
  saving: boolean;
  selectedPageId: string | null;
  navlinkToEdit: MarketplaceNavLink | null;
}

interface SortablePageItemProps  {
  item: MarketplaceNavLink;
  onDelete: (id: number) => void;
  onEdit: (item: MarketplaceNavLink) => void;
}

interface DragHandleProps {
  item: MarketplaceNavLink;
}

const DragHandle = SortableHandle((props: DragHandleProps) => (
  <span>
    <FontAwesomeIcon style={{ marginRight: 10 }} icon={faSort} />
    {props.item.text}
    <span className="pull-right text-muted">
      { props.item.internalLink ? (props.item.path ? props.item.path : null) : props.item.externalUrl}
    </span>
  </span>
));

const SortablePageItem = SortableElement((props: SortablePageItemProps) => (
  <div style={{ padding: 20 }} className="row page-item-row" key={props.item.id}>
    <div className="col-xs-9">
      <DragHandle item={props.item}  />

    </div>
    <div className="col-xs-3 text-right">
      <FontAwesomeIcon
        style={{ cursor: 'pointer' }}
        onClick={() => props.onEdit(props.item)}
        icon={faPencil}
      />
      <FontAwesomeIcon
        style={{ marginLeft: 15, cursor: 'pointer' }}
        onClick={() => props.onDelete(props.item.id)}
        icon={faTrash}
      />
    </div>

  </div>
));

interface SortablePageItemListProps {
  items: MarketplaceNavLink[];
  onDelete: (id: number) => void;
  onEdit: (item :MarketplaceNavLink) => void;
}

const SortablePageItemList = SortableContainer((props: SortablePageItemListProps) => (
  <div className="page-item-row-container">
    {props.items.map((v, index) => (
      <SortablePageItem key={v.id} item={v} index={index} onDelete={props.onDelete} onEdit={props.onEdit} />
    ))}
  </div>
));

export class SitePageIndexPage extends React.Component <IProps, IState> {

  private _getPagesSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      site: null,
      editModalShown: false,
      createModalShown: false,
      linkType: '',
      saving: false,
      selectedPageId: null,
      navlinkToEdit: null,
    };

    this.getPages = this.getPages.bind(this);
    this.editNavLink = this.editNavLink.bind(this);
    this.createNavLink = this.createNavLink.bind(this);
    this.deleteNavLink = this.deleteNavLink.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.hideEditModal = this.hideEditModal.bind(this);
    this.showCreateModal = this.showCreateModal.bind(this);
    this.hideCreateModal = this.hideCreateModal.bind(this);
    this.hideCreateModal = this.hideCreateModal.bind(this);
  }

  componentDidMount() {
    this.getPages(this.props.match.params.siteId);
  }

  getPages(siteId: string) {
    this.setState({ site: null, loading: true });
    if (this._getPagesSource) {
      this._getPagesSource.cancel('Cancelled getPages() XHR due to new request.');
    }
    this._getPagesSource = Axios.CancelToken.source();

    getSitePages(siteId, this._getPagesSource)
      .then((site) => {
        this.setState({ site, loading: false });
      });
  }

  showEditModal(item: MarketplaceNavLink) {
    if (item.internalLink) {
      item.externalUrl = '';
    }
    this.setState({ navlinkToEdit: item, linkType: item.internalLink ? 'internal' : 'external', editModalShown: true });
  }

  hideEditModal() {
    this.setState({ editModalShown: false, linkType: '' });
  }

  showCreateModal() {
    this.setState({ createModalShown: true });

  }

  hideCreateModal() {
    this.setState({ createModalShown: false, linkType: '' });
  }

  createNavLink(values: any, formProps: FormikActions<FormikValues>) {
    if (values) {
      this.setState({ saving: true });
      const formData = {
        isInternal: this.state.linkType === 'internal',
        externalUrl: values.externalUrl,
        linkText: values.text,
        newTab: values.newTab,
        pageId: values.pageId,
        siteId: this.props.match.params.siteId,
      };
      Axios.post('/api/marketplace/sites/navlinks', formData)
      .then((response) => {
        this.hideCreateModal();
        this.setState({ saving: false });
        this.getPages(this.props.match.params.siteId);
      })
      .catch((error) => {
        for (const index in error.response.data) {
          formProps.setFieldError(index, error.response.data[index][0]);
        }
        this.setState({ saving: false });
      });
    }
  }

  editNavLink(item: MarketplaceNavLink, formProps: FormikActions<any>) {
    if (item && this.state.navlinkToEdit) {
      this.setState({ saving: true });
      const formData = {
        isInternal: this.state.linkType === 'internal',
        externalUrl: item.externalUrl,
        linkText: item.text,
        newTab: item.newTab,
        pageId: item.pageId,
        siteId: this.props.match.params.siteId,
      };
      Axios.post(`/api/marketplace/sites/navlinks/${this.state.navlinkToEdit.id}`, formData)
        .then((response) => {
          this.hideEditModal();
          this.setState({ saving: false });
          this.getPages(this.props.match.params.siteId);
        })
        .catch((error) => {
          for (const index in error.response.data) {
            formProps.setFieldError(index, error.response.data[index][0]);
          }
          this.setState({ saving: false });
        });
    }
  }

  async deleteNavLink(id: number) {
    const r = confirm('Are you sure you want to delete this navigation link?');
    if (r && this.state.site) {
      const site = this.state.site;
      const d = await Axios.delete(`/api/marketplace/sites/navlinks/${id}`);
      if (d) {
        const index = site.navLinks.findIndex(i => i.id === id);
        if (index > -1) {
          site.navLinks.splice(index, 1);
        }
        this.setState({ site });
      }
    }
  }

  async onDrag(oldIndex: number, newIndex: number) {
    const site = this.state.site;
    if (site) {
      site.navLinks = arrayMove(site.navLinks, oldIndex, newIndex);
      this.setState({ site });
      const data = {
        navLinkSorting: site.navLinks.map((item, index) => {
          return {
            id: item.id,
            position: index,
          };
        }),
      };

      const update = await Axios.post(`/api/marketplace/sites/navlinks/${data.navLinkSorting[0].id}`, data);
    }

  }

  render() {

    if (this.state.loading || this.state.site === null) {
      return <LoadingSpinner />;
    }

    const site = this.state.site;

    const pageSections = site.pages.map(page => (
      <div style={{ padding: 20, whiteSpace: 'nowrap' }} className="row">
        <div className="col-md-6">
          {page.title}
        </div>
        <div className="col-md-4">
          <a target="_blank" href={`https://${site.domain}/${page.path}`}>
            /{page.path}
          </a>
        </div>
        <div className="col-md-2">
          <Link to={`/marketplace/sites/${site.id}/pages/${page.id}`}>
            <FontAwesomeIcon icon={faCog} />
          </Link>
        </div>
      </div>
    ));

    const pageOptions = site.pages.map(page => (
      <option key={page.id} value={page.id}>{`${page.title} (/${page.path})`}</option>
    ));

    const initialValues = {
      externalUrl: '',
      text: '',
      newTab: true,
      pageId: '',
    };

    const editInitialValues = {
      externalUrl: this.state.navlinkToEdit ? this.state.navlinkToEdit.externalUrl : '',
      text: this.state.navlinkToEdit ? this.state.navlinkToEdit.text : '',
      newTab: this.state.navlinkToEdit ? this.state.navlinkToEdit.newTab : false,
      pageId: this.state.navlinkToEdit ? this.state.navlinkToEdit.pageId : '',
    };

    let validation = Yup.object().shape({
    });
    if (this.state.linkType === 'internal') {
      validation = validation.concat(Yup.object().shape({
        text: Yup.string().required('Link Text is required'),
      }));
    } else {
      validation = validation.concat(Yup.object().shape({
        text: Yup.string().required('Link Text is required'),
        newTab: Yup.boolean(),
        externalUrl: Yup.string().url('Please enter a valid url. It must start with https:// or http:// to be valid.').required('External Url is required'),
      }));
    }

    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body" >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3 ><strong>{site.domain}</strong></h3>
            </div>
          </div>
        </div>

        <div className="panel panel-portal">
          <div className="panel-body" >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4><strong>Navigation</strong></h4>
            </div>
            <SortablePageItemList
              items={site.navLinks}
              onEdit={this.showEditModal}
              onDelete={this.deleteNavLink}
              useDragHandle
              onSortEnd={({ oldIndex, newIndex }) => this.onDrag(oldIndex, newIndex)}
            />
            <div className="text-center">
              <button className="btn btn-primary" onClick={this.showCreateModal}>Add Navigation Link</button>
            </div>
          </div>

        </div>
        <div className="panel panel-portal">
          <div className="panel-body" >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h4><strong>Pages</strong></h4>
              <Link to={`/marketplace/sites/${site.id}/pages/new`} className="btn btn-primary">New Page</Link>
            </div>
              <div style={{ padding: 20 }} className="row">
              <div className="col-md-6">
                <strong>Name</strong>
              </div>
              <div className="col-md-4">
                <strong>Link</strong>
              </div>
              <div className="col-md-2">
                <strong>Settings</strong>
              </div>
            </div>
            <ul>
              {pageSections}
            </ul>
          </div>
        </div>

        <GatewayModal
          onClose={this.hideEditModal}
          title="Edit Navigation Link"
          shown={this.state.editModalShown}
          type={ModalType.Primary}
        >
          <Formik initialValues={editInitialValues}
                  onSubmit={(values, formikActions) => this.editNavLink(values, formikActions)}
                  validationSchema={validation}>
            {(props: FormikProps<any>) => (
              <Form>
                <Modal.Body>
                  <div className="form-group">
                    <label>Select Internal or External Link</label>
                    <select defaultValue={this.state.linkType} onChange={e => this.setState({ linkType: e.target.value })}  className="form-control">
                      <option selected disabled>Select Type</option>
                      <option value="internal">Internal Link</option>
                      <option value="external">External Link</option>
                    </select>
                  </div>

                  { (this.state.linkType === 'external' || this.state.linkType === 'internal') ?
                    <div className="form-group">
                      <label>Link Text</label>
                      <Field type="text" className="form-control" name="text"/>
                      <p className="text-danger">
                        <ErrorMessage name="text" />
                      </p>
                    </div>
                    : null}

                  { this.state.linkType === 'internal' ? (
                    <div className="form-group">
                      <label>Select Page</label>
                      <Field component="select" name="pageId" className="form-control">
                        <option selected disabled>Select Page</option>
                        {pageOptions}
                      </Field>
                      <p className="text-danger">
                        <ErrorMessage name="pageId" />
                      </p>
                    </div>
                  ) : null}

                  { this.state.linkType === 'external' ? (
                    <>
                      <div className="form-group">
                        <label>External URL</label>
                        <Field type="text" placeholder="https://example.com" className="form-control" name="externalUrl"/>
                        <p className="text-danger">
                          <ErrorMessage name="externalUrl" />
                        </p>
                      </div>
                      <div className="form-group">
                        <Field type="checkbox"  name="newTab" checked={props.values.newTab}/> Open in new tab
                      </div>
                    </>
                  ) : null}
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={() => this.hideEditModal()} type="button" className="btn btn-default pull-left">Cancel</button>
                  <button className="btn btn-primary pull-right">{this.state.saving ? 'Saving...' : 'Save Navigation Link'}</button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>

        </GatewayModal>

        <GatewayModal
          onClose={this.hideCreateModal}
          title="Create Navigation Link"
          shown={this.state.createModalShown}
          type={ModalType.Primary}
        >
            <Formik initialValues={initialValues}
                    onSubmit={(values, formikActions) => this.createNavLink(values, formikActions)}
                    validationSchema={validation}
            >
              {(props: FormikProps<any>) => (
                <Form>
                  <Modal.Body>
                  <div className="form-group">
                    <label>Select Internal or External Link</label>
                    <select onChange={e => this.setState({ linkType: e.target.value })}  className="form-control">
                      <option selected disabled>Select Type</option>
                      <option value="internal">Internal Link</option>
                      <option value="external">External Link</option>
                    </select>
                  </div>
                  { (this.state.linkType === 'external' || this.state.linkType === 'internal') ?
                    <div className="form-group">
                      <label>Link Text</label>
                      <Field type="text" className="form-control" name="text"/>
                      <p className="text-danger">
                        <ErrorMessage name="text" />
                      </p>
                    </div>
                    : null}

                  { this.state.linkType === 'internal' ? (
                    <div className="form-group">
                      <label>Select Page</label>
                      <Field component="select" name="pageId" className="form-control">
                        <option selected disabled>Select Page</option>
                        {pageOptions}
                      </Field>
                    </div>
                  ) : null}

                  { this.state.linkType === 'external' ? (
                    <>
                    <div className="form-group">
                      <label>External URL</label>
                      <Field placeholder="https://example.com" type="text" className="form-control" name="externalUrl"/>
                      <p className="text-danger">
                        <ErrorMessage name="externalUrl" />
                      </p>
                    </div>
                    <div className="form-group">
                      <Field type="checkbox"  name="newTab" checked={props.values.newTab}/> Open in new tab
                    </div>
                    </>
                  ) : null}
                </Modal.Body>
                <Modal.Footer>
                  <button onClick={() => this.hideCreateModal()} type="button" className="btn btn-default pull-left">Cancel</button>
                  <button className="btn btn-primary pull-right">{this.state.saving ? 'Saving...' : 'Save Navigation Link'}</button>
                </Modal.Footer>
                </Form>
              )}
            </Formik>

        </GatewayModal>
      </FullContent>
    );
  }
}
