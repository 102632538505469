import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { UserContext } from '../../contexts';
import { BrandsListPage, ClientBrandPage } from './';
import { VendorBrandIndex } from './VendorBrandIndex';

export class BrandIndexPage extends React.Component<RouteComponentProps<any>, any> {

  render() {

    return (
      <UserContext.Consumer>
        { (user) => {

          if (user.type === 'admin') {
            return (<BrandsListPage {...this.props} />);
          }

          if (user.type === 'client') {
            return (<ClientBrandPage user={user} { ...this.props} />);
          }

          return <VendorBrandIndex { ... this.props} />;

        }}

      </UserContext.Consumer>

    );
  }

}
