import * as React from 'react';
import { LoadingSpinner, PaginationFooter } from '../../shared';
import { AccountStatsRow } from './AccountStatsRow';
import { ClientStats } from './models';
import { NoSalesDataPlaceholder } from './NoSalesDataPlaceholder';

interface IProps {
  clients: ClientStats[];
  isCurrent: boolean;
  setPage: (page: number) => void;
  filterClient: (client: any) => void;
  loading: boolean;
  totalClients: number;
  page: number;
  hasNext: boolean;

}

export class ClientList extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const rows = this.props.clients.map(clientStats =>
      <AccountStatsRow
        onClick={() => this.props.filterClient(clientStats.client)}
        client={clientStats}
        isCurrent={this.props.isCurrent}
        key={clientStats.client.id}
      />,
    );

    const table = rows.length ? (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Licensor</th>
              <th>Royalties</th>
              <th>Previous Year</th>
              <th>$ Comparison</th>
              <th>% Comparison</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>

        </table>
      </div>
    ) : (
      <NoSalesDataPlaceholder />
    );

    const content = this.props.loading ? (<LoadingSpinner />) : table;

    const footer = (this.props.page !== 1 || this.props.hasNext) ? (
      <PaginationFooter
        totalResults={this.props.totalClients}
        currentPage={this.props.page}
        hasNext={this.props.hasNext}
        setPage={this.props.setPage}
      />
    ) : (
      null
    );

    return (
      <div>
      <div className="panel panel-portal">

        <div className="panel-body">
          <div className="analytics-header text-primary">Top Performing Licensors</div>
          <div >
            <i className="fa fa-mouse-pointer"></i>
            <span className="text-muted"> Click a client to narrow analysis</span>
          </div>
          {content}

        </div>
      </div>
        {footer}
    </div>
    );

  }

}
