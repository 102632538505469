import * as moment from 'moment';
import { AffinityClient } from './AffinityClient';
import { AffinityImage, ImageApiResponse } from './Image';
import { IAdApiResponse, MarketplaceAdvertisement } from './MarketplaceAdvertisement';
import { ISiteApiResponse, MarketplaceSite } from './MarketplaceSite';

export interface IPostApiResponse {
  id: number;
  title: string;
  description?: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  published_at: string;
  short_description?: string;
  image: ImageApiResponse;
  site: ISiteApiResponse;
  licensor: any;
  ads: IAdApiResponse[];
  tags: MarketplacePostTag[];
}

export interface MarketplacePostTag {
  id: number;
  name: string;
}

export class MarketplacePost {
  id: number;
  title: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  deletedAt: moment.Moment | undefined;
  publishedAt: moment.Moment | undefined;
  description: string;
  shortDescription: string;
  image: AffinityImage | undefined;
  site: MarketplaceSite | undefined;
  licensor: AffinityClient | undefined;
  ads: MarketplaceAdvertisement[] = [];
  tags: MarketplacePostTag[] = [];

  static fromApi(data: IPostApiResponse) {
    const post = new MarketplacePost();
    post.id = data.id;
    post.title = data.title;
    post.createdAt = moment(data.created_at);
    post.updatedAt = moment(data.updated_at);
    if (data.published_at) {
      post.publishedAt = moment(data.published_at);
    }
    if (data.deleted_at) {
      post.deletedAt = moment(data.deleted_at);
    }
    if (data.image) {
      post.image = AffinityImage.fromApi(data.image);
    }
    if (data.site) {
      post.site = MarketplaceSite.fromApi(data.site);
    }
    if (data.description) {
      post.description = data.description;
    }
    if (data.short_description) {
      post.shortDescription = data.short_description;
    }
    if (data.licensor) {
      post.licensor = new AffinityClient(data.licensor);
    }
    if (data.ads) {
      post.ads = data.ads.map(a => MarketplaceAdvertisement.fromApi(a));
    }
    if (data.tags) {
      post.tags = data.tags;
    }

    return post;
  }
}
