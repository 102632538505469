export class LabelSupply {
  id: number;
  jPattonId: number;
  name: string;
  description: string;
  price: number;
  quantity: number;
  file: any;
  orderQuantity = 0;

  static fromApi(data: LabelSupplyApiResponse) {
    const supply = new LabelSupply();
    supply.id = Number(data.id);
    supply.description = data.description;
    supply.name = data.name;
    supply.jPattonId = Number(data.jpatton_id);
    supply.price = Number(data.price);
    supply.quantity = Number(data.quantity);
    supply.file = data.file;
    return supply;
  }

}

export interface LabelSupplyApiResponse {
  created_at: string;
  deleted_at: string;
  description: string;
  jpatton_id: string;
  id: string;
  name: string;
  price: string;
  quantity: string;
  updated_at: string;
  file: any;
}
