import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikValues } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { MetadataKeyTypeahead } from '../../shared/forms/MetadataKeyTypeahead';

interface MetadataAPIData {
  id: number;
  key_id: number;
  key: string;
  value: string;
  description:string;
}

interface MetadataKeyAPIData {
  id: number;
  name: string;
  description:string;
}

export const MetadataPage = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [metadatas, setMetadatas] = useState<MetadataAPIData[] | []>([]);
  const [createModalShown, setCreateModalShown] = useState(false);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [keyToEdit, setKeyToEdit] = useState<MetadataKeyAPIData[] | []>([]);
  const routeParams = useParams();

  useEffect(() => {
    getAccountMetadata();
  },        []);

  function getAccountMetadata() {
    setLoading(true);
    const params = {
      account_id: getAccountID(),
    };

    Axios.get('/api/metadata', { params })
      .then((response) => {
        setMetadatas(response.data.metadata.data);
        setLoading(false);
      });
  }

  function createMetadata(values: { selectedKey: any; keyValue: any; }, formProps: FormikActions<FormikValues>) {
    setSaving(true);
    let postURL = '/api/metadata';
    if (selectedId) {
      postURL = `${postURL}/${keyToEdit[0].id}`;
    }
    const params = {
      account_id: getAccountID(),
      selectedKey: values.selectedKey[0].id,
      keyValue: values.keyValue,
    };
    Axios.post(postURL, params)
      .then((response) => {
        getAccountMetadata();
        hideCreateModal();
        setKeyToEdit([]);
        setSelectedId(0);
        setSaving(false);
      })
      .catch((error) => {
        for (const index in error.response.data) {
          formProps.setFieldError(index, error.response.data[index][0]);
        }
        setSaving(false);
      });
  }

  function deleteMetadata() {
    if (selectedId) {
      setSaving(true);
      const params = {
        account_id: getAccountID(),
        id: selectedId,
      };
      Axios.delete('/api/metadata', { params })
        .then((response) => {
          setSelectedId(0);
          setKeyToEdit([]);
          getAccountMetadata();
          hideDeleteModal();
          setSaving(false);
        })
        .catch(() => setSaving(false));
    }
  }

  function showDeleteModal() {
    setDeleteModalShown(true);
  }

  function showCreateModal() {
    setCreateModalShown(true);
  }

  function hideDeleteModal() {
    setDeleteModalShown(false);
    setSelectedId(0);
    setKeyToEdit([]);
  }

  function hideCreateModal() {
    setCreateModalShown(false);
    setSelectedId(0);
    setKeyToEdit([]);
  }

  function setDeleteID(e: any) {
    setKeyToEdit([{
      id: parseInt(e.target.getAttribute('data-id'), 10),
      name: e.target.getAttribute('data-name'),
      description: '',
    }]);
    setSelectedId(parseInt(e.target.getAttribute('data-id'), 10));
    showDeleteModal();
  }

  function setEditID(e: any) {
    setKeyToEdit([{
      id: parseInt(e.target.getAttribute('data-id'), 10),
      name: e.target.getAttribute('data-name'),
      description: '',
    }]);
    setSelectedId(parseInt(e.target.getAttribute('data-id'), 10));
    showCreateModal();
  }
  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  let pageContent;
  const rows = (metadatas as any[]).map(metadata =>
    <tr key={ metadata.id } className="licensor-mapping-row">
      <td style={{ verticalAlign: 'middle' }}>
        <strong>{metadata.key}</strong>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        {metadata.value}
      </td>
      <td>
        <button className="btn btn-link text-danger pull-right" style={{ marginLeft:5 }} data-id={metadata.id} onClick={setDeleteID}>
          Delete
        </button>
        <button className="btn btn-link text-primary pull-right" data-id={metadata.id} data-name={metadata.key} data-key-id={metadata.key_id} onClick={setEditID}>
          Edit
        </button>
      </td>

    </tr>,
  );

  if (loading) {
    pageContent = (
      <LoadingSpinner />
    );
  } else if (metadatas.length === 0 && ! loading) {
    pageContent =  (
      <em>No Metadata Found.</em>
    );
  } else {
    pageContent = (
      <table className="table">
        <tbody>
        {rows}
        </tbody>
      </table>
    );
  }

  const deleteModalText = saving ? 'Deleting...' : 'Delete Metadata' ;
  const editModalText = saving ? 'Saving...' : 'Edit Metadata' ;
  const createModalText = saving ? 'Saving...' : 'Save Metadata' ;

  const initialValues = {
    selectedKey: selectedId ? keyToEdit : [],
    keyValue: selectedId && metadatas !== undefined ? metadatas.find((i:MetadataAPIData) => i.id === selectedId).value : '',
  };

  const validation = Yup.object({
    keyValue: Yup.string().required('A value is required'),
    selectedKey: Yup.array().min(1, 'A key is required'),
  });

  return (
    <FullContent breadcrumbs={[{ name: 'Metadata' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>

        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Metadata</strong></h3>
        </div>
        <button onClick={showCreateModal} className="btn btn-primary">Add Metadata</button>
      </div>
      <div className="panel panel-portal">
        { pageContent }
      </div>
      <GatewayModal shown={deleteModalShown} type={ModalType.Danger} onClose={hideDeleteModal} title="Delete Metadata" >
        <Modal.Body>
          <p className="text-center">
            <strong>Are you sure you want to delete this metadata?</strong>
          </p>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
          <button onClick={deleteMetadata} className="btn btn-danger pull-right" disabled={saving}>{deleteModalText}</button>
        </Modal.Footer>
      </GatewayModal>
      <GatewayModal shown={createModalShown} type={ModalType.Primary} onClose={hideCreateModal} title={ selectedId ? 'Edit Metadata' : 'Create Metadata'} >
        <Modal.Body>
          <Formik
            onSubmit={ (values, formProps) => createMetadata(values, formProps)}
            validationSchema={validation}
            validateOnBlur={false}
            initialValues={initialValues}>
            {formProps =>
              (
                <div>
                  <Form id="account-metadata-form">
                    <div className="form-group">
                      <label>Key Name</label>
                      <MetadataKeyTypeahead
                        selected={formProps.values.selectedKey}
                        onChange={(v) => {
                          formProps.setFieldValue('selectedKey', v);
                        }}
                        disabled={selectedId > 0}
                      />
                      <p className="text-danger">
                        <ErrorMessage name="selectedKey" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor={'keyValue'}>Value</label>
                      <Field placeholder="Value" name="keyValue" className="form-control"/>
                      <p className="text-danger">
                        <ErrorMessage name="keyValue" />
                      </p>
                    </div>
                  </Form>
                </div>
              )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideCreateModal} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" form="account-metadata-form" className="btn btn-primary pull-right" disabled={saving}>{selectedId ? editModalText : createModalText}</button>
        </Modal.Footer>
      </GatewayModal>
    </FullContent>

  );

};
