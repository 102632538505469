import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../../shared/modals';

interface IProps {
  isShown: boolean;
  confirm: () => void;
  close: () => void;
}

export const DeleteDesignModal: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <GatewayModal
      shown={props.isShown}
      title="Delete Submission"
      type={ModalType.Danger}
      onClose={props.close}>
        <Modal.Body>
          <p className="text-center">Are you sure you want to delete this design?</p>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-toolbar">
            <button type="button" className="btn btn-default pull-left" onClick={props.close}>Cancel</button>
            <button type="button" className="btn btn-danger pull-right" onClick={props.confirm}>Yes, Delete Design</button>
          </div>
        </Modal.Footer>
    </GatewayModal>
  );
};
