import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { orderBy, uniq } from 'lodash';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { FullContent } from '../ContentFrame';
import { GatewayModal, ModalType } from '../shared';

interface QuarterYearCount {
  quarter: number;
  year: number;
  count: number;
  processing: number;
}

export const QuarterlyReportsManagementPage = () => {
  const [existing, setExisting] = React.useState<QuarterYearCount[]>([]);
  const [availableYears, setAvailableYears] = React.useState<number[]>([]);
  const [toDelete, setToDelete] = React.useState(-1);
  const [submitting, setSubmitting] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  React.useEffect(() => {
    getExisting();
    const year = new Date().getFullYear();
    const startYear = 2009;
    let years = [... Array(year - startYear)].map((v, i) => startYear + i);
    years.push((new Date()).getFullYear());
    years = orderBy(uniq(years), Number, 'desc');
    setAvailableYears(years);

    const recheck = setInterval(() => {
      getExisting();
    },                          10000);

    return () => clearInterval(recheck);

  },              []);

  const getExisting = async () => {
    const d = await Axios.get('/api/report_saved_royalties');
    const data: QuarterYearCount[] = d.data.data;
    setExisting(data);
  };

  const createReports = async (values: any) => {
    if (!submitting) {
      setSubmitting(true);
      const data = {
        year: values.year,
        quarter: values.quarter,
        market: values.market,
        overwrite: values.overwrite,
      };
      await Axios.post('/api/report_saved_royalties', data);
      await getExisting();
      setSubmitting(false);
    }
  };

  const deleteReport = async (values: any) => {
    const del = getExistingToDelete();
    setDeleting(true);
    if (del) {
      await Axios.delete('/api/report_saved_royalties', { data : { year: del.year, quarter: del.quarter, market: values.market ? values.market : undefined } });
      await getExisting();
    }

    setToDelete(-1);
    setDeleting(false);

  };

  const getExistingToDelete = () => {
    if (toDelete > -1) {
      return existing[toDelete];
    }
    return null;
  };

  const initalGenerateValues = {
    year: null,
    quarter: null,
    market: null,
    overwrite: false,
  };

  const validationSchema = Yup.object().shape({
    year: Yup.number().typeError('Year is required').required('Year is required'),
    quarter: Yup.number().typeError('Quarter is required').required('Quarter is required'),
  });

  const del = getExistingToDelete();

  const totalProcessing = existing.reduce((p, n) => p + n.processing, 0);

  return (
    <FullContent>
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-portal">
            <div className="panel-body">
              <h5><strong>Generate Reports</strong></h5>
              <Formik
                initialValues={initalGenerateValues}
                onSubmit={createReports}
                validationSchema={validationSchema}
              >
                {formProps => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Year</label>
                          <Field component="select" className="form-control" name="year" >
                            <option value={0} selected disabled>Select Year</option>
                            {availableYears.map(y => (<option value={y}>{y}</option>))}
                          </Field>
                          <p className="text-danger"><ErrorMessage name="year" /></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Quarter</label>
                          <Field component="select" className="form-control" name="quarter" >
                            <option value={0} selected disabled>Select Quarter</option>
                            <option value={1}>Q1</option>
                            <option value={2}>Q2</option>
                            <option value={3}>Q3</option>
                            <option value={4}>Q4</option>
                          </Field>
                          <p className="text-danger"><ErrorMessage name="quarter" /></p>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Market</label>
                      <Field component="select" className="form-control" name="market">
                        <option value={0} selected>All Markets</option>
                        <option value={1}>Greek</option>
                        <option value={2}>Collegiate</option>
                        <option value={3}>Associations</option>
                        <option value={5}>Charitable</option>
                      </Field>
                      <ErrorMessage name="market" />
                    </div>
                    <div className="checkbox">
                      <label>
                        <input onChange={e => formProps.setFieldValue('overwrite', e.target.checked)} checked={formProps.values.overwrite} type="checkbox" /> Overwrite existing reports
                      </label>
                    </div>
                    <button type="submit" disabled={submitting} className="btn btn-primary btn-block">{submitting ? 'Generating...' : 'Generate Reports'} </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {totalProcessing ? <div className="row">
        <div className="col-md-12">
          <div className="panel panel-portal">
            <div className="panel-body text-center">

              <h3><strong>Processing {totalProcessing} Reports.</strong></h3>

            </div>
          </div>
        </div>
      </div> : null}
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-portal">
            <div className="panel-body">
              <h5><strong>Existing Reports</strong></h5>
            </div>
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>Year</th>
                  <th>Quarter</th>
                  <th>Count</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {existing.map((e, index) => (<tr>
                  <td></td>
                  <td>{e.year}</td>
                  <td>Q{e.quarter}</td>
                  <td>{e.count}</td>
                  <td><button onClick={() => setToDelete(index)} className="btn btn-xs pull-right btn-danger">Delete</button></td>
                </tr>))}

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <GatewayModal type={ModalType.Danger} title="Delete Existing Report" onClose={() => setToDelete(-1)} shown={toDelete !== -1}>
        <Formik initialValues={{ market: null }} onSubmit={deleteReport}>
          {formProps => (
            <Form>
              <Modal.Body>
                {del ? <p className="text-center">Are you sure you want to delete {del.year}-Q{del.quarter}?</p> : null}
                <p className="text-center">Selecting a market will only delete the selected markets reports.</p>
                <div className="form-group">
                  <label>Market</label>
                  <Field component="select"  className="form-control" name="market">
                    <option value={0} selected >All Markets</option>
                    <option value={1}>Greek</option>
                    <option value={2}>Collegiate</option>
                    <option value={3}>Associations</option>
                    <option value={5}>Charitable</option>
                  </Field>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setToDelete(-1)} className="btn btn-default pull-left">Cancel</button>
                <button type="submit" className="btn btn-danger pull-right">{deleting ? 'Deleting...' : 'Delete Reports'}</button>
              </Modal.Footer>
            </Form>
        )}
        </Formik>
      </GatewayModal>
    </FullContent>
  );
};
