import * as moment from 'moment';
import { AccountWebUrlType } from './AccountWebUrl';

export interface EnforcementTemplateResponse {
  id: number;
  created_at: string|null;
  updated_at: string|null;
  name: string;
  content: string;
  description: string;
  platform_id: number|null;
  is_dmca: boolean;
  platform?: any;
  subject: string;
}

export class EnforcementTemplate {
  id: number;
  createdAt: moment.Moment|null;
  updatedAt: moment.Moment|null;
  name: string;
  content: string;
  description: string;
  platformId: number|null;
  platform: AccountWebUrlType|null;
  isDmca: boolean;
  subject: string;

  static fromApi(data: EnforcementTemplateResponse) {
    const template = new EnforcementTemplate();
    template.id = data.id;
    template.createdAt = data.created_at ? moment(data.created_at) : null;
    template.updatedAt = data.updated_at ? moment(data.updated_at) : null;
    template.name = data.name;
    template.content = data.content;
    template.description = data.description;
    template.platformId = data.platform_id;
    template.isDmca = data.is_dmca;
    template.subject = data.subject;
    if (data.platform) {
      template.platform = AccountWebUrlType.fromApi(data.platform);
    } else {
      template.platform = null;
    }
    return template;
  }
}
