import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faExclamationCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { AffinityClient, ClientRule, ProductCategory } from '../../../shared';

export interface CategoryAlert {
  allowed: boolean;
  partial: boolean;
  category: ProductCategory;
  rule: null | ClientRule;
}

export interface LicensorCategoryAlert {
  licensor: AffinityClient;
  alerts: CategoryAlert[];
}

export interface LicensedAlert {
  hasLicenses: boolean;
  licensed: boolean;
  pending: boolean;
  pendingLicenses: any[];
}

interface IProps {
  licensed: LicensedAlert;
  category: LicensorCategoryAlert[];
  loading: boolean;
}

export class DesignAlert extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const licensedAlert = this.props.licensed;
    const categoryAlert = this.props.category;
    let licensed;
    if (licensedAlert.licensed) {
      licensed = (
        <div className="alert alert-success">
          <span><FontAwesomeIcon icon={faCheckCircle} /> Active license</span>
        </div>
      );
    } else {
      if (licensedAlert.pending) {
        licensed = (
          <div className="alert alert-info">
            <span><FontAwesomeIcon icon={faExclamationCircle} /> Pending license</span>
          </div>
        );

      } else {
        if (licensedAlert.hasLicenses) {
          licensed = (
            <div className="alert alert-warning">
              <span><FontAwesomeIcon icon={faExclamationCircle} /> Not licensed for category</span>
            </div>
          );
        } else {
          licensed = (
            <div className="alert alert-danger">
              <span><FontAwesomeIcon icon={faTimesCircle} /> Not licensed</span>
            </div>
          );
        }

      }
    }

    const pendingCount = licensedAlert.pendingLicenses.length;
    const pending = (
      <div className={`alert alert-${pendingCount ? 'warning' : 'success'}`}>
        <span><FontAwesomeIcon icon={pendingCount ? faExclamationCircle : faCheckCircle} /> {pendingCount ? `${pendingCount} Pending` : 'No pending'} license</span>
      </div>
    );

    let category;

    const catAlerts = categoryAlert.map(c => c.alerts).reduce((prev, next) => prev.concat(next), []);

    const allowed = catAlerts.filter(c => c.allowed).map(c => c.category.name).join(', ');
    const prohibited = catAlerts.filter(c => !c.allowed && !c.partial).map(c => c.category.name).join(', ');
    const partial = catAlerts.filter(c => c.partial && !c.allowed).map(c => c.category.name).join(', ');

    category = (
      <div>
        {allowed ?
          <div className="alert alert-success">
            <span><FontAwesomeIcon icon={faCheckCircle} /> {allowed} allowed</span>
          </div> : null
        }
        {partial ?
          <div className="alert alert-warning">
            <span><FontAwesomeIcon icon={faExclamationCircle} /> {partial} has a restricted rule</span>
          </div> : null
        }
        {prohibited ?
          <div className="alert alert-danger">
            <span><FontAwesomeIcon icon={faTimesCircle} /> {prohibited} prohibited</span>
          </div> : null
        }

      </div>
    );

    const loader = (
      <div className="design-alert-spinner">
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
    return (
      <div>
        <div className="row">
          <div style={{ paddingRight: 7.5 }} className="col-xs-6">
            {this.props.loading ? loader : licensed}
          </div>
          <div style={{ paddingLeft: 7.5 }} className="col-xs-6">
            {this.props.loading ? loader : pending}
          </div>

        </div>
        {this.props.loading ? loader : category}

      </div>
    );
  }
}
