import { Field, FormikProps } from 'formik';
import * as React from 'react';

interface IProps {
  formProps: FormikProps<any>;
}
export class ColorTypeForm extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <label>Pantone (PMS)</label>
              <Field name="pantone" type="text" className="form-control" placeholder="###" />
            </div>
            <div className="col-md-6">
              <label>RGB</label>
              <div className="row">
                <div className="col-md-4">
                  <Field name="rgbR" type="text" className="form-control" placeholder="R" />
                </div>
                <div className="col-md-4">
                  <Field name="rgbG" type="text" className="form-control" placeholder="G" />
                </div>
                <div className="col-md-4">
                  <Field name="rgbB" type="text" className="form-control" placeholder="B" />
                </div>

              </div>

            </div>

          </div>
        </div>

        <div className="form-group">
          <label>CMYK</label>
          <div className="row">
            <div className="col-md-3">
              <Field name="cmykC" type="text" className="form-control" placeholder="C" />
            </div>
            <div className="col-md-3">
              <Field name="cmykM" type="text" className="form-control" placeholder="M" />
            </div>
            <div className="col-md-3">

              <Field name="cmykY" type="text" className="form-control" placeholder="Y" />
            </div>
            <div className="col-md-3">

              <Field name="cmykK" type="text" className="form-control" placeholder="K" />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-md-6">
              <label >Hex</label>
              <div className="input-group">
                <span className="input-group-addon">#</span>
                <Field name="hex" type="text" className="form-control" placeholder="#FFFFFF" />
              </div>
            </div>
          </div>
        </div>

      </div>

    );
  }
}
