import Axios, { CancelTokenSource } from 'axios';
// import { Field } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { getAccountProfile } from '../../../api';
import { UserContext, UserType } from '../../../contexts';
import { AccountAddress, AccountAlias, AccountAliasAPIResponse } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { createAccountAddress, getAliases, getNewAccountAddress } from '../api';
import { AccountAddressForm, AccountAddressFormValues } from './AccountAddressForm';

interface IState {
  accountAddress: AccountAddress | null;
  accountName: string;
  loading: boolean;
  saving: boolean;
  deleteOldBusiness: boolean;
  error: string;
  confirmShown: boolean;
  updatedValues: AccountAddressFormValues | null;
  aliases: AccountAlias[];
  oldBusinessAddress: string | null;
  newBusinessAddress: string | null;
  isFirstAddress: boolean | null;
  user: UserType | null;
}

export class CreateAccountAddress extends React.Component<RouteComponentProps<any>, IState> {

  _saveAccountAddressCancelToken:CancelTokenSource;
  _getAccountAliasesSource: CancelTokenSource;
  _getAccountAddressesSource: CancelTokenSource;
  _getAccountSource: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      accountName: '',
      accountAddress: null,
      loading: true,
      saving: false,
      error: '',
      confirmShown: false,
      updatedValues: null,
      aliases: [],
      oldBusinessAddress: null,
      newBusinessAddress: null,
      isFirstAddress: null,
      user: null,
      deleteOldBusiness: false,
    };
    this.getAccountAddresses = this.getAccountAddresses.bind(this);
    this.saveAccountAddress = this.saveAccountAddress.bind(this);
    this.getAccountAliases = this.getAccountAliases.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
    this.hideConfirmModal = this.hideConfirmModal.bind(this);
    this.toggleDeleteOldBusiness = this.toggleDeleteOldBusiness.bind(this);
  }

  componentDidMount() {
    this.getAccountAliases();
    this.getAccountAddresses();
  }

  render() {
    if (!this.state.loading && this.state.isFirstAddress != null) {

      return (
        <FullContent>
          <div className="panel panel-portal">
            <div className="panel-body">
              <div className="col-xs-12 col-sm-8 col-sm-push-2">
                <div style={{ paddingBottom: 10 }}>
                  <h3><strong>Add Address</strong></h3>
                  {this.state.error ? (
                    <div className="alert alert-danger">
                      {this.state.error}
                    </div>
                  ) : null }
                </div>
                <UserContext.Consumer>
                  { user => (
                      <AccountAddressForm
                        accountAddress={null}
                        onSubmit={ values => this.showConfirmModal(values, user)}
                        saving={this.state.saving}
                        isUpdate={false}
                        aliases={this.state.aliases}
                        isForcedBusiness={this.state.isFirstAddress}
                        accountName={this.state.accountName}
                      />
                    )
                  }
                </UserContext.Consumer>
                <GatewayModal
                  onClose={this.hideConfirmModal}
                  shown={this.state.confirmShown}
                  title="Confirm Account Update"
                  type={ModalType.Primary}
                >
                  <Modal.Body>
                    <p className="text-center">Please review your request to change your business address.<br/>
                      <br/>
                      <span style={{ fontWeight: 'bold', color: 'red' }}>Your business address must be the legal address
                        of your business, as shown on business documents, tax forms, etc. This change typically requires
                        updates to your existing license agreements.</span>
                      <br/>
                      <br/>
                      { this.state.oldBusinessAddress &&
                        <>
                          <strong>Old Business Address</strong><br/>
                          {this.state.oldBusinessAddress}
                          <br/>
                          <br/>
                        </>
                      }
                      <strong>New Business Address</strong><br/>
                      {this.state.newBusinessAddress}
                      <br/>
                      <br/>
                    </p>
                    { this.state.oldBusinessAddress &&
                      <>
                        <div className="checkbox text-center">
                          <label>
                            <input type="checkbox" name="deleteOldBusiness" checked={this.state.deleteOldBusiness} onChange={this.toggleDeleteOldBusiness} />
                            <strong>Remove the old business address from your account?</strong>
                          </label>
                        </div>
                      </>
                    }
                  </Modal.Body>
                  <Modal.Footer>
                    <button onClick={this.hideConfirmModal} className="btn btn-default pull-left">Cancel</button>
                    <button onClick={this.saveAccountAddress} className="btn btn-primary pull-right">Save Changes</button>
                  </Modal.Footer>

                </GatewayModal>
              </div>

            </div>
          </div>
        </FullContent>
      );

    }
    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>
      </FullContent>
    );

  }

  showConfirmModal(values: AccountAddressFormValues, user: UserType) {
    if (values.isBusiness && ! this.state.isFirstAddress) {
      const secondaryLine = values.secondaryLine ? `${values.secondaryLine}, ` : '';
      const newBusinessAddress = `${values.primaryLine}, ${secondaryLine}${values.city}, ${values.stateId} ${values.zip}`;
      this.setState({ newBusinessAddress, user, deleteOldBusiness: true, confirmShown: true, updatedValues: values });
    } else {
      this.setState({ user, updatedValues: values });
      this.saveAccountAddress();
    }
  }

  hideConfirmModal() {
    this.setState({ confirmShown: false });
  }

  getAccountAddresses() {
    this._getAccountAddressesSource = Axios.CancelToken.source();

    const params = {
      account_id: this.accountID,
    };

    getNewAccountAddress(params, this._getAccountAddressesSource)
      .then((getAccountAddressesResponse) => {

        const data = getAccountAddressesResponse.data.data;

        if (data.length > 0) {
          this.setState({ isFirstAddress: false });
        } else {
          this.setState({ isFirstAddress: true });
        }

        let oldBusinessAddress = data.find(i => i.is_business_address);
        oldBusinessAddress = oldBusinessAddress ? oldBusinessAddress.full_address : null;

        this.setState({ oldBusinessAddress });
      });
  }

  saveAccountAddress() {
    const values = this.state.updatedValues;
    const user = this.state.user;
    if (values) {
      this.setState({ saving: true });
      this.hideConfirmModal();
      if (this.state.isFirstAddress) {
        values.isBusiness = true;
      }
      const formData = AccountAddress.formValuesToFormData(values);
      formData.append('account_id', this.accountID);
      formData.append('delete_old_business', this.state.deleteOldBusiness ? '1' : '0');
      this._saveAccountAddressCancelToken = Axios.CancelToken.source();

      createAccountAddress(formData, this._saveAccountAddressCancelToken)
        .then((accountAddressUpdateResponse) => {
          this.setState({ saving: false, error: '' });
          if (user.type === 'admin') {
            this.props.history.push(this.backRoute);
          } else {
            this.props.history.push('/settings/addresses');
          }
        }).catch(error => this.setState({ saving: false, error: 'There was an error updating the address.' }));

    }

  }

  getAccountAliases() {
    const params = {
      account_id: this.accountID,
    };
    this._getAccountAliasesSource = Axios.CancelToken.source();
    this._getAccountSource = Axios.CancelToken.source();

    const getAliasesRequest = getAliases(params, this._getAccountAliasesSource);
    const getAccountRequest = getAccountProfile(params['account_id'], this._getAccountSource);

    Axios.all([getAliasesRequest, getAccountRequest])
      .then(([aliasesResponse, accountResponse]) => {
        let aliases = [];
        if (aliasesResponse.data.data) {
          aliases = aliasesResponse.data.data.map((alias: AccountAliasAPIResponse) => AccountAlias.fromApi(alias));
        }
        const accountName = accountResponse.data.short_name;
        this.setState({ accountName, aliases, loading: false });
      });

  }

  toggleDeleteOldBusiness(event: any) {
    this.setState({
      deleteOldBusiness: event.target.checked,
    });
  }

  get accountID() {
    if (this.props.match.params['vendorId']) {
      return this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      return this.props.match.params['licensorId'];
    }
    return '';
  }

  get backRoute() {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.accountID}/settings/addresses`;
    }
    if (this.props.match.params['licensorId']) {
      return `/clients/${this.accountID}/settings/addresses`;
    }

    return '';
  }

}
