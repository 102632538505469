import { ChartOptions } from 'chart.js';
import * as moment from 'moment';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { LoadingSpinner } from '../../shared';
import { ChartHeader } from '../ChartHeader';

interface PerformanceRevStats {
  grossSales: number;
  royalties: number;
  month: number;
}

interface IProps {
  previous: PerformanceRevStats[];
  current: PerformanceRevStats[];
  type: 'sales' | 'royalties';
  loading: boolean;
}

export const RevenueOverTime = (props: IProps) => {

  if (props.loading) {
    return (<LoadingSpinner />);
  }

  const sortedMonths = [7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6];

  const sortedPrevious = props.previous.sort((a, b) => sortedMonths.indexOf(a.month) - sortedMonths.indexOf(b.month));
  const sortedCurrent = props.current.sort((a, b) => sortedMonths.indexOf(a.month) - sortedMonths.indexOf(b.month));

  const currentSalesData = sortedCurrent.map(s => s.grossSales);
  const previousSalesData = sortedPrevious.map(s => s.grossSales);
  const months = props.previous.map((s) => {
    const date = moment().month(s.month - 1);
    return date.format('MMM');
  });
  const currentRoyaltiesData = sortedCurrent.map(s => s.royalties);
  const previousRoyaltiesData = sortedPrevious.map(s => s.royalties);

  const royaltiesChartData = {
    labels: months,
    datasets: [
      {
        data: currentRoyaltiesData,
        fill: false,
        backgroundColor: '#4f6980',
        borderColor: '#4f6980',

      },
      {
        data: previousRoyaltiesData,
        fill: false,
        backgroundColor: '#d3d3d3',
        borderColor: '#d3d3d3',

      },
    ],
  };

  const salesChartData = {
    labels: months,
    datasets: [
      {
        data: currentSalesData,
        fill: false,
        backgroundColor: '#4f6980',
        borderColor: '#4f6980',

      },
      {
        data: previousSalesData,
        fill: false,
        backgroundColor: '#d3d3d3',
        borderColor: '#d3d3d3',

      },
    ],
  };
  const chartOptions: ChartOptions = {
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    scales: {
      xAxes: [{
        ticks: {
          // display: false,
        },
      }],
      yAxes: [{
        ticks: {
          display: false,
        },
      }],

    },
    elements: {
      point: {
        radius: 0,
      },
    },

  };

  if (props.type === 'sales') {
    return (
      <div>
        <ChartHeader header="Sales over time." subHeader="" />
        <Line
          data={salesChartData}
          options={chartOptions}

        />

      </div>
    );
  }

  return (
    <div>
      <ChartHeader header="Royalties over time." subHeader="" />
      <Line
        data={royaltiesChartData}
        options={chartOptions}

      />

    </div>
  );

};
