import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface IProps {
  title: string;
  subText: string;
  icon: IconProp;

}

export const DashboardSectionHeader = (props: IProps) => {
  return (
    <div className="performance-section-header" >
      <FontAwesomeIcon className="section-icon" icon={props.icon} />
      <div className="title-subtext">
        <h4><strong>{props.title}</strong></h4>
        <p className="text-muted">{props.subText}</p>
      </div>
    </div>

  );
};
