import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { AccountProfile, AccountProfileUpdate } from '../../../shared';
import { FormImageWithPreview, StateField } from '../../shared/forms';

interface IProps {
  account: AccountProfile;
  onSubmit: (values: AccountFormValues) => void;
  saving: boolean;
  isUpdateRequest: boolean;
  reason?: string;
  updateRequest?: AccountProfileUpdate;
}

export interface AccountFormValues {
  logo: File | null;
  logoId: number | null;
  logoPreview: string;
  accountName: string;
  legalBusinessName: string;
  accountShortName: string;
  type: string;
  businessLine1: string;
  businessLine2: string;
  businessCity: string;
  businessState: string;
  businessZip: string;
  businessPhone: string;
  publicLine1: string;
  publicLine2: string;
  publicCity: string;
  publicState: string;
  publicZip: string;
  publicPhone: string;
  description: string;
  banner: File | null;
  bannerPreview: string;
  reason: string;
}

export class AccountProfileForm extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.profileUpdates = this.profileUpdates.bind(this);
    this.setAllFormValues = this.setAllFormValues.bind(this);
  }

  profileUpdates(formProps: FormikProps<AccountFormValues>) {
    if (this.props.updateRequest) {
      const update = this.props.updateRequest;
      const businessAddressDiff = update.diffs.address_line1 ||
        update.diffs.address_line2 ||
        update.diffs.city ||
        update.diffs.state ||
        update.diffs.zip;
      const businessAddress = this.props.updateRequest.account.businessAddress;
      businessAddress.line1 = update.diffs.address_line1 ? update.addressLine1 : businessAddress.line1;
      businessAddress.line2 = update.diffs.address_line2 ? update.addressLine2 : businessAddress.line2;
      businessAddress.city = update.diffs.city ? update.city : businessAddress.city;
      businessAddress.state = update.diffs.state ? update.state : businessAddress.state;
      businessAddress.zip = update.diffs.zip ? update.zip : businessAddress.zip;
      const publicAddressDiff = update.diffs.public_address_line1 ||
        update.diffs.public_address_line2 ||
        update.diffs.public_city ||
        update.diffs.public_state ||
        update.diffs.public_zip;
      const publicAddress = this.props.updateRequest.account.publicAddress;
      publicAddress.line1 = update.diffs.public_address_line1 ? update.publicAddressLine1 : publicAddress.line1;
      publicAddress.line2 = update.diffs.public_address_line2 ? update.publicAddressLine2 : publicAddress.line2;
      publicAddress.city = update.diffs.public_city ? update.publicCity : publicAddress.city;
      publicAddress.state = update.diffs.public_state ? update.publicState : publicAddress.state;
      publicAddress.zip = update.diffs.public_zip ? update.publicZip : publicAddress.zip;
      return (
        <div className="form-horizontal">
          {update.diffs.image_id ? (
            <div className="form-group">
              <label className="col-sm-4 control-label">
                Logo
              </label>
              <div style={{ paddingTop: 7 }} className="col-lg-4 col-md-6 col-sm-8">
                <img
                  style={{ width: 100, height: 100, borderRadius: 4, border: '1px solid #eee' }}
                  className="img-responsive"
                  src={update.image.getSize('sm')}
                />
              </div>
            </div>

          ) : null}
          {update.diffs.short_name ? this.updateItem('Account Name', update.accountName) : null}
          {update.diffs.name ? this.updateItem('Legal Name', update.legalName) : null}

          {businessAddressDiff ? (
            this.updateItem('Business Address', businessAddress.singleLine)
          ) : null}

          {update.diffs.phone ? this.updateItem('Business Phone', update.phone) : null}

          {publicAddressDiff ? (
            this.updateItem('Public Address', publicAddress.singleLine)
          ) : null}

          {update.diffs.public_phone ? this.updateItem('Public Phone', update.publicPhone) : null}

          {update.diffs.description ? this.updateItem('description', update.description) : null}

          {this.updateItem('Note', update.reason)}

          <div className="row">
            <div className="col-sm-offset-4 col-lg-4 col-md-6 col-sm-8 button-row">
              <button onClick={() => this.setAllFormValues(formProps, update)} className="btn btn-primary">
                Use Changes
              </button>
            </div>

          </div>
          <div className="row">

            <p className="col-sm-offset-4 col-lg-4 col-md-6 col-sm-8 text-muted">
              You must save the form below for these to be approved.
            </p>
          </div>

        </div>
      );
    }
    return null;
  }

  setAllFormValues(formProps: FormikProps<AccountFormValues>, update: AccountProfileUpdate) {
    const diffs = update.diffs;
    this.updateFormValue(formProps, 'accountName', update.accountName, diffs.short_name);
    this.updateFormValue(formProps, 'legalBusinessName', update.legalName, diffs.name);
    this.updateFormValue(formProps, 'businessLine1', update.addressLine1, diffs.address_line1);
    this.updateFormValue(formProps, 'businessLine2', update.addressLine2, diffs.address_line2);
    this.updateFormValue(formProps, 'businessCity', update.city, diffs.city);
    this.updateFormValue(formProps, 'businessState', update.state, diffs.state);
    this.updateFormValue(formProps, 'businessZip', update.zip, diffs.zip);
    this.updateFormValue(formProps, 'businessPhone', update.phone, diffs.phone);
    this.updateFormValue(formProps, 'publicLine1', update.publicAddressLine1, diffs.public_address_line1);
    this.updateFormValue(formProps, 'publicLine2', update.publicAddressLine2, diffs.public_address_line2);
    this.updateFormValue(formProps, 'publicCity', update.publicCity, diffs.public_city);
    this.updateFormValue(formProps, 'publicState', update.publicState, diffs.public_state);
    this.updateFormValue(formProps, 'publicZip', update.publicZip, diffs.public_zip);
    this.updateFormValue(formProps, 'publicPhone', update.publicPhone, diffs.public_phone);
    this.updateFormValue(formProps, 'description', update.description, diffs.description);

    if (diffs.image_id) {
      formProps.setFieldValue('logoId', update.image.id);
      formProps.setFieldValue('logoPreview', update.image.getSize('sm'));
    }

  }

  updateFormValue(formProps: FormikProps<AccountFormValues>, field: string, value: string, diff: boolean) {
    if (value && diff) {
      formProps.setFieldValue(field, value);
    }
  }

  updateItem(label: string, value: string) {
    return (
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {label}
        </label>
        <div style={{ paddingTop: 7 }} className="col-lg-4 col-md-6 col-sm-8">
          {value}
        </div>
      </div>
    );

  }

  render() {

    const account = this.props.account;
    const initialValues: AccountFormValues = {
      logo: null,
      logoPreview: account.logo.getSize('sm'),
      logoId: null,
      accountName: account.accountName,
      accountShortName: account.accountType === 'client' ? account.shortAccountName : '',
      legalBusinessName: account.legalName,
      type: account.type,
      businessLine1: account.businessAddress.line1,
      businessLine2: account.businessAddress.line2,
      businessCity: account.businessAddress.city,
      businessState: account.businessAddress.state,
      businessZip: account.businessAddress.zip,
      businessPhone: account.businessAddress.phone,
      publicPhone: account.publicAddress.phone,
      publicLine1: account.publicAddress.line1,
      publicLine2: account.publicAddress.line2,
      publicCity: account.publicAddress.city,
      publicState: account.publicAddress.state,
      publicZip: account.publicAddress.zip,
      description: account.description,
      banner: null,
      bannerPreview: account.banner.getSize('md'),
      reason: this.props.reason ? this.props.reason : '',
    };

    let validation = Yup.object().shape({
    });
    if (this.props.isUpdateRequest) {
      validation = validation.concat(Yup.object().shape({
        reason: Yup.string().min(3).required('A comment is required.'),
      }));
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.props.onSubmit}
        validationSchema={validation}
      >
        {(formProps: FormikProps<AccountFormValues>) =>
          (
            <div>
              {this.props.updateRequest ? (
                <div style={{ marginTop: 15, marginBottom: 15 }}>
                  <p className="text-danger small"><strong>PENDING PROFILE UPDATE</strong></p>
                  {this.profileUpdates(formProps)}
                  <hr />
                </div>
              ) : null}
              <Form className="form-horizontal">

                <FormImageWithPreview imagePreview={formProps.values.logoPreview} onChange={(preview, file) => {
                  formProps.setFieldValue('logo', file);
                  formProps.setFieldValue('logoPreview', preview);
                }}
                  title="Logo"
                  labelClass="col-sm-4"
                  fieldClass="col-lg-4 col-md-6 col-sm-8"
                />
                {!this.props.isUpdateRequest ?
                  <FormImageWithPreview imagePreview={formProps.values.bannerPreview} onChange={(preview, file) => {
                    formProps.setFieldValue('banner', file);
                    formProps.setFieldValue('bannerPreview', preview);
                  }}
                    title="Banner"
                    labelClass="col-sm-4"
                    fieldClass="col-lg-4 col-md-6 col-sm-8"
                    imagePreviewWidth={150}
                  /> : null
                }
                <div className="form-group">
                  <label className="col-sm-4 control-label">Account Name</label>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Field placeholder="Account Name" name="accountName" className="form-control" />
                  </div>
                </div>
                <hr />
                {account.accountType === 'client' ? (
                  <div className="form-group">
                    <label className="col-sm-4 control-label">Account Short Name</label>
                    <div className="col-lg-4 col-md-6 col-sm-8">
                      <Field placeholder="Account Short Name" name="accountShortName" className="form-control" />
                    </div>
                  </div>
                ) : null}
                <div className="form-group">
                  <label className="col-sm-4 control-label">Legal Business Name</label>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Field placeholder="Legal Name" name="legalBusinessName" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">Business Phone</label>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Field placeholder="Phone" name="businessPhone" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">Business Address</label>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Field placeholder="Line 1" name="businessLine1" className="form-control" style={{ marginBottom: 15 }} />
                    <Field placeholder="Line 2" name="businessLine2" className="form-control" style={{ marginBottom: 15 }} />
                    <Field placeholder="City" name="businessCity" className="form-control" style={{ marginBottom: 15 }} />
                    <StateField
                      style={{ marginBottom: 15 }}
                      value={formProps.values.businessState}
                      short={true}
                      includeOtherStates={true}
                      onChange={(state: string) => formProps.setFieldValue('businessState', state)}
                    />
                    <Field placeholder="Postal Code" name="businessZip" className="form-control" />
                  </div>
                </div>

                <hr />
                <div className="form-group">
                  <label className="col-sm-4 control-label">Public Phone</label>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Field placeholder="Public Phone" name="publicPhone" className="form-control" />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-sm-4 control-label">Public Address</label>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Field placeholder="Line 1" name="publicLine1" className="form-control" style={{ marginBottom: 15 }} />
                    <Field placeholder="Line 2" name="publicLine2" className="form-control" style={{ marginBottom: 15 }} />
                    <Field placeholder="City" name="publicCity" className="form-control" style={{ marginBottom: 15 }} />
                    <StateField
                      style={{ marginBottom: 15 }}
                      value={formProps.values.publicState}
                      short={true}
                      includeOtherStates={true}
                      onChange={(state: string) => formProps.setFieldValue('publicState', state)}
                    />
                    <Field placeholder="Postal Code" name="publicZip" className="form-control" />
                  </div>
                </div>

                {!this.props.isUpdateRequest ? <div className="form-group">
                  <label className="col-sm-4 control-label">Description</label>
                  <div className="col-lg-4 col-md-6 col-sm-8">
                    <Field placeholder="Description" component="textarea" name="description" className="form-control" />
                  </div>
                </div> : null}
                <hr />
                {this.props.isUpdateRequest ?
                  <div>
                    <div className="form-group">
                      <label className="col-sm-4 control-label">
                        Please Describe The Changes
                      </label>
                      <div className="col-lg-4 col-md-6 col-sm-8">
                        <Field
                          placeholder="Please describe the requested changes, and include any helpful comments"
                          component="textarea"
                          name="reason"
                          className="form-control"
                        />
                        <p className="text-danger">
                          <ErrorMessage name="reason" />
                        </p>
                      </div>
                    </div>
                    <hr />
                  </div>
                  : null}

                <div className="settings-save-button-container">
                  <button disabled={this.props.saving} className="btn btn-primary btn-lg" type="submit">
                    {this.props.saving ? 'Saving...' : 'Save Changes'}
                  </button>
                </div>

              </Form>
            </div>
          )}
      </Formik>
    );

  }

}
