import { Field, FormikProps } from 'formik';
import * as React from 'react';

interface IProps {
  formProps: FormikProps<any>;
}
export class TextTypeForm extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div
        className={
          `form-group ${this.props.formProps.errors.brandText && this.props.formProps.touched.brandText ? 'has-error' : ''}`
        }
      >

        <label>Text</label>
        <Field name="brandText" type="text" className="form-control" placeholder="Brand Text" />

      </div>
    );
  }
}
