import * as React from 'react';
import NumberFormat from 'react-number-format';

interface IStatusFilter {
  id: number;
  name: string;
  count: number|null;
  alertClass: string;
  active: boolean;
  selectFn: (x: number) => void;
}

const StatusFilter = (props: IStatusFilter) => {
  const labelClassNames = ['label', `label-${props.alertClass}`];
  const countClassNames = ['badge', 'label-badge', `label-badge-${props.alertClass}`];
  const activeClass = props.active ? 'active' : '';

  function select() {
    props.selectFn(props.id);
  }

  return (
    <li className={activeClass} onClick={select}>
      <a style={{ cursor: 'pointer' }}>
        <span className={labelClassNames.join(' ')}>{props.name}</span>
        {props.count !== null ? <span className={countClassNames.join(' ')}>{
          <NumberFormat displayType={'text'} thousandSeparator={true} value={props.count} />}
        </span> : null}
      </a>
    </li>
  );
};

export default StatusFilter;
