import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { getLicensors } from '../../../../api';
import { AffinityClient, Vendor } from '../../../../shared';
import { LoadingSpinner } from '../../../shared';

interface SitePageVendorFields {
  singlePage: number;
  sidebar: number;
  keyword: string;
  clients: number[];
  vendors: number[];
  limit: number;
}

interface IProps {
  onSubmit: (values: string) => void;
  initialValues?: SitePageVendorFields;
}

export const SitePageVendorsForm = (props: IProps) => {
  const [clients, setClients] = React.useState<AffinityClient[]>([]);
  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [loading, setLoading] = React.useState(true);
  const params = useParams();

  React.useEffect(() => {
    setup();
  },              []);

  const setup = async () => {
    const siteID = params['siteId'];
    const getClients = getLicensors();
    const getVendors = Axios.get(`/api/marketplace/vendors?per_page=999&site_id=${siteID}&order_by=alpha`);

    const all = await Axios.all([getClients, getVendors]);
    setClients(all[0].data.data.map((c: any) => new AffinityClient(c)));
    setVendors(all[1].data.data.map((v: any) => Vendor.fromApi(v)));
    setLoading(false);
  };

  const selectedClients = (ids: number[]) => {
    return clients.filter(c => ids.includes(Number(c.id)));
  };

  const selectedVendors = (ids: number[]) => {
    return vendors.filter(c => ids.includes(Number(c.id)));
  };

  const initialValues: SitePageVendorFields = props.initialValues ? props.initialValues : {
    singlePage: 0,
    sidebar: 0,
    keyword: '',
    clients: [],
    vendors: [],
    limit: 36,
  };

  const handleSubmit = (values: SitePageVendorFields) => {
    const s = {
      is_single_page: Number(values.singlePage) ? true : false,
      has_sidebar: Number(values.sidebar) ? true : false,
      keyword: values.keyword ? values.keyword : null,
      client_ids: values.clients ? values.clients : null,
      vendor_ids: values.vendors ? values.vendors : null,
      limit: values.limit ? Number(values.limit) : null,
    };
    const cleaned = omitBy(s, isNil);
    props.onSubmit(JSON.stringify(cleaned));

  };

  if (loading) {
    return (<LoadingSpinner />);
  }
  const validationSchema = Yup.object({
    singlePage: Yup.number().when(
      'sidebar',
      {
        is: s => s === 1,
        then: Yup.number().oneOf([1], 'Must be single page when sidebar is active.'),
        otherwise: Yup.number(),
      },
    ),
    sidebar: Yup.number(),
    limit: Yup.number().typeError('Page size must be a number'),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {formProps => (
        <Form>
          <div className="form-group">
            <label>Single Page</label>
            <Field name="singlePage" component="select" className="form-control">
              <option value={0}>Not Single Page</option>
              <option value={1}>Single Page</option>
            </Field>
            <p className="text-danger">
              <ErrorMessage name="singlePage" />
            </p>
          </div>
          {Number(formProps.values.singlePage) ? <div className="form-group">
            <label>Has Sidebar</label>
            <Field name="sidebar" component="select" className="form-control">
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </Field>
          </div> : null}
          <div className="form-group">
            <label>Keyword</label>
            <Field name="keyword" className="form-control" />
          </div>
          <div className="form-group">
            <label className="small">Organizations</label>
            <Typeahead
              id="insignia-typeahead"
              placeholder="Organizations"
              selected={selectedClients(formProps.values.clients)}
              onChange={c => formProps.setFieldValue('clients', c.map(n => n.id))}
              options={clients}
              multiple={true}
              labelKey={option => option.shortName}
            />
          </div>
          <div className="form-group">
            <label className="small">Vendors</label>
            <Typeahead
              id="insignia-typeahead"
              placeholder="Vendors"
              selected={selectedVendors(formProps.values.vendors)}
              onChange={c => formProps.setFieldValue('vendors', c.map(n => n.id))}
              options={vendors}
              multiple={true}
              labelKey={option => option.shortName}
            />
          </div>

          <div className="form-group">
            <label>Page Size</label>
            <Field name="limit" className="form-control" />
            <p className="text-danger">
              <ErrorMessage name="limit" />
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="btn btn-primary">Save Page Item</button>
          </div>
        </Form>
      )}

    </Formik>
  );

};
