import { faBadgeDollar, faChartPie, faHashtag, faSackDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { usePerformanceQueryParams } from '..';
import { LoadingSpinner } from '../../shared';
import { ChartFooter } from '../ChartFooter';
import { ChartHeader } from '../ChartHeader';
import { ChartItemList } from '../ChartItemList';
import ExportButton from './ExportButton';
import { VendorRetailerStats } from './shared';
interface IProps {
  large: boolean;
  link?: string;
  vendors: VendorRetailerStats[];
  loading: boolean;
  isAggregate: boolean;
  onDashboard?: boolean;
  page?: number;
}

export const VendorPerformance = (props: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const performanceQP = usePerformanceQueryParams();
  const tableRef = React.useRef<HTMLTableElement>(null);
  const chartRef = React.useRef<any>(null);

  if (props.loading) {
    return (<LoadingSpinner />);
  }

  const list = props.vendors.map((r) => {
    return {
      percent: r.percent,
      units: r.units,
      avgUnitSales: (r.sales / r.units).toFixed(2),
      avgUnitRoyalties: (r.amount / r.units).toFixed(2),
      sales: r.sales,
      royalties: r.amount,
      name: r.name,
      vendor: r.name,
      image: r.image,
      previousRoyalties: r.previousAmount,
      royaltyComparison: r.amount - r.previousAmount,
      percentComparison: r.previousAmount > 0 ? ((r.amount - r.previousAmount) / r.previousAmount) * 100 : 0,
      rpu: r.rpu,
    };
  });

  const handleClick = (index: number) => {
    const vendor = props.vendors[index];
    if (vendor) {
      const queryString = performanceQP.toQueryString([{ value: vendor.id, label: 'vendor' }]);
      if (props.onDashboard) {
        history.push(`${location.pathname}/licensees?${queryString}`);
      } else {
        history.push(`${location.pathname}?${queryString}`);
      }
    }
  };

  const labels: any = props.onDashboard ? [{ vendor: '' }, { percent: '%' }] :
  [
      { vendor: '' },
      { percent: '%' },
      { units: 'Units' },
      { avgUnitSales: 'Avg Unit Sales' },
      { avgUnitRoyalties: 'Avg Unit Royalties' },
      { sales: 'Sales' },
      { royalties: 'Royalties' },
  ];

  if (props.large) {
    return (
      <div>
        <ChartHeader header="Licensees" subHeader="Sales by reported licensee." tableRef={tableRef}/>
        <ChartItemList
          listVisualStyle="none"
          items={list}
          itemLabels={labels}
          showLabels={true}
          rightAlignOffset={1}
          onClick={handleClick}
          overrideIndex={true}
          indexPage={props.page}
          ref={tableRef}
        />
      </div>
    );
  }

  return (
    <div>
      {
        props.onDashboard ?
          <ChartHeader header="Licensees" subHeader="Sales by reported licensee."/>
          : <ChartHeader header="Licensees" subHeader="Sales by reported licensee." tableRef={tableRef}/>
      }
      <ChartItemList
        listVisualStyle="none"
        items={list}
        itemLabels={labels}
        showLabels={!props.onDashboard}
        onClick={handleClick}
        ref={tableRef}
      />
      {props.vendors.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('licensees')} />}
    </div>
  );
};
