export interface BankInformation {

  routingNumber: string;
  accountNumber: string;
  accountHolderName: string;
  accountHolderType:  'company' | 'individual';

}
export class StripeService {
  stripe: any;

  constructor() {
    if (window['Stripe']) {
      this.stripe = window['Stripe'];
    }
  }

  generateBankToken(info: BankInformation, handler: (status: any, response: any) => void) {
    if (this.stripe) {
      this.stripe.bankAccount.createToken(
        {
          country: 'US',
          currency: 'USD',
          routing_number: info.routingNumber,
          account_number: info.accountNumber,
          account_holder_name: info.accountHolderName,
          account_holder_type: info.accountHolderType,
        },
        handler,
      );

    }
    return 'error';

  }
}
