import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { MarketplaceAdvertisement } from '../../../shared';
import { GatewayModal, LoadingSpinner, ModalType } from '../../shared';

interface IProps {
  site?: number;
  licensor?: number;
  shown: boolean;
  onClose: () => void;
  selectedAds: MarketplaceAdvertisement[];
  selectAd: (ad: MarketplaceAdvertisement) => void;

}

export const AvailableAdModal = (props: IProps) => {

  const [ads, setAds] = React.useState<MarketplaceAdvertisement[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [selected, setSelected] = React.useState<string | number>('');
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    getAds();
  },              [props.site, props.licensor]);
  React.useEffect(() => {
    setSelected('');

  },              [search]);

  const getAds = async () => {
    setLoading(true);
    const params = {
      showAll: true,
      licensor_id: props.licensor,
      site_id: props.site,
    };

    const cleaned = omitBy(params, isNil);
    const a = await Axios.get(`/api/marketplace/ads?${queryString.stringify(cleaned)}`);
    const list = a.data.data.map((a: any) => MarketplaceAdvertisement.fromApi(a));
    setAds(list);
    setLoading(false);

  };
  const adSelected = () => {
    const s = ads.find(a => a.id === selected);
    if (s) {
      props.selectAd(s);
      setSelected('');
    }
  };
  const close = () => {
    setSearch('');
    props.onClose();
  };

  const optionTitle = (ad: MarketplaceAdvertisement) => {
    const licensorName = ad.licensor && ad.licensor.shortName;
    const siteName = ad.site && ad.site.title;
    return `${ad.title} ${licensorName ? ` - ${licensorName}` : ''} ${siteName ? ` - ${siteName}` : ''}`;

  };

  const selectedIds = props.selectedAds.map(a => a.id);

  const options = ads.filter(a => !selectedIds.includes(a.id))
    .filter(a => a.title.includes(search))
    .map(a => <option key={a.id} value={a.id}>{optionTitle(a)} </option>);

  const body = loading ? (<LoadingSpinner />) : ads.length ? (
    <div style={{ display: 'flex' }} className="form-group">
      <select value={selected} onChange={e => setSelected(Number(e.target.value))} className="form-control">
        <option disabled  value={''}>Select Ad</option>
        {options}
      </select>
      <button style={{ marginLeft: 10 }} className="btn btn-primary" onClick={adSelected}>Select Ad</button>
    </div>
  //
  ) : (<p className="text-center text-muted"> No ads were found.</p>);

  return (
    <GatewayModal
      title="Available Ads"
      type={ModalType.Primary}
      shown={props.shown}
      onClose={close}
    >
      <Modal.Body>
        <div className="form-group">
          <input placeholder="Search Ads" value={search} onChange={e => setSearch(e.target.value)} type="text" className="form-control"/>
        </div>
        {body}

      </Modal.Body>
      <Modal.Footer>
        <button onClick={close} className="pull-right btn btn-default">Done</button>
      </Modal.Footer>
    </GatewayModal>
  );

};
