import Axios from 'axios';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Highlighter, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { UserContext } from '../../../contexts';
import { ProductCategory, VendorProduct, VendorProductApiResponse } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: any[];
  onChange: (accounts: ProductCategory[]) => void;
  multiple: boolean;
  showMarketingMaterials?: boolean | null;
}

type Status = LoadableStatuses<ProductCategory[], string>;

export const ProductCategoryTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });
  const [filterTo, setFilterTo] = React.useState<ProductCategory[]>([]);
  const ref = React.useRef<any>();
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    getCategories();
    if (user.type === 'vendor') {
      getVendorProducts();

    }
  },              []);

  const getVendorProducts = async () => {
    Axios.get(`/api/vendor-products?account_id=${user.account.id}`)
      .then((response) => {
        const products = response.data.data.map((p: VendorProductApiResponse) => VendorProduct.fromApi(p));
        const categories = products.map((p: VendorProduct) => p.category);
        setFilterTo(categories);
      });
  };

  const getCategories = async () => {
    setStatus({ state: 'loading' });
    const cat = await Axios.get('/api/product_categories?include_parents=1');
    const categories = cat.data.data.map((c: any) => ProductCategory.fromApi(c));
    const selected = [... props.selected];
    let shouldUpdate = false;
    selected.forEach((c, i) => {
      if (c.name === 'Loading') {
        shouldUpdate = true;
        const foundCat = categories.find((ca: ProductCategory) => Number(ca.id) === Number(c.id));
        if (foundCat) {
          selected[i].name = foundCat.name;
        }
      }
    });
    if (shouldUpdate) {
      props.onChange(selected);
    }
    const parents = orderBy(categories.filter((c: any) => c.isParent), c => ProductCategory.parentCategoryTree(c), 'asc');
    const children = orderBy(categories.filter((c: any) => !c.isParent), 'name', 'asc');
    const ordered: ProductCategory[] = [];
    const marketingMaterials = children.find(c => c.name.toLowerCase() === 'marketing materials');
    const showMarketingMaterials = typeof props.showMarketingMaterials !== 'undefined' ? props.showMarketingMaterials : true;

    parents.forEach((p) => {
      ordered.push(p);
      const child = children.filter(c => c.parent && c.parent.id === p.id);
      child.forEach(c => ordered.push(c));
    });
    if (marketingMaterials && showMarketingMaterials) {
      ordered.push(marketingMaterials);
    }
    setStatus({ content: ordered, state: 'success' });
  };

  const renderMenu = (categories: any[], menuProps: any) => {

    const items = categories.map((c, index) => {
      if (c.isParent) {
        return [
          index !== 0 && !c.parent ? <Menu.Divider key={`${c.id}-divider`} /> : undefined,
          (<Menu.Header key={`${c.id}-header`}>
            {ProductCategory.parentCategoryTree(c)}
          </Menu.Header>),
        ];
      }
      if (c.paginationOption) {
        return (
          <MenuItem key={index} option={c} position={index}>
            <div className="text-center">
              {c.label}
            </div>
          </MenuItem>
        );
      }

      return (
        <MenuItem key={index} option={c} position={index}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Highlighter search={menuProps.text}>
            {c.name}
            </Highlighter>
          </div>
        </MenuItem>
      );
    });
    return <Menu {...menuProps}>{items}</Menu>;

  };

  let options = status.state === 'success' ? status.content : [];
  if (filterTo.length) {
    const ids = filterTo.map(c => c.id);
    options = options.filter(o => ids.includes(o.id) || o.isParent || o.name.toLowerCase() === 'marketing materials');
    const c = options.filter(o => !o.isParent).map(o => o.parent && o.parent.id);
    options = options.filter(o => !o.isParent || c.includes(o.id));
  }
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      ref={ref}
      id={'product-category-typeahead'}
      placeholder={`Select Product ${props.multiple ? 'Categories' : 'Category'}`}
      options={options}
      selected={props.selected}
      onChange={(v) => {
        props.onChange(v);
        if (!v.length) {
          ref.current.focus();
          ref.current._showMenu();
        }
      }}
      multiple={props.multiple}
      paginate={true}
      isLoading={isLoading}
      maxResults={100}
      labelKey={(item: ProductCategory) => item.name}
      paginationText="Load More Categories"
      renderMenu={renderMenu}
      clearButton={true}

    />

  );

};
