import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { ACHPaymentMethod } from '../../shared';
import { CurrencyLabel, GatewayModal, ModalType } from '../shared';
import { ACHVerificationModal } from './ACHVerificationModal';

interface IProps {
  amountDue?: number | string;
  onSelect: () => void;
  onRemove?: () => void;
  achAccount: ACHPaymentMethod;
  onVerified: () => void;
  accountId: number;
  toggleModify: (id: number | null) => void;
}

export const ACHPaymentStub = (props: IProps) => {
  const ach = props.achAccount;
  const [verifying, setVerifying] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const [deleteShown, setDeleteShown] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [modalShown, setModalShown] = useState(false);

  useEffect(() => props.toggleModify(verifying ? props.achAccount.id : null), [verifying]);

  const handleVerification = async (values: { dep1: string, dep2: string }) => {
    setSaving(true);
    const formData = new FormData();
    formData.append('amount_1', `.${values.dep1}`);
    formData.append('amount_2', `.${values.dep2}`);
    const verification = await Axios.post(`/account/${props.accountId ? `${props.accountId}/` : ''}edit/verify-payment-method`, formData)
      .then((response) => {
        if (response.data.success) {
          return true;

        }
        setError(response.data.message);
        return false;

      });
    setSaving(false);
    if (verification) {
      props.onVerified();
    }

  };

  const onCancel = () => {
    setVerifying(false);
    props.toggleModify(null);
  };

  const closeDeleteModal = () => {
    setDeleteShown(false);
  };
  const showDeleteModal = () => {
    setDeleteShown(true);
  };
  const showModal = () => {
    setModalShown(true);
  };

  const closeModal = () => {
    setModalShown(false);
  };
  const confirmDelete = async () => {
    setDeleting(true);
    await Axios.delete(`/api/payment-methods/${props.achAccount.id}`);
    if (props.onRemove) {
      props.onRemove();
    }
    closeDeleteModal();
  };

  const enterVerification = () => {
    const soonest = moment(props.achAccount.verificationSentAt).add(1, 'day');
    const today = moment();
    if (today.isSameOrAfter(soonest)) {
      setVerifying(true);
    } else {
      alert('You must wait at least 24 hours before attempting to verify this account.');
    }
  };

  if (verifying) {
    const initialValues = {
      dep1: '',
      dep2: '',
    };
    const verifactionValidation = Yup.object().shape({
      dep1: Yup.number()
        .typeError('Micro deposit must be a number')
        .min(1, 'The micro deposit must be at least 1 cent.')
        .max(99, 'The micro deposit must be less than 99 cents.')
        .required('Micro deposit is required.'),
      dep2: Yup.number()
        .typeError('Micro deposit must be a number')
        .min(1, 'The micro deposit must be at least 1 cent.')
        .max(99, 'The micro deposit must be less than 99 cents.')
        .required('Micro deposit is required.'),
    });
    return (
      <div className="payment-method-panel">
        <div className="payment-option">
          <div className="payment-title-total">
            <span className={ach.isVerified ? 'text-primary' : 'text-danger'}>{ach.bankName} ({ach.lastFour})</span>
            {/* {props.amountDue ? <span><CurrencyLabel value={props.amountDue} /></span> : null} */}

          </div>
          <p className="payment-description ">We have transferred two micro desposits into your account.</p>
          <p className="payment-description ">
            Each micro deposit is between $0.01 - $0.99 (less than one dollar).
            </p>
          <img style={{ marginBottom: 15, maxWidth: 400 }} src="/assets/images/statement-image.png" className="img-responsive" />
          {error ? (
            <div className="alert alert-danger">
              {error}
            </div>
          ) : null}
          <Formik onSubmit={handleVerification} initialValues={initialValues} validationSchema={verifactionValidation}>
            {(props: FormikProps<any>) => (
              <Form className="form-horizontal">
                <div className="form-group">
                  <label style={{ textAlign: 'left' }} className="control-label col-sm-3">Deposit 1</label>
                  <div className="col-sm-4">
                    <div className="input-group">
                      <span className="input-group-addon">$0.</span>
                      <Field name="dep1" className="form-control" />
                    </div>
                    <span className="text-danger"><ErrorMessage name="dep1" /></span>
                  </div>

                </div>
                <div className="form-group">
                  <label style={{ textAlign: 'left' }} className="col-sm-3 control-label">Deposit 2</label>
                  <div className="col-sm-4">
                    <div className="input-group">
                      <span className="input-group-addon">$0.</span>
                      <Field name="dep2" className="form-control" />
                    </div>
                    <span className="text-danger"><ErrorMessage name="dep2" /></span>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <button type="button" onClick={onCancel} className="btn btn-block btn-default payment-method-button">
                    Cancel
                  </button>
                  <button style={{ marginLeft: 5 }} className="btn btn-block btn-primary payment-method-button">

                    {saving ? 'Verifying...' : 'Verify Deposits'}
                  </button>
                </div>
              </Form>
            )}

          </Formik>
        </div>

      </div>
    );

  }

  return (
    <div className="payment-method-panel">
      <div className="payment-option">
        <div className="payment-title-total">
          <span className={ach.isVerified ? 'text-primary' : 'text-danger'}>{ach.bankName} ({ach.lastFour})</span>
          {/* {props.amountDue ? <span><CurrencyLabel value={props.amountDue} /></span> : null} */}

        </div>
        <p className="payment-description">Payment is debited from your checking account.</p>
        <p className="payment-fee">NO PROCESSING FEE</p>
        {!ach.isVerified ? (<p style={{ marginBottom: 20 }} className="help-block">
          Verifying a checking account can take 2-4 business days to complete.
          Two “ACCTVERIFY” deposits will appear in your checking account.
          You will need to confirm the amounts before you can pay with this account. &nbsp;
            <span style={{ cursor: 'pointer' }} onClick={showModal} className="text-primary">
            <FontAwesomeIcon icon={faInfoCircle} /> Learn More
            </span>
        </p>) : null}
        {props.onRemove ? (
          <p className="text-muted">
            This checking account was added on {props.achAccount.createdAt.format('MM/DD/YYYY')}
          </p>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {props.onRemove ? (
            <button onClick={showDeleteModal} className="btn btn-block btn-default">
              Remove Payment Method
            </button>
          ) : null}
          {ach.isVerified ?
            props.amountDue ? (<button style={{ marginLeft: props.onRemove ? 5 : 0 }} onClick={props.onSelect} className="btn btn-block btn-primary payment-method-button">
              Pay <CurrencyLabel value={props.amountDue} /> with Bank Account ({ach.lastFour})
            </button>) : null :
            <button style={{ marginLeft: props.onRemove ? 5 : 0 }} onClick={enterVerification} className="btn btn-danger btn-block payment-method-button">
              Verify Bank Account ({ach.lastFour})
                </button>
          }
        </div>

      </div>
      <ACHVerificationModal shown={modalShown} onClose={closeModal} />

      <GatewayModal type={ModalType.Danger} shown={deleteShown} onClose={closeDeleteModal} title="Remove Payment Method" >
        <Modal.Body>
          Are you sure you want to delete this payment method?
          </Modal.Body>
        <Modal.Footer>
          <button onClick={closeDeleteModal} className="btn btn-default pull-left">
            Cancel
            </button>
          <button onClick={confirmDelete} className="btn btn-danger pull-right">
            {deleting ? 'Deleting...' : 'Yes, Delete Payment Method'}
          </button>
        </Modal.Footer>

      </GatewayModal>

    </div>
  );

};
