import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserContext } from '../../contexts';
import { VendorRoyaltyMinimumsPage } from './VendorRoyaltyMinimumsPage';

type IProps = RouteComponentProps<any>;

class VendorRoyaltyMinimums extends React.Component<IProps, any> {
  render() {
    return (
      <UserContext.Consumer>
        { (user) => {
          if (user.type === 'vendor') {
            return <VendorRoyaltyMinimumsPage vendorId={user.account.id}/>;
          }

          return <VendorRoyaltyMinimumsPage vendorId={this.props.match.params.vendorId}/>;
        }}
      </UserContext.Consumer>
    );
  }
}

const component = withRouter(VendorRoyaltyMinimums);
export { component as VendorRoyaltyMinimums };
