import { faFileDownload, faShoppingCart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getMarketplaceProducts } from '../../../api';
import { UserContext } from '../../../contexts';
import { AffinityClient, Filters, MarketplaceProduct, MarketplaceProductApiResponse, MarketplaceTag } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FilterBar, GatewayModal, IReviewItemStatus, LicensorTypeahead, LoadingSpinner, MarketplaceTagTypeahead, ModalType, PaginationFooter, ReviewGridItem  } from '../../shared';

type SortOrder = 'price_asc' | 'price_desc' | 'recent' | 'oldest' | 'featured';
type NullableSortOrder = SortOrder | null;

interface IState {
  products: MarketplaceProduct[];
  totalProducts: number;
  totalPages: number;
  hasNext: boolean;
  loading: boolean;
  filters: Filters;
  isPublished: number;
  isPendingReview: number;
  isArchived: number;
  sortOrder: NullableSortOrder;
  market: string;
  advancedSearch: boolean;
  tags: MarketplaceTag[];
}

interface PageFilters {
  keyword: string;
  page: number;
  per_page: number;
  is_approved: number | null;
  is_pending_review: number | null;
  is_archived: number | null;
  vendor_id: number | null;
  licensor_id: number | null;
  order_by: NullableSortOrder;
  market: string | null;
  tag_ids: string | null;
}

export class ProductIndexPage extends React.Component <RouteComponentProps<any>, IState> {

  private _getProductsSource: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    const currentParams = queryString.parse(props.location.search);
    const filters = new Filters();
    filters.page = currentParams.page ? Number(currentParams.page) : 1;
    filters.search = currentParams.keyword ? currentParams.keyword : '';
    let isPublished = 0;
    if (currentParams.is_published !== undefined) {
      if (Number(currentParams.is_published) === 1) {
        isPublished = 1;
      } else {
        isPublished = 2;
      }
    }
    let isPendingReview = 0;
    if (currentParams.is_pending_review !== undefined) {
      if (Number(currentParams.is_pending_review) === 1) {
        isPendingReview = 1;
      } else {
        isPendingReview = 2;
      }
    }
    let isArchived = 2;
    if (currentParams.is_archived !== undefined) {
      if (Number(currentParams.is_archived) === 1) {
        isArchived = 1;
      } else {
        isArchived = 2;
      }
    }
    let tags: MarketplaceTag[] = [];
    if (currentParams.tags !== undefined) {
      tags = currentParams.tags.split(',').map((t: any) => {
        return { id: t, name: 'Loading', keywords: '' };
      });
    }
    if (currentParams.licensor_id !== undefined) {
      filters.licensorId = currentParams.licensor_id;
    }

    const sortOrder = (currentParams.order_by) ? (currentParams.order_by as SortOrder) : null;

    this.state = {
      isPublished,
      isPendingReview,
      isArchived,
      filters,
      sortOrder,
      tags,
      products: [],
      totalProducts: 0,
      totalPages: 0,
      hasNext: true,
      loading: true,
      market: currentParams.market ? currentParams.market : '',
      advancedSearch: false,
    };

    this.getProducts = this.getProducts.bind(this);
    this.createFilters = this.createFilters.bind(this);
    this.setPublished = this.setPublished.bind(this);
    this.setPendingReview = this.setPendingReview.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.setAdvancedFilters = this.setAdvancedFilters.bind(this);
    this.setPage = this.setPage.bind(this);
    this.search = this.search.bind(this);
  }

  getProducts(params: any) {
    this.setState({ products: [], loading: true });
    if (this._getProductsSource) {
      this._getProductsSource.cancel('Cancelled getProducts() XHR due to new request.');
    }
    this._getProductsSource = Axios.CancelToken.source();

    getMarketplaceProducts(params, this._getProductsSource)
      .then((response) => {
        const pagination = response.data.meta.pagination;
        const products = response.data.data.map((product: MarketplaceProductApiResponse) =>
          MarketplaceProduct.fromApi(product));
        const filters = this.state.filters;
        this.setState({
          filters,
          products,
          loading: false,
          totalProducts: pagination.total,
          totalPages: pagination.total_pages,
          hasNext: filters.page < pagination.total_pages,
        });
      });
  }

  componentDidMount() {
    this.getProducts(this.createFilters());
  }

  productStatus(product: MarketplaceProduct): IReviewItemStatus {
    const status: IReviewItemStatus = { colorClass: '', label: '' };
    if (product.isPendingReview) {
      status.colorClass = 'text-primary';
      status.label = 'Pending Review';
    } else if (product.isApproved) {
      status.colorClass = 'text-success';
      status.label = 'Published';
    } else {
      status.colorClass = 'text-danger';
      status.label = 'Not Published';
    }
    return status;

  }

  createFilters() {
    let pendingReview;
    switch (this.state.isPendingReview) {
      case 0:
        pendingReview = null;
        break;
      case 1:
        pendingReview = 1;
        break;
      case 2:
        pendingReview = 0;
        break;
      default:
        pendingReview = null;
    }
    let published;
    switch (this.state.isPublished) {
      case 0:
        published = null;
        break;
      case 1:
        published = 1;
        break;
      case 2:
        published = 0;
        break;
      default:
        published = null;
    }
    let archived;
    switch (this.state.isArchived) {
      case 0:
        archived = null;
        break;
      case 1:
        archived = 1;
        break;
      case 2:
        archived = 0;
        break;
      default:
        archived = null;
    }

    let licensorId = null;
    if (this.props.match.params['licensorId']) {
      licensorId = this.props.match.params['licensorId'];
    }
    if (this.state.filters.licensorId) {
      licensorId = this.state.filters.licensorId;
    }

    return {
      vendor_id: this.props.match.params['vendorId'] ? this.props.match.params['vendorId'] : null,
      licensor_id: licensorId,
      keyword: this.state.filters.search,
      page: this.state.filters.page,
      per_page: 24,
      is_approved: published,
      is_pending_review: pendingReview,
      is_archived: archived,
      order_by: this.state.sortOrder,
      market: this.state.market ? this.state.market : null,
      tag_ids: this.state.tags ? this.state.tags.map(t => t.id).join(',') : null,
    };
  }

  setPublished(isPublished: number) {
    const filters = this.state.filters;
    filters.page = 1;
    this.setState({ filters, isPublished });
    const params = this.createFilters();
    let published;
    switch (isPublished) {
      case 0:
        published = null;
        break;
      case 1:
        published = 1;
        break;
      case 2:
        published = 0;
        break;
      default:
        published = null;
    }
    params.is_approved = published;
    params.page = 1;
    this.setQueryParams(params);
    this.getProducts(params);
  }

  setPendingReview(isPendingReview: number) {
    const filters = this.state.filters;
    filters.page = 1;
    this.setState({ filters, isPendingReview });
    const params = this.createFilters();
    let pendingReview;
    switch (isPendingReview) {
      case 0:
        pendingReview = null;
        break;
      case 1:
        pendingReview = 1;
        break;
      case 2:
        pendingReview = 0;
        break;
      default:
        pendingReview = null;
    }
    params.is_pending_review = pendingReview;
    params.page = 1;
    this.setQueryParams(params);
    this.getProducts(params);

  }
  setArchived(isArchived: number) {
    const filters = this.state.filters;
    filters.page = 1;
    this.setState({ filters, isArchived });
    const params = this.createFilters();
    let archived;
    switch (isArchived) {
      case 0:
        archived = null;
        break;
      case 1:
        archived = 1;
        break;
      case 2:
        archived = 0;
        break;
      default:
        archived = null;
    }
    params.is_archived = archived;
    params.page = 1;
    this.setQueryParams(params);
    this.getProducts(params);
  }

  setSortOrder(sort: string) {
    const filters = this.state.filters;
    filters.page = 1;

    const sortOrder = (sort === '') ? null : (sort as SortOrder);
    this.setState({ filters, sortOrder });
    const params = this.createFilters();
    params.order_by = sortOrder;
    params.page = 1;
    this.setQueryParams(params);
    this.getProducts(params);
  }

  setMarket(market: string) {
    const filters = this.state.filters;
    filters.page = 1;

    this.setState({ filters, market });
    const params = this.createFilters();
    params.market = market;
    params.page = 1;
    this.setQueryParams(params);
    this.getProducts(params);
  }

  setPage(page: number) {
    const filters = this.state.filters;
    filters.page = page;
    this.setState({ filters });
    const params = this.createFilters();
    params.page = page;
    this.setQueryParams(params);
    this.getProducts(params);
  }

  setAdvancedFilters(values: any) {
    const filters = this.state.filters;
    if (values.licensor.length) {
      filters.licensorId = values.licensor[0].id;
    } else {
      filters.setFilters({ licensorId: null });
    }
    this.setState({ filters, tags: values.tags });
    const params = this.createFilters();
    params.tag_ids = values.tags.map((t: MarketplaceTag) => t.id).join(',');
    if (values.licensor.length) {
      params.licensor_id = values.licensor[0].id;
    } else {
      params.licensor_id = this.props.match.params['licensorId'] ? this.props.match.params['licensorId'] : null;
    }

    this.setQueryParams(params, values.licensor.length > 0);
    this.getProducts(params);
    this.setState({ advancedSearch: false });
  }

  search(values: any) {
    const filters = this.state.filters;
    if (values.search !== undefined) {
      filters.search  =  values.search;
      this.setState({ filters });
    } else {
      filters.page = 1;
      this.setState({ filters });
      const params = this.createFilters();
      params.page = 1;
      this.setQueryParams(params);
      this.getProducts(params);
    }
  }

  generateQueryParamString(params: PageFilters, includeAccount: boolean) {
    const queryParams = {
      licensor_id: includeAccount ? params.licensor_id : null,
      vendor_id: includeAccount ? params.vendor_id : null,
      keyword: params.keyword.length ? params.keyword : null,
      page: params.page > 1 ? params.page : null,
      is_published: params.is_approved,
      is_pending_review: params.is_pending_review,
      is_archived: params.is_archived,
      order_by: params.order_by,
      market: params.market ? params.market : null,
      tags: params.tag_ids ? params.tag_ids : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  setQueryParams(params: PageFilters, includeAccount?: boolean) {
    const qs = this.generateQueryParamString(params, includeAccount ? includeAccount : false);
    this.props.history.replace(`${this.props.location.pathname}?${qs}`);
  }

  render() {
    const qs = this.generateQueryParamString(this.createFilters(), true);
    const products = this.state.products.map(product =>
      (
        <div key={product.id} className="col-xl-2 col-lg-3 col-md-4 col-sm-4">
          <ReviewGridItem
            status={this.productStatus(product)}
            url={`/marketplace/products/${product.id}?${qs}`}
            image={product.getImage('sm')}
          >
            <div style={{ paddingTop: 0, textAlign: 'center' }} className="panel-body">
              <div style={{ fontSize: 12 }} className=" text-ellipsis text-primary">
                {product.organization.shortName}
              </div>
              <div style={{ fontSize: 14,  fontWeight: 'bold' }} className=" text-primary text-ellipsis">{product.name}</div>

              <div style={{ fontSize: 12 }} className=" text-ellipsis">
                by {product.vendor.shortName}
              </div>
              <div style={{ fontSize: 14, fontWeight: 'bold' }} className=" text-ellipsis">
                {product.isOnSale ? 'On Sale, ' : ''}${product.price}
              </div>

            </div>
          </ReviewGridItem>
        </div>
      )
      ,
    );

    const body = this.state.loading ? (<LoadingSpinner />) : (
      <div className="row">
        {products}
      </div>
    );
    const isVendorRoute = this.props.match.params['vendorId'] ? true : false;
    // tslint:disable-next-line:max-line-length
    const pdfURL = 'https://7098d72a642d89bae0b9-2629df26037d9444813c402ef2e32b60.ssl.cf1.rackcdn.com/Affinity-Marketplace-Guide.pdf';

    return (
      <FullContent>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <h3 style={{ margin: 0 }}>Products</h3>
          <a href={pdfURL} target="_blank">
            <FontAwesomeIcon style={{ marginRight: 5 }} icon={faFileDownload} />Marketplace Guide Download
          </a>
        </div>
        <div className="panel panel-portal">
          <div
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
            className="panel-body"
          >
            <FontAwesomeIcon style={{ fontSize: 32, marginBottom: 10 }} className="text-primary" icon={faShoppingCart} />
            <br />
            <br />
            <p className="text-center">
              <strong>
                Sell on the Marketplace by adding individual product listings.
                <br />
                Customers can order these items from Marketplace websites and apps.
              </strong>
              <br />
              <br />
              Product listings are buyable through the Marketplace checkout process.
              <br />
              Please manage your listings and be prepared to promptly fulfill customer orders.
            </p>
            <UserContext.Consumer>
              {user => (user.type === 'admin' && isVendorRoute) || user.type === 'vendor' ?
                (
                  <Link
                    style={{ marginTop: 10 }}
                    to={this.addProductLink}
                    className="btn btn-primary "
                  >
                    Add Product
                  </Link>
                )
                : null}

            </UserContext.Consumer>

          </div>

        </div>
        <div style={{ paddingTop: 20, paddingBottom: 20 }}>

            <FilterBar
              useSearch={true}
              search={this.state.filters.search}
              usePerPage={false}
              useQuarter={false}
              useAdvancedSearch={true}
              advancedSearchOnClick={() => this.setState({ advancedSearch: true })}
              updateFilters={this.search}
              fieldClassOverrides={{ search: 'col-sm-2 col-md-2 col-lg-2 col-xs-12' }}
            >
              <div className="col-lg-2 col-sm-2 col-xs-12">
                <select
                  onChange={event => this.setPublished(Number(event.target.value))}
                  value={this.state.isPublished}
                  className="form-control input-sm"
                >
                  <option value={0}>Publish Filter (All)</option>
                  <option value={1}>Published</option>
                  <option value={2}>Not Published</option>
                </select>

              </div>
              <div className="col-lg-2 col-sm-2 col-xs-12">
                <select
                  onChange={event => this.setPendingReview(Number(event.target.value))}
                  value={this.state.isPendingReview}
                  className="form-control input-sm"
                >
                  <option value={0}>Review Filter (All)</option>
                  <option value={1}>Pending Review</option>
                  <option value={2}>Reviewed</option>
                </select>
              </div>
              <div className="col-lg-2 col-sm-2 col-xs-12">
                <select
                  onChange={event => this.setArchived(Number(event.target.value))}
                  value={this.state.isArchived}
                  className="form-control input-sm"
                >
                  <option value={0}>Archived Filter (All)</option>
                  <option value={1}>Archived</option>
                  <option value={2}>Not Archived</option>
                </select>
              </div>
              <div className="col-lg-2 col-sm-2 col-xs-12">
                <select
                  onChange={event => this.setSortOrder(event.target.value)}
                  value={this.state.sortOrder === null ? '' : this.state.sortOrder}
                  className="form-control input-sm"
                >
                  <option value="">Sort Order (Default)</option>
                  <option value="recent">Most Recent</option>
                  <option value="oldest">Oldest</option>
                  <option value="featured">Featured</option>
                  <option value="price_asc">Price Ascending</option>
                  <option value="price_desc">Price Descending</option>
                </select>
              </div>
            <UserContext.Consumer>
              {user => user.type === 'admin' && !this.isClientFiltered && !this.isVendorFiltered ? (
                <div className="col-lg-2 col-sm-2 col-xs-12">
                  <select
                    onChange={event => this.setMarket(event.target.value)}
                    value={this.state.market}
                    className="form-control input-sm"
                  >
                    <option value="">Market Filter (All)</option>
                    <option value="1">Greek</option>
                    <option value="2">College</option>
                    <option value="3">Associations</option>
                    <option value="5">Charitable</option>
                  </select>

                </div>

              ) : null}
            </UserContext.Consumer>
            </FilterBar>
          </div>

        {body}

        { !this.state.loading ?
          <PaginationFooter
            totalResults={this.state.totalProducts}
            showFirstLast={true}
            totalPages={this.state.totalPages}
            currentPage={this.state.filters.page}
            hasNext={this.state.hasNext}
            setPage={this.setPage}
          />
          : null}
        <GatewayModal
          type={ModalType.Primary}
          shown={this.state.advancedSearch}
          onClose={() => this.setState({ advancedSearch: false })}
          title="Search"
        >
          <Formik onSubmit={this.setAdvancedFilters}
            initialValues={{
              licensor: this.state.filters.licensorId ? [new AffinityClient({ id: this.state.filters.licensorId, short_name: 'Loading' })] : [],
              tags: this.state.tags,
            }}
          >
            {(formProps: FormikProps<any>) => (
              <Form>
                <Modal.Body>
                  <div className="form-group">
                    <label>Licensor</label>
                    <LicensorTypeahead
                      selected={formProps.values.licensor}
                      onChange={v => formProps.setFieldValue('licensor', v)}
                      multiple={false}
                    />
                  </div>
                  <UserContext.Consumer>
                    {user => user.type === 'admin' ? (

                      <div className="form-group">
                        <label>Tags</label>
                        <MarketplaceTagTypeahead
                          selected={formProps.values.tags}
                          onChange={v => formProps.setFieldValue('tags', v)}
                        />
                      </div>) : null}
                  </UserContext.Consumer>
                </Modal.Body>
                <Modal.Footer>
                  <button type="button" onClick={() => this.setState({ advancedSearch: false })} className="btn btn-default pull-left">
                    Cancel
                  </button>
                  <button className="btn btn-primary pull-right">
                    Search
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </GatewayModal>

      </FullContent>
    );
  }

  get addProductLink() {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.props.match.params['vendorId']}/marketplace/products/add`;
    }
    return '/marketplace/products/add';
  }

  get isClientFiltered() {
    return this.props.match.params['licensorId'] ? true : false;
  }
  get isVendorFiltered() {
    return this.props.match.params['vendorId'] ? true : false;
  }
}
