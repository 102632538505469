import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { IPostApiResponse, MarketplacePost } from '../../../shared';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { FilterBar, LoadingSpinner, PaginationFooter } from '../../shared';

export const PostsIndexPage = () => {
  const history = useHistory();
  const location = useLocation();
  const locationParams = queryString.parse(location.search);
  const [loading, setLoading] = React.useState(true);
  const [posts, setPosts] = React.useState<MarketplacePost[]>([]);
  const [perPage, setPerPage] = React.useState(locationParams.per_page ? Number(locationParams.per_page) : 24);
  const [keyword, setKeyword] = React.useState(locationParams.keyword ? locationParams.keyword : '');
  const user = React.useContext(UserContext);
  if (user.type !== 'admin') {
    return <Redirect to="/" />;

  }

  React.useEffect(() => {
    getPosts();
    setQueryParams();
  },              [perPage]);

  React.useEffect(() => {
    getPosts();
  },              []);

  const getPosts = async () => {

    setLoading(true);
    const params = {
      per_page: perPage === 24 ? null : perPage,
      keyword: keyword ? keyword : null,
    };

    const cleaned = omitBy(params, isNil);
    console.log(cleaned);
    const p = await Axios.get(`/api/marketplace/posts?${queryString.stringify(cleaned)}`, { data: cleaned });
    const list = p.data.data.map((i: IPostApiResponse) => MarketplacePost.fromApi(i));
    setPosts(list);
    setLoading(false);

  };

  const updateFilters = (values: any, reload: boolean) => {
    if (values.perPage) {
      setPerPage(values.perPage);
    } else {
      if (values.search !== undefined) {
        setKeyword(values.search);
      }
      if (reload) {
        getPosts();
        setQueryParams();
      }
    }

  };

  const setQueryParams = () => {
    const params = {
      per_page: perPage === 24 ? null : perPage,
      keyword: keyword ? keyword : null,
    };
    const cleaned = omitBy(params, isNil);
    history.push(`/marketplace/posts?${queryString.stringify(cleaned)}`);
  };

  const rows = posts.map(p => (<tr>
    <td><Link to={`/marketplace/posts/${p.id}`}>{p.title}</Link></td>
    <td>{p.shortDescription}</td>
    <td>{p.site && p.site.title}</td>
  </tr>));

  const body = loading ? (<LoadingSpinner />) : posts.length ?  (
    <div className="table-responsive">
      <table className="table table-portal table-hover">
        <thead>
          <tr>
            <th>
              Title
            </th>
            <th>
              Short Description
            </th>
            <th>
              Site
            </th>
          </tr>
        </thead>
        {rows}

      </table>

    </div>

  ) : <p className="text-center text-muted">No posts were found.</p>;
  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Marketplace Posts')} />
      <div style={{ flexDirection: 'row-reverse' }} className="row flex-row button-row">
        <div className="panel panel-portal button-panel">
          <div className="panel-body">
            <Link to="/marketplace/posts/new" className="btn btn-primary">Add Post</Link>
          </div>
        </div>
      </div>

      <div className="panel panel-portal button-panel">
        <div className="panel-body">
          <FilterBar
            search={keyword}
            perPage={perPage}
            usePerPage={true}
            useQuarter={false}
            useSearch={true}
            useDates={false}
            updateFilters={updateFilters}
          />
        </div>
      </div>

      <div className="panel panel-portal">
        <div className="panel-body">
          {body}

        </div>
      </div>

      <PaginationFooter setPage={console.log} />

    </FullContent>
  );
};
