import * as React from 'react';
import { getSalesText } from '../../../utils';

interface IQuarterRangeCell {
  year: number;
  quarter: number;
}

const QuarterRangeCell = (props: IQuarterRangeCell) => {

  if (!props.year || !props.quarter) {
    return null;
  }

  const yearStr = props.year.toString(10);

  const endYear = (parseInt(yearStr, 10) + 1).toString(10);
  const shortEndYear = endYear.substr(2);

  const dateRangeText = getSalesText(props.quarter, props.year);

  return (
    <div className="quarter-range">
      <div className="quarter-range-main" style={{ fontWeight: 'bold' }}>{dateRangeText}</div>
      <div className="quarter-range-sub">Q{props.quarter} {yearStr}-{shortEndYear} FY</div>
    </div>
  );
};

export default QuarterRangeCell;
