import { CSSProperties, useMemo } from 'react';
import * as React from 'react';
import { DropzoneProps, DropzoneState, useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#444444',
  outline: 'none',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out',
};

const defaultActiveStyle = {
  borderColor: '#2196f3',
};

type IProps = Omit<DropzoneProps, 'children'> & {
  style?: CSSProperties;
  activeStyle?: CSSProperties;
  children?: JSX.Element;
};

// tslint:disable-next-line:function-name
export function AffinityDropzone(props: IProps) {

  const baseDropzoneProps = Object.assign({}, props, {
    children: ((state: DropzoneState) => { return props.children; }),
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone(baseDropzoneProps);

  const activeStyle = props.activeStyle ? props.activeStyle : defaultActiveStyle;

  const style = useMemo(() => ({
    ...baseStyle,
    ...props.style,
    ...(isDragActive ? activeStyle : {}),
  }),                   [
    isDragActive,
    props.style,
  ]);

  const body = useMemo(() => {
    if (props.children) {
      return props.children;
    }
    return <p>Drop some files here, or click to select files</p>;
  },                   [props.children]);

  return (
    <div {...getRootProps()} style={style}>
      <input {...getInputProps()} />
      {body}
    </div>
  );
}
