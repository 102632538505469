import { orderBy } from 'lodash';
import { GeoCityStats, GeoStateStats } from '.';

export interface PerformanceStats {
  previousUnits: number;
  previousRoyalties: number;
  previousSales: number;
  currentUnits: number;
  currentRoyalties: number;
  currentSales: number;
  categories: any[];
  channels: any[];
  vendors: any[];
  retailers: any[];
  states: any[];
  cities: any[];
  revenueOverTime: {
    current: any[],
    previous: any[],
  };
  unitsOverTime: {
    current: any[],
    previous: any[],
  };
  avgUnitSalesOverTime: {
    current: any[],
    previous: any[],
  };
  avgUnitRoyaltiesOverTime: {
    current: any[],
    previous: any[],
  };
  isAggregate: boolean;
  isCurrent: boolean;
  geoDisplayable: boolean;
  salesDataDisplayable: boolean;
  historicalData: any[];
  totals: {
    current: {
      sales: number;
      royalties: number;
      units: number;
      avgUnitSales: number;
      avgUnitRoyalties: number;
    };
    previous: {
      sales: number;
      royalties: number;
      units: number;
      avgUnitSales: number;
      avgUnitRoyalties: number;
    };
  };
}

export const DefaultPerformanceStats: PerformanceStats = {
  previousUnits: 0,
  previousRoyalties: 0,
  previousSales: 0,
  currentUnits: 0,
  currentRoyalties: 0,
  currentSales: 0,
  geoDisplayable: true,
  salesDataDisplayable: true,
  categories: [],
  channels: [],
  vendors: [],
  retailers: [],
  states: [],
  cities: [],
  revenueOverTime: {
    current: [],
    previous: [],
  },
  unitsOverTime: {
    current: [],
    previous: [],
  },
  avgUnitSalesOverTime: {
    current: [],
    previous: [],
  },
  avgUnitRoyaltiesOverTime: {
    current: [],
    previous: [],
  },
  isAggregate: false,
  isCurrent: false,
  historicalData: [],
  totals: {
    current: {
      sales: 0,
      royalties: 0,
      units: 0,
      avgUnitSales: 0,
      avgUnitRoyalties: 0,
    },
    previous: {
      sales: 0,
      royalties: 0,
      units: 0,
      avgUnitSales: 0,
      avgUnitRoyalties: 0,
    },
  },
};

export interface VendorRetailerStats {
  name: string;
  id: number;
  image: string;
  amount: number;
  previousAmount: number;
  percent: number;
  rpu: number;
  units: number;
  sales: number;
}

export const apiToPerformanceStats = (data: any): PerformanceStats => {
  const channels = orderBy(data.channels.map((channelItem: any) => {
    return {
      channel: { id: channelItem.id, name: channelItem.title },
      units: channelItem.quantity,
      royalties: Number(channelItem.amount),
      sales: Number(channelItem.gross_sales),
      percent: channelItem.percent * 100,
      averageRPU: Number(channelItem.rpu),
    };
  }),                      ['royalties'], ['desc']);

  const categories = orderBy(data.categories.map((categoryItem: any) => {
    return {
      category: {
        id: categoryItem.id,
        name: categoryItem.title,
      },
      averageRPU: categoryItem.rpu,
      sales: Number(categoryItem.gross_sales),
      units: categoryItem.quantity,
      royalties: Number(categoryItem.amount),
      percent: categoryItem.percent * 100,
    };
  }),                        ['royalties'], ['desc']);

  const states: GeoStateStats[] = orderBy(data.states.map((state: any) => {
    return {
      state: state.title,
      fullState: state.full_state,
      amount: Number(state.amount),
      sales: Number(state.gross_sales),
      percent: state.percent * 100,
      rpu: Number(state.rpu),
      units: Number(state.quantity),
    };
  }),                                     ['amount'], ['desc']);

  const cities: GeoCityStats[] = orderBy(data.cities.map((city: any) => {
    return {
      city: city.title,
      amount: Number(city.amount),
      sales: Number(city.gross_sales),
      percent: city.percent * 100,
      rpu: Number(city.rpu),
      units: Number(city.quantity),
    };
  }),                                    ['amount'], ['desc']);

  const vendors: VendorRetailerStats[] = orderBy(data.licensees.map((v: any) => {
    return {
      name: v.title,
      id: v.id,
      image: v.image,
      amount: Number(v.amount),
      previousAmount: v.previous ? Number(v.previous.amount) : 0,
      percent: v.percent * 100,
      rpu: Number(v.rpu),
      units: Number(v.quantity),
      sales: Number(v.gross_sales),
    };
  }),                                            ['amount'], ['desc']);

  const retailers: VendorRetailerStats[] = orderBy(data.retailers.map((v: any) => {
    return {
      name: v.title,
      id: v.id,
      image: v.image,
      amount: Number(v.amount),
      previousAmount: v.previous ? Number(v.previous.amount) : 0,
      percent: v.percent * 100,
      rpu: Number(v.rpu),
      units: Number(v.quantity),
      sales: Number(v.gross_sales),
    };
  }),                                              ['amount'], ['desc']);

  const revenueOverTime = data.revenue_over_time ? data.revenue_over_time : { previous: [], current: [] };
  revenueOverTime.previous = revenueOverTime.previous.map((p: any) => {
    return {
      month: p.month,
      royalties: Number(p.royalties),
      grossSales: Number(p.gross_sales),
      units: Number(p.units),
      avgUnitSales: Number(p.avg_unit_sales),
      avgUnitRoyalties: Number(p.avg_unit_royalties),
    };
  });
  revenueOverTime.current = revenueOverTime.current.map((c: any) => {
    return {
      month: c.month,
      royalties: Number(c.royalties),
      grossSales: Number(c.gross_sales),
      units: Number(c.units),
      avgUnitSales: Number(c.avg_unit_sales),
      avgUnitRoyalties: Number(c.avg_unit_royalties),
    };
  });

  const unitsOverTime = {
    previous: revenueOverTime.previous.map((p: any) => ({
      month: p.month,
      units: p.units,
    })),
    current: revenueOverTime.current.map((c: any) => ({
      month: c.month,
      units: c.units,
    })),
  };

  const avgUnitSalesOverTime = {
    previous: revenueOverTime.previous.map((p: any) => ({
      month: p.month,
      avgUnitSales: p.avgUnitSales,
    })),
    current: revenueOverTime.current.map((c: any) => ({
      month: c.month,
      avgUnitSales: c.avgUnitSales,
    })),
  };

  const avgUnitRoyaltiesOverTime = {
    previous: revenueOverTime.previous.map((p: any) => ({
      month: p.month,
      avgUnitRoyalties: p.avgUnitRoyalties,
    })),
    current: revenueOverTime.current.map((c: any) => ({
      month: c.month,
      avgUnitRoyalties: c.avgUnitRoyalties,
    })),
  };

  const historicalData = data.historical_data ? data.historical_data.map((d: any) => {
    return { year: d.year, royalties: Number(d.royalties.toFixed(2)), grossSales: Number(d.gross_sales.toFixed(2)) };
  }) : [];

  return {
    categories,
    channels,
    states,
    cities,
    vendors,
    retailers,
    revenueOverTime,
    unitsOverTime,
    avgUnitSalesOverTime,
    avgUnitRoyaltiesOverTime,
    historicalData,
    previousUnits: data.totals.yoy_total_units,
    previousRoyalties: data.totals.yoy_total_royalties,
    previousSales: data.totals.yoy_total_sales,
    currentUnits: data.totals.total_units,
    currentRoyalties: data.totals.total_royalties,
    currentSales: data.totals.total_sales,
    isAggregate: data.is_aggregate,
    isCurrent: data.is_current,
    salesDataDisplayable: data.displayable.includes('nongeo'),
    geoDisplayable: data.displayable.includes('geo'),
    totals: {
      current: {
        sales: data.totals.total_sales,
        royalties: data.totals.total_royalties,
        units: data.totals.total_units,
        avgUnitSales: data.totals.avg_unit_sales,
        avgUnitRoyalties: data.totals.avg_unit_royalties,
      },
      previous: {
        sales: data.totals.yoy_total_sales,
        royalties: data.totals.yoy_total_royalties,
        units: data.totals.yoy_total_units,
        avgUnitSales: data.totals.yoy_avg_unit_sales,
        avgUnitRoyalties: data.totals.yoy_avg_unit_royalties,
      },
    },
  };
};
