import { faFolderTree, faImages, faLock, faMapMarkerAlt, faPassport, faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Redirect, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { AccountAddress, AccountProfile } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FormImageWithPreview, GatewayModal, LoadingSpinner, ModalType, SettingsPanel } from '../../shared';

export const SettingsIndexPage = () => {
  const match = useRouteMatch();
  const user = React.useContext(UserContext);
  const [account, setAccount] = React.useState<AccountProfile | null>(null);
  const [addresses, setAddresses] = React.useState<AccountAddress[]>([]);
  const [addressCount, setAddressCount] = React.useState(0);
  const [phoneModalShown, setPhoneModalShown] = React.useState(false);
  const [accountEditModalShown, setAccountEditModalShown] = React.useState(false);
  const [updatingImages, setUpdatingImages] = React.useState(false);
  if (!match) {
    return (<Redirect to="/" />);
  }

  React.useEffect(() => {
    getAccount();
    getAddresses();

  },              []);

  const accountId = () => {

    if (match.params['vendorId']) {
      return match.params['vendorId'];
    }
    if (match.params['licensorId']) {
      return match.params['licensorId'];
    }
    return user.account.id;
  };

  const getAccount = async () => {
    const a = await Axios.get(`/api/accounts/${accountId()}`);
    setAccount(AccountProfile.fromApi(a.data.data));
  };

  const getAddresses = async () => {
    const params = {
      account_id: accountId(),
    };
    const a = await Axios.get('/api/account-addresses', { params: user.type === 'admin' ? params : undefined });
    setAddresses(a.data.data.map((a: any) => AccountAddress.fromApi(a)));
    setAddressCount(a.data.meta.pagination.total_count);
  };

  const settingsContentFormatter = (title: string, value: string) => {
    return (
        <div style={{ paddingBottom: 20 }}>
            <p className="text-muted no-margin">
                {title}
            </p>
            <p className="no-margin">
                {value}
            </p>
        </div>
    );
  };

  if (!account) {
    return <LoadingSpinner />;
  }

  const primaryAddress = addresses.find(i => i.isBusiness);

  return  (
        <FullContent>
            <SettingsPanel title="General Information" icon={faPassport} action={user.type === 'admin' ? <a onClick={() => setAccountEditModalShown(true)}>Edit</a> : null} >
                <div className="row">
                    <div className="col-md-6">
                        {settingsContentFormatter('Legal Business Name', account.legalName)}
                    </div>
                    <div className="col-md-6">
                        {settingsContentFormatter('Account Name', account.accountName)}
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-12">
                        {settingsContentFormatter('Description', account.description ? account.description : '-')}
                    </div>

                </div>

                <p className="text-muted small">
                    <FontAwesomeIcon icon={faLock} /> This information is managed by Affinity. If you would like to request an update, please contact us and describe the requested changes.
                </p>

            </SettingsPanel>
            {user.type === 'admin' ? <SettingsPanel title="Also Known As" subitle="List relevant alternative, subsidiary, or DBA names." icon={faFolderTree} action={(<Link to={'settings/alias'}>Edit</Link>)} >
                <div className="row" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {account.aliases.map(a => (
                        <div className="col-md-4" style={{ display: 'flex', alignItems: 'center', paddingBottom: 20 }}>
                            <div style={{ backgroundColor: '#F3F3F3', borderRadius: 4, padding: 10, height: 40, width: 40, display: 'flex', alignItems: 'center', marginRight: 20 }}>
                                <FontAwesomeIcon className="text-muted" icon={faFolderTree} />
                            </div>
                            <span>{a.name}</span>
                        </div>
                    ))}
                </div>

            </SettingsPanel> : null}
            <SettingsPanel title="Addresses" icon={faMapMarkerAlt} action={(<Link to={'settings/addresses'}>Edit</Link>)} >
                <div className="row">
                    <div className="col-md-6">
                        {settingsContentFormatter('Legal Business Address', primaryAddress ? primaryAddress.fullAddress : '-')}

                    </div>
                    <div className="col-md-6">
                        {settingsContentFormatter('Other Addresses', addressCount - 1 > 0 ? `${addressCount - 1} additional addresses` : '-')}
                    </div>
                </div>

            </SettingsPanel>
            <SettingsPanel title="Phone Numbers" icon={faPhoneAlt} action={(<a onClick={() => setPhoneModalShown(true)}>Edit</a>)} >
                <div className="row">
                    <div className="col-md-3">
                      <div style={{ paddingBottom: 20 }}>
                          <p className="text-muted no-margin">
                              Business
                          </p>
                          <p className="no-margin">
                              {account.businessAddress.phone ? <a target="_blank" href={`tel:${account.businessAddress.phone}`}>{account.businessAddress.phone}</a> : '-'}
                          </p>
                      </div>

                    </div>
                    <div className="col-md-3">
                        <div style={{ paddingBottom: 20 }}>
                          <p className="text-muted no-margin">
                              Public
                          </p>
                          <p className="no-margin">
                              {account.publicAddress.phone ? <a target="_blank" href={`tel:${account.publicAddress.phone}`}>{account.publicAddress.phone}</a> : '-'}
                          </p>
                      </div>
                    </div>
                </div>

            </SettingsPanel>
            <SettingsPanel title="Images" icon={faImages} >
                {updatingImages ? <LoadingSpinner /> : <div className="row">
                    <div className="col-md-6">
                        <FormImageWithPreview
                            title="Profile Image"
                            imagePreview={account.logo.getSize('sm')}
                            onChange={async (preview, file) => {
                              setUpdatingImages(true);
                              const data = new FormData();
                              data.append('image', file);
                              const a = await Axios.post(`/api/accounts/${accountId()}`, data);
                              setAccount(AccountProfile.fromApi(a.data.data));
                              setUpdatingImages(false);

                            }}
                            labelClass="text-muted"
                        />

                    </div>
                    <div className="col-md-6">
                        <FormImageWithPreview
                            title="Banner Image"
                            imagePreview={account.banner.getSize('sm')}
                            onChange={async (preview, file) => {
                              setUpdatingImages(true);
                              const data = new FormData();
                              data.append('banner', file);
                              const a = await Axios.post(`/api/accounts/${accountId()}`, data);
                              setAccount(AccountProfile.fromApi(a.data.data));
                              setUpdatingImages(false);

                            }}
                            labelClass="text-muted"
                        />

                    </div>
                </div>}

            </SettingsPanel>

      <GatewayModal
        title="Update Phone Numbers"
        shown={phoneModalShown}
        onClose={() => setPhoneModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
            onSubmit={async (v) => {
              const data = { phone: v.business , public_phone: v.public };
              const a = await Axios.post(`/api/accounts/${accountId()}`, data);
              setAccount(AccountProfile.fromApi(a.data.data));
              setPhoneModalShown(false);
            }}
            initialValues={{ business: account.businessAddress.phone, public: account.publicAddress.phone }}
        >
          {formProps => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Business Phone</label>
                  <Field className="form-control" name="business" />
                </div>
                <div className="form-group">
                  <label>Public Phone</label>
                  <Field className="form-control" name="public" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary pull-right">Update</button>
                <button onClick={() => setPhoneModalShown(false)} type="button" className="btn btn-default pull-left">Cancel</button>
              </Modal.Footer>
            </Form>

          )}

        </Formik>

      </GatewayModal>

  <GatewayModal
        title="Edit Account"
        shown={accountEditModalShown}
        onClose={() => setAccountEditModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
            onSubmit={async (v) => {
              const data = { short_name: v.accountName , name: v.legalName, description: v.description };
              const a = await Axios.post(`/api/accounts/${accountId()}`, data);
              setAccount(AccountProfile.fromApi(a.data.data));
              setAccountEditModalShown(false);
            }}
            initialValues={{
              accountName: account.accountName,
              legalName: account.legalName,
              description: account.description,
            }}
        >
          {formProps => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Account Name</label>
                  <Field className="form-control" name="accountName" />
                </div>
                <div className="form-group">
                  <label>Legal Name</label>
                  <Field className="form-control" name="legalName" />
                </div>
                <div className="form-group">
                  <label>Description</label>
                  <Field component="textarea" className="form-control" name="description" />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button className="btn btn-primary pull-right">Update</button>
                <button onClick={() => setAccountEditModalShown(false)} type="button" className="btn btn-default pull-left">Cancel</button>
              </Modal.Footer>
            </Form>

          )}

        </Formik>

      </GatewayModal>

        </FullContent>
  );

};
