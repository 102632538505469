import Axios from 'axios';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { useRouteMatch } from 'react-router';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { MarketplaceAdvertisement } from '../../../shared';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { AdForm } from './AdForm';

export const AdDetailPage = () => {

  const [ad, setAd] = React.useState<MarketplaceAdvertisement>();
  const [updating, setUpdating] = React.useState(false);
  const match = useRouteMatch();
  const user = React.useContext(UserContext);

  if (user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  React.useEffect(() => {
    getAd();

  },              []);

  const getAd = async () => {
    const id = match && match.params['id'];
    const a = await Axios.get(`/api/marketplace/ads/${id}`);
    setAd(MarketplaceAdvertisement.fromApi(a.data.data));
  };

  const updateAd = async (values: any) => {
    setUpdating(true);
    const id = match && match.params['id'];
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('url', values.url);
    formData.append('url_text', values.url_text);
    if (values.site) {
      formData.append('site_id', values.site);
    }
    if (values.licensor) {
      formData.append('licensor_id', values.licensor);
    }
    if (values.image) {
      formData.append('image', values.image);
    }
    const ad = await Axios.post(`/api/marketplace/ads/${id}`, formData);
    setAd(MarketplaceAdvertisement.fromApi(ad.data.data));
    setUpdating(false);

  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Marketplace Ad')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3><strong>Marketplace Ad</strong></h3>
          <hr />
          {ad ? <AdForm ad={ad} submitting={updating} onSubmit={updateAd}  /> : <LoadingSpinner />}

        </div>
      </div>
    </FullContent>
  );
};
