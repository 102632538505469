import * as React from 'react';
import { FullContent } from './FullContent';

interface IProps {
  children: React.ReactNode;
}

export class CenteredContent extends React.Component<IProps, any> {

  render() {
    return (
      <FullContent >
        <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
          {this.props.children}
        </div>
      </FullContent>
    );
  }

}
