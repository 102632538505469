import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import { Filters } from '../../../shared';

export class UseFilters extends Filters {
  platform = '';

  constructor(location?: string) {
    super();
    this.defaultFilters.status = 0;
    this.status = 0;
    if (location) {
      this.setQueryParamsFromLocation(location);
    }

  }

  setQueryParamsFromLocation(location: string) {
    super.setQueryParamsFromLocation(location);
    const queryValues = queryString.parse(location);
    this.sortBy = queryValues.sort ? queryValues.sort : this.sortBy;
    this.market = queryValues.market ? queryValues.market : this.market;
    this.platform = queryValues.platform ? queryValues.platform : this.platform;
    this.status = queryValues.status ? queryValues.status : this.status;
  }

  generateQueryParamString() {
    const queryParams = {
      search: this.search.length ? this.search : null,
      start_date: this.startDate.length ? this.startDate : null,
      end_date: this.endDate.length ? this.endDate : null,
      page: this.page !== 1 ? this.page : null,
      market: this.market.length ? this.market : null,
      user: this.userId ? this.userId : null,
      sort: this.sortBy.length ? this.sortBy : null,
      platform: this.platform.length ? this.platform : null,
      status: this.status ? this.status : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  setFilters(filters: any) {
    super.setFilters(filters);
    const newFilters = {
      platform: this.platform,
      ...filters,
    };
    this.sortBy = newFilters.sortBy;
    this.page = newFilters.page;
    this.platform = newFilters.platform;
  }

  get filters() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      search: this.search,
      startDate: this.startDate,
      endDate: this.endDate,
      market: this.market,
      userId: this.userId,
      platform: this.platform,
      status: this.status,
    };
  }
}
