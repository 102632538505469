import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { Insignia, Trademark, Vendor } from '../../shared';
import User from '../../shared/User';
import { FullContent } from '../ContentFrame';
import { FormImageWithPreview, GatewayModal, LoadingSpinner, ModalType, TrademarkTypeahead, UserTypeahead, VendorTypeahead } from '../shared';

interface InsigniaFormValues {
  title: string;
  description: string;
  isParent: boolean;
  enabledForDesigns: boolean;
  royalties: boolean;
  code: string;
  users: User[];
  trademarks: Trademark[];
  isVendorSpecific: boolean;
  vendors: Vendor[];
  parent: number;
  sampleImage: File | null;
  sampleImagePreview: string;
}

interface IState {
  insignia: Insignia[];
  saving: boolean;
  loadingInsignia: boolean;
  updateInsignia: Insignia | null;
  errorMessage: string;
  archiveModal: boolean;
  archiving: boolean;
}

export class InsigniaFormPage extends React.Component<RouteComponentProps<any>, IState> {

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      insignia: [],
      saving: false,
      loadingInsignia: false,
      updateInsignia: null,
      errorMessage: '',
      archiveModal: false,
      archiving: false,
    };
    this.getInsignia = this.getInsignia.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getIndivdualInsignia = this.getIndivdualInsignia.bind(this);
    this.archiveInsignia = this.archiveInsignia.bind(this);

  }
  componentDidMount() {
    this.getInsignia();
    if (this.insigniaId) {
      this.getIndivdualInsignia();
    }
  }

  render() {
    const insigniaCodes = this.state.insignia.map(i => i.code);
    const validation = Yup.object({
      title: Yup.string().required('Title is required').min(2, 'Title must be at least 2 characters.')
        .test('no->', 'Title cannot contain >', value => !value.includes('>')),
      sampleImagePreview: Yup.string().notRequired(),
      code: Yup.string().required('Code is required.').notOneOf(insigniaCodes, 'Code must be unique.'),
    });
    const updateInsignia = this.state.updateInsignia;
    const initialValues: InsigniaFormValues = {
      title: updateInsignia ? updateInsignia.originalTitle : '',
      description: updateInsignia ? updateInsignia.description : '',
      code: updateInsignia ? updateInsignia.code : '',
      isParent: updateInsignia ? updateInsignia.isParent : false,
      parent: updateInsignia && updateInsignia.parent ? updateInsignia.parent.id : 0,
      vendors: updateInsignia ? updateInsignia.vendors : [],
      isVendorSpecific: updateInsignia ? updateInsignia.isVendorSpecific : false,
      sampleImage: null,
      sampleImagePreview: updateInsignia ? updateInsignia.image.getSize('md') : '',
      users: updateInsignia ? updateInsignia.designReviewUsers : [],
      trademarks: updateInsignia ? updateInsignia.trademarks : [],
      enabledForDesigns: updateInsignia ? updateInsignia.enabledForDesigns : true,
      royalties: updateInsignia ? updateInsignia.royalties : true,

    };
    const parentInsignias = this.state.insignia.map(i => (
      <option key={i.id} value={i.id}>{i.title}</option>
    ));
    return (
      <FullContent>
        <div className="panel panel-portal">
          {this.state.loadingInsignia ? <div className="panel-body"><LoadingSpinner /></div> : <div className="panel-body">
            <h2><strong>{this.state.updateInsignia ? 'Update' : 'New'} Insignia</strong></h2>
            <hr />
            <Formik
              initialValues={initialValues}
              validationSchema={validation}
              onSubmit={this.onSubmit}
            >
              {(formProps: FormikProps<InsigniaFormValues>) =>
                (
                  <Form>
                    {this.state.errorMessage ? <div className="alert alert-danger">{this.state.errorMessage}</div> : null}
                    <div className="form-group">
                      <label className="control-label">Title</label>
                      <Field name="title" className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name="title" />
                      </p>
                    </div>
                  <div className="form-group">
                    <label className="control-label">Code</label>
                    <Field name="code" className="form-control" />
                    <p className="text-danger">
                      <ErrorMessage name="code" />
                    </p>
                  </div>

                    <div className="form-group">
                      <label className="control-label">Description</label>
                      <Field name="description" className="form-control" component="textarea" />
                    </div>

                     <div className="form-group">
                      <label className="control-label">Parent</label>
                      <Field component="select" name="parent" className="form-control" >
                        <option selected value={0}>Top Level, No Parent</option>
                        {parentInsignias}
                      </Field>
                      <p className="text-danger">
                        <ErrorMessage name="parent" />
                      </p>
                    </div>

                    <div className="form-group">
                      <label>Users</label>
                      <UserTypeahead
                        multiple={true}
                        onChange={u => formProps.setFieldValue('users', u)}
                        accountId={this.licensorId}
                        selected={formProps.values.users}
                      />
                    </div>

                  <div className="form-group">
                    <label>Trademarks</label>

                    <TrademarkTypeahead
                      multiple={true}
                      onChange={t => formProps.setFieldValue('trademarks', t)}
                      accountId={this.licensorId}
                      selected={formProps.values.trademarks}

                    />
                  </div>
                  <div className="checkbox">
                    <label>
                      <input
                        name="isVendorSpecific"
                        onChange={formProps.handleChange}
                        checked={formProps.values.isVendorSpecific}
                        type="checkbox"
                      /> Vendor Specific
                    </label>
                  </div>
                  {formProps.values.isVendorSpecific ?
                    <div className="form-group">
                      <VendorTypeahead
                        selected={formProps.values.vendors}
                        onChange={v => formProps.setFieldValue('vendors', v)}
                        multiple={true}
                      />
                    </div>

                    : null}
                  <div className="checkbox">
                    <label>
                      <input
                        name="royalties"
                        onChange={formProps.handleChange}
                        checked={formProps.values.royalties}
                        type="checkbox"
                      /> Valid on Royalty Sales Data
                    </label>
                  </div>
                  <div className="checkbox">
                    <label>
                      <input
                        name="enabledForDesigns"
                        onChange={formProps.handleChange}
                        checked={formProps.values.enabledForDesigns}
                        type="checkbox"
                      /> Enabled for Design Tagging
                    </label>
                  </div>

                    <FormImageWithPreview imagePreview={formProps.values.sampleImagePreview} onChange={(preview, file) => {
                      formProps.setFieldValue('sampleImage', file);
                      formProps.setFieldValue('sampleImagePreview', preview);
                    }}
                      title="Sample Image"
                      labelClass="col-sm-12"
                      fieldClass="col-sm-12"
                    />
                    <p className="text-danger">
                      <ErrorMessage name="sampleImagePreview" />
                    </p>
                  <button className="btn btn-primary btn-lg pull-right" disabled={this.state.saving} type="submit">
                    {this.state.saving ? 'Saving...' : 'Save'}
                  </button>
                    {updateInsignia ?
                    <button style={{ marginRight: 15 }} className="btn btn-default btn-lg pull-right" onClick={() => this.setState({ archiveModal: true })} disabled={this.state.saving} type="button">
                      Archive
                    </button>
                    : null}

                  </Form>
              )}
            </Formik>
          </div>}
        </div>
        <GatewayModal
          shown={this.state.archiveModal}
          onClose={() => this.setState({ archiveModal: false })}
          type={ModalType.Danger}
          title="Archive Insignia"
        >
          <Modal.Body>
            <p className="text-center">
              Are you sure you want to archive this insignia?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => this.setState({ archiveModal: false })} className="btn btn-default pull-left">Cancel</button>
            <button onClick={this.archiveInsignia} className="btn btn-danger pull-right">{this.state.archiving ? 'Archiving...' : 'Yes, Archive Insignia'}</button>

          </Modal.Footer>

        </GatewayModal>

      </FullContent >

    );
  }

  onSubmit(values: InsigniaFormValues) {
    const formData = new FormData();
    formData.append('client_id', this.licensorId);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('is_parent', values.isParent ? '1' : '0');
    formData.append('is_vendor_specific', values.isVendorSpecific ? '1' : '0');
    formData.append('brand_code', values.code);
    formData.append('enabled_for_designs', values.enabledForDesigns ? '1' : '0');
    formData.append('enabled_for_royalties', values.royalties ? '1' : '0');

    if (values.parent) {
      formData.append('parent_id', `${values.parent}`);
    }

    if (values.sampleImage) {
      formData.append('image', values.sampleImage);
    }
    values.trademarks.forEach(t => formData.append('trademarks[]', `${t.id}`));
    values.users.forEach(u => formData.append('users[]', `${u.id}`));
    values.vendors.forEach(v => formData.append('vendors[]', `${v.id}`));
    this.setState({ saving: true });

    if (this.insigniaId) {
      formData.append('id', this.insigniaId);
      Axios.post(`/api/brandmarks/${this.insigniaId}`, formData)
        .then((response) => {
          this.props.history.push(`/clients/${this.licensorId}/settings/brand`);
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.setState({ errorMessage: Object.values(error.response.data.errors).join(' ') });
          } else {
            this.setState({ errorMessage: 'There was an error updating the insignia.' });
          }
          this.setState({ saving: false });
        });

    } else {
      Axios.post('/api/brandmarks', formData)
        .then((response) => {
          this.props.history.push(`/clients/${this.licensorId}/settings/brand`);
        }).catch((error) => {
          if (error.response && error.response.status === 422) {
            this.setState({ errorMessage: Object.values(error.response.data.errors).join(' ') });
          } else {
            this.setState({ errorMessage: 'There was an error creating the insignia.' });
          }
          this.setState({ saving: false });
        });
    }

  }

  getInsignia() {
    Axios.get(`/api/brandmarks?client_id=${this.licensorId}`)
      .then((response) => {
        const insignia = response.data.data.map((i: any) => Insignia.fromApi(i))
          .filter((i: Insignia) => {
            if (this.insigniaId && Number(i.id) === Number(this.insigniaId)) {
              return false;
            }
            if (this.insigniaId && i.parent && Number(i.parent.id) === Number(this.insigniaId)) {
              return false;
            }
            return true;
          });
        this.setState({
          insignia: orderBy(insignia,  'order',  'asc') ,
        });
      });
  }

  getIndivdualInsignia() {
    this.setState({ loadingInsignia: true });
    Axios.get(`/api/brandmarks/${this.insigniaId}`)
      .then((response) => {
        this.setState({ updateInsignia: Insignia.fromApi(response.data.data), loadingInsignia: false });
      });

  }

  async archiveInsignia() {
    this.setState({ archiving : true });
    await Axios.delete(`/api/brandmarks/${this.insigniaId}`);
    this.props.history.push(`/clients/${this.licensorId}/settings/brand`);

  }

  get licensorId() {
    return this.props.match.params['licensorId'];
  }

  get insigniaId() {
    return this.props.match.params['id'] ? this.props.match.params['id'] : null;
  }

}
