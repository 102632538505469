import Axios from 'axios';
import { ErrorMessage, Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { Trademark } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FormImageWithPreview, GatewayModal, LoadingSpinner, ModalType } from '../../shared';

interface IGroup {
  name: string;
  options: number[];
}

interface StateFormValues {
  type: 2;
  title: string;
  registration: string;
  description: string;
  dateOfFirstUse: string;
  dateOfFirstUseCommerce: string;
  image: any;
  imagePreview: string;
  certificateFile: any;
  groups: IGroup[];

}
interface CommonLawFormValues {
  type: 3;
  title: string;
  description: string;
  dateOfFirstUse: string;
  dateOfFirstUseCommerce: string;
  image: any;
  imagePreview: string;
  groups: IGroup[];

}

export const TrademarksPage = () => {
  const [unregisteredModalShown, setUnregisteredModalShown] = React.useState(false);
  const [registeredModalShown, setRegisteredModalShown] = React.useState(false);
  const [commonLawModalShown, setCommonLawModalShown] = React.useState(false);
  const [stateModalShown, setStateModalShown] = React.useState(false);
  const [trademarkResponse, setTrademarkResponse] = React.useState<any>(null);
  const [trademarks, setTrademarks] = React.useState<Trademark[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [submissionError, setSubmissionError] = React.useState('');
  const [internationalGSList, setGSList] = React.useState<any[]>([]);
  const user = React.useContext(UserContext);
  const match = useRouteMatch();

  React.useEffect(() => {
    getTrademarks();
    getGSList();
  },              []);

  React.useEffect(() => {
    if (!registeredModalShown) {
      setTrademarkResponse(null);
      setSubmissionError('');
    }

  },              [registeredModalShown]);

  const getTrademarks = async () => {
    setLoading(true);
    const accountId =  match && match.params['licensorId'] ? match.params['licensorId'] : user.account.id;
    const t = await Axios.get('/api/trademarks', { params: { account_id: accountId } });
    setTrademarks(t.data.data.map((t: any) => Trademark.fromApi(t)));
    setLoading(false);
  };

  const getGSList = async () => {
    const g = await Axios.get('/api/international-gs-list');
    setGSList(g.data.data);
  };

  const submitRegisteredForm = async (v: any) => {
    const accountId =  match && match.params['licensorId'] ? match.params['licensorId'] : user.account.id;
    if (!trademarkResponse) {
      setSearching(true);
      const t = await Axios.get('/api/trademarks/findTrademark',
                                { params: { type: v.type, ids: v.id, account_id: accountId } },
        ).catch((e) => {
          setSearching(false);
          if (e.response && e.response.data.error) {
            setSubmissionError(e.response.data.error);
          } else {
            setSubmissionError('There was an error searching for trademarks');
          }
          return null;
        });
      if (!t) {
        return;
      }

      setTrademarkResponse(t.data.data);
      setSearching(false);
    } else {
      setSubmitting(true);
      const d = await Axios.post('/api/trademarks/storeTrademark', { type: v.type, ids: v.id, account_id: accountId })
        .catch((e) => {
          if (e) {
            setSubmissionError('There was an error saving the trademark');
          }
          return false;
        });
      if (d) {
        getTrademarks();
        setRegisteredModalShown(false);
      }
      setSubmitting(false);
    }

  };

  const submitUnregisteredForm = async (v: any) => {
    setSubmitting(true);
    const accountId = match && match.params['licensorId'] ? match.params['licensorId'] : user.account.id;
    const formData = new FormData();
    formData.append('account_id', accountId);
    formData.append('title', v.title);
    formData.append('description', v.description);
    formData.append('date_of_first_use', v.dateOfFirstUse);
    formData.append('date_of_first_use_in_commerce', v.dateOfFirstUseCommerce);
    formData.append('type', v.type);
    if (v.registrationNumber) {
      formData.append('registration_number', v.registrationNumber);
    }
    if (v.certificateFile) {
      formData.append('cert_file', v.certificateFile, v.certificateFile.name);
    }
    if (v.image) {
      formData.append('image', v.image, v.image.name);
    }
    if (v.groups) {
      formData.append('groups', JSON.stringify(v.groups));
    }
    await Axios.post('/api/trademarks/storeUnregistered', formData);
    getTrademarks();
    setUnregisteredModalShown(false);
    setCommonLawModalShown(false);
    setStateModalShown(false);
    setSubmitting(false);

  };
  const rows = trademarks.map(t => (
    <Link
      to={`trademarks/${t.id}`}
      className="fake-tr"
      style={{ cursor: 'pointer', height: 120, color: '#333', textDecoration: 'none' }}
    >
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>

          {t.image ? <img style={{ maxWidth: 100 }} className="img-responsive" src={t.image.getSize('sm')} /> :
            <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>

              <strong>{t.title}</strong>
            </span>}
        </div>

      </td>
      <td>{t.type}</td>
      <td>{t.isRegistered ? t.status : null}</td>
      <td>{t.statusDate ? t.statusDate.format('MMM YYYY') : null}</td>
      <td>{t.serialNumber}</td>
      <td>{t.registrationNumber}</td>
      <td>{t.registrationDate ? t.registrationDate.format('MMM YYYY') : null}</td>
    </Link>
  ));

  const body  = loading ? (<LoadingSpinner />) : (
    <div className="table-responsive">
      <table className="table table-hover table-portal">
        <thead>
          <tr>
            <th></th>
            <th>Type</th>
            <th>Status</th>
            <th>Status Date</th>
            <th>Serial #</th>
            <th>Reg. #</th>
            <th>Reg. Date</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );

  const stateFormInitialValues: StateFormValues = {
    type: 2,
    title: '',
    registration: '',
    description: '',
    dateOfFirstUse: '',
    dateOfFirstUseCommerce: '',
    image: null,
    imagePreview: '',
    certificateFile: null,
    groups: [] as IGroup[],
  };

  return (
    <FullContent>

      <div className="panel panel-portal">
        <div className="panel-body">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <h3 className="no-margin"><strong>Trademarks</strong></h3>
            <DropdownButton
              bsStyle="primary"
              title="Add Trademark"
              id="add-trademar-button"

            >
              <MenuItem onClick={() => setRegisteredModalShown(true)}>Federal Trademark</MenuItem>
              <MenuItem onClick={() => setStateModalShown(true)}>State Trademark</MenuItem>
              <MenuItem onClick={() => setCommonLawModalShown(true)}>Common Law Trademark</MenuItem>
              {/* <MenuItem onClick={() => setUnregisteredModalShown(true)}>Unregistered Trademark</MenuItem> */}

            </DropdownButton>

          </div>
          <hr />
          {body}

        </div>
      </div>

      <GatewayModal
        title="Add Federal Trademark"
        shown={registeredModalShown}
        onClose={() => setRegisteredModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
          initialValues={{
            id: '',
            type: '',
          }}
          onSubmit={submitRegisteredForm}
          validationSchema={
            Yup.object({
              id: Yup.string().required('Serial or Registration is required'),
              type: Yup.string().required('Type is required'),
            })
        }
        >
          <Form>
            <Modal.Body>
              {submissionError ? (<div className="alert alert-danger">
                {submissionError}

              </div>) : null}
              <div className="form-group">
                <label>USPTO Number</label>
                <Field name="id" placeholder="Serial or Registration Number" className="form-control" />
                <p className="text-danger"> <ErrorMessage name="id" />
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="type">USPTO Number Type</label>
                <Field component="select" name="type" className="form-control">
                  <option value={undefined}>Select Serial or Registration Number</option>
                  <option value="sn">Serial Number</option>
                  <option value="rn">Registration Number</option>
                </Field>
                <p className="text-danger">
                  <ErrorMessage name="type" />
                </p>
              </div>
              <hr />
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {searching ? <LoadingSpinner /> : null}
                {trademarkResponse ?
                  trademarkResponse.img ? <img style={{ maxWidth: 300, maxHeight: 300 }} src={`data:image/jpeg;base64, ${trademarkResponse.img}`} />
                    : <h3>{trademarkResponse.trademark.status.markElement}</h3>
                  : null}

              </div>

            </Modal.Body>
            <Modal.Footer>

              <button className="btn btn-primary">{trademarkResponse ?
                submitting ? 'Submiting...' : 'Submit Trademark' : 'Search'}
              </button>
            </Modal.Footer>

          </Form>

        </Formik>
      </GatewayModal>
      <GatewayModal
        title="Add Unregistered Trademark"
        shown={unregisteredModalShown}
        onClose={() => setUnregisteredModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
          initialValues={{
            type: 4,
            title: '',
            description: '',
            dateOfFirstUse: '',
            dateOfFirstUseCommerce: '',
            image: null,
            imagePreview: '',
          }}
          onSubmit={submitUnregisteredForm}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required.'),
          })}

        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <Field name="title" className="form-control" />
                  <p className="text-danger">
                    <ErrorMessage name="title" />
                  </p>
                </div>
                <div className="form-group">
                  <label htmlFor="title">Description</label>
                  <Field name="description" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="dateOfFirstUse">Date of First Use</label>
                  <Field name="dateOfFirstUse" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="dateOfFirstUseCommerce">Date of First Use in Commerce</label>
                  <Field name="dateOfFirstUseCommerce" className="form-control" />
                </div>
                <FormImageWithPreview
                  title="Image"
                  imagePreview={formProps.values.imagePreview}
                  onChange={(preview, image) => {
                    formProps.setFieldValue('image', image);
                    formProps.setFieldValue('imagePreview', preview);
                  }}
                  labelClass="no"
                />

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setUnregisteredModalShown(false)} className="btn btn-default pull-left">
                  Cancel
            </button>
                <button className="btn btn-primary pull-right">{submitting ? 'Submitting...' : 'Submit'}</button>

              </Modal.Footer>

            </Form>

          )}

        </Formik>
      </GatewayModal>
      <GatewayModal
        title="Add Common Law Trademark"
        shown={commonLawModalShown}
        onClose={() => setCommonLawModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
          initialValues={{
            type: 3,
            title: '',
            description: '',
            dateOfFirstUse: '',
            dateOfFirstUseCommerce: '',
            image: null,
            imagePreview: '',
            groups: [] as IGroup[],
          }}
          onSubmit={submitUnregisteredForm}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required.'),
          })}

        >
          {(formProps: FormikProps<CommonLawFormValues>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <Field name="title" className="form-control" />
                  <p className="text-danger">
                    <ErrorMessage name="title" />
                  </p>
                </div>
                <div className="form-group">
                  <label htmlFor="title">Description</label>
                  <Field name="description" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="dateOfFirstUse">Date of First Use</label>
                  <Field name="dateOfFirstUse" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="dateOfFirstUseCommerce">Date of First Use in Commerce</label>
                  <Field name="dateOfFirstUseCommerce" className="form-control" />
                </div>
                <FormImageWithPreview
                  title="Image"
                  imagePreview={formProps.values.imagePreview}
                  onChange={(preview, image) => {
                    formProps.setFieldValue('image', image);
                    formProps.setFieldValue('imagePreview', preview);
                  }}
                  labelClass="no"
                />
                <FieldArray
                  name="groups"
                  render={(arrayHelpers) => {

                    return (
                      <div>
                        {formProps.values.groups.map((g, index) => (
                          <div style={{ marginBottom: 15 }}>
                            <div className="form-group">
                              <label htmlFor="optionName">Group Name</label>
                              <div style={{ justifyContent: 'space-between', alignItems: 'center' }} className="flex-container">
                                <Field name={`groups.${index}.name`} className="form-control" />
                                <button
                                  type="button"
                                  style={{ marginLeft: 10 }}
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="btn btn-danger btn-sm"
                                >
                                  Remove Group
                                </button>
                              </div>
                            </div>
                            <Typeahead
                              id={`group.${index}.typeahead`}
                              placeholder="GS Item"
                              selected={formProps.values.groups[index].options}
                              options={internationalGSList}
                              multiple={true}
                              onChange={v => formProps.setFieldValue(`groups.${index}.options`, v) }
                              labelKey={(label: any) => `${label.code} - ${label.description}`}
                              paginate={false}
                              maxResults={internationalGSList.length}
                            />

                          </div>
                        ))}

                        <button
                          type="button"
                          style={{ marginTop: 15 }}
                          onClick={() => arrayHelpers.push({ name: '', options: [] })}
                          className="btn btn-primary"
                        >
                          Add GS Group
                        </button>

                      </div>

                    );
                  }}

                />

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setCommonLawModalShown(false)} className="btn btn-default pull-left">
                  Cancel
            </button>
                <button className="btn btn-primary pull-right">{submitting ? 'Submitting...' : 'Submit'}</button>

              </Modal.Footer>

            </Form>

          )}

        </Formik>
      </GatewayModal>
      <GatewayModal
        title="Add State Trademark"
        shown={stateModalShown}
        onClose={() => setStateModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
          initialValues={stateFormInitialValues}
          onSubmit={submitUnregisteredForm}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required.'),
          })}

        >
          {(formProps: FormikProps<StateFormValues>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="title">Title</label>
                  <Field name="title" className="form-control" />
                  <p className="text-danger">
                    <ErrorMessage name="title" />
                  </p>
                </div>
                <div className="form-group">
                  <label htmlFor="title">Registration Number</label>
                  <Field name="registration" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="title">Description</label>
                  <Field name="description" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="dateOfFirstUse">Date of First Use</label>
                  <Field name="dateOfFirstUse" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="dateOfFirstUseCommerce">Date of First Use in Commerce</label>
                  <Field name="dateOfFirstUseCommerce" className="form-control" />
                </div>
                <FormImageWithPreview
                  title="Image"
                  imagePreview={formProps.values.imagePreview}
                  onChange={(preview, image) => {
                    formProps.setFieldValue('image', image);
                    formProps.setFieldValue('imagePreview', preview);
                  }}
                  labelClass="no"
                />
                <div className="form-group">
                  <label htmlFor="certificateFile">Certificate File</label>
                  <input name="certificateFile" id="certificateFile" type="file" onChange={e => formProps.setFieldValue('certificateFile', e.target.files && e.target.files[0])} />
                </div>
                <FieldArray
                  name="groups"
                  render={(arrayHelpers) => {

                    return (
                      <div>
                        {formProps.values.groups.map((g, index) => (
                          <div style={{ marginBottom: 15 }}>
                            <div className="form-group">
                              <label htmlFor="optionName">Description</label>
                              <div style={{ justifyContent: 'space-between', alignItems: 'flex-start' }} className="flex-container">
                                <Field component="textarea" name={`groups.${index}.name`} className="form-control" />
                                <button
                                  type="button"
                                  style={{ marginLeft: 10 }} onClick={() => arrayHelpers.remove(index)}
                                  className="btn btn-danger btn-sm"
                                >
                                  Remove Group
                                </button>
                              </div>
                            </div>
                            <label>GS List</label>
                            <Typeahead
                              id={`group.${index}.typeahead`}
                              placeholder="GS Item"
                              selected={formProps.values.groups[index].options}
                              options={internationalGSList}
                              multiple={true}
                              onChange={v => formProps.setFieldValue(`groups.${index}.options`, v) }
                              labelKey={(label: any) => `${label.code} - ${label.description}`}
                              paginate={false}
                              maxResults={internationalGSList.length}
                            />

                          </div>
                        ))}

                        <button
                          type="button"
                          style={{ marginTop: 15 }}
                          onClick={() => arrayHelpers.push({ name: '', options: [] })}
                          className="btn btn-primary"
                        >
                          Add GS Group
                        </button>

                      </div>

                    );
                  }}

                />

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setStateModalShown(false)} className="btn btn-default pull-left">
                  Cancel
            </button>
                <button className="btn btn-primary pull-right">{submitting ? 'Submitting...' : 'Submit'}</button>

              </Modal.Footer>

            </Form>

          )}

        </Formik>
      </GatewayModal>

    </FullContent>
  );
};
