import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as queryString from 'query-string';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Filters } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FilterBar, GatewayModal, ModalType, PaginationFooter } from '../../shared';

interface IState {
  itemTypes: any[];
  loading: boolean;
  deleteModalShown: boolean;
  idToDelete: string;
  filters: Filters;
  totalResults: number;
  lastPage: number;
}

export class ItemTypesPage extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);

    const filters = new Filters();
    filters.setQueryParamsFromLocation(this.props.location.search);

    this.state = {
      filters,
      itemTypes: [],
      loading: false,
      deleteModalShown: false,
      idToDelete: '',
      totalResults: 0,
      lastPage: 1,
    };
    this.getItemTypes = this.getItemTypes.bind(this);
    this.deleteItemType = this.deleteItemType.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.setDeleteID = this.setDeleteID.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.setPage = this.setPage.bind(this);
  }

  componentDidMount() {
    this.getItemTypes();
  }

  componentWillUnmount() {

  }

  getItemTypes() {
    this.setState({ loading: true });
    const params = this.state.filters.getQueryParams();
    params['account_id'] = this.accountID;

    Axios.get('/api/item-types', { params })
      .then((response) => {
        this.setState({
          itemTypes: response.data.data,
          loading: false,
          lastPage: response.data.meta.pagination.page_count,
          totalResults: response.data.meta.pagination.total_count,
        });
      })
      .catch(error => console.error(error));
  }

  deleteItemType() {
    if (this.state.idToDelete) {
      const params = {
        account_id: this.accountID,
        id: this.state.idToDelete,
      };
      Axios.delete('/api/item-types', { params })
        .then((response) => {
          const itemTypes = this.state.itemTypes.filter(itemType => itemType.id !== this.state.idToDelete);
          this.setState({ itemTypes, idToDelete: '' });
          this.getItemTypes();
          this.hideDeleteModal();
        });
    }
  }

  showDeleteModal() {
    this.setState({ deleteModalShown: true });
  }

  hideDeleteModal() {
    this.setState({ deleteModalShown: false });
  }

  setDeleteID(e: any) {
    this.setState({ idToDelete: e.target.getAttribute('data-id') });
    this.showDeleteModal();
  }

  get accountID() {
    if (this.props.match.params['vendorId']) {
      return this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      return this.props.match.params['licensorId'];
    }
    return '';
  }

  updateFilters(filters: any, performSearch: boolean) {

    const updatedFilters = this.state.filters;
    if (filters.search) {
      filters.page = 1;
    }
    updatedFilters.setFilters(filters);
    this.setState({ filters: updatedFilters });

    if (performSearch) {
      const baseUrl = this.props.location.pathname;
      this.props.history.replace(`${baseUrl}?${queryString.stringify(updatedFilters.getQueryParams())}`);
      this.getItemTypes();
    }

  }

  setPage(page: number) {
    this.updateFilters({ page }, true);
  }

  render() {
    const rows = this.state.itemTypes.map(product =>
      <tr key={ product.identifier } className="licensor-mapping-row">
        <td style={{ verticalAlign: 'middle' }}>
          {product.identifier}
        </td>
        <td>
          {product.name}
        </td>
        <td>
          <button className="btn btn-default text-danger" data-id={product.identifier} onClick={this.setDeleteID}>
            Delete
          </button>
        </td>

      </tr>,
  );

    let pageContent;
    if (this.state.loading) {
      pageContent = (
        <div className="react-loading-content">
          <span><FontAwesomeIcon icon={faSpinner} spin /></span>
        </div>
      );

    } else if (this.state.itemTypes.length === 0 && ! this.state.loading) {
      pageContent =  (
            <em>No Item Types Found.</em>
      );
    } else {
      pageContent = (
        <div>
          <div className="table-responsive">
            <table className="table royalties-table">
              <thead>
              <tr>
                <th>Item Type</th>
                <th>Affinity Category</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              {rows}
              </tbody>

            </table>
          </div>
        </div>

      );

    }

    return (
      <FullContent breadcrumbs={[{ name: 'Item Types' }]}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Item Types</strong></h3>
        </div>
          <Link to={'item-types/add'} className="btn btn-primary">Add Item Type</Link>
      </div>
        <FilterBar
          updateFilters={this.updateFilters}
          usePerPage={false}
          useQuarter={false}
          useSearch={true}
          search={this.state.filters.search}
          perPage={this.state.filters.perPage}
          fieldClassOverrides={{ search: 'address-search-bar col-lg-3 col-sm-3 col-xs-12' }}
        />

        <div className="panel panel-portal">
          <div className="panel-body">
        { pageContent }
          </div>
        </div>
        <PaginationFooter
          setPage={this.setPage}
          currentPage={this.state.filters.page}
          totalResults={this.state.totalResults}
          hasNext={this.state.lastPage > this.state.filters.page} />
        <GatewayModal shown={this.state.deleteModalShown} type={ModalType.Danger} onClose={this.hideDeleteModal} title="Delete Item Type Mapping" >
          <Modal.Body>
            <p className="text-center">Are you sure you want to remove this item type?</p>

          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
            <button onClick={this.deleteItemType} className="btn btn-danger pull-right">Delete Item Type</button>
          </Modal.Footer>
        </GatewayModal>
      </FullContent>

    );

  }

}
