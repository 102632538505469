import { CancelTokenSource } from 'axios';
import axios from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { deleteSchedule, getLicensorSchedules, setStandardSchedule, updateScheduleVendors } from '../../api';
import { UserContext } from '../../contexts';
import { RoyaltySchedule } from '../../shared';
import { FullContent } from '../ContentFrame';
import { GatewayModal, ModalType } from '../shared/modals';
import RoyaltyScheduleEntry from './RoyaltyScheduleEntry';
import StandardScheduleModal, { StandardRoyaltyParams } from './StandardScheduleModal';
import UpdateVendorsModal, { ISelectOption } from './UpdateVendorsModal';

interface IVendorOption {
  id: number;
  name: string;
}

interface IState {
  royaltySchedules: any[];
  currentSchedule: RoyaltySchedule | null;
  deleteModalShown: boolean;
  deletingSchedule: boolean;
  vendorModalShown: boolean;
  updatingVendors: boolean;
  vendorSelections: IVendorOption[];
  standardScheduleModalShown: boolean;
  standardRoyaltyParams: StandardRoyaltyParams | null;
  updatingStandardRoyalty: boolean;
}

type IProps = RouteComponentProps<any>;

class ClientRoyaltySchedulesPage extends React.Component<IProps, IState> {

  protected _getSchedulesSource: CancelTokenSource;
  protected _deleteScheduleSource: CancelTokenSource;
  protected _updateVendorsSource: CancelTokenSource;
  protected _updateStandardScheduleSource: CancelTokenSource;

  constructor(props: IProps) {
    super(props);
    this.state = {
      royaltySchedules: [],
      currentSchedule: null,
      deleteModalShown: false,
      deletingSchedule: false,
      vendorModalShown: false,
      updatingVendors: false,
      vendorSelections: [],
      standardScheduleModalShown: false,
      standardRoyaltyParams: null,
      updatingStandardRoyalty: false,
    };

    this.getSchedules = this.getSchedules.bind(this);
    this.showArchiveModal = this.showArchiveModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.submitArchiveSchedule = this.submitArchiveSchedule.bind(this);

    this.showVendorModal = this.showVendorModal.bind(this);
    this.hideVendorModal = this.hideVendorModal.bind(this);
    this.submitUpdatedVendors = this.submitUpdatedVendors.bind(this);
    this.addVendorSelection = this.addVendorSelection.bind(this);
    this.removeVendorSelection = this.removeVendorSelection.bind(this);

    this.showStandardScheduleModal = this.showStandardScheduleModal.bind(this);
    this.hideStandardScheduleModal = this.hideStandardScheduleModal.bind(this);
    this.updateStandardScheduleParams = this.updateStandardScheduleParams.bind(this);
    this.submitStandardSchedule = this.submitStandardSchedule.bind(this);
  }

  componentDidMount() {
    this.getSchedules();
  }

  public componentWillUnmount() {
    if (this._getSchedulesSource) {
      this._getSchedulesSource.cancel('Cancelled ClientRoyaltySchedulesPage:getSchedules XHR due to unmount.');
    }
    if (this._deleteScheduleSource) {
      this._deleteScheduleSource.cancel('Cancelled ClientRoyaltySchedulesPage deleteSchedule XHR due to unmount.');
    }
  }

  getSchedules() {
    this._getSchedulesSource = axios.CancelToken.source();
    getLicensorSchedules(this.props.match.params.licensorId, this._getSchedulesSource)
      .then((response: any) => {
        const schedules = response.data.data.map((item: any) => {
          return new RoyaltySchedule(item);
        })
          .filter((schedule: RoyaltySchedule) => {
          return schedule.countMetadata.active_licenses > 0 || schedule.deletedAt === null;
        })
          .sort((a: RoyaltySchedule, b: RoyaltySchedule) => {
            if (a.deletedAt === null && b.deletedAt !== null) {
              return -1;
            }

            if (b.deletedAt === null && a.deletedAt !== null) {
              return 1;
            }
          // Default ("Standard Royalty") schedules come before non-default schedules
            if (a.isDefaultSchedule) {
              return -1;
            }
            if (b.isDefaultSchedule) {
              return 1;
            }

          // Otherwise, sort by number of licenses it's attached to
            return b.countMetadata.licenses - a.countMetadata.licenses;
          });

        const standardSchedule = schedules.find((schedule: RoyaltySchedule) =>
          schedule.isDefaultSchedule && schedule.deletedAt === null,
        );

        const standardRoyaltyParams = {
          royaltyRate: standardSchedule && standardSchedule.royaltyRate,
          minimumRoyalty: standardSchedule && standardSchedule.minimumGuarantee,
        };

        this.setState({
          standardRoyaltyParams,
          royaltySchedules: schedules,
        });
      });
  }

  showArchiveModal(schedule: RoyaltySchedule) {
    this.setState({
      currentSchedule: schedule,
      deleteModalShown: true,
    });
  }
  hideDeleteModal() {
    this.setState({
      currentSchedule: null,
      deleteModalShown: false,
    });
  }
  submitArchiveSchedule() {

    if (this.state.currentSchedule === null) {
      throw new Error('Current schedule unset while archiving');
    }

    this.setState({ deletingSchedule: true });
    this._deleteScheduleSource = axios.CancelToken.source();
    deleteSchedule(this.state.currentSchedule.id, this._deleteScheduleSource)
      .then((response: any) => {
        this.setState({
          deletingSchedule: false,
          deleteModalShown: false,
        });
        this.getSchedules();
      });
  }

  showVendorModal(schedule: RoyaltySchedule) {
    this.setState({
      currentSchedule: schedule,
      vendorModalShown: true,
      vendorSelections: schedule.exclusiveVendors.map(vendor => ({
        id: vendor.id,
        name: vendor.name,
      })),
    });
  }

  hideVendorModal() {
    this.setState({
      currentSchedule: null,
      vendorModalShown: false,
    });
  }

  submitUpdatedVendors() {
    if (this.state.currentSchedule === null) {
      throw new Error('Current schedule unset while submitting vendors');
    }

    if (this.state.vendorSelections === null) {
      throw new Error('Current schedule vendor ids unset while submitting vendors');
    }

    const selectedVendorIds = this.state.vendorSelections.map(vendor => vendor.id);

    this._updateVendorsSource = axios.CancelToken.source();
    this.setState({ updatingVendors: false });
    updateScheduleVendors(this.state.currentSchedule.id, selectedVendorIds, this._updateVendorsSource)
      .then((response: any) => {
        this.setState({
          currentSchedule: null,
          vendorModalShown: false,
          updatingVendors: false,
        });
        this.getSchedules();
      });
  }

  addVendorSelection(selection: ISelectOption) {
    const vendorSelections = this.state.vendorSelections.concat([selection]);
    this.setState({ vendorSelections });
  }

  removeVendorSelection(removed: ISelectOption) {
    const vendorSelections = this.state.vendorSelections.filter((sel) => {
      return sel.id !== removed.id;
    });
    this.setState({ vendorSelections });
  }

  showStandardScheduleModal() {
    this.setState({ standardScheduleModalShown: true });
  }

  hideStandardScheduleModal() {
    this.setState({ standardScheduleModalShown: false });
  }

  submitStandardSchedule() {

    if (this.state.standardRoyaltyParams === null) {
      return;
    }

    const licensorId = this.props.match.params.licensorId;
    this._updateStandardScheduleSource = axios.CancelToken.source();
    this.setState({ updatingStandardRoyalty: true });
    setStandardSchedule(licensorId, this.state.standardRoyaltyParams, this._updateStandardScheduleSource)
      .then(() => {
        this.setState({
          updatingStandardRoyalty: false,
          standardScheduleModalShown: false,
        });
        this.getSchedules();
      });
  }

  updateStandardScheduleParams(scheduleParams: StandardRoyaltyParams) {
    this.setState({ standardRoyaltyParams: scheduleParams });
  }

  public render() {

    const schedules = this.state.royaltySchedules.map(schedule => (
      <div key={schedule.id}>
        <RoyaltyScheduleEntry
          schedule={schedule}
          onClickArchive={this.showArchiveModal}
          onClickUpdateVendors={this.showVendorModal}
          onClickUpdateStandard={this.showStandardScheduleModal}
        />
        <hr />
      </div>
    ));

    return (
      <FullContent>
          <div className="panel panel-portal">
            <div className="panel-body">
              <div className="row">
                <div className="col-xs-12">
                  <UserContext.Consumer>
                    {user => user.type === 'admin' ? (
                      <Link
                        to={`/clients/${this.props.match.params.licensorId}/settings/schedules/add`}
                        className="btn btn-primary pull-right"
                      >
                        New Schedule
                      </Link>
                    ) : null}
                  </UserContext.Consumer>

                  <h3>Royalty Schedules</h3>
                  <p className="text-muted">
                    Each schedule defines a unique royalty fee structure.
                    Schedules are based on the item's licensee, distribution channel, insignia, and
                    product category.
                  </p>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12">
                  {schedules}
                </div>
              </div>
            </div>
          </div>

        <GatewayModal
          type={ModalType.Danger}
          onClose={this.hideDeleteModal}
          shown={this.state.deleteModalShown}
          title={this.state.currentSchedule && this.state.currentSchedule.title}
        >
          <Modal.Body>
            <div
              className="text-center"
              style={{ fontSize: '16px', display: 'block', marginTop: '15px', fontWeight: 'bold' }}
            >
              <div>
                Are you sure you want to delete this royalty schedule?
              </div>
              <div className="text-danger">
                This action will delete this royalty schedule so it will no longer be
                pulled into new licenses.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.hideDeleteModal} className="btn btn-default pull-left">Cancel</button>
            <button
              className="btn btn-danger"
              disabled={this.state.deletingSchedule}
              onClick={this.submitArchiveSchedule}
            >
              Delete
            </button>
          </Modal.Footer>
        </GatewayModal>

        <UpdateVendorsModal
          shown={this.state.vendorModalShown}
          hideModal={this.hideVendorModal}
          currentSchedule={this.state.currentSchedule}
          addSelection={this.addVendorSelection}
          submit={this.submitUpdatedVendors}
          submitting={false}
          selections={this.state.vendorSelections}
          removeSelection={this.removeVendorSelection}
        />

        <StandardScheduleModal
          shown={this.state.standardScheduleModalShown}
          hideModal={this.hideStandardScheduleModal}
          standardRoyaltyParams={this.state.standardRoyaltyParams}
          submitting={this.state.updatingStandardRoyalty}
          submit={this.submitStandardSchedule}
          onChange={this.updateStandardScheduleParams}
        />

      </FullContent>
    );
  }
}

export default withRouter(ClientRoyaltySchedulesPage);
