import Axios from 'axios';
import * as React from 'react';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';

export const ResearchIndex = () => {

  const [search, setSearch] = React.useState('');
  const [totalResults, setTotalResults] = React.useState(0);
  const [results, setResults] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const searchEtsy = async () => {
    setLoading(true);
    const e = await Axios.get(`/api/enforcement-bots/etsy/search?keyword=${search}`);
    console.log(e);
    setTotalResults(e.data.count);
    setResults(e.data.results);
    setLoading(false);

  };

  const getScanRequests = async () => {
    const e = await Axios.get('/api/enforcement-bots/requests');
    console.log(e);
  };

  const getWebBotSellers = async () => {
    const e = await Axios.get('/api/enforcement-bots/sellers');
    console.log(e);
  };

  const getWebBotUrls = async () => {
    const e = await Axios.get('/api/enforcement-bots/urls');
    console.log(e);
  };

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          {loading ? <LoadingSpinner /> : <div>

            <div className="form-group">
              <label>Etsy Search</label>
              <input className="form-control" value={search} onChange={e => setSearch(e.target.value)} />
            </div>
            <button onClick={searchEtsy} className="btn btn-primary">Search Etsy</button>
          </div>}
          <br />
          <div>
            <button onClick={getScanRequests} className="btn btn-block btn-primary">Get Scan Requests</button>
            <button onClick={getWebBotSellers} className="btn btn-block btn-primary">Get Web Bot Sellers</button>
            <button onClick={getWebBotUrls} className="btn btn-block btn-primary">Get Web Bot URLS</button>
          </div>

        </div>
      </div>
      {!loading && results ? <div className="panel panel-portal">
        <div className="panel-body">
          <h3>Total Results: {totalResults}</h3>
          <h4>Results Preview</h4>
          <pre>{JSON.stringify(results, null, 2)}</pre>
        </div>
      </div> : null}
    </FullContent>
  );

};
