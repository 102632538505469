import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { NoteUserList } from '.';
import { NoteThread } from '../../shared';
import { LoadingSpinner } from '../shared';

interface IProps {
  accountId: number | string;
  accountType: 'vendor' | 'licensor';
}

export const InlineNotesListView = (props: IProps) => {

  const [threads, setThreads] = React.useState<NoteThread[]>([]);
  const [newNote, setNewNote] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getThreads();
    document.body.addEventListener('note-list-reload', noteListReload, false);

    return () => {
      document.body.removeEventListener('note-list-reload', noteListReload, false);
    };

  },              []);

  React.useEffect(() => {
    if (newNote) {
      getThreads();
    }

  },              [newNote]);

  const noteListReload = (e: CustomEvent) => {
    setNewNote(e.detail.thread);
  };

  const getThreads = async (reset = true) => {
    if (reset) {
      setLoading(true);
      setThreads([]);
    }
    const query = {
      account_id: props.accountId,
    };
    const cleanParams = omitBy(query, isNil);
    const qs = queryString.stringify(cleanParams);

    const t = await Axios.get(`/api/note-threads?${qs}`);
    setThreads(t.data.data.map((d: any) => NoteThread.fromApi(d)));
    setLoading(false);
  };

  let baseRoute = '/notes';
  if (props.accountType === 'vendor') {
    baseRoute = `/vendors/${props.accountId}/notes`;
  } else {
    baseRoute = `/clients/${props.accountId}/notes`;
  }

  const listItems = loading ? (<LoadingSpinner />) : threads.length ? threads.map(l => (
    <NavLink key={l.id} className="note-list-nav-link" to={`${baseRoute}/${l.id}`}>
      <div className="note-list-item">
        <div className="note-list-icon">
          {l.threadIcon ? <FontAwesomeIcon className={l.iconClass} icon={l.threadIcon} /> : null}

        </div>
        <div className="note-list-content">
          <div className="note-user-list">
            <div className="note-user-images">
              <img
                className="note-user-image"
                src={l.account.logo.getSize('th')}
              />
            </div>
            <div className="note-user-names small">
              <strong>
                {l.account.accountName}
              </strong>

            </div>
          </div>
          <NoteUserList users={l.users} />

          <h4 className="text-primary no-margin thread-title">
            <strong>{l.title}</strong>
            {l.hasAttachments ?
              <FontAwesomeIcon style={{ marginLeft: 10 }} className="text-muted" icon={faPaperclip} />
            : null}
          </h4>
          <p
            style={{ marginTop: 10, color: '#333' }}
            className="small"
            dangerouslySetInnerHTML={{ __html: l.previewMessage }} />
          <p className="small no-margin text-muted">
            Updated on {l.updatedAt.format('MMM DD, YYYY')} ({l.updatedAt.fromNow()}) {l.noteCode ? `• ${l.noteCode}` : ''}
          </p>
        </div>
      </div>
    </NavLink>

  )) : <h4 style={{ marginTop: 20 }} className="text-center"><strong>No Notes Found</strong></h4>;

  return (
    <div style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
      <div className="panel panel-portal">
        <div className="notes-list-container">
          <div className="notes-list">
            {listItems}

          </div>

        </div>
      </div>
    </div>

  );
};
