import { MarketplaceSitePage } from './MarketplaceSitePage';

export interface ISiteApiResponse {
  id: string;
  domain: string;
  title: string;
  referral_link: string;
  description: string;
  favicon_image_url: string;
  header_image_url: string;
  footer_image_url: string;
  site_logo_url: string;
  email_image_url: string;
  css: {
    font_family: string;
    link_color: string;
    button_bg_color: string;
    top_bg_color: string;
    top_color: string;
    nav_color: string;
    nav_bg_color: string;
  };
  nav_links: [{
    id: number;
    position: number;
    new_tab: boolean;
    path: string;
    text: string;
    external_url: string;
    internal_link: boolean;
    page_id: number;
  }];
  top_text: string;
  ios_url: string;
  android_url: string;
  facebook_url: string;
  instagram_url: string;
  twitter_url: string;
  pinterest_url: string;
  pages: any[];
}

export interface MarketplaceNavLink {
  id: number;
  position: number;
  newTab: boolean;
  path: string;
  text: string;
  externalUrl: string;
  internalLink: boolean;
  pageId: number | null;
}

export class MarketplaceSite {
  id: string;
  domain: string;
  title: string;
  referralLink: string;
  description: string;
  faviconImageUrl: string;
  headerImageUrl: string;
  footerImageUrl: string;
  siteLogoUrl: string;
  emailImageUrl: string;
  css: {
    fontFamily: string;
    linkColor: string;
    buttonBackgroundColor: string;
    topbackgroundColor: string;
    topColor: string;
    navColor: string;
    navBackgroundColor: string;
  };
  navLinks: MarketplaceNavLink[];
  topText: string;
  iosUrl: string;
  androidUrl: string;
  facebookUrl: string;
  instagramUrl: string;
  twitterUrl: string;
  pinterestUrl: string;
  pages: MarketplaceSitePage[];

  static fromApi(data: ISiteApiResponse) {
    const site = new MarketplaceSite();
    site.id = data.id;
    site.domain = data.domain;
    site.title = data.title;
    site.referralLink = data.referral_link;
    site.description = data.description;
    site.faviconImageUrl = data.favicon_image_url;
    site.headerImageUrl = data.header_image_url;
    site.footerImageUrl = data.footer_image_url;
    site.siteLogoUrl = data.site_logo_url;
    site.emailImageUrl = data.email_image_url;
    site.css = {
      fontFamily: data.css.font_family,
      linkColor: data.css.link_color,
      buttonBackgroundColor: data.css.button_bg_color,
      topbackgroundColor: data.css.top_bg_color,
      topColor: data.css.top_color,
      navColor: data.css.nav_color,
      navBackgroundColor: data.css.nav_bg_color,
    };
    site.navLinks = [];
    if (data.nav_links && data.nav_links.length > 0) {
      data.nav_links = data.nav_links.sort((a, b) => {
        return a.position - b.position;
      });
      for (const nl of data.nav_links) {
        site.navLinks.push({
          id: nl.id,
          position: nl.position,
          newTab: nl.new_tab,
          path: nl.path,
          text: nl.text,
          externalUrl: nl.external_url,
          internalLink: nl.internal_link,
          pageId: nl.page_id,
        });
      }
    }
    site.topText = data.top_text;
    site.iosUrl = data.ios_url;
    site.androidUrl = data.android_url;
    site.facebookUrl = data.facebook_url;
    site.instagramUrl = data.instagram_url;
    site.twitterUrl = data.twitter_url;
    site.pinterestUrl = data.pinterest_url;

    site.pages = data.pages ? data.pages.map(MarketplaceSitePage.fromApi) : [];

    return site;
  }
}
