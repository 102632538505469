import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import 'chartjs-plugin-colorschemes';
import 'core-js';
import { Quill } from 'react-quill';
import { AffinityTemplateVariable, TemplateContent, TemplateVariableBlot } from './components/shared';

library.add(fas);
library.add(fab);

// console.log(Quill.imports);

Quill.register({
  'blots/template-content': TemplateContent,
  'blots/template-variable': TemplateVariableBlot,
  'blots/affinity-template-variable': AffinityTemplateVariable,
},             true);

import * as React from 'react';
import * as  ReactDOM from 'react-dom';
import ReactApp from './ReactApp';

window['React'] = React;
window['ReactDOM'] = ReactDOM;

window['ReactApp'] = ReactApp;
