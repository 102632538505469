import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { UserContext } from '../../contexts';
import { AffinityClient } from '../../shared';
import { LoadingSpinner } from '../shared';
import { SortableHeader }  from '../shared/tables';
import { fetchDesignResponses } from './api';
import { DesignResponse } from './DesignResponse';
import { DesignResponseDeleteModal } from './DesignResponseDeleteModal';
import { DesignResponseListItem } from './DesignResponseListItem';
import { DesignResponseModal } from './DesignResponseModal';

interface IProps {
  accountId: number | null;
  showLicensor: boolean;
  showAddButton: boolean;
}

interface IState {
  loading: boolean;
  designResponses: any[];
  modalShown: boolean;
  modalValues: any;
  deleteModalShown: boolean;
  deleteResponse: DesignResponse | null;
}

export class DesignResponseGrid extends React.Component<IProps, IState> {

  protected _getDesignResponsesSource: CancelTokenSource;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      designResponses: [],
      modalShown: false,
      modalValues: null,
      deleteModalShown: false,
      deleteResponse: null,
    };

    this.getDesignResponses = this.getDesignResponses.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
  }

  componentDidMount() {
    this.getDesignResponses();
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.accountId !== this.props.accountId) {
      this.getDesignResponses();
    }
  }

  componentWillUnmount() {
    if (this._getDesignResponsesSource) {
      this._getDesignResponsesSource.cancel('Cancelled getDesignResponses() XHR due to unmount');
    }
  }

  getDesignResponses() {
    this.setState({ loading: true });
    if (this._getDesignResponsesSource) {
      this._getDesignResponsesSource.cancel('Cancelled getDesignResponses() XHR due to new request');
    }
    this._getDesignResponsesSource = Axios.CancelToken.source();
    fetchDesignResponses(this.props.accountId, this._getDesignResponsesSource)
      .then((response) => {
        const responses: DesignResponse[] = response.data.data.map((item: any) => {
          return {
            id: item.id,
            title: item.title,
            body: item.body,
            updatedAt: new Date(item.updated_at),
            account: new AffinityClient(item.account),
          };
        });

        this.setState({
          designResponses: responses,
          loading: false,
        });
      });
  }

  showModal(response?: DesignResponse) {
    this.setState({ modalShown: true, modalValues: response });
  }

  closeModal(designResponse?: DesignResponse) {
    if (designResponse) {
      const responses = this.state.designResponses;
      const index = responses.findIndex(response => Number(designResponse.id) === Number(response.id));
      if (index !== -1) {
        responses[index] = designResponse;
      } else {
        responses.push(designResponse);
      }
      this.setState({ modalShown: false, modalValues: null, designResponses: responses });
    } else {
      this.setState({ modalShown: false, modalValues: null });
    }
  }

  showDeleteModal(response: DesignResponse) {
    this.setState({ deleteModalShown: true, deleteResponse: response });
  }

  closeDeleteModal(designResponse: DesignResponse | null) {
    if (designResponse) {
      const responses = this.state.designResponses;
      const index = responses.findIndex(response => designResponse.id === response.id);
      if (index !== -1) {
        responses.splice(index, 1);
      }
      this.setState({ deleteModalShown: false, deleteResponse: null, designResponses: responses });

    } else {
      this.setState({ deleteModalShown: false, deleteResponse: null });
    }

  }

  // doesnt do anything
  sort() {
  }

  render() {

    if (this.state.loading) {
      return (<LoadingSpinner />);
    }

    let content;

    if (this.state.designResponses.length === 0) {
      content = (
        <div className="panel panel-body">
          <p>No design responses exist yet. Click "Add" to create one.</p>
        </div>
      );
    } else {
      content = (
        <div>
          <div className="panel panel-portal">
            <div className="table-responsive">
              <table className="table table-hover table-portal">
                <thead>
                  <tr>
                    { this.props.showLicensor ?
                    <SortableHeader
                      title="Licensor"
                      disableSort={true}
                      sortSelectFn={this.sort}
                      sortDir="desc"
                      sortKey="item"
                    />
                      : <></>
                    }
                    <SortableHeader
                      title="Title"
                      disableSort={true}
                      sortSelectFn={this.sort}
                      sortDir="desc"
                      sortKey="item"
                    />
                    <SortableHeader
                      title="Updated"
                      disableSort={true}
                      sortSelectFn={this.sort}
                      sortDir="desc"
                      sortKey="item"
                    />
                    <SortableHeader
                      title=""
                      disableSort={true}
                      sortSelectFn={this.sort}
                      sortDir="desc"
                      sortKey="item"
                    />

                  </tr>
                </thead>
                <tbody>
                  {this.state.designResponses.map(item => (
                    <DesignResponseListItem
                      showLicensor={this.props.showLicensor}
                      showModal={this.showModal}
                      showDeleteModal={this.showDeleteModal}
                      item={item}
                      key={item.id}
                    />
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );

    }

    return (
      <div>
        { this.props.showAddButton ?
          (<div className="panel panel-portal">
            <div className="panel-body" style={{ background: '#f9f9f9', padding: '5px' }}>
              <button onClick={() => this.showModal()} className="btn btn-default pull-right">Add Response</button>
            </div>
          </div>)
          : null
        }
        {content}
      <UserContext.Consumer>
        { (user) => {
          const accountId = this.props.accountId ? this.props.accountId : user.account.id;
          return (
              <DesignResponseModal
                shown={this.state.modalShown}
                values={this.state.modalValues}
                accountId={Number(accountId)}
                onClose={this.closeModal}
              />
          );
        }
        }
        </UserContext.Consumer>
        <DesignResponseDeleteModal
          shown={this.state.deleteModalShown}
          response={this.state.deleteResponse}
          onClose={this.closeDeleteModal}

        />
      </div>
    );
  }

}
