import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { UserContext } from '../../../contexts';
import FileAttachmentButton from './FileAttachmentButton';

interface IProps {
  submitComment: (comment: string, group: number, attachedFiles: File[]) => void;
  cancel: () => void;
  saving: boolean;
}

interface IState {
  comment: string;
  group: number;
  attachedFiles: File[];
}

export class DesignComment extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      comment: '',
      group: 4,
      attachedFiles: [],
    };
    this.updateFile = this.updateFile.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.updateGroup = this.updateGroup.bind(this);
    this.submitComment = this.submitComment.bind(this);
  }

  updateFile = (file: []) => {
    this.setState({ attachedFiles: file });
  }

  updateComment(event: any) {
    this.setState({ comment: event.target.value });
  }

  updateGroup(event: any) {
    this.setState({ group: Number(event.target.value) });
  }

  submitComment() {
    if (this.state.comment.length && !this.props.saving) {
      this.props.submitComment(this.state.comment, this.state.group, this.state.attachedFiles);
    }
  }

  render() {
    const saving = this.props.saving;
    return (
      <div style={{ marginTop: 10 }}>
        <label className="small">Send Comment</label>
        <UserContext.Consumer>
          {user => user.type === 'admin' ? (
            <div className="form-group">
              <select onChange={this.updateGroup} className="form-control" value={this.state.group}>
                <option value={4}>Send To All</option>
                <option value={3}>Send to Vendor</option>
                <option value={2}>Send to Licensor</option>
                <option value={1}>Send to Admin</option>
              </select>
            </div>
          ) : null}
        </UserContext.Consumer>

        <textarea
          style={{ marginBottom: 10, marginTop: 10 }}
          className="form-control"
          onChange={this.updateComment}
          value={this.state.comment}
        >
        </textarea>
        <FileAttachmentButton onFileSelect={this.updateFile} inputId={'commentFile'} />
        <button disabled={saving} onClick={this.submitComment} className="btn btn-success btn-block">
          {this.props.saving ? 'Sending Comment...' : 'Send Comment' }
        </button>
        <button disabled={saving} onClick={this.props.cancel} className="btn btn-default btn-block">Cancel</button>
      </div>

    );
  }
}
