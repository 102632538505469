import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeError } from '@stripe/stripe-js';
import Axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CCPaymentMethod } from '../../shared';
import { LoadingSpinner } from '../shared';
import { ProcessingFeeModal } from './ProcessingFeeModal';

interface IProps {
  amountDue?: number | string;
  accountId?: number;
  onCardSuccess: () => void;
  toggleModify: (type: 'cc' | null) => void;
  currentCardCount: number;
}

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

export const NewCreditCard = (props: IProps) => {
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState<null|StripeError>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [checkingIntent, setCheckingIntent] = useState(true);
  const [hasPendingIntent, setHasPendingIntent] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => props.toggleModify(isAdding ? 'cc' : null), [isAdding]);

  useEffect(() => {
    checkCurrentIntent();
  },        []);

  const onCancel = () => {
    setIsAdding(false);
    props.toggleModify(null);
  };

  const checkCurrentIntent = async () => {

    const hasAccountId = props.accountId ? `?account_id=${props.accountId}` : '';
    const setupIntentSecret = await Axios.get(`/api/setup-intents${hasAccountId}`).then((response) => {
      if (response.data.status === 'requires_payment_method') {
        return false;
      }
      return response.data.client_secret;
    }).catch(() => false);

    setCheckingIntent(false);
    if (setupIntentSecret) {
      setHasPendingIntent(true);
    } else {
      setHasPendingIntent(false);
    }
  };

  const handleSubmit = async () => {

    if (!stripe || !elements || processing) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }
    const testToken = await stripe.createToken(cardElement);

    if (testToken.token && testToken.token.card) {
      const card = testToken.token.card;
      if (card.funding !== 'credit')  {
        alert('Sorry, we are currently unable to accept this card. We\'re not able to accept debit, prepaid or other card types. Please enter in a different credit card to continue.');
        cardElement.clear();
        cardElement.focus();
        setProcessing(false);
        return;
      }
    }
    if (testToken.error) {
      setError(testToken.error);
      cardElement.focus();
      setProcessing(false);
      return;
    }

    const hasAccountId = props.accountId ? `?account_id=${props.accountId}` : '';

    const setupIntentSecret = await Axios.post(`/api/setup-intents${hasAccountId}`).then((response) => {
      return response.data.client_secret;
    });

    const setupIntent = await stripe.confirmCardSetup(setupIntentSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (setupIntent.error) {
      setError(setupIntent.error);
      setProcessing(false);

      return;
    }

    let foundCard = false;
    while (!foundCard) {
      // Wait at least 1 second per call That way we don't call the endpoint too often
      await new Promise(r => setTimeout(r, 1000));
      await Axios.get(`/api/payment-methods?${props.accountId ? `&account_id=${props.accountId}` : ''}`)
        .then((response) => {
          const cards = [];
          response.data.data.forEach((m: any) => {
            if (m.payment_method_type) {
              const type = m.payment_method_type;
              if (type.name === 'Card') {
                cards.push(CCPaymentMethod.fromApi(m));
              }
            }
          });
          foundCard = cards.length > props.currentCardCount;
        });
    }

    setProcessing(false);

    if (setupIntent.error) {
      setError(setupIntent.error);
      return;
    }
    props.onCardSuccess();

  };

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    if (event.error === null) {
      setError(null);
    }
    setCardComplete(event.complete);
  };

  const showModal = () => {
    setModalShown(true);
  };

  const hideModal = () => {
    setModalShown(false);
  };

  if (isAdding) {
    const buttonText = processing ? 'Adding Card...' : 'Add Credit Card';
    return (
      <div className="payment-method-panel">
        <div className="panel-body">
          <div className="payment-option">
            <div className="payment-title-total">
              <span className="text-primary">Add Credit Card</span>

            </div>
             <div>
              <p className="payment-description">Payment is charged to your credit card.</p>

              <p  className="payment-fee">INCLUDES PROCESSING FEE
                &nbsp;<span onClick={showModal} className="text-primary fee-learn-more">
                  <FontAwesomeIcon icon={faInfoCircle} /> Learn More
                </span>
              </p>
            </div>
            {error ? (
              <div className="alert alert-danger">
                {error.message}
              </div>
            ) : null}
            <div style={{ marginBottom: 20 }}>
              <CardElement
                className="form-control"
                options={CARD_OPTIONS}
                onChange={handleCardChange}

              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <button onClick={onCancel} className="btn btn-block btn-default payment-method-button">
                Cancel
                  </button>
              <button onClick={handleSubmit} style={{ marginLeft: 5 }} className="btn btn-block btn-primary payment-method-button">
                {buttonText}
              </button>
            </div>

          </div>

        </div>
        <ProcessingFeeModal isShown={modalShown} onClose={hideModal} />

      </div>

    );
  }

  let body;
  if (checkingIntent) {
    body = (<LoadingSpinner />);
  } else {
    if (hasPendingIntent) {
      body = (
        <p className="text-center">
          We are currently processing the previous card added to your account.
          Please come back later to check if this has changed.
        </p>
      );
    } else {
      body = (
        <div className="payment-option">
          <div className="payment-title-total">
            <span className="text-primary">Add Credit Card</span>
          </div>
          <p className="payment-description">Payment is charged to your credit card.</p>
          <p className="payment-fee">INCLUDES PROCESSING FEE
          &nbsp; <span onClick={showModal} className="text-primary fee-learn-more">
              <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faInfoCircle} /> Learn More
          </span>
          </p>
          <button onClick={() => setIsAdding(true)} className="btn btn-block btn-default">Add Credit Card</button>

        </div>
      );

    }
  }

  return (
    <div className="payment-method-panel">
      {body}
      <ProcessingFeeModal isShown={modalShown} onClose={hideModal} />
    </div>
  );

};
