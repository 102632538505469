import {  CRS, Icon, LatLng } from 'leaflet';
import { LeafletMouseEvent } from 'leaflet';
import * as React from 'react';
import { Map, Marker, Popup } from 'react-leaflet';
import { Design, DesignActivity, DesignIteration } from '../../../shared';
import { DesignActivityPanel } from './DesignActivityPanel';
import DesignOverlay from './DesignOverlay';

interface IProps {
  iteration: DesignIteration;
  spot: number;
  isSecondary: boolean;
  canAddPin: boolean;
  pinAdded?: (pin: LatLng) => void;
  selectedActivity?: DesignActivity | null;
  activityOpened?: () => void;
  design: Design;
}

interface IState {
  loadingImage: boolean;
  image: HTMLImageElement | null;
  pin: LatLng | null;
}

interface MarkerActivity {
  activity: DesignActivity;
  ref: any;
}

export class Iteration extends React.Component <IProps, IState> {

  mapRef: any;
  pinRefs: MarkerActivity[] = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      loadingImage: true,
      image: null,
      pin: null,
    };

    this.loadImageLayer = this.loadImageLayer.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.selectPin = this.selectPin.bind(this);
  }

  componentDidMount() {

  }

  componentDidUpdate() {
    if (this.props.selectedActivity) {
      this.selectPin(this.props.selectedActivity);
      if (this.props.activityOpened) {
        this.props.activityOpened();
      }
    }

  }

  componentWillUnmount() {
    if (this.state.image) {
      this.state.image.remove();
    }
  }

  loadImageLayer() {
    const tempImg = document.createElement('img');
    tempImg.src = this.props.iteration.image.getSize('or');
    tempImg.onload = (() => {

      this.setState({ loadingImage: false, image: tempImg });
    });
  }

  handleMapClick(event: LeafletMouseEvent) {
    if (this.props.canAddPin) {
      this.mapRef.leafletElement.closePopup();
      this.setState({ pin: event.latlng });
      if (this.props.pinAdded) {
        this.props.pinAdded(event.latlng);
      }
    }

  }

  selectPin(activity: DesignActivity) {
    const a = this.pinRefs.find(ma => ma.activity.id === activity.id);
    if (a) {
      a.ref.leafletElement.openPopup();
    }
  }

  render() {
    const iteration = this.props.iteration;
    const overlay = this.state.image ? (
      <DesignOverlay url={iteration.image.getSize('or')} image={this.state.image} />
    ) : null;
    const markerIcon = new Icon({
      iconUrl: 'assets/marker-icon.png',
      iconRetinaUrl: 'assets/marker-icon-2x.png',
      shadowUrl: 'assets/marker-shadow.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });
    this.pinRefs = [];
    const activityMarkers = iteration.activity
      .filter(activity => activity.x && activity.y)
      .map((activity) => {
        const latLng = new LatLng(activity.y, activity.x);
        const r: MarkerActivity = {
          activity,
          ref: null,
        };
        this.pinRefs.push(r);
        const m = (
          <Marker ref={ref => r.ref = ref } key={activity.id} icon={markerIcon} position={latLng} >
            <Popup >
              <strong>{activity.user.firstName}:</strong> <div dangerouslySetInnerHTML={{ __html: activity.message }}></div>
            </Popup>
          </Marker>
        );

        return m;
      });
    const tempMarker = this.state.pin ? (
      <Marker icon={markerIcon} position={this.state.pin} />
    ) : null;
    const activity = iteration.activity.map(activity =>
      (<DesignActivityPanel onClick={this.selectPin} key={activity.id} activity={activity} />),
    );
    const activityContainer =  this.props.isSecondary ? (
      <div className="col-xl-3 col-lg-4 col-md-5">
        {activity}
      </div>
    ) : null;
    const itercationClass = this.props.isSecondary ? 'col-xl-9 col-lg-8 col-md-7' : 'col-md-12';
    const reviewTimeRow = iteration.decision ? (
      <tr>
        <td><strong>Review Time</strong></td>
        <td>{iteration.reviewDuration}</td>
      </tr>
    ) : null;
    const reviewedAtRow = iteration.decision ? (
      <tr>
        <td><strong>Reviewed At</strong></td>
        <td>{iteration.decision.format('MMMM Do YYYY, h:mm:ss a')}</td>
      </tr>
    ) : null;
    const iterationBody = (
      <div >
        <Map
          whenReady={this.loadImageLayer}
          onClick={this.handleMapClick}
          style={{ height: 700 }}
          center={[270.69, 129.29]}
          zoom={2}
          minZoom={-1}
          maxZoom={3}
          zoomSnap={0}
          attributionControl={false}
          crs={CRS.Simple}
          keyboard={true}
          zoomAnimation={false}
          zoomDelta={.4}
          ref={ref => this.mapRef = ref}
        >

          {overlay}

          {activityMarkers}
          {tempMarker}
        </Map>
        <table style={{ marginTop: 10 }} className="table table-striped">
          <tbody>
            <tr>
              <td><strong>Version #</strong></td>
              <td>{this.props.spot}</td>
            </tr>
            <tr>
              <td><strong>Filename</strong></td>
              <td>{iteration.image.previousFileName}</td>
            </tr>
            {this.props.design.channelBooleanLabel ? <tr>
              <td><strong>Distribution Channels</strong></td>
              <td>{this.props.design.channelBooleanLabel}</td>
            </tr> : null}
            {this.props.design.customer ? <tr>
              <td><strong>Customer Name</strong></td>
              <td>{this.props.design.customer}</td>
            </tr> : null}
            {this.props.design.unitEstimation ? <tr>
              <td><strong>Estimated Units</strong></td>
              <td>{this.props.design.unitEstimation}</td>
            </tr> : null}
            <tr>
              <td><strong>Uploaded At</strong></td>
              <td>{iteration.created.format('MMMM Do YYYY, h:mm:ss a')}</td>
            </tr>
            {reviewedAtRow}
            {reviewTimeRow}
            <tr>
              <td><strong>Status</strong></td>
              <td>{iteration.phase.name}</td>
            </tr>

          </tbody>
        </table>
        <a target="_blank" href={iteration.image.getSize('or')} className="btn btn-default btn-block">Download</a>

      </div>

    );
    return (
      <div className="row">
        <div className={itercationClass}>
          {this.props.isSecondary ? (
            <div className="panel panel-portal">
              <div className="panel-body">
                {iterationBody}

              </div>
            </div>
          ) : iterationBody}
        </div>
        {activityContainer}
      </div>

    );

  }

}
