import axios, { CancelTokenSource } from 'axios';
import { camelCase } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';

import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DocumentTitle from 'react-document-title';
import { withRouter } from 'react-router';
import { getReportingAdmins } from '../../../api';
import { RoyaltyAdminsContext } from '../../../contexts';
import User from '../../../shared/User';
import { getAffinityPageTitle, getQuarter } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { SearchFilterBar, SortDirection, StatusFilterBar } from '../../shared';
import { IndexTable } from '../IndexTable';
import { IndexTableFooter } from '../IndexTableFooter';
import { RoyaltyReportSidebar } from '../RoyaltyReportSidebar';
import { AddRoyaltyReportModal } from './AddRoyaltyReportModal';

interface IFilters {
  search?: string;
  vendorId?: number|null;
  phaseId?: number|null;
  page: number;
  perPage?: number;
  quarter?: string;
  sortBy?: string;
  sortDir?: SortDirection;
  submitted?: boolean|null;
  selectedAdminId?: string;
}

const defaultFilters = {
  search: '',
  vendorId: null,
  phaseId: null,
  page: 1,
  perPage: 24,
  quarter: '',
  sortBy: '',
  sortDir: 'desc',
  submitted: null,
  selectedAdminId: null,
};

class RoyaltyReportsIndexPage extends React.Component<any, any> {

  protected _fetchDataSource: any;
  protected _getLabelsSource: any;
  protected _loadAdminsSource: CancelTokenSource;

  public constructor(props: any) {
    super(props);

    this.state = {
      labels: [],
      quarters: [],
      loadingLabels: false,
      filters: defaultFilters,
      data: [],
      loadingData: false,

      pagination: {
        totalResults: 0,
        currentPage: 1,
        hasNext: false,
      },

      loadingAdmins: false,
      admins: [],
      assignableAdmins: [],
      unassignableAdmins: [],

      showAddModal: false,
      vendorProfile: { name: '' },
    };

    if (this.props.match.params.vendorId) {
      this.state.filters.vendorId = parseInt(this.props.match.params.vendorId, 10);
    } else {
      this.state.filters.vendorId = null;
    }

    this.handleChoosePage = this.handleChoosePage.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getLabels = this.getLabels.bind(this);
    this.selectLabel = this.selectLabel.bind(this);
    this.selectSort = this.selectSort.bind(this);
    this.updateSearchFilterBar = this.updateSearchFilterBar.bind(this);
    this.showAddModal = this.showAddModal.bind(this);
    this.hideAddModal = this.hideAddModal.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  public componentDidMount() {
    const queryStringFilters = this.getFiltersFromQueryString();

    const filters = { ...queryStringFilters, vendorId: this.state.filters.vendorId };
    this.setState({ filters });

    this.getLabels(filters.vendorId);
    this.fetchData(filters);

    this.getAdmins();
  }

  public componentWillUnmount() {
    if (this._getLabelsSource) {
      this._getLabelsSource.cancel('Cancelled getLabels XHR due to unmount.');
    }

    if (this._fetchDataSource) {
      this._fetchDataSource.cancel('Cancelled fetchData XHR due to unmount.');
    }

  }

  public handleChoosePage(i: number) {
    this.updateFilters({ page: i }, true);
  }

  getAdmins() {
    this.setState({ loadingAdmins: true });
    if (this._loadAdminsSource) {
      this._loadAdminsSource.cancel('Cancelled RoyaltyReportsIndexPage:getAdmins XHR due to new request.');
    }
    this._loadAdminsSource = axios.CancelToken.source();
    getReportingAdmins(this._loadAdminsSource).then((admins) => {
      this.setState({
        loadingAdmins: false,
        assignableAdmins: admins.assignable,
        unassignableAdmins: admins.unassignable,
      });
    });
  }

  public refresh() {
    this.fetchData(this.state.filters);
  }

  public updateFilters(filters: IFilters, reload: boolean) {

    const newFilters = { ...this.state.filters, ...filters };

    this.setQueryStringFromFilters(newFilters);
    this.setState({ filters: newFilters });

    if (reload) {
      this.fetchData(newFilters);
    }
  }

  public fetchData(filters: IFilters) {
    this.setState({ loadingData: true });
    const params = this.getFetchDataProps(filters);
    if (this._fetchDataSource) {
      this._fetchDataSource.cancel('Cancelled fetchData XHR due to new request.');
    }
    this._fetchDataSource = axios.CancelToken.source();
    axios.get('/api/royalty-reports', { params, cancelToken: this._fetchDataSource.token })
      .then((res) => {

        const rows = res.data.data.map((item: any) => {
          return {
            id: parseInt(item.id, 10),
            due: item.total_due,
            itemText: 'Text',
            quarter: item.quarter ? parseInt(item.quarter, 10) : null,
            year: item.year ? parseInt(item.year, 10) : null,
            updated: item.updated_at ? item.updated_at : null,
            submitted: item.submitted_at ? item.submitted_at : null,
            resubmitted: item.was_resubmitted,
            vendor: item.vendor,
            phaseId: item.phase.id,
            assignedAdmin: item.admin_user ? (new User(item.admin_user)) : null,
          };
        });

        const paginationData = res.data.meta.pagination;
        this.setState({pagination: {
          totalResults: paginationData.total_count,
          currentPage: paginationData.page,
          hasNext: paginationData.has_next,
        }});
        this.setState({ data: rows });
        this.setState({ loadingData: false });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        }
      });
  }

  public getFetchDataProps(filters: IFilters) {

    let submittedValue = null;
    if (filters.submitted === true) {
      submittedValue = 1;
    } else if (filters.submitted === false) {
      submittedValue = 0;
    }

    return {
      search: filters.search,
      limit: filters.perPage,
      vendor_id: filters.vendorId,
      phase_id: filters.phaseId,
      page: filters.page,
      quarter: filters.quarter,
      sort_by: filters.sortBy,
      sort_asc: filters.sortDir === 'asc' ? 1 : 0,
      is_submitted: submittedValue,
      admin_id: filters.selectedAdminId,
    };
  }

  public setQueryStringFromFilters(filters: IFilters) {
    const baseUrl = this.props.location.pathname;

    let submittedVal = null;
    if (filters.submitted !== null) {
      submittedVal = (filters.submitted) ? 'yes' : 'no';
    }

    const queryProps = {
      search: filters.search,
      phase_id: filters.phaseId,
      page: filters.page,
      quarter: filters.quarter,
      per_page: filters.perPage,
      sort_by: filters.sortBy,
      sort_dir: filters.sortDir === 'asc' ? 'asc' : 'desc',
      submitted: submittedVal,
      admin_id: filters.selectedAdminId,
    };

    // TODO: take snake-case/camel-case into consideration
    const filteredQueryProps = Object.keys(queryProps)
      .filter(key => queryProps[key] !== defaultFilters[camelCase(key)])
      .reduce((obj, key) => {
        obj[key] = queryProps[key];
        return obj;
      },      {});

    this.props.history.replace(`${baseUrl}?${queryString.stringify(filteredQueryProps)}`);
  }

  public getFiltersFromQueryString(): IFilters {
    const queryValues = queryString.parse(this.props.location.search);

    let submittedVal = null;
    if (queryValues.submitted && queryValues.submitted === 'yes') {
      submittedVal = true;
    } else if (queryValues.submitted && queryValues.submitted === 'no') {
      submittedVal = false;
    }

    return {
      search: queryValues.search || defaultFilters.search,
      phaseId: (queryValues.phase_id && parseInt(queryValues.phase_id, 10)) || defaultFilters.phaseId,
      page: (queryValues.page && parseInt(queryValues.page, 10)) || defaultFilters.page,
      quarter: queryValues.quarter || defaultFilters.quarter,
      perPage: queryValues.per_page || defaultFilters.perPage,
      sortBy: queryValues.sort_by || defaultFilters.sortBy,
      sortDir: queryValues.sort_dir || defaultFilters.sortDir,
      submitted: submittedVal,
      selectedAdminId: queryValues.admin_id || defaultFilters.selectedAdminId,
    };
  }

  public getLabels(vendorId: number|null) {
    this.setState({ loadingLabels: true });
    if (this._getLabelsSource) {
      this._getLabelsSource.cancel('Cancelled getLabels XHR due to new request.');
    }
    this._getLabelsSource = axios.CancelToken.source();

    const requestParams = {
      vendor_id: vendorId,
    };

    const axiosConfig = {
      params: requestParams,
      cancelToken: this._getLabelsSource.token,
    };

    axios.get('/api/royalty-reports/filterLabels', axiosConfig)
      .then((res) => {

        const labels = res.data.data.labels.map((labelItem: any) => ({
          id: labelItem.id,
          count: labelItem.count,
          alertClass: labelItem.alert_class,
          name: labelItem.phase,
        }));

        const quarters = res.data.data.quarters.map((quarterItem: any) => ({
          label: quarterItem.label,
          value: quarterItem.value,
        }));

        this.setState({
          labels,
          quarters,
        });
        this.setState({ loadingLabels: false });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        }
      });
  }

  public selectLabel(labelId: number) {
    this.updateFilters({ phaseId: labelId, page: 1 }, true);
  }

  public updateSearchFilterBar(items: any, reload: boolean) {
    this.updateFilters({ ...items, page: 1 }, reload);
  }

  public selectSort(sortBy: string, sortDir: SortDirection) {
    const newFilters = { ...this.state.filters, sortBy, sortDir, page: 1 };
    this.setState({ filters: newFilters });
    this.fetchData(newFilters);
  }

  public showAddModal() {
    this.setState({ showAddModal: true });
  }

  public hideAddModal() {
    this.setState({ showAddModal: false });
  }

  public render() {

    const isVendorView = this.state.filters.vendorId !== null || this.props.user.type === 'vendor';
    const isAdmin = this.props.user.type === 'admin';
    let oldSidebar;
    if (isVendorView) {
      oldSidebar = this.props.user.type === 'admin' ? (
        <RoyaltyReportSidebar showAddModal={this.showAddModal}/>
      ) : (
        <RoyaltyReportSidebar/>
      );
    }
    const date = new Date();
    const currentQuarter = getQuarter(date);

    let y = date.getFullYear();
    if (currentQuarter === 3 || currentQuarter === 4) {
      y = y - 1;
    }

    const mainContent = (
      <DocumentTitle title={getAffinityPageTitle('Royalties')}>
        <RoyaltyAdminsContext.Provider value={this.state.assignableAdmins}>
          <div>
            <div className="">
              {oldSidebar}

            </div>
            {(this.props.user.type === 'vendor') ? (
              <></>
              ) : (
                <StatusFilterBar
                  labels={this.state.labels}
                  selected={this.state.filters.phaseId}
                  selectFn={this.selectLabel}
                />
            )}

          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-portal">
                <div className="panel-body" style={{ background: '#f9f9f9', padding: '5px' }}>

                  <SearchFilterBar
                    changedFn={this.updateSearchFilterBar}
                    quarters={this.state.quarters}
                    assignableAdmins={this.state.assignableAdmins}
                    unassignableAdmins={this.state.unassignableAdmins}
                    showAdminFilter={isAdmin}
                    showSubmittedFilter={isAdmin}
                    {...this.state.filters}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="panel panel-portal">
                {this.state.loadingData ? (
                  <div>
                    <div className="ajax-loader">
                      <span><FontAwesomeIcon icon={faSpinner} spin /></span>
                    </div>
                    <div style={{ padding: '5px 0', fontWeight: 'bold' }}>Loading...</div>
                  </div>
                ) : (
                  <IndexTable
                    rows={this.state.data}
                    selectedSort={this.state.filters.sortBy}
                    sortDir={this.state.filters.sortDir}
                    sortSelectFn={this.selectSort}
                    history={this.props.history}
                    showVendorCol={!isVendorView}
                    showAssignedAdminCol={!isVendorView}
                    showQuarterRangeCol={isVendorView}
                  />
                )}
              </div>
            </div>
            <div className="col-xs-12">
              <IndexTableFooter choosePageFn={this.handleChoosePage} {...this.state.pagination} />
            </div>
          </div>
            <AddRoyaltyReportModal
              finalYear={y}
              shown={this.state.showAddModal}
              onClose={this.hideAddModal}
              onSubmit={this.refresh}
              vendorId={this.state.filters.vendorId}
            />
        </div>
        </RoyaltyAdminsContext.Provider>
      </DocumentTitle>
    );

    const breadcrumbs = [{ name: 'Royalties' }];

    const wrapped = <FullContent breadcrumbs={breadcrumbs} >{mainContent}</FullContent>;

    return wrapped;
  }
}

const component = withRouter(RoyaltyReportsIndexPage);
export { component as RoyaltyReportsIndexPage };
