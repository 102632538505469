import * as React from 'react';
import { ChangeEvent } from 'react';
import { IAvailableProductEntry } from '../api';
import { UploadSectionCategory } from './UploadSectionCategory';

interface IProps {
  vendorId: string | null;
  availableProducts: IAvailableProductEntry[];
  onCategoryDrop: (category: IAvailableProductEntry, acceptedFiles: File[], rejectedFiles: File[]) => void;
}

interface IState {
  searchText: string;
}

const designCategoriesStyle = {
  padding: 0,
};

export class UploadSection extends React.Component <IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      searchText: '',
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      searchText: event.target.value,
    });
  }

  render() {
    const categoryItems = this.props.availableProducts.filter((availableProduct) => {
      const searchText = this.state.searchText.toLowerCase();
      return (searchText === '')
        || availableProduct.categoryTree.some(treeItem => treeItem.name.toLowerCase().includes(searchText));
    }).map((availableProduct) => {
      return <UploadSectionCategory
        key={availableProduct.categoryId}
        category={availableProduct}
        onDrop={this.props.onCategoryDrop}
      />;
    });

    return (
      <div>
        <div className="input-group">
          <span className="input-group-addon"><i className="fa fa-search"></i></span>
          <input
            type="text"
            value={this.state.searchText}
            onChange={this.handleSearchChange}
            className="form-control"
            placeholder="Filter products"
          />
        </div>

        <ul style={designCategoriesStyle}>
          {categoryItems}
        </ul>

      </div>
    );
  }
}
