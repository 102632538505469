import * as moment from 'moment';
import { AffinityClient } from './AffinityClient';
import { AffinityImage, ImageApiResponse } from './Image';
import { ISiteApiResponse, MarketplaceSite } from './MarketplaceSite';

export interface IAdApiResponse {
  id: number;
  title: string;
  url: string;
  url_text: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  image: ImageApiResponse;
  site: ISiteApiResponse;
  licensor: any;
}

export class MarketplaceAdvertisement {
  id: number;
  title: string;
  url: string;
  urlText: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  deletedAt: moment.Moment | undefined;
  image: AffinityImage | undefined;
  site: MarketplaceSite | undefined;
  licensor: AffinityClient | undefined;

  static fromApi(data: IAdApiResponse) {
    const ad = new MarketplaceAdvertisement();
    ad.id = data.id;
    ad.title = data.title;
    ad.url = data.url;
    ad.urlText = data.url_text;
    ad.createdAt = moment(data.created_at);
    ad.updatedAt = moment(data.updated_at);
    if (data.deleted_at) {
      ad.deletedAt = moment(data.deleted_at);
    }
    if (data.image) {
      ad.image = AffinityImage.fromApi(data.image);
    }
    if (data.site) {
      ad.site = MarketplaceSite.fromApi(data.site);
    }
    if (data.licensor) {
      ad.licensor = new AffinityClient(data.licensor);
    }

    return ad;
  }
}
