import { Form, Formik } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { UserContext } from '../../contexts';
import User from '../../shared/User';
import { NoteForm, NoteFormValidation, NoteFormValues } from './forms';
interface IProps {
  onCancel: () => void;
  onCreate: (values: NoteFormValues) => void;
}

export const NewNoteForm = (props: IProps) => {
  const user = React.useContext(UserContext);
  const fakeApi = {
    id: Number(user.id),
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: '',
    image: user.image,
    type: user.type || '',

  };
  const currentUser = User.fromApi(fakeApi);
  const iv: NoteFormValues = {
    note: '',
    users: [currentUser],
    accountUsers: [],
    contactMethod: null,
    contactOrigin: null,
    attachments: [],
    noteDate: moment().format('YYYY-MM-DD'),
  };

  return (
    <div>
      <Formik validationSchema={NoteFormValidation} initialValues={iv} onSubmit={props.onCreate} >
        {formProps => (
          <Form>
            <NoteForm {...formProps} />
            <div style={{ justifyContent: 'space-between' }} className="flex-container">

              <button type="button" onClick={() => props.onCancel()} className="btn btn-default">Cancel</button>
              <button className="btn btn-primary">Create Note</button>
            </div>
          </Form>
        )}
      </Formik>

    </div>
  );

};
