import { faFlag, faThumbtack } from '@fortawesome/pro-solid-svg-icons';
import * as moment from 'moment';
import { AccountProfile } from '../AccountProfile';
import { AffinityImage } from '../Image';
import User from '../User';
import { Note, NoteApiResponse } from './Note';
import { NoteThreadCategory, NoteThreadCategoryApiResponse } from './NoteThreadCategory';

export interface NoteThreadApiResponse {
  id: number;
  created_at: string;
  updated_at: string;
  subject: string;
  note_code: string;
  is_pinned: boolean;
  has_warning: boolean;
  notes?: NoteApiResponse[];
  users?: any[];
  account: any;
  has_attachments: boolean;
  has_multiple_notes: boolean;
  categories: NoteThreadCategoryApiResponse[];
  latest_note: string;

}

export class NoteThread {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  subject: string;
  noteCode: string;
  isPinned: boolean;
  hasWarning: boolean;
  notes: Note[] = [];
  users: User[] = [];
  accountPrimaryContact: User;
  hasAttachments: boolean;
  hasMultipleNotes: boolean;
  categories: NoteThreadCategory[] = [];
  account: AccountProfile;
  latestNote: string;

  static fromApi(data: NoteThreadApiResponse) {
    const t = new NoteThread();
    t.id = data.id;
    t.createdAt = moment(data.created_at);
    t.updatedAt = moment.utc(data.updated_at).local();
    t.subject = data.subject ? data.subject : 'No Subject';
    t.noteCode = data.note_code;
    t.isPinned = data.is_pinned;
    t.hasWarning = data.has_warning;
    t.hasAttachments = data.has_attachments;
    t.hasMultipleNotes = data.has_multiple_notes;
    t.latestNote = data.latest_note;
    if (data.notes) {
      t.notes = data.notes.map(n => Note.fromApi(n));
    }
    if (data.users) {
      t.users = data.users.map(d => User.fromApi(d));
    }
    if (data.account) {
      t.account = AccountProfile.fromApi(data.account);
      if (data.account.primary_contact) {
        t.accountPrimaryContact = User.fromApi(data.account.primary_contact);
        if (data.account.image) {
          t.accountPrimaryContact.image = AffinityImage.fromApi(data.account.image);
        }
      }

    }
    if (data.categories) {
      t.categories = data.categories.map(c => NoteThreadCategory.fromApi(c));
    }
    return t;
  }

  replaceNote(note: Note, remove = false) {
    const index = this.notes.findIndex(n => n.id === note.id);
    console.log(index);
    if (index !== -1) {
      if (remove) {
        this.notes.splice(index, 1);
      } else {
        this.notes.splice(index, 1, note);
      }
    }
  }

  get previewMessage() {
    return this.latestNote ? this.latestNote : 'No Preview';
  }

  get threadIcon() {
    if (this.isPinned) {
      return faThumbtack;
    }
    if (this.hasWarning) {
      return faFlag;
    }
    return null;
  }

  get iconClass() {
    if (this.isPinned) {
      return 'text-warning';
    }
    if (this.hasWarning) {
      return 'text-danger';
    }
    return '';
  }

  get title() {
    const c = this.categories.length ? `${this.categories.map(c => c.name).join(', ')}: ` : '';
    return `${c}${this.subject}`;
  }

}
