import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChartPie, faGlobe, faImage, faUsdCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface IProps {
  icon: IconProp;
  title: string;
  subtitle: string;
}

export const HiddenPerformanceModule = (props: IProps) => {

  return (
    <div className="no-sales-data-analytics" style={{}}>
      <FontAwesomeIcon className="text-primary" icon={props.icon} style={{ fontSize: 28 }} />
      <h4>{props.title}</h4>
      <p className="text-muted">
        {props.subtitle}
      </p>
    </div>
  );
};

export const HiddenSalesDataModule = () => {
  return <HiddenPerformanceModule
    icon={faChartPie}
    title="Sales data not available"
    subtitle="Analytics will become available as sales are reported."
  />;
};

export const HiddenGeoDataModule = () => {
  return <HiddenPerformanceModule
    icon={faGlobe}
    title="Geolocation data not available"
    subtitle="Analytics will become available as sales are reported with geolocation data."

  />;
};

export const HiddenPayments = () => {
  return <HiddenPerformanceModule
    icon={faUsdCircle}
    title="Payment data not available"
    subtitle="Payment reports will be displayed after we process payments for your program."

  />;
};

export const HiddenInsignia = () => {
  return <HiddenPerformanceModule
    icon={faImage}
    title="Insignia data not available"
    subtitle="Insignia reports will be displayed after we start tagging product designs."

  />;
};
