import * as moment from 'moment';
import { Design, Insignia, InsigniaApiResponse } from '.';
import { IGalleryFormValues, IProductFormValues } from '../components/Marketplace';
import { IOptionGroup } from '../components/Marketplace/Products/OptionGroupInput';
import { AffinityClient } from './AffinityClient';
// import { dataURItoBlob } from '../utils';
import { Vendor } from './Vendor';

export interface Category {
  id: number;
  name: string;
  type: string;
}

export interface ProductImage {
  id: number;
  urls: {
    th: string,
    sm: string,
    md: string,
    or: string,
  };
}

export interface MarketplaceTag {
  id: number;
  name: string;
  keywords: string;
}
export interface MarketplaceProductApiResponse {
  id: number | string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  decision_at: string | null;
  vendor_updated_at: string | null;
  name: string;
  description: string;
  vendor_product_identifier: string;
  price: string;
  regular_price: string;
  sale_price: string;
  is_on_sale: boolean;
  product_web_address: string;
  is_buy_now: boolean;
  is_shopify: boolean;
  is_pending_review: boolean;
  is_approved: boolean;
  is_archived: boolean;
  review_message: string;
  shipping_price_first: string;
  shipping_price_first_default: boolean;
  shipping_price_additional: string;
  marketplace_returns_text: string;
  marketplace_returns_text_default: boolean;
  marketplace_delivery_text: string;
  marketplace_delivery_text_default: boolean;
  minimum_quantity: number | string;
  minimum_quantity_default: boolean;
  processing_duration_quantity: number | string;
  processing_duration_term: { id: number, name: string };
  processing_duration_quantity_default: boolean;
  primary_image: ProductImage;
  secondary_images: ProductImage[];
  category: Category;
  organization: { id: number, name: string, short_name: string };
  vendor: { id: number, name: string, short_name: string, status: { id: number, display: string } };
  option_groups: IOptionGroup[];
  tags: MarketplaceTag[];
  insignia?: InsigniaApiResponse;
  customization: boolean;
  stay_archived: boolean;
  upis: any[];
  designs: any[];
  internal_rating: number;
}
export class MarketplaceProduct {
  id: number | string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  deletedAt: null | moment.Moment;
  decisionAt: null | moment.Moment;
  vendorUpdatedAt: null | moment.Moment;
  name: string;
  description: string;
  vendorProductId: string;
  price: string;
  regularPrice: string;
  salePrice: string;
  isOnSale: boolean;
  productWebAddress: string;
  isBuyNow: boolean;
  isShopify: boolean;

  isPendingReview: boolean;
  isApproved: boolean;
  isArchived: boolean;
  reviewMessage: string;

  defaultShippingPrice: boolean;
  defaultFulfillment: boolean;
  defaultShippingPolicy: boolean;
  defaultReturnPolicy: boolean;
  defaultMinimumQuantity: boolean;

  shippingPriceFirst: string;
  shippingPriceAdditional: string;
  marketplaceReturnsText: string;
  marketplaceDeliveryText: string;
  minimumQuantity: any;
  processingDurationQuantity: any;
  processingDurationTermId: number;

  options: IOptionGroup[];

  primaryImage: ProductImage;

  newPrimaryImage: File;

  secondaryImages: ProductImage[];

  newSecondaryImages: any[];

  category: Category | undefined;

  organization: AffinityClient;

  insignia: Insignia | null;

  vendor: Vendor;

  tags: MarketplaceTag[];

  designs?: Design[];

  customization: boolean;

  stayArchived: boolean;
  upis: any[];

  internalRating: number;

  constructor() {
    this.organization = new AffinityClient({ id: 0 });
    this.vendor = new Vendor({ id: 0 });
  }

  static fromApi(data: MarketplaceProductApiResponse) {
    const product = new MarketplaceProduct();
    product.id = data.id;
    product.createdAt = moment(data.created_at);
    product.updatedAt = moment(data.updated_at);
    product.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;
    product.decisionAt = data.decision_at ? moment(data.decision_at) : null;
    product.vendorUpdatedAt = data.vendor_updated_at ? moment(data.vendor_updated_at) : null;
    product.name = data.name;
    product.description = data.description;
    product.vendorProductId = data.vendor_product_identifier;
    product.price = data.price;
    product.regularPrice = data.regular_price;
    product.salePrice =  data.sale_price;
    product.isOnSale = data.is_on_sale;
    product.productWebAddress =  data.product_web_address;
    product.isBuyNow =  data.is_buy_now;
    product.isShopify = data.is_shopify;
    product.isPendingReview =  data.is_pending_review;
    product.isApproved =  data.is_approved;
    product.isArchived = data.is_archived;
    product.reviewMessage = data.review_message;
    product.shippingPriceFirst =  data.shipping_price_first;
    product.defaultShippingPrice = data.shipping_price_first_default;
    product.shippingPriceAdditional =  data.shipping_price_additional;
    product.marketplaceReturnsText =  data.marketplace_returns_text;
    product.defaultReturnPolicy = data.marketplace_returns_text_default;
    product.marketplaceDeliveryText =  data.marketplace_delivery_text;
    product.defaultShippingPolicy = data.marketplace_delivery_text_default;
    product.minimumQuantity =  data.minimum_quantity;
    product.defaultMinimumQuantity = data.minimum_quantity_default;
    product.processingDurationQuantity =  data.processing_duration_quantity;
    product.defaultFulfillment = data.processing_duration_quantity_default;
    product.primaryImage =  data.primary_image;
    product.secondaryImages = data.secondary_images;
    product.category = data.category;
    product.customization = data.customization;
    product.stayArchived = data.stay_archived;
    product.internalRating = data.internal_rating;
    if (data.organization) {
      product.organization =  new AffinityClient(data.organization);
    }
    if (data.vendor) {
      product.vendor = new Vendor(data.vendor);
    }

    if (data.option_groups) {
      product.options = data.option_groups;
    }

    if (data.processing_duration_term) {
      product.processingDurationTermId = data.processing_duration_term.id;
    }
    if (data.insignia) {
      product.insignia = Insignia.fromApi(data.insignia);
    }

    product.tags = data.tags;
    product.upis = data.upis;

    if (data.designs) {
      product.designs = data.designs.map((d:any) => Design.fromApi(d));
    }

    return product;

  }

  getImage(size: 'th' | 'sm' | 'md' | 'or') {
    if (this.primaryImage) {
      const image = this.primaryImage.urls[size];
      if (image) {
        return image;
      }
      return this.primaryImage.urls.or;

    }
      // alp logo
      // tslint:disable-next-line:max-line-length
    return '';
  }

  setValuesFromForm(values: IProductFormValues, isNew: boolean) {
    this.name = values.name;
    this.category = values.category;
    this.organization = values.organization;
    this.description = values.description;
    this.vendorProductId = values.vendorProductId;
    this.regularPrice = values.price;
    this.salePrice = values.salePrice;
    this.isOnSale = values.isOnSale;
    this.shippingPriceFirst = values.shippingFirst;
    this.shippingPriceAdditional = values.shippingAdditional;
    this.processingDurationQuantity = values.fulfillmentDuration;
    this.processingDurationTermId = values.fulfillmentTerm;
    this.defaultShippingPrice = values.defaultShippingPrice;
    this.marketplaceDeliveryText = values.shippingPolicy;
    this.marketplaceReturnsText = values.returnsPolicy;
    this.defaultShippingPolicy = values.defaultShippingPolicy;
    this.defaultReturnPolicy = values.defaultReturnPolicy;
    this.defaultFulfillment = values.defaultFulfillment;
    this.insignia = values.insignia;
    this.customization = values.customization;
    this.upis = values.upis;

    if (values.primaryImage) {
      this.newPrimaryImage = values.primaryImage;
    }
    if (values.secondaryImages) {
      this.newSecondaryImages = values.secondaryImages;
    }

    if (values.options) {
      this.options = values.options;
    }

  }

  setValuesFromGallery(values: IGalleryFormValues, isNew: boolean) {
    this.name = values.name;
    this.category = values.category;
    this.organization = values.organization;
    this.description = values.description;
    this.vendorProductId = values.vendorProductId;
    this.regularPrice = values.price;
    this.salePrice = values.salePrice;
    this.isOnSale = values.isOnSale;
    if (values.primaryImage) {
      this.newPrimaryImage = values.primaryImage;
    }
    if (values.secondaryImages) {
      this.newSecondaryImages = values.secondaryImages;
    }

  }

  generateFormData(isNew: boolean): FormData {
    const formData = new FormData();

    formData.append('name', this.name);

    if (this.category !== undefined) {
      formData.append('product_category_id', this.category.id.toString());
    }

    formData.append('client_account_id', this.organization.id.toString());

    if (this.newPrimaryImage) {
      formData.append('product_image', this.newPrimaryImage);
    }
    if (this.regularPrice) {
      formData.append('price', this.regularPrice.toString());
    }
    if (this.salePrice) {
      formData.append('sale_price', this.salePrice.toString());
    }
    formData.append('is_on_sale', this.isOnSale ? '1' : '0');
    if (this.vendorProductId) {
      formData.append('vendor_product_identifier', this.vendorProductId);
    }
    if (this.productWebAddress) {
      formData.append('product_web_address', this.productWebAddress);
    }
    if (this.description) {
      formData.append('description', this.description);
    }
    if (!this.defaultReturnPolicy) {
      formData.append('marketplace_returns_text', this.marketplaceReturnsText);
    }
    if (!this.defaultShippingPolicy) {
      formData.append('marketplace_delivery_text', this.marketplaceDeliveryText);
    }
    if (!this.defaultShippingPrice) {
      if (this.shippingPriceFirst) {
        formData.append('shipping_price_first', this.shippingPriceFirst.toString());
      }
      if (this.shippingPriceAdditional) {
        formData.append('shipping_price_additional', this.shippingPriceAdditional.toString());
      }
    }
    if (!this.defaultFulfillment) {
      formData.append('processing_duration_term_id', `${this.processingDurationTermId}`);
      formData.append('processing_duration_quantity', this.processingDurationQuantity);
    }

    if (this.newSecondaryImages) {
      this.newSecondaryImages.forEach((element, index) => {
        if (!isNew) {
          if (element.id) {
            formData.append('old_secondary_images[]', element.id);
            formData.append('old_secondary_images_position[]', index.toString());

          } else {
            formData.append('new_secondary_images[]', element);
            formData.append('new_secondary_images_position[]', index.toString());
          }
        } else {
          formData.append('secondary_images[]', element);
        }

      });
    }

    if (this.options) {
      formData.append('option_groups', JSON.stringify(this.options));
    }
    if (this.insignia) {
      formData.append('insignia_id', `${this.insignia.id}`);
    }
    if (this.upis) {
      formData.append('upis[]', `${this.upis}`);
    }

    if (this.tags) {
      const tags = this.tags.map(tag => tag.id);
      formData.append('tags', tags.join(','));
    }

    formData.append('customization', this.customization ? '1' : '0');

    return formData;
  }

  generateGalleryFormData(isNew: boolean): FormData {
    const formData = new FormData();

    formData.append('name', this.name);

    if (this.category !== undefined) {
      formData.append('product_category_id', this.category.id.toString());
    }

    formData.append('client_account_id', this.organization.id.toString());

    if (this.newPrimaryImage) {
      formData.append('product_image', this.newPrimaryImage);
    }
    if (this.regularPrice) {
      formData.append('price', this.regularPrice.toString());
    }
    if (this.salePrice) {
      formData.append('sale_price', this.salePrice.toString());
    }
    formData.append('is_on_sale', this.isOnSale ? '1' : '0');
    if (this.vendorProductId) {
      formData.append('vendor_product_identifier', this.vendorProductId);
    }
    if (this.description) {
      formData.append('description', this.description);
    }

    if (this.newSecondaryImages) {
      this.newSecondaryImages.forEach((element, index) => {
        if (!isNew) {
          if (element.id) {
            formData.append('old_secondary_images[]', element.id);
            formData.append('old_secondary_images_position[]', index.toString());

          } else {
            formData.append('new_secondary_images[]', element);
            formData.append('new_secondary_images_position[]', index.toString());
          }
        } else {
          formData.append('secondary_images[]', element);
        }

      });
    }
    formData.append('option_groups', JSON.stringify([]));

    return formData;
  }

  hasTag(tag: MarketplaceTag) {
    return this.tags.findIndex(t => t.id === tag.id);
  }

  removeTag(tag: MarketplaceTag) {
    const hasTag = this.hasTag(tag);
    if (hasTag !== -1) {
      this.tags.splice(hasTag, 1);
    }
  }

  addTag(tag: MarketplaceTag) {
    const hasTag = this.hasTag(tag);
    if (hasTag === -1) {
      this.tags.push(tag);
    }
  }

  isShopifyUpi(upi: string) {
    const existing = (this.upis && this.upis.length > 0) ? this.upis.find(u => u.upi === upi) : false;
    return existing && existing.synced_from_shopify;
  }

}
