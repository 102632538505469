import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import { AffinityClient, ProductCategoryParentMapping, RoyaltyReport, RoyaltyReportLineItem, RoyaltySchedule, Vendor } from '../../../shared';
import { Insignia } from '../../../shared/Insignia';
import { LicensorTypeahead, ProductCategoryTypeahead } from '../../shared';
import { CategoryModal, GatewayModal, ModalType } from '../../shared/modals';

interface IProps {
  onClose: (salesData?: RoyaltyReportLineItem) => any;
  shown: boolean;
  salesData?: RoyaltyReportLineItem;
  licensors: AffinityClient[];
  allInsignia: Insignia[];
  categories: ProductCategoryParentMapping[];
  royaltyReport: RoyaltyReport;
  distributionChannels: any[];
}

interface IPropsUpdate {
  shown: boolean;
  onClose: () => any;
  salesData: RoyaltyReportLineItem;
  update: (l: RoyaltyReportLineItem) => void;
  report: RoyaltyReport;
}

interface IState {
  salesData: RoyaltyReportLineItem;
  categoryModalShown: boolean;
  availableSchedules: RoyaltySchedule[];
  loadingSchedules: boolean;
  requiresSchedule: boolean;
  submittingForm: boolean;
  formLicensorId: string | null;
  loadingVendors: boolean;
  vendors: Vendor[];
  vendorSearchPage: number;
  vendorSearch: string;
}

interface FormValues {
  licensor: any;
  invoiceId: string;
  date: moment.Moment;
  grossSales: number | string;
  numberOfUnits: number | string;
  pricePerUnit: number | string;
  retailer: string;
  customer: string;
  shippingCountry: string;
  shippingPostalCode: string;
  uniqueProductId: string;
  insignia: any;
  productCategory: any;
  distributionChannel: any;
  schedule: any;
  royaltyDue: number | string;
  retailerAccount: Vendor | null;
}

export const SalesDataOrderDetailsModalUpdate = (props: IPropsUpdate) => {
  const [distributionChannels, setDistributionChannels] = React.useState<any[]>([]);
  const [insignia, setInsignia] = React.useState<any[]>([]);
  const [updating, setUpdating] = React.useState(false);
  const [schedules, setSchedules] = React.useState<RoyaltySchedule[]>([]);
  const [loadingSchedules, setLoadingSchedules] = React.useState(false);

  React.useEffect(() => {
    getChannels();
    getInsignia();
    getAvailableSchedules(props.salesData.licensor.id);

  },              []);

  const validationSchema = Yup.object().shape({
    licensor: Yup.array().min(1, 'Required'),
    invoiceId: Yup.string().required('Required'),
    date: Yup.mixed().required('Required'),
    grossSales: Yup.number().required('Required'),
    numberOfUnits: Yup.number().integer().required('Required'),
    pricePerUnit: Yup.number().required('Required'),
    shippingCountry: Yup.string().required('Required'),
    uniqueProductId: Yup.string().notRequired(),
    productCategory: Yup.array().min(1, 'Required'),
    distributionChannel: Yup.number().required('Required'),
    schedule: Yup.number().required('Required'),
    royaltyDue: Yup.number(),

  });

  const errorClassName = (errors: any, touched: any) => {
    if (errors && touched) {
      return 'form-group has-error';
    }
    return 'form-group';
  };

  const getChannels = async () => {
    const c = await Axios.get('/api/distributionChannels');
    setDistributionChannels(c.data.data);
  };

  const getInsignia = async () => {
    const i = await Axios.get('/api/brandmarks');
    setInsignia(i.data.data.map((d: any) => Insignia.fromApi(d)));
  };

  const getAvailableSchedules = async (licensorId: number) => {
    setLoadingSchedules(true);
    const s = await Axios.get(`/api/royalty-reports/${props.report.id}/available-schedules?licensor_id=${licensorId}`);
    const sc = s.data.data.map((schedule: any) => new RoyaltySchedule(schedule));
    setSchedules(sc);
    setLoadingSchedules(false);

  };

  const saveSalesData = async (values: any) => {
    if (updating) {
      return;
    }

    setUpdating(true);
    const reportDetails = {
      client_account_id: values.licensor[0].id,
      distribution_channel_id: values.distributionChannel,
      net_sales: values.grossSales,
      quantity: values.numberOfUnits,
      price_per_unit: values.pricePerUnit,
      reported_date: values.date.format('YYYY-MM-DD'),
      reported_invoice_id: values.invoiceId,
      item_identifier: values.uniqueProductId,
      product_category_id: values.productCategory[0].id,
      insignia_id: values.insignia ? values.insignia : null,
      customer: values.customer,
      retailer: values.retailer,
      shipping_country: values.shippingCountry,
      shipping_postal_code: values.shippingPostalCode,
      royalty_schedule_id: values.schedule,
      amount_due: values.royaltyDue,
      retailer_vendor_account_id: null,
      // retailer_vendor_account_id: values.retailerAccount.length ?  values.retailerAccount[0].id : null,
    };
    const l = await Axios.put(`/api/royalty-reports/${props.report.id}/line-items/${props.salesData.id}`, reportDetails);
    const lineItem = new RoyaltyReportLineItem(l.data.data);
    props.update(lineItem);
    props.onClose();
    setUpdating(false);

  };

  const findSchedule = (id: any) => {
    return schedules.find(s => Number(s.id) === Number(id));
  };

  const distChannels = distributionChannels.map(channel =>
    <option key={channel.id} value={channel.id}>{channel.name}</option>,
  );
  const availableInsignia = insignia.map((insignia) => {
    const title = insignia.isDeleted ? `${insignia.title} (Archived Insignia)` : insignia.title;
    return <option key={insignia.id} value={insignia.id}>{title}</option>;
  });
  const l = props.salesData;
  const initialValues = {
    licensor: [l.licensor],
    invoiceId: l.invoiceID,
    date: moment(l.invoiceDate),
    grossSales: l.grossSales,
    numberOfUnits: l.quantity,
    pricePerUnit: l.pricePerUnit,
    retailer: l.retailer,
    customer: l.customer,
    shippingCountry: l.shippingCountry,
    shippingPostalCode: l.shippingPostalCode,
    uniqueProductId: l.uniqueProductID,
    productCategory: [l.productCategory],
    distributionChannel: l.channel.id,
    insignia: l.insignia ? l.insignia.id : '',
    schedule: l.schedule.id,
    royaltyDue: l.royalty,
    retailerAccount: [l.retailerAccount],
  };

  return (
    <GatewayModal
      type={ModalType.Primary}
      onClose={() => props.onClose()}
      title="Order Details"
      shown={props.shown}
    >
      <Modal.Body>
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={saveSalesData}>
          {(formProps: FormikProps<any>) => {
            const currentSchedule = findSchedule(formProps.values.schedule);
            let requiresRoyaltyDue = false;
            if (currentSchedule) {
              requiresRoyaltyDue = currentSchedule.isCustom;
            }

            return (
              <Form>
                <div className={errorClassName(formProps.errors.licensor, formProps.touched.licensor)}>
                  <label htmlFor="licensor">Licensor</label>
                  <LicensorTypeahead
                    selected={formProps.values.licensor}
                    onChange={(l) => {
                      formProps.setFieldValue('licensor', l);
                      formProps.setFieldValue('schedule', '');
                      if (l.length) {
                        getAvailableSchedules(l[0].id);
                      } else {
                        setSchedules([]);
                      }
                    }}
                    multiple={false}

                  />
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className={errorClassName(formProps.errors.invoiceId, formProps.touched.invoiceId)}>
                      <label htmlFor="invoiceId">Invoice Id</label>
                      <input
                        id="invoiceId"
                        type="text"
                        value={formProps.values.invoiceId}
                        onChange={formProps.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={errorClassName(formProps.errors.date, formProps.touched.date)}>
                      <label htmlFor="date">Date</label>
                      <DatePicker
                        selected={formProps.values.date}
                        onChange={date => formProps.setFieldValue('date', date)}
                        customInput={(
                          <div>
                            <input
                              id="date"
                              value={formProps.values.date.format('MM/DD/YYYY')}
                              onChange={formProps.handleChange}
                              type="text"
                              className="form-control"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className={errorClassName(formProps.errors.grossSales, formProps.touched.grossSales)}>
                      <label htmlFor="grossSales">Gross Sales</label>
                      <div className="input-group">
                        <span className="input-group-addon">$</span>
                        <input
                          style={{ zIndex: 0 }}
                          id="grossSales"
                          type="text"
                          value={formProps.values.grossSales}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={errorClassName(formProps.errors.numberOfUnits, formProps.touched.numberOfUnits)} >
                      <label htmlFor="numberOfUnits">Number of Units</label>
                      <input
                        id="numberOfUnits"
                        type="text"
                        value={formProps.values.numberOfUnits}
                        onChange={formProps.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={errorClassName(formProps.errors.pricePerUnit, formProps.touched.pricePerUnit)}>
                      <label htmlFor="pricePerUnit">Price Per Unit</label>
                      <div className="input-group">
                        <span className="input-group-addon">$</span>
                        <input
                          style={{ zIndex: 0 }}
                          id="pricePerUnit"
                          type="text"
                          value={formProps.values.pricePerUnit}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>

                <div className="row">
                  <div className="col-md-6">
                    <div className={errorClassName(formProps.errors.retailer, formProps.touched.retailer)}>
                      <label htmlFor="retailer">Retailer</label>
                      <input
                        id="retailer"
                        type="text"
                        value={formProps.values.retailer}
                        onChange={formProps.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={errorClassName(formProps.errors.customer, formProps.touched.customer)}>
                      <label htmlFor="customer">Customer</label>
                      <input
                        id="customer"
                        type="text"
                        value={formProps.values.customer}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className={errorClassName(formProps.errors.retailer, formProps.touched.retailer)}>
                      <label htmlFor="retailer">Retailer Vendor Account</label>
                      {/* <VendorTypeahead
                        selected={formProps.values.retailerAccount}
                        multiple={false}
                        onChange={v => formProps.setFieldValue('retailerAccount', v)}
                      /> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className={
                      errorClassName(formProps.errors.shippingCountry, formProps.touched.shippingCountry)
                    }
                    >
                      <label htmlFor="shippingCountry">Shipping Country</label>
                      <input
                        id="shippingCountry"
                        type="text"
                        value={formProps.values.shippingCountry}
                        onChange={formProps.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={
                      errorClassName(formProps.errors.shippingPostalCode, formProps.touched.shippingPostalCode)
                    }
                    >
                      <label htmlFor="shippingPostalCode">Shipping Postal Code</label>
                      <input
                        id="shippingPostalCode"
                        type="text"
                        value={formProps.values.shippingPostalCode}
                        onChange={formProps.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                </div>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <div className={
                      errorClassName(formProps.errors.uniqueProductId, formProps.touched.uniqueProductId)
                    }>
                      <label htmlFor="uniqueProductId">Item Type</label>
                      <input
                        id="uniqueProductId"
                        type="text"
                        value={formProps.values.uniqueProductId}
                        onChange={formProps.handleChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={
                      errorClassName(formProps.errors.productCategory, formProps.touched.productCategory)
                    }>
                      <label htmlFor="uniqueProductId">Product Category</label>
                      <br />
                      <ProductCategoryTypeahead
                        multiple={false}
                        selected={formProps.values.productCategory}
                        onChange={c => formProps.setFieldValue('productCategory', c)}

                      />
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className={
                      errorClassName(formProps.errors.distributionChannel, formProps.touched.distributionChannel)
                    }>
                      <label htmlFor="distributionChannel">Distribution Channel</label>
                      <select
                        id="distributionChannel"
                        value={formProps.values.distributionChannel}
                        onChange={formProps.handleChange}
                        className="form-control"
                      >
                        <option>Select Distribution Channel</option>
                        {distChannels}

                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className={
                      errorClassName(formProps.errors.insignia, formProps.touched.insignia)
                    }>
                      <label htmlFor="insignia">Insignia</label>
                      <select
                        id="insignia"
                        value={formProps.values.insignia}
                        onChange={formProps.handleChange}
                        className="form-control"
                        disabled={availableInsignia.length === 0}
                      >
                        <option value={''}>No Insignia</option>
                        {availableInsignia}

                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className={errorClassName(formProps.errors.schedule, formProps.touched.schedule)}>
                      <label htmlFor="distributionChannel">Current Royalty Schedule</label>
                      <Field disabled={loadingSchedules} component="select" name="schedule" className="form-control">
                        <option value="" selected disabled>{loadingSchedules ? 'Loading...' : 'Select Schedule' }</option>
                        {schedules.map(schedule =>
                          <option key={schedule.id} value={schedule.id}>{schedule.title} ({schedule.labelText()})</option>,
                        )}
                      </Field>
                    </div>
                  </div>
                </div>

                {
                  requiresRoyaltyDue ? (
                    <div className="row">
                      <div className="col-md-12">
                        <div className={errorClassName(formProps.errors.royaltyDue, formProps.touched.royaltyDue)}>
                          <label htmlFor="distributionChannel">Royalty</label>
                          <div className="input-group">
                            <span className="input-group-addon">$</span>
                            <input
                              style={{ zIndex: 0 }}
                              id="royaltyDue"
                              type="text"
                              value={formProps.values.royaltyDue}
                              onChange={formProps.handleChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null

                }

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                  <button
                    onClick={() => props.onClose()}
                    type="button"
                    className="btn btn-default pull-left"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">{updating ? 'Updating...' : 'Update Sales Data'}</button>
                </div>

              </Form>
            );
          }}

        </Formik>

      </Modal.Body>
    </GatewayModal>

  );
};

export class SalesDataOrderDetailsModal extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    const licensorId = (this.props.salesData && this.props.salesData.licensor) ? (
      String(this.props.salesData.licensor.id)
    ) : (
      null
    );
    this.state = {
      salesData: this.props.salesData ? this.props.salesData : new RoyaltyReportLineItem(),
      categoryModalShown: false,
      availableSchedules: [],
      loadingSchedules: false,
      requiresSchedule: this.props.salesData ? false : true,
      submittingForm: false,
      formLicensorId: licensorId,
      loadingVendors: false,
      vendors: [],
      vendorSearchPage: 1,
      vendorSearch: '',
    };
    this.saveSalesData = this.saveSalesData.bind(this);
    this.closeCategoryModal = this.closeCategoryModal.bind(this);
    this.showCategoryModal = this.showCategoryModal.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.findCategory = this.findCategory.bind(this);
    this.getAvailableSchedules = this.getAvailableSchedules.bind(this);
    this.showScheduleSelect = this.showScheduleSelect.bind(this);
    this.getVendors = this.getVendors.bind(this);
    this.getVendorsNextPage = this.getVendorsNextPage.bind(this);
  }

  showCategoryModal() {
    this.setState({ categoryModalShown: true });
  }

  closeCategoryModal() {
    this.setState({ categoryModalShown: false });
  }

  selectCategory(category: any, formProps: FormikProps<FormValues>) {
    formProps.setFieldValue('productCategory', category.id);
    this.closeCategoryModal();
  }

  findCategory(id: any) {
    const categories = this.props.categories.reduce(
      (prev, current) => {
        return prev.concat(this.flattenMapping(current));
      }
      ,
      [] as ProductCategoryParentMapping[]);
    const category = categories.find(c => c.category.id === id);
    if (category) {
      return category.category.name;
    }
    if (!id) {
      return 'Select Product Category';
    }
    return 'Loading ...';
  }

  flattenMapping(mapping: ProductCategoryParentMapping) {
    let categories: ProductCategoryParentMapping[] = [];
    mapping.children.forEach((c) => {
      if (c.category.isParent) {
        const allChildren: ProductCategoryParentMapping[] = c.children.reduce(
          (prev, next) => {
            let m = prev;
            if (next.category.isParent) {
              m = prev.concat(this.flattenMapping(next));

            } else {
              m.push(next);
            }

            return m;
          },
          [] as ProductCategoryParentMapping[]);

        categories = categories.concat(allChildren);

      } else {
        categories = categories.concat(c);
      }
    });

    return categories;

  }

  findSchedule(id: any) {
    if (this.state.requiresSchedule) {
      return this.state.availableSchedules.find(s => Number(s.id) === Number(id));
    }
    if (this.state.salesData.id)  {
      return this.state.salesData.schedule;
    }
    return null;

  }

  getAvailableSchedules(licensorId: number) {
    this.setState({ loadingSchedules: true });
    Axios.get(`/api/royalty-reports/${this.props.royaltyReport.id}/available-schedules?licensor_id=${licensorId}`)
      .then((response) => {
        const schedules = response.data.data.map((schedule: any) => new RoyaltySchedule(schedule));
        this.setState({ availableSchedules: schedules, loadingSchedules: false });
      })
      .catch(error =>  console.log(error));

  }

  getVendors(search: string, page = 1) {
    this.setState({ loadingVendors : true, vendorSearch: search  });
    Axios.get(`/api/vendors?keyword=${search}&page=${page}`).then((response) => {
      let vendors = response.data.data.map((v: any) => Vendor.fromApi(v));
      if (page > 1) {
        vendors = this.state.vendors.concat(vendors);
      }
      this.setState({ vendors,  loadingVendors: false });
    });
  }

  getVendorsNextPage() {
    const page = this.state.vendorSearchPage + 1;
    this.getVendors(this.state.vendorSearch, page);
    this.setState({ vendorSearchPage: page });

  }

  showScheduleSelect(licensorId: number) {
    this.setState({ requiresSchedule: true });
    this.getAvailableSchedules(licensorId);

  }

  saveSalesData(values: FormValues) {
    this.setState({ submittingForm: true });
    const reportDetails = {
      client_account_id: values.licensor,
      distribution_channel_id: values.distributionChannel,
      net_sales: values.grossSales,
      quantity: values.numberOfUnits,
      price_per_unit: values.pricePerUnit,
      reported_date: values.date.format('YYYY-MM-DD'),
      reported_invoice_id: values.invoiceId,
      item_identifier: values.uniqueProductId,
      product_category_id: values.productCategory,
      insignia_id: values.insignia ? values.insignia : null,
      customer: values.customer,
      retailer: values.retailer,
      shipping_country: values.shippingCountry,
      shipping_postal_code: values.shippingPostalCode,
      royalty_schedule_id: values.schedule,
      amount_due: values.royaltyDue,
      retailer_vendor_account_id: values.retailerAccount ? values.retailerAccount.id : null,
    };
    if (this.state.salesData.id) {
      Axios.put(`/api/royalty-reports/${this.props.royaltyReport.id}/line-items/${this.state.salesData.id}`, reportDetails)
        .then((response) => {
          const lineItem = new RoyaltyReportLineItem(response.data.data);
          this.setState({ submittingForm: false, salesData: lineItem });
          this.props.onClose(lineItem);
        })
        .catch((error) => {
          this.setState({ submittingForm: false });
          console.error(error);
        });

    } else {
      Axios.post(`/api/royalty-reports/${this.props.royaltyReport.id}/line-items`, reportDetails)
        .then((response) => {
          const lineItem = new RoyaltyReportLineItem(response.data.data);
          this.setState({ submittingForm: false });
          this.props.onClose(lineItem);
        })
        .catch((error) => {
          this.setState({ submittingForm: false });
          console.error(error);
        });

    }

  }

  errorClassName(errors: any, touched: any) {
    if (errors && touched) {
      return 'form-group has-error';
    }
    return 'form-group';
  }

  render() {

    const validationSchema = Yup.object().shape({
      licensor: Yup.number().required('Required'),
      invoiceId: Yup.string().required('Required'),
      date: Yup.mixed().required('Required'),
      grossSales: Yup.number().required('Required'),
      numberOfUnits: Yup.number().integer().required('Required'),
      pricePerUnit: Yup.number().required('Required'),
      shippingCountry: Yup.string().required('Required'),
      uniqueProductId: Yup.string().notRequired(),
      productCategory: Yup.number().required('Required'),
      distributionChannel: Yup.number().required('Required'),
      schedule: Yup.number().required('Required'),
      royaltyDue: Yup.number(),

    });

    const initialValues: FormValues = {
      licensor: this.state.salesData.licensor && this.state.salesData.licensor.id,
      invoiceId: this.state.salesData.invoiceID || '',
      date: moment(this.state.salesData.invoiceDate),
      grossSales: this.state.salesData.grossSales || '',
      numberOfUnits: this.state.salesData.quantity || '',
      pricePerUnit: this.state.salesData.pricePerUnit || '',
      retailer: this.state.salesData.retailer || '',
      customer: this.state.salesData.customer || '',
      shippingCountry: this.state.salesData.shippingCountry || '',
      shippingPostalCode: this.state.salesData.shippingPostalCode || '',
      uniqueProductId: this.state.salesData.uniqueProductID || '',
      productCategory: this.state.salesData.productCategory && this.state.salesData.productCategory.id,
      distributionChannel: this.state.salesData.channel && this.state.salesData.channel.id,
      insignia: this.state.salesData.insignia && this.state.salesData.insignia.id,
      schedule: this.state.salesData.schedule && this.state.salesData.schedule.id,
      royaltyDue: this.state.salesData.royalty || '',
      retailerAccount: this.state.salesData.retailerAccount ? this.state.salesData.retailerAccount : null,
    };

    const licensors = this.props.licensors.map(licensor =>
      <option key={licensor.id} value={licensor.id}>{licensor.name}</option>,
    );
    const distChannels = this.props.distributionChannels.map(channel =>
      <option key={channel.id} value={channel.id}>{channel.name}</option>,
    );
    const availableInsignia = this.props.allInsignia.filter((insignia) => {
      return this.state.salesData
        && this.state.salesData.licensor
        && String(insignia.clientId) === String(this.state.formLicensorId);
    }).map((insignia) => {
      const title = insignia.isDeleted ? `${insignia.title} (Archived Insignia)` : insignia.title;
      return <option key={insignia.id} value={insignia.id}>{title}</option>;
    });
    const royaltySchedules = this.state.availableSchedules.map(schedule =>
      <option key={schedule.id} value={schedule.id}>{schedule.title} ({schedule.labelText()})</option>,
    );
    let buttonText: string;
    if (this.state.submittingForm) {
      buttonText = 'Saving...';

    } else if (this.state.salesData.id) {
      buttonText = 'Save Sales Data';
    } else {
      buttonText = 'Create Sales Data';
    }

    return (
      <GatewayModal
        type={ModalType.Primary}
        onClose={() => this.props.onClose()}
        title="Order Details"
        shown={this.props.shown}
      >
        <Modal.Body>
          <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={this.saveSalesData}>
            {(formProps: FormikProps<FormValues>) => {
              const currentSchedule = this.findSchedule(formProps.values.schedule);
              let requiresRoyaltyDue;
              if (currentSchedule) {
                requiresRoyaltyDue = currentSchedule.isCustom;
              }

              return (
                <form onSubmit={formProps.handleSubmit}>
                  <div className={this.errorClassName(formProps.errors.licensor, formProps.touched.licensor)}>
                    <label htmlFor="licensor">Licensor</label>
                    <select id="licensor" value={formProps.values.licensor} onChange={(event) => {
                      formProps.handleChange(event);
                      formProps.setFieldValue('schedule', '');
                      this.setState({ formLicensorId: event.target.value });
                      this.getAvailableSchedules(Number(event.target.value));
                    }} className="form-control" >
                      <option>Select Licensor</option>
                      {licensors}
                    </select>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className={this.errorClassName(formProps.errors.invoiceId, formProps.touched.invoiceId)}>
                        <label htmlFor="invoiceId">Invoice Id</label>
                        <input
                          id="invoiceId"
                          type="text"
                          value={formProps.values.invoiceId}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={this.errorClassName(formProps.errors.date, formProps.touched.date)}>
                        <label htmlFor="date">Date</label>
                        <DatePicker
                          selected={formProps.values.date}
                          onChange={date => formProps.setFieldValue('date', date)}
                          customInput={(
                            <div>
                              <input
                                id="date"
                                value={formProps.values.date.format('MM/DD/YYYY')}
                                onChange={formProps.handleChange}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className={this.errorClassName(formProps.errors.grossSales, formProps.touched.grossSales)}>
                        <label htmlFor="grossSales">Gross Sales</label>
                        <div className="input-group">
                          <span className="input-group-addon">$</span>
                          <input
                            style={{ zIndex: 0 }}
                            id="grossSales"
                            type="text"
                            value={formProps.values.grossSales}
                            onChange={formProps.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={this.errorClassName(formProps.errors.numberOfUnits, formProps.touched.numberOfUnits)} >
                        <label htmlFor="numberOfUnits">Number of Units</label>
                        <input
                          id="numberOfUnits"
                          type="text"
                          value={formProps.values.numberOfUnits}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className={this.errorClassName(formProps.errors.pricePerUnit, formProps.touched.pricePerUnit)}>
                        <label htmlFor="pricePerUnit">Price Per Unit</label>
                        <div className="input-group">
                          <span className="input-group-addon">$</span>
                          <input
                            style={{ zIndex: 0 }}
                            id="pricePerUnit"
                            type="text"
                            value={formProps.values.pricePerUnit}
                            onChange={formProps.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr></hr>

                  <div className="row">
                    <div className="col-md-6">
                      <div className={this.errorClassName(formProps.errors.retailer, formProps.touched.retailer)}>
                        <label htmlFor="retailer">Retailer</label>
                        <input
                          id="retailer"
                          type="text"
                          value={formProps.values.retailer}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={this.errorClassName(formProps.errors.customer, formProps.touched.customer)}>
                        <label htmlFor="customer">Customer</label>
                        <input
                          id="customer"
                          type="text"
                          value={formProps.values.customer}
                          onChange={formProps.handleChange}
                          onBlur={formProps.handleBlur}
                          className="form-control"
                        />
                      </div>
                    </div>

                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className={this.errorClassName(formProps.errors.retailer, formProps.touched.retailer)}>
                        <label htmlFor="retailer">Retailer Vendor Account</label>
                        <AsyncTypeahead
                          id="retailer-account-search"
                          onSearch={this.getVendors}
                          onChange={v => v.length ?
                            formProps.setFieldValue('retailerAccount', v[0])
                            : formProps.setFieldValue('retailerAccount', null)
                          }
                          onPaginate={this.getVendorsNextPage}
                          labelKey={option => option.shortName}
                          minLength={3}
                          maxResults={14}
                          paginate={true}
                          placeholder="Retailer Account"
                          options={this.state.vendors}
                          selected={formProps.values.retailerAccount ? [formProps.values.retailerAccount] : undefined}
                          isLoading={this.state.loadingVendors}
                          useCache={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={
                          this.errorClassName(formProps.errors.shippingCountry, formProps.touched.shippingCountry)
                        }
                      >
                        <label htmlFor="shippingCountry">Shipping Country</label>
                        <input
                          id="shippingCountry"
                          type="text"
                          value={formProps.values.shippingCountry}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={
                          this.errorClassName(formProps.errors.shippingPostalCode, formProps.touched.shippingPostalCode)
                        }
                      >
                        <label htmlFor="shippingPostalCode">Shipping Postal Code</label>
                        <input
                          id="shippingPostalCode"
                          type="text"
                          value={formProps.values.shippingPostalCode}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                  </div>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={
                        this.errorClassName(formProps.errors.uniqueProductId, formProps.touched.uniqueProductId)
                      }>
                        <label htmlFor="uniqueProductId">Item Type</label>
                        <input
                          id="uniqueProductId"
                          type="text"
                          value={formProps.values.uniqueProductId}
                          onChange={formProps.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={
                        this.errorClassName(formProps.errors.productCategory, formProps.touched.productCategory)
                      }>
                        <label htmlFor="uniqueProductId">Product Category</label>
                        <br />
                        <button
                          type="button"
                          onClick={this.showCategoryModal}
                          style={{ textAlign: 'left' }}
                          className="btn form-control btn-block btn-default text-left"
                        >
                          {this.findCategory(formProps.values.productCategory)}
                        </button>

                        <CategoryModal
                          categories={this.props.categories}
                          onCategorySelect={category => this.selectCategory(category, formProps)}
                          onClose={this.closeCategoryModal}
                          shown={this.state.categoryModalShown}
                        />

                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={
                        this.errorClassName(formProps.errors.distributionChannel, formProps.touched.distributionChannel)
                      }>
                        <label htmlFor="distributionChannel">Distribution Channel</label>
                        <select
                          id="distributionChannel"
                          value={formProps.values.distributionChannel}
                          onChange={formProps.handleChange}
                          className="form-control"
                        >
                          <option>Select Distribution Channel</option>
                          {distChannels}

                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={
                        this.errorClassName(formProps.errors.insignia, formProps.touched.insignia)
                      }>
                        <label htmlFor="insignia">Insignia</label>
                        <select
                          id="insignia"
                          value={formProps.values.insignia}
                          onChange={formProps.handleChange}
                          className="form-control"
                          disabled={availableInsignia.length === 0}
                        >
                          <option value={''}>No Insignia</option>
                          {availableInsignia}

                        </select>
                      </div>
                    </div>
                  </div>
                  {
                    this.state.requiresSchedule ?
                      this.state.loadingSchedules ? (
                        <div style={{ padding: 20 }} className="react-loading-content">
                          <span><FontAwesomeIcon icon={faSpinner} spin /></span>
                        </div>)
                        : (
                          <div className="row">
                            <div className="col-md-12">
                              <div className={this.errorClassName(formProps.errors.schedule, formProps.touched.schedule)}>
                                <label htmlFor="distributionChannel">Schedule</label>
                                {
                                  formProps.values.licensor ?
                                    (
                                      <select
                                        id="schedule"
                                        value={formProps.values.schedule}
                                        onChange={formProps.handleChange}
                                        className="form-control"
                                      >
                                        <option>Select Schedule</option>
                                        {royaltySchedules}
                                      </select>
                                    ) :
                                    (
                                      <p className="text-muted">Select a Licensor to see available schedules.</p>
                                    )
                                }
                              </div>
                            </div>
                          </div>
                        )
                      : (
                        <div className="row">
                          <div className="col-md-12">
                            <div className={this.errorClassName(formProps.errors.schedule, formProps.touched.schedule)}>
                              <label htmlFor="distributionChannel">Current Royalty Schedule</label>
                              <input
                                id="schedule"
                                className="form-control"
                                disabled={true}
                                value={
                                  `${this.state.salesData.schedule.title} (${this.state.salesData.schedule.labelText()})`
                                }
                              />
                              <span
                                id="helpBlock"
                                onClick={() => this.showScheduleSelect(formProps.values.licensor)}
                                style={{ cursor: 'pointer' }}
                                className="help-block text-primary"
                              >
                                Change Schedule
                              </span>
                            </div>
                          </div>
                        </div>

                      )
                  }

                  {
                    requiresRoyaltyDue ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className={this.errorClassName(formProps.errors.royaltyDue, formProps.touched.royaltyDue)}>
                            <label htmlFor="distributionChannel">Royalty</label>
                            <div className="input-group">
                              <span className="input-group-addon">$</span>
                              <input
                                style={{ zIndex: 0 }}
                                id="royaltyDue"
                                type="text"
                                value={formProps.values.royaltyDue}
                                onChange={formProps.handleChange}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null

                  }

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>
                    <button
                      onClick={() => this.props.onClose()}
                      type="button"
                      className="btn btn-default pull-left"
                    >
                      Cancel
                    </button>
                    <button className="btn btn-primary" type="submit">{buttonText}</button>
                  </div>

                </form>
              );
            }}

          </Formik>

        </Modal.Body>
      </GatewayModal>
    );
  }

}
