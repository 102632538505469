import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { wrapDisplayName } from 'recompose';
import { getRoyaltyReport } from '../api';
import { RoyaltyReport } from './RoyaltyReport';

interface WithReportState {
  report: RoyaltyReport | null;
  isReportLoading: boolean;
}

// Props you want the resulting component to take (besides the props of the wrapped component)
type ExternalProps = RouteComponentProps<any>;
export type setReportType = ((report: RoyaltyReport) => void);

// Props the HOC adds to the wrapped component
export interface InjectedReportProps {
  report: RoyaltyReport;
  setReport: setReportType;
  loadReport(): void;
}

const withReport = () => <OriginalProps extends {}>(
  WrappedComponent: React.ComponentType<OriginalProps & InjectedReportProps>,
) => {
  class WithReport extends React.Component<OriginalProps & ExternalProps, WithReportState> {

    protected _loadReportSource: CancelTokenSource;

    constructor(props: OriginalProps & ExternalProps) {
      super(props);
      this.loadReport = this.loadReport.bind(this);
      this.setReport = this.setReport.bind(this);
      this.state = {
        report: null,
        isReportLoading: true,
      };
    }

    componentDidMount() {
      this.loadReport();
    }

    public componentWillUnmount() {
      if (this._loadReportSource) {
        this._loadReportSource.cancel('Cancelled withReport:loadReport XHR due to unmount.');
      }
    }

    loadReport() {
      this.setState({ isReportLoading: true });
      if (this._loadReportSource) {
        this._loadReportSource.cancel('Cancelled withReport:loadReport XHR due to new request.');
      }
      this._loadReportSource = axios.CancelToken.source();
      getRoyaltyReport(this.props.match.params.id)
        .then((response) => {

          const report = new RoyaltyReport(response.data.data);
          this.setState({ report, isReportLoading: false });
        })
        .catch((error) => {
          // Redirect to the /royalties page if this report does not exist
          if (error.response && error.response.status === 404) {
            this.props.history.replace('/royalties');
            return;
          }
          console.error(error);
        });
    }

    setReport(report: RoyaltyReport) {
      this.setState({ report });
    }

    render() {

      if (this.state.isReportLoading) {
        // TODO: Full page loading spinner
        return (
          <div>
            <div className="ajax-loader">
              <span><FontAwesomeIcon icon={faSpinner} spin /></span>
            </div>
          </div>
        );
      }

      if (this.state.report) {
        return (<WrappedComponent
            report={this.state.report}
            loadReport={this.loadReport}
            setReport={this.setReport}
            {...this.props}
          />
        );
      }

      // Should never see this
      return null;
    }
  }

  if (process.env.NODE_ENV !== 'production') {
    (WithReport as any).displayName = wrapDisplayName(WrappedComponent, 'WithReport');
  }
  return WithReport;
};

export default withReport;
