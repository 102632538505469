import { AccountAlias, AccountAliasAPIResponse } from './AccountAlias';
import { AffinityImage, ImageApiResponse } from './Image';

// export interface VendorImage {
//   id: number | null;
//   previous_file_name: string;
//   urls: { or: string, th?: string, sm?: string, md?: string };
// }

export interface VendorAPIResponse {
  id: string;
  name: string;
  short_name: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
  status: any;
  profile: any;
  public_short_name: string;
  website: string;
  image: ImageApiResponse;
  header_image: ImageApiResponse;
  account_dbas?: AccountAliasAPIResponse[];
  owner?: any;
  phone: string;
}

export class Vendor {
  id: number;
  legalName: string;
  shortName: string;
  profile: any;
  image: AffinityImage;
  status: { id: number, display: string };
  aliases: AccountAlias[] = [];
  owner: any;
  city: string;
  state: string;
  website: string;
  phone: string;

  constructor(data?: any) {
    if (data) {
      this.id = Number(data.id);
      this.legalName = data.name;
      this.shortName = data.short_name;
      this.profile = data.profile;
      this.image = data.image;
      if (data.status) {
        this.status = data.status;
      }
      this.phone = data.phone;

      if (data.image) {
        this.image = AffinityImage.fromApi(data.image);
      } else {
        this.image = new AffinityImage();
      }
    }

  }

  static fromApi(data: VendorAPIResponse) {
    const vendor = new Vendor();
    vendor.id = Number(data.id);
    vendor.legalName = data.name;
    vendor.shortName = data.short_name;
    vendor.city = data.city;
    vendor.state = data.state;
    vendor.website = data.website;
    if (data.profile) {
      vendor.profile = data.profile;
    }
    vendor.phone = data.phone;
    if (data.image) {
      vendor.image = AffinityImage.fromApi(data.image);
    } else {
      vendor.image = new AffinityImage();
    }
    vendor.status = data.status;
    if (data.account_dbas) {
      vendor.aliases = data.account_dbas.map(alias => AccountAlias.fromApi(alias));
    }
    if (data.owner) {
      vendor.owner = data.owner;
    }

    return vendor;

  }

  get statusColor() {
    const status = Number(this.status.id);
    if (status === 2) {
      return 'success';
    }
    if (status === 4 || status === 1) {
      return 'info';
    }
    if (status === 5) {
      return 'danger';
    }
    if (status === 3) {
      return 'warning';
    }
    return 'default';

  }

  // getProfileImage(size: 'sm' | 'or' | 'th' | 'md') {
  //   let image = '';
  //   if (this.image) {
  //     switch (size) {
  //       case 'sm':
  //         image = this.image.urls.sm || '';
  //         break;
  //       case 'or':
  //         image = this.image.urls.or || '';
  //         break;
  //       case 'th':
  //         image = this.image.urls.th || '';
  //         break;
  //       case 'md':
  //         image = this.image.urls.md || '';
  //         break;
  //     }

  //   } else {
  //     image = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/alp-icon.png';
  //   }

  //   return image;

  // }
}
