import Axios, { AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios';
import axios from 'axios';
import { logIfCancelled } from '../../api';

export const getAliases  = (params: any, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return Axios.get('/api/account-aliases', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getAliases');
      return error;
    });
};

export const deleteAlias = (id: number): AxiosPromise => {
  return Axios.delete(`/api/account-aliases/${id}`)
    .catch((error) => {
      logIfCancelled(error, 'deleteAlias');
      return error;
    });
};

export const getAlias = (id: number, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return Axios.get(`/api/account-aliases/${id}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getAlias');
      return error;
    });
};

export const createAlias = (params: any, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  return Axios.post('/api/account-aliases', params, { headers, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'createAlias');
      return error;
    });
};

export const updateAlias = (id: number, params: any, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  return Axios.post(`/api/account-aliases/${id}`, params, { headers, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'updateAlias');
      return error;
    });
};

export const getCurrentAccountUpdate = (cancelTokenSource: CancelTokenSource, id?: number): AxiosPromise => {
  const params = { account_id: id };
  return Axios.get('/api/account-updates/current', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getAccountUpdate');
      return error;
    });
};

export const requestAccountUpdate = (params: FormData, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return Axios.post('/api/account-updates', params, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'requestAccountUpdate');
      return error;
    });
};

export const getNewAccount = (cancelTokenSource: CancelTokenSource, id?: number): AxiosPromise => {
  return Axios.get(`/api/accounts/${id ? id : ''}`, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getNewAccount');
      return error;
    });
};

export const closeUpdateAccountRequest = (id: number, cancelTokenSource: CancelTokenSource): Promise<boolean> => {
  return Axios.delete(`/api/account-updates/${id}`)
    .catch((error) => {
      logIfCancelled(error, 'closeUpdateAccountRequest');
      return false;
    })
    .then((response) => {
      return true;
    });
};

export const getNewAccountAddress = (params: any, cancelTokenSource: CancelTokenSource, id?: number): AxiosPromise => {
  return Axios.get(`/api/account-addresses/${id ? id : ''}`, { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getNewAccountAddress');
      return error;
    });
};

export const deleteAccountAddress = (id: number): AxiosPromise => {
  return Axios.delete(`/api/account-addresses/${id}`)
    .catch((error) => {
      logIfCancelled(error, 'deleteAccountAddress');
      return error;
    });
};

export const updateAccountAddress = (
  accountAddressId: number,
  accountAddressUpdates: FormData,
  cancelTokenSource: CancelTokenSource,
): Promise<any> => {
  const headers = { 'Content-Type': 'multipart/form-data' };

  return axios
    .post(`/api/account-addresses/${accountAddressId}`,
          accountAddressUpdates, { headers, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'updateAccountAddress');
      return error;
    });
};

export const createAccountAddress = (
  accountAddressData: FormData,
  cancelTokenSource: CancelTokenSource,
): Promise<any> => {
  const headers = { 'Content-Type': 'multipart/form-data' };

  return axios
    .post('/api/account-addresses/', accountAddressData, { headers, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'createAccountAddress');
      return error;
    });
};
