import * as moment from 'moment';

// 'deliverability' values from Lob verification response
// https://docs.lob.com/#us_verifications_object
type LobDeliverability = 'deliverable'
  | 'deliverable_unnecessary_unit'
  | 'deliverable_incorrect_unit'
  | 'deliverable_missing_unit'
  | 'undeliverable';

export interface MarketplaceAddressApiResponse {
  id: number;
  created_at: string;
  updated_at: string;
  is_shipping: boolean;
  is_saved: boolean;
  is_billing: boolean;
  addressee: string;
  street_address: string;
  street_address_2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  lob_deliverability: LobDeliverability | null;
}

export class MarketplaceAddress {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  isShipping: boolean;
  isBilling: boolean;
  isSaved: boolean;
  addressee: string;
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  lobDeliverability: LobDeliverability | null;

  static fromApi(data: MarketplaceAddressApiResponse) {
    const address = new MarketplaceAddress();
    address.id = data.id;
    address.createdAt = moment(data.created_at);
    address.updatedAt = moment(data.updated_at);
    address.isShipping = data.is_shipping;
    address.isBilling = data.is_billing;
    address.isSaved = data.is_saved;
    address.addressee = data.addressee;
    address.streetAddress = data.street_address;
    address.streetAddress2 = data.street_address_2;
    address.city = data.city;
    address.state = data.state;
    address.zip = data.zip;
    address.phone = data.phone;
    address.lobDeliverability = data.lob_deliverability;
    return address;
  }

  get formattedPhone() {
    return this.phone ? this.phone.replace(/\D+/g, '')
     .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
  }

}
