// import Parchment from 'parchment';
import { Quill } from 'react-quill';

const Parchment = Quill.import('parchment');

const Block = Quill.import('blots/block');
const Container = Quill.import('blots/container');
const Break = Quill.import('blots/break');
const Inline = Quill.import('blots/inline');
const TextBlot = Quill.import('blots/text');
const HeaderBlot = Quill.import('formats/header');
const ListBlot = Quill.import('formats/list');
const EmbedBlot = Quill.import('blots/embed');

export class AffinityTemplateVariable extends EmbedBlot {
  static className = 'affinity-template-variable';
  static blotName = 'affinity-template-variable';
  static tagName = 'affinity-template-variable';
  public domNode: any;

  static create(value: {label: string, text: string}) {
    const node = super.create(value);

    const labelNode = document.createTextNode(value.text);
    node.appendChild(labelNode);
    node.setAttribute('data-label', value.label);
    node.setAttribute('data-text', value.text);
    return node;
  }

  static value(domNode: HTMLElement) {
    return domNode.dataset;
  }

  length(): number {
    return 1;
  }

  deleteAt(index: number, length: number) {
    super.deleteAt(index, length);
  }

}
export class TemplateContent extends Block {
  static allowedChildren = [HeaderBlot, Break, TextBlot, Inline, ListBlot, AffinityTemplateVariable];
  static className = 'template-content';
  static blotName = 'template-content';
  static tagName = 'div';

  static formats(domNode: HTMLElement) {
    // return true;
    return domNode.tagName === this.tagName ? undefined : super.formats(domNode);
  }

  remove() {
    if (this.prev == null && this.next == null) {
      this.parent.remove();
    } else {
      super.remove();
    }
  }

  format(name: any, value: any) {
    if (name === TemplateVariableBlot.blotName && !value) {
      this.replaceWith(Parchment.create(this.statics.scope));
    } else {
      super.format(name, value);
    }
  }

  replaceWith(name: any, value?: any) {
    this.parent.isolate(this.offset(this.parent), this.length());
    if (name === this.parent.statics.blotName) {
      this.parent.replaceWith(name, value);
      return this;
    }
    this.parent.unwrap();
    return super.replaceWith(name, value);

  }
}
export class TemplateVariableBlot extends Container {

  static blotName = 'affinity-templates';
  static tagName = 'affinity-conditional';
  static defaultChild = TemplateContent.blotName;
  static allowedChildren = [TemplateContent];
  static scope = Parchment.Scope.BLOCK_BLOT;

  static create(value: any) {
    const node = super.create(value);
    node.setAttribute('data-label', value);
    return node;
  }

  static formats(domNode: HTMLElement): any {
    const label = domNode.getAttribute('data-label');
    return label ? label : undefined;
  }

  format(name: any, value: any) {
    if (this.children.length > 0) {
      this.children.tail.format(name, value);
    }
  }

  optimize(context: any) {
    super.optimize(context);
    const next = this.next;
    if (next != null && next.prev === this &&
        next.statics.blotName === this.statics.blotName &&
        next.domNode.tagName === this.domNode.tagName &&
        next.domNode.getAttribute('data-label') === this.domNode.getAttribute('data-label')
        ) {
      next.moveChildren(this);
      next.remove();
    }
  }

  formats() {
    return { [this.statics.blotName]: this.statics.formats(this.domNode) };
  }

  constructor(node: any) {
    super(node);
  }

  insertBefore(blot: any, ref: any) {
    if (blot instanceof TemplateContent) {
      super.insertBefore(blot, ref);
    } else {
      const index = ref == null ? this.length() : ref.offset(this);
      const after = this.split(index);
      if (after.parent) {
        after.parent.insertBefore(blot, after);
      }
    }
  }

  replace(target: any) {
    if (target.statics.blotName !== this.statics.blotName) {
      const item = Parchment.create(this.statics.defaultChild);
      target.moveChildren(item);
      this.appendChild(item);
    }
    super.replace(target);
  }

}
