import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { ReportSavedRoyalties, Vendor } from '../../shared';
import { formattedFiscalYear } from '../../utils';
import { FullContent } from '../ContentFrame';
import { AccountCell, CurrencyLabel, GatewayModal, LoadingSpinner, ModalType } from '../shared';

interface AffinityFiscalQuarterReport {
  total_paid: number;
  total_affinity: number;
  total_licensor: number;
  total_payments: number;
  total_royalties: number;
  non_sharing_admin: number;
  non_sharing_royalties: number;
  admin_fees: number;
  tiers: AffinityCommisionTier[];
  payments: AffinityQuarterPayment[];
  pdf: string;
  csv: string;
  adjustments: string[];
}

interface AffinityCommisionTier {
  asterisk: false;
  clientAmount: number;
  commission: number;
  commissionRate: string;
  end: number;
  hasRange: boolean;
  royalties: number;
  start: number;
}

interface AffinityQuarterPayment {
  account: Vendor;
  invoice_ids: number[];
  sharing: boolean;
  total: number;
}

export const FiscalQuarterDetailPage = () => {
  const [loading, setLoading] = React.useState(true);
  const [loadingRsr, setLoadingRsr] = React.useState(true);
  const [reportData, setReportData] = React.useState<AffinityFiscalQuarterReport>();
  const [rsr, setRsr] = React.useState<ReportSavedRoyalties>();
  const [generateModal, setGenerateModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [adminDetailsModal, setAdminDetailsModal] = React.useState(false);
  const history = useHistory();
  const params = useParams();
  const user = React.useContext(UserContext);

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    getReportData();
    getRsr();
  },              [params['year'], params['quarter']]);

  const quarter = params['quarter'];
  const year = params['year'];

  const getReportData = async () => {
    setLoading(true);
    setReportData(undefined);
    const d = await Axios.get(`/api/report_saved_royalties/${year}/${quarter}?licensor=${params['licensorId']}`);
    const report = d.data.data;
    report.payments = report.payments.map((r: any) => {
      r.account = Vendor.fromApi(r.account);
      return r;
    });

    setReportData(report);
    setLoading(false);
  };

  const getRsr = async () => {
    setLoadingRsr(true);
    setRsr(undefined);
    const d = await Axios.get(`/api/report_saved_royalties/${year}/${quarter}/rsr?licensor=${params['licensorId']}`)
      .catch(e => null);
    if (d && d.data) {
      setRsr(ReportSavedRoyalties.fromApi(d.data.data));
    }
    setLoadingRsr(false);
  };

  const replaceReportFile = async (file: File) => {
    setLoading(true);
    const fd = new FormData();
    fd.append('pdf', file, file.name);
    const d = await Axios.post(`/api/report_saved_royalties/${year}/${quarter}/pdf?licensor=${params['licensorId']}`, fd);
    const report = d.data.data;
    report.payments = report.payments.map((r: any) => {
      r.account = Vendor.fromApi(r.account);
      return r;
    });
    setLoading(false);
  };

  const deleteReportData = async (pdf: boolean, data: boolean) => {

    setLoading(true);
    setDeleteModal(false);
    const d = await Axios.delete(`/api/report_saved_royalties/${year}/${quarter}/?licensor=${params['licensorId']}&pdf=${pdf ? 1 : 0}&data=${data ? 1 : 0}`);
    const report = d.data.data;
    report.payments = report.payments.map((r: any) => {
      r.account = Vendor.fromApi(r.account);
      return r;
    });
    setLoading(false);
  };

  const regenerateReport = async () => {
    setLoading(true);
    setGenerateModal(false);
    const d = await Axios.post(`/api/report_saved_royalties/${year}/${quarter}/generate?licensor=${params['licensorId']}`);
    const report = d.data.data;
    report.payments = report.payments.map((r: any) => {
      r.account = Vendor.fromApi(r.account);
      return r;
    });

    setReportData(report);
    setLoading(false);
  };

  const changeQuarter = (year: string, quarter: string) => {
    let url;
    if (params['licensorId']) {
      url = `/clients/${params['licensorId']}/performance/payments/fiscal-quarters/${year}/${quarter}`;
    } else {
      url = `/performance/payments/fiscal-quarters/${year}/${quarter}`;
    }
    history.push(url);
  };

  const reportOption = (event: any) => {
    if (event === 'replace') {
      if (hiddenFileInput.current) {
        hiddenFileInput.current.click();
      }
    } else if (event === 'regenerate') {
      setGenerateModal(true);

    } else if (event === 'delete') {
      setDeleteModal(true);
    } else if (event === 'insigniaPDF') {
      window.open(`/api/reports/insignia-payments?client_id=${params['licensorId']}&year=${year}&quarter=${quarter}&type=pdf`, '_blank');
    } else if (event === 'insigniaCSV') {
      window.open(`/api/reports/insignia-payments?client_id=${params['licensorId']}&year=${year}&quarter=${quarter}&type=csv`, '_blank');
    } else if (event === 'audit') {
      setAdminDetailsModal(true);
    }
  };

  const compareValues = (x: number, y: number) => {
    return x.toFixed(2) === y.toFixed(2);
  };

  const date = new Date();
  const totalYears = 1 + date.getFullYear() - 2014;
  const years = Array(totalYears).fill(0).map((_: null, i: number) => i + 2014).reverse();
  const pdfDownload = `/api/report_saved_royalties/${year}/${quarter}/pdf?${user.type === 'admin' ? `licensor=${params['licensorId']}` : ''}`;
  const csvDownload = `/api/report_saved_royalties/${year}/${quarter}/csv?${user.type === 'admin' ? `licensor=${params['licensorId']}` : ''}`;

  return (
    <FullContent>
      {user.type === 'admin' ?
        <input
          type="file"
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              replaceReportFile(e.target.files[0]);
            }
          }}
          accept="application/pdf"
        />
        : null}
      <div className="panel panel-portal">
        <div style={{ padding: 5, background: '#f9f9f9' }} className="panel-body">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <div>
                <select disabled={loading || loadingRsr} onChange={e => changeQuarter(year, e.target.value)} value={quarter} style={{ width: 100 }} className="form-control input-sm">
                  <option value={1}>Q1</option>
                  <option value={2}>Q2</option>
                  <option value={3}>Q3</option>
                  <option value={4}>Q4</option>
                </select>
              </div>
              <div>
                <select disabled={loading || loadingRsr} onChange={e => changeQuarter(e.target.value, quarter)} value={year} style={{ width: 100 }} className="form-control input-sm">
                  {years.map(y => <option value={y}>{formattedFiscalYear(y)}</option>)}
                </select>
              </div>
            </div>

            {user.type === 'admin' ?

              <DropdownButton
                onSelect={reportOption}
                id="report-options-dropdown"
                bsStyle={'default btn-sm'}
                title="Report Options"
              >
                <MenuItem eventKey="audit"> Audit Results </MenuItem>
                <MenuItem eventKey="insigniaPDF"> Insignia PDF </MenuItem>
                <MenuItem eventKey="insigniaCSV"> Insignia CSV </MenuItem>
                <MenuItem eventKey="replace"> Replace Report PDF </MenuItem>
                <MenuItem eventKey="regenerate"> Regenerate Report </MenuItem>
                <MenuItem eventKey="delete"> Delete Report </MenuItem>
              </DropdownButton> : null }

          </div>
        </div>
      </div>

      {loadingRsr ? <LoadingSpinner /> : <div>
        {!rsr ? <div className="panel panel-portal">
          <div className="panel-body">
            <p className="text-center">
              <strong>No report found for this quarter. <br /> Results are not final.</strong>
            </p>
          </div>

        </div> : <div>
          {user.type === 'admin' && rsr.adjustments.length ?
            <div className="panel panel-portal">
              <div className="panel-heading"><strong>Adjusments</strong></div>
              <div className="panel-body">
                {rsr.adjustments.map(a => <p><strong>{a.note}</strong></p>)}
              </div>
            </div>
            : null}
          <div className="panel panel-portal">
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
              <h1><strong>Q{quarter} {year}</strong></h1>
              <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                {rsr.pdf ? <a href={pdfDownload} className="btn btn-primary" target="_blank">Download PDF</a> : null}
                {rsr.csv ? <a href={csvDownload} className="btn btn-default" target="_blank">Download CSV</a> : null}
              </div>
            </div>
            <hr />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', paddingBottom: 10 }}>
              {rsr.sharesAdminFees ? <div className="text-center">
                <p className="no-margin"><strong><CurrencyLabel value={rsr.grossPayments} /></strong></p>
                <p className="text-muted no-margin small">Gross Payments</p>
              </div> : null }
              {!rsr.sharesAdminFees || user.type === 'admin' ? <div className="text-center">
                <p className="no-margin"><strong><CurrencyLabel value={rsr.grossRoyalties} /></strong></p>
                <p className="text-muted no-margin small">Total Royalties</p>
              </div> : null }

              {user.type === 'admin' ? <div className="text-center">
                <p className="no-margin"><strong><CurrencyLabel value={rsr.adminFees} /></strong></p>
                <p className="text-muted no-margin small">Total Admin</p>
              </div> : null}

              {user.type === 'admin' ? <div className="text-center">
                <p className="no-margin"><strong><CurrencyLabel value={rsr.affinityNet} /></strong></p>
                <p className="text-muted no-margin small">Total Affinity</p>
              </div> : null}
              <div className="text-center">
                <p className="no-margin"><strong><CurrencyLabel value={rsr.netRoyalties} /></strong></p>
                <p className="text-muted no-margin small">Net Payments</p>
              </div>
              <div className="text-center">
                <p className="no-margin"><strong>{rsr.paymentCount}</strong></p>
                <p className="text-muted no-margin small">Payments</p>
              </div>

            </div>
          </div>
        </div>}
        {user.type === 'admin' ? <div className="panel panel-portal">
          <div className="panel-heading">
            <strong>Tiered Report for Q{quarter} {year}</strong>
          </div>
          {loading || !reportData ? <LoadingSpinner /> : <div className="panel-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Tier</th>
                  <th>Commisssion Rate</th>
                  <th>Royalties Paid</th>
                  <th>Commissions to Affinity</th>
                  <th>Total Licensor</th>
                </tr>
              </thead>
              <tbody>
                {reportData.tiers.map(t => (<tr>
                  <td>${t.start} - {t.end}</td>
                  <td>{t.commissionRate}</td>
                  <td><CurrencyLabel value={t.royalties} /></td>
                  <td><CurrencyLabel value={t.commission} /></td>
                  <td><CurrencyLabel value={t.clientAmount} /></td>
                </tr>))}
              </tbody>
            </table>
          </div>}
        </div> : null}
        <div className="panel panel-portal">
          {loading || !reportData ? <LoadingSpinner /> : <div className="panel-body">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ maxWidth: 300 }}>Vendor Name</th>
                    <th style={{ textAlign: 'right' }}>Invoice Number</th>
                    <th style={{ textAlign: 'right' }}>Gross Payments</th>
                  </tr>
                </thead>
                <tbody>
                  {reportData.payments.map((p: AffinityQuarterPayment) => (
                    <tr>
                      <td style={{ maxWidth: 300, verticalAlign: 'middle' }}>
                        <div style={{ maxWidth: 300 }}>
                          <AccountCell name={p.account.legalName} image={p.account.image.getSize('th')} />
                        </div>
                      </td>
                      <td style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        {p.invoice_ids.map(i => (<div>
                        {user.type === 'admin' ? <Link to={`/invoices/${i}`}>{i}</Link> : <span>{i}</span> }
                      </div>))}
                    </td>
                      <td style={{ textAlign: 'right', verticalAlign: 'middle' }}><CurrencyLabel value={p.total} /></td>
                    </tr>

                  ))}

                </tbody>
              </table>

          </div>}
        </div>
      </div>}
      <GatewayModal type={ModalType.Primary} title="Regenerate Data" shown={generateModal} onClose={() => setGenerateModal(false)}>
        <Modal.Body>
          Are you sure you want to regenerate this quarters data?
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setGenerateModal(false)} className="btn btn-default pull-left">Cancel</button>
          <button onClick={regenerateReport} className="btn btn-primary pull-right">Regenerate Report</button>
        </Modal.Footer>
      </GatewayModal>

      <GatewayModal type={ModalType.Primary} title="Comparisons" shown={adminDetailsModal} onClose={() => setAdminDetailsModal(false)}>
        <Modal.Body>
          {!loadingRsr && reportData ? <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Value</th>
                  <th className="text-right">Sum</th>
                  <th className="text-right">Saved</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Gross Payments</td>
                  <td className="text-right"><CurrencyLabel value={reportData.total_paid} /></td>
                  <td className="text-right">{rsr ? <CurrencyLabel value={rsr.grossPayments} /> : '-' }</td>
                  <td>{rsr && !compareValues(Number(reportData.total_paid), Number(rsr.grossPayments)) ? <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /> : null }</td>
                </tr>
                <tr>
                  <td>Total Royalties</td>
                  <td className="text-right"><CurrencyLabel value={reportData.total_royalties} /></td>
                  <td className="text-right">{rsr ? <CurrencyLabel value={rsr.grossRoyalties} /> : '-'}</td>
                  <td>{rsr && !compareValues(Number(reportData.total_royalties), Number(rsr.grossRoyalties)) ? <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /> : null }</td>
                </tr>
                <tr>
                  <td>Total Affinity</td>
                  <td className="text-right"><CurrencyLabel value={reportData.total_affinity} /></td>
                  <td className="text-right">{rsr ? <CurrencyLabel value={rsr.affinityNet} /> : '-'}</td>
                  <td>{rsr && !compareValues(Number(reportData.total_affinity), Number(rsr.affinityNet)) ? <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /> : null }</td>
                </tr>
                <tr>
                  <td>Net Payments</td>
                  <td className="text-right"><CurrencyLabel value={reportData.total_licensor} /></td>
                  <td className="text-right">{rsr ? <CurrencyLabel value={rsr.netRoyalties} /> : '-'}</td>
                  <td>{rsr && !compareValues(Number(reportData.total_licensor), Number(rsr.netRoyalties)) ? <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /> : null }</td>
                </tr>
                <tr>
                  <td>Admin</td>
                  <td className="text-right"><CurrencyLabel value={reportData.admin_fees} /></td>
                  <td className="text-right">{rsr ? <CurrencyLabel value={rsr.adminFees} /> : '-'}</td>
                  <td>{rsr && !compareValues(Number(reportData.admin_fees), Number(rsr.adminFees)) ? <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /> : null }</td>
                </tr>
                <tr>
                  <td>Non-sharing Admin</td>
                  <td className="text-right"><CurrencyLabel value={reportData.non_sharing_admin} /></td>
                  <td className="text-right">{rsr ? <CurrencyLabel value={rsr.nonSharingAdmin} /> : '-'}</td>
                  <td>{rsr && !compareValues(Number(reportData.non_sharing_admin), Number(rsr.nonSharingAdmin)) ? <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /> : null }</td>
                </tr>
                <tr>
                  <td>Non-sharing Royalties</td>
                  <td className="text-right"><CurrencyLabel value={reportData.non_sharing_royalties} /></td>
                  <td className="text-right">{rsr ? <CurrencyLabel value={rsr.nonSharingRoyalties} /> : '-'}</td>
                  <td>{rsr && !compareValues(Number(reportData.non_sharing_royalties), Number(rsr.nonSharingRoyalties)) ? <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" /> : null }</td>
                </tr>
              </tbody>

            </table>

          </div> : <LoadingSpinner /> }
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setAdminDetailsModal(false)} className="btn btn-default pull-right">Done</button>
        </Modal.Footer>
      </GatewayModal>

      <GatewayModal type={ModalType.Danger} title="Delete Report" shown={deleteModal} onClose={() => setDeleteModal(false)}>
        <Modal.Body>
          Are you sure you want to delete report this report?
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setDeleteModal(false)} className="btn btn-default pull-left">Cancel</button>
          <button onClick={() => deleteReportData(true, false) } className="btn btn-danger pull-right">Delete Files Only</button>
          <button onClick={() => deleteReportData(true, true)} className="btn btn-danger pull-right">Delete Full Report</button>
        </Modal.Footer>
      </GatewayModal>
    </FullContent>
  );
};
