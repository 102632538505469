import Axios from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { DesignReviewer } from '../../../shared';
import User from '../../../shared/User';
import { GatewayModal, LoadingSpinner, ModalType, UserTypeahead } from '../../shared';

export const ReviewersTab = () => {
  const [loading, setLoading] = React.useState(false);
  const [modalShown, setModalShown] = React.useState(false);
  const [users, setUsers] = React.useState<DesignReviewer[]>([]);
  const [newUsers, setNewUsers] = React.useState <User[]>([]);
  const match = useRouteMatch();
  if (!match) {
    return <></>;
  }

  React.useEffect(() => {
    getUsers();

  },              []);

  const getUsers = async () => {
    setLoading(true);
    const a = await Axios.get(`/api/designs/${match.params['id']}/reviewers`);
    setUsers(a.data.data.map((u: any) => DesignReviewer.fromApi(u)));
    setLoading(false);

  };

  const removeUser = async (id: number) => {
    const c = confirm('Are you sure you want to delete remove this reviewer?');
    if (c) {
      const d = await Axios.delete(`/api/designs/${match.params['id']}/reviewers/${id}`);
      setUsers(d.data.data.map((u: any) => DesignReviewer.fromApi(u)));
    }

  };

  const addUser = async () => {
    if (newUsers.length) {
      const data = {
        user_id:  newUsers[0].id,
      };
      const a = await Axios.post(`/api/designs/${match.params['id']}/reviewers`, data);
      setUsers(a.data.data.map((u: any) => DesignReviewer.fromApi(u)));
      setModalShown(false);
      setNewUsers([]);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ padding: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row-reverse', paddingBottom: 20 }}>
        <button onClick={() => setModalShown(true)} className="btn btn-primary">Add User</button>

      </div>

      {users.map(u => (
        <div key={u.id} style={{ borderTop: '1px solid #eee', padding: 15 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img style={{ height: 50, width: 50, borderRadius: 50 }} src={u.user.image.getSize('sm')} />
              <div style={{ marginLeft: 15 }}>
                <h5 className="no-margin"><strong>{u.user.fullName}</strong></h5>
                <p className="text-muted">{u.user.accountName}</p>
              </div>

            </div>
            <button onClick={() => removeUser(u.id)} className="btn btn-danger">Remove</button>
          </div>

        </div>
      ))}
      <GatewayModal
        shown={modalShown}
        onClose={() => setModalShown(false)}
        type={ModalType.Primary}
        title="Add User"
      >
        <Modal.Body>
          <div className="form-group">
            <label>User</label>
            <UserTypeahead
              accountId={1}
              selected={newUsers}
              onChange={setNewUsers}
              multiple={false}
            />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setModalShown(false)} type="button" className="btn btn-default pull-left">Cancel</button>
          <button onClick={addUser} className="btn btn-primary pull-right">Add User</button>
        </Modal.Footer>
      </GatewayModal>

    </div>
  );

};
