import * as React from 'react';
import { FullContent } from '../ContentFrame';
import { CardSetupForm } from './CardSetupForm';

export class PaymentsTestPage extends React.Component {
  render () {
    return (
      <FullContent>
        <CardSetupForm />
      </FullContent>
    );
  }
}
