import * as React from 'react';
import { AffinityClient } from '../../../../shared';
import { LicensorSelect } from '../../../shared';

interface IProps {
  licensors: AffinityClient[];
  availableLicensors: AffinityClient[];
  isReadOnly: boolean;
  onChange: (licensors: AffinityClient[]) => void;
}

export class DesignReviewSecondary extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(licensors: AffinityClient[]) {
    this.props.onChange(licensors);
  }

  render() {
    if (this.props.isReadOnly) {
      const licensors = this.props.licensors.map(l => l.name).join(', ');
      return (
        <p>
          <strong>Secondary Licensors:</strong> <span className="text-muted">{licensors}</span>
        </p>
      );
    }
    const filtered = this.props.availableLicensors.filter(l => l.name);
    return (
      <div className="form-group">
        <label className="small">Secondary Licensors</label>
        <LicensorSelect
          onChange={this.handleChange}
          licensors={filtered}
          selectedLicensors={this.props.licensors}
          multiple={true}
          id="secondary-licensor-typeahead"
        />
      </div>
    );
  }

}
