import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCaretRight, faCircle, faCog, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Link, Prompt, RouteComponentProps } from 'react-router-dom';
import { getProductCategories } from '../../../api';
import { UserContext } from '../../../contexts';
import { ProductCategory, ProductCategoryParentMapping, VendorProduct, VendorProductApiResponse } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { AffinityDropzone } from '../../Navigation';
import { CategoryModal, FilterBar, GatewayModal, LoadingSpinner, ModalType } from '../../shared';

interface IState {
  products: VendorProduct[];
  productsUpdating: number[];
  loading: boolean;
  searchFilter: string;
  addCategoryShown: boolean;
  categories: ProductCategoryParentMapping[];
  deleteId: number | null;
  deleteModalShown: boolean;
  deleting: boolean;
}

export class VendorProductsPage extends React.Component<RouteComponentProps<any>, IState> {

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      products: [],
      productsUpdating: [],
      loading: true,
      searchFilter: '',
      addCategoryShown: false,
      categories: [],
      deleteId: null,
      deleteModalShown: false,
      deleting: false,
    };
    this.getProducts = this.getProducts.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.findUpdating = this.findUpdating.bind(this);
    this.productRow = this.productRow.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.getCategories = this.getCategories.bind(this);
    this.showCategories = this.showCategories.bind(this);
    this.hideCategories = this.hideCategories.bind(this);
    this.createProduct = this.createProduct.bind(this);
    this.showDelete = this.showDelete.bind(this);
    this.hideDelete = this.hideDelete.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
  }

  componentDidMount() {
    this.getProducts();
    this.getCategories();
  }

  categoryTree(category: ProductCategory) {
    let c: ProductCategory | null = category;
    const tree = [];
    while (c != null) {
      tree.unshift(c);
      c = c.getParent();
    }
    const names = tree.map(cat => cat.name).map((n, index) => {
      return (
        <span>
          {index > 0 ? <FontAwesomeIcon style={{ marginRight: 5, marginLeft: 5 }} icon={faCaretRight} /> : null}
          {n}
        </span>
      );

    });

    return (<div> {names} </div>);
  }

  productRow(p: VendorProduct) {
    return (
      <tr key={p.id}>
        <td>
          {!p.image.isUsingDefault ? <a target="_blank" href={p.image.getSize('or')}>
            <img className="img-responsive" style={{ height: 50, width: 50 }} src={p.image.getSize('sm')} />
          </a> : <div style={{ height: 50, width: 50 }} className="fake-product-image"></div> }
        </td>
        <td style={{ verticalAlign: 'middle' }}>{p.category.name}</td>
        <td style={{ verticalAlign: 'middle' }}>{this.categoryTree(p.category)}</td>

        <td style={{ verticalAlign: 'middle' }}>
          {p.isReviewed ? (
            <span className="text-success"><FontAwesomeIcon icon={faCircle} /> Reviewed </span>
          ) : (
              <span className="text-info"><FontAwesomeIcon icon={faCircle} /> Pending Review </span>
            )}

        </td>
        <UserContext.Consumer>
          {user => user.type === 'admin' ? (
            <td style={{ verticalAlign: 'middle' }}>
              {p.isReviewed ? (
                <button onClick={() => this.updateStatus('pending', p.id)} className="btn btn-sm btn-default pull-right">
                  Mark as Pending
                </button>
              ) : (
                  <button onClick={() => this.updateStatus('reviewed', p.id)} className="btn btn-sm btn-default pull-right">
                    Mark as Reviewed
                  </button>
                )}

            </td>
          ) : null}
        </UserContext.Consumer>
        <td style={{ verticalAlign: 'middle' }}>
          <AffinityDropzone
            onDropAccepted={files => this.uploadPhoto(files[0], p.id)}
            accept="image/*" style={{ padding: 0, background: 'transparent', border: 'none' }}
          >
            <div style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%' }}>
              <button className="btn btn-default btn-sm">Upload Photo</button>

            </div>

          </AffinityDropzone>
        </td>

        <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>
          {this.findUpdating(p.id) ?
            <span><FontAwesomeIcon spin icon={faSpinner} /></span> :
            p.isReviewed ? null :
              <UserContext.Consumer>
                {user => user.type === 'admin' ? (
                  <span style={{ cursor: 'pointer' }} className="text-danger" onClick={() => this.showDelete(p.id)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </span>
                ) : null}
              </UserContext.Consumer>
          }

        </td>

      </tr>

    );

  }

  findUpdating(index: number) {
    return this.state.productsUpdating.findIndex(u => u === index) > -1;
  }

  setSearch(search: string) {
    this.setState({ searchFilter: search });
  }

  hideCategories() {
    this.setState({ addCategoryShown: false });
  }
  showCategories() {
    this.setState({ addCategoryShown: true });
  }

  showDelete(id: number) {
    this.setState({ deleteModalShown: true, deleteId: id });
  }
  hideDelete() {
    if (!this.state.deleting) {
      this.setState({ deleteId: null, deleteModalShown: false });
    }
  }

  render() {
    let body;
    if (this.state.loading) {
      body = (<LoadingSpinner />);
    } else {
      const pendingProductRows = this.state.products
        .filter(p => !p.isReviewed && (
          this.state.searchFilter.length > 1 ?
            p.categoryTree.toLowerCase().includes(this.state.searchFilter.toLowerCase()) : true
        ))
        .map(this.productRow);

      const reviewedProductRows = this.state.products
        .filter(p => p.isReviewed && (
          this.state.searchFilter.length > 1 ?
            p.categoryTree.toLowerCase().includes(this.state.searchFilter.toLowerCase()) : true
        ))
        .map(this.productRow);
      const usedCategories = this.state.products.map(p => p.category);
      body = (
        <div>
          <Prompt
            when={this.state.productsUpdating.length > 0}
            message="Please wait until all images have finished uploading and each product is saved."
            />
          <div style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center' }}>
            <h3 className="no-margin">
              Products
            </h3>

            <div>
              <Link to={'item-types'}>
                <button className="btn btn-default">
                  <FontAwesomeIcon icon={faCog} />Manage Item Types
                </button>
              </Link>
              <UserContext.Consumer>
                {user => user.type === 'admin' ? (
                  <button onClick={this.showCategories} className="btn btn-primary" style={{ marginLeft:10 }}>
                    Add Category
                  </button>
                ) : null}
              </UserContext.Consumer>
            </div>

          </div>
          <hr />
          <FilterBar
            search={this.state.searchFilter}
            useSearch={true}
            usePerPage={false}
            useQuarter={false}
            updateFilters={(p, r) => p.search !== undefined ? this.setSearch(p.search) : null}
           />
          <hr />
          <table className="table">
            <thead>
              <tr>
                <th>
                  Sample
                </th>
                <th>
                  Category Name
                </th>
                <th>
                  Category Tree
                </th>
                <th colSpan={5}>
                  Status
                </th>

              </tr>

            </thead>
            <tbody>
              {pendingProductRows}
              {reviewedProductRows}
            </tbody>
          </table>
          <CategoryModal
           categories={this.state.categories}
           onCategorySelect={this.createProduct}
           onClose={this.hideCategories}
           shown={this.state.addCategoryShown}
           usedCategories={usedCategories}
           isUsedLabel="Added"

          />

        </div>
      );

    }

    return (
      <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
        {body}
        </div>
        <GatewayModal
          shown={this.state.deleteModalShown}
          onClose={this.hideDelete}
          type={ModalType.Danger}
          title="Delete Product"
        >
          <Modal.Body>
            <p className="text-danger text-center"><strong>Are you sure that you want to delete this product?</strong></p>

          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.hideDelete} className="btn btn-default pull-left">Cancel</button>
            <button onClick={this.deleteProduct} disabled={this.state.deleting} className="btn btn-danger pull-right">
              {!this.state.deleting ? 'Yes, Delete Product' : 'Deleting...'}
            </button>

          </Modal.Footer>

        </GatewayModal>

      </div>

      </FullContent>

    );
  }

  getProducts() {
    Axios.get(`/api/vendor-products?account_id=${this.accountId}`)
      .then((response) => {
        const products = response.data.data.map((p: VendorProductApiResponse) => VendorProduct.fromApi(p))
          .sort((a: VendorProduct, b: VendorProduct) => {
            if (a.category.name < b.category.name) {
              return -1;
            }
            if (a.category.name > b.category.name) {
              return 1;
            }
            return 0;
          });
        this.setState({ products, loading: false });
      });
  }

  getCategories() {
    getProductCategories()
      .then((categories) => {
        this.setState({ categories });
      });
  }

  updateStatus(status: 'pending' | 'reviewed', id: number) {
    if (!this.findUpdating(id)) {
      const updating = this.state.productsUpdating;
      updating.push(id);
      this.setState({ productsUpdating: updating });
      Axios.post(`/api/vendor-products/${id}`, { status })
        .then((response) => {
          const products = this.state.products;
          const index = this.state.products.findIndex(p => p.id === id);
          if (index > -1) {
            products[index] = VendorProduct.fromApi(response.data.data);
          }
          const u = this.state.productsUpdating;
          const updatingIndex = u.findIndex(p => p === id);
          if (updatingIndex > -1) {
            u.splice(updatingIndex, 1);
          }

          this.setState({ products, productsUpdating: u });

        });
    } else {
      alert('Please wait until all images have finished uploading and each product is saved.');
    }

  }

  uploadPhoto(file: File, id: number) {
    if (!this.findUpdating(id)) {
      const updating = this.state.productsUpdating;
      updating.push(id);
      this.setState({ productsUpdating: updating });
      const formData = new FormData();

      formData.append('image', file);
      Axios.post(`/api/vendor-products/${id}`, formData)
        .then((response) => {
          const products = this.state.products;
          const index = this.state.products.findIndex(p => p.id === id);
          if (index > -1) {
            products[index] = VendorProduct.fromApi(response.data.data);
          }
          const u = this.state.productsUpdating;
          const updatingIndex = u.findIndex(p => p === id);
          if (updatingIndex > -1) {
            u.splice(updatingIndex, 1);
          }

          this.setState({ products, productsUpdating: u });

        });
    } else {
      alert('Please wait until all images have finished uploading and each product is saved.');
    }

  }

  deleteProduct() {
    if (this.state.deleteId && !this.state.deleting) {
      this.setState({ deleting: true });

      Axios.delete(`/api/vendor-products/${this.state.deleteId}`)
        .then((response) => {
          const products = this.state.products;
          const index = this.state.products.findIndex(p => p.id === this.state.deleteId);
          if (index !== -1) {
            products.splice(index, 1);
            this.setState({ products, deleting: false });
            this.hideDelete();
          }
        });
    }

  }

  createProduct(category: any) {
    this.hideCategories();
    this.setState({ loading: true });
    Axios.post('/api/vendor-products', { vendor_id: this.accountId, category_id: category.id })
      .then((response) => {
        let products = this.state.products;
        const product = VendorProduct.fromApi(response.data.data);
        products.push(product);
        products = products.sort((a: VendorProduct, b: VendorProduct) => {
          if (a.category.name < b.category.name) {
            return -1;
          }
          if (a.category.name > b.category.name) {
            return 1;
          }
          return 0;
        });
        this.setState({ products, loading: false });
      });
  }

  get accountId() {
    // only on vendor pages
    return this.props.match.params['vendorId'];
  }

}
