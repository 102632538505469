import Linkify from 'linkifyjs/react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { MarketplaceInquiry } from '../../../shared';

interface IProps {
  inquiry: MarketplaceInquiry;
  user: any;
}
export class MessageInquiry extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <h5>Custom Order Inquiry</h5>
        <Linkify tagName="p">{ this.props.inquiry.message }</Linkify>
        <b>Quantity:</b> {this.props.inquiry.quantity}
        <br/>
        { this.props.inquiry.dueDate.isValid() ? (
        <div><b>Date Needed:</b> {this.props.inquiry.dueDate.format('MM/DD/YYYY')}</div>) : null}
        { this.props.inquiry.shareEmail ? (<div><b>Email: </b>{this.props.inquiry.email}</div>) : null}
        { this.props.inquiry.sharePhone ? (<div><b>Phone: </b>{this.props.inquiry.phone}</div>) : null}

        { this.props.inquiry.product ?
          (
            <div>
              <h5>{this.props.inquiry.product.name}</h5>
              <Link to={`/marketplace/products/${this.props.inquiry.product.id}`}>
                <img style={{ maxHeight: 100 }} src={this.props.inquiry.product.primaryImage.urls.sm} />
              </Link>
            </div>
          ) : null
        }

      </div>
    );

  }

}
