import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface IStatusCell {
  status: string;
  icon: IconDefinition;
  className: string;
}

const StatusCell = (props: IStatusCell) => {
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    lineHeight: 'initial',
    fontSize: 12,
  };

  const iStyle = {
    marginRight: 5,
    marginLeft: -5,
    fontSize: 12,
  };

  return (
        <div style={containerStyle} className="">
          <FontAwesomeIcon style={iStyle} className={props.className} icon={props.icon} />
          <span>{props.status}</span>
        </div>
  );
};

export default StatusCell;
