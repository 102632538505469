import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { getAffinityPageTitle } from '../../utils';

interface IState {
  pageTitle: string;
  showContent: boolean;

}

class AngularWrapper extends React.Component<any, IState> {
  app: any;
  element: any;
  angular: any;
  historyListener: any;
  canSkip: boolean;
  rootScope: any;
  _mounted: boolean;
  excludedRoutes = [
    '/apply',
    '/products',
  ];
  constructor(props: any) {
    super(props);
    this.state = {
      pageTitle: '',
      showContent: true,
    };
  }

  componentDidMount() {
    this._mounted = true;
    this.angular = window['angular'];

    this.historyListener = this.props.history.listen((location: any, action: any) => {
      const scope = this.angular.element(document.getElementById('angular-app')).scope();
      if (this.app) {
        const $timeout = this.app.get('$timeout');
        $timeout(() => {
          scope.$apply();
        });
      }

    });
    this.angular.module('portalApp').run(['$rootScope', '$location', '$browser',
      ($rootScope: any, $location: any, $browser: any) => {
        $rootScope.$on('$routeChangeStart', (event: any, next: any, current: any) => {

          let shouldTransition = true;
          const path: string = $location.path();
          this.excludedRoutes.forEach((p) => {
            if (path.indexOf(p) !== -1) {
              shouldTransition = false;
            }
          });

          if (this._mounted && shouldTransition) {
            this.setState({ showContent: false });
          }
        });
        $rootScope.$on('$routeChangeSuccess', (event: any, next: any, current: any) => {
          if (this._mounted) {
            this.setState({ showContent: true });
          }
        });
        $rootScope.$on('$locationChangeSuccess', (event: any, next: any, current: any) => {
          if (this._mounted) {
            let currentTitle: string = $location.path().split('/')[1];
            currentTitle = `${currentTitle.charAt(0).toUpperCase()}${currentTitle.substring(1)}`;
            this.setState({ pageTitle: currentTitle });
          }

          if (this.canSkip) {
            this.props.history.replace($location.$$url);
          } else {
            this.canSkip = true;
          }

        });
        $rootScope.$on('$locationChangeStart', (event: any, next: any, current: any) => {
          // In some cases angularjs modal backdrops will not get removed.
          // This will remove the backdrop in cases the bootstrap modal close does not complete
          // backdrop removal in time.
          $('.modal-backdrop').remove();
        });
      }]);

    this.app = this.angular.bootstrap(this.element, ['portalApp']);
    this.rootScope = this.app.get('$rootScope');

  }
  componentWillUnmount() {
    this._mounted = false;
    this.historyListener();
    this.rootScope.$destroy();

  }

  render() {
    return (
      <div>
        <DocumentTitle title={getAffinityPageTitle(this.state.pageTitle)} />
        <div id="content" className={this.state.showContent ? '' : 'fade-out'}>
          <div id="angular-app" ref={element => this.element = element} >

            <div ng-view=""> </div>

            <div className="modal-holder">
              <div
                className="modal modal-portal modal-primary fade close-on-change"
                 id="large-modal"
                 tabIndex={-1}
                 role="dialog"
                 aria-labelledby=""
                 aria-hidden="true"
              >
                <div className="modal-dialog modal-lg" id="large-modal-dialog"><div className="modal-content"></div></div>
              </div>

            </div>
          </div>
        </div>

     </div>
    );

  }

}

export default AngularWrapper;
