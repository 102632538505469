import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as moment from 'moment';
import * as React from 'react';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { ACHPaymentMethod } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';

export const ExpeditedDesignsPage = () => {
  const [agree, setAgree] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [ach, setAch] = React.useState<ACHPaymentMethod | null>(null);
  const [expeditedEnabled, setExpeditedEnabled] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);
  const user = React.useContext(UserContext);
  // const history = useHistory();
  const match = useParams();
  if (user.type !== 'admin' && user.type !== 'vendor') {
    <Redirect push to={'/'} />;
  }
  let accountId : number;
  if (user.type === 'admin') {
    accountId = Number(match['vendorId']);
  } else {
    accountId = user.account.id;
  }

  React.useEffect(() => {
    getDetails();

  },              []);

  const getDetails =  async () => {
    setLoading(true);

    const paymentMethods = Axios.get(`/api/payment-methods?${accountId ? `&account_id=${accountId}` : '' }`);
    const account = Axios.get(`/api/vendors/${accountId}?include=profile`);
    const requiredData = await Axios.all([paymentMethods, account]);
    const a = findAch(requiredData[0].data.data);
    const isEnabled = requiredData[1].data.data.profile.expedited_design_enabled_at;
    setExpeditedEnabled(isEnabled);
    setAch(a);
    setLoading(false);

  };

  const findAch = (data: any[]) => {
    let ach: ACHPaymentMethod | null = null;
    data.forEach((paymentMethod: any) => {
      if (paymentMethod.payment_method_type) {
        const type = paymentMethod.payment_method_type;
        if (type.name === 'ACH') {
          ach = ACHPaymentMethod.fromApi(paymentMethod);
        }
      }
    });
    return ach;
  };

  const enable = async () => {
    if (agree) {
      setSubmitting(true);
      const enabled = await Axios.post(`/api/v1/account/${user.type === 'admin' ? `${accountId}/` : ''}enable_expedited_designs`);
      setExpeditedEnabled(enabled.data.data.expedited_design_enabled_at.date);
      setSubmitting(false);

    }

  };

  const disable = async () => {
    setSubmitting(true);
    await Axios.post(`/api/v1/account/${user.type === 'admin' ? `${accountId}/` : ''}disable_expedited_designs`);
    setExpeditedEnabled('');
    setSubmitting(false);

  };

  let body;
  if (loading) {
    body = <LoadingSpinner />;
  } else {
    const paymentRequired = ach && ach.isVerified ? '' : <div>

      <p className="text-muted">
        <strong className="text-danger">
          <FontAwesomeIcon icon={faInfoCircle} /> Verified checking account required
        </strong> - You must have a
        verified checking account before you can enable expedited design features.
      </p>
      <p className="text-muted">
        Once you have a verified checking account,
        you can control the ability for users to select individual designs for expedited review.

      </p>

    </div> ;

    const expeditedMessage = expeditedEnabled ? `
      Expedited design features are enabled and users can select individual designs for expedited review. You will be
      charged $3.75 per design if the design is reviewed within the allotted time frame. The allotted time frame for
      designs that are selected Monday-Thursday for expedited review is 24 hours. The allotted time frame for designs
      that are selected Friday-Sunday for expedited review is no later than Monday at 11:59 pm Pacific. The fee for
      designs selected for expedited review will be invoiced and automatically debit your account weekly.
    ` : `
    Enabling the expedited design features will allow users to select individual designs for expedited review. You will be charged $3.75 per design if the design is reviewed within the allotted time frame. The allotted time frame for designs that are selected Monday-Thursday for expedited review is 24 hours. The allotted time frame for designs that are selected Friday-Sunday for expedited review is no later than Monday at 11:59 pm Pacific. The fee for designs selected for expedited review will be invoiced and automatically debit from your linked checking account weekly.
    `;

    const date = moment(expeditedEnabled).format('MMMM DD, YYYY');

    const enabledTime = expeditedEnabled ? (
      <p className="text-muted">
        <strong className="text-primary">
          <FontAwesomeIcon icon={faCheckCircle} /> Enabled
        </strong>
        - Expedited design features were enabled on {date}
      </p>
    ) : null;
    const accountInfo = ach && !expeditedEnabled ? (
      <div>
        <h4 className="text-center">{ach.bankName}</h4>
        <h4 className="text-center">{ach.lastFour}</h4>
      </div>
    ) : null;

    body = (
      <div>
        {paymentRequired}
        <p>{expeditedMessage}</p>
        {enabledTime}
        {accountInfo}
        {!expeditedEnabled && ach ? <div className="checkbox">
          <label>
            <input type="checkbox" checked={agree} onChange={e => setAgree(e.target.checked)} />
              I understand the expedited review process and conset to automatic billing.
            </label>
        </div>  : null}
          {!expeditedEnabled ? ach ? <button onClick={enable} className="btn btn-primary">
            {submitting ? 'Enabling...' : 'Enable Expedited Designs'}
          </button> : null :
          <button onClick={disable} className="btn btn-default">
            {submitting ? 'Disabling...' : 'Disable Expedited Designs'}
          </button>}
      </div>

    );

  }

  return (
   <FullContent>
     <div className="panel panel-portal">
       <div className="panel-body">
         <h3><strong>Expedited Designs</strong></h3>
         {body}

       </div>
     </div>
   </FullContent>
  );
};
