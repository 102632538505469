import Axios from 'axios';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import { UserContext } from '../../../contexts';
import { AccountProfile, AccountProfileUpdate, AffinityClient } from '../../../shared';
import { BenchmarkAPIData } from '../../../shared/Benchmark';
import { FullContent } from '../../ContentFrame';
import { GatewayModal, LicensorTypeahead, LoadingSpinner, ModalType } from '../../shared';
import { AccountFormValues } from './AccountProfileForm';

export const BenchmarksPage = () => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [selectedLicensor, setSelectedLicensor] = useState<AffinityClient[]>([]);
  const [benchmarks, setBenchmarks] = useState<AffinityClient[]>([]);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [createModalShown, setCreateModalShown] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [licensorError, setLicensorError] = useState('');
  const [account, setAccount] = React.useState<AccountProfile>(new AccountProfile());
  const [reverseAddCheckbox, setReverseAddCheckbox] = useState(true);
  const routeParams = useParams();

  useEffect(() => {
    getAccount();
    getBenchmarks();
  },        []);

  const getAccount = async () => {
    const a = await Axios.get(`/api/accounts/${getAccountID()}`);
    setAccount(AccountProfile.fromApi(a.data.data));
  };

  const getBenchmarks = () => {
    setLoading(true);
    const params = {
      accountId: getAccountID(),
    };
    Axios.get('/api/benchmark', { params })
      .then((response) => {
        const b = response.data.benchmarks.map((b: BenchmarkAPIData) => new AffinityClient(b));
        setBenchmarks(b);
        setLoading(false);
      });
  };

  const createBenchmark = () => {
    setSaving(true);
    const accountId = getAccountID();
    const licensor = selectedLicensor[0];
    setLicensorError('');

    if (selectedLicensor.length > 0) {
      if (licensor.id === accountId) {
        setLicensorError('You cannot select your own account as a benchmark.');
        setSaving(false);
        return;
      }
      if (benchmarks.some(benchmark => benchmark.id === licensor.id)) {
        setLicensorError('This benchmark has already been added.');
        setSaving(false);
        return;
      }
    } else {
      setLicensorError('No Licensor Selected.');
      setSaving(false);
      return;
    }

    const params = {
      accountId: getAccountID(),
      benchmarkAccountId: selectedLicensor.length > 0 ? selectedLicensor[0].id : null,
      reverseAdd: reverseAddCheckbox,
    };

    Axios.post('/api/benchmark', params)
      .then(() => {
        getBenchmarks();
        setCreateModalShown(false);
        setSelectedLicensor([]);
      })
      .catch((error) => {
        console.error('Error creating benchmark:', error);
        setSaving(false);
      })
      .finally(() => setSaving(false));
  };

  const deleteBenchmark = (id: number) => {
    setSaving(true);
    const params = {
      accountId: getAccountID(),
    };
    Axios.delete(`/api/benchmark/${id}`, { params })
      .then(() => {
        getBenchmarks();
        setDeleteModalShown(false);
        setSaving(false);
      })
      .catch(() => setSaving(false));
  };

  const showDeleteModal = (id: number) => {
    setSelectedId(id);
    setDeleteModalShown(true);
  };

  const hideDeleteModal = () => {
    setDeleteModalShown(false);
    setSelectedId(0);
  };

  const showCreateModal = () => {
    setCreateModalShown(true);
  };

  const hideCreateModal = () => {
    setCreateModalShown(false);
  };

  const handleLicensorChange = (selected: any) => {
    setLicensorError('');
    setSelectedLicensor(selected);
  };

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  let pageContent;
  const rows = benchmarks.length > 0 ? benchmarks.map((benchmark:AffinityClient) =>
    <tr key={benchmark.id}>
      <td style={{ verticalAlign: 'middle' }}><img style={{ width: 50 }} src={benchmark.image.sm} /></td>
      <td style={{ verticalAlign: 'middle' }}><strong>{benchmark.shortName}</strong></td>
      <td style={{ verticalAlign: 'middle' }}>
        <button
          className="btn btn-link text-danger pull-right"
          style={{ marginLeft: '5px' }}
          onClick={() => showDeleteModal(benchmark.id)}
        >
          Delete
        </button>
      </td>
    </tr>,
  ) : null;

  if (loading) {
    pageContent = <LoadingSpinner />;
  } else if (benchmarks.length === 0) {
    pageContent = <em>No Benchmarks Found.</em>;
  } else {
    pageContent = (
      <table className="table">
        <tbody>{rows}</tbody>
      </table>
    );
  }
  const createButtonText = saving ? 'Creating...' : 'Create Benchmark';
  const deleteButtonText = saving ? 'Deleting...' : 'Delete Benchmark';

  return (
    <FullContent breadcrumbs={[{ name: 'Benchmarks' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
        <h3><strong>Benchmarks</strong></h3>
        <button className="btn btn-primary" onClick={showCreateModal}>Add Benchmark</button>
      </div>
      <div className="panel panel-portal">
        {pageContent}
      </div>
      <GatewayModal shown={deleteModalShown} type={ModalType.Danger} onClose={hideDeleteModal} title="Delete Benchmark">
        <Modal.Body>
          <p className="text-center">Are you sure you want to delete this benchmark?</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideDeleteModal} className="btn btn-default">Cancel</button>
          <button onClick={() => deleteBenchmark(selectedId)} className="btn btn-danger">{deleteButtonText}</button>
        </Modal.Footer>
      </GatewayModal>
      <GatewayModal shown={createModalShown} type={ModalType.Primary} onClose={hideCreateModal} title="Add Benchmark">
        <Modal.Body>
          <LicensorTypeahead
            selected={selectedLicensor}
            onChange={handleLicensorChange}
            multiple={false}
          />
          <div className="form-group" style={{ marginTop: 10 }}>
            <input type="checkbox" checked={reverseAddCheckbox} onChange={() => setReverseAddCheckbox(!reverseAddCheckbox)} />  Add {account.accountName} as a benchmark to the selected account
          </div>
          {licensorError && <div className="text-danger" style={{ marginTop: 5 }}>{licensorError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideCreateModal} className="btn btn-default">Cancel</button>
          <button onClick={createBenchmark} className="btn btn-primary">{createButtonText}</button>
        </Modal.Footer>
      </GatewayModal>
    </FullContent>
  );
};
