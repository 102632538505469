import { faDownload, faMousePointer } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { LoadingSpinner, PaginationFooter } from '../../shared';
import { AccountStatsRow } from './AccountStatsRow';
import { VendorStats } from './models';
import { NoSalesDataPlaceholder } from './NoSalesDataPlaceholder';
import { PartialRepresentation } from './PartialRepresentation';

interface IProps {
  vendors: VendorStats[];
  exportUrl: string;
  filterVendor: (vendorId: any) => void;
  setPage: (page: number) => void;
  page: number;
  totalVendors: number;
  hasNext: boolean;
  loading: boolean;
  isCurrent: boolean;
  isPartial: boolean;
}

export class VendorList extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    const rows = this.props.vendors.map(vendorStats =>
      <AccountStatsRow
        onClick={() => this.props.filterVendor(vendorStats.vendor)}
        vendor={vendorStats}
        isCurrent={this.props.isCurrent}
        key={vendorStats.vendor.id}
      />,
    );

    const exportUrl = this.props.exportUrl;

    const table = rows.length ? (
      <div className="table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Rank</th>
                <th>Vendor</th>
                <th>Royalties</th>
                <th>Previous Year</th>
                <th>$ Comparison</th>
                <th>% Comparison</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>

          </table>
        </div>
    ) : (
        <NoSalesDataPlaceholder />
    );

    const content = this.props.loading ? (<LoadingSpinner />) : table;

    const partialContent = this.props.isPartial ? (<PartialRepresentation />) : null;

    const footer = (this.props.page !== 1 || this.props.hasNext) ? (
      <PaginationFooter
        totalResults={this.props.totalVendors}
        currentPage={this.props.page}
        hasNext={this.props.hasNext}
        setPage={this.props.setPage}
      />
    ) : (
      null
    );

    return (
      <div>
      <div className="panel panel-portal">
        <div className="panel-body">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <div>
                <div className="analytics-header text-primary">Top Performing Vendors</div>
                <div >
                  <FontAwesomeIcon icon={faMousePointer} />
                  <span className="text-muted"> Click a vendor to narrow analysis</span>
                </div>

                {partialContent}

              </div>
              <a className="btn btn-default" href={exportUrl}><FontAwesomeIcon icon={faDownload} /> Vendor Sales Export </a>
            </div>

          </div>

        <div className="panel-body">
          {content}

        </div>
      </div>
        {footer}
    </div>
    );

  }
}
