import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { LoadingSpinner } from '../../shared';

export interface InsigniaStats {
  id: number;
  title: string;
  count: number;
  percent: number;
  image: string;
}

interface IProps {
  insignia: InsigniaStats[];
  loading: boolean;
}

export const InsigniaGraph = (props: IProps) => {

  if (props.loading) {
    return <LoadingSpinner />;
  }

  const barColor = (index: number) => {
    const colors = [
      '#4f6980',
      '#849db1',
      '#a2ceaa',
      '#638b66',
      '#bfbb60',
      '#f47942',
      '#fbb04e',
      '#b66353',
      '#d7ce9f',
      '#b9aa97',
      '#7e756d',
    ];

    return colors[index % colors.length];
  };
  const bars = props.insignia.map((i, index) => {
    const data: Chart.ChartData = {
      datasets: [{
        data: [i.percent * 100],
        backgroundColor: barColor(index),

      }],
      labels: [''],
    };
    return (
      <div key={i.id} style={{ maxWidth: '20%', width: '20%', display: 'flex', flexDirection: 'column' }}>
        <div>
          <Bar
            height={300}
            data={data}
            options={{
              legend: {
                display: false,
              },
              scales: {
                yAxes: [{
                  display: false,
                  ticks: {
                    suggestedMax: 100,
                    beginAtZero: true,
                  },
                }],
              },
              tooltips: {
                mode: 'single',
                callbacks: {
                  label: (tooltip: any, data: any) => {
                    if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
                      const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
                      return `${Number(value).toFixed(1)}%`;
                    }
                    return '';
                  },
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: -25,
            flexGrow: 1,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginBottom: 10, backgroundColor: i.image ? 'white' : '#e6e6e6'  }}>
            {i.image ? null : <div style={{ height: 75, width: 75 }}></div>}
            <img  className="img-responsive" src={i.image} />
          </div>
          <span className="insignia-graph-title"><strong>{i.title}</strong></span>
          <strong>
            <NumberFormat decimalScale={1} suffix="%" displayType="text" value={i.percent * 100} />
          </strong>
        </div>

      </div>

    );
  });
  return (
  <div style={{ display: 'flex' }}>
    {bars}

  </div>
  );

};
