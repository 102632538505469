import { faDotCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import * as React from 'react';
import StatusCell from './StatusCell';

interface IRoyaltyStatusCellProps {
  userType: string;
  phaseId: number;
  submittedAt: Date;
}

class RoyaltyStatusCell extends React.PureComponent<IRoyaltyStatusCellProps, any> {

  public render() {

    const statusCellProps: any = {
      status: '',
      icon: null,
      className: '',
    };

    if (this.props.userType === 'admin') {
      if (this.props.phaseId === 1) {
        if (this.props.submittedAt) {
          statusCellProps.status = 'Vendor Review';
          statusCellProps.icon = faDotCircle ;
          statusCellProps.className = 'text-info';
        } else {
          statusCellProps.status = 'Not Submitted';
          statusCellProps.icon = faDotCircle;
          statusCellProps.className = 'text-info';
        }
      } else if (this.props.phaseId === 3) {
        statusCellProps.status = 'Needs Review';
        statusCellProps.icon = faDotCircle;
        statusCellProps.className = 'text-danger';

      } else if (this.props.phaseId === 14) {
        statusCellProps.status = 'Reviewed';
        statusCellProps.icon = faCheck;
        statusCellProps.className = 'text-success';
      }
    } else if (this.props.userType === 'vendor') {
      if (this.props.phaseId === 1) {
        statusCellProps.status = 'Needs Review';
        statusCellProps.icon = faExclamationCircle;
        statusCellProps.className = 'text-info';
      } else if (this.props.phaseId === 3 || this.props.phaseId === 14) {
        statusCellProps.status = 'Submitted';
        statusCellProps.icon = faCheck;
        statusCellProps.className = 'text-success';

      }
    }

    return <StatusCell {...statusCellProps} />;
  }

}

export default RoyaltyStatusCell;
