import { faCheckCircle, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../shared';

interface IEmailStatus {
  vendorName: string;
  vendorId: number;
  invoiceId: number;
  emailSent: boolean;
}

export const AnnualInvoiceEmailsPage = () => {

  const [emailStatuses, setEmailStatuses] = useState<IEmailStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<null|string>(null);
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    getEmailStatuses();
  },        []);

  const getEmailStatuses = async () => {
    setLoading(true);
    setLoadingError(null);
    const emailStatuses = await Axios.get('/api/annual-invoice-emails')
      .then((response => response.data.data.map((si: any) => ({
        vendorId: si.vendor_id,
        vendorName: si.vendor_name,
        invoiceId: si.invoice_id,
        emailSent: si.email_sent,
      })))).catch(() => {
        setLoadingError('Failed to load');
        setLoading(false);
      });
    setEmailStatuses(emailStatuses);
    setLoading(false);
  };

  const clickSendEmails = async () => {
    setSubmitting(true);
    await Axios.post('/api/annual-invoice-emails');
    setSubmitting(false);
    setModalShown(false);
  };

  const statusTableRows = emailStatuses.map(status => (
    <tr>
      <td>
        {status.vendorName}
      </td>
      <td>
        <a href={`/vendors/${status.vendorId}/invoices/${status.invoiceId}`}>
          Invoice #{status.invoiceId}
        </a>
      </td>
      <td>
        {status.emailSent ? (
          <div className="text-muted"><FontAwesomeIcon style={{ marginRight: 10 }} icon={faCheckCircle} />Sent</div>
        ) : (
          <div><FontAwesomeIcon style={{ marginRight: 10 }} icon={faExclamationCircle} />Not sent</div>
        )}
      </td>
    </tr>
  ));

  const statusTable = loading ? (
    <LoadingSpinner />
  ) : (
    <div className="table-responsive">
      <table className="table table-portal table-hover">
        <thead>
          <tr>
            <th scope="col">Vendor</th>
            <th scope="col">Invoice</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {statusTableRows}
        </tbody>
      </table>
    </div>
  );

  const body = loadingError ? loadingError : (
    <>
      {statusTable}
      <div style={{ marginTop: '10px' }}>
        <button
          className="btn btn-primary"
          disabled={loading}
          onClick={() => setModalShown(true)}
        >
          Send Emails
        </button>
      </div>
    </>
  );

  return (
    <FullContent breadcrumbs={[{ name: 'Annual Invoice Emails' }]}>
      <DocumentTitle title={getAffinityPageTitle('Annual Invoice Emails')}>
        <div>
          <div className="panel panel-portal">
            <div className="panel-heading">
              <h3>Annual Renewal Invoice Emails</h3>
            </div>
            <div className="panel-body" style={{ padding: '5px' }}>
              {body}
            </div>
          </div>
          <GatewayModal type={ModalType.Primary} onClose={() => setModalShown(false)} shown={modalShown}>
            <Modal.Body>
              <p>
                Emails will be sent to all vendors in this list that have not yet received an annual
                invoice renewal email.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={clickSendEmails}
                disabled={submitting}
                className="btn btn-primary pull-left"
              >
                Send Emails
              </button>
              <button
                onClick={() => setModalShown(false)}
                className="btn btn-default pull-right"
              >
                Close
              </button>
            </Modal.Footer>
          </GatewayModal>
        </div>
      </DocumentTitle>
    </FullContent>
  );
};
