import axios, { CancelTokenSource } from 'axios';
// import * as  moment from'moment';
import * as React from 'react';
import Media from 'react-media';
import { Route, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import { getMessageThreads } from '../../../api';
import { UserType } from '../../../contexts';
import { MarketplaceMessageThread } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner, PaginationFooter } from '../../shared';
import { MessageThreadPage } from './MessageThreadPage';

interface IProps {
  user: UserType;
}

interface IState {
  threads: MarketplaceMessageThread[];
  loading: boolean;
  page: number;
  totalPages: number;
  totalResults: number;
}

export class MessagesIndexPage extends React.Component <IProps & RouteComponentProps<any>, IState> {

  protected _getThreadsSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      threads: [],
      loading: true,
      page: 1,
      totalPages: 1,
      totalResults: 0,
    };
    this.getThreads = this.getThreads.bind(this);
  }

  componentDidMount() {
    this.getThreads(1);

  }

  getThreads(page: number) {
    this.setState({ page, threads: [], loading: true });
    if (this._getThreadsSource) {
      this._getThreadsSource.cancel('Cancelled getThreads() XHR due to new request.');
    }

    this._getThreadsSource = axios.CancelToken.source();
    let id = this.props.user.account.id;

    if (this.props.user.type === 'admin') {
      id = null;
    }

    if (this.props.match.params['vendorId']) {
      id = this.props.match.params['vendorId'];
    }

    getMessageThreads({ page, vendor_id: id }, this._getThreadsSource)
      .then((response) => {
        const threads = response.data.data.map((thread: any) => MarketplaceMessageThread.fromApi(thread));
        this.setState({ threads, loading: false, totalResults: response.data.total, totalPages: response.data.last_page });
      });
  }

  render() {
    const vendorRoute = this.props.match.params['vendorId'] ? `/vendors/${this.props.match.params['vendorId']}` : '';
    const messages = this.state.threads.map((thread) => {
      return (
        <NavLink
          className="marketplace-thread-item"
          key={thread.id}
          to={`${vendorRoute}/marketplace/messages/${thread.id}`}
        >
          <h4 className="thread-title">
            { this.props.user.type === 'vendor' ?
              thread.users[0].name :
              thread.users.map(user => user.name).join(', ')
            }
          </h4>
            <p className="text-muted thread-message">{thread.latestMessage ? thread.latestMessage.message : (<br />)}</p>
            <p className="text-muted thread-time">
              {thread.latestMessage ? thread.latestMessage.updatedAt.format('M/DD/YYYY h:mm A') : ''}
            </p>
        </NavLink>
      );
    });
    return (
      <FullContent>
        <div className="row">
          <div className="col-md-5">
            <div className="panel panel-portal">
              {!this.state.loading ? (
                <div style={{ padding: 0 }} className="panel-body">
                  {messages}
                </div>
               ) : (<div className="panel-body"> <LoadingSpinner /> </div>)}

            </div>
            {!this.state.loading ? (
              <PaginationFooter
                hasNext={this.state.page < this.state.totalPages}
                currentPage={this.state.page}
                totalResults={this.state.totalResults}
                setPage={this.getThreads}
              />

            ) : null}
          </div>
          <Media query={{ minWidth: 992  }} >
            <div className="col-md-7 marketplace-messages-container">
              <Route path={'/marketplace/messages/:id'} render={
                props =>
                  <MessageThreadPage {...props} user={this.props.user} />
              } />
              <Route path={'/vendors/:vendorId/marketplace/messages/:id'} render={
                props =>
                  <MessageThreadPage {...props} user={this.props.user} />
              } />
            </div>

          </Media>

        </div>

      </FullContent>
    );
  }

}
