import * as React from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import User from '../../../shared/User';
import { CurrencyLabel, DateCell } from '../../shared';
import InlineAdminSelector from './InlineAdminSelector';
import QuarterCell from './QuarterCell';
import QuarterRangeCell from './QuarterRangeCell';
import ResubmissionIndicator from './ResubmissionIndicator';
import RoyaltyStatusCell from './RoyaltyStatusCell';

interface IVendor {
  id: number;
  name: string;
  short_name: string;
  materialized_aliases: string;
  image: any;
}

interface IRow {
  id: number;
  vendor: IVendor;
  due: string;
  assignedAdmin: User | null;
  phaseId: number;
  year: number;
  quarter: number;
  updated: Date;
  submitted: Date;
  resubmitted: boolean;
}

interface IIndexTableRowProps {
  row: IRow;
  history: any;
  showVendorCol: boolean;
  showQuarterRangeCol: boolean;
  showAssignedAdminCol: boolean;
}

class IndexTableRow extends React.PureComponent<IIndexTableRowProps, any> {

  public constructor(props: IIndexTableRowProps) {
    super(props);
  }

  public render() {

    const row = this.props.row;

    const olpLogo = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/olp-logo-bw.jpg';
    const vendorImage = row.vendor;
    const vendorImageSrc = (vendorImage.image && vendorImage.image.urls) ? vendorImage.image.urls.th : olpLogo;

    const vendorRow = (
            <Link className="cell-primary-link" style={{ zIndex: 2 }} to={`/vendors/${row.vendor.id}/royalties`}>
                <img className="pull-left hidden-md hidden-sm hidden-xs"
                     src={vendorImageSrc}
                />
                <strong>{row.vendor.short_name}</strong>
                <small style={{ display: 'block' }}>
                  {row.vendor.materialized_aliases ? row.vendor.materialized_aliases : row.vendor.name}
                </small>
            </Link>
        );

    const rowDue = row.due ? row.due : 0.00;
    const formattedDue = row.phaseId !== 1 ? (<CurrencyLabel value={rowDue} />) : '-';

    const royaltyLink = this.props.showVendorCol ?
      `/vendors/${row.vendor.id}/royalties/${row.id}` :
      `royalties/${row.id}`;

    return (
            <tr key={row.id}>
              {this.props.showVendorCol ? (
                (<td>
                  <Link style={{ zIndex: 1 }} className="cell-link-overlay" to={royaltyLink}></Link>
                  {vendorRow}
                  </td>)
                ) : (
                  <></>
                )}
                <td style={{ width: 95 }}>
                  <QuarterCell year={row.year} quarter={row.quarter}/>
                  <Link className="cell-link-overlay" to={royaltyLink}></Link>
                </td>
              {this.props.showQuarterRangeCol ? (
                (<td>
                  <Link className="cell-link-overlay" to={royaltyLink}>
                    <QuarterRangeCell year={row.year} quarter={row.quarter} />
                  </Link>
                </td>)
              ) : (
                <></>
              )}
              <td style={{ width: 95, padding: 0 }}>
                <DateCell date={row.updated}/>
                <Link className="cell-link-overlay" to={royaltyLink}></Link>
              </td>
              <td style={{ width: 95, padding: 0 }}>
                <DateCell date={row.submitted} />
                <Link className="cell-link-overlay" to={royaltyLink}></Link>
              </td>
              <td style={{ textAlign: 'right', width: 95 }}>
                <Link className="cell-link-overlay" to={royaltyLink}></Link>
                <div className="cell-link-wrapper">
                  {formattedDue}
                </div>
              </td>
              {this.props.showAssignedAdminCol ? (
                <td>
                  <InlineAdminSelector
                    reportId={this.props.row.id}
                    initialAdmin={this.props.row.assignedAdmin}
                  />
                </td>
              ) : (
                <></>
              )}
              <td style={{ width: 185, maxWidth: 165 }}>
                <Link className="cell-link-overlay" to={royaltyLink}></Link>
                <div className="cell-link-wrapper">
                  <UserContext.Consumer>
                    {
                      (user: any) =>
                        <RoyaltyStatusCell
                          userType={user.type}
                          phaseId={this.props.row.phaseId}
                          submittedAt={row.submitted}
                        />
                      }
                  </UserContext.Consumer>
                  </div>

              </td>
              <td style={{ width: 20 }}>
                <Link className="cell-link-overlay testing-things" to={royaltyLink} ></Link>
                <div className="cell-link-wrapper">
                  <ResubmissionIndicator resubmitted={row.resubmitted}/>
                </div>
              </td>
            </tr>
    );
  }
}

export default IndexTableRow;
