import Axios from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../../shared';
import { useRoyaltyReport } from './useRoyaltyReport';

interface IProps {
  onSubmit: (invoiceId: number) => void;
}

export const NoSalesPanel = (props: IProps) => {
  const [modal, setModal] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const report = useRoyaltyReport();

  const submit = async () => {
    setSubmitting(true);
    const r = await Axios.patch(`/api/royalty-reports/${report.reportId}`, { submitted: true, pay_invoice: true });
    report.updateAllReports();
    props.onSubmit(r.data.data.invoice_id);
    setSubmitting(false);
  };

  return (
    <div>
      <div className="panel panel-portal">
        <div className="panel-body">
          <h4>No Sales Report</h4>
          <p className="text-muted">Only submit a no sales report if you did not have any licensed sales during this sales period.</p>
          <p>If you did not have any licensed sales, click the no sales report button below to submit your quarterly report.</p>
          <button onClick={() => setModal(true)} className="btn btn-default">No Sales Report</button>
        </div>
      </div>
      <GatewayModal
        type={ModalType.Primary}
        onClose={() => setModal(false)}
        shown={modal}
        title={'No Sales Report'}
      >
        <Modal.Body>
          <p>
            You are about to submit a no sales report. By clicking submit, you are confirming that there have been no licensed sales for this sales period.
          </p>
          <p>
            If you have any sales to report, including royalty-exempt sales, click cancel and submit your report using the sales data template.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setModal(false)}
            className="btn btn-default pull-left"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary pull-right"
            onClick={submit}
          >
            {submitting ? 'Submitting...' : 'Submit No Sales Report'}
          </button>
        </Modal.Footer>
      </GatewayModal>
    </div>
  );

};
