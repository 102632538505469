import Axios, { CancelTokenSource } from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AccountWebUrlType, AffinityClient, Trademark, TrademarkUse } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FilterBar, GatewayModal, LicensorTypeahead, LoadingSpinner, ModalType, PaginationFooter, TrademarkTypeahead } from '../../shared';
import { getTrademarkUseList, massUpdateTrademarkUses } from './api';
import { UseFilters } from './UseFilters';
import { UsesTable } from './UsesTable';

interface IProps {
  caseId: number;
  useFullContent?: boolean;
  showUseButton?: boolean;
}
export const UsesIndexPage = (props: IProps) => {
  const location = useLocation();
  const [items, setItems] = React.useState<TrademarkUse[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [counts, setCounts] = React.useState<any[]>([]);
  const [selectedLicensor, setSelectedLicensor] = React.useState<AffinityClient[]>([]);
  const [selectedTrademark, setSelectedTrademark] = React.useState<Trademark[]>([]);
  const [massUpdateModalShown, setMassUpdateModalShown] = React.useState(false);
  const [advancedSearch, setAdvancedSearch] = React.useState(false);
  const [platforms, setPlatforms] = React.useState<AccountWebUrlType[]>([]);
  const [filters, setFilters] = React.useState(new UseFilters(location.search));
  const [totalPages, setTotalPages] = React.useState(999);
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const showFullContent = props.useFullContent === false ? false : true;
  const showUseButton = props.showUseButton === true ? true : false;

  React.useEffect(() => {
    // const cancelTokenSource = Axios.CancelToken.source();
    // getItems(cancelTokenSource);
    // getCounts();
    getUrlTypes();

  },              []);

  React.useEffect(() => {
    const cancelTokenSource = Axios.CancelToken.source();
    getItems(cancelTokenSource);
    getCounts();

  },              [selectedTrademark.length, selectedLicensor.length, location.search, props.caseId]);

  const getItems = async (cancelSource: CancelTokenSource) => {

    setLoading(true);
    setSelected([]);
    let clientId = selectedLicensor.length ? selectedLicensor[0].id : null;
    let vendorId = null;
    if (routeMatch !== null) {
      if (routeMatch.params['vendorId']) {
        vendorId = routeMatch.params['vendorId'];
      }
      if (routeMatch.params['clientId']) {
        clientId = routeMatch.params['clientId'];
      }
    }
    const f = {
      clientId,
      vendorId,
      caseId : props.caseId,
      statusId: filters.status ? filters.status : null,
      market: filters.market ? filters.market : null,
      platform: filters.platform ? filters.platform : null,
      page: filters.page,
    };
    const cleaned = omitBy(f, isNil);

    getTrademarkUseList(cleaned, cancelSource).then((trademarkUses) => {
      setItems(trademarkUses.uses);
      setTotalPages(trademarkUses.totalPages);
      setLoading(false);
    });

  };

  const getUrlTypes = async () => {
    const t = await Axios.get('/api/account-web-url-types');
    setPlatforms(t.data.data.map((type: any) => AccountWebUrlType.fromApi(type)));
  };

  const getCounts = async () => {
    let clientId = selectedLicensor.length ? selectedLicensor[0].id : null;
    let vendorId = null;
    if (routeMatch !== null) {
      if (routeMatch.params['vendorId']) {
        vendorId = routeMatch.params['vendorId'];
      }
      if (routeMatch.params['clientId']) {
        clientId = routeMatch.params['clientId'];
      }
    }
    const f = {
      client_id: clientId,
      vendor_id: vendorId,
      case_id : props.caseId,
      status_id: filters.status ? filters.status : null,
      market: filters.market ? filters.market : null,
      platform: filters.platform ? filters.platform : null,
    };
    const cleaned = omitBy(f, isNil);
    const c = await Axios.get('/api/trademark-use-counts', { params: cleaned });
    const m = c.data.data.map((d: any) => {
      return {
        id: d.status_id,
        name: d.status_name,
        count: d.count,
      };
    });
    const all = {
      id: 0,
      name: 'All',
      count: m.reduce((p: number, n: any) => p + n.count, 0),
    };
    setCounts([all, ... m]);

  };

  const toggleSelected = (id: number, isChecked: boolean) => {
    const s = [... selected];
    const i = selected.indexOf(id);
    if (isChecked) {
      if (i === -1) {
        s.push(id);
      }
    } else {
      if (i !== -1) {
        s.splice(i, 1);
      }
    }
    setSelected(s);
  };

  const selectAll = () => {
    if (selected.length === items.length) {
      setSelected([]);
    } else {
      setSelected(items.map(i => i.id));
    }
  };

  const statusColors = (id: number) => {
    switch (id) {
      case 1:
        return 'info';

      case 2:
        return 'danger';

      case 3:
        return 'primary';

      case 4:
        return 'success';

      default:
        return 'primary';
    }
  };

  const applyFilters = (f: any, reload: boolean) => {
    console.log(f, reload);
    if (f.startDate) {
      filters.startDate = f.startDate;
    }
    if (f.endDate) {
      filters.endDate = f.endDate;
    }
    if (f.search !== undefined) {
      filters.search = f.search;
    }
    if (f.market !== undefined) {
      filters.market = f.market;
    }
    if (f.user !== undefined) {
      filters.userId = f.user;
    }
    if (f.page) {
      filters.page = f.page;
    }
    if (f.sort !== undefined) {
      filters.sortBy = f.sort;
    }
    if (f.status !== undefined) {
      filters.status = f.status;
    }
    if (f.startDate !== undefined) {
      filters.startDate = f.startDate;
    }
    if (f.endDate !== undefined) {
      filters.endDate = f.endDate;
    }
    if (f.platform !== undefined) {
      filters.platform = f.platform;
    }
    if (f.search !== undefined) {
      filters.search = f.search;
    }

    if (reload) {
      if (!f.page) {
        filters.page = 1;
      }
      const baseUrl = location.pathname;
      history.replace(`${baseUrl}?${filters.generateQueryParamString()}`);
    }

    const fi = new UseFilters();
    fi.setFilters(filters.filters);
    setFilters(fi);

  };

  const html =
      <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <h3><strong>Trademark Uses</strong></h3>
      {
        showUseButton ?
          <div>
            <DropdownButton
              style={{ marginLeft: 15 }}
              id="content-button"
              title="Add Trademark Uses"
              bsStyle="primary"
            >
              <MenuItem eventKey="1"><Link to={`${props.caseId}/uses/new`}> Internet Content</Link></MenuItem>
              <MenuItem eventKey="2"><Link to={`${props.caseId}/uses/manual-new`}> Other Content</Link></MenuItem>

            </DropdownButton>
          </div> : null
      }
    </div>

    {!selected.length ? <FilterBar
        useSearch={true}
        search={filters.search}
        usePerPage={false}
        useQuarter={false}
        useDates={false}
        updateFilters={console.log}
        useAdvancedSearch={true}
        advancedSearchOnClick={() => setAdvancedSearch(true)}
      >

        <div className="col-md-2">
          <div className="form-group">
            <select value={filters.status} onChange={e => applyFilters({ status: e.target.value }, true)} className="form-control input-sm">
              <option value="">All Statuses</option>
              <option value="1">Processing</option>
              <option value="2">Missing Data</option>
              <option value="3">Pending</option>
              <option value="4">Resolved</option>
            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select value={filters.market} onChange={e => applyFilters({ market: e.target.value }, true)} className="form-control input-sm">
              <option value="">All Markets</option>
              <option value="1">Greek</option>
              <option value="2">College</option>
              <option value="3">Associations</option>

            </select>
          </div>
        </div>
        <div className="col-md-2">
          <div className="form-group">
            <select value={filters.platform} onChange={e => applyFilters({ platform: e.target.value }, true)} className="form-control input-sm">
              <option value="">All Platforms</option>
              {platforms.map(p => <option value={p.id}>{p.name}</option>)}
            </select>
          </div>
        </div>
        {/* <div className="col-sm-2">
            <LicensorTypeahead
              selected={selectedLicensor}
              onChange={setSelectedLicensor}
              multiple={false}
            />

          </div>
          <div className="col-sm-2">
            <TrademarkTypeahead
              selected={selectedTrademark}
              multiple={false}
              onChange={setSelectedTrademark}
              accountId={selectedLicensor.length ? selectedLicensor[0].id : ''}

            />

          </div> */}
        {/* <div className="col-sm-2">
            <button onClick={() => setMassUpdateModalShown(true)} className="btn btn-primary">
              Mass Update
            </button>
          </div> */}

      </FilterBar> :

      <div className="row">
        <div className="col-sm-2">
          <button onClick={() => setMassUpdateModalShown(true)} className="btn btn-primary">
            Mass Update
          </button>
        </div>

      </div>

    }

    {loading ? <LoadingSpinner /> : <div style={{ marginTop: 15 }} className="panel panel-portal">
      <UsesTable
        selected={selected}
        items={items}
        selectAll={selectAll}
        onSelect={toggleSelected}

      />
    </div>}
    <GatewayModal
      shown={massUpdateModalShown}
      onClose={() => setMassUpdateModalShown(false)}
      type={ModalType.Primary}
      title="Mass Update"
    >
      <Formik initialValues={{ resolution: 0 }} onSubmit={async (values) => {
        console.log(values);
        const updates = {
          ids: selected,
          resolution: values.resolution ? values.resolution : null,
        };
        const cancelTokenSource = Axios.CancelToken.source();
        await massUpdateTrademarkUses(updates, cancelTokenSource);
        const ct = Axios.CancelToken.source();
        setSelected([]);
        setMassUpdateModalShown(false);
        getItems(ct);
        getCounts();

      }}>
        {(formProps: FormikProps<{resolution: number}>)  => (
          <Form>
            <Modal.Body>
              <p className="text-center">You are updating {selected.length} records</p>
              <div className="form-group">
                <label>
                  Resolution Type
                </label>
                <Field className="form-control" component="select" name="resolution">
                  <option value={0}>Select Resolution Type</option>
                  <option value={1}>Removed</option>
                  <option value={2}>Non-Infringing</option>
                  <option value={3}>Licensed</option>
                </Field>
                <p className="help">Choosing a resolution type will mark uses as Resolved.</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={() => setMassUpdateModalShown(false)} className="btn btn-default pull-left">
                Cancel
              </button>
              <button className="btn btn-primary pull-right">Submit</button>

            </Modal.Footer>
          </Form>
        )}

      </Formik>

    </GatewayModal>
    <GatewayModal
      type={ModalType.Primary}
      shown={advancedSearch}
      onClose={() => setAdvancedSearch(false)}
      title="Search"
    >
      <Formik onSubmit={(v) => {
        applyFilters({
          market: v.market,
          user: v.owner,
          sort: v.sort,
          status: v.status,
          startDate: v.startDate,
          endDate: v.endDate,
          platform: v.platform,

        },           true);
        setAdvancedSearch(false);
      }}
              initialValues={{
                market: filters.market,
                owner: filters.userId,
                sort: filters.sortBy,
                startDate: filters.startDate,
                endDate: filters.endDate,
                platform: filters.platform,
              }}
      >
        {(formProps: FormikProps<any>) => (
          <Form>
            <Modal.Body>
              {/* <div className="form-group">
                    <label>Status</label>
                    <Field className="form-control" name="status" component="select">
                      <option value="">All Cases ({counts.all})</option>
                      <option value="open">Open Cases ({counts.open})</option>
                      <option value="closed">Closed Cases ({counts.closed}) </option>
                    </Field>
                  </div> */}
              <div className="form-group">
                <label>Market</label>
                <Field className="form-control" name="market" component="select">
                  <option value="">All Markets</option>
                  <option value="1">Greek</option>
                  <option value="2">College</option>
                  <option value="3">Associations</option>
                </Field>
              </div>
              <div className="form-group">
                <label>Platform</label>
                <Field name="platform" component="select" className="form-control">
                  <option value="">All Platforms</option>
                  {platforms.map(p => <option value={p.id}>{p.name}</option>)}
                </Field>
              </div>

              <div className="form-group">
                <label>Licensor</label>
                <LicensorTypeahead
                  selected={selectedLicensor}
                  onChange={setSelectedLicensor}
                  multiple={false}
                />
              </div>

              <div className="form-group">
                <label>Trademark</label>
                <TrademarkTypeahead
                  selected={selectedTrademark}
                  multiple={false}
                  onChange={setSelectedTrademark}
                  accountId={selectedLicensor.length ? selectedLicensor[0].id : ''}

                />
              </div>

              {/* <div className="form-group">
                    <label>Sort</label>
                    <Field className="form-control" name="sort" component="select">
                      <option value="">Default (User Pins, Created At)</option>
                      <option value="asc">Created ASC</option>
                      <option value="desc">Created DESC</option>
                    </Field>
                  </div> */}
              {/* <div className="form-group">
                    <label>Created Dates</label>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          onChange={e => formProps.setFieldValue('startDate', e.target.value)}
                          value={formProps.values.startDate}
                          name="startDate"
                          id="startDate"
                          type="date"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          onChange={e => formProps.setFieldValue('endDate', e.target.value)}
                          value={formProps.values.endDate}
                          name="endDate"
                          id="endDate"
                          type="date"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div> */}

            </Modal.Body>
            <Modal.Footer>
              <button type="button" onClick={() => setAdvancedSearch(false)} className="btn btn-default pull-left">
                Cancel
              </button>
              <button className="btn btn-primary pull-right">
                Search
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </GatewayModal>

    {loading ? null : <PaginationFooter
      setPage={p => applyFilters({ page: p }, true)}
      currentPage={filters.page}
      totalPages={totalPages}
      hasNext={filters.page < totalPages}
    />}
  </>;

  if (showFullContent) {
    return (
      <FullContent>
        {html}
      </FullContent>
    );
  }
  return html;
};
