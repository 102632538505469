import axios, { AxiosError, CancelTokenSource } from 'axios';
import { isNil, omitBy } from 'lodash';
import { TrademarkUse } from '../../../shared';
import { EnforcementTemplate } from '../../../shared/EnforcementTemplate';
import { SubmittableStatuses } from '../../../shared/RequestStatuses';
import { TrademarkCase } from '../../../shared/TrademarkCase';

function logIfCancelled(error: AxiosError, methodName: string): void {
  if (axios.isCancel(error)) {
    console.log(`Cancelled ${methodName} request`, error);
  }
}

interface ITrademarkCaseSearch {
  isOpen?: boolean|number|null;
  clientId?: string|null;
  vendorId?: string|null;
  startDate?: string|null;
  endDate?: string|null;
  closedStartDate?: string|null;
  closedEndDate?: string|null;
  market?: string|null;
  keyword?: string|null;
  ownerId?: string|null;
  page?: number;
  sort?: string|null;
  platform?: string|null;
}

export const getCaseList = (
  filters: ITrademarkCaseSearch,
  cancelTokenSource: CancelTokenSource,
): Promise<{cases: TrademarkCase[], pagination: any}> => {
  const url = '/api/trademark-cases';
  const params = {
    page: filters.page,
    is_open: filters.isOpen,
    client_id: filters.clientId,
    vendor_id: filters.vendorId,
    end_date: filters.endDate,
    start_date: filters.startDate,
    closed_end_date: filters.closedEndDate,
    closed_start_date: filters.closedStartDate,
    market: filters.market,
    keyword: filters.keyword,
    owner_id: filters.ownerId,
    sort: filters.sort,
    platform: filters.platform,
  };
  const cleaned = omitBy(params, isNil);
  return axios.get(url, { params: cleaned, cancelToken: cancelTokenSource.token })
    .then((response) => {
      const v = {
        cases : response.data.data.map((apiItem: any) => TrademarkCase.fromApi(apiItem)),
        pagination: response.data.meta.pagination,
      };
      return v;
    }).catch((error) => {
      logIfCancelled(error, 'getCaseList');
      return error;
    });
};

export const getCaseCounts = (
  filters: ITrademarkCaseSearch,
) => {
  const url = '/api/trademark-case-counts';
  const params = {
    page: filters.page,
    is_open: filters.isOpen,
    client_id: filters.clientId,
    vendor_id: filters.vendorId,
    end_date: filters.endDate,
    start_date: filters.startDate,
    market: filters.market,
    keyword: filters.keyword,
    owner_id: filters.ownerId,
    sort: filters.sort,
    platform: filters.platform,
  };
  const cleaned = omitBy(params, isNil);
  return axios.get(url, { params: cleaned })
    .catch((error) => {
      logIfCancelled(error, 'getCaseCounts');
      return error;
    });
};

export const getCase = (
  id: string,
  cancelTokenSource: CancelTokenSource,
): Promise<TrademarkCase> => {
  const url = `/api/trademark-cases/${id}`;
  return axios.get(url, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return TrademarkCase.fromApi(response.data.data);
    }).catch((error) => {
      logIfCancelled(error, 'getCase');
      return error;
    });
};

type TrademarkCasePatchAction = 'open' | 'close';
export const patchCase = (
  id: string,
  action: TrademarkCasePatchAction,
  data: any,
  cancelTokenSource: CancelTokenSource,
): Promise<TrademarkCase> => {
  const url = `/api/trademark-cases/${id}`;
  let params = {
    action,
  };
  if (data) {
    params = Object.assign(params, data);
  }

  return axios.patch(url, params, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return TrademarkCase.fromApi(response.data.data);
    }).catch((error) => {
      logIfCancelled(error, 'getCase');
      return error;
    });
};

interface IMassUpdateCases {
  ids: number[];
  ownerId?: number|string|null;
  action?: TrademarkCasePatchAction|null;
}
export const massUpdateCases = (
  updates: IMassUpdateCases,
  cancelTokenSource: CancelTokenSource,
): Promise<string> => {
  const url = '/api/trademark-cases';
  const requestBody = {
    ids: updates.ids,
    owner_id: updates.ownerId,
    action: updates.action,
  };
  return axios.patch(url, requestBody, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return response.data.message;
    }).catch((error) => {
      logIfCancelled(error, 'massUpdateTrademarkUses');
      return error;
    });
};

export const openCase = (id: string, cancelTokenSource: CancelTokenSource) => {
  return patchCase(id, 'open', null, cancelTokenSource);
};

export const closeCase = (id: string, resolution: number, note: string, cancelTokenSource: CancelTokenSource) => {
  return patchCase(id, 'close', { note, resolution: Number(resolution) }, cancelTokenSource);
};

type IPinResponse = { status: 'success'} | { status: 'failure'; message: string};
export const pinCase = (id: string, cancelTokenSource: CancelTokenSource): Promise<IPinResponse> => {
  const url = `/api/trademark-cases/${id}/pin`;
  return axios.post(url, {}, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      if (response.status === 400) {
        return {
          status: 'failure' as 'failure',
          message: response.data.message,
        };
      }
      return { status: 'success' as 'success' };
    }).catch((error) => {
      logIfCancelled(error, 'pinCase');
      return {
        status: 'failure' as 'failure',
        message: 'error',
      };
    });
};
export const unpinCase = (id: string, cancelTokenSource: CancelTokenSource): Promise<IPinResponse> => {
  const url = `/api/trademark-cases/${id}/pin`;
  return axios.delete(url, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      if (response.status === 400) {
        return {
          status: 'failure' as 'failure',
          message: response.data.message,
        };
      }
      return { status: 'success' as 'success' };
    }).catch((error) => {
      logIfCancelled(error, 'pinCase');
      return {
        status: 'failure' as 'failure',
        message: 'error',
      };
    });
};

interface ITrademarkUseSearch {
  statusId?: string;
  clientId?: string;
  vendorId?: string;
  keyword?: string;
  caseId?: string;
  trademarkId?: string;
  market?: string|null;
  platform?: string|null;
  page?: number;
}

/**
 * Return a list of TrademarkUse[]
 * @param filters
 * @param cancelTokenSource
 */
export const getTrademarkUseList = (
  filters: ITrademarkUseSearch,
  cancelTokenSource: CancelTokenSource,
): Promise<{ uses: TrademarkUse[], totalPages: number }> => {
  const url = '/api/trademark-uses';
  const params = {
    status_id: filters.statusId,
    client_id: filters.clientId,
    vendor_id: filters.vendorId,
    keyword: filters.keyword,
    case_id: filters.caseId,
    trademark_id: filters.trademarkId,
    market: filters.market,
    platform: filters.platform,
    page: filters.page,
  };
  const cleaned = omitBy(params, isNil);
  return axios.get(url, { params: cleaned, cancelToken: cancelTokenSource.token })
    .then((response) => {
      return {
        uses: response.data.data.map((apiItem: any) => TrademarkUse.fromApi(apiItem)),
        totalPages: response.data.meta.pagination.total_pages,
      };

    })
    .catch((error) => {
      logIfCancelled(error, 'getTrademarkUseList');
      return error;
    });
};

interface ITrademarkUseCount {
  statusId: number;
  statusName: string;
  count: number;
}

export const getTrademarkUseListCounts = (
  filters: ITrademarkUseSearch,
  cancelTokenSource: CancelTokenSource,
): Promise<ITrademarkUseCount[]> => {
  const url = '/api/trademark-use-counts';
  const params = {
    client_id: filters.clientId,
    vendor_id: filters.vendorId,
    keyword: filters.keyword,
    trademark_id: filters.trademarkId,
  };
  return axios.get(url, { params, cancelToken: cancelTokenSource.token })
    .then((response) => {
      return response.data.data.map((apiItem: any) => ({
        statusId: apiItem.status_id,
        statusName: apiItem.status_name,
        count: apiItem.count,
      }));
    })
    .catch((error) => {
      logIfCancelled(error, 'getTrademarkUseListCounts');
      return error;
    });
};

/**
 * Return a specific TrademarkUse by ID
 * @param id
 * @param cancelTokenSource
 */
export const getTrademarkUse = (
  id: string,
  cancelTokenSource: CancelTokenSource,
): Promise<TrademarkUse> => {
  const url = `/api/trademark-uses/${id}`;
  return axios.get(url, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return TrademarkUse.fromApi(response.data.data);
    })
    .catch((error) => {
      logIfCancelled(error, 'getTrademarkUse');
      return error;
    });
};

interface IMassUpdateTrademarkUses {
  ids: number[];
  vendorId?: number|string|null;
  clientId?: number|string|null;
  trademarkId?: number|string|null;
  resolution?: number|string|null;
}
export const massUpdateTrademarkUses = (
  updates: IMassUpdateTrademarkUses,
  cancelTokenSource: CancelTokenSource,
): Promise<string> => {
  const url = '/api/trademark-uses';
  const requestBody = {
    ids: updates.ids,
    vendor_id: updates.vendorId,
    client_id: updates.clientId,
    trademark_id: updates.trademarkId,
    resolution: updates.resolution,
  };
  const cleaned = omitBy(requestBody, isNil);
  return axios.patch(url, cleaned, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return response.data.message;
    }).catch((error) => {
      logIfCancelled(error, 'massUpdateTrademarkUses');
      return error;
    });
};

/**
 * Delete a specific TrademarkUse by ID
 * @param id
 * @param cancelTokenSource
 */
export const deleteTrademarkUse = (
  id: string,
): Promise<null> => {
  const url = `/api/trademark-uses/${id}`;
  return axios.delete(url)
    .then((response) => {
      return null;
    })
    .catch((error) => {
      logIfCancelled(error, 'deleteTrademarkUse');
      return error;
    });
};

interface IURLSubmission {
  url: string;
  vendorId?: string;
  clientId?: string;
  clientIds?: [];
  trademarkId?: string;
  trademarkIds?: [];
}

/**
 * Submit a url for automatic scraping and creation of TrademarkUse
 * @param urlsToScrape
 * @param cancelTokenSource
 */
export const submitUrlsToScrape = (
  urlsToScrape: IURLSubmission[],
  cancelTokenSource: CancelTokenSource,
): Promise<string> => {
  const url = '/api/trademark-use-urls';

  const urls = urlsToScrape.map(item => ({
    url: item.url,
    vendor_id: item.vendorId,
    client_id: item.clientId,
    client_ids: item.clientIds,
    trademark_id: item.trademarkId,
    trademark_ids: item.trademarkIds,
  }));

  return axios.post(
    url,
    { urls },
    { cancelToken: cancelTokenSource.token },
  ).then(response => response.data.message)
    .catch((error) => {
      logIfCancelled(error, 'submitUrlToScrape');
      return error.response.data.message;
    });
};

export type MassUploadStatus = SubmittableStatuses<string, string>;
export const uploadMassUploadSpreadsheet = (file: File): Promise<MassUploadStatus> => {
  const formData = new FormData();
  formData.append('file', file);
  const headers = { 'Content-Type': 'multipart/form-data' };
  return axios.post('/api/trademark-use-urls-csv', formData, { headers })
    .catch(error => error.response)
    .then((response) => {
      if (response.status === 200) {
        return {
          state: 'success',
          content: response.data.message,
        };
      }
      let errorMessage = 'An unknown failure occurred';
      if (response.status === 422 && response.data.error) {
        errorMessage = response.data.error;
      }
      return {
        state: 'failure',
        error: errorMessage,
      };
    });
};

interface IEnforcementTemplateSearch {
  name?: string;
  platformId?: string;
}

/**
 * Return a list of EnforcementTemplate[]
 */
export const getEnforcementTemplateList = (
  filters: IEnforcementTemplateSearch,
  cancelTokenSource: CancelTokenSource,
): Promise<EnforcementTemplate[]> => {
  const url = '/api/enforcement-templates';
  const params = {
    name: filters.name,
    platform_id: filters.platformId,
  };
  return axios.get(url, { params, cancelToken: cancelTokenSource.token })
    .then((response) => {
      return response.data.data.map((apiItem: any) => EnforcementTemplate.fromApi(apiItem));
    })
    .catch((error) => {
      logIfCancelled(error, 'getEnforcementTemplateList');
      return error;
    });
};

/**
 * Return an EnforcementTemplate by ID
 */
export const getEnforcementTemplate = (
  id: number|string,
  cancelTokenSource: CancelTokenSource,
): Promise<EnforcementTemplate> => {
  const url = `/api/enforcement-templates/${id}`;
  return axios.get(url, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return EnforcementTemplate.fromApi(response.data.data);
    })
    .catch((error) => {
      logIfCancelled(error, 'getEnforcementTemplate');
      return error;
    });
};

/**
 * Return an EnforcementTemplate by ID
 */
export const deleteEnforcementTemplate = (
  id: number|string,
  cancelTokenSource: CancelTokenSource,
): Promise<null> => {
  const url = `/api/enforcement-templates/${id}`;
  return axios.delete(url, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return null;
    })
    .catch((error) => {
      logIfCancelled(error, 'getEnforcementTemplate');
      return error;
    });
};

interface ICreateEnforcementTemplate {
  name: string;
  content: string;
  description: string;
  platformId?: number|null;
  isDmca: boolean;
}

/**
 * Create a new EnforcementTemplate
 */
export const createEnforcementTemplate = (
  createParams: ICreateEnforcementTemplate,
  cancelTokenSource: CancelTokenSource,
): Promise<EnforcementTemplate> => {
  const url = '/api/enforcement-templates';

  const body = {
    name: createParams.name,
    content: createParams.content,
    description: createParams.description,
    platform_id: createParams.platformId,
    is_dmca: createParams.isDmca,
  };

  return axios.post(url, body, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return EnforcementTemplate.fromApi(response.data.data);
    })
    .catch((error) => {
      logIfCancelled(error, 'createEnforcementTemplate');
      return error;
    });
};

type IUpdateEnforcementTemplate = ICreateEnforcementTemplate & { id: number };

/**
 * Update an existing EnforcementTemplate
 */
export const updateEnforcementTemplate = (
  updateParams: IUpdateEnforcementTemplate,
  cancelTokenSource: CancelTokenSource,
): Promise<EnforcementTemplate> => {

  const body = {
    name: updateParams.name,
    content: updateParams.content,
    description: updateParams.description,
    platform_id: updateParams.platformId,
    is_dmca: updateParams.isDmca,
  };

  const url = `/api/enforcement-templates/${updateParams.id}`;
  return axios.post(url, body, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return EnforcementTemplate.fromApi(response.data.data);
    })
    .catch((error) => {
      logIfCancelled(error, 'updateEnforcementTemplate');
      return error;
    });
};

interface ITemplatePreviewParams {
  caseId: number|string;
  templateId: number|string;
}

export const getEnforcementTemplatePreview = (
  templatePreviewParams: ITemplatePreviewParams,
  cancelTokenSource: CancelTokenSource,
): Promise<string> => {
  const url = '/api/enforcement-template-previews';
  const params = {
    case_id: templatePreviewParams.caseId,
    template_id: templatePreviewParams.templateId,
  };
  return axios.get(url, { params, cancelToken: cancelTokenSource.token })
    .then((response) => {
      return response.data.data.html;
    }).catch((error) => {
      logIfCancelled(error, 'getEnforcementTemplatePreview');
      return error;
    });
};

export const sendDmcaEmail = (
  templatePreviewParams: ITemplatePreviewParams,
  cancelTokenSource: CancelTokenSource,
): Promise<string> => {
  const url = '/api/dmca-emails';
  const params = {
    case_id: templatePreviewParams.caseId,
    template_id: templatePreviewParams.templateId,
  };
  return axios.post(url, params, { cancelToken: cancelTokenSource.token })
    .then((response) => {
      return response.data.message;
    }).catch((error) => {
      logIfCancelled(error, 'sendDmcaEmail');
      return error;
    });
};
