import Axios from 'axios';
import { groupBy, map, orderBy } from 'lodash';
import * as React from 'react';
import { Highlighter, Menu, MenuItem, Token, Typeahead } from 'react-bootstrap-typeahead';
import { Insignia } from '../../../shared';

interface InsigniaTypeaheadProps {
  onChange: (insignia: Insignia[]) => void;
  licensorId: number;
  selected: Insignia[];
  multiple: boolean;
  clearButton?: boolean;
}

export const InsigniaTypeahead = (props: InsigniaTypeaheadProps) => {
  const [insignia, setInsignia] = React.useState<Insignia[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getInsignia();

  },              [props.licensorId]);

  const getInsignia = async () => {
    setLoading(true);
    const ins = await Axios.get(`/api/brandmarks?hide_deleted=1&client_id=${props.licensorId}`);
    const insignia = ins.data.data.map((i: any) => Insignia.fromApi(i));
    setInsignia(insignia);
    const selected = [... props.selected];
    let shouldUpdate = false;
    selected.forEach((c, i) => {
      console.log(selected);
      if (c.title === 'Loading') {
        shouldUpdate = true;
        const foundCat = insignia.find((ca: Insignia) => Number(ca.id) === Number(c.id));
        if (foundCat) {
          selected[i].title = foundCat.title;
        }
      }
    });
    if (shouldUpdate) {
      props.onChange(selected);
    }
    setLoading(false);

  };

  const parentHasRules: any = (ins: Insignia) => {
    // find parent with rule information
    const p = insignia.find((i) => {
      if (ins.parent) {
        return i.id === ins.parent.id;
      }
      return false;
    });
    if (p) {
      return ins.hasRules() || parentHasRules(p);
    }
    return ins.hasRules();
  };

  const renderToken = (selected: Insignia, tokenProps: any, index: number) => {

    return (
      <Token
        key={index}
        onRemove={tokenProps && tokenProps.onRemove}
        style={loading && !insignia.length ? undefined : {
          // backgroundColor: '#f2f8fc',
          // backgroundColor: selected.isVendorAllowed(props.vendor) ? !parentHasRules(selected) ? '#dff0d8' : '#fcf8e3' : '#f2dede',
          // color: selected.isVendorAllowed(props.vendor) ? !parentHasRules(selected) ? '#3c763d' : '#8a6d3b' : '#a94442',
        }}
      >
       {loading && !insignia.length ? 'Loading...' : selected.title}

      </Token>
    );

  };
  const renderMenu = (insignia: Insignia[], menuProps: any) => {
    let index = 0;
    // @ts-ignore
    const orderedInsignia = orderBy(insignia.filter(i => !props.selected.map(ins => ins.id).includes(i.id)), 'order', 'asc');
    const grouped = groupBy(orderedInsignia
      .filter(i => i.parent),
                            i => i.parent && i.parent.title);
    const ungrouped = orderedInsignia.filter(i => !i.parent);

    const getMenuItem = (insignia: Insignia) => {
      const position = insignia.order ? insignia.order : 999;
      return <MenuItem
        key={insignia.id}
        option={insignia}
        position={position}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 25, height: 25, marginRight: 10 }}>
            {
              insignia.image.isUsingDefault ? null : (
                <img className="img-responsive" alt="" src={insignia.image.getSize('th')} />
              )}
          </div>

          <span><Highlighter search={menuProps.text}>{insignia.title}</Highlighter> </span>
        </div>
      </MenuItem>;
    };

    const groupedItems = Object.keys(grouped).map(parent => [
      !!index && <Menu.Divider key={`${parent}-divider`} />,
      <Menu.Header key={`${parent}-header`}>
        {parent}
      </Menu.Header>,
      map(grouped[parent], (state) => {
        const item = getMenuItem(state);

        index++;
        return item;
      }),
    ]);
    const ungroupedItems = ungrouped.length > 0 ? (
      <>
      <Menu.Header key="ungrouped-header">Top-level</Menu.Header>
        {ungrouped.map((insignia: Insignia) => (
          getMenuItem(insignia)
        ))}
        <Menu.Divider key="ungrouped-divider" />
      </>
    ) : null;

    return (
      <Menu {...menuProps}>
        {ungroupedItems}
        {groupedItems}
      </Menu>
    );

  };

  return (
    <Typeahead
      id="insignia-typeahead"
      placeholder="Insignia"
      renderMenu={renderMenu}
      selected={props.selected}
      options={insignia}
      onChange={props.onChange}
      multiple={props.multiple}
      labelKey={option => option.title}
      renderToken={renderToken}
      paginate={false}
      isLoading={loading}
      maxResults={insignia.length}
      clearButton={props.clearButton}

    />
  );

};
