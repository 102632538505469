
import * as moment from 'moment';
import { AffinityImage, ImageApiResponse } from '../Image';
import { DesignActivity, DesignActivityApiResponse } from './DesignActivity';
import { DesignPhase, DesignPhaseApiResponse } from './DesignPhase';

export interface DesignInterationApiResponse {
  id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  decision_at: string;
  phase: DesignPhaseApiResponse;
  image: ImageApiResponse;
  activity: DesignActivityApiResponse[];
}

export class DesignIteration {
  id: number;
  created: moment.Moment;
  updated: moment.Moment;
  deleted: moment.Moment;
  decision: moment.Moment;
  phase: DesignPhase;
  image: AffinityImage;
  activity: DesignActivity[] = [];

  static fromApi(data: DesignInterationApiResponse) {
    const iteration = new DesignIteration();
    iteration.id = data.id;
    iteration.created = moment.utc(data.created_at).local();
    iteration.updated = moment.utc(data.updated_at).local();
    iteration.deleted = moment.utc(data.deleted_at).local();
    if (data.decision_at) {
      iteration.decision = moment.utc(data.decision_at).local();
    }
    iteration.phase = DesignPhase.fromApi(data.phase);
    if (data.image) {
      iteration.image = AffinityImage.fromApi(data.image);
    }
    if (data.activity) {
      iteration.activity = data.activity.map(activity => DesignActivity.fromApi(activity));
    }
    return iteration;
  }

  get reviewDuration() {
    if (this.decision) {
      return this.created.from(this.decision, true);
    }
    return 'Pending Review';
  }
}
