import axios, { AxiosError, AxiosPromise, CancelTokenSource } from 'axios';

function logIfCancelled(error: AxiosError, methodName: string): void {
  if (axios.isCancel(error)) {
    console.log(`Cancelled ${methodName} request`, error);
  }
}

export const deleteBrandAsset = (
  brandId: number,
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const url = `/api/brand-assets/${brandId}`;
  return axios.delete(url, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'deleteBrandAsset');
      return error;
    });
};

export const reorderBrandAsset = (
  brandId: number,
  newOrderIndex: number,
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const url = `/api/brand-assets/${brandId}`;

  const params = {
    index: newOrderIndex,
  };
  return axios.patch(url, params, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'reorderBrandAsset');
      return error;
    });
};
