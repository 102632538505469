import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

export enum PayoutAccountStatus {
  NotSubmitted,
  Submitted,
}

export interface MarketplacePayoutAccount {
  bank: string;
  lastFour: number;
}

interface IProps {
  formProps: any;
  status: PayoutAccountStatus;
  account: MarketplacePayoutAccount | null;
  onSubmit?: (account: any) => any;
  error?: string;
}

export const PayoutValidationSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required('Account Number is required.'),
  verifyAccountNumber: Yup.string()
    .oneOf([Yup.ref('accountNumber'), null], 'Your account numbers must match.')
    .required('Please verify your account number.'),
  routingNumber: Yup.string()
    .length(9, 'Routing Number must be 9 digits')
    .required('Routing Number is required.'),
});

export const PayoutDefaultValues = {
  accountNumber: '',
  verifyAccountNumber: '',
  routingNumber: '',
};

export class PayoutAccount extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  createStripeKey(bank: { routing: string, account: string}) {

  }

  formFieldClassName(isTouched: boolean, error: any) {
    if (isTouched && error) {
      return 'form-group has-error';
    }
    return 'form-group';

  }

  render() {
    const account = this.props.account;
    const formProps = this.props.formProps ;
    const error = this.props.error;
    let errorMessage = null;
    if (error && error.length) {
      errorMessage = <p className="text-danger text-center">{error}</p>;
    }
    const submittedAccount = this.props.status === PayoutAccountStatus.Submitted && account ? (

    <div className="col-xs-10 col-xs-push-1 text-success text-center">
        <h3 ><FontAwesomeIcon icon={faCheckCircle} /></h3>
        <p>Payouts are configured to credit your {account.bank} account ending in {account.lastFour}.</p>
      </div>

    ) : (
        <div className="row">
          <div className="col-md-9">
            {errorMessage}
            <div className="form-horizontal">
              <div className={this.formFieldClassName(formProps.touched.accountNumber, formProps.errors.accountNumber)}>
                <label htmlFor="" className="control-label col-sm-4">Account Number</label>
                <div className="col-sm-8">
                  <Field name="accountNumber" placeholder="Account Number" type="text" className="form-control" />
                  <ErrorMessage name="accountNumber" />
                </div>
              </div>
              <div
                className={
                  this.formFieldClassName(formProps.touched.verifyAccountNumber, formProps.errors.verifyAccountNumber)
                }
              >
                <label htmlFor="" className="control-label col-sm-4">Verify Account Number</label>
                <div className="col-sm-8">
                  <Field
                    name="verifyAccountNumber"
                    placeholder="Verify Account Number"
                    type="text"
                    className="form-control"
                  />

                  <ErrorMessage name="verifyAccountNumber" />
                </div>
              </div>
              <div className={this.formFieldClassName(formProps.touched.routingNumber, formProps.errors.routingNumber)}>
                <label htmlFor="" className="control-label col-sm-4">Routing Number</label>
                <div className="col-sm-8">
                  <Field name="routingNumber" placeholder="Routing Number" type="text" className="form-control" />

                  <ErrorMessage name="routingNumber" />
                </div>
              </div>
            </div>

          </div>

        </div>

    );
    return (

      <div className="row">
        <div className="col-xs-10 col-xs-push-1">
          <h4 className="text-center text-primary">Payout Bank Account</h4>
          <p className="text-center">
            Payout accounts must be checking accounts based in the United States that can receive ACH credit transfers.
            Your payout account will never be used for billing and can be updated at any time.
          </p>
          <br />

        </div>

        {submittedAccount}

      </div>
    );
  }
}
