import Axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ACHPaymentMethod, CCPaymentMethod } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { ACHPaymentStub, CreditCardPaymentStub, NewACHPayment, NewCreditCard } from '../../Payments';
import { LoadingSpinner } from '../../shared';

export const PaymentMethodsPage = (props: RouteComponentProps<any>) => {
  const [loading, setLoading] = useState(true);
  const [ach, setAch] = useState<ACHPaymentMethod | null>(null);
  const [cards, setCards] = useState<CCPaymentMethod[]>([]);
  const [modifyingPM, setModifiyingPM] = useState<number | 'ach' | 'cc' | null>(null);

  const accountId = props.match.params['vendorId'];
  useEffect(() => {
    getPaymentMethods();
  },        []);

  const getPaymentMethods = async () => {
    setLoading(true);
    const accountString = accountId ? `?account_id=${accountId}` : '';
    const pm = await Axios.get(`/api/payment-methods${accountString}`)
      .then((response) => {
        const methods = parsePaymentMethods(response.data.data);
        return { ach: methods.ach, cards: methods.cards };
      });

    setAch(pm.ach);
    setCards(pm.cards);
    setModifiyingPM(null);
    setLoading(false);

  };
  const parsePaymentMethods = (data: any[]) => {
    const cards: CCPaymentMethod[] = [];
    let ach: ACHPaymentMethod | null = null;
    data.forEach((paymentMethod: any) => {
      if (paymentMethod.payment_method_type) {
        const type = paymentMethod.payment_method_type;
        if (type.name === 'ACH') {
          ach = ACHPaymentMethod.fromApi(paymentMethod);
        } else {
          cards.push(CCPaymentMethod.fromApi(paymentMethod));
        }
      }
    });
    return { cards, ach };
  };

  const cardList = cards.filter(c => !modifyingPM).map(c => (
    <CreditCardPaymentStub
      key={c.id}
      card={c}
      onSelect={getPaymentMethods}
      onRemove={getPaymentMethods}
    />
  ));

  const achStub = ach && (!modifyingPM || modifyingPM === ach.id) ? (
    <ACHPaymentStub
      onSelect={getPaymentMethods}
      onRemove={getPaymentMethods}
      achAccount={ach}
      onVerified={getPaymentMethods}
      accountId={accountId}
      toggleModify={setModifiyingPM}
    />

  ) : null;

  const newACH = !ach && (modifyingPM === 'ach' || !modifyingPM) ? (
    <NewACHPayment
      onSuccess={getPaymentMethods}
      accountId={accountId}
      toggleModify={setModifiyingPM}
    />
  ) : null;

  let body;
  if (loading) {
    body = (
     <LoadingSpinner />
    );
  } else {
    body = (
      <div className="row">
        <div className="col-xs-12 col-md-8 col-md-offset-2">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <h3 className="text-center"><strong>Manage Payment Methods</strong></h3>
            {achStub}
            {cardList}
            {newACH}
            { modifyingPM === 'cc' || !modifyingPM ? <NewCreditCard
              onCardSuccess={getPaymentMethods}
              accountId={accountId}
              currentCardCount={cards.length}
              toggleModify={setModifiyingPM}

            /> : null }
          </div>
        </div>
      </div>

    );
  }

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          {body}
        </div>
      </div>
    </FullContent>
  );
};
