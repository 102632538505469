import { faBadgeCheck, faEdit, faPenSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoyaltyReportLineItem } from '../../../shared';
import { CurrencyLabel } from '../../shared';

interface IProps {
  onEdit: (pos: number) => any;
  lineItem: RoyaltyReportLineItem;
  position: number;
  canEdit: boolean;
  hideDesignPreview: boolean;
  showCityState: boolean;
}

export const SalesDataRow = (props: IProps) => {
  const l = props.lineItem;
  let retailer = l.retailer ? l.retailer : l.customer;
  if (l.retailer && l.retailerAccount) {
    retailer = l.retailerAccount.shortName;
  }

  return (
    <tr className={l.isDesignClient ? '' : 'danger'}>
      {props.canEdit ? <td style={{ width: 50, verticalAlign: 'middle', textAlign: 'center' }}>
       <span onClick={() => props.onEdit(props.position) }>
          <FontAwesomeIcon className="text-primary" icon={faEdit} />
        </span>
      </td> : null}
      {!props.hideDesignPreview ? <td style={{ width: 80, verticalAlign: 'middle' }}>
        <img src={l.design ? l.design.primaryIteration.image.getSize('sm') : l.licensor.affinityImage.getSize('sm')} style={{ width: 50, height: 50, margin: 'auto' }} className="img-responsive" />
      </td> : null}
      <td style={{ width: 150, verticalAlign: 'middle' }}>
        <p className="no-margin">{l.licensor.name}</p>
        <p className="no-margin">{l.productCategory ? l.productCategory.name : ''}</p>
        <p className="no-margin small">{l.upi ? l.design ? <Link to={`/designs/${l.design.id}`} target="_blank">{l.upi}</Link> :  <span className="text-danger">{l.upi}</span> : '' }</p>

      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <p className="no-margin">{l.invoiceID}</p>
        <p className="no-margin help-block">{moment(l.invoiceDate).format('MM-DD-YYYY')}</p>
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <p className="no-margin">{retailer}  {(l.retailer && l.retailerAccount) && <FontAwesomeIcon className="text-primary" icon={faBadgeCheck}/>}</p>
        <p className="no-margin help-block">{l.channel.name}</p>
      </td>
      {props.showCityState ? <td style={{ verticalAlign: 'middle', width:100 }}>
        <p className="no-margin">{l.postalCode ? `${l.postalCode.city}, ${l.postalCode.state}` : ''}</p>
      </td> : null}
      <td style={{ width: 150, verticalAlign: 'middle' }} className="text-right">
        <p className="no-margin">
          <CurrencyLabel value={l.grossSales} />
        </p>
        <p className="no-margin help-block">{l.quantity} unit{l.quantity > 1 ? 's,' : ','} <CurrencyLabel value={l.pricePerUnit} /> each</p>
      </td>
      <td style={{ width: 150, verticalAlign: 'middle' }} className="text-right">
        <p className="no-margin"><CurrencyLabel value={l.royalty} /></p>
            <OverlayTrigger placement="bottom" overlay={<Tooltip style={{ zIndex: 100000 }} id={String(l.id)}>{l.schedule && l.schedule.labelText()}</Tooltip>}>
              <p className="no-margin help-block">{l.schedule && l.schedule.title} </p>
            </OverlayTrigger>
      </td>
    </tr>

  );

};
