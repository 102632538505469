import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AccountWebUrlType } from '../../shared';

interface IProps {
  type: AccountWebUrlType;
}

export const UrlTypeTooltip = (props: IProps) => {
  if (!props.type) {
    return null;
  }

  const tooltip = (<Tooltip id={`${props.type.id}-platorm`}>{props.type.name}</Tooltip>);

  return (
    <OverlayTrigger
      placement="top"
      overlay={tooltip}
    >
      <span><i className={props.type.icon}></i></span>
    </OverlayTrigger>
  );

};
