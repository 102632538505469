import { ChartOptions } from 'chart.js';
import { max, uniq } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { formattedFiscalYear, toUSD } from '../../../utils';
import { LoadingSpinner } from '../../shared';
import { ChartHeader } from '../ChartHeader';

interface PerformanceRevStats {
  grossSales: number;
  royalties: number;
  year: number;
}

interface IProps {
  historicalData: PerformanceRevStats[];
  loading: boolean;
}

export const HistoricalPerformance = (props: IProps) => {

  if (props.loading) {
    return (<LoadingSpinner />);
  }
  const availableYears = uniq(props.historicalData.map(d => d.year));

  const dataSets = [
    {
      label: 'Sales',
      data: props.historicalData.map(d => d.grossSales),
      yAxisID: 'Sales',
    },
    {
      label: 'Royalties',
      data: props.historicalData.map(d => d.royalties),
      yAxisID: 'Royalties',
    },
  ];

  const barData: Chart.ChartData = {
    datasets: dataSets,
    labels: availableYears.map(d => formattedFiscalYear(d)),
  };

  const barOptions: Chart.ChartOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'index',
      callbacks: {
        label: (tooltip, data: any) => {
          if (tooltip.index !== undefined && tooltip.datasetIndex !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            return `${data.datasets[tooltip.datasetIndex].label}: ${toUSD(value)}`;
          }
          return '$';
        },
      },

    },
    scales: {
      yAxes: [{
        id: 'Royalties',
        stacked: false,
        position: 'left',
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }, {
        id: 'Sales',
        stacked: false,
        position: 'right',
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }],
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  return (
    <div>
      <ChartHeader header="Historical Insights" subHeader="Annual Sales and Royalties" />
      <div style={{ height: 250, maxHeight: 250 }}>
        <Bar
          data={barData}
          options={barOptions}
        />
      </div>
    </div>
  );

};
