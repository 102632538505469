import { faCheck, faPauseCircle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight, faCircle, faComment, faTimes } from '@fortawesome/pro-solid-svg-icons';

export interface DesignPhaseApiResponse {
  id: number;
  name: string;
  short_name: string;
  banner_class: string;
  is_decision: boolean;
  phase_group: string;
}

export class DesignPhase {
  id: number;
  name: string;
  shortName: string;
  banner: string;
  decision: boolean;
  buttonText: string;
  phaseGroup: string;

  static fromApi(data: DesignPhaseApiResponse) {
    const phase = new DesignPhase();
    phase.id = data.id;
    phase.name = data.name;
    phase.shortName = data.short_name;
    phase.banner = data.banner_class;
    phase.decision = data.is_decision;
    phase.phaseGroup = data.phase_group;
    return phase;
  }

  get buttonClass() {
    switch (this.id) {
      case 1:
        return 'default';
      case 2:
      case 3:
        return 'primary';
      case 7:
      case 8:
      case 9:
        return 'danger';
      case 12:
      case 13:
      case 14:
      case 15:
        return 'success';
      default:
        return 'info';
    }
  }

  get labelClass() {
    switch (this.id) {
      case 1:
      case 2:
      case 3:
        return 'text-info';
      case 7:
      case 8:
      case 9:
        return 'text-danger';
      case 12:
      case 13:
      case 14:
      case 15:
        return 'text-success';
      default:
        return 'text-info';
    }
  }
  get icon() {
    switch (this.id) {
      case 1:
        return faPauseCircle;
      case 2:
      case 3:
        return faArrowRight;
      case 7:
      case 8:
      case 9:
        return faTimes;
      case 12:
      case 13:
        return faComment;
      case 14:
      case 15:
        return faCheck;
      default:
        return faCircle;
    }
  }

  getDefaultReviewText(isExclusive: boolean) {
    switch (this.id) {
      case 1:
        return 'This design is on hold.';
      case 2:
        let sendToClient = 'Please review this design.';
        if (!isExclusive) {
          sendToClient += ' <br /><br />If you do not approve or reject the design within two (2) business days, we will approve it on your behalf.';
        }
        // tslint:disable-next-line:max-line-length
        return sendToClient;
      case 3:
        return 'This design is in admin review.';
      case 7:
        return '';
      case 8:
        return 'This design is rejected. <br /><br />We welcome you to modify and resubmit the design for further review.';
      case 9:
        // tslint:disable-next-line:max-line-length
        return 'This design is rejected. <br /><br />Due to the nature of the design, it must be completely revised and submitted separately as a new design.';
      case 12:
        // tslint:disable-next-line:max-line-length
        return 'This design is approved, pending the following change(s). <br /><br />The design does not need to be resubmitted as long as the change(s) are made. <br /><br />Please be advised that approval of this design is limited only to the appropriateness of the "theme" and the portion of the design that includes the marks of our client(s). Further, it is the express expectation, per the license agreement, that you have secured approval from any other intellectual property owner (if any), including trademarks and/or copyrights, prior to printing or production of any product.';
      case 13:
        return 'This design is approved, pending the following change(s).';
      case 14:
        // tslint:disable-next-line:max-line-length
        return 'This design is approved. <br /><br />Please be advised that approval of this design is limited only to the appropriateness of the "theme" and the portion of the design that includes the marks of our client(s). Further, it is the express expectation, per the license agreement, that you have secured approval from any other intellectual property owner (if any), including trademarks and/or copyrights, prior to printing or production of any product.';
      case 15:
        return 'This design is approved.';
      default:
        return '';
    }
  }

  static availablePhases(userType: string, currentPhase: DesignPhase) {
    const available =  [
      {
        id: '14',
        text: 'Approved By Admin',
        buttonText: 'Approve',
        userType: 'admin',
      },
      {
        id: '12',
        text: 'Approved By Admin (With Changes)',
        buttonText: 'Approve With Changes',
        userType: 'admin',
      },
      {
        id: '15',
        text: 'Approve',
        buttonText: 'Approve',
        userType: 'client',
      },
      {
        id: '13',
        text: 'Approve (With Changes)',
        buttonText: 'Approve With Changes',
        userType: 'client',
      },
      {
        id: '8',
        text: 'Rejected By Admin',
        buttonText: 'Reject',
        userType: 'admin',
      },
      {
        id: '7',
        text: 'Reject',
        buttonText: 'Reject',
        userType: 'client',
      },
      {
        id: '2',
        text: 'Licensor Review',
        buttonText: 'Send To Licensor',
        userType: 'admin',

      },
      {
        id: '3',
        text: 'Admin Review',
        buttonText: 'Send To Admin',
        userType: 'client',
      },
      {
        id: '1',
        text: 'On Hold',
        buttonText: 'Hold',
        userType: 'admin',
      },
    ];
    const phasesToShow: number[] = [];
    switch (currentPhase.id) {
      case 2:
        phasesToShow.push(7, 13, 15);
        break;
      case 3:
        phasesToShow.push(1, 2, 8, 9, 12, 14);
        break;
      case 7:
        phasesToShow.push(13, 15);
        break;
      case 13:
        phasesToShow.push(7);
        break;
      case 15:
        phasesToShow.push(7);
        break;
      default:
        break;
    }
    return available
      .filter((a) => {
        if (userType === 'all') {
          return true;
        }
        return a.userType === userType && phasesToShow.includes(Number(a.id));
      })
      .map((a) => {
        const phase = new DesignPhase();
        phase.id = Number(a.id);
        phase.name = a.buttonText;
        phase.shortName = a.text;
        return phase;
      });
  }
}
