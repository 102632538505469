import {
  faCaretDown,
  faCaretRight,
  faExclamationTriangle,
  faSpinner,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikValues } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as moment from 'moment';
import * as queryString from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { AffinityClient, AffinityImage, Trademark, TrademarkApiResponse, Vendor } from '../../../shared';
import User from '../../../shared/User';
import { WebBotSeller } from '../../../shared/WebBotSeller';
import { WebBotUrl, WebBotUrlApiResponse } from '../../../shared/WebBotUrl';
import { FullContent } from '../../ContentFrame';
import {
  FilterBar,
  GatewayModal,
  LoadingSpinner,
  ModalType,
  PaginationFooter,
  VendorTypeahead,
} from '../../shared';
import { LicensorEditRow } from '../../shared/LicensorEditRow';
import { ScanFilters } from './ScanFilters';

interface Paginator {
  total: number;
  page: number;
  lastPage: boolean;
  totalPages: number;
}

export const SellerDetailPage = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const [urls, setUrls] = React.useState<WebBotUrl[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [caseSaving, setCaseSaving] = React.useState(false);
  const [licensorLoading, setLicensorLoading] = React.useState(true);
  const [seller, setSeller] = React.useState<WebBotSeller>(new WebBotSeller());
  const [expanded, setExpanded] = React.useState(false);
  const [showAuthorizeModal, setShowAuthorizeModal] = React.useState(false);
  const [showPhotoModal, setShowPhotoModal] = React.useState(false);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [photoUrl, setPhotoUrl] = React.useState('');
  const [selectedUrl, setSelectedUrl] = React.useState<WebBotUrl | null>(null);
  const [editModalLicensors, setEditModalLicensors] = React.useState([]);
  const [webBotUrlId, setWebBotUrlId] = React.useState(0);
  const [showNewLicensorRow, setShowNewLicensorRow] = React.useState(false);
  const [showAccountLinkModal, setShowAccountLinkModal] = React.useState(false);
  const [showAccountCreateModal, setShowAccountCreateModal] = React.useState(false);
  const [showIgnoreModal, setShowIgnoreModal] = React.useState(false);
  const [showAdminSettingsModal, setShowAdminSettingsModal] = React.useState(false);
  const [dropdownStatus, setDropdownStatus] = React.useState('5');
  const [selectedLicensor, setSelectedLicensor] = React.useState<AffinityClient[]>([]);
  const [selectedVendor, setSelectedVendor] = React.useState<Vendor[]>([]);
  const [selectedTrademark, setSelectedTrademark] = React.useState<Trademark[]>([]);
  const [licensorAlerts, setLicensorAlerts] = React.useState(0);
  const [createErrorMessage, setCreateErrorMessage] = React.useState(null);
  const [statuses, setStatuses] = React.useState([]);
  const [allLicensors, setAllLicensors] = React.useState([]);
  const [adminUsers, setAdminUsers] = React.useState<User[]>([]);
  const [pagination, setPagination] = React.useState<Paginator>({
    total: 0,
    page: 0,
    lastPage: false,
    totalPages: 0,
  });
  const [filters, setFilters] = React.useState(new ScanFilters(location.search));
  const [statusFilter, setStatusFilter] = React.useState(query.status);
  const routeMatch = useRouteMatch();
  const routeParams = useParams();
  const history = useHistory();
  const [firstLoad, setFirstLoad] = React.useState(true);

  React.useEffect(() => {
    if (firstLoad) {
      getAllStatuses();
      getAllLicensors();
      getAdminUsers();
      setFirstLoad(false);
    }
    getSellerDetail();
    getWebBotUrls();
  },              [location.search]);

  const setPage = (newPage: number) => {
    const temp = pagination;
    temp.page = newPage;
    setPagination(temp);
    let currentParams = queryString.parse(location.search);
    currentParams = { ...currentParams, page: newPage };
    const cleaned = omitBy(currentParams, isNil);

    const baseUrl = location.pathname;
    history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
  };

  const getAdminUsers = async () => {
    const u = await Axios.get('/api/users?account_id=1');
    const users = u.data.data.map((u: any) => User.fromApi(u));
    setAdminUsers(users);
  };

  const applyFilters = (f: any, reload: boolean) => {
    if (f.startDate) {
      filters.startDate = f.startDate;
    }
    if (f.endDate) {
      filters.endDate = f.endDate;
    }
    if (f.search !== undefined) {
      filters.search = f.search;
    }
    if (f.market !== undefined) {
      filters.market = f.market;
    }
    if (f.user !== undefined) {
      filters.userId = f.user;
    }
    if (f.page) {
      filters.page = f.page;
    }
    if (f.sort !== undefined) {
      filters.sortBy = f.sort;
    }
    if (f.status !== undefined) {
      filters.status = f.status;
    }
    if (f.authorized !== undefined) {
      filters.authorized = f.authorized;
    }
    if (f.startDate !== undefined) {
      filters.startDate = f.startDate;
    }
    if (f.endDate !== undefined) {
      filters.endDate = f.endDate;
    }
    // if (f.platform !== undefined) {
    //   filters.platform = f.platform;
    // }
    if (f.search !== undefined) {
      filters.search = f.search;
    }
    if (f.licensorId !== undefined) {
      filters.licensorId = f.licensorId;
    }

    if (reload) {
      if (!f.page) {
        filters.page = 1;
      }
      const baseUrl = location.pathname;
      setLoading(true);
      history.replace(`${baseUrl}?${filters.generateQueryParamString()}`);
    }

    const fi = new ScanFilters();
    fi.setFilters(filters.filters);
    setFilters(fi);

  };

  const rescan = async () => {
    setInProgress(true);
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }
    await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/rescan`);
    history.push('/scans');
  };

  const updateStatus = async (urlId: string, statusId: string) => {
    setSaving(true);
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }
    const params = {
      urlId,
      statusId,
    };
    await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/status/`, params);
    setSaving(false);
  };

  const ignoreSeller = async () => {
    setSaving(true);
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }
    await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/ignore-all/`);
    setLoading(true);
    await getSellerDetail();
    await getWebBotUrls();
    setShowIgnoreModal(false);
    setSaving(false);
  };

  const authorizeAll = async () => {
    setSaving(true);
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }
    await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/authorize-all`);
    await getSellerDetail();
    await getWebBotUrls();
    setShowAuthorizeModal(false);
    setSaving(false);
  };

  async function linkAccount() {
    setSaving(true);
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }
    const params = {
      vendor_account_id: selectedVendor[0].id,
    };
    const response = await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/assign-vendor`, params);
    const s = WebBotSeller.fromApi(response.data.data);
    setSeller(s);
    setLicensorAlerts(s.licensorAlerts);
    setSaving(false);
    setShowAccountLinkModal(false);
  }

  function openPhotoModal(url: WebBotUrl) {
    setSelectedUrl(url);
    setShowPhotoModal(true);
  }

  function addNewLicensorRow() {
    setShowNewLicensorRow(true);
  }

  function openEditModal(licensors: [], urlId: number) {
    setShowNewLicensorRow(false);
    setEditModalLicensors(licensors);
    setWebBotUrlId(urlId);
    setShowEditModal(true);
  }

  function getTrademarkCell(licensors:any[]) {
    let total = 0;
    let name = '';
    let nullTrademark = false;
    for (const l of licensors) {
      if (l.trademarks && l.trademarks.length > 0) {
        for (const t of l.trademarks) {
          name = t.title;
          total += 1;
        }
      } else {
        nullTrademark = true;
      }

    }
    let innerText = '';
    if (total === 0) {
      innerText = 'No trademarks';
    }
    if (total === 1) {
      innerText = name;
    }
    if (total > 1) {
      innerText = `${total} trademarks`;
    }
    if (nullTrademark) {
      return <span className="text-danger">{innerText}</span>;
    }
    return innerText;
  }

  const deleteLicensorTrademark = async (licensorId:string) => {
    if (!licensorId) {
      setShowNewLicensorRow(false);
      return;
    }
    const sellerId = getSellerId();
    const params = {
      licensorId,
      webBotUrlId,
      sellerId,
    };

    const result = await Axios.delete('/api/enforcement-bots/urls', { params });
    if (result.data === 'success') {
      const tmp = editModalLicensors.filter((l:any) => l.id !== licensorId);
      setEditModalLicensors(tmp);
      await getWebBotUrls();
    }
    return result.data;
  };

  function getSellerId() {
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }
    return sellerId;
  }

  function filterStatus(e: any) {
    setDropdownStatus(e.target.value);
  }

  function closeEditModal() {
    setShowEditModal(false);
    setSelectedLicensor([]);
    setSelectedTrademark([]);
  }

  function updateSelectedLicensor(l:any[]) {
    setSelectedLicensor(l[0]);
    setShowNewLicensorRow(false);
    if (l && l.length > 0) {
      const tmp = editModalLicensors;
      l[0].trademarks = [];
      tmp.push(l[0]);
      setEditModalLicensors(tmp);
      setSelectedLicensor([]);
      updateLicensorTrademark(l[0].id, []);
    }

  }

  const updateLicensorTrademark = async (licensorId: string, trademarks:Trademark[]) => {
    setSaving(true);

    const sellerId = getSellerId();
    const trademarkId = trademarks && trademarks.length === 1 ? trademarks[0].id : null;
    const params = {
      licensorId,
      sellerId,
      trademarkId,
      webBotUrlId,
    };

    const result = await Axios.post('/api/enforcement-bots/urls', params);
    let t = [];
    if (result.data.data) {
      t = result.data.data.map((t:TrademarkApiResponse) => Trademark.fromApi(t));
    }
    setSaving(false);
    await getWebBotUrls();
    return t;
  };

  const createCase = async () => {
    setCaseSaving(true);

    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }

    await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/create-case`)
      .then((e) => {
        const s = WebBotSeller.fromApi(e.data.data);
        setSeller(s);
        setLicensorAlerts(s.licensorAlerts);
        setCaseSaving(false);
      });
  };

  const createAccount = async () => {
    setSaving(true);
    setCreateErrorMessage(null);

    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }

    const params = {
      status: dropdownStatus,
    };

    await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/create-vendor`, params)
      .then((e) => {
        const r = WebBotSeller.fromApi(e.data.data);
        setSeller(r);
        setLicensorAlerts(r.licensorAlerts);

        setSaving(false);
        setShowAccountCreateModal(false);
      })
      .catch((e) => {
        if (e.response.status === 422) {
          if (e.response.data.errors && e.response.data.errors.short_name)  {
            setCreateErrorMessage(e.response.data.errors.short_name[0]);
          }
        }
        setSaving(false);
      });

  };

  const updateDateToReverify = async (id: any, unverify= false) => {
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }

    const params = {
      unverify,
      licensor_id: id,
    };
    const response = await Axios.post(`/api/enforcement-bots/sellers/${sellerId}/update-client`, params);
    setLicensorAlerts(response.data.licensorAlerts);
    await getWebBotUrls();
    return response;
  };

  const saveAdminSettings = async (values: FormikValues, formProps: FormikActions<FormikValues>) => {
    setSaving(true);
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }

    const params = {
      adminUser: values.adminUser,
      closedDate: values.closedDate,
      ignore: values.ignoreSeller,
      sellerStatus: values.sellerStatus,
    };
    await Axios.post(`/api/enforcement-bots/sellers/${sellerId}`, params);
    await getSellerDetail();
    setSaving(false);
    setShowAdminSettingsModal(false);
  };

  const getSellerDetail = async () => {
    const sellerId = getSellerId();
    const r = await Axios.get(`/api/enforcement-bots/sellers/${sellerId}`);
    const s = WebBotSeller.fromApi(r.data.data);
    setSeller(s);
    if (r.data.data.scan_request == null || (r.data.data.scan_request && r.data.data.scan_request.start_time && r.data.data.scan_request.end_time)) {
      setInProgress(false);
    } else {
      setInProgress(true);
    }
    setLicensorAlerts(s.licensorAlerts);
    setLicensorLoading(false);
  };

  const getAllLicensors = async () => {
    const sellerId = getSellerId();
    const r = await Axios.get(`/api/enforcement-bots/sellers/${sellerId}/licensors`);
    if (r.data) {
      setAllLicensors(r.data);
    }
  };

  const getAllStatuses = async () => {
    const r = await Axios.get('/api/enforcement-bots/statuses');
    if (r.data) {
      setStatuses(r.data);
    }
  };

  const getWebBotUrls = async () => {
    let sellerId = null;
    if (routeMatch !== null && routeMatch.params['id']) {
      sellerId = routeMatch.params['id'];
    }
    const query = queryString.parse(location.search);
    const params = {
      sellerId,
      page: query.page,
      search: query.search,
      status: query.status,
      authorized: query.authorized,
      licensorId: query.licensorId,
    };
    const cleaned = omitBy(params, isNil);
    const s = await Axios.get('/api/enforcement-bots/urls', { params: cleaned });
    setUrls(s.data.data.map((url:WebBotUrlApiResponse) => WebBotUrl.fromApi(url)));
    const paginationResult = s.data.meta.pagination;
    setPagination({
      total: paginationResult.total,
      page: paginationResult.current_page,
      lastPage: paginationResult.total_pages === paginationResult.current_page,
      totalPages: paginationResult.total_pages,
    });
    setLoading(false);
  };

  const statusColors = {
    1: 'black', // 'Potential'
    2: '#28a745', // 'Current'
    3: 'black', // 'Cancelled'
    4: 'black', // 'Registered'
    5: '#e06026', // 'Illegal'
    6: 'black', // 'Out of Business'
    7: 'black', // 'Non-Producing'
    8: 'black', // 'One-Time'
    10: 'black', // 'Declined'
    11: 'black', // 'Non-Issue'
    12: 'black', // 'Retailer'
  };

  const backupImage = new AffinityImage();
  backupImage.defaultImage = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/vendor-icon.png';
  const icon = !expanded ? <span style={{ marginLeft: 5 }}><FontAwesomeIcon icon={faCaretRight} /></span> : <span style={{ marginLeft: 5 }}><FontAwesomeIcon icon={faCaretDown} /></span>;
  const authorizeText = saving ? 'Authorizing...' : 'Authorize All';
  const accountLinkText = saving ? 'Linking...' : 'Link Account';
  const accountCreateText = saving ? 'Creating...' : 'Create Account';
  const createCaseText = caseSaving ? 'Creating...' : 'Create Case';
  const addToCaseText = caseSaving ? 'Adding...' : 'Add to Case';
  const ignoreText = saving ? 'Submitting...' : 'Ignore and Reset';
  const adminSettingsText = saving ? 'Saving...' : 'Save Changes';
  const top = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span style={{ cursor: 'pointer' }} onClick={() => setExpanded(!expanded)}>
          <span style={{ fontWeight: 'bold', fontSize: 22 }}>{seller.licensors ? seller.licensors.length : 0} licensors</span>
        {icon}
        <br/>
          <span className={licensorAlerts !== 0 ? 'text-danger' : 'text-success'}>{licensorAlerts !== 0 ? `${licensorAlerts} alerts` : 'No Licensor Alerts'}</span>
      </span>
      { licensorAlerts > 0 ? <button className="btn btn-default" onClick={e => setShowAuthorizeModal(true)}>Authorize for All</button> : null}
    </div>
  );

  const initialValues = {
    adminUser: seller.owner ? seller.owner.id : null,
    sellerStatus: seller.closedAt && seller.closedAt.format('YYYY-MM-DD') ? 'closed' : 'open',
    closedDate: seller.closedAt ? seller.closedAt.format('YYYY-MM-DD') : undefined,
    ignoreSeller: seller.ignoreSeller,
  };

  // const validation = Yup.object({
  //   adminUser: Yup.string().min(1).required('A platform name is required'),
  //   sellerStatus: Yup.string().min(1).required('A scan type is required'),
  //   closedDate: Yup.string().when('scanType', { is: 'keyword', then: Yup.string().min(1).required('A keyword is required') }),
  //   ignoreSeller: Yup.boolean().required('Please select an option'),
  // });

  const html =
      <FullContent>

        <div style={{ marginTop: 15 }} className="panel panel-portal">
            { !licensorLoading ?
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="panel-body">
                <div className="col-lg-6" style={{ borderRight: '1px  solid #ccc' }}>
                  {seller.platform ? seller.platform.name : '' } Seller   { inProgress ? <span><FontAwesomeIcon icon={faSpinner} spin /></span> : null }
                  <h5 className="text-muted"><strong>Status</strong> <div className="label label-primary">{seller.closedAt ? 'CLOSED' : 'OPEN'}</div></h5>
                  {seller.createdBy ?
                    <p className="small">Created by {seller.createdBy.fullName} on {seller.createdAt.format('MMM DD, YYYY')} ({seller.createdAt.fromNow()})</p>
                    : null}
                  <div style={{ display: 'flex', alignItems: 'center' }} >
                    {seller.image ? <img style={{ width: 50, height: 50 }} src={seller.image.getSize('sm')} /> : null}
                    <span style={{ marginLeft: 10 }}>
                        {seller.platformUsername} <br/>
                        <a href={seller.sellerUrl} target="_blank">{seller.sellerUrl}</a>
                      </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  Account
                  <div style={{ display: 'flex', alignItems: 'center' }} >
                    <img style={{ width: 50, height: 50 }} src={ seller.linkedAccount ? seller.linkedAccount.logo.getSize('sm') : backupImage.getSize('sm')} />
                    <span style={{ marginLeft: 10 }}>
                        { seller.linkedAccount ? <Link to={`/vendors/${seller.linkedAccount.id}`} target="_blank">{seller.linkedAccount.accountName}</Link> : 'No Linked Account' }<br/>
                        { seller.linkedAccount ? <span style={{ color: statusColors[seller.linkedAccount.status.id] }}>{seller.linkedAccount.status.display}</span> : 'No Status' }
                      </span>
                  </div>
                  { !seller.linkedAccount ?
                    <div className="pull-right">
                      <button className="btn btn-primary" style={{ marginRight: 10 }} onClick={e => setShowAccountLinkModal(true)}>Link With Account</button>
                      <button className="btn btn-primary" onClick={e => setShowAccountCreateModal(true)}>Create Account</button>
                    </div>
                    :
                    <div className="pull-right">
                        <button style={{ marginLeft: 10 }} className="btn btn-primary" onClick={e => createCase()}>{ seller.caseId ? addToCaseText : createCaseText}</button>
                      { seller.caseId ? <a style={{ marginLeft: 10 }} href={`vendors/${seller.linkedAccount.id}/enforce/cases/${seller.caseId}`} target="_blank" className="btn btn-primary">View Case</a> : null}
                    </div>
                  }
                  <DropdownButton className="pull-right" bsStyle="default" id="case-action" title="Options" style={{ marginLeft: 10 }}>
                    <MenuItem onClick={rescan}>Rescan Seller</MenuItem>
                    <MenuItem onClick={() => setShowIgnoreModal(true)}>Ignore and Reset Seller</MenuItem>
                    <MenuItem onClick={() => setShowAdminSettingsModal(true)}>Admin Settings</MenuItem>
                  </DropdownButton>
                </div>
              </div>
          : <LoadingSpinner />}
        </div>

    <div style={{ marginTop: 15 }} className="panel panel-portal">
      <div className="panel-body">

        {licensorLoading ?     <LoadingSpinner/> :
        top
      }

          { expanded && !licensorLoading ?
            <>
              <div className="table-responsive">
                <table className="table table-portal table-hover">
                  <thead>
                  <tr>
                    <th>Licensor</th>
                    <th>Total Products</th>
                    <th>Confirmed Authorized At</th>
                    <th>Date to Reverify</th>
                  </tr>
                  </thead>
                  <tbody>
                  {seller.licensors.map(l => (
                    <SellerDetailRow licensor={l} onReverify={updateDateToReverify} />

                    ))}
                  </tbody>
                </table>
              </div>
            </>
            : null
          }
      </div>
    </div>
        <FilterBar
          useSearch={true}
          search={filters.search}
          usePerPage={false}
          useQuarter={false}
          useDates={false}
          // useLicensor={true}
          // licensors={false}
          updateFilters={applyFilters}
          useAdvancedSearch={false}
        >
          <div className="col-lg-3 col-sm-3 col-xs-12">
            <select
              style={{ lineHeight: 1.5 }}
              className="form-control input-sm"
              value={filters.status}
              onChange={e => applyFilters({ status: e.target.value }, true)}
            >
              <option value="">All Statuses</option>
              {statuses.map((s:any) => {
                return <option value={s.id} key={`s${s.id}`}>{s.name}</option>;
              })}
            </select>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-12">
            <select
              style={{ lineHeight: 1.5 }}
              className="form-control input-sm"
              value={filters.licensorId}
              onChange={e => applyFilters({ licensorId: e.target.value }, true)}
            >
              <option value="">All Licensors</option>
              {allLicensors.map((l:any) => {
                return <option value={l.id} key={`l${l.id}`}>{l.short_name}</option>;
              })}
            </select>
          </div>
          <div className="col-lg-3 col-sm-3 col-xs-12">
            <select
              style={{ lineHeight: 1.5 }}
              className="form-control input-sm"
              value={filters.authorized}
              onChange={e => applyFilters({ authorized: e.target.value }, true)}
            >
              <option value="">All Products</option>
              <option value="2" key="p2">Authorized for All</option>
              <option value="1" key="p1">Possibly Licensed</option>
              <option value="3" key="p3">Unknown</option>
            </select>
          </div>
        </FilterBar>
    {loading ? <LoadingSpinner /> : <div style={{ marginTop: 15 }} className="panel panel-portal">
      <div className="table-responsive">
        <table className="table table-portal table-hover">
          <thead>
            <tr>
              <th style={{ width: 150 }}>Image</th>
              <th style={{ width: 400 }}>Name</th>
              <th>Licensor(s)</th>
              <th>Trademark(s)</th>
              <th>Status</th>
              <th>Authorization</th>
              <th>Price</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
          {urls.map(s => (
            <tr key={s.id}>
              <td style={{ width: 150 }} onClick={e => openPhotoModal(s)}>
                  <img className="img-responsive" src={s.imageUrl} style={{ width: 'auto', height: 150 }}/>
              </td>
              <td style={{ width: 400 }}>
                <Link to={{ pathname: s.fullUrl }} target="_blank">
                  {s.name}
                </Link>

              </td>
              <td onClick={e => openEditModal(s.licensors, s.id)} style={{ cursor: 'pointer' }}>
                { s.licensors && s.licensors.length > 0 ?
                  (s.licensors.length === 1 ?
                    s.licensors[0].shortName
                    : `${s.licensors.length} licensors`)
                  : 'No licensors' }
              </td>
              <td onClick={e => openEditModal(s.licensors, s.id)} style={{ cursor: 'pointer' }}>
                {getTrademarkCell(s.licensors)}
              </td>
              <td>
                <select
                  style={{ lineHeight: 1.5 }}
                  className="form-control input-sm"
                  defaultValue={s.status.id}
                  onChange={e => updateStatus(String(s.id), e.target.value)}
                >
                  <option value="1">Not Set</option>
                  <option value="2">Removed</option>
                  <option value="3">Ignored</option>
                  <option value="4" >Non-Infringing</option>
                  <option value="5">Licensed</option>
                </select>
              </td>
              <td>
                {s.possibleLicensed === null ? 'Authorized' : (s.possibleLicensed ? <><FontAwesomeIcon icon={faExclamationTriangle} className="text-success" />  Possibly Licensed</> : 'Unknown') }
              </td>
              <td>
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(s.price))}
              </td>
              <td>
                {s.createdAt.format('MMM DD, YYYY')}
              </td>
              <td>
                {s.updatedAt.format('MMM DD, YYYY')}
              </td>
            </tr>
          ))}

          </tbody>
        </table>
      </div>

    </div>}

        {loading ? null : <PaginationFooter
          totalResults={pagination.total}
          hasNext={!pagination.lastPage}
          currentPage={pagination.page}
          setPage={setPage}
          totalPages={pagination.totalPages}
          showFirstLast={true}
        />}
        <GatewayModal
          type={ModalType.Primary}
          shown={showAccountCreateModal}
          onClose={() => setShowAccountCreateModal(false)}
          title={'Create Account'}
        >
          <Modal.Body>
            <p>
              Are you sure you want to create a new account?
            </p>
            <label>Account Status</label>
            <select name="status" className="form-control" value={dropdownStatus} onChange={filterStatus}>
              <option value="1">Potential</option>
              <option value="2">Current</option>
              <option value="3">Cancelled</option>
              <option value="4">Registered</option>
              <option value="5">Illegal</option>
              <option value="6">Out of Business</option>
              <option value="7">Non-Producing</option>
              <option value="8">One Time</option>
              <option value="11">Non-Issue</option>
              <option value="12">Retailer</option>
            </select>
            <p className="text-danger">
              {createErrorMessage}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setShowAccountCreateModal(false)} className="btn btn-default">
              Close
            </button>
            <button onClick={createAccount} className="btn btn-primary pull-right">{accountCreateText}</button>
          </Modal.Footer>
        </GatewayModal>
        <GatewayModal
          type={ModalType.Primary}
          shown={showAccountLinkModal}
          onClose={() => setShowAccountLinkModal(false)}
          title={'Link Account'}
        >
          <Modal.Body>
            <p>
              Search all vendor accounts and select an account to link with this seller.
            </p>
            <VendorTypeahead selected={selectedVendor} onChange={setSelectedVendor}   multiple={false}/>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setShowAccountLinkModal(false)} className="btn btn-default">
              Close
            </button>
            <button onClick={linkAccount} className="btn btn-primary pull-right">{accountLinkText}</button>
          </Modal.Footer>
        </GatewayModal>
        <GatewayModal
          type={ModalType.Primary}
          shown={showAuthorizeModal}
          onClose={() => setShowAuthorizeModal(false)}
          title={'Authorize For All'}
        >
          <Modal.Body>
            <p>
              Are you sure you want to authorize all users for 6 months?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setShowAuthorizeModal(false)} className="btn btn-default">
              Close
            </button>
            <button onClick={authorizeAll} className="btn btn-primary pull-right">{authorizeText}</button>
          </Modal.Footer>
        </GatewayModal>
        <GatewayModal
          type={ModalType.Primary}
          shown={showIgnoreModal}
          onClose={() => setShowIgnoreModal(false)}
          title={'Ignore and Reset'}
        >
          <Modal.Body>
            <p>
              Are you sure you want to ignore and reset this seller? This will remove all tagged licensors and ignore all products. The seller will be excluded from future scans.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setShowIgnoreModal(false)} className="btn btn-default">
              Cancel
            </button>
            <button onClick={ignoreSeller} className="btn btn-primary pull-right">{ignoreText}</button>
          </Modal.Footer>
        </GatewayModal>
        <GatewayModal
          type={ModalType.Primary}
          shown={showAdminSettingsModal}
          onClose={() => setShowAdminSettingsModal(false)}
          title={'Admin Settings'}
        >

            <Formik
              onSubmit={ (values, formProps) => saveAdminSettings(values, formProps)}
              // validationSchema={validation}
              validateOnBlur={false}
              initialValues={initialValues}>
              {formProps =>
                (
                <Form id="admin-settings-form" >
                  <Modal.Body>
                  <div>
                    <div className="form-group">
                      <label htmlFor="adminUser">Admin User</label>
                      <Field component="select"
                             name="adminUser"
                             className="form-control"
                      >
                        <option value="">All Users</option>
                        {adminUsers.map(u => <option key={u.id} value={u.id}>{u.fullName}</option>)}
                      </Field>
                      <p className="text-danger">
                        <ErrorMessage name="adminUser" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label htmlFor="sellerStatus">Status</label>
                      <Field component="select"
                             name="sellerStatus"
                             className="form-control"
                      >
                        <option value="open">Open</option>
                        <option value="closed">Closed</option>
                      </Field>
                    </div>
                    { formProps.values.sellerStatus === 'closed' ?
                      <div className="form-group">
                        <label>Closed Date</label>
                        <Field
                          onChange={(e:any) => formProps.setFieldValue('closedDate', e.target.value)}
                          defaultValue={formProps.values.closedDate ? formProps.values.closedDate : undefined}
                          name="closedDate"
                          id="closedDate"
                          type="date"
                          className="form-control"
                        />
                      </div>
                      : null
                    }
                    <div className="form-group">
                      <strong>Ignore Seller</strong>
                      <div className="checkbox">
                        <label htmlFor="ignoreSeller"> <Field type="checkbox" name="ignoreSeller" id="ignoreSeller" defaultChecked={formProps.values.ignoreSeller} />  Ignore Seller</label>
                      </div>
                    </div>
                  </div>
                  </Modal.Body>
                </Form>
                )}
                </Formik>

                  <Modal.Footer>
                    <button onClick={() => setShowAdminSettingsModal(false)} className="btn btn-default">
                      Close
                    </button>
                    <button type="submit" form="admin-settings-form" className="btn btn-primary pull-right">{adminSettingsText}</button>
                  </Modal.Footer>

        </GatewayModal>
        <GatewayModal
          type={ModalType.Primary}
          shown={showPhotoModal}
          onClose={() => setShowPhotoModal(false)}
          title={'Photo'}
          size={'large'}
        >
          {selectedUrl ? (
            <Modal.Body>
              <p>
                <strong>Name: </strong> { selectedUrl.name }
                <br />
                <strong>Description: </strong> { selectedUrl.description }
                <br />

              </p>
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} >
                {selectedUrl.tags.map(t => (
                  <span style={{ marginTop: 5, marginBottom: 5, margin: 5 }} className="label label-primary">{t}</span>
                ))}

              </div>
              <img className="img-responsive" src={selectedUrl.imageUrl} />
            </Modal.Body>
          ) : null}

          <Modal.Footer>
            <button onClick={() => setShowPhotoModal(false)} className="btn btn-default">
              Close
            </button>
          </Modal.Footer>
        </GatewayModal>
        <GatewayModal
          type={ModalType.Primary}
          shown={showEditModal}
          onClose={() => closeEditModal()}
          title={'Edit'}
          size={'large'}
        >
          <Modal.Body>
            <div className="row">
              <div className="col-lg-5" style={{ marginBottom: 10 }}>
                <strong>Licensor</strong>
              </div>
              <div className="col-lg-7" style={{ marginBottom: 10 }}>
                <strong>Trademarks</strong>
              </div>
            </div>
          {
            editModalLicensors && editModalLicensors.length > 0 ?
              editModalLicensors.map(l => (
                <LicensorEditRow licensor={l} updateLicensor={updateSelectedLicensor} updateTrademark={updateLicensorTrademark} deleteTrademark={deleteLicensorTrademark}/>
              ))
              : null
          }
            { showNewLicensorRow ?
              <LicensorEditRow licensor={null} updateLicensor={updateSelectedLicensor} updateTrademark={updateLicensorTrademark} deleteTrademark={deleteLicensorTrademark}/>
              : null}
          <div>
            <button onClick={() => addNewLicensorRow()} className="btn btn-primary">
              Add Licensor
            </button>
          </div>

          </Modal.Body>
          <Modal.Footer>
            <button onClick={() => setShowEditModal(false)} className="btn btn-default">
              Close
            </button>
          </Modal.Footer>
        </GatewayModal>
  </FullContent>;

  return html;
};

interface SellerDetailRowProps {
  licensor: any;
  onReverify: (id:string, unverify?: boolean) => any;
}

const SellerDetailRow = (props: SellerDetailRowProps) => {
  const [isHover, setIsHover] = React.useState(false);
  const [rowLoading, setRowLoading] = React.useState(false);
  const [confirmedAuthorizedAt, setConfirmedAuthorizedAt] = React.useState(props.licensor.confirmedAuthorizedAt);
  const [dateToReverify, setDateToReverify] = React.useState(props.licensor.dateToReverify);

  React.useEffect(() => {
  },              [rowLoading, dateToReverify, confirmedAuthorizedAt]);

  const handleMouseOn = async () => {
    setIsHover(true);
  };

  const handleMouseOff = async () => {
    setIsHover(false);
  };

  const updateReverify = async (id:string, unverify= false) => {
    setRowLoading(true);
    const result = await props.onReverify(id, unverify);
    const dtr = result.data.dateToReverify ? moment(result.data.dateToReverify.date) : null;
    const cat = result.data.confirmedAuthorizedAt ? moment(result.data.confirmedAuthorizedAt.date) : null;
    setDateToReverify(dtr);
    setConfirmedAuthorizedAt(cat);
    setRowLoading(false);
  };

  return (
    <tr key={props.licensor.id}>
      <td>
        <span className={ (props.licensor.dateToReverify == null || props.licensor.dateToReverify.toDate() < moment.now()) ? 'text-danger' : ''}>{props.licensor.shortName}</span>
      </td>
      <td>
        {props.licensor.totalProducts}
      </td>
      <td>
        {
          confirmedAuthorizedAt ?
            (
              <>
                { rowLoading ? <><FontAwesomeIcon icon={faSpinner} spin /></> : confirmedAuthorizedAt.format('MMM DD, YYYY')}
                <div onMouseEnter={handleMouseOn} onMouseLeave={handleMouseOff} style={{ cursor: 'pointer', marginLeft: 5, width: 20, height: 20, display: 'inline-block' }} className="text-danger" onClick={() => updateReverify(props.licensor.id, true)}>
                  {isHover ? <FontAwesomeIcon icon={faTimes} /> : '' }
                </div>
              </>
            )
            : <span style={{ cursor: 'pointer' }} className="btn-link" onClick={e => updateReverify(props.licensor.id)}>{ rowLoading ? <><FontAwesomeIcon icon={faSpinner} spin /></> : 'Authorize Now'}</span>
        }
      </td>
      <td>
        { dateToReverify ?
          dateToReverify.format('MMM DD, YYYY')
          : '-'}

      </td>
    </tr>
  );
};
