import { AffinityImage, ImageApiResponse } from './Image';

export interface AccountAliasAPIResponse {
  id: number;
  logo: ImageApiResponse;
  name: string;
  phone: string;
  url: string;
  header_image: ImageApiResponse;
}
export class AccountAlias {
  id: number;
  logo: AffinityImage;
  name: string;
  phone: string;
  url: string;
  banner: AffinityImage;

  static fromApi(data: AccountAliasAPIResponse) {
    const alias = new  AccountAlias();
    alias.id = data.id;
    if (data.logo) {
      alias.logo = AffinityImage.fromApi(data.logo);
    } else {
      alias.logo = new AffinityImage();
    }
    alias.name = data.name;
    alias.phone = data.phone;
    alias.url = data.url;
    if (data.header_image) {
      alias.banner = AffinityImage.fromApi(data.header_image);
    } else {
      alias.banner = new AffinityImage();
    }
    return alias;
  }
}
