import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { CurrencyLabel } from '../../shared';
import { ShareStats } from './models/ShareStats';
import { NoSalesDataPlaceholder } from './NoSalesDataPlaceholder';
import { PartialRepresentation } from './PartialRepresentation';

interface IProps {
  stats: ShareStats;
  isPartial: boolean;
}

export class ShareOfRoyalties extends React.Component<IProps, any> {

  render() {

    const stats = this.props.stats;

    const pieData = {
      labels: [
        'Share',
        'Other',
      ],
      datasets: [{
        data: [stats.share, (stats.total - stats.share)],
        backgroundColor: ['#4f6980', '#e6e6e6'],
      }],
    };
    const pieOptions: ChartOptions = {

      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        callbacks: {
          label: (tooltip: any, data: any) => {
            if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
              const totalData = data.datasets[tooltip.datasetIndex].data
                .reduce((total: number, d: number) => total += d, 0);
              const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              const percent = ((value / totalData) * 100).toFixed(2);
              return `${percent}%`;
            }
            return '';
          },
        },

      },
      plugins: {
        colorschemes: {
          scheme: 'tableau.MillerStone11',
        },
      },

    };
    const hasData = stats.total > 0 || stats.share > 0;
    const partialContent = this.props.isPartial && hasData ? (<PartialRepresentation />) : null;
    const percent = ((stats.share / stats.total) * 100).toFixed(2);

    const content = hasData ?
      (
        <div>
          <div>
            <Pie height={210} options={pieOptions} data={pieData} />
          </div>
          <h3 style={{ margin: 0, marginTop: 15 }} className="text-primary text-center">
            <CurrencyLabel value={stats.share} />
          </h3>
          { stats.units > 0 ? <p style={{ margin: 0 }} className="text-muted text-center small">

              <NumberFormat  displayType={'text'} thousandSeparator={true} value={stats.units} /> units
            </p>
          : null }
          { stats.rpu > 0 ? <p style={{ margin: 0, marginBottom: 10 }} className="text-muted text-center">
            <CurrencyLabel value={stats.rpu} /> Avg. RPU
          </p> : null }
        </div>

      )
      :
      (
        <NoSalesDataPlaceholder />
      );
    const percentText = percent !== 'NaN' ? percent : '';

    return (
      <div className="panel panel-portal">
        <div className="panel-body" style={{ paddingBottom: 0 }}>
          <div className="analytics-header text-primary">{percentText}% of Royalties</div>
          <div>
            <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
            <span className="text-muted">This segment’s percentage of royalties</span>
          </div>
          {partialContent}
        </div>
        <div className="panel-body" style={{ paddingTop: 3 }}>
          {content}
        </div>
      </div >
    );

  }

}
