import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Note, NoteThread } from '../../shared';
import { GatewayModal, ModalType } from '../shared';
import { NoteThreadForm, ThreadFormValidation, ThreadFormValues } from './forms';

interface IProps {
  thread: NoteThread;
  shown: boolean;
  onClose: () => void;
  onSubmit: (v: ThreadFormValues) => void;
}

export const EditNoteThreadModal = (props: IProps) => {
  const formValues: ThreadFormValues = {
    categories: props.thread.categories,
    subject: props.thread.subject,
    pinned: props.thread.isPinned,
    flagged: props.thread.hasWarning,
    code: props.thread.noteCode,
  };

  return (
   <GatewayModal type={ModalType.Primary} shown={props.shown} onClose={props.onClose} title="Edit Thread">
      <Formik onSubmit={props.onSubmit} validationSchema={ThreadFormValidation} initialValues={formValues}>
        {(formProps: FormikProps<ThreadFormValues>) => (
          <Form>
            <Modal.Body>
              <NoteThreadForm {...formProps} />
            </Modal.Body>
            <Modal.Footer>
              <button onClick={props.onClose} type="button" className="btn btn-default pull-left">Cancel</button>
              <button type="submit" className="btn btn-primary pull-right">Submit</button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
   </GatewayModal>
  );

};
