import Axios from 'axios';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MarketplaceTag } from '../../../shared';
import { LoadableStatuses } from '../../../shared/RequestStatuses';

interface IProps {
  selected: MarketplaceTag[];
  onChange: (users: MarketplaceTag[]) => void;
}

type Status = LoadableStatuses<MarketplaceTag[], string>;

export const MarketplaceTagTypeahead = (props: IProps) => {
  const [status, setStatus] = React.useState<Status>({ state: 'loading' });

  React.useEffect(() => {
    getTags();

  },              []);

  const getTags = async () => {
    setStatus({ state: 'loading' });
    const t = await Axios.get('/api/marketplace/tags');
    const tags = t.data.data;
    const selected = [... props.selected];
    let shouldUpdate = false;
    selected.forEach((c, i) => {
      if (c.name === 'Loading') {
        shouldUpdate = true;
        const foundTag = tags.find((t: MarketplaceTag) => Number(t.id) === Number(c.id));
        if (foundTag) {
          selected[i].name = foundTag.name;
        }
      }
    });
    if (shouldUpdate) {
      props.onChange(selected);
    }
    setStatus({ content: tags, state: 'success' });

  };
  const options = status.state === 'success' ? status.content : [];
  const isLoading = status.state === 'loading';

  return (
    <Typeahead
      id="tag-typeahead"
      placeholder="Select Tags"
      options={options}
      selected={props.selected}
      onChange={props.onChange}
      multiple={true}
      paginate={false}
      isLoading={isLoading}
      maxResults={options.length}
      minLength={0}
      clearButton={true}
      labelKey={(item: MarketplaceTag) => item.name ? item.name : 'Broken'}

    />

  );

};
