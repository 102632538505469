import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../shared';

interface IProps {
  shown: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteNoteThreadModal = (props: IProps) => {

  return (
    <GatewayModal
      shown={props.shown}
      onClose={props.onClose}
      type={ModalType.Danger}
      title="Delete Thread"
    >
      <Modal.Body>
        <p className="text-center">
          Are you sure you want to delete this note thread?
      </p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onClose} className="btn btn-default pull-left">Cancel</button>
        <button onClick={props.onDelete} className="btn btn-danger pull-right">Yes, Delete Note Thread</button>
      </Modal.Footer>

    </GatewayModal>
  );

};
