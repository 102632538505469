import { AccountProfile, AccountProfileAPIResponse } from './AccountProfile';
import { AffinityImage, ImageApiResponse } from './Image';
import { ProductCategory, ProductCategoryApiResponse } from './ProductCategory';

export interface VendorProductApiResponse {
  id: number;
  category: ProductCategoryApiResponse;
  vendor: AccountProfileAPIResponse;
  image?: ImageApiResponse;
  phase: VendorProductPhase;
  is_reviewed: boolean;
}

interface VendorProductPhase {
  id: number;
  name: string;
}

export class VendorProduct {

  id: number;
  category: ProductCategory;
  phase: VendorProductPhase;
  image: AffinityImage;
  vendor: AccountProfile;
  isReviewed: boolean;

  static fromApi(data: VendorProductApiResponse) {
    const product = new VendorProduct();
    product.id = data.id;
    product.category = ProductCategory.fromApi(data.category);
    product.phase = data.phase;
    if (data.vendor) {
      product.vendor = AccountProfile.fromApi(data.vendor);
    }
    if (data.image) {
      product.image = AffinityImage.fromApi(data.image);
    } else {
      product.image = new AffinityImage();
    }
    product.isReviewed = data.is_reviewed;
    return product;
  }

  get categoryTree () {
    let c: ProductCategory | null = this.category;
    const tree = [];
    while (c != null) {
      tree.unshift(c);
      c = c.getParent();
    }
    return tree.map(cat => cat.name).reduce((prev, current, index) => {
      const title = `${prev} ${index > 0 ? '>' : ''} ${current}`;
      return title;
    },                                      '');
  }

}
