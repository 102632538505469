import * as moment from 'moment';
// import { File } from '../File';
import User from '../User';
import { DesignPhase, DesignPhaseApiResponse } from './DesignPhase';
export interface DesignActivityApiResponse {
  id: number;
  created_at: string;
  updated_at: string;
  message: string;
  is_activity: boolean;
  decision_made: boolean;
  group: string;
  x_coord: number;
  y_coord: number;
  user: { id: number, first_name: string, last_name: string, account_name: string };
  phase?: DesignPhaseApiResponse;
  attachment: any;
}
export class DesignActivity {
  id: number;
  created: moment.Moment;
  updated: moment.Moment;
  message: string;
  isActivity: boolean;
  decisionMade: boolean;
  group: string;
  x: number;
  y: number;
  user: User;
  phase: DesignPhase;
  attachment: any;
  static fromApi(data: DesignActivityApiResponse) {
    const activity = new DesignActivity();
    activity.id = data.id;
    activity.created = moment.utc(data.created_at).local();
    activity.updated = moment.utc(data.updated_at).local();
    activity.message = data.message;
    activity.isActivity = data.is_activity;
    activity.decisionMade = data.decision_made;
    activity.group = data.group;
    activity.x = data.x_coord ? +data.x_coord / 100 : data.x_coord;
    activity.y = data.y_coord ? -data.y_coord / 100 : data.y_coord;
    activity.user = new User(data.user);
    if (data.phase) {
      activity.phase = DesignPhase.fromApi(data.phase);
    }
    activity.attachment = data.attachment;
    return activity;
  }

  get hasCoords() {
    if (this.x && this.y) {
      return true;
    }
    return false;
  }

}
