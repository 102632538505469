import { faDownload, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { formattedFiscalYear, getDueDateText, getQuarter, getSalesText } from '../../../utils';

interface ISidebarProps {
  showAddModal?: (() => any);
}

export class RoyaltyReportSidebar extends React.Component<ISidebarProps, any> {
  constructor(props: ISidebarProps) {
    super(props);
  }

  reportDueText() {

    const date = new Date();
    date.setDate(1);
    let monthToUse;
    if (date.getMonth()) {
      monthToUse = date.getMonth() - 1;
    } else {
      monthToUse = 11;
    }
    date.setMonth(monthToUse);

    const quarter = getQuarter(date);

    let year = date.getFullYear();
    if (quarter >= 2) {
      if (quarter > 2) {
        year = year - 1;
      } else {
        if (date.getMonth() === 11) {
          year = year - 1;
        }
      }
    }

    const quarterLabel = `Q${quarter}`;
    const yearLabel = formattedFiscalYear(year);
    const salesDue = getSalesText(quarter, year);
    const dueDate = getDueDateText(quarter, year);

    return `${quarterLabel} ${yearLabel} (${salesDue}) reports are due ${dueDate}`;
  }

  render() {

    let createReportButton = <></>;
    if (this.props.showAddModal) {
      createReportButton = <div className="panel panel-portal button-panel">
        <div className="panel-body button-panel">
          <button className="btn btn-sm btn-block btn-success" onClick={this.props.showAddModal}>Create Report</button>
        </div>
      </div>;
    }

    // tslint:disable-next-line:max-line-length
    const salesDataTemplate = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/Affinity-Sales-Data-Template.xlsx';

    // tslint:disable-next-line:max-line-length
    const helpCenterLink = ' https://help.affinitylicensing.com/en/collections/1443709-royalty-reporting';

    return (
      <div>
        <div className="page-title">
          <h4>Royalties</h4>
          <div className="page-title-actions">
            <a href={helpCenterLink} target="_blank">
              Royalty Reporting Resources
            <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faExternalLink} />

            </a>
          </div>
        </div>
        <div className="panel panel-portal">
          <div
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 40 }}
            className="panel-body text-center"
          >
            <h4 style={{ marginBottom: 30 }} className="text-primary"><strong>{this.reportDueText()}</strong></h4>

            <h5 style={{ margin: 0 }} ><strong>Sales Data Reporting</strong></h5>
            <p style={{ margin: 0 }}>
              All sales for the quarterly sales period should be reported in one Affinity sales data template.
              Your sales data spreadsheet must be formatted properly and contain all required data.

            </p>
            <a href={salesDataTemplate} className="btn btn-link">
              <FontAwesomeIcon icon={faDownload} />
              Download Sales Data Template
            </a>
          </div>

        </div>
        <div className="row flex-row button-row">
          {createReportButton}
          <div className="panel panel-portal button-panel">
            <div className="panel-body">
              <Link
                to="royalties/analytics"
                className="btn btn-sm btn-block btn-default"
              >
                Royalty Analytics

              </Link>
            </div>
          </div>

          <div className="panel panel-portal button-panel">
            <div className="panel-body">
              <Link to="royalties/minimums" className="btn btn-sm btn-default">Minimum Royalties</Link>
            </div>

          </div>

        </div>
      </div>
    );
  }

}
