import { relativeTimeRounding } from 'moment';
import * as moment from 'moment';
import { AffinityImage, ImageApiResponse } from './Image';

export interface TrademarkApiResponse {
  id: number;
  title: string;
  serial_number: string;
  registration_number: string;
  description: string;
  image?: ImageApiResponse;
  gs_list_groups?: GSListGroupApiResponse[];
  filing_date: string;
  registration_date: string;
  date_of_first_use: string;
  date_of_first_use_in_commerce: string;
  owner: string;
  is_default: boolean;
  registration_certificate: {
    url: string;
  };
  status_date: string;
  trademark: boolean;
  certification_mark: boolean;
  service_mark: boolean;
  collective_membership_mark: boolean;
  collective_service_mark: boolean;
  collective_trademark: boolean;
  status: string;
  type: { id: number, name: string};
  events: TrademarkEvent[];
}

export class TrademarkEvent {
  id: number;
  fullCode: string;
  description: string;
  date: moment.Moment;
  order: number;

  static fromApi(data: TrademarkEventApiResponse) {
    const te = new TrademarkEvent();
    te.id = data.id;
    te.fullCode = data.full_code;
    te.description = data.description;
    te.date = moment(data.date);
    return te;
  }
}

interface TrademarkEventApiResponse {
  id: number;
  full_code: string;
  description: string;
  order: number;
  date: string;
}

interface GSListGroupApiResponse {
  id: number;
  description: string;
  class_status: string;
  gs_list: GSItem[];
  date_of_first_use: string;
  date_of_first_use_in_commerce: string;
}
export interface GSItem {
  code: string;
  description: string;
  is_international: boolean;
}

export class GSListGroup {
  id: number;
  description: string;
  status: string;
  gsList: GSItem[] = [];
  dateOfFirstUse: moment.Moment;
  firstUseInCommerce: moment.Moment;

  static fromApi(data: GSListGroupApiResponse) {
    const g = new GSListGroup();
    g.id = data.id;
    g.description = data.description;
    g.status = data.class_status;
    if (data.gs_list) {
      g.gsList = data.gs_list;
    }
    if (data.date_of_first_use) {
      g.dateOfFirstUse = moment(data.date_of_first_use);
    }
    if (data.date_of_first_use_in_commerce) {
      g.firstUseInCommerce = moment(data.date_of_first_use_in_commerce);
    }
    return g;

  }

  get internationalGSList() {
    return this.gsList.filter(g => g.is_international);
  }
  get usGSList() {
    return this.gsList.filter(g => !g.is_international);
  }

  get groupTitle() {
    if (this.internationalGSList.length) {
      return this.internationalGSList[0].description;
    }
    if (this.usGSList.length) {

      return this.usGSList[0].description;
    }
    return '';
  }

}

export class Trademark {

  id: number;
  title: string;
  serialNumber: string;
  registrationNumber: string;
  description: string;
  owner: string;
  isDefault: boolean;

  filingDate: moment.Moment;
  registrationDate: moment.Moment;
  dateOfFirstUse: moment.Moment;
  dateOfFirstUseCommerce: moment.Moment;
  statusDate: moment.Moment;

  trademark: boolean;
  serviceMark: boolean;
  certificationMark: boolean;
  collectiveMembershipMark: boolean;
  collectiveServiceMark: boolean;
  collectiveTrademark: boolean;

  events: TrademarkEvent[];

  status: string;

  image: AffinityImage;

  gsGroups: GSListGroup[] = [];

  certUrl: string;

  _type: { id: number, name: string};

  static fromApi(data: TrademarkApiResponse) {
    const t = new Trademark();
    t.id = data.id;
    t.serialNumber = data.serial_number;
    t.registrationNumber = data.registration_number;
    t.title = data.title;
    t.owner = data.owner;
    t.trademark = data.trademark;
    t.serviceMark = data.service_mark;
    t.certificationMark = data.certification_mark;
    t.collectiveMembershipMark = data.collective_membership_mark;
    t.collectiveServiceMark = data.collective_service_mark;
    t.collectiveTrademark = data.collective_trademark;
    t.status = data.status;
    t.isDefault = data.is_default;
    if (data.filing_date) {
      t.filingDate = moment(data.filing_date);
    }
    if (data.registration_date) {
      t.registrationDate = moment(data.registration_date);
    }

    if (data.events) {
      t.events = data.events.map(e => TrademarkEvent.fromApi(e));
    }
    if (data.date_of_first_use) {
      t.dateOfFirstUse = moment(data.date_of_first_use);
    }
    if (data.date_of_first_use_in_commerce) {
      t.dateOfFirstUseCommerce = moment(data.date_of_first_use_in_commerce);
    }
    if (t.serialNumber && !t.title) {
      t.title = `Serial #${t.serialNumber}`;
    }
    if (data.image) {
      t.image = AffinityImage.fromApi(data.image);
    }
    t.description = data.description;
    if (data.gs_list_groups) {
      t.gsGroups = data.gs_list_groups.map(g => GSListGroup.fromApi(g));
    }
    if (data.registration_certificate) {
      t.certUrl = data.registration_certificate.url;
    }
    if (data.status_date) {
      t.statusDate = moment(data.status_date);
    }
    t._type = data.type;
    return t;
  }

  get isRegistered() {
    return this.serialNumber ? true : false;
  }

  get usptoLink() {
    return `https://tsdr.uspto.gov/#caseNumber=${this.serialNumber}&caseType=SERIAL_NO&searchType=statusSearch`;
  }

  get type() {
    switch (this._type.id) {
      case 1:
        const t = ['Federal Trademark'];
        if (this.serviceMark) {
          t.push('Service Mark');
        }
        if (this.certificationMark) {
          t.push('Certification Mark');
        }
        if (this.collectiveMembershipMark) {
          t.push('Collective Membership Mark');
        }
        if (this.collectiveServiceMark) {
          t.push('Collective Service Mark');
        }
        if (this.collectiveTrademark) {
          t.push('Collective Trademark');
        }

        return t.join(', ');

      case 2:
      case 3:
        return this._type.name;
      case 4:
        return 'Unregistered';
      default:
        return 'Unknown';
    }

  }
}
