
// import * as moment from 'moment';
import { MarketplaceMessage, MarketplaceMessageApiResponse } from './MarketplaceMessage';

export interface MarketplaceMessageThreadApiResponse {
  id: number;
  messages: MarketplaceMessageApiResponse[];
  latest_message: MarketplaceMessageApiResponse;
  users: any[];
}
export class MarketplaceMessageThread {
  id: number;
  messages: MarketplaceMessage[];
  latestMessage: MarketplaceMessage | null;
  users: any[];

  static fromApi(data: MarketplaceMessageThreadApiResponse) {
    const thread = new MarketplaceMessageThread();
    thread.id = data.id;
    thread.messages = data.messages ?
      data.messages.reverse().map((message: any) => MarketplaceMessage.fromApi(message)) : [];
    thread.latestMessage = data.latest_message ?  MarketplaceMessage.fromApi(data.latest_message) : null;
    thread.users = data.users;
    return thread;

  }

}
