import axios from 'axios';
import { logIfCancelled } from '../../api';

export interface ISerialSearchResult {
  vendorImageUrl: string;
  vendorName: string;
  vendorCity: string;
  vendorState: string;
  vendorUrl: string;
  startSerialRange: string;
  endSerialRange: string;
  isLicensed: boolean | null;
}

export const getSerialSearch = (search: string, clientId: string | null = null): Promise<ISerialSearchResult|null> => {
  const params = {
    search,
    client_id: clientId,
  };
  return axios.get('/api/labels/serials', { params })
    .then((response) => {
      const r = response.data.data;
      const vendor = r.order.account;

      const hasImages = vendor.image && vendor.image.urls;
      let imageUrl;
      if (!hasImages) {
        imageUrl = 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/alp-icon.png';
      } else {
        imageUrl = vendor.image.urls.md ? vendor.image.urls.md : vendor.image.urls.or;
      }

      return {
        vendorImageUrl: imageUrl,
        vendorName: vendor.short_name,
        vendorCity: vendor.city,
        vendorState: vendor.state,
        vendorUrl: vendor.website,
        startSerialRange: r.serial_start,
        endSerialRange: r.serial_end,
        isLicensed: r.is_licensed,
      };
    })
    .catch((error) => {
      logIfCancelled(error, 'getSerial');
      throw error;
    });
};
