import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../shared';

interface IProps {
  shown: boolean;
  onClose: () => void;
}

export const ACHVerificationModal = (props: IProps) => {

  return (
    <GatewayModal title="ACH Verification Information" shown={props.shown} type={ModalType.Primary} onClose={props.onClose}>
      <Modal.Body>
        <p className="text-center">
          In 2-4 business days, two “ACCTVERIFY” deposits will appear in your account.
          These deposits will be between $.01 and $.99.
          Once you receive and identify the deposits,
          you'll need to enter the amounts into the Gateway to complete the verification.
          This is a one-time process to confirm your identity and protect your information.
          You will need to confirm the amounts before you can pay with this account.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onClose} className="btn btn-default pull-right">Close</button>
      </Modal.Footer>

    </GatewayModal>
  );

};
