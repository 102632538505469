import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export class LoadingSpinner extends React.PureComponent {

  render() {
    return (
      <div className="react-loading-content">
        <span><FontAwesomeIcon icon={faSpinner} spin /></span>
      </div>
    );
  }

}
