import * as moment from 'moment';
import { Invoice } from '../../Invoice';
import { Vendor } from '../../Vendor';
export class LicenseApplication {
  id: number;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  deletedAt: moment.Moment|null;
  isRefresh: boolean;
  onHold: boolean;
  holdDescription: string;
  paymentRecieved: boolean;
  productSampleRecieved: boolean;
  licensorIds: number[] = [];
  status: number;
  vendor: Vendor;
  invoice: Invoice;

  static fromApi(data: any) {
    const application = new LicenseApplication();
    application.id = data.id;
    application.createdAt = moment(data.created_at);
    application.updatedAt = moment(data.updated_at);
    application.deletedAt = data.deleted_at ? moment(data.deleted_at) : null;
    application.onHold = data.on_hold;
    application.holdDescription = data.hold_description;
    application.isRefresh = data.is_refresh;
    if (data.vendor) {
      application.vendor = Vendor.fromApi(data.vendor);
    }
    application.licensorIds = data.licensor_ids;
    application.status = data.status;
    application.productSampleRecieved = data.products_approved;

    if (data.invoice) {
      application.invoice = Invoice.fromApi(data.invoice);
    }

    return application;
  }

  get invoiceStatusLabel() {
    if (this.invoice) {
      if (Number(this.invoice.invoiceStatus) === 2) {
        return 'Invoice Paid';
      }
      if (Number(this.invoice.invoiceStatus) === 3) {
        return 'Invoice Voided';
      }
      if (Number(this.invoice.invoiceStatus) === 4) {
        return 'Invoice Waived';
      }
      if (Number(this.invoice.invoiceStatus) === 1) {
        return 'Invoice Unpaid';
      }
    }
    return 'Invoice Unknown';
  }

  get invoiceStatusLabelClass() {
    if (this.invoice) {
      if (Number(this.invoice.invoiceStatus) === 2 || Number(this.invoice.invoiceStatus) === 4) {
        return 'text-success';
      }
      if (Number(this.invoice.invoiceStatus) === 1) {
        return 'text-danger';
      }
    }
    return 'text-muted';
  }

}
