import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { match } from 'react-router';
import { UserType } from '../../contexts';
import { Filters, Vendor } from '../../shared';
import { addHttp, removeHttpWww } from '../../utils';
import { FullContent } from '../ContentFrame';
import { FilterBar, LoadingSpinner, PaginationFooter, SortableHeader } from '../shared';

interface IProps {
  user: UserType;
  match?: match<{licensorId: string | undefined}>; // if coming directly from a route

}

interface IState {
  licensor: number;
  vendorCounts: any[];
  vendors: Vendor[];
  loadingVendors: boolean;
  totalVendors: number;
  graphTotal: number;
  filters: Filters;
  hasNext: boolean;
  loading: boolean;
  initialLoad: boolean;
}

export class ClientVendorList extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    let id;
    if (props.user) {
      id = props.user.account.id;
    } else if (props.match) {
      id = props.match.params.licensorId;
    }
    const filters = new Filters();
    filters.sortBy = 'short_name';
    this.state = {
      filters,
      licensor: id,
      vendorCounts: [],
      vendors: [],
      loadingVendors: true,
      loading: true,
      totalVendors: 0,
      graphTotal: 0,
      hasNext: false,
      initialLoad: true,
    };
    this.getVendorList = this.getVendorList.bind(this);
    this.setPage = this.setPage.bind(this);
    this.filter = this.filter.bind(this);
    this.intialLoad = this.intialLoad.bind(this);
  }

  componentDidMount() {
    this.intialLoad();

  }

  intialLoad() {

    this.setState({ loading:  true });
    const search = this.state.filters.search.length ? `&search=${this.state.filters.search}` : '';
    const page = this.state.filters.page;
    const id = this.state.licensor;
    const list = axios.get(`/api/licensors/${id}/licensed-vendors?page=${page}${search}`);
    const graph = axios.get(`/api/v2/stats/vendors?organization_id=${id}`);
    axios.all([graph, list]).then(axios.spread((graphResponse: any, listResponse) => {
      this.setState({
        vendors: listResponse.data.data.map((v: any) => Vendor.fromApi(v)),
        totalVendors: listResponse.data.meta.pagination.total,
        graphTotal: listResponse.data.meta.pagination.total,
        vendorCounts: graphResponse.data.data,
        loadingVendors: false,
        loading: false,
        hasNext: this.state.filters.page < listResponse.data.meta.pagination.total_pages,
      });

    }));

  }

  getVendorList() {
    this.setState({ loadingVendors: true, initialLoad: false });
    const search = this.state.filters.search.length ? `&search=${this.state.filters.search}` : '';
    const page = this.state.filters.page;
    const id = this.state.licensor;
    axios.get(`/api/licensors/${id}/licensed-vendors?page=${page}${search}`)
      .then(response => this.setState({
        vendors: response.data.data.map((v: any) => Vendor.fromApi(v)),
        loadingVendors: false,
        totalVendors: response.data.meta.pagination.total,
        hasNext: this.state.filters.page < response.data.meta.pagination.total_pages,
      }),
    );

  }

  setPage(page: number) {
    const filters = this.state.filters;
    filters.page = page;
    this.setState({ filters });
    this.getVendorList();
  }

  sort() {

  }

  filter(params: any, reload: boolean) {
    const filters = this.state.filters;
    filters.setFilters(params);
    if (reload) {
      this.getVendorList();
    }
  }

  render() {
    const vendors = this.state.vendors
      .map(vendor => (
        <tr key={vendor.id}>
          <td>
            <div className="load-account" style={{ zIndex: 2 }} >
              <img className="pull-left hidden-sm hidden-xs" src={vendor.image.getSize('th')} />
              <strong>{vendor.shortName}</strong>
            </div>
          </td>
          <td className="text-muted">
            {vendor.city}, {vendor.state}
          </td>
          <td><a target="_blank" href={addHttp(vendor.website)}>{removeHttpWww(vendor.website) }</a></td>
          <td>{vendor.owner ? `${vendor.owner.first_name} ${vendor.owner.last_name}` : ''}</td>
          <td>{vendor.owner ? vendor.owner.email : ''}</td>
          <td>{vendor.owner && vendor.owner.phone ? vendor.owner.phone : vendor.phone }</td>
        </tr>

    ));
    let exportLink;
    if (this.props.user) {
      exportLink = '/licenses/csv';
    } else if (this.props.match) {
      exportLink = `clients/${this.props.match.params.licensorId}/licenses/csv`;
    }

    const header = (
      <div className="panel-body">
        { this.state.totalVendors > 0 ? (
          <h2 style={{ marginBottom: 0 }} className="text-primary text-center">{ this.state.graphTotal }</h2>
        ) : null}
        <h3 style={{ margin: 0 }} className="text-center">Licensed Vendors</h3>
        <p className="text-muted text-center">View and export your current licensee list.</p>

      </div>
    );
    let body;
    if (this.state.loading) {
      body = (
        <div className="panel panel-portal">
          {header}

          <div className="panel-body">
            <LoadingSpinner />
          </div>
        </div>

      );

    } else {
      if (this.state.vendors.length || !this.state.initialLoad) {

        body = (

          <div>

            <div className="panel panel-portal">
              {header}
              <div className="panel-body">
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div style={{ width: '100%' }} >
                    <FilterBar useSearch={true} usePerPage={false} useQuarter={false} updateFilters={this.filter} />
                  </div>

                  <a target="_blank" href={exportLink} className="btn btn-default btn-sm">
                    <FontAwesomeIcon className="text-primary" icon={faDownload} /> Download Licensee List
              </a>
                </div>
              </div>
              <div className="panel-body">

                {this.state.loadingVendors ? (<LoadingSpinner />) :
                  this.state.vendors.length ?
                  (
                    <div className="table-responsive">
                      <table className="table table-portal">
                        <thead>
                          <tr>
                            <SortableHeader
                              title="Vendor"
                              sortDir="desc"
                              sortKey="vendor"
                              sortSelectFn={this.sort}
                              disableSort={true}
                            />
                            <SortableHeader
                              title="Location"
                              sortDir="desc"
                              sortKey="vendor"
                              sortSelectFn={this.sort}
                              disableSort={true}
                            />
                            <SortableHeader
                              title="Website"
                              sortDir="desc"
                              sortKey="vendor"
                              sortSelectFn={this.sort}
                              disableSort={true}
                            />
                            <SortableHeader
                                title="Contact"
                                sortDir="desc"
                                sortKey="contact"
                                sortSelectFn={this.sort}
                                disableSort={true}
                              />
                            <SortableHeader
                                title="Email"
                                sortDir="desc"
                                sortKey="email"
                                sortSelectFn={this.sort}
                                disableSort={true}
                            />
                            <SortableHeader
                                title="Phone"
                                sortDir="desc"
                                sortKey="phone"
                                sortSelectFn={this.sort}
                                disableSort={true}
                            />
                          </tr>
                        </thead>
                        <tbody>
                          {vendors}
                        </tbody>

                      </table>
                    </div>
                  ) : (
                    <div>
                      <h4 className="text-center text-muted">No Matching Vendors Found.</h4>
                    </div>
                  )
                }

              </div>

            </div>

            <PaginationFooter
              currentPage={this.state.filters.page}
              totalResults={this.state.totalVendors}
              setPage={this.setPage}
              hasNext={this.state.hasNext}
            />

          </div>
        );

      } else {
        body = (
          <div className="panel panel-portal">
            {header}
            <div className="panel-body">
              <h4 style={{ paddingBottom: 20 }} className="text-center strong">
                We'll show your licensee list once you sign and activate your first license agreement.
              </h4>
            </div>
          </div>

        );
      }

    }

    return (
      <FullContent breadcrumbs={[{ name: 'Vendors' }]}>

        {body}

      </FullContent>

    );
  }
}
