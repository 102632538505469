import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { sortBy } from 'lodash';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Prompt, useParams } from 'react-router';
import { UserContext } from '../../contexts';
import { AffinityImage } from '../../shared';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CurrencyLabel, GatewayModal, LoadingSpinner, ModalType } from '../shared';

interface LicenseRenewal {
  licensor: {
    id: number;
    name: string;
    image: AffinityImage;
  };
  minRoyalty: string | null;
  renewalFee: {
    isWaived: boolean;
    amount: string;
  };
  status: RenewalType;
  renewable: boolean;
}

enum RenewalType {
  renew,
  terminate,
  licensorTerminated,
}

export const ManageRenewalsPage = () => {
  const [error, setError] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [renewals, setRenewals] = React.useState<LicenseRenewal[]>([]);
  const [initialRenewals, setInitialRenewals] = React.useState<LicenseRenewal[]>([]);
  const [fullRenewalModal, setFullRenewalModal] = React.useState(false);
  const [fullNonRenewalModal, setFullNonRenewalModal] = React.useState(false);
  const [nonRenewalModal, setNonRenewalModal] = React.useState(false);
  const [recentLicenseModal, setRecentLicenseModal] = React.useState(false);
  const user = React.useContext(UserContext);
  const params = useParams();

  React.useEffect(() => {
    getLicenses();

  },              []);

  const currentYear = (new Date()).getFullYear();
  // Date.getMonth() is 0-based: use current year if before July 1, otherwise use next year.
  const effectiveYear = (new Date()).getMonth() < 6 ? currentYear : currentYear + 1;
  const mapResponseToStatusItem = (statusItem: any) => ({
    status: statusItem.cancellation_status,
    licensor: {
      id: statusItem.client.id,
      name: statusItem.client.short_name,
      image: AffinityImage.fromApi(statusItem.client.image),
    },
    renewalFee: {
      isWaived: statusItem.renewal_fee.is_waived,
      amount: statusItem.renewal_fee.amount,
    },
    minRoyalty: statusItem.minimum_royalty,
    renewable: statusItem.are_license_renewals_applicable,
  });
  const getLicenses = async () => {
    setLoading(true);
    const query = params['vendorId'] ? { vendor_id: params['vendorId'] } : {};
    const r = await Axios.get('/api/license-renewals', { params: query });
    const sorted = sortBy(r.data.data.map(mapResponseToStatusItem), [(t) => { return t.licensor.name.toLowerCase(); }]);
    setRenewals(sorted);
    setInitialRenewals(r.data.data.map(mapResponseToStatusItem));
    setLoading(false);
  };

  const getRenewalLabel = (license: LicenseRenewal) => {
    if (license.status === RenewalType.renew) {
      return <strong className="text-primary">
        Auto-renews on 7/1/{effectiveYear}
      </strong>;
    }
    return <strong className="text-danger">
      Terminates on 7/1/{effectiveYear}
    </strong>;
  };

  const licenseNameLabel = (renewal: LicenseRenewal, margin = 10) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: margin }}>
        <img style={{ width: 30, height: 30 }} src={renewal.licensor.image.getSize('th')} className="img-responsive" />
        <strong style={{ marginLeft: 10, overflow: 'hidden', textOverflow: 'ellipsis' }}>{renewal.licensor.name}</strong>
      </div>
    );
  };

  const licensorList = (renewals: LicenseRenewal[]) => {
    const items = renewals.map(r => <li style={{ marginBottom: 10 }} key={r.licensor.id}>{r.licensor.name}</li>);
    return (
      <ul style={{ marginBottom: 20 }}>
        {items}
      </ul>
    );
  };

  const setRenewalStatus = (value: any, id: number) => {
    const r = [... renewals];
    const index = r.findIndex(c => c.licensor.id === id);
    if (index !== -1) {
      r[index].status = Number(value);
      setRenewals(r);
    }

  };

  const setAllLicensesNonRenewal = () => {
    const r = [... renewals];
    r.forEach((i, index) => {
      if (i.status !== RenewalType.licensorTerminated) {
        i.status = RenewalType.terminate;
      }
    });
    setRenewals(r);
    showModal();

  };

  const submitChanges = async () => {
    setSubmitting(true);
    const query = params['vendorId'] ? { vendor_id: params['vendorId'] } : {};
    const data = renewals.map(r => ({
      client_id: r.licensor.id,
      cancellation_status: r.status,
    }));
    const r = await Axios.post('/api/license-renewals', { items: data }, { params: query })
      .then(r => r.data)
      .catch((e) => {
        if (!e.response || e.response.status !== 422) {
          return null;
        }
        return { error: e.response.data.error };
      });
    if (r.error) {
      setError(r.error);
    } else if (r) {
      const sorted = sortBy(r.data.map(mapResponseToStatusItem), [(t) => { return t.licensor.name.toLowerCase(); }]);
      setRenewals(sorted);
      setInitialRenewals(r.data.map(mapResponseToStatusItem));
      closeModals();
      setError('');
    } else {
      setError('There was an error submitting your changes. Please try again.');
    }

    setSubmitting(false);

  };

  const showModal = () => {
    const renewing = renewals.filter(l => l.status === RenewalType.renew);
    const nonRenewing = renewals.filter(l => l.status !== RenewalType.renew);
    if (nonRenewing.length === 0) {
      setFullRenewalModal(true);
    } else if (renewing.length === 0) {
      setFullNonRenewalModal(true);
    } else {
      setNonRenewalModal(true);
    }

  };

  const closeModals = () => {
    setFullRenewalModal(false);
    setFullNonRenewalModal(false);
    setNonRenewalModal(false);
  };

  const minFeeLabel = (renewal: LicenseRenewal) => {
    if (!renewal.minRoyalty) {
      return 'No Minimum';
    }
    return (<span><CurrencyLabel value={renewal.minRoyalty} /> minimum</span>);

  };

  const renewalFeeLabel = (license: LicenseRenewal) => {
    if (Number(license.renewalFee.amount) === 0) {
      return 'No renewal fee';
    }
    return (<span>
      <CurrencyLabel value={license.renewalFee.amount} /> renewal {license.renewalFee.isWaived ? (
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          onClick={() => setRecentLicenseModal(true)}
          icon={faInfoCircle}
        />
      ) : null}
    </span>);

  };

  const rows = renewals.filter(r => r.licensor.name.toLowerCase().includes(search.toLowerCase())).map((r, index) => (
    <tr>
      <td className="hidden-xs" style={{ verticalAlign: 'middle' }}>
        {licenseNameLabel(r, 0)}
      </td>
      <td className="visible-xs" style={{ verticalAlign: 'middle', maxWidth: 150, textOverflow: 'ellipsis', overflow: 'hidden' }}>
        {licenseNameLabel(r, 0)}
      </td>
      <td style={{ verticalAlign: 'middle' }} className="text-muted hidden-xs">
        {minFeeLabel(r)}
      </td>
      <td style={{ verticalAlign: 'middle' }} className="text-muted hidden-xs">
        {renewalFeeLabel(r)}
      </td>
      <td style={{ verticalAlign: 'middle' }} className="hidden-xs">
        {getRenewalLabel(r)}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <select
          disabled={user.type === 'vendor' && (r.status === RenewalType.licensorTerminated || !r.renewable)}
          onChange={e => setRenewalStatus(e.target.value, r.licensor.id)}
          value={r.status}
          className="form-control"
          style={{ minWidth: 100 }}
        >
          <option value={RenewalType.renew}>Renew License</option>
          <option value={RenewalType.terminate}>Do Not Renew</option>
          {user.type === 'admin' || r.status === RenewalType.licensorTerminated ?
            <option disabled={user.type === 'vendor'} value={RenewalType.licensorTerminated}>Licensor Non-Renewal</option>
            : null }
        </select>

      </td>
    </tr>
  ));

  const confirmText = (
    <p>
      By submitting this form you confirm that you are an authorized user with permission to take this action.
    </p>
  );

  const errorBanner = error ? (
    <div className="alert alert-danger">
      {error}
    </div>
  ) : null;
  const modalFooter = (
    <Modal.Footer>
      <button onClick={closeModals} className="pull-left btn btn-default">Cancel</button>
      <button onClick={submitChanges} className="pull-rigth btn btn-primary">
        {submitting ? 'Submitting...' : 'Save and Submit'}
      </button>
    </Modal.Footer>
  );

  const licensorNonRenewalRows = renewals.filter(r => r.status === RenewalType.licensorTerminated);
  const nonRenewalRows = renewals.filter(r => r.status === RenewalType.terminate);

  const hasChanges = initialRenewals.length ? !initialRenewals.map((r) => {
    const i = renewals.find(cr => cr.licensor.id === r.licensor.id);
    if (i) {
      if (i.status === r.status) {
        return true;
      }
    }
    return false;
  })
  .reduce((prev, current) => !prev ? false : current, true) : false;
  const propmtMessage = 'You have unsaved changes. Please click "Cancel" to go back and save your Renewal Settings. If you click "Ok" your notice will not be submitted.';
  const saveInfo = (
    <p>
      <strong>
        Please review the information below. You must scroll to the bottom and click 'Save and Submit' to submit your notice.
      </strong>
    </p>
  );

  return (
    <FullContent>
      <Prompt message={propmtMessage} when={hasChanges}  />
      <DocumentTitle title={getAffinityPageTitle('Renewals')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3><strong>Manage Renewals</strong></h3>
          <p className="text-muted">
            You may provide notice of non-renewal for one or more of your license agreements using the form below.
            You must save your renewal settings to fully submit your notice(s).
            If you would like to provide notice of non-renewal for all license agreements,
            please scroll to the bottom and select the full non-renewal notice option.

          </p>

          <p className="text-muted">

            Selecting non-renewal will terminate your license effective July 1, {effectiveYear}.
            Please note that upon termination you remain responsible for Q4 (sales April-June) reporting obligations,
            annual minimum royalties (if applicable), and all continuing obligations as defined in your license agreement.

          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '30px 0' }}>
            <input
              style={{ maxWidth: 300, marginRight: 30 }}
              value={search}
              onChange={e => setSearch(e.target.value)}
              className="form-control"
              placeholder="Search Licenses"
            />
            <button onClick={showModal} className="btn btn-primary">Save Renewal Settings</button>

          </div>
          {loading ? <LoadingSpinner /> : <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>License</th>
                  <th className="hidden-xs">Minimum Royalty</th>
                  <th className="hidden-xs">Renewal Fee</th>
                  <th className="hidden-xs">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div> }
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button onClick={showModal} className="btn btn-primary">Save Renewal Settings</button>
          </div>
        </div>

      </div>

      <div className="panel panel-portal">
        <div className="panel-body">
          <h4 className="text-primary"><strong>Full Non-Renewal Notice</strong></h4>
          <p className="small text-muted">
              If you would like to provide notice of non-renewal for all of your license agreements
              please select the option below.
          </p>
          <button onClick={setAllLicensesNonRenewal} className="btn btn-default">
            Do Not Renew All Licenses
          </button>
        </div>
      </div>
      <GatewayModal
        shown={fullNonRenewalModal}
        type={ModalType.Primary}
        onClose={() => setFullNonRenewalModal(false)}
        title="Notice of Full Non-Renewal"
      >

        <Modal.Body>
          {errorBanner}
          {saveInfo}
          <p>
            You have selected all licenses to provide notice of non-renewal.
          </p>
          {licensorList(nonRenewalRows)}

          {licensorNonRenewalRows.length ? <div>
            <p>
              The following licensors have elected to not renew your license.
              You can expect to receive a separate notice with more information.
            </p>
            {licensorList(licensorNonRenewalRows)}
          </div> : null}

          <p>
            All of your licenses will be terminated effective July 1, {effectiveYear}.
            Please note that upon termination you remain responsible for Q4 (sales April-June) reporting obligations,
            annual minimum royalties (if applicable), and all continuing obligations as defined in your license agreement.
          </p>

        {confirmText}
        </Modal.Body>
        {modalFooter}

      </GatewayModal>
      <GatewayModal
        shown={fullRenewalModal}
        type={ModalType.Primary}
        onClose={() => setFullRenewalModal(false)}
        title="Notice of Renewal"
      >

        <Modal.Body>
          {errorBanner}
          <p>
            You have selected to renew all of your licenses.
            All licenses will be renewed on July 1, {effectiveYear}. You will receive a renewal fee invoice in July.
          </p>
          {confirmText}
        </Modal.Body>
        {modalFooter}

      </GatewayModal>
      <GatewayModal
        shown={nonRenewalModal}
        type={ModalType.Primary}
        onClose={() => setNonRenewalModal(false)}
        title="Notice of Non-Renewal"
      >

        <Modal.Body>
          {errorBanner}
          {saveInfo}
          {nonRenewalRows.length ? <div>
            <p>
              You have provided notice of non-renewal for the following licenses:
            </p>
            {licensorList(nonRenewalRows)}
          </div> : null}

          {licensorNonRenewalRows.length ? <div>
            <p>
              The following licensors have elected to not renew your license.
              You can expect to receive a separate notice with more information.
            </p>
            {licensorList(licensorNonRenewalRows)}
          </div> : null}

          <p>
            The licenses above will be terminated effective July 1, {effectiveYear}.
            Please note that upon termination you remain responsible for Q4 (sales April-June) reporting obligations,
            annual minimum royalties (if applicable), and all continuing obligations as defined in your license agreement.
          </p>

          {confirmText}

        </Modal.Body>
        {modalFooter}

      </GatewayModal>

      <GatewayModal
        shown={recentLicenseModal}
        title="Recently Activated License"
        onClose={() => setRecentLicenseModal(false)}
        type={ModalType.Primary}
      >
        <Modal.Body>
          <p>
            Considering this license was recently activated (after April 1),
            you are not responsible for the renewal fee this year.
            Please keep in mind, however,
            that this is a one-time courtesy and this license will be subject to a renewal fee next year.
          </p>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setRecentLicenseModal(false)} className="btn btn-default pull-right">
            Done
          </button>
        </Modal.Footer>

      </GatewayModal>

    </FullContent>

  );
};
