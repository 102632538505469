import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import * as Yup from 'yup';
import { CreditCardPaymentStub } from '.';
import { payInvoice } from '../../api';
import { ACHPaymentMethod, CCPaymentMethod, PayInvoiceRequest } from '../../shared';
import { Invoice } from '../../shared/Invoice';
import { CurrencyLabel, GatewayModal, LoadingSpinner, ModalType } from '../shared';
import { ACHPaymentStub } from './ACHPaymentStub';
import { NewACHPayment } from './NewACHPayment';
import { NewCreditCard } from './NewCreditCard';

interface IProps {
  invoiceId?: number;
  amount?: number;
  accountId: number;
  onSuccess: () => void;
  paymentMethodSelected?: (paymentMethodId: number) => Promise<PaymentSubmissionStatus>;
  partialAmount?: string;
  modalMode: boolean;
}

export interface PaymentSubmissionStatus {
  success: boolean;
  error?: string;
}

export const PaymentManager = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [ach, setAch] = useState<ACHPaymentMethod | null>(null);
  const [cards, setCards] = useState<CCPaymentMethod[]>([]);
  const [invoice, setInvoice] = useState<Invoice | null>(null);
  const [paying, setPaying] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const [selectedPaymentMethod, setPaymentMethod] = useState<ACHPaymentMethod | CCPaymentMethod |null>(null);
  const [modifyingPM, setModifiyingPM] = useState<number | 'ach' | 'cc' | null>(null);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [partialModalShown, setPartialModalShown] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  useEffect(() => {
    initialLoad();
  },        []);

  const initialLoad = async () => {
    const invoices = props.invoiceId ? Axios.get(`/api/invoices/${props.invoiceId}`) : null;
    let feeString = props.invoiceId ? `invoice_id=${props.invoiceId}` : `pre_fee_total=${props.amount}`;
    if (props.partialAmount) {
      feeString = `pre_fee_total=${(Number(props.partialAmount) * 100).toFixed(0)}`;
    }
    const paymentMethods = Axios.get(`/api/payment-methods?${feeString}${props.accountId ? `&account_id=${props.accountId}` : '' }`);
    const requiredData = await Axios.all([paymentMethods, invoices])
      .then(([paymentResponse, invoiceResponse]) => {
        // invoices
        const invoice = invoiceResponse ?  Invoice.fromApi(invoiceResponse.data.data) : null;
        if (invoice) {
          if (invoice.partialPayments) {
            const amount = props.partialAmount ? props.partialAmount : invoice.amountDue;
            setPaymentAmount(amount.toString());
          }
        }

        // paymentMethods
        const methods = parsePaymentMethods(paymentResponse ? paymentResponse.data.data : []);
        return { invoice, ach: methods.ach, cards: methods.cards };
      });
    setAch(requiredData.ach);
    setCards(requiredData.cards);
    if (props.invoiceId) {
      setInvoice(requiredData.invoice);
    }
    setLoading(false);
  };

  const updatePaymentMethods = async (amount?: string) => {
    setUpdating(true);
    let feeString = props.invoiceId ? `invoice_id=${props.invoiceId}` : `pre_fee_total=${props.amount}`;
    if (paymentAmount) {
      feeString = `pre_fee_total=${(Number(paymentAmount) * 100).toFixed(0)}`;
    }
    if (amount) {
      feeString = `pre_fee_total=${(Number(amount) * 100).toFixed(0)}`;
    }
    const accountParam = props.accountId ? `&account_id=${props.accountId}` : '';
    const paymentMethods = await Axios.get(`/api/payment-methods?${feeString}${accountParam}`)
      .then((response) => {
        const methods = parsePaymentMethods(response.data.data);
        return { ach: methods.ach, cards: methods.cards };
      });

    setCards(paymentMethods.cards);
    setAch(paymentMethods.ach);
    setUpdating(false);

  };

  const parsePaymentMethods = (data: any[]) => {
    const cards: CCPaymentMethod[] = [];
    let ach: ACHPaymentMethod | null = null;
    data.forEach((paymentMethod: any) => {
      if (paymentMethod.payment_method_type) {
        const type = paymentMethod.payment_method_type;
        if (type.name === 'ACH') {
          ach = ACHPaymentMethod.fromApi(paymentMethod);
        } else {
          cards.push(CCPaymentMethod.fromApi(paymentMethod));
        }
      }
    });
    return { cards, ach };
  };

  const submitPayment = async () => {
    if (!paying && selectedPaymentMethod) {
      setPaying(true);
      if (invoice) {

        const request: PayInvoiceRequest = {
          invoiceId: invoice.id.toString(),
          paymentMethodId: selectedPaymentMethod.id.toString(),
          amount : invoice.partialPayments ? Number(paymentAmount) : undefined,
        };
        const payment = await payInvoice(request).then(() => true)
        .catch((error) => {
          const code = error.response.status;
          if (code === 422) {
            const errors = error.response.data.errors;
            const errorList = Object.values(errors).map((v: string[]) => v[0]);
            setPaymentError(errorList.join('. '));
          } else {
            setPaymentError('An error occurred when submitting the payment.');
          }
          setPaying(false);
        });
        if (payment) {
          props.onSuccess();
          setPaying(false);
        }
      } else if (props.modalMode && props.paymentMethodSelected) {
        const payment = await props.paymentMethodSelected(selectedPaymentMethod.id);
        if (payment.success) {
          setPaying(false);
        } else {
          setPaying(false);
          setPaymentError(payment.error ? payment.error : 'An error occured when submitting the payment.');
        }
      }
    }

  };

  const closeModal = () => {
    setModalShown(false);
    setPaymentMethod(null);
  };

  const selectPaymentMethod = (method: ACHPaymentMethod | CCPaymentMethod) => {
    setPaymentMethod(method);
    if (!props.modalMode) {
      setModalShown(true);

    }
  };

  const updatePartialPayment = async (amount: string) => {
    setPaymentAmount(amount);
    updatePaymentMethods(amount);
    setPartialModalShown(false);
  };

  if (loading) {
    return (
      <LoadingSpinner />
    );
  }

  const cardList = cards.filter(c => !modifyingPM || modifyingPM === c.id).map((c) => {
    let fee;
    if (invoice) {
      if (invoice.partialPayments) {
        fee = (((Number(paymentAmount) * 100) + c.fees.calculated) / 100).toFixed(2);
      } else {
        fee = invoice.calculateTotalWithFee(c);
      }
    } else if (props.amount) {
      fee = (props.amount + c.fees.calculated) / 100;
    } else {
      fee = 0.0;
    }
    return (
      <CreditCardPaymentStub
        key={c.id}
        amountDue={fee}
        card={c}
        onSelect={() => selectPaymentMethod(c)}
      />
    );
  });
  let amountDue;
  let amountTotal;
  if (invoice) {
    amountTotal = invoice.amountDue;
    if (invoice.partialPayments) {
      amountDue = Number(paymentAmount).toFixed(2);
    } else {
      amountDue = invoice.amountDue;
    }
  } else if (props.amount) {
    amountTotal = (props.amount) / 100;
    amountDue = (props.amount) / 100;
  } else {
    amountDue = 0.0;
    amountTotal = 0.0;
  }
  let confirmLastFour = '';
  let confirmName = '';
  let confirmType = '';
  let calculatedTotal = amountDue;

  if (selectedPaymentMethod && (selectedPaymentMethod as ACHPaymentMethod).bankName) {
    const ach = selectedPaymentMethod as ACHPaymentMethod;
    confirmType = 'bank';
    confirmLastFour = ach.lastFour;
    confirmName = ach.bankName;

  } else if (selectedPaymentMethod && (selectedPaymentMethod as CCPaymentMethod).brand) {
    const card = selectedPaymentMethod as CCPaymentMethod;
    confirmType = 'card';
    confirmLastFour = card.lastFour;
    confirmName = card.brand;
    if (invoice) {
      if (invoice.partialPayments) {
        calculatedTotal = (((Number(paymentAmount) * 100) + card.fees.calculated) / 100).toFixed(2);
      } else {
        calculatedTotal = invoice.calculateTotalWithFee(card);
      }
    } else if (props.amount) {
      calculatedTotal = ((props.amount + card.fees.calculated) / 100).toFixed(2);
    }
  }
  const confirmModalBody = (
    <div>
      {paymentError ? (
        <div className="alert alert-danger">
          {paymentError}

        </div>
      ) : null}
      <h4 className="text-primary payment-value"><strong>Please confirm your <NumberFormat
        value={calculatedTotal}
        displayType={'text'}
        thousandSeparator={true}
        prefix={'$'}
        decimalScale={2}
        fixedDecimalScale={true}
      /> payment.</strong></h4>
      <p>This amount will
            {confirmType === 'bank' ? ' be debited from the following checking account' : ' be charged to the following credit card'}.
          </p>

      <h4 className="text-center payment-name"><strong>{confirmName}</strong></h4>
      <h4 className="payment-last-four text-center">{confirmLastFour}</h4>
      {props.modalMode ? (
        <button style={{ marginBottom: 20 }} onClick={() => setPaymentMethod(null)} className="btn btn-link btn-block text-center">
          Change Payment Method
        </button>
      ) : null}

      <p className="text-muted text-center payment-authorize">
        By clicking Submit Payment, I authorize Affinity to
              {confirmType === 'bank' ? ' debit my checking account ' : ' charge my credit card '}
              for the amount indicated above.
            </p>
    </div>

  );
  let paymentMethods;
  if (updating) {
    paymentMethods = (<LoadingSpinner />);

  } else if (props.modalMode && selectedPaymentMethod) {
    paymentMethods = (
      <div>
        {confirmModalBody}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <button onClick={() => setPaymentMethod(null)} className="btn btn-default">Change Payment Method</button> */}
          <button onClick={submitPayment} className="btn btn-block btn-primary">
            {paying ? 'Submitting...' : 'Submit Payment'}
          </button>
        </div>
      </div>
    );
  } else {
    paymentMethods = updating ? (<LoadingSpinner />) : (
      <div>
        {ach && (modifyingPM === ach.id || !modifyingPM) ? (
          <ACHPaymentStub
            amountDue={amountDue}
            achAccount={ach}
            onSelect={() => selectPaymentMethod(ach)}
            onVerified={updatePaymentMethods}
            accountId={props.accountId}
            toggleModify={setModifiyingPM}
          />
        ) : null}

        {cardList}
        {!ach && (modifyingPM === 'ach' || !modifyingPM) ? (<NewACHPayment
          onSuccess={updatePaymentMethods}
          amountDue={amountDue}
          accountId={props.accountId}
          toggleModify={setModifiyingPM}
        />)
          : null}
        {modifyingPM === 'cc' || !modifyingPM ? (<NewCreditCard
          amountDue={amountDue}
          onCardSuccess={updatePaymentMethods}
          accountId={props.accountId}
          toggleModify={setModifiyingPM}
          currentCardCount={cards.length}
        />) : null}

      </div>

    );
  }

  const partialValidation = Yup.object().shape({
    payment: Yup.number()
      .min(.51, 'Unfortunately, we\'re not able to accept online payments for $0.50 and below. Please contact our accounting department for assistance.')
      .max(amountTotal, 'You have entered an amount above the total amount due on this invoice. Please enter an amount less than the total amount due.')
      .typeError('You have entered an invalid amount. Please enter a dollar amount equal to or less than the total amount due on the invoice. Please do not include any commas')
      .required('A partial payment is required.'),
  });

  return (
    <div>
      <div className="panel panel-portal">
        <div className="panel-body">
          {invoice ? <h3 className="no-margin text-center"><strong>Invoice #{invoice.id}</strong></h3> : null}

          {invoice ? <p className="no-margin text-muted text-center">{invoice.invoiceType.display}</p> : null}
          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #ddd',
                  paddingBottom: 5,
                  marginBottom: 5,
                }}
              >
                <span className="text-muted">Total Due</span>
                <span><strong><CurrencyLabel value={amountTotal} /></strong></span>
              </div>
              {invoice && invoice.partialPayments ?
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span className="text-muted">Payment Amount</span>
                  <span><strong><CurrencyLabel value={amountDue} /></strong></span>
                </div>
              : null}
              {invoice && invoice.partialPayments ?
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span className="text-muted"></span>
                  <button onClick={() => setPartialModalShown(true)} style={{ padding: 0 }} className="btn btn-link">
                    Change Payment Amount
                  </button>
                </div>
              : null}

            </div>
          </div>

        </div>
      </div>

      <div className="panel panel-portal">
        <div className="panel-body">
          <div className="row">
            {!props.modalMode || (props.modalMode && !selectedPaymentMethod) ?
              <div className="col-xs-12 col-md-8 col-md-offset-2">
                <h4><strong>Select Payment Option to Continue</strong></h4>
                <p className="help-block">
                  Please select an available payment method or add a new payment method to continue.
              </p>
              </div>
            : null}
          </div>

          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
              {paymentMethods}
            </div>
          </div>

        </div>
      </div>

      <GatewayModal
        onClose={closeModal}
        title="Confirm Payment" shown={modalShown}
        type={ModalType.Primary}
      >
        <Modal.Body className="text-center confirm-payment-modal">
            {confirmModalBody}
        </Modal.Body>

        <Modal.Footer>
          <button onClick={closeModal} className="btn btn-default pull-left">Cancel</button>
          <button onClick={submitPayment} className="btn btn-primary pull-right">
            {paying ? 'Submitting...' : 'Submit Payment'}
          </button>

        </Modal.Footer>
      </GatewayModal>

      <GatewayModal
        onClose={() => setPartialModalShown(false)}
        title="Set Partial Amount" shown={partialModalShown}
        type={ModalType.Primary}
      >
        <Formik
          initialValues={{ payment: amountDue }}
          onSubmit={values => updatePartialPayment(values.payment)}
          validationSchema={partialValidation}
        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                {invoice && invoice.partialPayments ? (
                  <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
                      <label style={{ marginRight: 10 }}>Enter the payment amount below.</label>

                    <div style={{ maxWidth: 200, width: 200 }} className="input-group">
                      <div className="input-group-addon">$</div>
                      <Field
                        name="payment"
                        className="form-control input-lg text-right"
                      />
                    </div>

                    <p style={{ marginTop: 15 }} className="text-danger text-center">
                      <ErrorMessage name="payment" />
                    </p>

                  </div>

                ) : null}

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setPartialModalShown(false)} className="btn btn-default pull-left">
                  Cancel
                </button>
                <button className="btn btn-primary pull-right">Change Payment Amount</button>

              </Modal.Footer>
            </Form>

          )}

        </Formik>
      </GatewayModal>

    </div>

  );

};
