import * as React from 'react';
import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AccountAddress, AffinityMarket } from '../../../shared';

interface IProps {
  selected: any[];
  onChange: (markets: any[]) => void;
  disabled?: boolean;
  multiple?: boolean;
  size?: 'sm' | null;
}

export const MarketTypeahead = (props: IProps) => {

  const [markets, setMarkets] = useState<AffinityMarket[]>([]);
  const [loading, setLoading] = useState(true);
  const ref = React.useRef<any>();

  React.useEffect(() => {
    setMarkets([
      new AffinityMarket({ id: 1, name: 'Greek' }),
      new AffinityMarket({ id: 2, name: 'Collegiate' }),
      new AffinityMarket({ id: 3, name: 'Associations' }),
      new AffinityMarket({ id: 5, name: 'Charitable' }),
    ]);
    setLoading(false);
  },              []);

  return (
    <Typeahead
      ref={ref}
      id={'market-typeahead'}
      bsSize={props.size ? props.size : undefined}
      placeholder={'Select Market'}
      options={markets}
      isLoading={loading}
      selected={props.selected}
      multiple={props.multiple}
      onChange={(v) => {
        props.onChange(v);
      }}
      paginate={false}
      maxResults={5}
      labelKey={(item: AffinityMarket) => item.name}
      clearButton={true}
      disabled={props.disabled}
    />

  );
};
