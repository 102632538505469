import * as React from 'react';
import { GatewayNavRoute } from '../shared';
import User from '../shared/User';

export const defaultUser: UserType = {
  id: 0,
  type: null,
  navigation: [],
  first_name: '',
  last_name: '',
  email: '',
  fullname: '',
  account: null,
  canAccessUsers: false,
  canAccessAccount: false,
  canUploadDesigns: false,
  canMassUploadDesigns:false,
  image: null,
  scope: '',
};

export interface UserType {
  id: number;
  account: any;
  type: string|null;
  navigation: GatewayNavRoute[];
  first_name: string;
  last_name: string;
  email: string;
  fullname: string;
  canAccessUsers: boolean;
  canAccessAccount: boolean;
  canUploadDesigns: boolean;
  canMassUploadDesigns: boolean;
  image: any;
  scope: string;
}

export const UserContext = React.createContext<UserType>(defaultUser);

export const RoyaltyAdminsContext = React.createContext<User[]>([]);
