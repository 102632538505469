import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { AffinityClient, MarketplaceAdvertisement } from '../../../shared';
import { MarketplaceSite } from '../../../shared/MarketplaceSite';

interface IProps {
  ad?: MarketplaceAdvertisement;
  submitting: boolean;
  onSubmit: (values: any) => void;
}

export const AdForm = (props: IProps) => {
  const [sites, setSites] = React.useState<MarketplaceSite[]>([]);
  const [licensors, setLicensors] = React.useState<AffinityClient[]>([]);

  React.useEffect(() => {
    getSites();
    getLicensors();

  },              []);

  const initialValues = {
    title: props.ad ? props.ad.title : '',
    url: props.ad ? props.ad.url : '',
    url_text: props.ad ? props.ad.urlText : '',
    image: null,
    licensor: props.ad && props.ad.licensor ? props.ad.licensor.id : null,
    site: props.ad && props.ad.site ? props.ad.site.id : null,
  };

  const validation = Yup.object().shape({
    title: Yup.string().required('Title is required.'),
    url: Yup.string().url('Must be a valid URL.').required('URL is required'),
    url_text: Yup.string().required('URL text is required.'),
    image: props.ad ? Yup.mixed().notRequired() : Yup.mixed().required('Image is required.'),
  });

  const getSites = async () => {
    const s = await Axios.get('/api/marketplace/sites');
    const siteLists = s.data.data.map((i: any) => MarketplaceSite.fromApi(i));
    setSites(siteLists);
  };

  const getLicensors = async () => {
    const l = await Axios.get('/api/licensors');
    const licensorList = l.data.data.map((i: any) => new AffinityClient(i));
    setLicensors(licensorList);
  };

  const siteOptions = sites.map(s => (<option key={s.id} value={s.id}>{s.title}</option>));
  const licensorOptions = licensors.map(l => (<option key={l.id} value={l.id}>{l.shortName}</option>));

  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit} validationSchema={validation}>
      {(formProps: FormikProps<any>) => (
        <Form>
          <div className="form-group">
            <label>Title</label>
            <Field placeholder="Ad Title" className="form-control" name="title" />
            <p className="text-danger">
              <ErrorMessage name="title" />
            </p>
          </div>
          <div className="form-group">
            <label>URL</label>
            <Field placeholder="Ad URL" className="form-control" name="url" />
            <p className="text-danger">
              <ErrorMessage name="url" />
            </p>
          </div>
          <div className="form-group">
            <label>URL Text</label>
            <Field placeholder="Ad URL Text" className="form-control" name="url_text" />
            <p className="text-danger">
              <ErrorMessage name="url_text" />
            </p>
          </div>
          {props.ad ? (
            <div className="form-group">
              <label>Current Image</label>
              <img
                alt="Current Image"
                style={{ maxHeight: 200 }}
                src={props.ad.image && props.ad.image.getSize('sm')}
                className="img-responsive"
              />
            </div>
          ) : null}
          <div className="form-group">
            <label>Ad Image</label>
            <input
              type="file"
              name="image"
              onChange={e => formProps.setFieldValue('image', e.target.files && e.target.files[0])}
            />
            <p className="text-danger">
              <ErrorMessage name="image" />
            </p>
          </div>
          <div className="form-group">
            <label>Site</label>
            <Field component="select" className="form-control" name="site" >
              <option selected disabled>Select Site</option>
              {siteOptions}
            </Field>
          </div>
          <div className="form-group">
            <label>Licensor</label>
            <Field component="select" className="form-control" name="licensor" >
              <option selected disabled>Select Licensor</option>
              {licensorOptions}
            </Field>
          </div>

          <button type="submit" className="btn btn-primary">{

            props.submitting ?
              'Submitting...' :
              `${props.ad ? 'Update' : 'Create' } Ad`
          }</button>
        </Form>
      )}

    </Formik>
  );
};
