import * as React from 'react';
import { DesignResponseGrid } from './DesignResponseGrid';

interface IProps {
  accountId: number;
}

export class AccountDesignResponseIndex extends React.PureComponent<IProps, any> {

  render() {

    return (
      <div>
        <DesignResponseGrid showAddButton={true} showLicensor={false} accountId={this.props.accountId} />
      </div>
    );
  }

}
