export class ProductCategory {
  id: number;
  name: string;
  parent: ProductCategory;
  isParent: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
    }
  }

  static fromApi(data: ProductCategoryApiResponse) {
    const category = new ProductCategory();
    category.id = data.id;
    category.name = data.name;
    if (data.parent) {
      category.parent = ProductCategory.fromApi(data.parent);
    }
    category.isParent = data.is_parent;
    return category;

  }

  getParent() {
    if (this.parent) {
      return this.parent;
    }
    return null;
  }

  static parentCategoryTree(category: ProductCategory) {
    let c: ProductCategory | null = category;
    const tree = [];
    while (c != null) {
      tree.unshift(c);
      c = c.getParent();
    }
    const names = tree.map(cat => cat.name);
    return names.join(' > ');
  }

}

export interface ProductCategoryApiResponse {
  id: number;
  name: string;
  parent?: ProductCategoryApiResponse;
  is_parent: boolean;
}

export interface ProductCategoryParentMapping {
  category: ProductCategory;
  children: ProductCategoryParentMapping[];
}
