import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { DistributionChannel } from '../../../shared';
import { GatewayModal, ModalType } from '../../shared';

interface IProps {
  shown: boolean;
  channels: DistributionChannel[];
  onClose: () => void;

}

export class DistributionChannelsInfoModal extends React.Component<IProps, any> {

  render() {

    const content = this.props.channels.map(channel => (
        <div>
          <strong>{channel.name}</strong>
          <p>{channel.description}</p>
        </div>
    ));

    return (
      <GatewayModal
        type={ModalType.Primary}
        shown={this.props.shown}
        onClose={this.props.onClose}
        title={'About Distribution Channels'}
      >
        <Modal.Body>
          {content}

        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.props.onClose} className="btn btn-default">
            Close

          </button>
        </Modal.Footer>
      </GatewayModal>

    );

  }

}
