import { faDownload, faExternalLink, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as  _ from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { UserType } from '../../contexts';
import { BrandCategoryType, BrandMarkAsset } from '../../shared';

interface IProps {
  brand: BrandMarkAsset;
  categories: any[];
  index: number;
  deleteBrand?: (brand: BrandMarkAsset) => any;
  edit?: (brand: BrandMarkAsset) => any;
  delete: (brand: BrandMarkAsset) => any;
  user: UserType;

}

interface IState {
  showModal: boolean;
}

export class BrandAsset extends React.Component<IProps, IState>  {

  constructor(props: IProps) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.contentBackground = this.contentBackground.bind(this);
    this.deleteBrand = this.deleteBrand.bind(this);
    this.editBrand = this.editBrand.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.deleteBrand = this.deleteBrand.bind(this);
    this.recentlyUpdated = this.recentlyUpdated.bind(this);
  }

  deleteBrand(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.props.delete(this.props.brand);
  }

  editBrand() {
    if (this.props.edit && (this.props.user.type === 'admin' || this.props.user.type === 'client')) {
      this.props.edit(this.props.brand);
    }
  }

  contentBackground() {
    if (this.props.brand.category.type === BrandCategoryType.IMAGE && this.props.brand.previewFile) {
      return `url(${this.props.brand.previewFile.url})`;
    }
    if (this.props.brand.category.type === BrandCategoryType.COLOR && this.props.brand.colors !== null) {
      // some of the colors will have a # others won't...
      const color = this.props.brand.colors.hex ?  this.props.brand.colors.hex.replace('#', '') : 'ffffff';
      return `#${color}`;
    }
    if (this.props.brand.category.type === BrandCategoryType.FONT && this.props.brand.previewFile) {
      return `url(${this.props.brand.previewFile.url})`;
    }
    return 'white';

  }

  showEditModal() {
    this.setState({ showModal: true });
  }

  closeEditModal() {
    this.setState({ showModal: false });
  }

  recentlyUpdated() {
    const lastDate = moment(this.props.brand.createdAt).add(60, 'days');
    return moment().isBefore(lastDate);

  }

  render() {
    const imageBackground = {
      background: this.contentBackground(),
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    };
    const isPrimaryLabel = this.props.brand.isPrimary ?
      <span className="label label-info pull-right ng-scope">Primary</span> :
      null;
    const downloadLink = this.props.brand.file ? (
      <a
        style={{ marginLeft: 10 }}
        onClick={event => event.stopPropagation()}
        className="btn btn-sm btn-default btn-block text-center"
        target="_blank" href={`/api/brand-assets/${this.props.brand.id}/downloads`}
      >
        <FontAwesomeIcon className="text-success" icon={faDownload} />
        <span>Download {this.props.brand.getFileExtension()}</span>
      </a>) : this.props.brand.category.type === BrandCategoryType.FONT && this.props.brand.value ? (
        <a
          style={{ marginLeft: 10 }}
          onClick={event => event.stopPropagation()}
          className="btn btn-sm btn-default btn-block text-center"
          target="_blank" href={this.props.brand.value}
        >
          <FontAwesomeIcon className="text-success" icon={faExternalLink} />
          <span>Download Link</span>
        </a>
      ) : null;
    const colorDetails = this.props.brand.category.type === BrandCategoryType.COLOR &&
      this.props.brand.colors !== null ?
      (
        <div style={{ alignSelf: 'flex-end', width: '100%', background: 'white', whiteSpace: 'nowrap' }}>
          <div style={{ display: 'flex', justifyContent:'space-between' }}>
            <strong>HEX: </strong> <span>#{this.props.brand.colors.hex}</span>
          </div>
          { this.props.brand.colors.rgb ?
          <div style={{ display: 'flex', justifyContent:'space-between' }}><strong>RGB: </strong>
            {this.props.brand.colors.rgb.r}/
            {this.props.brand.colors.rgb.g}/
            {this.props.brand.colors.rgb.b}

          </div> : null}
          { this.props.brand.colors.cmyk ?
          <div style={{ display: 'flex', justifyContent:'space-between' }}><strong>CMYK: </strong>
            {this.props.brand.colors.cmyk.c}/
            {this.props.brand.colors.cmyk.m}/
            {this.props.brand.colors.cmyk.y}/
            {this.props.brand.colors.cmyk.k}
          </div> : null }
          <div style={{ display: 'flex', justifyContent:'space-between' }}>
            <strong>PMS: </strong> {this.props.brand.colors.pms}
          </div>
        </div>
      ) : null;

    const textDetails = this.props.brand.category.type === BrandCategoryType.TEXT ? (
      <div
        style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <blockquote>{this.props.brand.value}</blockquote>
      </div>
    ) : null;
    const fontDetails = this.props.brand.category.type === BrandCategoryType.FONT ? (
      <div
        style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        {this.props.brand.previewFile ?
          <style
            dangerouslySetInnerHTML={{
              __html: `
                 @font-face {
                   font-family: ${this.props.brand.title};
                   src: url("/api/brand-assets/${this.props.brand.id}/font");
                 }
              `,
            }}

          />

           : null
        }
        {this.props.brand.value ? <link rel="stylesheet" href={this.props.brand.value}></link> : null}
        {this.props.brand.value && !this.props.brand.previewFile ? <h4 style={{ fontFamily: this.props.brand.value ? this.props.brand.title : undefined }}>
          {this.props.brand.title}
        </h4> : null}
      </div>
      ) : null;

    const updatedDate = `Updated ${moment(this.props.brand.updatedAt).fromNow()}` ;
    const createdDate = `Recently added ${moment(this.props.brand.createdAt).fromNow()}` ;
    const displayDate = this.recentlyUpdated() ?
        <div className="small text-muted text-ellipsis text-danger">{createdDate}</div>
      : <div className="small text-muted text-ellipsis"> {updatedDate} </div>;

    return (
        <div
          style={{
            background: 'transparent',
            opacity: 1,
          }}
          className="col-xl-2 col-lg-3 col-md-6 col-sm-4"
        >

        <div onClick={this.editBrand} className="panel panel-portal panel-portal-hover corner-label">

              <div className="panel-body" >
                <div style={{ height: 150, display: 'flex', padding: 0, ...imageBackground }}>
                  {colorDetails}
                  {textDetails}
                  {fontDetails}
                </div>
                <div className="brand-text-area">
                  <div className="text-ellipsis">
                    <strong>{this.props.brand.title}</strong>
                  </div>
                  {displayDate}

                </div>
              </div>

            <div className="panel-footer">
              <div style={{ display: 'flex', justifyContent: 'space-between' }} className="">
                { this.props.user.type === 'admin' || this.props.user.type === 'client' ?
                  <button onClick={this.deleteBrand} className="btn btn-sm btn-default text-center" >
                    <FontAwesomeIcon className="text-danger" icon={faTimes} />
                  </button>
                  : null
                }
                {downloadLink}

              </div>

            </div>

            {isPrimaryLabel}

          </div>

        </div>
    );
  }

}
