import { File } from './File';
import { Insignia } from './Insignia';

export interface BrandColor {
  cmyk?: {c: string; m: string; y: string; k: string; };
  hex?: string;
  pms?: string;
  rgb?: {r: number; g: number; b: number; };
}

export enum BrandCategoryType {
  COLOR = 'color',
  IMAGE = 'image',
  FONT = 'font',
  TEXT = 'text',
}

export class BrandMarkAsset {

  id: number;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  description: string;
  value: any;
  categoryId: number;
  fileId: number;
  isPrimary: boolean;
  file: File | null;
  previewFile: File | null;
  category: { id: number, type: BrandCategoryType, title: string};
  colors: BrandColor | null;
  account: any;
  insignia: Insignia;
  sortIndex: number;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.createdAt = new Date(data.created_at);
      this.updatedAt = new Date(data.updated_at);
      this.title = data.title;
      this.description = data.description;
      this.value = data.value;
      this.categoryId = data.category_id;
      this.fileId = data.file_id;
      this.category = data.category;
      if (data.file) {
        this.file = new File(data.file);
      } else {
        this.file = null;
      }
      if (data.preview_file) {
        this.previewFile = new File(data.preview_file);
      } else {
        this.previewFile = null;
      }
      if (this.file && !this.previewFile) {
         // This might not be accurate
        if (this.category.type !== BrandCategoryType.FONT) {
          this.previewFile = this.file;
        }
      }
      this.isPrimary = data.is_primary;
      if (this.category.type === BrandCategoryType.COLOR) {
        this.colors = data.value;
        if (this.colors && this.colors.hex) {
          // over time as colors get updated this should not be an issue
          this.colors.hex = this.colors.hex.replace('#', '');
        }
      } else {
        this.colors = null;
      }

      if (data.account) {
        this.account = data.account;
      } else {
        this.account = null;
      }

      if (data.insignia) {
        this.insignia = Insignia.fromApi(data.insignia);
      }
      this.sortIndex = data.sort_index;
    }

  }

  getFileExtension() {
    if (this.file) {
      return this.file.url.toUpperCase().split('.').pop();
    }
    return '';
  }

  matchSearch(search: string) {
    const lowerSearch = search.toLowerCase();
    let inFile = false;
    if (this.file) {
      inFile = this.file.previousFileName.toLowerCase().includes(lowerSearch);
    }
    return inFile || this.title.toLowerCase().includes(lowerSearch) || this.description.toLowerCase().includes(lowerSearch);
  }

}
