import { faDownload, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { CancelTokenSource } from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikProps } from 'formik';
import { isNil, omitBy, orderBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { DropdownButton, MenuItem, Modal } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { UserContext, UserType } from '../../contexts';
import { BrandCategoryType, BrandMarkAsset, Insignia } from '../../shared';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType, Pill, PillTabs } from '../shared';
import { deleteBrandAsset } from './api';
import { BrandAsset } from './BrandAsset';
import { BrandAssetDeleteModal } from './BrandAssetDeleteModal';
import { BrandAssetModal } from './BrandAssetModal';

interface CategoryBrands {
  category: any;
  brands: BrandMarkAsset[];
}

interface InsigniaGroup {
  id: number;
  title: string;
  insignia: Insignia[];
}

interface IProps {
  user: UserType;
}

interface IState {
  brands: CategoryBrands[];
  categories: any[];
  insignias: Insignia[];
  clientID: number;
  loading: boolean;
  createModalShown: boolean;
  deleteModalShown: boolean;
  selectedBrand: BrandMarkAsset | null;
  addCategory: any;
  insigniaGroups: InsigniaGroup[];
  selectedGroup: InsigniaGroup | null;
  insigniaFilter: Insignia | null;
  typeFilter: 'all' | 'Font' | 'Image' | 'Color' | 'Text' | 'Style Guide';
  addTab: boolean;
  search: string;
}

export class ClientBrandPage extends React.Component<IProps & RouteComponentProps<any>, IState> {

  static contextType  = UserContext;

  protected _deleteAssetSource: CancelTokenSource;

  constructor(props: any) {
    super(props);

    let accountId;
    if (props.user && props.user.type === 'client') {
      accountId = props.user.account_id;
    } else {
      accountId = this.props.match.params['licensorId'];
    }

    const params = queryString.parse(this.props.location.search);

    this.state = {
      brands: [],
      categories: [],
      clientID: accountId,
      loading: true,
      createModalShown: false,
      deleteModalShown: false,
      selectedBrand: null,
      insignias: [],
      addCategory: null,
      insigniaGroups: [],
      selectedGroup: null,
      insigniaFilter: null,
      typeFilter: params.type ? params.type : 'all',
      addTab: false,
      search: params.search ? params.search : '',
    };

    this.deleteBrand = this.deleteBrand.bind(this);
    this.showCreateBrandMarkModal = this.showCreateBrandMarkModal.bind(this);
    this.closeCreateBrandMarkModal = this.closeCreateBrandMarkModal.bind(this);
    this.showEditBrandMarkModal = this.showEditBrandMarkModal.bind(this);
    this.showDeleteBrandMarkModal = this.showDeleteBrandMarkModal.bind(this);
    this.closeDeleteBrandMarkModal = this.closeDeleteBrandMarkModal.bind(this);
    this.onBrandModalSuccess = this.onBrandModalSuccess.bind(this);
    this.getInsignias = this.getInsignias.bind(this);
    this.getData = this.getData.bind(this);
    this.getInsigniaGroups = this.getInsigniaGroups.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.search = this.search.bind(this);
    this.getCurrentQueryParams = this.getCurrentQueryParams.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.getInsigniaGroups();
    this.getInsignias();
  }

  getData() {
    this.setState({ loading: true });
    axios.all([
      axios.get('/api/brand-categories'),
      axios.get(`/api/brand-assets?limit=-1&sort_by=sort_index&licensor_id=${this.state.clientID}`),

    ]).then(axios.spread((categories: any, assets: any) => {
      const c = categories.data.data;
      const sortedAssets: CategoryBrands[] = c.map((category: any) => {
        return { category, brands: [] };
      });
      assets.data.data.map((brand: any) => new BrandMarkAsset(brand)).forEach((value: any) => {
        const index = sortedAssets.findIndex(cat => cat.category.id === value.category.id);
        if (index === -1) {
          sortedAssets.push({ category: value.category, brands:[value] });
        } else {
          sortedAssets[index].brands.push(value);
        }
      });

      this.setState({ categories: c, brands: this.sortBrandCategories(sortedAssets), loading: false });

    })).catch(() => this.setState({ loading: false }));

  }

  getInsignias() {
    const params = this.getCurrentQueryParams();
    axios.get(`/api/brandmarks?client_id=${this.state.clientID}`)
      .then((response) => {
        const insignias = orderBy(response.data.data.map((i: any) => Insignia.fromApi(i)), 'order', 'asc')
          .filter(i => !i.isDeleted);

        const insigniaFilter = params.insignia ? insignias.find(i => i.id === Number(params.insignia)) : null;
        this.setState({ insignias,
          insigniaFilter,
        });
      });
  }

  async getInsigniaGroups() {
    const tabs = await axios.get(`/api/brand-assets/tabs?client_id=${this.state.clientID}`);
    const insigniaGroups = orderBy(tabs.data.data.map((t: any) => {
      return {
        id: t.id,
        title: t.title,
        insignia: t.insignia.map((i: any) => Insignia.fromApi(i)),
      };
    }),                            g => g.insignia[0].order, 'asc');
    const params = queryString.parse(this.props.location.search);
    const selectedTab = params['tab'] ?
      insigniaGroups.find((i: any) => i.id === Number(params['tab'])) : null;
    this.setState({ insigniaGroups, selectedGroup: selectedTab });
  }

  sortBrandCategories(categories: CategoryBrands[]) {
    return categories.sort((a, b) => {
      if (b.category.title.toLowerCase() === 'style guide') {
        return 1;
      }
      if (a.category.title.toLowerCase() === 'style guide') {
        return -1;
      }
      if (Number(a.category.id) > Number(b.category.id)) {
        return 0;
      }

      return -1;
    });
  }

  onBrandModalSuccess(brand: BrandMarkAsset) {
    const brands = this.state.brands;
    const categoryIndex = brands.findIndex(category => category.category.id === brand.category.id);
    if (categoryIndex !== -1) {
      const brandIndex = brands[categoryIndex].brands.findIndex(b => b.id === brand.id);
      if (brandIndex !== -1) {
        brands[categoryIndex].brands[brandIndex] = brand;

      } else {
        brands[categoryIndex].brands.unshift(brand);
      }

    } else {
      brands.push({ category: brand.category, brands: [brand] });
    }
    this.setState({ brands: this.sortBrandCategories(brands) });

  }

  deleteBrand(brand: BrandMarkAsset) {
    const brands = this.state.brands;
    const categoryIndex = brands.findIndex(category => category.category.id === brand.category.id);
    if (categoryIndex !== -1) {
      brands[categoryIndex].brands = brands[categoryIndex].brands.filter(b => b.id !== brand.id);
      this._deleteAssetSource = axios.CancelToken.source();
      deleteBrandAsset(brand.id, this._deleteAssetSource)
        .then(() => {
          this.setState({ brands });
        });
    }
  }

  showCreateBrandMarkModal(category: any) {
    this.setState({ createModalShown: true, addCategory: category });
  }

  closeCreateBrandMarkModal() {
    this.setState({ createModalShown: false, selectedBrand: null });
  }

  closeDeleteBrandMarkModal() {
    this.setState({ deleteModalShown: false, selectedBrand: null });
  }

  showEditBrandMarkModal(brand: BrandMarkAsset) {
    this.setState({ createModalShown: true, selectedBrand: brand, addCategory: brand.category });
  }

  showDeleteBrandMarkModal(brand: BrandMarkAsset) {
    this.setState({ deleteModalShown: true, selectedBrand: brand });
  }

  selectTab(pill: Pill) {
    const baseUrl = this.props.location.pathname;
    const currentParams = this.getCurrentQueryParams();
    if (pill.id === 'all') {
      currentParams.tab = null;
      const cleaned = omitBy(currentParams, isNil);
      this.setState({ selectedGroup: null });
      this.props.history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
    } else {
      const group = this.state.insigniaGroups.find(g => g.id === pill.id);
      if (group) {
        currentParams.tab = group.id;
        this.setState({ selectedGroup: group });
        this.props.history.replace(`${baseUrl}?${queryString.stringify(currentParams)}`);
      }
    }
  }

  search(result: any[]) {
    const baseUrl = this.props.location.pathname;
    const currentParams = this.getCurrentQueryParams();
    if (result.length) {
      if (result[0].customOption) {
        this.setState({ insigniaFilter: null, search: result[0].title });
        currentParams.insignia = null;
        currentParams.search = result[0].title;

      } else {
        const findInsignia = this.state.insignias.find(i => i.id === result[0].id);
        if (findInsignia) {
          currentParams.insignia = findInsignia.id;
          currentParams.search = null;
          this.setState({ insigniaFilter: findInsignia, search: '' });
        }

      }
    } else {
      currentParams.insignia = null;
      currentParams.search = null;
      this.setState({ insigniaFilter: null, search: '' });
    }

    const cleaned = omitBy(currentParams, isNil);
    this.props.history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);

  }

  sortAssets(assets: BrandMarkAsset[], category: any) {
    if (category.type === 'image') {
      return orderBy(
        assets,
        [a => a.insignia ? a.insignia.order : 999, 'isPrimary', 'title'],
        ['asc', 'desc', 'asc'],
      );

    }
    return orderBy(
      assets,
      [a => a.insignia ? a.insignia.order : 999, 'isPrimary'],
      ['asc', 'desc'],
    );

  }

  getCurrentQueryParams() {
    const current = queryString.parse(this.props.location.search);
    return current;
  }

  render() {

    const user: UserType = this.context;

    let filteredInsignias: Insignia[] = [];
    if (this.state.insigniaFilter) {
      filteredInsignias = Insignia.createDescendantTree(this.state.insigniaFilter, this.state.insignias);
      console.log(filteredInsignias);
    }

    const categoryBrandList = this.state.brands
      .filter(c => this.state.typeFilter === 'all' || c.category.title === this.state.typeFilter)
      .map((brand) => {
        const sortedAssets = this.sortAssets(brand.brands, brand.category);
        const brands = sortedAssets
          .filter((b) => {
              let isVisible = true;
            if (b.insignia && user.type === 'vendor') {
              const allAncestors = Insignia.createAncestorTree(b.insignia, this.state.insignias);

              for (let i = 0; i < allAncestors.length; i++) {
                const ins = allAncestors[i];
                if (ins.isVendorSpecific) {
                  const ids = ins.vendors.map(v => Number(v.id));
                  if (!ids.includes(Number(user.account.id))) {
                    isVisible = false;
                  }
                }
              }
            }
            return isVisible;
          })
          .filter(b => !filteredInsignias.length || (b.insignia && filteredInsignias.map(i => i.id).includes(b.insignia.id)))
          .filter(b => !this.state.search || b.matchSearch(this.state.search))
          .filter(b => this.state.selectedGroup ?
            (b.insignia && this.state.selectedGroup.insignia.map(i => i.id).includes(b.insignia.id)) : true);

        return {
          brands,
          category: brand.category,
        };

      });

    const results = categoryBrandList.map((c) => {
      return {
        category: c.category,
        count: c.brands.length,
      };
    });

    const categoryContainers = categoryBrandList.map((c) => {
      const brands = c.brands.map((brand: BrandMarkAsset, index: number) =>
          brand.category.type === BrandCategoryType.COLOR || brand.category.type === BrandCategoryType.IMAGE
        || brand.category.type === BrandCategoryType.TEXT || brand.category.type === BrandCategoryType.FONT ? (
          <UserContext.Consumer key={brand.id} >
            { user =>
              <BrandAsset
                key={brand.id}
                deleteBrand={this.deleteBrand}
                brand={brand}
                index={index}
                categories={this.state.categories}
                edit={this.showEditBrandMarkModal}
                delete={this.showDeleteBrandMarkModal}
                user={user}

              />
             }
        </UserContext.Consumer>
        ) : null);

      if (!brands.length) {
        return null;
      }
      return (
        <div key={c.category.id}>
          <div className="brand-category-header">
            <h5 style={{ color: '#808080' }}><strong>{c.category.title}</strong></h5>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }} className="row" key={c.category.id}>
            {brands.length ? brands : (
              <div className="col-xs-12">
                <div className="panel panel-portal">
                  <div className="panel-body text-center">
                    <strong className="text-center text-muted">No {c.category.title.toLowerCase()} added yet.</strong>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );

    });

    let tabs: Pill[] = [{ id: 'all', label: 'All' }];
    tabs = tabs.concat(this.state.insigniaGroups.filter((g) => {
      const parent = g.insignia[0];
      console.log('parent', parent);
      if (parent.isVendorSpecific && user.type === 'vendor') {
        const ids = parent.vendors.map(v => Number(v.id));
        if (ids.includes(Number(user.account.id))) {
          return true;
        }
        return false;

      }
      return true;
    }).map((g) => {
      return {
        id: g.id,
        label: g.title,
      };
    }));

    const selectedTab: Pill = this.state.selectedGroup ?
      { id: this.state.selectedGroup.id, label: this.state.selectedGroup.title } : { id: 'all', label: 'All' };

    const tabValidation = Yup.object().shape({
      title: Yup.string().required('Title is required.'),
      insignia: Yup.array().required('Insignia is required.'),
    });

    let main;
    if (this.state.loading) {
      main = <LoadingSpinner />;
    } else {
      main = (
        <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {console.log(this.state.insignias)}
              <Typeahead
                id="insignia-search"
                clearButton={true}
                placeholder="Seach"
                options={this.state.insignias
                  .filter((insignia) => {
                    let isVisible = true;
                    if (user.type === 'vendor') {
                      const allAncestors = Insignia.createAncestorTree(insignia, this.state.insignias);

                      for (let i = 0; i < allAncestors.length; i++) {
                        const ins = allAncestors[i];
                        if (ins.isVendorSpecific) {
                          const ids = ins.vendors.map(v => v.id);
                          if (!ids.includes(Number(user.account.id))) {
                            isVisible = false;
                          }
                        }
                      }
                    }
                    return isVisible;
                  })
                  .filter((i) => {
                    const brands: BrandMarkAsset[] = this.state.brands.reduce((p: BrandMarkAsset[], c) => {
                      return p.concat(c.brands);
                    },                                                        []);

                    const count = brands.reduce((p, c) => {
                      if (c.insignia && c.insignia.id === i.id) {
                        return p + 1;
                      }
                      return p;
                    },                          0);
                    return count > 0;
                  })
                  .map((i) => {
                    return {
                      id: i.id,
                      title: i.title,
                    };
                  })}
                labelKey={'title'}
                allowNew={true}
                newSelectionPrefix="Search: "
                selectHintOnEnter={true}
                onChange={this.search}
                inputProps={{
                  style:{ width: 300 },
                }}
                selected={this.state.insigniaFilter ?
                  [{ id: this.state.insigniaFilter.id, title: this.state.insigniaFilter.title }] : this.state.search
                    ? [{ id: null, title: this.state.search }] : []
                }
              />
              <DropdownButton
                title="Filter By Type"
                id="filter-dropdown"
                style={{ marginLeft: 10 }}
                onSelect={(e: any) => {
                  const currentParams = this.getCurrentQueryParams();
                  if (e === 'all') {
                    currentParams.type = null;
                  } else {
                    currentParams.type = e;
                  }
                  const cleaned = omitBy(currentParams, isNil);
                  const baseUrl = this.props.location.pathname;
                  this.props.history.replace(`${baseUrl}?${queryString.stringify(cleaned)}`);
                  this.setState({ typeFilter: e });
                }}
              >
                <MenuItem active={this.state.typeFilter === 'all'} eventKey="all">All</MenuItem>
                <MenuItem active={this.state.typeFilter === 'Style Guide'} eventKey="Style Guide">Style Guide</MenuItem>
                <MenuItem active={this.state.typeFilter === 'Image'} eventKey="Image">Image</MenuItem>
                <MenuItem active={this.state.typeFilter === 'Color'} eventKey="Color">Color</MenuItem>
                <MenuItem active={this.state.typeFilter === 'Text'} eventKey="Text">Text</MenuItem>
                <MenuItem active={this.state.typeFilter === 'Font'} eventKey="Font">Font</MenuItem>

              </DropdownButton>

            </div>

            <div>
              {user.type !== 'vendor' ? <DropdownButton
                title="Add Asset"
                id="asset-dropdown"
                style={{ marginLeft: 10 }}
                onSelect={this.showCreateBrandMarkModal}
                bsStyle="primary"
              >
                {this.state.categories.map(c => <MenuItem eventKey={c}>{c.title}</MenuItem>)}
              </DropdownButton> : null}
            </div>

          </div>
          <hr />
          {/* <div className="text-center">
            <h4>Quick Download</h4>
            <p className="text-muted">
              Download all brand files in a single zip archive.
              <br /> The zip file will not include official colors, fonts, and text.
            </p>
            <a
              className="btn btn-default"
              target="_blank"
              href={`/api/brand-assets/downloads?licensor_id=${this.state.clientID}`}
            >
              <FontAwesomeIcon className="text-success" icon={faDownload} /> Download All
            </a>

          </div> */}
          <div style={{ display: 'flex', marginBottom: 20 }}>

            {this.state.insigniaGroups.length ? <PillTabs
              pills={tabs}
              active={selectedTab}
              onSelect={this.selectTab}

            /> : null}
            {user.type === 'admin' && this.state.insigniaGroups.length ? <div style={{ height: 32, borderRight: '1px solid #eee', marginRight: 15 }} /> : null}
            {user.type === 'admin' ? <div className="pill-tabs">
              <div onClick={() => this.setState({ addTab: true })} className="pill-tab">
                Add Tab
              </div>
            </div> : null}
            {this.state.selectedGroup && user.type === 'admin' ? <div className="pill-tabs">
              <div onClick={async () => {
                const d = confirm('Delete tab?');
                if (d && this.state.selectedGroup) {
                  const g = this.state.selectedGroup;
                  const groups = this.state.insigniaGroups;
                  const index = groups.findIndex(i => i.id === g.id);
                  if (index !== -1) {
                    groups.splice(index, 1);
                  }
                  this.setState({ selectedGroup: null, insigniaGroups: groups });
                  await axios.delete(`/api/brand-assets/tabs/${g.id}`);
                }

              }} className="pill-tab">
                <FontAwesomeIcon icon={faTrash} />
              </div>
            </div> : null}

          </div>

          <h5 style={{ color: '#808080', marginBottom: 20 }}>
            <strong>Results include {results.filter(r => r.count > 0).map(r => `${r.count} ${r.category.title}${r.count > 1 ? 's' : ''}`).join(', ')}.</strong>
          </h5>

          <div className="panel panel-portal">
            <div className="panel-body">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <h4 className="no-margin"><strong>Quick Download</strong></h4>
                  <p className="text-muted no-margin">Download all brand files in a single zip archive.</p>
                  <p className="text-muted no-margin">The zip file will not include official colors, fonts and text.</p>

                </div>

                <a
                  target="_blank"
                  href={`/api/brand-assets/downloads?licensor_id=${this.state.clientID}&${queryString.stringify(this.getCurrentQueryParams())}`}
                  className="btn btn-default"
                >
                  <FontAwesomeIcon className="text-success" icon={faDownload} /> Download All
                </a>
              </div>
            </div>
          </div>

          {categoryContainers}
          <BrandAssetModal
            shown={this.state.createModalShown}
            onClose={this.closeCreateBrandMarkModal}
            onSuccess={this.onBrandModalSuccess}
            title={this.state.selectedBrand ? this.state.selectedBrand.title : 'Create Brand Asset'}
            categories={this.state.categories}
            asset={this.state.selectedBrand}
            licensorId={this.state.clientID}
            insignias={this.state.insignias}
            category={this.state.addCategory}
          />
          <BrandAssetDeleteModal
            shown={this.state.deleteModalShown}
            onClose={this.closeDeleteBrandMarkModal}
            onDelete={this.deleteBrand}
            asset={this.state.selectedBrand}
          />
          <GatewayModal
            onClose={() => this.setState({ addTab: false })}
            shown={this.state.addTab}
            type={ModalType.Primary}
            title="Add Tab"
          >
            <Formik
              initialValues={{
                insignia: [],
                title: '',
              }}
              onSubmit={async (t) => {
                const data = {
                  title: t.title,
                  client_id: this.state.clientID,
                  insignia_id: t.insignia[0].id,
                };
                const tabs = await axios.post('/api/brand-assets/tabs', data);
                const insigniaGroups = orderBy(tabs.data.data.map((t: any) => {
                  return {
                    id: t.id,
                    title: t.title,
                    insignia: t.insignia.map((i: any) => Insignia.fromApi(i)),
                  };
                }),                            g => g.insignia[0].order, 'asc');
                this.setState({ insigniaGroups, addTab: false });
              }}
              validationSchema={tabValidation}

            >
              {(props: FormikProps<any>) => (
                <Form>
                  <Modal.Body>
                    <div className="form-group">
                      <label>Title</label>
                      <Field name="title" className="form-control" placeholder="Title" />
                      <p className="text-danger">
                        <ErrorMessage name="title" />
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Insignia</label>
                      <Typeahead
                        id="insignia-tab"
                        selected={props.values.insignia}
                        placeholder="Insignia"
                        options={this.state.insignias}
                        labelKey={i => i.title}
                        selectHintOnEnter={true}
                        multiple={false}
                        onChange={i => props.setFieldValue('insignia', i)}
                      />
                      <p className="text-danger">
                        <ErrorMessage name="insignia" />
                      </p>
                    </div>

                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => this.setState({ addTab: false })}
                      className="btn btn-default"
                    >
                      Cancel
                    </button>
                    <button className="btn btn-primary">Add Tab</button>
                  </Modal.Footer>
                </Form>
              )}

            </Formik>

          </GatewayModal>
        </div>
      );
    }
    return <DocumentTitle title={getAffinityPageTitle('Brand')}>
      <FullContent breadcrumbs={[{ name: 'Brand' }]}>
        {main}

      </FullContent>

      </DocumentTitle>;
  }

}
