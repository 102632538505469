import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface IResubmissionIndicator {
  resubmitted: boolean;
}

const ResubmissionIndicator = (props: IResubmissionIndicator) => {
  return props.resubmitted ? (
    <FontAwesomeIcon title="Resubmission" className="text-muted" icon={faSync} />
  ) : (
    <></>
  );
};

export default ResubmissionIndicator;
