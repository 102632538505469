import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStripe } from '@stripe/react-stripe-js';
import Axios from 'axios';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ACHVerificationModal } from './ACHVerificationModal';

interface IProps {
  amountDue?: number | string;
  accountId: number;
  onSuccess: () => void;
  toggleModify: (type: 'ach' | null) => void;
}

interface BankForm {
  accountHolder: string;
  accountHolderType: string;
  accountNumber: string;
  verify: string;
  routingNumber: string;
}

export const NewACHPayment = (props: IProps) => {
  const [adding, setAdding] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modalShown, setModalShown] = useState(false);
  const stripe = useStripe();

  useEffect(() => props.toggleModify(adding ? 'ach' : null), [adding]);

  const showModal = () => {
    setModalShown(true);
  };

  const closeModal = () => {
    setModalShown(false);
  };

  const modal = (
    <ACHVerificationModal shown={modalShown} onClose={closeModal} />
  );

  const onCancel = () => {
    setAdding(false);
    props.toggleModify(null);
  };

  const onSubmit = async (values: BankForm) => {
    setSaving(true);
    if (!stripe) {
      setSaving(false);
      return;
    }
    const token = await stripe.createToken('bank_account', {
      account_holder_name: values.accountHolder,
      account_holder_type: values.accountHolderType,
      currency: 'USD',
      country: 'US',
      account_number: values.verify,
      routing_number: values.routingNumber,
    });
    if (!token.token || !token.token.bank_account) {
      setSaving(false);
      return;
    }

    const accountURL = `/account/${props.accountId ? `${props.accountId}/` : ''}edit/add-payment-method`;
    const formData = new FormData();
    formData.append('uri', token.token.id);

    const account = await Axios.post(accountURL, formData)
      .then((response) => {
        console.log(response);
        return response.data;
      });

    if (account.success) {
      props.onSuccess();
    }

  };

  if (adding) {
    const initialValues: BankForm = {
      accountHolder: '',
      accountHolderType: '0',
      accountNumber: '',
      verify: '',
      routingNumber: '',
    };

    return (
      <div className="payment-method-panel">
        <div className="payment-option">
          <div className="payment-title-total">
            <span className="text-primary">Add Checking Account</span>
          </div>
          <p className="payment-description">Payment is debited from your checking account.</p>
          <p className="payment-fee">NO PROCESSING FEE</p>
          <p style={{ marginBottom: 20 }} className="help-block">
            Verifying a checking account can take 2-4 business days to complete.
            Two “ACCTVERIFY” deposits will appear in your checking account.
            You will need to confirm the amounts before you can pay with this account. &nbsp;
            <span style={{ cursor: 'pointer' }} onClick={showModal} className="text-primary">
              <FontAwesomeIcon icon={faInfoCircle} /> Learn More
            </span>
          </p>

                    <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: FormikProps<BankForm>) => (
              <Form>
                <div className="form-group">
                  <label>Account Holder Name</label>
                  <Field placeholder="Account Holder Name" name="accountHolder" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Account Holder Type</label>
                  <Field component="select" name="accountHolderType" className="form-control">
                    <option disabled value="0">Select Personal or Business</option>
                    <option value="individual">Personal</option>
                    <option value="company">Business</option>
                  </Field>
                </div>
                <img
                  style={{ marginBottom: 15, maxWidth: 400 }}
                  src="/assets/images/check-image.png"
                  className="img-responsive"
                />
                <div className="form-group">
                  <label>Routing Number</label>
                  <Field placeholder="Routing Number" name="routingNumber" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Account Number</label>
                  <Field placeholder="Account Number" name="accountNumber" className="form-control" />
                </div>
                <div className="form-group">
                  <label>Verify Account Number</label>
                  <Field placeholder="Verify Account Number" name="verify" className="form-control" />
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <button type="button" onClick={onCancel} className="btn btn-block btn-default payment-method-button">
                    Cancel
                  </button>
                  <button style={{ marginLeft: 5 }} className="btn btn-block btn-primary payment-method-button">
                    {saving ? 'Adding Account...' : 'Add Checking Account'}
                  </button>
                </div>

              </Form>
            )}

          </Formik>

        </div>
        {modal}

      </div>
    );

  }

  return (
    <div className="payment-method-panel">
      <div className="payment-option">
        <div className="payment-title-total">
          <span className="text-primary">Add Checking Account </span>

        </div>
        <p className="payment-description">Payment is debited from your checking account.</p>
        <p className="payment-fee">NO PROCESSING FEE</p>
        <p style={{ marginBottom: 20 }} className="help-block">
            Verifying a checking account can take 2-4 business days to complete.
            Two “ACCTVERIFY” deposits will appear in your checking account.
            You will need to confirm the amounts before you can pay with this account. &nbsp;
            <span style={{ cursor: 'pointer' }} onClick={showModal} className="text-primary">
              <FontAwesomeIcon icon={faInfoCircle} /> Learn More
            </span>
          </p>
        <button onClick={() => setAdding(true)} className="btn btn-block btn-default">Add Checking Account</button>
      </div>

      {modal}
    </div>
  );

};
