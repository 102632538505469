import { faExpand, faWindowMaximize } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import mapboxgl from 'mapbox-gl';
import * as querystring from 'query-string';
import * as React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { LayerInfo } from '../Audience';
import { LoadingSpinner } from './LoadingSpinner';
import { useMainContainer } from './useMainContaner';

interface IProps {
  loading: boolean;
  height: any;
  width: any;
  lat?: number;
  lng?: number;
  zoom?: number;
  onMapLoad?: () => void;
  isFullScreen: boolean;
  fullParams?: MapParams;
  useDefaultCenter: boolean;
}

export interface MapParams {
  withSales?: boolean;
  withAudience?: boolean;
  withRetailers?: boolean;
  audienceId?: number;
  channelIds?: number[];
  categoryIds?: number[];
  vendorIds?: number[];
  retailerIds?: number[];
  year?: number | string;
  quarter?: string;
  accountId?: number;

}

export const AffinityMap = React.forwardRef((props: IProps, ref) => {
  const mapContainer = React.useRef<any>(null);
  const map = React.useRef<any>(null);
  const mainContainer = useMainContainer();
  const history = useHistory();
  const routeParams = useParams();
  const user = React.useContext(UserContext);

  React.useEffect(() => {
    if (props.isFullScreen) {
      mainContainer.addClass('with-map');
    }
    initMap();

  },              []);

  React.useImperativeHandle(ref, () => ({
    setCenter,
    setZoom,
    addHeatmapLayer,
    addChoroplethLayer,
    addMarkerLayer,
    map: map.current,
    removeLayers: removeAllLayers,

  }));

  const initMap = () => {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYWZmaW5pdHlsaWNlbnNpbmciLCJhIjoiY2w1dTFkdjdlMDA4NTNqbnh6YXNnbnh4NyJ9.ZUvL4sQRU3oJrZYa_UCi6A';
    map.current = new mapboxgl.Map({
      zoom: props.zoom ? props.zoom :2.75,
      container: mapContainer.current,
      projection: {
        name: 'mercator',
      },
      style: 'mapbox://styles/affinitylicensing/cl5u1lsby000216qp6p3p26pq',
      center: [props.lng ? props.lng : -98.583333, props.lat ? props.lat : 39.833333],
    });

    if (props.useDefaultCenter) {
      getCenterData();
    }

    map.current.on('load', () => {
      if (props.onMapLoad) {
        props.onMapLoad();
      }
    });

  };

  const getCenterData = async () => {
    let params;
    if (getAccountID()) {
      params = {
        account_id: getAccountID(),
      };
    }
    const p = await Axios.get('/api/map-defaults', { params });
    const data = p.data.data;
    if (data) {
      setZoom(data.zoom);
      setCenter(data.longitude, data.latitude);
    }

  };

  const getAccountID = () => {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  };

  const removeAllLayers = (layers: LayerInfo[]) => {
    layers.forEach((l) => {
      if (map.current.getLayer(l.layerName)) {
        map.current.removeLayer(l.layerName);
      }
      if (map.current.getSource(l.sourceName)) {
        map.current.removeSource(l.sourceName);
      }
    });

  };

  const setCenter = (lng: number, lat: number) => {
    map.current.setCenter([lng, lat]);
  };

  const setZoom = (zoom: number) => {
    map.current.setZoom(zoom);
  };

  const viewFull = () => {
    if (props.fullParams) {
      const params = props.fullParams;
      const mapParams = {
        channels: params.channelIds || null,
        categories: params.categoryIds || null,
        retailers: params.retailerIds || null,
        vendors: params.vendorIds || null,
        year: params.year || null,
        quarter: params.quarter ? `Q${params.quarter}` : null,
        withSales: params.withSales ? 1 : null,
        withAudience: params.withAudience ? 1 : null,
        withRetailers: params.withRetailers ? 1 : null,
        audience: params.audienceId || null,
      };
      const cleaned = omitBy(mapParams, isNil);
      const qs = querystring.stringify(cleaned);

      if (user.type === 'client') {
        history.push(`/map?${qs}`);
      } else {
        if (params.accountId) {
          history.push(`/clients/${params.accountId}/map?${qs}`);
        }

      }

    }

  };

  const addHeatmapLayer = (info: LayerInfo) => {
    const colorOptions = {
      blue: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(189, 72%, 77%)',
        0.67,
        'hsl(194, 37%, 56%)',
        1,
        'hsl(197, 47%, 36%)',
      ],
      gray: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(0, 0%, 100%)',
        0.67,
        'hsl(0, 0%, 56%)',
        1,
        'hsl(0, 0%, 18%)',
      ],
      green: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(100, 82%, 85%)',
        0.67,
        'hsl(102, 43%, 52%)',
        1,
        'hsl(100, 98%, 24%)',
      ],
      orange: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(38, 84%, 83%)',
        0.67,
        'hsl(25, 79%, 65%)',
        1,
        'hsl(23, 96%, 42%)',
      ],
      pink: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(295, 78%, 73%)',
        0.67,
        'hsl(280, 83%, 68%)',
        1,
        'hsl(273, 89%, 60%)',
      ],
      purple: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(283, 64%, 81%)',
        0.67,
        'hsl(275, 84%, 69%)',
        1,
        'hsl(273, 90%, 51%)',
      ],
      red: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(29, 100%, 74%)',
        0.67,
        'hsl(11, 91%, 49%)',
        1,
        'hsl(0, 100%, 24%)',
      ],
      yellow: [
        'interpolate',
        ['exponential', 1],
        ['heatmap-density'],
        0,
        'hsla(0, 0%, 0%, 0)',
        0.33,
        'hsl(56, 77%, 83%)',
        0.67,
        'hsl(54, 63%, 64%)',
        1,
        'hsl(56, 100%, 38%)',
      ],
    };
    const defaultColor = 'gray';
    let heatmapColor = info.mapColor ? info.mapColor : defaultColor;

    if (! colorOptions[heatmapColor]) {
      heatmapColor = defaultColor;
    }
    map.current.addLayer(
      {
        id: info.layerName,
        type: 'heatmap',
        source: info.sourceName,
        minzoom: info.minZoom,
        maxzoom: info.maxZoom,
        visibility: 'visible',
        paint: {
          // increase weight as diameter breast height increases
          'heatmap-weight': {
            property: 'value',
            type: 'exponential',
            stops: [
              [1, 0],
              [info.max, 1],
            ],
          },
          // increase intensity as zoom level increases
          'heatmap-intensity': {
            stops: [
              [5, .5],
              [11, 2],
              [15, 3],
            ],
          },
          // assign color values be applied to points depending on their density
          'heatmap-color': colorOptions[heatmapColor],
          // increase radius as zoom increases
          'heatmap-radius': {
            stops: [
              [7, 15],
              [11, 20],
            ],
          },
          'heatmap-opacity': {
            default: .8,
            stops: [
              [14, .8],
              [15, .6],
            ],
          },
        },
      },
      'waterway-label',
    );
  };

  const addMarkerLayer = (info: LayerInfo) => {
    const colorOptions = ['blue', 'gray', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'];
    let markerColor = info.mapColor;

    if (info.mapColor && !colorOptions.includes(info.mapColor)) {
      markerColor = 'gray';
    }
    map.current.loadImage(
      `/assets/images/marker-icons/mapbox-marker-icon-20px-${markerColor}.png`,
      (error: any, image: any) => {
        if (error) throw error;
        map.current.addImage(`mapbox-marker-${markerColor}`, image);

        map.current.addLayer({
          id: info.layerName,
          type: 'symbol',
          source: info.sourceName,
          minzoom: info.minZoom,
          maxzoom: info.maxZoom,
          visibility: 'visible',
          layout: {
            'icon-image': `mapbox-marker-${markerColor}`,
            'icon-allow-overlap': true,
          },
        });
      });
  };

  const addChoroplethLayer = (info: LayerInfo, max: number, max2: number) => {

    map.current.addLayer(
      {
        id: info.layerName,
        source: info.sourceName,
        minzoom: info.minZoom,
        maxzoom: info.maxZoom,
        visibility: 'visible',
        type: 'fill',
        paint: {
          'fill-color': [
            'interpolate',
            ['exponential', 1],
            // ['linear'],
            ['get', 'count'],
            0,
            'hsla(0, 0%, 0%, 0)',
            1,
            'hsl(210,22%,84%)',
            max2,
            'hsl(198,39%,45%)',
            max,
            'hsl(201,56%,34%)',
          ],
          'fill-opacity': 0.75,
          'fill-outline-color': '#FFFFFF',
        },
      },
      'waterway-label',
    );
  };

  return (
    <div style={{ height: props.height, width: props.width, position : 'relative' }}>
      {props.loading ? <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 10, background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <LoadingSpinner />
      </div> : null}
      <div style={{ height: '100%', width: '100%' }} ref={mapContainer}></div>
      {props.fullParams ? <div className="affinity-map-expand">
        <button onClick={viewFull} className="btn btn-primary">
          <FontAwesomeIcon icon={faExpand} /> Expand Map

        </button>
      </div> : null}
    </div>
  );
});
