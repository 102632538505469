import * as moment from 'moment';
import {
  MarketplaceOrderLineItemTracking,
  MarketplaceOrderLineItemTrackingApiResponse,
} from './MarketplaceOrderLineItemTracking';
import { MarketplaceProduct, MarketplaceProductApiResponse } from './MarketplaceProduct';

export enum LineItemStatus {
  Canceled = 'Refunded',
  Shipped = 'Shipped',
  Pending = 'Pending',
}

interface MarketplaceRefund {
  id: number;
  createdAt: moment.Moment;
  quantity: number;
  orderVendorRefund: { subtotal:string, tax: string, shipping: string };
}

const refundFromApi = (data: any) => {
  return {
    id: data.id,
    createdAt: moment(data.created_at),
    quantity: data.quantity,
    orderVendorRefund: data.order_vendor_refund ? {
      subtotal: data.order_vendor_refund.subtotal_amount,
      tax: data.order_vendor_refund.tax_amount,
      shipping: data.order_vendor_refund.shipping_amount,
    } : null,
  };
};

export interface MarketplaceOrderLineItemApiResponse {
  id: number;
  shipped_at: string;
  quantity: number;
  price: string;
  shipping_price: string;
  option_1_title: string;
  option_1_value: string;
  option_2_title: string;
  option_2_value: string;
  option_3_title: string;
  option_3_value: string;
  return_policy: string;
  delivery_policy: string;
  expected_fulfilled_at: string;
  full_unit_price: string;
  unit_price: string;
  product: MarketplaceProductApiResponse;
  refunds: any;
  tracking: MarketplaceOrderLineItemTrackingApiResponse | null;
  shopify_order_id: number | null;
  customization: string;
}

interface IOrderOption {
  name: string;
  value: string;
}

export class MarketplaceOrderLineItem {
  id: number;
  shippedAt: null | moment.Moment;
  quantity: number;
  price: string;
  shippingPrice: string;
  options: IOrderOption[];
  returnPolicy: string;
  deliveryPolicy: string;
  expectedFulfilledAt: null | moment.Moment;
  fullUnitPrice: string;
  unitPrice: string; // includes sale value
  product: MarketplaceProduct;
  refunds: MarketplaceRefund[];
  tracking: MarketplaceOrderLineItemTracking | null;
  taxRate = 0;
  shopifyOrderId: number | null;
  customization: string;

  static fromApi(data: MarketplaceOrderLineItemApiResponse) {
    const lineItem = new MarketplaceOrderLineItem();
    lineItem.id = data.id;
    lineItem.shippedAt = data.shipped_at ? moment(data.shipped_at) : null;
    lineItem.quantity = data.quantity;
    lineItem.price = data.price;
    lineItem.shippingPrice = data.shipping_price;
    lineItem.returnPolicy = data.return_policy;
    lineItem.deliveryPolicy = data.delivery_policy;
    lineItem.expectedFulfilledAt = data.expected_fulfilled_at ? moment(data.expected_fulfilled_at) : null;
    lineItem.fullUnitPrice = data.full_unit_price;
    lineItem.unitPrice = data.unit_price;
    lineItem.product = MarketplaceProduct.fromApi(data.product);
    lineItem.refunds = data.refunds.map(refundFromApi);
    lineItem.tracking = data.tracking ? MarketplaceOrderLineItemTracking.fromApi(data.tracking) : null;

    lineItem.options = [1, 2, 3]
      .map(i => ({
        name: data[`option_${i}_title`],
        value: data[`option_${i}_value`],
      })).filter(option => option.name)
    ;
    lineItem.shopifyOrderId = data.shopify_order_id;

    lineItem.customization = data.customization;

    return lineItem;
  }

  get status() {
    if (this.refundableCount() === 0) {
      return LineItemStatus.Canceled;
    }
    if (this.shippedAt !== null && this.shippedAt.isValid()) {
      return LineItemStatus.Shipped;
    }
    return LineItemStatus.Pending;
  }

  refundableCount() {

    return this.quantity - this.refundedQuantity;
  }

  hasRefunds() {
    return this.refunds.length > 0;
  }

  get subTotalRefunded() {
    return this.refundedQuantity * Number(this.unitPrice);
  }

  get refundedQuantity() {
    return this.refunds
      .map(refund => refund.quantity)
      .reduce((acc, amt) => acc + amt, 0);
  }
}
