import { groupBy, map } from 'lodash';
import * as React from 'react';
import { Highlighter, Menu, MenuItem, Typeahead } from 'react-bootstrap-typeahead';
import { AffinityClient } from '../../shared';

interface IProps {
  multiple: boolean;
  licensors: AffinityClient[];
  selectedLicensors: AffinityClient[];
  id: string;
  onChange: (licensors: AffinityClient[]) => void;
}

export class LicensorSelect extends React.Component<IProps, any> {

  _typeahead: any;

  constructor(props: any) {
    super(props);
    this.clearFieldOnFocus = this.clearFieldOnFocus.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  clearFieldOnFocus(event: any) {
    if (!this.props.multiple) {
      event.target.value = '';
    }
  }

  onChange(licensors: AffinityClient[]) {
    this.props.onChange(licensors);
    if (!this.props.multiple && licensors.length) {
      this._typeahead.blur();
    }

  }

  render() {
    return (
      <Typeahead
        id={this.props.id}
        placeholder={this.props.multiple ? 'Licensors' : 'Licensor'}
        renderMenu={this.renderMenu}
        selected={this.props.selectedLicensors}
        options={this.props.licensors}
        onFocus={this.clearFieldOnFocus}
        multiple={this.props.multiple}
        onChange={this.onChange}
        labelKey={(label: any) => label.name}
        paginate={false}
        maxResults={this.props.licensors.length}
        ref={ref => this._typeahead = ref}
      />
    );
  }

  renderMenu(licensors: AffinityClient[], menuProps: any) {
    let index = 0;
    const grouped = groupBy(licensors.filter(l => l.market), l => l.market.name);
    const items = Object.keys(grouped).sort().map(market => [
      !!index && <Menu.Divider key={`${market}-divider`} />,
      <Menu.Header key={`${market}-header`}>
        {market}
      </Menu.Header>,
      map(grouped[market], (state) => {
        const item =
          <MenuItem key={index} option={state} position={index}>
            <Highlighter search={menuProps.text}>
              {state.name}
            </Highlighter>
          </MenuItem>;

        index++;
        return item;
      }),
    ]);

    return <Menu {...menuProps}>{items}</Menu>;

  }

}
