import * as moment from 'moment';

export interface MarketplaceOrderVendorApiResponse {
  id: number;
  tax_amount: number;
  shipping_amount: number;
  total_paid_amount: number;
  tax_rate: number;
  vendor_id: number;
  refunds: MarketplaceOrderVendorRefundApiResponse[] | undefined;
}

interface MarketplaceOrderVendorRefundApiResponse {
  created_at: string;
  subtotal_amount: number;
  tax_amount: number;
  shipping_amount: number;
}

class MarketplaceOrderVendorRefund {
  createdAt: moment.Moment;
  subtotalAmount: number;
  taxAmount: number;
  shippingAmount: number;

  static fromApi(data: MarketplaceOrderVendorRefundApiResponse): MarketplaceOrderVendorRefund {
    const refund = new MarketplaceOrderVendorRefund();
    refund.createdAt = moment(data.created_at);
    refund.subtotalAmount = Number(data.subtotal_amount);
    refund.taxAmount = Number(data.tax_amount);
    refund.shippingAmount = Number(data.shipping_amount);

    return refund;
  }
}

export class MarketplaceOrderVendor {
  id: number;
  taxAmount: number;
  shippingAmount: number;
  totalPaidAmount: number;
  taxRate: number;
  vendorId: number;
  refunds: MarketplaceOrderVendorRefund[];

  static fromApi(data: MarketplaceOrderVendorApiResponse): MarketplaceOrderVendor {
    const orderVendor = new MarketplaceOrderVendor();
    orderVendor.id = Number(data.id);
    orderVendor.taxAmount = Number(data.tax_amount);
    orderVendor.shippingAmount = Number(data.shipping_amount);
    orderVendor.totalPaidAmount = Number(data.total_paid_amount);
    orderVendor.taxRate = Number(data.tax_rate);
    orderVendor.vendorId = Number(data.vendor_id);
    orderVendor.refunds = data.refunds ? data.refunds.map(r => MarketplaceOrderVendorRefund.fromApi(r)) : [];

    return orderVendor;
  }
}
