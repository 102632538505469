export interface NoteThreadCategoryApiResponse {
  id: number;
  name: string;
  for_clients: boolean;
  for_vendors: boolean;
}

export class NoteThreadCategory {
  id: number;
  name: string;
  forVendors: boolean;
  forClients: boolean;

  static fromApi(data: NoteThreadCategoryApiResponse): NoteThreadCategory {
    return {
      id: data.id,
      name: data.name,
      forVendors: data.for_vendors,
      forClients: data.for_clients,
    };
  }
}
