import { faShopify } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import {
  createThread,
  IShippingCarrier,
  refundMarketplaceLineItem,
} from '../../api';
import { UserContext } from '../../contexts';
import { MarketplaceProduct } from '../../shared';
import { MarketplaceOrder } from '../../shared/MarketplaceOrder';
import { LineItemStatus, MarketplaceOrderLineItem } from '../../shared/MarketplaceOrderLineItem';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { CurrencyLabel, GatewayModal, LoadingSpinner, ModalType } from '../shared';

interface IShipFormValues {
  shippingCarrier: number | '';
  trackingCode: string;
}

const shipSelectedValidationSchema = Yup.object().shape({
  shippingCarrier: Yup.number().required('Please select a shipping carrier.'),
  trackingCode: Yup.string()
    .matches(/^(?!http.*$).*/, 'Please enter only the tracking code, not a URL.')
    .matches(/^((?!\/).)*$/, 'Please enter only the tracking code, not a URL.'),
});

export const OrderPage = () => {
  const [order, setOrder] = React.useState<MarketplaceOrder | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [policyModalShown, setPolicyModalShown] = React.useState(false);
  const [shippingModalShown, setShippingModalShown] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<MarketplaceOrderLineItem | null>(null);
  const [shippingCarriers, setShippingCarriers] = React.useState<IShippingCarrier[]>([]);
  const [messageModalShown, setMessageModalShown] = React.useState(false);
  const [refundModalShown, setRefundModalShown] = React.useState(false);
  const [cancelModalShown, setCancelModalShown] = React.useState(false);
  const [resyncModalShown, setResyncModalShown] = React.useState(false);
  const [shopifyDataModalShown, setShowShopifyDataModalShown] = React.useState(false);
  const [shopifyData, setShopifyData] = React.useState<any>(null);
  const [message, setMessage] = React.useState('');
  const [refundButtonEnabled, setRefundButtonEnabled] = React.useState(true);
  const [refunding, setRefunding] = React.useState(false);
  const [refundMessage, setRefundMessage] = React.useState('');
  const user = React.useContext(UserContext);
  const match = useRouteMatch();

  React.useEffect(() => {
    getOrder();
    getShippingCarriers();

  },              []);

  const getOrder = async () => {
    setLoading(true);
    const vendorParam = match && match.params['vendorId'] ? `?vendor_id=${match.params['vendorId']}` : '';
    const o = await Axios.get(`/api/marketplace/marketplaceOrders/${match ? match.params['id'] : ''}${vendorParam}`);
    setOrder(MarketplaceOrder.fromApi(o.data.data));
    setLoading(false);
  };

  const getShippingCarriers = async () => {
    const c = await Axios.get('/api/marketplace/shippingCarriers');
    setShippingCarriers(c.data.data.map((carrier: any) =>  {
      return {
        id: Number(carrier.id),
        name: carrier.name,
      };
    }));
  };

  const recieptItem = (title: string, value: number, refunded: number, showRefunded: boolean, bold: boolean) => {
    return (
      <div className={`order-reciept-item ${bold ? 'totals' : ''}`}>
        <div className="item">{title}</div>
        <div className="value"><CurrencyLabel value={value} /></div>
        {showRefunded ? <div className="refunded"><CurrencyLabel value={refunded} /></div> : null}
        {showRefunded ? <div className="remaining"><CurrencyLabel value={value - refunded} /></div> : null}
      </div>
    );
  };

  const setItemAndShow =
    (item: MarketplaceOrderLineItem, modal: 'policy' | 'shipping' | 'message' | 'refund' | 'cancel') => {
      setSelectedItem(item);
      switch (modal) {
        case 'policy':
          setPolicyModalShown(true);
          break;
        case 'shipping':
          setShippingModalShown(true);
          break;
        case 'message':
          setMessageModalShown(true);
          break;
        case 'refund':
          setRefundModalShown(true);
          break;
        case 'cancel':
          setCancelModalShown(true);
          break;
      }

    };

  const shipSelected = async (formValues: IShipFormValues, formikActions: FormikActions<IShipFormValues>) => {

    if (order && selectedItem && formValues.shippingCarrier) {
      const lineItemId = selectedItem.id;
      const shippingDetails = {
        line_item_id: Number(lineItemId),
        shipping_carrier_id: formValues.shippingCarrier,
        tracking_code: formValues.trackingCode,
      };

      await Axios.post(`/api/marketplace/marketplaceOrders/${order.id}/shipments`, shippingDetails);
      const items = order.lineItems;
      const index = items.findIndex(item => item.id === lineItemId);
      if (index !== -1) {
        items[index].shippedAt = moment();
      }
      order.lineItems = items;
      formikActions.setSubmitting(false);
      setShippingModalShown(false);
      setSelectedItem(null);
    }
  };

  const sendMessage = async () => {
    if (order) {
      await createThread(order.customer.id, message);
      setMessageModalShown(false);
      setMessage('');
    }
  };

  const fullRefund = async () => {

    if (order && selectedItem) {
      setRefundButtonEnabled(false);
      setLoading(true);
      await refundMarketplaceLineItem(
        order.id,
        selectedItem.id,
        selectedItem.quantity,
        Number(selectedItem.shippingPrice),
      );
      setCancelModalShown(false);
      setRefundButtonEnabled(true);
      getOrder();

    }
  };

  const refund = async (lineItem: MarketplaceOrderLineItem, quantity: number, shippingAmount: number = 0) => {

    if (order) {
      setRefundButtonEnabled(false);
      setRefunding(true);
      const r = await refundMarketplaceLineItem(
        order.id,
        lineItem.id,
        quantity,
        shippingAmount,
      ).catch(r => null);
      if (r) {
        setRefundModalShown(false);
        setRefundButtonEnabled(true);
        setRefunding(false);
        getOrder();
      } else {
        setRefundButtonEnabled(true);
        setRefunding(false);
        setRefundMessage('There was an error submitting the refund. Please try again later.');
      }

    }
  };

  const resubmitShopify = async (values: {email: string}) => {
    if (order) {
      const o = order;
      o.customer.email = values.email;
      setOrder(o);
      await Axios.post(`/api/marketplace/marketplaceOrders/${order.id}/resubmit-shopify`, { email: values.email });
      setResyncModalShown(false);
    }
  };

  const getShopifyData = async (product: MarketplaceProduct) => {
    setShopifyData(null);
    const d = await Axios.get(`/api/marketplace/products/${product.id}/get-shopify-data`);
    setShopifyData(d.data.data);
  };
  const showShopifyDataModal = (product: MarketplaceProduct) => {
    getShopifyData(product);
    setShowShopifyDataModalShown(true);
  };

  const refundSelected = (values: any) => {
    if (selectedItem) {
      refund(selectedItem, values.quantity, values.shippingAmount);
    }
  };

  const shopifyDataOptionsRender = () => {
    const f = shopifyData.options.filter((o: any) => o.name !== 'Title');
    if (f.length) {
      return (
        <>
          <h3><strong>Options</strong></h3>
          {f.map((o: any) => (
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <strong>{o.name}</strong>
              <br />
              {o.values.map((v: string) => (
                <div>
                  {v}
                </div>
              ))}
            </div>
          ))}
        </>
      );
    }
    return <></>;

  };

  let body;
  if (loading) {
    body = (
      <div className="panel panel-portal">
        <div className="panel-body">
          <LoadingSpinner />
        </div>
      </div>
    );
  } else {
    if (order) {
      const groupedSections = order.groupedLineItems.map((g) => {

        const orderVendor = order.orderVendors.find(ov => ov.vendorId === g.vendor.id);
        const shippingRefunded = orderVendor ? (
          orderVendor.refunds.map(r => r.shippingAmount).reduce((sum, s) => s + sum, 0)
        ) : 0;

        const lineItems = g.lineItems.map((item) => {
          const status = item.status;
          let statusLabel;
          let estimateLabel;
          if (status === LineItemStatus.Canceled) {
            statusLabel = (
              <h5 className="text-primary"><strong>Refund Processed</strong></h5>
            );

          } else if (status === LineItemStatus.Shipped && item.shippedAt) {
            statusLabel = (
              <h5 className="text-primary"><strong>Shipped on {item.shippedAt.format('MM/DD/YYYY')}</strong></h5>
            );
            estimateLabel = (
              <h5 className="text-muted">
                  Expected shipment {item.expectedFulfilledAt ? <span> on or before {item.expectedFulfilledAt.format('MM/DD/YYYY')} </span> : null}
              </h5>
            );

          } else {
            statusLabel = (
              <h5 className="text-primary">
                <strong>
                  Expected shipment {item.expectedFulfilledAt ? <span> on or before {item.expectedFulfilledAt.format('MM/DD/YYYY')} </span> : null}
                </strong>
              </h5>
            );
          }
          const lineItemTotal = Number(item.price);

          const shipButtonShown = (status === LineItemStatus.Pending);
          const refundButtonShown = (status !== LineItemStatus.Canceled);

          return (
            <div key={item.id} className="panel-body line-item">
              <div className="row">
                <div className="col-md-4">
                  <Link target="_blank" to={`/marketplace/products/${item.product.id}`}>
                    <img style={{ maxWidth: 200 }} className="img-responsive" src={item.product.primaryImage.urls.md} />
                  </Link>
                </div>
                <div className="col-md-5">
                  <h4><strong>{item.product.name}</strong></h4>
                  {estimateLabel}
                  {statusLabel}

                  <h5>
                    <strong>Quantity: {item.quantity}</strong>
                    {item.refundedQuantity ? (<span> ({item.refundedQuantity} refunded)</span>) : null}
                  </h5>
                  <p>
                    <strong>Subtotal: <CurrencyLabel value={lineItemTotal} /></strong>
                    {item.subTotalRefunded ?
                      (<span> (<CurrencyLabel value={item.subTotalRefunded} /> refunded)</span>) : null}
                  </p>
                  {item.options.map(option => (
                    <h5><strong>{option.name}</strong>: {option.value}</h5>
                  ))}
                  {item.customization ? <h5><strong>Customization Note</strong>: <span style={{ whiteSpace: 'pre-line' }}>{item.customization}</span></h5> : null}
                  {item.tracking && item.tracking.trackingCode ?
                    <h5>
                      <strong>Tracking Code: </strong>
                      <a target="_blank" href={item.tracking.url}>
                        {item.tracking.trackingCode}
                      </a>
                    </h5>
                    : null}
                  <a
                    onClick={() => setItemAndShow(item, 'policy')}
                    style={{ cursor: 'pointer' }}
                    className="text-primary"
                  >
                    Shipping And Return Policy
                </a>
                <div style={{ marginTop: 10 }}>
                  {item.product.isShopify ?
                      <FontAwesomeIcon
                        style={{ fontSize: 24 }}
                        className={item.shopifyOrderId ? 'text-success' : 'text-muted'}
                        icon={faShopify}
                        />
                      : null
                  }
                  {item.product.isShopify && user.type === 'admin' ?
                    <span onClick={() => showShopifyDataModal(item.product)} style={{ marginLeft: 10, cursor: 'pointer' }}>View Shopify Status</span>
                    : null}
                </div>

                </div>
                <div className="col-md-3">
                  {item.tracking && item.tracking.trackingCode ? (
                    <a
                      target="_blank"
                      href={item.tracking.url}
                      className="btn btn-block btn-primary"
                    >
                      Track Order
                    </a>
                  ) : null}
                  {shipButtonShown ? (
                    <button
                      className="btn btn-block btn-primary"
                      onClick={() => setItemAndShow(item, 'shipping')}
                    >
                      Confirm Shipment
                    </button>
                  ) : null}
                  {refundButtonShown ? (
                    <button
                      className="btn btn-block btn-default"
                      onClick={() => setItemAndShow(item, 'refund')}
                      disabled={!refundButtonEnabled}
                    >
                      Issue Refund
                    </button>
                  ) : null}
                  {!item.hasRefunds() && shippingRefunded === 0 ? (
                    <button
                      className="btn btn-block btn-default"
                      onClick={() => setItemAndShow(item, 'cancel')}
                    >
                      Cancel Item
                    </button>
                  ) : null}
                  <button
                    className="btn btn-block btn-default"
                      onClick={() => setItemAndShow(item, 'message')}
                  >
                    Message Customer

                  </button>

                </div>
              </div>

            </div>
          );
        });

        return (
          <div className="panel panel-portal">
            {user.type === 'admin' ? <div className="panel-heading">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to={`/vendors/${g.vendor.id}/marketplace/orders`}>
                  <img
                    style={{ width: 50, height: 50, marginRight: 15 }}
                    className="img-responsive"
                    src={g.vendor.image.getSize('sm')}
                  />
                </Link>
                <h5><strong>{g.vendor.legalName}</strong></h5>
              </div>

            </div> : null}
            <div className="marketplace-line-items">

              {lineItems}
            </div>
          </div>
        );
      });

      const vendorMap = order.uniqueVendors.reduce((map, vendor) => {
        map[Number(vendor.id)] = vendor;
        return map;
      },                                           {});

      const refundList = order.orderVendors.map((ov) => {
        const vendor = vendorMap[ov.vendorId];
        return ov.refunds.map((ovr) => {
          return (
            <p className="text-center no-margin">
              {user.type === 'admin' ? `${vendor.legalName}: ` : ''}
              <CurrencyLabel
                value={ovr.subtotalAmount + ovr.shippingAmount + ovr.taxAmount}
              /> refund processed on {ovr.createdAt ? ovr.createdAt.format('MM/DD/YYYY') : 'unknown date'}
            </p>
          );
        });
      }).reduce((acc, val) => acc.concat(val), []);
      body = (
        <div>
          <div className="panel panel-portal">
            <div className="panel-body">
              <h4><strong>Order #{order.id}</strong></h4>
              <div className="row">
                <div className="col-md-4">
                  <p className="no-margin text-muted">Date</p>
                  <p><strong>{order.createdAt.format('MM/DD/YYYY')}</strong></p>

                  <p className="text-muted no-margin">Total</p>
                  <p ><strong><CurrencyLabel value={order.total} /></strong></p>

                  <p className="text-muted no-margin">Retailer</p>
                  <p><a href={order.site ? `https://${order.site.domain}` : ''}>{order.site ? order.site.title : ''}</a> </p>
                </div>
                <div className="col-md-4">
                  <p className="text-muted no-margin">Customer</p>
                  <p className="no-margin"><strong>{order.customer.name}</strong></p>
                  <p><a href={`mailto:${order.customer.email}`}>{order.customer.email}</a></p>
                  <p className="text-muted no-margin">Billing Phone</p>
                  <p>{order.billingAddress.formattedPhone}</p>
                </div>
                <div className="col-md-4">
                  <p className="text-muted no-margin">Shipping Address</p>
                  <address>
                    <strong>
                      {order.shippingAddress.addressee}<br />
                      {order.shippingAddress.streetAddress} <br />
                      {order.shippingAddress.streetAddress2 ?
                        (<>{order.shippingAddress.streetAddress2} <br /></>) :
                        null
                      }
                      {order.shippingAddress.city}, {order.shippingAddress.state} {order.shippingAddress.zip}
                    </strong>
                  </address>

                  <p className="text-muted no-margin">Shipping Phone</p>
                  <p>{order.shippingAddress.formattedPhone}</p>
                </div>
              </div>
              <hr />
              <div>
                <div className="order-reciept-item titles">
                  <div className="item"></div>
                  <div className="value">Order</div>
                  {order.totalRefunded > 0 ? <div className="refunded">Refunded</div> : null}
                  {order.totalRefunded > 0 ? <div className="remaining">Remaining</div> : null}
                </div>
                {recieptItem('Subtotal', order.subtotal, order.subTotalRefunded, order.totalRefunded > 0, false)}
                {recieptItem('Shipping', order.shippingTotal, order.shippingRefunded, order.totalRefunded > 0, false)}
                {recieptItem('Taxes', order.taxesTotal, order.totalTaxRefunded, order.totalRefunded > 0, false)}
                {recieptItem('Total', order.total, order.totalRefunded, order.totalRefunded > 0, true)}
                <br />
                {order.totalRefunded > 0 ? (<div>
                  <hr />
                  <div>
                    <p className="text-center no-margin">
                      {refundList}
                    </p>
                  </div>

                </div>) : null}

              </div>

            </div>
          </div>

          {groupedSections}

          {user.type === 'admin' && order.hasShopify && !order.isShopifyFulfilled ? <div className="panel panel-portal">
            <div className="panel-body">
              <h5 className="text-center">Re-Sync Shopify Orders</h5>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button onClick={() => setResyncModalShown(true)} className="btn btn-primary">Resync Order</button>
              </div>
            </div>
          </div>  : null}

        </div>
      );
    }
  }

  let maxShippingRefund = 0;
  let shippingValidationMessage = 'Item not selected';

  if (selectedItem && order) {
    const orderVendor = order.orderVendors.find(ov => ov.vendorId === selectedItem.product.vendor.id);
    if (orderVendor) {
      const shippingRefunded = orderVendor.refunds
        .map(r => r.shippingAmount)
        .reduce((sum, s) => s + sum, 0);
      maxShippingRefund = orderVendor.shippingAmount - shippingRefunded;
      shippingValidationMessage = `Shipping Amount must be ${maxShippingRefund.toFixed(2)} or less`;
    }
  }

  const refundValidation = Yup.object().shape({
    quantity: Yup.number()
      .when('shippingAmount', {
          is: amount => Number(amount) > 0,
          then: Yup.number().notRequired(),
          otherwise: Yup.number().required().min(1, 'You must refund at least one item.'),
        },
      )
      .max(selectedItem ? selectedItem.refundableCount() : 0, selectedItem ? `You may only refund up to ${selectedItem.refundableCount()} units` : 'Too many units')
      .typeError('Quantity must be a number')
      .required(),
    shippingAmount: Yup.number()
      .typeError('Shipping Amount must be a number')
      .required('Shipping Amount is required, but can be $0.00')
      .max(maxShippingRefund, shippingValidationMessage),
  });

  const unitsRemaining = selectedItem ? selectedItem.refundableCount() : 0;

  return (
    <FullContent >
      <DocumentTitle title={getAffinityPageTitle(`Marketplace Order #${order ? order.id : ''}`)} />
      {body}

      <GatewayModal
        title="Resync Order"
        type={ModalType.Primary}
        shown={resyncModalShown}
        onClose={() => setResyncModalShown(false)}
      >
        <Formik
          onSubmit={resubmitShopify}
          initialValues={{
            email: order && order.customer.email,
          }}
          validationSchema={Yup.object().shape({ email: Yup.string().required('Email is required.').email('Must be a valid email') })}
        >
          {(formikProps: FormikProps<{email: string}>) =>
            <Form>
              <Modal.Body>
                <p className="text-muted text-center">
                  You can update the customers email if you think that is the reason the order failed.
                </p>
                <div className="form-group">
                  <label>Email</label>
                  <Field
                    className="form-control"
                    name="email"
                  />
                </div>
                <div className="text-danger">
                  <ErrorMessage name="email" />
                </div>
              </Modal.Body>
              < Modal.Footer >
                <button
                  type="button"
                  onClick={() => setResyncModalShown(false)}
                  className="btn btn-default pull-left"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" > Resync Order </button>
              </Modal.Footer>
            </Form>
          }
        </Formik>
      </GatewayModal>
      <GatewayModal
        title="View Shopify Status"
        type={ModalType.Primary}
        shown={shopifyDataModalShown}
        onClose={() => setShowShopifyDataModalShown(false)}
      >
        <Modal.Body>
          {shopifyData ? (
            <div>
              <span>Tracks Inventory: {shopifyData.tracksInventory ? 'Yes' : 'No'}</span>

              {shopifyDataOptionsRender()}

              <h3><strong>Variants</strong></h3>
              {shopifyData.variants.map((v: any) => (
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  {v.title !== 'Default Title' ? <div><strong>{v.title}</strong><br /></div> : null}
                  <span>Quantity: {v.inventoryQuantity}</span>
                  <br/>
                  <span>Allows Out Of Stock Sales: {v.allowsOutOfStockSales ? 'Yes' : 'No'}</span>
                </div>
              ))}
            </div>
          ) : <LoadingSpinner />}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowShopifyDataModalShown(false)} className="pull-right btn btn-default">Close</button>
        </Modal.Footer>

      </GatewayModal>
      <GatewayModal
        title="Shipping and Return Policies"
        type={ModalType.Info}
        shown={policyModalShown}
        onClose={() => setPolicyModalShown(false)}
      >
        <Modal.Body>
          <h5 className="text-muted">Shipping Information:</h5>
          <p>{selectedItem ? selectedItem.deliveryPolicy : ''}</p>
          <h5 className="text-muted">Return Policy:</h5>
          <p>{selectedItem ? selectedItem.returnPolicy : ''}</p>
        </Modal.Body>
        <Modal.Footer>
          <button className="pull-right btn btn-default" onClick={() => setPolicyModalShown(false)}>Close</button>
        </Modal.Footer>
      </GatewayModal>

      <GatewayModal
        title="Confirm Shipment"
        type={ModalType.Primary}
        shown={shippingModalShown}
        onClose={() => setShippingModalShown(false)}
      >
        <Formik
          onSubmit={shipSelected}
          initialValues={{
            shippingCarrier: '',
            trackingCode: '',
          }}
          validationSchema={shipSelectedValidationSchema}
        >
          {(formikProps: FormikProps<IShipFormValues>) =>
            <form onSubmit={formikProps.handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="order-page-shipping-carrier-select">Shipping Carrier</label>
                  <Field
                    id="order-page-shipping-carrier-select"
                    name="shippingCarrier"
                    component="select"
                    className="form-control"
                  >
                    <option value="">Select Shipping Carrier</option>
                    {shippingCarriers.map(carrier => (
                      <option
                        className="form-control input-sm"
                        key={carrier.id}
                        value={carrier.id}
                      >
                        {carrier.name}
                      </option>
                    ))}
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage name="shippingCarrier" />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="order-page-shipping-tracking-code">Tracking Code</label>
                  <Field
                    id="order-page-shipping-tracking-code"
                    className="form-control"
                    name="trackingCode"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="trackingCode" />
                  </div>
                </div>
              </Modal.Body>
              < Modal.Footer >
                <button
                  type="button"
                  onClick={() => setShippingModalShown(false)}
                  className="btn btn-default"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={formikProps.isSubmitting}
                >
                  Confirm Shipment
                </button>
              </Modal.Footer>
            </form>
          }
        </Formik>
      </GatewayModal>
      <GatewayModal
        title="Issue Refund"
        type={ModalType.Primary}
        shown={refundModalShown}
        onClose={() => setRefundModalShown(false)}
        size="small"
        backdrop={'static'}
      >
        <Formik
          onSubmit={refundSelected}
          initialValues={{
            shippingAmount: 0.00,
            quantity: 0,
          }}
          validationSchema={refundValidation}
        >

          {(formikProps: FormikProps<{ shippingAmount: string, quantity: string }>) => (
            <Form>
              <Modal.Body>
                {refundMessage ? (<div className="alert alert-danger text-center">{refundMessage}</div>) : null}
                <div className="form-group">
                  <label>
                    Quantity
                      </label>
                  <p className="text-muted">
                    {unitsRemaining} available to refund
                      </p>
                  <Field name="quantity" className="form-control" />
                  <p className="text-danger">
                    <ErrorMessage name="quantity" />
                  </p>
                </div>
                <div className="form-group">
                  <label>
                    Shipping Amount
                      </label>
                  <p className="text-muted">
                    <CurrencyLabel value={maxShippingRefund} /> available to refund
                      </p>
                  <div className="input-group">
                    <span className="input-group-addon">$</span>

                    <Field className="form-control" name="shippingAmount" />
                  </div>
                  <p className="text-danger">
                    <ErrorMessage name="shippingAmount" />
                  </p>
                  <br />

                  <div className="h5 text-center no-margin"><strong>Refund</strong> (excluding taxes)</div>
                  <p className="text-center">
                    <CurrencyLabel
                      value={selectedItem ?
                        Number(formikProps.values.quantity) * Number(selectedItem.unitPrice)
                        + Number(formikProps.values.shippingAmount) : 0
                      }
                    />
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setRefundModalShown(false)} className="btn btn-default pull-left">
                  Cancel
                    </button>
                <button
                  type="submit"
                  className="btn btn-primary pull-right"
                  disabled={!refundButtonEnabled}
                >
                  {refunding ? 'Issuing Refund...' : 'Issue Refund'}
                    </button>

              </Modal.Footer>

            </Form>

          )}
        </Formik>

      </GatewayModal>
      <GatewayModal
        title="Cancel Item"
        type={ModalType.Primary}
        shown={cancelModalShown}
        onClose={() => setCancelModalShown(false)}
      >
        <Modal.Body>
          <p className="text-center">Are you sure you want to cancel this item and issue a full refund?</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setCancelModalShown(false)} className="btn btn-default pull-left">Back</button>
          <button
            className="btn btn-primary"
            onClick={fullRefund}
            disabled={!refundButtonEnabled}
          >
            Cancel Item
          </button>

        </Modal.Footer>
      </GatewayModal>
      <GatewayModal
        title="Message Customer"
        type={ModalType.Primary}
        shown={messageModalShown}
        onClose={() => setMessageModalShown(false)}
      >
        <Modal.Body>
          <div className="form-group">
            <label>Message</label>
            <textarea
              onChange={e => setMessage(e.target.value)}
              value={message}
              name="message"
              cols={30} rows={5}
              className="form-control"
            >

            </textarea>

          </div>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setMessageModalShown(false)} className="btn btn-default">Cancel</button>
          <button onClick={sendMessage} className="btn btn-primary">Send Message</button>

        </Modal.Footer>
      </GatewayModal>
    </FullContent>

  );

};
