import { AccountProfileAPIResponse, ImageApiResponse } from '.';
import { AccountProfile } from './AccountProfile';
import { AffinityImage } from './Image';

interface AccountProfileUpdateRequestAPIResponse {
  id: number;
  is_pending: boolean;
  submits: number;
  name: string;
  short_name: string;
  public_short_name: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;

  public_address_line1: string;
  public_address_line2: string;
  public_city: string;
  public_state: string;
  public_zip: string;
  public_phone: string;

  description: string;

  account: AccountProfileAPIResponse;

  image: ImageApiResponse;

  differs: ProfileUpdateRequestDiffs;

  reason: string;
}

interface ProfileUpdateRequestDiffs {
  name: boolean;
  short_name: boolean;
  image_id: boolean;
  address_line1: boolean;
  address_line2: boolean;
  city: boolean;
  state: boolean;
  zip: boolean;
  phone: boolean;

  public_address_line1: boolean;
  public_address_line2: boolean;
  public_city: boolean;
  public_state: boolean;
  public_zip: boolean;
  public_phone: boolean;

  description: boolean;

}

export class AccountProfileUpdate {
  id: number;
  accountName: string; // To be used in the UI
  legalName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  publicAddressLine1: string;
  publicAddressLine2: string;
  publicCity: string;
  publicState: string;
  publicZip: string;
  publicPhone: string;
  description: string;

  diffs: ProfileUpdateRequestDiffs;

  account: AccountProfile;

  image: AffinityImage;

  reason: string;

  static fromApi(data: AccountProfileUpdateRequestAPIResponse) {
    const update = new AccountProfileUpdate();
    update.id = data.id;
    update.accountName = data.short_name;
    update.legalName = data.name;
    update.addressLine1 = data.address_line1;
    update.addressLine2 = data.address_line2;
    update.city = data.city;
    update.state = data.state;
    update.zip = data.zip;
    update.phone = data.phone;

    update.publicAddressLine1 = data.public_address_line1;
    update.publicAddressLine2 = data.public_address_line2;
    update.publicCity = data.public_city;
    update.publicState = data.public_state;
    update.publicZip = data.public_zip;
    update.publicPhone = data.public_phone;

    update.account = AccountProfile.fromApi(data.account);
    update.diffs = data.differs;
    update.reason = data.reason;
    if (data.image) {
      update.image = AffinityImage.fromApi(data.image);
    } else {
      update.image = new AffinityImage();
    }
    return update;
  }

  diffedAccount() {
    const t = this.account;
    if (this.diffs.name) {
      t.legalName = this.legalName;
    }
    if (this.diffs.short_name) {
      t.accountName = this.accountName;
    }
    if (this.diffs.address_line1) {
      t.businessAddress.line1 = this.addressLine1;
    }
    if (this.diffs.address_line2) {
      t.businessAddress.line2 = this.addressLine2;
    }
    if (this.diffs.city) {
      t.businessAddress.city = this.city;
    }
    if (this.diffs.state) {
      t.businessAddress.state = this.state;
    }
    if (this.diffs.zip) {
      t.businessAddress.zip = this.zip;
    }
    if (this.diffs.public_address_line1) {
      t.publicAddress.line1 = this.publicAddressLine1;
    }
    if (this.diffs.public_address_line2) {
      t.publicAddress.line2 = this.publicAddressLine2;
    }
    if (this.diffs.public_city) {
      t.publicAddress.city = this.publicCity;
    }
    if (this.diffs.public_state) {
      t.publicAddress.state = this.publicState;
    }
    if (this.diffs.public_zip) {
      t.publicAddress.zip = this.publicZip;
    }
    if (this.diffs.phone) {
      t.businessAddress.phone = this.phone;
    }
    if (this.diffs.public_phone) {
      t.publicAddress.phone = this.publicPhone;
    }

    if (this.diffs.description) {
      t.description = this.description;
    }

    if (this.diffs.image_id) {
      t.logo = this.image;
    }

    return t;

  }

}
