import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ProcessingFeeModal } from '.';
import { CCPaymentMethod } from '../../shared';
import { CurrencyLabel, GatewayModal, ModalType } from '../shared';

interface IProps {
  amountDue?: number | string;
  onSelect: () => void;
  onRemove?: () => void;
  card: CCPaymentMethod;
}

export const CreditCardPaymentStub = (props: IProps) => {
  const [deleteShown, setDeleteShown] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [modalShown, setModalShown] = useState(false);

  const card = props.card;

  const closeDeleteModal = () => {
    setDeleteShown(false);
  };
  const showDeleteModal = () => {
    setDeleteShown(true);
  };
  const confirmDelete = async () => {
    setDeleting(true);
    await Axios.delete(`/api/payment-methods/${props.card.id}`);
    if (props.onRemove) {
      props.onRemove();
    }
    closeDeleteModal();
  };
  const showModal = () => {
    setModalShown(true);
  };

  const hideModal = () => {
    setModalShown(false);
  };

  return (
    <div className="payment-method-panel">
      <div className="payment-option">
        <div className="payment-title-total">
          <span className="text-primary">{card.brand} ({card.lastFour})</span>
          {/* {props.amountDue ? <span><CurrencyLabel value={props.amountDue} /></span> : null} */}

        </div>
        <p className="payment-description">Payment is charged to your credit card.</p>

          <p className="payment-fee">
            {props.amountDue ? <CurrencyLabel value={card.fees.calculated / 100} /> : 'INCLUDES'} PROCESSING FEE
            &nbsp; <span onClick={showModal} className="text-primary fee-learn-more">
              <FontAwesomeIcon  icon={faInfoCircle} /> Learn More
            </span>
          </p>

        {props.onRemove ? (
          <p className="text-muted">This credit card was added on {props.card.createdAt.format('MM/DD/YYYY')}</p>

        ) : null}

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

          {props.onRemove ? (
            <button onClick={showDeleteModal} className="btn btn-block btn-default">
              Remove Payment Method
            </button>
          ) : null}
          {props.amountDue ? <button style={{ marginLeft: props.onRemove ? 5 : 0 }} onClick={props.onSelect} className="btn btn-block btn-primary payment-method-button">
            Pay <CurrencyLabel value={props.amountDue} /> with Card ({card.lastFour})
            </button> : null}
        </div>

      </div>

      <GatewayModal type={ModalType.Danger} shown={deleteShown} onClose={closeDeleteModal} title="Remove Payment Method" >
        <Modal.Body>
          Are you sure you want to delete this payment method?
          </Modal.Body>
        <Modal.Footer>
          <button onClick={closeDeleteModal} className="btn btn-default pull-left">
            Cancel
            </button>
          <button onClick={confirmDelete} className="btn btn-danger pull-right">
            {deleting ? 'Deleting...' : 'Yes, Delete Payment Method'}
          </button>
        </Modal.Footer>

      </GatewayModal>

      <ProcessingFeeModal isShown={modalShown} onClose={hideModal} />

    </div>
  );

};
