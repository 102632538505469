import { faMapMarkerAlt, faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UserContext } from '../../../contexts';
import { DesignActivity } from '../../../shared';
import { UserContactModal } from '../../shared';

interface IProps {
  activity: DesignActivity;
  onClick?: (activity: DesignActivity) => any;
}

export class DesignActivityPanel extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.activity);
    }
  }

  async downloadFile(event: React.MouseEvent, id: number, fileName: string) {
    event.preventDefault();

    try {
      const url = `/api/designs/${id}/comment/attachment`;

      const response = await axios.get(url, {
        responseType: 'blob',
      });

      const data = response.data;
      const downloadUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  }
  render() {
    const activity = this.props.activity;
    let activityMessage;

    if (activity.isActivity) {
      const reject = activity.phase && activity.phase.banner.includes('rejected');
      const labelClass = reject ? 'label-danger' : 'label-primary';

      activityMessage = (
        <div dangerouslySetInnerHTML={{ __html: activity.message }} className={`label ${labelClass}`}>
        </div>
      );
    } else {
      activityMessage = (<p dangerouslySetInnerHTML={{ __html: activity.message }} className="no-margin"></p>);
    }

    const tooltip = (<Tooltip id={String(activity.id)}>{activity.group}</Tooltip>);

    const dateTime = (
      <UserContext.Consumer>
        {user => user.type === 'admin' ? (
          <OverlayTrigger placement="bottom" overlay={tooltip}>
            <span>{activity.created.format('MMMM Do YYYY, h:mm a')}</span>
          </OverlayTrigger>
        ) : (
          <span>{activity.created.format('MMMM Do YYYY, h:mm a')}</span>
        )}
      </UserContext.Consumer>
    );

    return (
      <div className="panel panel-portal">
        <div className="panel-body">
          <UserContactModal user={activity.user} blockedUserTypes={['vendor']}>
            <h5 className="no-margin"><strong>{activity.user.fullName}</strong></h5>
          </UserContactModal>
          <p className="text-muted small">{activity.user.accountName}</p>
          {activityMessage}
          { activity.hasCoords ?
            <a onClick={this.onClick} style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faMapMarkerAlt} /> Go To Pin</a>
            : null
          }
          {activity.attachment ? (
            <a href="#"  style={{
              display: 'inline-block',
              maxWidth: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
               onClick={e => this.downloadFile(e, activity.id, activity.attachment.previous_file_name)}>
              <FontAwesomeIcon icon={faPaperclip}/> {activity.attachment.previous_file_name}
            </a>
          ) : null}

          <p style={{ marginTop: 5 }} className="text-muted small">
            {dateTime}
          </p>

        </div>
      </div>
    );
  }
}
