import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, FormikActions, FormikProps } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';
import { AffinityClient, ProductCategory } from '../../../shared';
import { LoadingSpinner } from '../../shared';
import { IAvailableProductEntry } from '../api';
import { IUploadedDesignEntry, UploadStatus } from './DesignUploadPage';
import { UploadItemForm } from './UploadItemForm';

interface IProps {
  item: IUploadedDesignEntry;
  licensors: AffinityClient[];
  products: IAvailableProductEntry[];
  isSavingAll: boolean;
  onSubmitFn: (designId: number, x: IUploadItemFormValues) => Promise<any>;
  onExpediteFn: (designId: number, expedite: boolean) => any;
  onDeleteFn: (designId: number) => any;
  onSavedAll: (designId: number) => any;
}

export interface IUploadItemFormValues {
  title: string;
  productEntry: ProductCategory[];
  organization: AffinityClient[];
  description: string;
}

const uploadValidation = Yup.object().shape({
  title: Yup.string().required('Name is required'),
  productEntry: Yup.array().min(1, 'A product category is required.').required('A product category is required.'),
  description: Yup.string(),
});

export class UploadItem extends React.Component <IProps, {}> {

  constructor(props: IProps) {
    super(props);
    this.submit = this.submit.bind(this);
    this.onClickExpedite = this.onClickExpedite.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  submit(values: IUploadItemFormValues, formikActions: FormikActions<IUploadItemFormValues>) {
    const designId = this.props.item.designId;

    if (designId) {
      this.props.onSubmitFn(designId, values).then(() => {
        formikActions.setSubmitting(false);
      });
    } else {
      formikActions.setSubmitting(false);
    }
  }

  onClickDelete(event: any): void {
    const designId = this.props.item.designId;

    if (designId === null) {
      throw 'Design ID is missing';
    }

    this.props.onDeleteFn(designId);
  }

  onClickExpedite(event: any): void {
    const designId = this.props.item.designId;

    if (designId === null) {
      throw 'Design ID is missing';
    }

    this.props.onExpediteFn(designId, !this.props.item.isExpedited);
  }

  render() {
    const uploadItem = this.props.item;

    const licensorOptions = this.props.licensors.map((client: AffinityClient) => ({ id: client.id, name: client.name }));
    const productOptions = this.props.products.map((product: IAvailableProductEntry) => {
      const tree = product.categoryTree;
      const lastTreeName = tree.length > 0 ? tree[tree.length - 1].name : '';
      return { id: product.categoryId, name: lastTreeName };
    });

    const initialValues = {
      title: uploadItem.title,
      productEntry: uploadItem.categories ? uploadItem.categories : [],
      organization: uploadItem.organization ? [uploadItem.organization] : [],
      description: '',
    };

    if (uploadItem.status === UploadStatus.REJECTED || uploadItem.status === UploadStatus.FAILEDUPLOAD) {

      const warningText = uploadItem.status === UploadStatus.REJECTED ? (
        'Image could not be uploaded. Check that it is a JPG, GIF, or PNG file, and under 4MB.'
      ) : (
        'Image failed to upload.'
      );

      return (
        <div className="alert alert-warning">
          <h4 className="alert-heading">{ uploadItem.file.name }</h4>
          <FontAwesomeIcon icon={faExclamationTriangle} />{' '}{warningText}
        </div>
      );
    }

    if (uploadItem.status === UploadStatus.UPLOADING) {
      return <div className="panel"><LoadingSpinner /></div>;
    }

    return (
      <Formik
        validationSchema={uploadValidation}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={this.submit}
      >
        {(formikProps: FormikProps<IUploadItemFormValues>) => (
          <UploadItemForm
              uploadItem={uploadItem}
              formikProps={formikProps}
              productOptions={productOptions}
              licensorOptions={licensorOptions}
              isSavingAll={this.props.isSavingAll}
              onSavedAll={this.props.onSavedAll}
              onClickExpedite={this.onClickExpedite}
              onClickDelete={this.onClickDelete}
          />
        )}
      </Formik>
    );
  }
}
