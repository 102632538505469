import { CancelTokenSource } from 'axios';
import axios from 'axios';
import { range } from 'lodash';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { createNewRoyaltyReport } from '../../../api';
import { formattedFiscalYear } from '../../../utils';
import { GatewayModal, ModalType } from '../../shared/modals';

interface IProps {
  finalYear: number;
  onClose: () => any;
  onSubmit?: () => any;
  shown: boolean;
  vendorId: number;
}

interface IState {
  selectedYear: number;
  selectedQuarter: number;
  submitting: boolean;
}

export class AddRoyaltyReportModal extends React.Component<IProps, IState> {

  protected _submitSource: CancelTokenSource;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      selectedYear: props.finalYear,
      selectedQuarter: 1,
      submitting: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeYear = this.onChangeYear.bind(this);
    this.onChangeQuarter = this.onChangeQuarter.bind(this);
  }

  public componentWillUnmount() {
    if (this._submitSource) {
      this._submitSource.cancel('Cancelled AddRoyaltyReportModal:onSubmit XHR due to unmount.');
    }
  }

  public onSubmit() {
    const params = {
      quarter: this.state.selectedQuarter,
      year: this.state.selectedYear,
      vendorId: this.props.vendorId,
    };
    this.setState({ submitting: true });
    if (this._submitSource) {
      this._submitSource.cancel('Cancelled AddRoyaltyReportModal:onSubmit XHR due to new request.');
    }
    this._submitSource = axios.CancelToken.source();
    createNewRoyaltyReport(params, this._submitSource)
      .then(() => {
        this.setState({ submitting: false });
        this.props.onClose();
        if (this.props.onSubmit) {
          this.props.onSubmit();
        }
      });
  }

  public onChangeYear(event: ChangeEvent<HTMLSelectElement>) {
    const year = event.target.value;
    this.setState({ selectedYear: parseInt(year, 10) });
  }

  public onChangeQuarter(event: ChangeEvent<HTMLSelectElement>) {
    const quarter = event.target.value;
    this.setState({ selectedQuarter: parseInt(quarter, 10) });
  }

  public render() {
    const yearOptions = range(0, 10).map((offset) => {
      const year = this.props.finalYear - offset;
      return {
        value: year,
        label: formattedFiscalYear(year),
      };
    });

    const quarterOptions = range(1, 5).map((quarter) => {
      return {
        value: quarter,
        label: `Q${quarter}`,
      };
    });

    return (
      <GatewayModal
        type={ModalType.Primary}
        onClose={this.props.onClose}
        shown={this.props.shown}
        title="Create Report"
      >
        <Modal.Body>
          <div className="text-center" style={{ fontSize: 16, margin: '15px 0', fontWeight: 'bold' }}>
            Select the quarter and year for the new report.
          </div>
          <div className="row">
            <div className="col-md-4 col-md-offset-4">
              <div className="form-group">
                <label>Quarter</label>
                <select onChange={this.onChangeQuarter} className="form-control">
                  {quarterOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Year</label>
                <select onChange={this.onChangeYear} className="form-control">
                  {yearOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClose}>Cancel</Button>
          <Button bsStyle="primary" onClick={this.onSubmit}>Create Report</Button>
        </Modal.Footer>
      </GatewayModal>
    );
  }
}
