import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios, { CancelTokenSource } from 'axios';
import * as moment from 'moment';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { AccountProfile, AccountProfileUpdate, AccountWebUrl } from '../../shared';
import { CenteredContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';
import { getCurrentAccountUpdate, getNewAccount } from './api';

interface IState {
  loading: boolean;
  account: AccountProfile;
  accountUpdate: AccountProfileUpdate | null;
  accountUrls: AccountWebUrl[];
}

export class AccountProfilePage extends React.Component<RouteComponentProps<any>, IState>  {

  _getAccountSource: CancelTokenSource;
  _getUpdateSource: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    const account = new AccountProfile();
    account.id = this.accountID;
    this.state = {
      account,
      loading: true,
      accountUpdate: null,
      accountUrls: [],
    };
    this.getAccount = this.getAccount.bind(this);
    this.createSettingsLink = this.createSettingsLink.bind(this);
    this.primaryContactLink = this.primaryContactLink.bind(this);
  }

  componentDidMount() {
    this.getAccount();
  }

  settingsLinks(type: 'client' | 'vendor', isAdmin: boolean) {
    switch (type) {
      case 'client':
        return (
          <div>
            {isAdmin ? (
              <div>
                <Link to={this.createNormalLink('design-guidelines')}>Design Review Guidelines</Link><br /><br />
              </div>
            ) : null}
            <Link to={this.createSettingsLink('designs')}>Design Responses</Link><br /><br />
            <Link to={this.createSettingsLink('web-links')}>Web Links</Link><br /><br />
            {isAdmin ? (
              <div>
                <Link to={this.createNormalLink('royaltyschedules')}>Royalty Schedules</Link><br /><br />
                <Link to={this.createNormalLink('rules')}>License Rules</Link><br /><br />
                <Link to={this.createSettingsLink('templates')}>Template Settings</Link><br /><br />
                <Link to={this.createSettingsLink('market')}>Market Settings</Link><br /><br />
                <Link to={this.createSettingsLink('admin')}>Admin Settings</Link>
              </div>

            ) : null}
          </div>

        );

      case 'vendor':
        return (
          <div>
            <Link to={this.createSettingsLink('web-links')}>Web Links</Link><br /><br />
            <Link to={this.createSettingsLink('products')}>Product Categories</Link><br /><br />
            <Link to={this.createSettingsLink('operations')}>Business Operations</Link><br /><br />
            <Link to={this.createSettingsLink('history')}>Business History</Link><br /><br />
            <Link to={this.createSettingsLink('billing')}>Billing Settings</Link><br /><br />
            <Link to={this.createSettingsLink('nexus')}>Tax Nexus</Link><br /><br />
            <Link to={this.createSettingsLink('expedited-designs')}>Expedited Designs</Link><br /><br />
            {isAdmin ? (<Link to={this.createSettingsLink('admin')}>Admin Settings</Link>) : null}
          </div>
        );
    }

  }

  render() {
    const account = this.state.account;

    const aliasList = account.aliases.map(alias => alias.name).join(', ');
    const body = this.state.loading  ? (<div className="panel-body"><LoadingSpinner /></div>) :
    (
        <div className="panel-body">
          <div className="account-name-status-container">
            <h4><strong>{account.accountName} {aliasList.length ? `(${aliasList})` : null}</strong></h4>
          </div>
          <UserContext.Consumer>
            {user => user.type === 'admin' ? (
              <div className="account-profile-flags">
                <span
                  className={`label label-${this.statusColor(Number(account.status.id))}`}
                >
                  {account.status.display}
                </span>
                {account.flag && account.flag.id === 2 ? <span className="label label-danger">30 Day Notice</span> : null}
              </div>
            ) : null}
          </UserContext.Consumer>

          <UserContext.Consumer>
            {user => user.type === 'admin' && account.flag ? (
              <p className="text-danger">
                {this.thirtyDayNotice(account.flag.at, account.flag.message)}
              </p>
            ) : null}
          </UserContext.Consumer>

          <div style={{ marginTop: 10, marginBottom: 10 }} className="settings-social-media">
            {this.state.accountUrls.map(u => <a href={u.url} target="_blank"><i className={u.type.icon}/></a>)}
            <UserContext.Consumer>
              {user => user.type === 'admin' && this.state.account.intercom ? (
                  <a href={this.state.account.intercomURL} target="_blank"><i className="fab fa-intercom" /></a>
              ) : null}
            </UserContext.Consumer>
          </div>
          <UserContext.Consumer>
            {user => user.type === 'admin' ? (
              <p>
                {account.description}
              </p>

            ) : null}
          </UserContext.Consumer>
          <hr />
          <UserContext.Consumer>
            {user => this.settingsLinks(account.accountType, user.type === 'admin')}
          </UserContext.Consumer>
          <hr />
          {this.state.accountUpdate ? (
            <div style={{ marginBottom: 15 }}>
              <p className="text-center no-margin">
                <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} />&nbsp;
                <strong>Pending Profile Update</strong>
              </p>
              <p className="text-center text-danger">The account profile has proposed changes under review.</p>
              <hr />
              </div>

          ) : null}

          <h5 className="text-muted">Business Profile</h5>
          <address>
            <strong>{account.legalName}</strong><br />
            {account.businessAddress.singleLine}<br />
            {account.businessAddress.phone}

          </address>

          <h5 className="text-muted">Public Profile</h5>
          <address>
            <strong>{account.accountName}</strong><br />
            {account.publicAddress.singleLine} <br />
            {account.publicAddress.phone}

          </address>

          <UserContext.Consumer>
            {user => user.type === 'admin' ? (
              <div>
                <h5 className="text-muted">Also Known As</h5>
                <div style={{ marginBottom: 15 }}>
                  <Link to={this.createSettingsLink('alias', true)}>{aliasList ? aliasList : 'Manage Profiles'}</Link>
                </div>
              </div>
              ) : aliasList ? (
                <div>
                  <h5 className="text-muted">Also Known As</h5>
                  <div style={{ marginBottom: 15 }}>
                    {aliasList}
                  </div>
                </div>
              ) : null
            }

          </UserContext.Consumer>

          <UserContext.Consumer>
            {user => user.type === 'admin' ? (
              <Link to={this.editProfileLink} className="btn btn-default btn-block">
                Edit Profile
              </Link>
            ) : (
                <Link to={this.editProfileLink} className="btn btn-default btn-block">
                  Edit Profile
              </Link>
              )}
          </UserContext.Consumer>
          <hr />
          <UserContext.Consumer>
            {user => (
              <div className="row">
                <div className="col-md-6">
                  <h5 className="text-muted">Primary Contact</h5>
                  {
                    account.primaryConact ? <address>
                      <strong>
                        {
                          user.type === 'admin' ? <Link to={this.primaryContactLink(account.primaryConact.id)}>
                            {account.primaryConact.firstName} {account.primaryConact.lastName}
                          </Link> : `${account.primaryConact.firstName} ${account.primaryConact.lastName}`
                        }
                      </strong><br />
                      {account.primaryConact.email}<br />
                      {account.primaryConact.phone}
                    </address> : <Link to={this.primaryContactLink()}>Add Primary Contact</Link>
                  }
                </div>
                {user.type === 'admin' ? <div className="col-md-6">
                  {
                    account.adminRep ? <address>
                      <strong>
                        {
                          user.type === 'admin' ? <Link to={this.primaryContactLink(account.adminRep.id)}>
                            {account.adminRep.firstName} {account.adminRep.lastName}
                          </Link> : `${account.adminRep.firstName} ${account.adminRep.lastName}`
                        }
                      </strong><br />
                      {account.adminRep.email}<br />
                      {account.adminRep.phone}
                    </address> : null
                  }

                </div> : null}

              </div>

            )}

          </UserContext.Consumer>

        </div>

    );
    return (
      <CenteredContent>
        <div className="panel panel-portal">
          {body}

        </div>
      </CenteredContent>
    );
  }

  getAccount() {
    this.setState({ loading: true });
    this._getAccountSource = Axios.CancelToken.source();
    this._getUpdateSource = Axios.CancelToken.source();
    const getAccount = getNewAccount(this._getAccountSource, this.accountID);
    const getUpdate = getCurrentAccountUpdate(this._getUpdateSource, this.accountID);
    const getUrls = Axios.get(`/api/account-web-urls?account_id=${this.accountID}&includeAlias=1`);

    Axios.all([getAccount, getUpdate, getUrls])
      .then(([getAccountResponse, getUpdateResponse, getUrlsResponse]) => {
        let update;
        if (getUpdateResponse.data && getUpdateResponse.data.data) {
          update = AccountProfileUpdate.fromApi(getUpdateResponse.data.data);
        } else {
          update = null;
        }
        this.setState({
          loading: false,
          account: AccountProfile.fromApi(getAccountResponse.data.data),
          accountUpdate: update,
          accountUrls: getUrlsResponse.data.data.map((u: any) => AccountWebUrl.fromApi(u)),
        });
      });
  }

  createSettingsLink(path: string, adminOnly?: boolean) {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.props.match.params['vendorId']}/settings/${path}`;
    }
    if (this.props.match.params['licensorId']) {
      return `/clients/${this.props.match.params['licensorId']}/settings/${path}`;
    }
    if (adminOnly) {
      return '';
    }
    return `/settings/${path}`;

  }

  createNormalLink(path: string) {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.props.match.params['vendorId']}/${path}`;
    }
    if (this.props.match.params['licensorId']) {
      return `/clients/${this.props.match.params['licensorId']}/${path}`;
    }

    return `/${path}`;
  }

  statusColor(status: number) {
    if (status ===  2) {
      return 'success';
    }
    if (status === 4 || status === 1) {
      return 'info';
    }
    if (status === 5) {
      return 'danger';
    }
    if (status === 3) {
      return 'warning';
    }
    return 'muted';

  }

  thirtyDayNotice(date: moment.Moment, message: string) {
    const start =  moment(date).format('dddd, MMMM Do, YYYY');
    const end = moment(date).add(30, 'days');

    return `${message} - Started on ${start} and ending on ${end.format('dddd, MMMM Do, YYYY')} (${end.fromNow()})`;

  }

  get accountID() {
    if (this.props.match.params['vendorId']) {
      return this.props.match.params['vendorId'];
    }
    if (this.props.match.params['licensorId']) {
      return this.props.match.params['licensorId'];
    }
    return '';
  }

  get editProfileLink() {
    if (this.props.match.params['vendorId'] || this.props.match.params['licensorId']) {
      return `${this.props.location.pathname}/edit`;
    }
    return '/settings/profile/request';

  }

  primaryContactLink(id?: number) {
    if (this.props.match.params['vendorId']) {
      return `/vendors/${this.props.match.params['vendorId']}/settings/users/${id ? id : ''}`;
    }
    if (this.props.match.params['licensorId']) {
      return `/clients/${this.props.match.params['licensorId']}/settings/users/${id ? id : ''}`;
    }
    return `/settings/users/${id ? id : ''}`;
  }
}
