import * as React from 'react';
import { IOptionGroup, OptionGroupInput } from './OptionGroupInput';

interface IProps {
  optionGroups: IOptionGroup[];
  onUpdateSelections: (i: number, group: IOptionGroup) => void;
  addGroup: (name: string) => void;
  removeGroup: (i: number) => void;
}

export const OptionGroupInputs = (props: IProps) => {

  const optionGroupsMarkup = props.optionGroups.map((group, i) => (

      <OptionGroupInput

        id={i}
        key={i}
        groupName={group.name}
        options={group.options}
        onUpdateSelections={(group: IOptionGroup) => props.onUpdateSelections(i, group)}
        onRemove={() => props.removeGroup(i)}
      />
  ));

  const onClickAdd = () => {

    const eligibleDefaultNames = [
      'Size',
      'Color',
      'Material',
    ];
    const groupNames = props.optionGroups.map(group => group.name);
    const eligibleOptions = eligibleDefaultNames.filter(candidate => !groupNames.includes(candidate));
    const newName = eligibleOptions.length > 0 ? eligibleOptions[0] : '';

    props.addGroup(newName);
  };

  const addButton = props.optionGroups.length < 3 ? (
    <button type="button" className="btn btn-primary" onClick={onClickAdd}>Add Product Option</button>
  ) : (
    null
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <label>Product Options</label>
      </div>
      { optionGroupsMarkup }
      { addButton }
    </div>
  );
};
