import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { UserContext, UserType } from '../../contexts';
import { GatewayNavRoute } from '../../shared';
import { findFAIcon } from '../../utils';
// import { SecondaryNavigation } from './SecondaryNavigation';

interface IState {
  vendorId: number | null;
  clientId: number | null;
  routes: any[];
  loadingRoutes: boolean;
  secondaryAccount: any;
  slideSecondary: boolean;
}

const DefaultAccount = {
  id: 0,
  image: {
    sm: 'https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/olp-logo-bw.jpg',
  },
};

export class PrimaryNavigation extends React.Component <any, IState> {
  protected _secondarAccountSource: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      vendorId: null,
      clientId: null,
      routes: [],
      secondaryAccount: DefaultAccount,
      loadingRoutes: false,
      slideSecondary: false,
    };
    this.createSecondaryNavLink = this.createSecondaryNavLink.bind(this);
    this.checkRouteMatch = this.checkRouteMatch.bind(this);
    this.isVendorOrClient = this.isVendorOrClient.bind(this);
  }

  componentDidMount() {
    this.backRoute = this.backRoute.bind(this);
  }

  createSecondaryNavLink(route: GatewayNavRoute, isSub: boolean) {
    let navLink;

    const icon = findFAIcon(route.icon);
    const url = `${this.props.secondaryAccount.url}${route.url.length ? '/' : ''}${route.url}`;

    navLink = (
      <NavLink
        to={url}
        isActive={(match, location) => this.checkRouteMatch(route, location)}
        strict={false}
        exact={false}
      >
        <FontAwesomeIcon
          style={{ visibility: route.icon && !isSub ? 'visible' : 'hidden' }}
          className="nav-icon"
          icon={icon}
          fixedWidth
        />
       {route.title} <span dangerouslySetInnerHTML={{ __html: route.status }}></span>
      </NavLink>
    );
    const linkClass = isSub ? 'nav-secondary-link' : 'nav-primary-link';
    const link = (<li className={linkClass} key={`${route.title}-${route.url}`}>{navLink}</li>);
    if (route.subLinks.length) {
      const subLinks = route.subLinks.map((sub: any) => this.createSecondaryNavLink(sub, true));
      return (
          <div key={`${route.title}-${route.url}`}>
            {link}
            <div style={{ display: this.showSubLinks(route, this.props.location) ? 'block' : 'none' }}>
              {subLinks}
            </div>

          </div>
      );
    }
    return link;
  }

  createNavLink(route: GatewayNavRoute, isSub: boolean) {
    const icon = findFAIcon(route.icon);
    let routeLink;
    routeLink =
      <NavLink to={`/${route.url}`} isActive={(match, location) => this.checkRouteMatch(route, location)}>
        <FontAwesomeIcon
            style={{ visibility: route.icon && !isSub ? 'visible' : 'hidden'  }}
            className="nav-icon"
            icon={icon}
            fixedWidth
        />
        {route.title} <span dangerouslySetInnerHTML={{ __html: route.status }}></span>
      </NavLink>;
    const linkClass = isSub ? 'nav-secondary-link' : 'nav-primary-link';
    const link =  (<li className={linkClass} key={`${route.title}-${route.url}`}>{routeLink}</li>);
    if (route.subLinks.length) {
      const subLinks = route.subLinks.map((sub: any) => this.createNavLink(sub, true));
      return (
        <div key={`${route.title}-${route.url}`}>
          {link}
          <div style={{ display: this.showSubLinks(route, this.props.location) ? 'block' : 'none' }}>
            {subLinks}
          </div>

        </div>
      );
    }
    return link;
  }

  onSubLink(routes: GatewayNavRoute[], location: any) {
    return routes.reduce(
      (prev, current) => {
        if (prev) {
          return true;
        }
        return this.checkRouteMatch(current, location);
      },
      false,
    );

  }

  showSubLinks(route: GatewayNavRoute, location: any) {
    const isOnSubLink = this.onSubLink(route.subLinks, location);
    return isOnSubLink || this.checkRouteMatch(route, location);
  }

  checkRouteMatch(route: GatewayNavRoute, location: any) {
    const path = this.props.isAccountRoute && this.props.secondaryAccount.url ?
      location.pathname.replace(this.props.secondaryAccount.url, '') : location.pathname;
    if (route.subLinks.length) {
      if (this.onSubLink(route.subLinks, location)) {
        return false;
      }
    }
    if (route.url === '' && (path === '/' || path === '')) {
      return true;
    }
    return path.indexOf(route.url) === 1;
  }

  backRoute() {
    const accountUrl: string = this.props.secondaryAccount && this.props.secondaryAccount.url;
    if (accountUrl) {
      if (accountUrl.indexOf('vendors') !== -1) {
        return '/vendors';
      }
      if (accountUrl.indexOf('clients') !== -1) {
        return '/clients';
      }
    }

    return '/';

  }

  isVendorOrClient() {
    const type = this.props.user.type;
    return type === 'client' || type === 'vendor';
  }

  render() {
    const showImage = this.props.isAccountRoute || this.isVendorOrClient() ? 'nav-links-container show-image' : 'nav-links-container';
    const accountURL = this.props.secondaryAccount.url ? this.props.secondaryAccount.url : '/';

    const imageSource = this.isVendorOrClient() ? this.props.user.account.image.sm : this.props.secondaryAccount.image.sm;

    const spinnerOrProfile = this.props.loadingRoutes ?
    (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems : 'center'  }}>
        <FontAwesomeIcon style={{ color: 'black', fontSize: 30 }} icon={faSpinner} spin />
      </div>
      )
      : (
        <img className="img-responsive" src={imageSource} />
      );
    const profileImageWrapper = `profile-image-wrapper ${this.isVendorOrClient() ? 'is-primary' : ''}`;
    const profileImage = this.props.secondaryAccount || this.isVendorOrClient() ?  (
      <div className={profileImageWrapper}>
        {!this.isVendorOrClient() ? <Link
          to={this.backRoute()}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 60, cursor: 'pointer' }}
        >
          <FontAwesomeIcon style={{ color: 'white', opacity: 0.5 }} icon={faCaretLeft} />
        </Link> : null}

        <div>
          <Link to={accountURL} className="profile-image-container">
            {spinnerOrProfile}
          </Link>
        </div>

      </div>

    ) : null;

    const routes =  (
      <ul className={showImage}>
        <UserContext.Consumer>
          {(user: UserType) => {
            const routes = this.props.routes.length ?
              this.props.routes.map((nav: any) => this.createSecondaryNavLink(nav, false)) :
              user.navigation.map(nav => this.createNavLink(nav, false));
            return this.props.loadingRoutes ? null : routes;
          }}
        </UserContext.Consumer>
          {this.props.adminRoutes.length ? (

          <div style={{ marginTop: 20, marginBottom: 10 }}>

            <div style={{ marginLeft: 10, marginRight: 10, paddingBottom: 5, marginBottom: 10, color: 'rgba(255, 255, 255, .5)' }}>
              <strong style={{ marginLeft: 10, color: '#888', fontSize: 11 }}>ADMIN</strong>

            </div>
            {this.props.adminRoutes.map((nav: any) => this.createSecondaryNavLink(nav, false))}
          </div>
          ) : null}
      </ul>
    );

    return (
      <div className={`navigation-wrapper ${this.props.className}`} id="nav-wrapper">
        <div className="disabled-navigation"></div>
        <div className="primary-navigation" id="primary-navigation">
          <div className="nav-logo">
            <Link to="/">
              <img className="img-responsive" src="/assets/images/gateway/new-gateway-logo.png" height="50" />
            </Link>
          </div>

          <div className="primary-nav-link-container hide-scrollbar">
             {profileImage}
             {routes}
          </div>
        </div>
      </div>
    );

  }

}
