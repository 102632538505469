import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import * as Yup from 'yup';

interface ImageGridItem {
  column: string;
  image: string;
  buttonText: string;
  link: string;
  internalLink: number;
}
interface SitePageImageGridFields {
  items: ImageGridItem[];
}

interface IProps {
  onSubmit: (values: string) => void;
  initialValues?: SitePageImageGridFields;
}

export const SitePageImageGridForm = (props: IProps) => {

  const initialValues = props.initialValues ? props.initialValues : {
    items: [],
  };

  const handleSubmit = (values: SitePageImageGridFields) => {
    const s = {
      items: values.items.map((i) => {
        return {
          column_size: i.column,
          image_url: i.image,
          button_text: i.buttonText ? i.buttonText : null,
          link: i.link ? i.link : null,
          internal_link: Number(i.internalLink) ? true : false,
        };
      }),
    };
    const cleaned = {
      items: s.items.map(v => omitBy(v, isNil)),
    };
    props.onSubmit(JSON.stringify(cleaned));
  };

  const validationSchema = Yup.object({
    items: Yup.array().of(
      Yup.object({
        column: Yup.string().required('Column Size is required'),
        image: Yup.string().required('Image URL is required').url('Must be a valid url'),
        buttonText: Yup.string(),
        link: Yup.string().when('buttonText', {
          is: text => text.length,
          then: Yup.string().required('Link is required with button text'),
          otherwise: Yup.string(),
        }),
      }),
    ).min(1),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>

      {formProps => (
        <Form>
          <FieldArray
            name="items"
            render={helpers => (
              <div>
                {formProps.values.items.map((item, index) => (
                  <div key={index}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <h4><strong>Item</strong></h4>
                      {formProps.values.items.length > 1 ? <button
                        onClick={() => helpers.remove(index)}
                        type="button"
                        className="btn btn-danger btn-sm"
                      >
                        Remove
                      </button> : null}

                    </div>

                    <div className="form-group">
                      <label>Column Size</label>
                      <Field name={`items.${index}.column`} className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name={`items.${index}.column`} />
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Image URL</label>
                      <Field name={`items.${index}.image`} className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name={`items.${index}.image`} />
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Button Text</label>
                      <Field name={`items.${index}.buttonText`} className="form-control" />
                    </div>
                    <div className="form-group">
                      <label>Link</label>
                      <Field name={`items.${index}.link`} className="form-control" />
                      <p className="text-danger">
                        <ErrorMessage name={`items.${index}.link`} />
                      </p>
                    </div>
                    <div className="form-group">
                      <label>Link Type</label>
                      <Field component="select" className="form-control" name={`items.${index}.internalLink`} >
                        <option value={1}>Internal</option>
                        <option value={0}>External</option>
                      </Field>
                    </div>
                    <hr />
                  </div>
                ))}
                <button
                  onClick={() => helpers.push({ column: '', image: '', buttonText: '', link: '', internalLink: 0 })}
                  type="button"
                  className="btn btn-primary btn-sm"
                >
                  Add Item
                </button>
              </div>

            )}
          />

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
            <button className="btn btn-primary">Save Page Item</button>
          </div>
        </Form>
      )}

    </Formik>
  );

};
