import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

interface SitePageHTMLFields {
  html: string;
}

interface IProps {
  onSubmit: (values: string) => void;
  initialValues?: SitePageHTMLFields;
}

export const SitePageHTMLForm = (props: IProps) => {

  const initialValues = props.initialValues ? props.initialValues : {
    html: '',
  };

  const handleSubmit = (values: SitePageHTMLFields) => {
    props.onSubmit(JSON.stringify(values));
  };

  const validationSchema = Yup.object({
    html: Yup.string().required('HTML is required.'),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {props => (
        <Form>
          <div className="form-group">
            <label>HTML</label>
            <Field placeholder="HTML" component="textarea" className="form-control" name="html" />
            <p className="text-danger">
              <ErrorMessage name="html" />
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="btn btn-primary">Save Page Item</button>
          </div>
        </Form>
      )}

    </Formik>
  );

};
