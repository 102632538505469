import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { GatewayModal, ModalType } from '../shared/modals';
import { deleteDesignResponse } from './api';
import { DesignResponse } from './DesignResponse';

interface IProps {
  onClose: (response: DesignResponse | null) => any;
  shown: boolean;
  response: DesignResponse | null;
}

interface IState {
  loading: boolean;
}

export class DesignResponseDeleteModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.deleteResponse = this.deleteResponse.bind(this);
  }

  deleteResponse() {
    this.setState({ loading: true });
    if (this.props.response) {
      deleteDesignResponse(this.props.response.id)
        .then(() => this.props.onClose(this.props.response));

    }
  }

  closeModal() {
    this.props.onClose(null);
  }

  render() {

    return (
      <GatewayModal type={ModalType.Danger} title="Delete Response" shown={this.props.shown} onClose={this.closeModal}>
        <Modal.Body>
          <p>Are you sure you want to delete this response?</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.closeModal} className="btn btn-default pull-left">Cancel</button>
          <button
            disabled={this.state.loading}
            onClick={this.deleteResponse}
            className="btn btn-danger pull-right"
          >
            { this.state.loading ? 'Deleting...' : 'Delete' }
          </button>
        </Modal.Footer>
      </GatewayModal>
    );

  }

}
