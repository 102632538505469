import { faCogs, faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { NoteThread } from '../../shared';
import { GatewayModal, LoadingSpinner, ModalType, PaginationFooter, UserTypeahead } from '../shared';
import { NotesFilters } from './NotesFilters';
import { NoteThreadCategories } from './NoteThreadCategories';
import { NoteUserList } from './NoteUserList';

export const NotesList = () => {
  const { accountId } = useParams<any>();
  const location = useLocation();
  const history = useHistory();
  const [threads, setThreads] = React.useState<NoteThread[]>([]);
  const threadsRef = React.useRef(threads);
  const [newNote, setNewNote] = React.useState<string | null>(null);
  const [filters, setFilters] = React.useState(new NotesFilters(location.search));
  const [loading, setLoading] = React.useState(false);
  const [lastPage, setLastPage]  = React.useState(1);
  const [advancedSearch, setAdvancedSearch] = React.useState(false);
  const [totalThreads, setTotalThreads] = React.useState(0);

  const isFirstAccountId = React.useRef(true);

  React.useEffect(() => {

    document.body.addEventListener('note-list-reload', noteListReload, false);

    document.addEventListener('keydown', keyboardShortcutHandler, false);

    return () => {
      document.body.removeEventListener('note-list-reload', noteListReload, false);
      document.removeEventListener('keydown', keyboardShortcutHandler, false);
    };

  },              []);

  React.useEffect(() => {
    if (newNote) {
      getThreads(false);
    }

  },              [newNote]);

  React.useEffect(() => {
    getThreads();

  },              [location.search]);

  React.useEffect(() => {
    threadsRef.current = threads;
  },              [threads]);

  React.useEffect(() => {
    if (isFirstAccountId.current) {
      isFirstAccountId.current = false;
    } else {
      getThreads();
    }

  },              [accountId]);

  const getThreads = async (reset = true) => {
    if (reset) {
      setLoading(true);
      setThreads([]);
    }
    const query = {
      page : filters.page,
      account_id: accountId,
      search: filters.search ? filters.search : null,
      user_ids: filters.users.length ? filters.users.map(u => u.id).join() : null,
      category_ids: filters.categories.length ? filters.categories.map(u => u.id).join() : null,
      sort: filters.sortBy.length ? filters.sortBy : null,
      subject: filters.subject.length ? filters.subject : null,
      note: filters.note.length ? filters.note : null,
      note_code: filters.noteCode.length ? filters.noteCode : null,
      start_date: filters.createdStart.length ? filters.createdStart : null,
      end_date: filters.createdEnd.length ? filters.createdEnd : null,
      is_warning: filters.flagged ? 1 : null,

    };
    const cleanParams = omitBy(query, isNil);
    const qs = queryString.stringify(cleanParams);

    const t = await Axios.get(`/api/note-threads?${qs}`);
    setThreads(t.data.data.map((d: any) => NoteThread.fromApi(d)));
    setLastPage(t.data.meta.pagination.total_pages);
    setTotalThreads(t.data.meta.pagination.total);
    setLoading(false);
  };

  const noteListReload = (e: CustomEvent) => {
    setNewNote(e.detail.thread);
  };

  const setPage = (page: number) => {
    filters.page = page;
    const baseUrl = location.pathname;
    history.replace(`${baseUrl}?${filters.generateQueryParamString()}`);
    const nf = new NotesFilters();
    nf.setFilters(filters.filters);
    setFilters(nf);

  };

  const closeSearch = () => {
    setAdvancedSearch(false);
    filtersChanged({}, true);

  };

  const filtersChanged = (f: any, reload: boolean) => {
    if (f.search !== undefined) {
      filters.search = f.search;
    }
    if (f.users !== undefined) {
      filters.users = f.users;
    }
    if (f.categories !== undefined) {
      filters.categories = f.categories;
    }
    if (f.sort !== undefined) {
      filters.sortBy = f.sort;
    }
    if (f.subject !== undefined) {
      filters.subject = f.subject;
    }
    if (f.note !== undefined) {
      filters.note = f.note;
    }
    if (f.noteCode !== undefined) {
      filters.noteCode = f.noteCode;
    }
    if (f.flagged !== undefined) {
      filters.flagged = f.flagged;
    }
    if (f.pinned !== undefined) {
      filters.pinned = f.pinned;
    }
    if (f.createdStart !== undefined) {
      filters.createdStart = f.createdStart;
    }
    if (f.createdEnd !== undefined) {
      filters.createdEnd = f.createdEnd;
    }
    if (reload) {
      filters.page = 1;
      const baseUrl = location.pathname;
      history.replace(`${baseUrl}?${filters.generateQueryParamString()}`);

    }
    const nf = new NotesFilters();
    nf.setFilters(filters.filters);
    setFilters(nf);

  };

  let baseRoute = '/notes';
  if (location.pathname.includes('vendors')) {
    baseRoute = `/vendors/${accountId}/notes`;
  }
  if (location.pathname.includes('clients')) {
    baseRoute = `/clients/${accountId}/notes`;
  }

  const keyboardShortcutHandler = (event: KeyboardEvent) => {
    const noteTextLocation = history.location.pathname.lastIndexOf('/');
    const noteId = history.location.pathname.substring(noteTextLocation + 1);

    const target = event.target as Element;
    const ignoreKey = target.localName === 'input' ||
      target.localName === 'select' ||
      target.localName === 'textarea' ||
      target.className === 'ql-editor';

    if (!ignoreKey) {
      const currentThread = noteId !== 'notes' ? threadsRef.current.findIndex(t => Number(t.id) === Number(noteId)) : -1;

      if (event.key === 'ArrowLeft') {
        if (currentThread !== -1) {
          const index = currentThread - 1;
          if (index !== -1) {
            const thread = threadsRef.current[index];
            history.push(`${baseRoute}/${thread.id}?${filters.generateQueryParamString()}`);
          }
        }

      }
      if (event.key === 'ArrowRight') {

        if (currentThread !== -1) {

          const index = currentThread + 1;
          if (index < threadsRef.current.length) {
            const thread = threadsRef.current[index];
            history.push(`${baseRoute}/${thread.id}?${filters.generateQueryParamString()}`);
          }
        } else {
          const thread = threadsRef.current[0];
          history.push(`${baseRoute}/${thread.id}?${filters.generateQueryParamString()}`);
        }

      }
    }

  };

  const listItems = loading ? (<LoadingSpinner />) : threads.length ? threads.map(l => (
    <NavLink key={l.id} className="note-list-nav-link" to={`${baseRoute}/${l.id}?${filters.generateQueryParamString()}`}>
      <div className="note-list-item">
        <div className="note-list-icon">
          {l.threadIcon ? <FontAwesomeIcon className={l.iconClass} icon={l.threadIcon} /> : null}

        </div>
        <div className="note-list-content">
          {!accountId ? <div className="note-user-list">
            <div className="note-user-images">
              <img
                className="note-user-image"
                src={l.account.logo.getSize('th')}
              />
            </div>
            <div className="note-user-names small">
              <strong>
                {l.account.accountName}
              </strong>

            </div>
          </div> : null}
          <NoteUserList users={l.users} />

          <h4 className="text-primary no-margin thread-title">
            <strong>{l.title}</strong>
            {l.hasAttachments ?
              <FontAwesomeIcon style={{ marginLeft: 10 }} className="text-muted" icon={faPaperclip} />
            : null}
          </h4>
          <p
            style={{ marginTop: 10, color: '#333' }}
            className="small"
            dangerouslySetInnerHTML={{ __html: l.previewMessage }} />
          <p className="small no-margin text-muted">
            Updated on {l.updatedAt.format('MMM DD, YYYY')} ({l.updatedAt.fromNow()}) {l.noteCode ? `• ${l.noteCode}` : ''}
          </p>
        </div>
      </div>
    </NavLink>

  )) : <h4 style={{ marginTop: 20 }} className="text-center"><strong>No Notes Found</strong></h4>;

  return (
    <div style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: 15 }}>
        <div className="row">
          <div className="col-md-8">
            <div className="input-group">
              <input
                value={filters.search}
                onChange={e => filtersChanged({ search: e.target.value }, false)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    filtersChanged({}, true);
                  }
                }}
                type="text"
                className="form-control"
                placeholder="Search"
              />
              <span className="input-group-btn">
                <button onClick={() => setAdvancedSearch(true)} className="btn btn-default">
                  <FontAwesomeIcon
                    className={filters.generateQueryParamString().length ? 'text-primary' : ''}
                    icon={faCogs}
                  />
                </button>
              </span>
            </div>

          </div>
          <div className="col-md-4">
            {accountId ?
              <Link className="btn btn-primary btn-block" to={`${baseRoute}/new?${filters.generateQueryParamString()}`}>
                New Thread
              </Link> : null}

          </div>
        </div>
      </div>
      <div style={{ maxHeight: '100%', overflowY: 'scroll', height: '100%' }} className="panel panel-portal">
        <div className="notes-list-container">
          <div className="notes-list">
            {listItems}

          </div>

        </div>
      </div>
      <PaginationFooter
        hasNext={filters.page < lastPage}
        currentPage={filters.page}
        setPage={setPage}
        totalPages={lastPage}
        totalResults={totalThreads}
        />
      <GatewayModal
        shown={advancedSearch}
        onClose={closeSearch}
        type={ModalType.Primary}
        title="Advanced Search"
      >
        <Modal.Body>

          <label>Tags</label>
          <NoteThreadCategories
            id="thread-categories"
            selectedCategories={filters.categories}
            selectCategoryFn={v => filtersChanged({ categories: v }, false)}
          />
          <div className="form-group">
            <label >Subject</label>
            <input onChange={e => filtersChanged({ subject: e.target.value }, false)} value={filters.subject} placeholder="Subject" type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label >Note Content</label>
            <input  onChange={e => filtersChanged({ note: e.target.value }, false)} value={filters.note} placeholder="Content" type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label>Users</label>
            <UserTypeahead
              selected={filters.users}
              accountId={1}
              multiple={true}
              onChange={u => filtersChanged({ users: u }, false)}
            />
          </div>
          <div className="form-group">
            <label>Note Code</label>
            <input onChange={e => filtersChanged({ noteCode: e.target.value }, false)} value={filters.noteCode} placeholder="Note Code" type="text" className="form-control" />
          </div>

          <div className="form-group">
            <label>Options</label>
            <div className="checkbox">
              <label>
                <input
                  onChange={e => filtersChanged({ pinned: e.target.checked }, false)}
                  type="checkbox"
                  name="pinned"
                  checked={filters.pinned}
                /> Pinned
              </label>
            </div>
            <div className="checkbox">
              <label>
                <input
                  onChange={e => filtersChanged({ flagged: e.target.checked }, false)}
                  type="checkbox"
                  name="flagged"
                  checked={filters.flagged}
                /> Flagged
              </label>
            </div>
          </div>
          <div className="form-group">
            <label>Created Dates</label>
            <div className="row">
              <div className="col-md-6">
                <input
                  onChange={e => filtersChanged({ createdStart: e.target.value }, false)}
                  value={filters.createdStart}
                  name="start"
                  id="start"
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <input
                  onChange={e => filtersChanged({ createdEnd: e.target.value }, false)}
                  value={filters.createdEnd}
                  name="end"
                  id="end"
                  type="date"
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>Sort</label>
            <select value={filters.sortBy} onChange={e => filtersChanged({ sort: e.target.value }, false)} className="form-control">
              <option value="default">Sort Order (Default)</option>
              <option value="date-desc">Date Desc</option>
              <option value="date-asc">Date Asc</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={closeSearch} className="btn btn-default">Done</button>
        </Modal.Footer>

      </GatewayModal>
    </div>

  );
};
