import * as moment from 'moment';
import { AccountWebUrlType } from './AccountWebUrl';
import { AffinityClient } from './AffinityClient';
import { AffinityImage, ImageApiResponse } from './Image';
import { Trademark, TrademarkApiResponse } from './Trademark';
import { TrademarkCase, TrademarkCaseApiResponse } from './TrademarkCase';
import { Vendor, VendorAPIResponse } from './Vendor';

export interface TrademarkUseApiResponse {
  clients: any;
  id: number;
  web_url: string;
  seller_id: string;
  note: string;
  title: string;
  description: string;
  price: string|null;

  client: any;
  trademark: TrademarkApiResponse;
  trademarks: TrademarkApiResponse[];
  vendor: VendorAPIResponse;
  screenshot_file: ImageApiResponse;
  preview_file: ImageApiResponse;
  data_file: any;
  web_url_type: any;
  resolution_type: {id: IResolutionTypeIds; name: string}|null;
  status: any;
  case_id: number;
  case?: TrademarkCaseApiResponse;
  created_at: string;
  resolved_at: string;
  link_checked_at: string;
}
type IResolutionTypeIds = 1 | 2;
interface IResolutionType {
  id: IResolutionTypeIds;
  name: string;
}

export class TrademarkUse {

  id: number;

  caseId: number;
  case: TrademarkCase;

  url: string;
  urlType: AccountWebUrlType;

  sellerId: string;
  title: string;
  description: string;
  price: string|null;

  image: AffinityImage;

  screenshot: AffinityImage;

  dataFile: any;

  licensor: AffinityClient;
  licensors: AffinityClient[];

  trademark: Trademark;
  trademarks: Trademark[];

  vendor: Vendor;

  resolutionType: IResolutionType|null;

  note: string;

  createdAt: moment.Moment;

  resolvedAt: moment.Moment | null;

  linkCheckedAt: moment.Moment | null;

  _status: any;

  static fromApi(data: TrademarkUseApiResponse) {
    const t = new TrademarkUse();
    t.id = data.id;
    t.caseId = data.case_id;
    t.title = data.title;
    t.description = data.description;
    t.price = data.price;
    t.url = data.web_url;
    t.sellerId = data.seller_id;
    t.note = data.note;
    t.createdAt = moment(data.created_at);
    t.resolvedAt = data.resolved_at ? moment(data.resolved_at) : null;
    t.linkCheckedAt = data.link_checked_at ? moment(data.link_checked_at) : null;
    if (data.preview_file) {
      t.image = AffinityImage.fromApi(data.preview_file);
    } else {
      t.image = new AffinityImage();
    }
    if (data.screenshot_file) {
      t.screenshot = AffinityImage.fromApi(data.screenshot_file);
    } else {
      t.screenshot = new AffinityImage();
    }
    if (data.data_file) {
      t.dataFile = data.data_file;
    }

    if (data.vendor) {
      t.vendor = Vendor.fromApi(data.vendor);
    }
    if (data.client) {
      t.licensor = new AffinityClient(data.client);
    }
    if (data.clients && data.clients.length > 0) {
      t.licensors = data.clients.map((l: any) => {
        return {
          id: l.id,
          totalProducts: l.total_products,
          shortName: l.short_name,
          trademarks: l.trademarks && l.trademarks.length > 0 ? l.trademarks.map((t:TrademarkApiResponse) => Trademark.fromApi(t)) : [],
          defaultTrademark: l.default_trademark,
          affinityImage: l.image_id ? AffinityImage.fromApi({ id:0 , previous_file_name:'', urls:l.image }) : new AffinityImage(),
          image: l.image ? l.image : null,
        };
      });
    } else {
      t.licensors = [];
    }
    if (data.trademark) {
      t.trademark = Trademark.fromApi(data.trademark);
    }
    // if (data.trademarks) {
    //   t.trademarks = data.trademarks.map((t) => Trademark.fromApi(t.trademark));
    // }
    if (data.resolution_type) {
      t.resolutionType = data.resolution_type;
    } else {
      t.resolutionType = null;
    }
    if (data.web_url_type) {
      t.urlType = AccountWebUrlType.fromApi(data.web_url_type);
    }
    if (data.case) {
      t.case = TrademarkCase.fromApi(data.case);
    }
    t._status = data.status;

    return t;

  }

  get status() {
    return this._status.name;
  }

  get statusId() {
    return this._status.id;
  }

  get statusClass() {
    switch (this._status.id) {
      case 1:
        return 'info';

      case 2:
        return 'danger';

      case 3:
        return 'primary';

      case 4:
        return 'success';

    }
    return 'warning';
  }

  get statusLabelClass() {
    switch (this._status.id) {
      case 1:
        return 'info';

      case 2:
        return 'danger';

      case 3:
        return 'danger';

      case 4:
        return 'success';

    }
    return 'warning';
  }

}
