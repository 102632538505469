import * as React from 'react';
import { DropEvent } from 'react-dropzone';
import { AffinityDropzone } from '../../Navigation';
import { IAvailableProductEntry } from '../api';

interface IProps {
  category: IAvailableProductEntry;
  onDrop: (category: IAvailableProductEntry, acceptedFiles: File[], rejectedFiles: File[]) => void;
}

const dropzoneStyle = {
  borderColor: 'transparent',
  padding: 15,
  backgroundColor: '#ffffff',
  borderRadius: 8,
};

export class UploadSectionCategory extends React.Component <IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.onDropFn = this.onDropFn.bind(this);
  }

  onDropFn(acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) {
    return this.props.onDrop(this.props.category, acceptedFiles, rejectedFiles);
  }

  render() {

    const parents = this.props.category.categoryTree
      .slice(0, this.props.category.categoryTree.length - 1)
      .map(item => item.name)
      .join(', ')
      .toUpperCase();
    const primaryName = this.props.category.categoryTree[this.props.category.categoryTree.length - 1].name;

    return (
      <li className="design-categories-item">
        <AffinityDropzone
          style={dropzoneStyle}
          onDrop={this.onDropFn}
          accept="image/*,application/pdf"
        >
          <div>
            <div style={{ fontSize: 10 }} className="text-muted no-margin">{parents}</div>
            <div style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5 }}>{primaryName}</div>

          </div>
        </AffinityDropzone>
      </li>
    );
  }
}
