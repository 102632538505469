import * as moment from 'moment';
import * as React from 'react';

interface IDateCell {
  date: Date;
}

export const DateCell = (props: IDateCell) => {

  const m = moment(props.date);

  if (!m.isValid()) {
    return <div></div>;
  }

  const monthText = m.format('MMM');
  const dayText = m.format('D');
  const yearText = m.format('YYYY');

  return (
        <div className="table-date">
            <span className="table-date-month text-primary">{monthText}</span>
            <span className="table-date-day">{dayText}</span>
            <span className="table-date-year"><span>{yearText}</span></span>
        </div>
  );
};
