import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikValues } from 'formik';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { UserContext } from '../../contexts';
import { FullContent } from '../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../shared';

interface AudienceSegmentAPIData {
  id: number;
  name: string;
  sum: number;
}

export const AudienceDashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [audiences, setAudiences] = useState<AudienceSegmentAPIData[] | []>([]);
  const [saving, setSaving] = useState(false);
  const [createModalShown, setCreateModalShown] = useState(false);
  const routeParams = useParams();
  const history = useHistory();
  const user = useContext(UserContext);

  useEffect(() => {
    getAudienceSegments();
  },        []);

  function getAudienceSegments() {
    setLoading(true);
    const params = {
      account_id: getAccountID(),
    };

    Axios.get('/api/audience/segments', { params })
      .then((response) => {
        setAudiences(response.data.data);
        setLoading(false);
      });
  }

  function saveAudienceSegment(values: FormikValues, formProps: FormikActions<FormikValues>) {
    if (values) {
      setSaving(true);

      const formData = {
        name: values.name,
        heatmap: values.heatmap,
        pin: values.pin,
        mapColor: values.mapColor,
        account_id: getAccountID(),
      };

      Axios.post('/api/audience/segments', formData)
        .then((response) => {
          hideCreateModal();
          setSaving(false);
          getDetailPage(response.data.id, true);
        })
        .catch((error) => {
          for (const index in error.response.data) {
            formProps.setFieldError(index, error.response.data[index][0]);
          }
          setSaving(false);
        });
    }
  }

  function showCreateModal() {
    setCreateModalShown(true);
  }

  function hideCreateModal() {
    setCreateModalShown(false);
  }

  function getDetailPage(id:string, showUploadModal = false) {
    if (user.type === 'admin') {
      history.push({
        pathname: getBackRoute(id),
        state: { openUploadModal: showUploadModal },
      });
    } else {
      history.push({
        pathname: getBackRoute(`settings/audiences/${id}`),
        state: { openUploadModal: showUploadModal },
      });
    }
  }

  function getSelectedDetailPage(e:any) {
    const id = e.currentTarget.getAttribute('data-id');
    getDetailPage(id);
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  function getBackRoute(id: string) {
    if (routeParams['vendorId']) {
      return `/vendors/${getAccountID()}/settings/audiences/${id}`;
    }
    if (routeParams['licensorId']) {
      return `/clients/${getAccountID()}/settings/audiences/${id}`;
    }

    return '';
  }

  let pageContent;

  const rows =
    (audiences as any[]).map(audience =>
    <tr key={ audience.id } data-id={audience.id} className="licensor-mapping-row text-primary" onClick={getSelectedDetailPage} style={{ cursor:'pointer' }}>
      <td style={{ verticalAlign: 'middle' }}>
        {audience.name}
      </td>
      <td style={{ verticalAlign: 'middle', textAlign: 'right' }}>
        <span className="text-muted" style={{ marginRight: 30 }}>{audience.sum}</span>
        <i className="fa fa-chevron-right"/>
      </td>
    </tr>,
  );

  if (loading) {
    pageContent =  (
      <LoadingSpinner/>
    );

  } else if (audiences.length === 0 && ! loading) {
    pageContent =  (
      <em>No Audiences Found.</em>
    );
  } else {
    pageContent = (
      audiences as any[]).map(audience =>
      <div className="panel panel-portal" key={ audience.id }>
        <div className="panel-body">
          <h4 className="text-primary">{audience.name}</h4>
          <button className="btn btn-default text-primary pull-right">
            View Dashboard
          </button>
        </div>
      </div>,
    );
  }

  const initialValues = {
    name: '',
    pin: false,
    heatmap: true,
    mapType: '',
    mapColor: 'gray',
  };

  const validation = Yup.object({
    name: Yup.string().min(1).required('An audience name is required'),
    mapColor: Yup.string().min(1).required('A map color is required'),
    mapType: Yup
      .string()
      .when(['heatmap', 'pin'], {
        is: false,
        then: Yup.string().min(1).required('At least one map type must be selected below'),
      }),
    pin: Yup.boolean(),
    heatmap: Yup.boolean(),
  });

  const createModalText = saving ? 'Saving...' : 'Create Audience';

  return (
    <FullContent breadcrumbs={[{ name: 'audiences' }]}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <div style={{ maxWidth: '50%' }}>
          <h3><strong>Audiences</strong></h3>
        </div>
        <div style={{ maxWidth: '50%' }}>
          <button className="btn btn-primary" onClick={showCreateModal} style={{ marginLeft: 5 }}>
            New Audience
          </button>
        </div>
      </div>
        { pageContent }

      <GatewayModal shown={createModalShown} type={ModalType.Primary} onClose={hideCreateModal} title="Create Audience" >
        <Modal.Body>
          <Formik
            onSubmit={ (values, formProps) => saveAudienceSegment(values, formProps)}
            validationSchema={validation}
            validateOnBlur={false}
            initialValues={initialValues}>
            {formProps =>
              (
                <div>
                  <Form id="audience-segment-form">
                      <div className="form-group">
                        <label htmlFor="segment">Audience Name</label>
                        <Field placeholder="Audience Name" name="name" className="form-control"/>
                        <p className="text-danger">
                          <ErrorMessage name="name" />
                        </p>
                      </div>
                    <div className="form-group">
                      <label htmlFor="mapColor">Audience Map Color</label>
                      <Field component="select"
                             name="mapColor"
                             className="form-control"
                      >
                        <option value="" disabled={true}>Select Map Color</option>
                        <option value="blue">Blue</option>
                        <option value="gray">Gray</option>
                        <option value="green">Green</option>
                        <option value="orange">Orange</option>
                        <option value="Pink">Pink</option>
                        <option value="purple">Purple</option>
                        <option value="red">Red</option>
                        <option value="yellow">Yellow</option>
                      </Field>
                    </div>
                    <label htmlFor="heatmap">Audience Map Type
                    </label>
                    <p className="text-danger">
                      <ErrorMessage name="mapType" />
                    </p>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="heatmap" defaultChecked={formProps.values.heatmap}
                        />
                        Heatmap
                      </label>
                    </div>
                    <div className="checkbox">
                      <label>
                        <Field type="checkbox" name="pin" defaultChecked={formProps.values.pin} />
                        Pins
                      </label>
                    </div>
                  </Form>
                </div>
              )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={hideCreateModal} className="btn btn-default pull-left">Cancel</button>
          <button type="submit" form="audience-segment-form" className="btn btn-primary pull-right" disabled={saving}>{createModalText}</button>
        </Modal.Footer>
      </GatewayModal>

    </FullContent>

  );

};
