import * as React from 'react';
import { IBreadcrumb } from '../Breadcrumbs';

interface Props {
  children: React.ReactNode;
  noPadding?: boolean;
  breadcrumbs?: IBreadcrumb[];

}

export class FullContent extends React.PureComponent<Props, any> {
  public render() {

    return (
      <div className="content-frame-padding">
        {this.props.children}
      </div>
    );
  }
}
