import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { getShopifyStatus, IShopifyStatus } from '../../api';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';

type IProps = RouteComponentProps<any>;

interface IState {
  isLoading: boolean;
  credentials: IShopifyStatus[];
}

export class ShopifyPage extends React.Component <IProps, IState> {
  private _getShopifyStatus: CancelTokenSource;

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
      credentials: [],
    };

    this.getShopifyStatus = this.getShopifyStatus.bind(this);
  }

  componentDidMount() {
    this.getShopifyStatus();
  }

  getShopifyStatus() {
    if (this._getShopifyStatus) {
      this._getShopifyStatus.cancel('Cancelled getShopifyStatus');
    }
    this._getShopifyStatus = Axios.CancelToken.source();

    this.setState({ isLoading: true });
    getShopifyStatus(this.props.match.params['vendorId'], this._getShopifyStatus).then((credentials) => {
      this.setState({
        credentials,
        isLoading: false,
      });
    });
  }

  async syncNow(credential: number) {
    setTimeout(() => {
      alert('Store sync started.');
    },         0);
    await Axios.post(`/api/marketplace/sync-shopify-store/${credential}`);
  }

  render() {

    if (this.state.isLoading) {
      return <LoadingSpinner />;
    }

    let bodyText = (
      <div>
        <p style={{ fontWeight: 'bold' }}>
          The Shopify integration is currently restricted to vendors who request access
          and are able to meet certain requirements.
        </p>

        <h4>Requirements</h4>
        <ul>
          <li>Currently fulfilling online orders with a Shopify store</li>
          <li>Products available to purchase individually, at 1 unit</li>
          <li>Separate products for specific licensors, not as a variant</li>
          <li>Separate collections for each Affinity licensor</li>
        </ul>

        <p style={{ fontWeight: 'bold' }}>
          If you are interested in connecting your Shopify store with the Affinity Marketplace,
          and are prepared to meet the requirements above, please contact us to learn more.
        </p>

        <div style={{ textAlign: 'center' }}>
          <a href="mailto:marketplace@affinitylicensing.com" className="btn btn-primary">
            Contact Us
          </a>
        </div>
      </div>
    );

    if (this.state.credentials.length > 0) {
      const credRows = this.state.credentials.map(cred => <tr>
        <td style={{ fontWeight: 'bold', textOverflow: 'ellipsis' }}>{cred.store}</td>
        <td>{cred.isConnected ? 'Connected' : 'Not Connected'}</td>
        <td>{cred.productsSynced}</td>
        <td><button onClick={() => this.syncNow(cred.id)} className="btn btn-default btn-sm">Sync Now</button></td>
      </tr>);

      bodyText = (
        <table className="table table-portal table-hover">
          <thead>
            <tr>
              <th scope="col">Store</th>
              <th scope="col">Status</th>
              <th scope="col">Products Synced</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {credRows}
          </tbody>
        </table>
      );
    }

    return (
      <FullContent>
        <div className="panel panel-portal">
          <div className="panel-body">
            <div style={{ textAlign: 'center' }}>
              <img src="/assets/images/affinity/shopify-200.png" alt="Shopify" />
              <h3>Shopify Integration</h3>
            </div>
            { bodyText }
          </div>
        </div>
      </FullContent>
    );
  }
}
