import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { orderBy } from 'lodash';
import * as React from 'react';
import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { GSListGroup, Trademark, TrademarkEvent } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { FormImageWithPreview, GatewayModal, LoadingSpinner, ModalType } from '../../shared';

interface ImageForm {
  image: any;
  imagePreview: string;
}

export const TrademarkDetailPage = () => {
  const [trademark, setTrademark] = React.useState<Trademark | null>(null);
  const [deleteModalShown, setDeleteModalShown] = React.useState(false);
  const [defaultModalShown, setDefaultModalShown] = React.useState(false);
  const [imageModalShown, setImageModalShown] = React.useState(false);
  const [certModalShown, setCertModalShown] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const user = useContext(UserContext);

  React.useEffect(() => {
    getTrademark();

  },              []);

  const getTrademark = async () => {

    if (match) {
      const t = await Axios.get(`/api/trademarks/${match.params['id']}`);
      setTrademark(Trademark.fromApi(t.data.data));
    }

  };
  const deleteTrademark = async () => {

    if (trademark) {
      await Axios.delete(`/api/trademarks/${trademark.id}`);
      if (match) {
        const clientString = match.params['licensorId'] ? `/clients/${match.params['licensorId']}` : '';
        history.push(`${clientString}/brand/trademarks`);
      }

    }
  };

  const submitDefaultTrademark = async () => {
    setSaving(true);
    if (trademark) {
      const t = await Axios.post(`/api/trademarks/${trademark.id}/default`);
      setTrademark(Trademark.fromApi(t.data.data));
      setDefaultModalShown(false);
    }
    setSaving(false);
  };

  const addImage = async (v: ImageForm) => {
    if (trademark) {
      setUploading(true);

      const data = new FormData();
      data.append('image', v.image, v.image.name);

      const t = await Axios.post(`/api/trademarks/${trademark.id}/replace-image`, data);

      setTrademark(Trademark.fromApi(t.data.data));
      setUploading(false);
      setImageModalShown(false);

    }

  };
  const addCert = async (v: any) => {
    if (trademark) {
      setUploading(true);

      const data = new FormData();
      data.append('cert', v.certificate, v.certificate.name);

      const t = await Axios.post(`/api/trademarks/${trademark.id}/replace-certificate`, data);

      setTrademark(Trademark.fromApi(t.data.data));
      setUploading(false);
      setCertModalShown(false);

    }

  };

  const dataRow = (title: string, value: string, titleWidth: number = 300, bottomMargin: number = 20) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: bottomMargin }}>
        <span style={{ flexBasis: titleWidth }}><strong>{title}</strong></span>
        <span style={{ textAlign: 'left', flexGrow: 1 }}>{value}</span>
      </div>
    );
  };

  const gsListGroup = (group: GSListGroup) => {

    return (
      <div style={{ borderRadius: 4, border: '1px solid #eee', padding: 15, marginTop: 10, marginBottom: 10 }} >
          <h3>{group.groupTitle}</h3>
          <p className="help-block">
            {group.description}
          </p>
          {group.status ? dataRow('Class Status', group.status, 200, 10) : null}
          {group.internationalGSList.length ?
            dataRow('International Class(es)', group.internationalGSList.map(i => i.code).join(', '), 200, 10) :
            null
          }
          {group.usGSList.length ? dataRow('U.S Class(es)', group.usGSList.map(i => i.code).join(', '), 200, 10) : null}
          {group.dateOfFirstUse ?  dataRow('First Use', group.dateOfFirstUse.format('MMM YYYY'), 200, 10) : null}
          {group.firstUseInCommerce ?
          dataRow('Use in Commerce', group.firstUseInCommerce.format('MMM YYYY'), 200, 10) : null}

      </div>
    );

  };

  const body = trademark ? (
    <div className="row">
      <div className="col-md-4">
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          {trademark.image ?

            <img
              className="img-responsive"
              src={trademark.image.getSize('or')}
            />
            : null}
          {!trademark.isRegistered ? <button onClick={() => setImageModalShown(true)} style={{ marginTop: 15 }} className="btn btn-block btn-default">
            {trademark.image ? 'Replace' : 'Upload'} Image
          </button> : null}
          {!trademark.isRegistered ? <button onClick={() => setCertModalShown(true)} style={{ marginTop: 15 }} className="btn btn-block btn-default">
            {trademark.certUrl ? 'Replace' : 'Upload'} Certificate
          </button> : null}
        </div>

      </div>
      <div className="col-md-8">
        <h2><strong>{trademark.title}  {trademark.isDefault && user.type === 'admin' ? <FontAwesomeIcon style={{ color: 'green' }} icon={faCheck} /> : null}</strong></h2>
        {trademark.certUrl ? <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
          <a style={{ marginRight: 20 }} href={trademark.certUrl} target="_blank" className="btn btn-primary">
            Download Certificate
          </a>
          {trademark.serialNumber ?
          <a href={trademark.usptoLink} target="_blank" className="btn btn-default">View on USPTO</a> : null}
        </div> : null}
        {trademark.serialNumber ? dataRow('Serial #', trademark.serialNumber) : null}
        {trademark.registrationNumber ? dataRow('Registration #', trademark.registrationNumber) : null}
        {trademark.registrationDate ?
          dataRow('Registration Date', trademark.registrationDate ? trademark.registrationDate.format('MMM YYYY') : '')
          : null }
        {/*{trademark.earliestRenewalDate ?*/}
        {/*  dataRow('Earliest Renewal Date', trademark.earliestRenewalDate ? trademark.earliestRenewalDate.format('MMM YYYY') : '')*/}
        {/*  : null }*/}
        {/*{trademark.lastDateNoFee ?*/}
        {/*  dataRow('Last Date to Renew without Fees', trademark.lastDateNoFee ? trademark.lastDateNoFee.format('MMM YYYY') : '')*/}
        {/*  : null }*/}
        {/*{trademark.lastDateWithFee ?*/}
        {/*  dataRow('Last Date to Renew with Fees', trademark.lastDateWithFee ? trademark.lastDateWithFee.format('MMM YYYY') : '')*/}
        {/*  : null }*/}
        {trademark.filingDate ?
          dataRow('Filing Date', trademark.filingDate ? trademark.filingDate.format('MMM YYYY') : '') : null
        }
        {trademark.status ? dataRow('Status', trademark.status) : null}
        {trademark.statusDate ?
          dataRow('Status Date', trademark.statusDate ? trademark.statusDate.format('MMM YYYY') : '') : null
        }
        {trademark.type ? dataRow('Type', trademark.type) : null}
        {trademark.owner ? dataRow('Owner', trademark.owner) : null}
        {trademark.description ?
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
            <strong>Description</strong>
            <p>{trademark.description}</p>
          </div> : null}
        {trademark.events.length ?
          <div className="table-responsive">
            <strong>Event History</strong>
            <table className="table table-bordered table-striped">
              <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Date</th>
              </tr>
              </thead>
              <tbody>
              {trademark.events.map((te:TrademarkEvent) => {
                return <tr>
                  <td>{te.fullCode}</td>
                  <td>{te.description}</td>
                  <td>{te.date.format('MMM. DD, YYYY')}</td>
                </tr>;
              })}
              </tbody>
            </table>
          </div>
         : null}
        {trademark.gsGroups.length ?
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
            <strong>Goods and Services</strong>
            {orderBy(trademark.gsGroups, ['groupTitle'], ['asc']).map(g => gsListGroup(g))}

          </div> : null}

        <button style={{ marginRight: 20 }} onClick={() => setDeleteModalShown(true)} className="btn btn-default">Delete Trademark</button>
        { user.type === 'admin' ? <button onClick={() => setDefaultModalShown(true)} className="btn btn-default">{ trademark.isDefault ? 'Remove Default' : 'Set Default' }</button>
          : null
        }

      </div>

    </div>

  ) : (<LoadingSpinner />);

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          {body}

        </div>
      </div>
      <GatewayModal
        title="Remove Trademark"
        shown={deleteModalShown}
        onClose={() => setDeleteModalShown(false)}
        type={ModalType.Danger}
      >
        <Modal.Body>
          <p className="text-center">Are you sure that you want to delete this trademark?</p>
        </Modal.Body>

        <Modal.Footer>
          <button onClick={() => setDeleteModalShown(false)} className="btn btn-default pull-left">Close</button>
          <button onClick={() => deleteTrademark()} className="btn btn-danger pull-right">Yes, Delete Trademark</button>
        </Modal.Footer>

      </GatewayModal>
      <GatewayModal
        title="Set Default"
        shown={defaultModalShown}
        onClose={() => setDefaultModalShown(false)}
        type={ModalType.Primary}
      >
        <Modal.Body>
          <p className="text-center">Assigning this trademark as the default trademark will automatically tag it on products found online. There can only be one default. If another trademark is the current default it will be replaced.</p>
        </Modal.Body>

        <Modal.Footer>
          <button onClick={() => setDefaultModalShown(false)} className="btn btn-default pull-left">Close</button>
          <button onClick={() => submitDefaultTrademark()} className="btn btn-primary pull-right">{ saving ? 'Saving...' : (trademark && trademark.isDefault ? 'Remove Default' : 'Set Default') }</button>
        </Modal.Footer>

      </GatewayModal>
      <GatewayModal
        title="Upload Image"
        shown={imageModalShown}
        onClose={() => setImageModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
          initialValues={{
            image: null,
            imagePreview: '',
          }}
          onSubmit={addImage}
          validationSchema={Yup.object({
            image: Yup.mixed().required('Image is required.'),
          })}

        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <FormImageWithPreview
                  title="Image"
                  imagePreview={formProps.values.imagePreview}
                  onChange={(preview, image) => {
                    formProps.setFieldValue('image', image);
                    formProps.setFieldValue('imagePreview', preview);
                  }}
                  labelClass="no"
                />

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setImageModalShown(false)} className="btn btn-default pull-left">
                  Cancel
            </button>
                <button className="btn btn-primary pull-right">{uploading ? 'Submitting...' : 'Submit'}</button>

              </Modal.Footer>

            </Form>

          )}

        </Formik>
      </GatewayModal>
      <GatewayModal
        title="Upload Certificate"
        shown={certModalShown}
        onClose={() => setCertModalShown(false)}
        type={ModalType.Primary}
      >
        <Formik
          initialValues={{
            certificate: null,
          }}
          onSubmit={addCert}
          validationSchema={Yup.object({
            certificate: Yup.mixed().required('Certicate is required.'),
          })}

        >
          {(formProps: FormikProps<any>) => (
            <Form>
              <Modal.Body>
                <div className="form-group">
                  <label>Certificate File</label>
                  <input
                    type="file"
                    onChange={e => e.target.files && formProps.setFieldValue('certificate', e.target.files[0])}
                  />
                </div>

              </Modal.Body>
              <Modal.Footer>
                <button type="button" onClick={() => setCertModalShown(false)} className="btn btn-default pull-left">
                  Cancel
            </button>
                <button className="btn btn-primary pull-right">{uploading ? 'Submitting...' : 'Submit'}</button>

              </Modal.Footer>

            </Form>

          )}

        </Formik>
      </GatewayModal>
    </FullContent>

  );
};
