import axios, { AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios';
import { LatLng } from 'leaflet';
import { logIfCancelled } from '../../api';
import { AffinityClient, DesignPhase, ProductCategory, Vendor } from '../../shared';
import { IUploadItemFormValues } from './DesignUpload/UploadItem';

export const getDesigns = (params: any, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.get('/api/designs', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getDesigns');
      return error;
    });
};

export const getDesign = (id: number, params: any, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.get(`/api/designs/${id}`, { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getDesign');
      return error;
    });
};

export const submitComment = (id: number, message: string, group: number, attachedFiles: File[], coords?: LatLng): AxiosPromise => {
  const formData = new FormData();
  formData.append('message', message);
  formData.append('group_id', group.toString());

  if (coords) {
    formData.append('x_coord', (+coords.lng.toFixed(2) * 100).toString());
    formData.append('y_coord', (-coords.lat.toFixed(2) * 100).toString());
  }

  attachedFiles.forEach((file, index) => {
    formData.append(`attachedFiles[${index}]`, file);
  });

  return axios.post(`/api/designs/${id}/comment`, formData)
    .catch((error) => {
      logIfCancelled(error, 'submitComment');
      return error;
    });
};

export const getCounts = (params: any, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.get('/api/design-counts', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getCounts');
      return error;
    });
};

export interface IAvailableProductEntry {
  categoryId: number;
  categoryTree: {
    id: number;
    parent_id: number;
    name: string;
  }[];
}
type IAvailableProductsResponse = IAvailableProductEntry[];

export const getAvailableProducts = (vendorId?: string): Promise<IAvailableProductsResponse> => {

  const url = vendorId ? `/api/products/account_list/${vendorId}` : '/api/products/account_list';

  const mapData = (row: any): IAvailableProductEntry => {
    const currentCategory = Number(row.product_category_id);
    const tree: {id: number, parent_id: number, name: string}[] = row.categoryTree.map((item: any) => ({
      id: Number(item.id),
      name: item.name,
      parent_id: Number(item.parent_id),
    }));

    const sortedTree = tree.sort((a, b) => {
      if (a.id === currentCategory) {
        return 1;
      }
      if (a.parent_id === 0) {
        return -1;
      }
      if (a.id === b.parent_id) {
        return -1;
      }
      if (b.id === a.parent_id) {
        return 1;
      }
      return 0;
    });

    return {
      categoryId: currentCategory,
      categoryTree: sortedTree,
    };
  };

  return axios.get(url)
    .catch((error) => {
      logIfCancelled(error, 'getAvailableProducts');
      return error;
    }).then((response: AxiosResponse<any>) => {
      const data = response.data;

      return data.map(mapData);
    });
};

export const getClients = (cancelTokenSource?: CancelTokenSource, isActive?: boolean): Promise<AffinityClient[]> => {

  const params = { include: 'market,profile' };

  if (isActive) {
    params['is_active'] = 1;
    params['accepting_designs'] = 1;
  }
  const config = { params };
  if (cancelTokenSource) {
    config['cancelToken'] = cancelTokenSource.token;
  }

  return axios.get('/api/clients', config)
    .then(response => (
      response.data.data.map((item: any) => (
        new AffinityClient({
          id: item.id,
          short_name: item.name,
          market: item.market,
          profile: item.profile,
        })
      )).filter((licensor: any) => licensor.name)
    )).catch((error) => {
      logIfCancelled(error, 'getClients');
      return error;
    });
};

interface IUploadDesignParams {
  fileId: number;
  productCategoryId: number | null;
  primaryClientId: number | null;
  isAutotaggedClient: boolean;
  title: string;
  internalCode: string;
  description: string;
  vendorAccountId: number | null;
}

interface IDesignUploadResponse {
  id: number;
  primaryClientId: number | null;
  productCategories: ProductCategory[];
}

export const uploadDesign = (design: IUploadDesignParams): Promise<null | IDesignUploadResponse> => {

  const requestBody = {
    file_id: design.fileId,
    product_category_id: design.productCategoryId,
    primary_client_id: design.primaryClientId,
    is_autotagged_client: design.isAutotaggedClient,
    title: design.title,
    internal_code: design.internalCode,
    description: design.description,
    vendor_account_id: design.vendorAccountId,
    channel_retail: 0,
    channel_direct: 0,
    channel_internal: 0,
  };

  return axios.post('/api/designs/upload', requestBody)
    .then((response) => {
      return {
        id: Number(response.data.data.id),
        primaryClientId: response.data.data.primary_client ? Number(response.data.data.primary_client.id) : null,
        productCategories: response.data.data.product_categories.map((c: any) => ProductCategory.fromApi(c)),
      };
    }).catch(error => null);
};

export const updateDesign = (design: IUploadItemFormValues & { designId: number }): Promise<boolean> => {
  if (!design.designId) {
    return Promise.resolve(false);
  }

  const requestBody = {
    title: design.title,
    product_id: null,
    product_categories: design.productEntry.map(c => c.id),
    is_autotagged_client: null,
    description: design.description,
    primary_client_id: design.organization && design.organization.length ? design.organization[0].id : null,
  };

  return axios.put(`api/designs/${design.designId}`, requestBody)
    .then(response => true)
    .catch(error => false);
};

export const getLicensorDetails = (licensors: AffinityClient[], cancelTokenSource: CancelTokenSource): AxiosPromise => {
  const params = {
    ids: licensors.length ? licensors.map(licensor => licensor.id).join() : null,
    include: 'productGuidelines,branding,designTerms,profile,insignia,insignia.vendors',
    internal: true,
  };
  return axios.get('/api/clients', { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getLicensorDetails');
      return error;
    });
};

export interface DesignUpdateParams {
  insignia: number[];
  primaryClient: number | null;
  productCategories: number[];
  secondaryClients: number[];
}

export const updateDesignDetails =
  (id: number, params: any, cancelTokenSource: CancelTokenSource): AxiosPromise => {
    return axios.put(`/api/designs/${id}/tags`, params, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'updateDesign');
      return error;
    });
  };

export const expediteDesign = (id: number, shouldExpedite: boolean, cancelTokenSource: CancelTokenSource): AxiosPromise => {
  return axios.put(`/api/designs/${id}/expedite`, { expedite: shouldExpedite }, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'expediteDesign');
      return error;
    });
};

export const reviewDesign = (
  id: number,
  phase: DesignPhase,
  message: string,
  notify: boolean,
  cancelTokenSource: CancelTokenSource,
  attachedFiles: File[],
  coords?: LatLng,
): AxiosPromise => {

  const formData = new FormData();
  formData.append('message', message);
  formData.append('notify', notify ? '1' : '0');
  formData.append('phase_id', phase.id.toString());

  if (coords) {
    formData.append('x_coord', (+coords.lng.toFixed(2) * 100).toString());
    formData.append('y_coord', (-coords.lat.toFixed(2) * 100).toString());
  }

  attachedFiles.forEach((file, index) => {
    formData.append(`attachedFiles[${index}]`, file);
  });
  return axios.post(`/api/designs/${id}/review`, formData, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'reviewDesign');
      return error;
    });
};

export const getDesignLicenses = (
  vendor: Vendor,
  licensors: AffinityClient[],
  cancelTokenSource: CancelTokenSource,
): AxiosPromise => {
  const params = {
    vendor_id: vendor.id,
    licensor_id: licensors.map(licensor => licensor.id).join(','),
  };
  return axios.get('/api/design-licenses', { params, cancelToken: cancelTokenSource.token })
      .catch((error) => {
        logIfCancelled(error, 'getDesignLicenses');
        return error;
      });
};
interface IUploadFileResponse {
  id: number;
}
export const uploadDesignFile = (file: File): Promise<IUploadFileResponse> => {

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const formData = new FormData();
  formData.append('image', file, file.name);

  return axios.post('/api/designs/image', formData, config)
    .then((response) => {
      return {
        id: Number(response.data.file_id),
      };
    });
};

export const toggleDesignExpedite = (designId: number, expedite: boolean): Promise<boolean> => {
  return axios.put(`/api/designs/${designId}/expedite`, { expedite })
    .then(result => true)
    .catch(error => false);
};

export const deleteDesign = (designId: number) : Promise<boolean> => {
  return axios.delete(`/api/designs/${designId}`)
    .then(result => true)
    .catch(error => false);
};

export const getInferredClient = (filename: string): Promise<AffinityClient | null> => {
  const params = {
    name: filename,
  };

  return axios.get('/api/designs/inferred-client', { params })
    .then(response => response.data ? new AffinityClient(response.data.data) : null)
    .catch(error => null);
};

export const getCanExpedite = (vendorId?: string): Promise<boolean> => {
  const params = {
    vendor_id: vendorId,
  };

  return axios.get('/api/designs/permissions', { params })
    .then(response => response.data.data.can_expedite)
    .catch(response => false);
};

export const submitDesignIteration = (id: number, fileId: number, categories: ProductCategory[], cancelTokenSource: CancelTokenSource) => {
  return axios.post(`/api/designs/${id}/iterations`, { file_id: fileId, categories: categories.map(c => c.id) }, { cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'submitDesignIteration');
      return error;
    });
};

export const getDesignNavigation = (id: number, params: any, cancelTokenSource: CancelTokenSource) => {
  return axios.get(`/api/designs/${id}/navigation`, { params, cancelToken: cancelTokenSource.token })
    .catch((error) => {
      logIfCancelled(error, 'getDesignNavigation');
      return error;
    });
};
