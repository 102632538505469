import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AboutModal } from '../Royalties/RoyaltyAnalytics/AboutModal';

interface IProps {
  link: string;
  isPartial: boolean;

}

export const ChartFooter = (props: IProps) => {
  const [modalShown, setModalShown] = React.useState(false);
  return <div className="performance-chart-footer">
    <div className="text-muted">
      {props.isPartial ? <div style={{ cursor: 'pointer' }} onClick={() => setModalShown(true)}>
        <FontAwesomeIcon icon={faInfoCircle} /> Partial representation of sales
      </div> : null}
    </div>
    <Link className="" to={props.link}><strong>View More</strong></Link>
    <AboutModal shown={modalShown} onClose={() => setModalShown(false)} />

  </div>;
};
