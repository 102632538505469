import { DistributionChannel } from '.';

interface ClientRuleApiResponse {
  id: number;
  account_id: number;
  product_category_id: number;
  brand_mark_id: number;
  distribution_channel_id: number;
  excluded_vendor_ids: number[];
  approved: boolean;
  distribution_channel: DistributionChannel;
}
export class ClientRule {
  id: number;
  licensorId: number;
  productCategoryId: number;
  brandMarkId: number;
  distributionChannelId: number;
  approved: boolean;
  excludedVendors: number[];
  channel: DistributionChannel;

  static fromApi(data: ClientRuleApiResponse) {
    const rule = new ClientRule();
    rule.id = Number(data.id);
    rule.licensorId = Number(data.account_id);
    rule.productCategoryId = Number(data.product_category_id);
    if (data.brand_mark_id) {
      rule.brandMarkId = Number(data.brand_mark_id);
    }
    if (data.distribution_channel_id) {
      rule.distributionChannelId = Number(data.distribution_channel_id);
    }
    rule.excludedVendors = data.excluded_vendor_ids.map(id => Number(id));
    rule.approved = data.approved;
    if (data.distribution_channel) {
      rule.channel = new DistributionChannel(data.distribution_channel);
    }
    return rule;
  }
}
