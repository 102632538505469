import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { AboutModal } from './AboutModal';

interface IState {
  modalShown: boolean;
}

export class PartialRepresentation extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      modalShown: false,
    };

    this.showAboutModal = this.showAboutModal.bind(this);
    this.hideAboutModal = this.hideAboutModal.bind(this);
  }

  showAboutModal() {
    this.setState({ modalShown: true });

  }

  hideAboutModal() {
    this.setState({ modalShown: false });
  }

  render() {
    return (
      <div>
        <p className="text-danger">
          <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
          Partial representation of sales.
          <a style={{ cursor :'pointer' }} onClick={this.showAboutModal}> Learn more </a>
        </p>
        <AboutModal shown={this.state.modalShown} onClose={this.hideAboutModal} />
      </div>
    );

  }
}
