import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { Note } from '../../shared';
import { GatewayModal, ModalType } from '../shared';
import { NoteForm, NoteFormValidation, NoteFormValues } from './forms';

interface IProps {
  note: Note | null;
  submitting: boolean;
  shown: boolean;
  onClose: () => void;
  onSubmit: (v: NoteFormValues) => void;
}

export const EditNoteModal = (props: IProps) => {
  if (!props.note) {
    return null;
  }
  const formValues: NoteFormValues = {
    note: props.note.note,
    users: props.note.adminUsers,
    accountUsers: props.note.accountUsers,
    contactMethod: props.note.contactMethod,
    attachments: [],
    currentAttachments: props.note.attachments.length ? props.note.attachments : undefined,
    noteDate: props.note.occurredAt.format('YYYY-MM-DD'),
    contactOrigin: props.note.contactOrigin,
  };

  return (
   <GatewayModal type={ModalType.Primary} shown={props.shown} onClose={props.onClose} title="Edit Note">
      <Formik onSubmit={props.onSubmit} validationSchema={NoteFormValidation} initialValues={formValues}>
        {(formProps: FormikProps<NoteFormValues>) => (
          <Form>
            <Modal.Body>
              <NoteForm {...formProps} />
            </Modal.Body>
            <Modal.Footer>
              <button onClick={props.onClose} type="button" className="btn btn-default pull-left">Cancel</button>
              <button type="submit" className="btn btn-primary pull-right">
                {props.submitting ? 'Submitting...' : 'Submit'}
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
   </GatewayModal>
  );

};
