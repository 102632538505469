import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { UserContext } from '../../../contexts';
import { MessagesIndexPage } from './MessagesIndexPage';

export class MessagesWrapper extends React.Component<RouteComponentProps<any>, any> {

  render() {
    return (
      <UserContext.Consumer>
        {(user) => {
          return <MessagesIndexPage {...this.props} user={user} />;
        }}
      </UserContext.Consumer>
    );

  }
}
