import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeError } from '@stripe/stripe-js';
import axios from 'axios';
import { FunctionComponent, ReactNode, useState } from 'react';
import * as React from 'react';

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

interface ISubmitButtonProps {
  error: any;
  processing: boolean;
  disabled: boolean;
  children?: ReactNode;
}

const SubmitButton: FunctionComponent<ISubmitButtonProps> = (props: ISubmitButtonProps) => (
  <button
    className={`btn ${props.error ? 'btn-danger' : 'btn-default'}`}
    type="submit"
    disabled={ props.processing || props.disabled }
  >
    { props.processing ? 'Processing...' : props.children }
  </button>
);

const ErrorMessage = (props: { children: any }) => (
  <div className="alert alert-danger" role="alert">
    {props.children}
  </div>
);

export const CardSetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<null|StripeError>(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    if (error) {
      cardElement.focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const setupIntentSecret = await axios.post('/api/setup-intents').then((response) => {
      return response.data.client_secret;
    });

    const setupIntent = await stripe.confirmCardSetup(setupIntentSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    setProcessing(false);

    if (setupIntent.error) {
      setError(setupIntent.error);
      return;
    }
  };

  const handleChange = (event: StripeCardElementChangeEvent) => {
    if (event.error === null) {
      setError(null);
    }
    setCardComplete(event.complete);
  };

  return (
    <form onSubmit={handleSubmit}>

      <CardElement
        className="form-control"
        options={CARD_OPTIONS}
        onChange={handleChange}
      />
      { error ? <ErrorMessage>{error.message}</ErrorMessage> : null }
      <SubmitButton
        disabled={!stripe}
        error={null}
        processing={processing}
      >
        Save Card
      </SubmitButton>
    </form>
  );
};
