import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

const defaultProps = {
  totalResults: 0,
  currentPage: 1,
  totalCount: 0,
  hasNext: false,
};

interface IProps {
  totalResults: number;
  currentPage: number;
  hasNext: boolean;
  choosePageFn: (i: number) => any;
}

export class IndexTableFooter extends React.Component<IProps, any> {
  public static defaultProps = defaultProps;

  public constructor(props: any) {
    super(props);

    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
  }

  public handleClickPrev() {
    this.props.choosePageFn(this.props.currentPage - 1);
  }

  public handleClickNext() {
    this.props.choosePageFn(this.props.currentPage + 1);
  }

  public render() {

    const hasNext = this.props.hasNext;
    const hasPrev = this.props.currentPage > 1;

    return (
        <div className="panel panel-portal">
            <div className="panel-body" style={{ background: '#f9f9f9', padding: '5px' }}>
                <div className="btn-toolbar text-center">
                    <button
                        onClick={this.handleClickPrev}
                        disabled={!hasPrev}
                        className="btn btn-sm btn-default pull-left"
                    >
                        <FontAwesomeIcon icon={faArrowLeft} /> Previous Page
                    </button>
                    <button
                        onClick={this.handleClickNext}
                        disabled={!hasNext}
                        className="btn btn-sm btn-default pull-right ng-scope"
                    >
                        Next Page <FontAwesomeIcon className="icon-right" icon={faArrowRight} />
                    </button>
                  <div className="text-center text-muted">
                    Page {this.props.currentPage} ({this.props.totalResults} total results)
                  </div>
                </div>
            </div>
        </div>
    );
  }
}
