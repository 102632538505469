import { orderBy } from 'lodash';
import * as React from 'react';
import { AffinityClient, BrandCategoryType, BrandMarkAsset } from '../../../shared';

interface IProps {
  licensor: AffinityClient;
  brandMarks: BrandMarkAsset[];
}

interface SortedBrandAsset {
  insignia: { id: number, title: string, order: number };
  brands: BrandMarkAsset[];
}

export class LicensorInsignia extends React.Component<IProps, any> {

  sortedBrandAssets(): SortedBrandAsset[] {
    const sortedInsignias: SortedBrandAsset[] = [];
    const noInsignia: SortedBrandAsset = { insignia: { title: 'Other', id: 0, order: 999 }, brands: [] };
    this.props.brandMarks.forEach((item) => {
      if (item.category.type === BrandCategoryType.IMAGE && item.previewFile && item.previewFile.url) {
        if (item.insignia) {
          const index = sortedInsignias.findIndex(sorted => Number(sorted.insignia.id) === Number(item.insignia.id));
          if (index !== -1) {
            sortedInsignias[index].brands.push(item);
          } else {
            sortedInsignias.push({ insignia: item.insignia, brands: [item] });
          }
        } else {
          noInsignia.brands.push(item);
        }
      }
    });
    sortedInsignias.push(noInsignia);
    sortedInsignias.forEach((sorted: SortedBrandAsset, index) => {
      sortedInsignias[index].brands = orderBy(
        sorted.brands,
        ['isPrimary', 'title'],
        ['desc', 'asc'],
      );
    });
    return orderBy(sortedInsignias, i => i.insignia.order, 'asc');
  }

  render() {
    const assets = this.sortedBrandAssets().map((sorted) => {
      const brands = sorted.brands.map(a => (
        <div key={a.id} className="design-brand-asset">
          <a target="_blank" className="design-brand-image-link" href={a.previewFile && a.previewFile.url || ''}>
            <img src={a.previewFile && a.previewFile.url || ''} className="img-responsive" />
          </a>
          { a.isPrimary ? <div className="label label-primary" >PRIMARY</div> : null }
        </div>
      ));

      return (
       <div key={sorted.insignia.id}>
         <div className="design-brand-insignia">
           <strong>{sorted.insignia.title}</strong>
         </div>
         <div className="design-brand-image-container">
           {brands}
         </div>
         <hr />
       </div>
      );
    });
    return (
      <div>
        <h3 className="text-primary">{this.props.licensor.name}</h3>
        {assets}
      </div>
    );

  }

}
