import Axios from 'axios';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Redirect, useHistory } from 'react-router';
import { UserContext } from '../../../contexts';
import { MarketplacePostTag } from '../../../shared';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { PostForm } from './PostForm';

export const NewPostPage = () => {

  const [creatingPost, setCreatingPost] = React.useState(false);

  const history = useHistory();
  const user = React.useContext(UserContext);

  if (user.type !== 'admin') {
    return <Redirect to="/" />;
  }

  const createPost = async (values: any)  => {

    setCreatingPost(true);
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('site_id', values.site);
    formData.append('licensor_id', values.licensor);
    formData.append('image', values.image);
    formData.append('published_at', values.published);
    if (values.tags && values.tags.length) {
      values.tags.forEach((t: MarketplacePostTag) => formData.append('tags[]', `${t.id}`));
    }

    const post = await Axios.post('/api/marketplace/posts', formData);
    if (post.data.data) {
      history.push('/marketplace/posts');
    }

  };

  return  (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('New Post')} />
      <div className="panel panel-portal">
        <div className="panel-body">
          <h3><strong>New Post</strong></h3>
          <hr />
          <PostForm submitting={creatingPost} onSubmit={createPost} />
        </div>
      </div>

    </FullContent>
  );

};
