import Axios from 'axios';
import * as moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { UserContext } from '../../contexts';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';

interface Payout {
  id: string;
  arrivalDate: moment.Moment;
  amount: string;
  destination: string;
}

export const StripePayoutsPage = () => {
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [loading, setLoading] = useState(true);
  const user = React.useContext(UserContext);

  useEffect(() => {
    getPayouts();
  },        []);

  const getPayouts = async () => {
    const payouts = await Axios.get('/api/stripe-payouts')
      .then(response => response.data.data.map((p: any) => ({
        id: p.id,
        arrivalDate: moment(p.arrival_date),
        amount: p.amount_fmt,
        destination: p.destination,
      }))).catch(() => {
        setLoading(false);
      });
    setPayouts(payouts);
    setLoading(false);
  };

  const requestPayoutEmail = async (payout: string) => {
    await Axios.get(`/api/stripe-exports/${payout}`);
    alert(`An email will be sent to ${user.email} with the CSV attached.`);
  };

  const rows = payouts.map((payout) => {

    return <tr key={payout.id}>
      <td>{payout.arrivalDate.format('MMMM Do YYYY, h:mm a')}</td>
      <td>{payout.destination}</td>
      <td>${payout.amount}</td>
      <td><button onClick={() => requestPayoutEmail(payout.id)} className="btn btn-default btn-sm">Request Export</button></td>
    </tr>;
  });

  const table = loading ? (
    <LoadingSpinner />
  ) : (
    <div className="table-responsive">
      <table className="table table-portal table-hover">
        <thead>
        <tr>
          <th scope="col">Arrival Date</th>
          <th scope="col">Destination</th>
          <th scope="col">Amount</th>
          <th scope="col">Download</th>
        </tr>
        </thead>
        <tbody>
        { rows }
        </tbody>
      </table>
    </div>
  );

  return (
    <FullContent breadcrumbs={[{ name: 'Stripe Payouts' }]}>
      <DocumentTitle title={getAffinityPageTitle('Stripe Payouts')}>
        <div>
          <div className="panel panel-portal">
            <div className="panel-heading">
              <h3>Stripe Payouts</h3>
            </div>
            <div className="panel-body" style={{ padding: '5px' }}>
              { table }
            </div>
          </div>
        </div>
      </DocumentTitle>
    </FullContent>
  );
};
