import { faCaretRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartData, ChartOptions } from 'chart.js';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { formattedFiscalYear, toUSD } from '../../../utils';
import { QuarterStats } from './models';
import { PartialRepresentation } from './PartialRepresentation';

interface IProps {

  quarters: QuarterStats[];
  quarter?: 'Q1' | 'Q2' | 'Q3' | 'Q4';
  year: string;
  isPartial: boolean;
  hasFilters: boolean;
}

export class QuarterOverQuarter extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  mapRoyalties(stat: QuarterStats | undefined) {
    if (stat) {
      return stat.totalRoyalties;
    }
    return 0;
  }

  render() {
    const quarters = this.props.quarters.filter((quarter) => {
      return quarter.name === this.props.quarter && quarter.totalRoyalties > 0;
    });

    const years = quarters.map(quarter => `${this.props.quarter} ${formattedFiscalYear(quarter.year)}`);
    const barData: ChartData = {
      labels: years,
      datasets: [{
        label: this.props.quarter,
        data: quarters.map(this.mapRoyalties),
      }],
    };

    const barOptions: ChartOptions = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        callbacks: {
          label: (tooltip, data: any) => {
            if (tooltip.index !== undefined && tooltip.datasetIndex !== undefined) {
              const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              const quarter = data.datasets[tooltip.datasetIndex].label;
              return `${quarter}: ${toUSD(value)}`;
            }
            return '$';

          },

        },

      },

      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: (value, index, values) => {
              return `${toUSD(value)}`;

            },
          },
        }],

      },
      plugins: {
        colorschemes: {
          scheme: 'tableau.MillerStone11',
        },
      },
    };

    const partialContent = this.props.isPartial ? (<PartialRepresentation />) : null;
    const quarterTotal  = quarters.reduce(
      (sum, quarter) => {
        const year = Number(this.props.year.split('-')[0]);
        if (quarter.year === year) {
          return sum + quarter.totalRoyalties;
        }
        return sum;
      }
    , 0);
    const filteredText = this.props.hasFilters ? (
      <span>
        &nbsp;<FontAwesomeIcon className="text-muted" icon={faCaretRight} />
        &nbsp;Filtered
      </span>
    ) : null;

    return (
      <div className="panel panel-portal">
        <div className="panel-body">
          <div className="analytics-header">
            {this.props.quarter} {this.props.year}{filteredText}:
          <span className="text-primary"> {toUSD(quarterTotal)}</span>
          </div>
          <div>
            <FontAwesomeIcon icon={faInfoCircle} /> &nbsp;
            <span className="text-muted">Compare the selected quarter with QOQ totals</span>
          </div>

          {partialContent}
        </div>
        <div className="panel-body">
          <div>
            <Bar height={300} data={barData} options={barOptions} />
          </div>
        </div>
      </div>

    );

  }

}
