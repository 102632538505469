import { faArrowRight, faCheck, faDownload, faExclamationTriangle, faSync, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Link, Redirect, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { UserContext } from '../../../contexts';
import { AffinityImage, ReportProcessingStatus, RoyaltyReport, Vendor } from '../../../shared';
import { ContentWithSidebar } from '../../ContentFrame';
import { AffinityDropzone } from '../../Navigation';
import { CurrencyLabel, GatewayModal, LoadingSpinner, ModalType } from '../../shared';
import { NoSalesPanel } from '../royalties-shared/NoSalesPanel';
import ReportActionBox from '../royalties-shared/ReportActionBox';
import ReportAdminBox from '../royalties-shared/ReportAdminBox';
import ReportSubmissionInformation from '../royalties-shared/ReportSubmissionInformation';
import { useRoyaltyReport } from '../royalties-shared/useRoyaltyReport';
import { ReportDataTable } from '../RoyaltyReportSubmissionPage';
import ReportOldFiles from './ReportOldFiles';

export const RoyaltyReportIndexPage = () => {
  const match = useRouteMatch();
  const report = useRoyaltyReport();
  const user = React.useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const [uploading, setUploading] = React.useState(false);
  const [hasUploaded, setHasUploaded] = React.useState(false);
  const [noSalesModal, setNoSalesModal] = React.useState(false);
  const [validationModal, setValidationModal] = React.useState(false);
  const [uploadModal, setUploadModal] = React.useState(false);
  const [queueModal, setQueueModal] = React.useState(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [loadingRollups, setLoadingRollups] = React.useState(false);
  const [uploadErrors, setUploadErrors] = React.useState<string[]>([]);
  const [resubmissionModalShown, setResubmissionModalShown] = React.useState(false);
  const [processingStatus, setProcessingStatus] = React.useState<ReportProcessingStatus>(new ReportProcessingStatus());

  React.useEffect(() => {
    getRollup();
    getProcessingStatus();
    report.enableHandler();

  },              []);

  React.useEffect(() => {
    if (location.search.includes('showResubmission')) {
      setResubmissionModalShown(true);
      history.replace(`${report.reportId}`);
    }

  },              [location.search]);

  if (!match) {
    return <Redirect to="/" />;
  }

  const uploadFile = async (file: File) => {
    if (match) {
      const resetStatus = new ReportProcessingStatus();
      resetStatus.loading = false;
      setProcessingStatus(resetStatus);

      const p = new Promise(r => setTimeout(r, 4000));
      p.then(() => getProcessingStatus());
      setUploading(true);
      setHasUploaded(true);
      const data = new FormData();
      data.append('report', file);
      const id = match.params['id'];
      const a = await Axios.post(`/api/royalty-reports/${id}/upload`, data)
        .catch((e) => {
          setUploadErrors(e.response.data.errors);
          setHasUploaded(false);
          return false;
        });
      if (a) {
        // setUploading(false); //lets wait for this to be checked from the upload-status call
        if (typeof a === 'object' && a.data === 'Report Queued') {
          report.update(false);
        } else {
          report.update(false);
        }

      } else {
        setUploading(false);
        setUploadModal(true);
      }
    }
  };

  const getRollup = async () => {
    setLoadingRollups(true);
    const r = await Axios.get(`/api/royalty-reports/${report.reportId}/rollups?type=schedule`);
    const rollups = r.data.data.map((d: any) => {
      if (d.licensor.image) {
        d.licensor.image = AffinityImage.fromApi(d.licensor.image);
      } else {
        d.licensor.image = new AffinityImage();
      }
      return d;
    });
    setRows(rollups);
    setLoadingRollups(false);
  };

  const getProcessingStatus = async () => {
    const d = await Axios.get(`/api/royalty-reports/${report.reportId}/upload-status`);
    const data = d.data.data;
    setProcessingStatus(ReportProcessingStatus.fromApi(data));

    if (!data.processed && !uploading) {
      await new Promise(r => setTimeout(r, 3000));
      getProcessingStatus();
    } else {
      setHasUploaded(false);
      setUploading(false);
    }

  };

  const nextStep = async (r: RoyaltyReport) => {
    if (!uploading) {
      if (processingStatus.processed && processingStatus.requiredIssues.length === 0) {
        if (processingStatus.unmappedCategoryCount) {
          history.push(`${r.id}/item-types`);
        } else {
          history.push(`${r.id}/sales-data`);
        }
      }
    }
  };

  if (!report.report || report.loading || processingStatus.loading) {
    return <div><LoadingSpinner /></div>;
  }

  const r = report.report;

  // Simple way to redirect users.
  if (user.type === 'admin' && !match.params['vendorId']) {
    return <Redirect to={`/vendors/${r.vendor.id}/royalties/${r.id}`} />;
  }

  const adminBox = user.type === 'admin' ? (
    <ReportAdminBox resetReport={() => {
      setProcessingStatus(new ReportProcessingStatus());
      getProcessingStatus();
    }} user={r.adminUser} report={r} setReport={console.log} />
  ) : null;

  let dropArea = <div></div>;

  if (!r.salesDataFile && !hasUploaded) {
    dropArea = <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
      {!uploading && !processingStatus.processing ? <button className="btn btn-primary">
        {uploading ? 'Uploading...'  : 'Upload Sales Data File'}
      </button> : null}
      <p className="text-center text-muted">Excel, CSV, and OpenOffice files are accepted</p>
    </div>;
  } else {
    dropArea = <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <div>
        {r.salesDataFile ?
          <p className="no-margin strong">
            <strong>{r.salesDataFile.previousFileName}</strong> <span style={{ marginLeft: 15 }}> <a target="_blank" href={r.salesDataFile.url} onClick={e => e.stopPropagation()}><FontAwesomeIcon className="text-primary" icon={faDownload} /></a></span>
          </p>
          : null}
        {!processingStatus.processing && !uploading ? processingStatus.errorCount > 0 ?
          <p className="no-margin text-muted"><FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} /> Fix errors and reupload</p> :

          <p className="no-margin text-muted"><FontAwesomeIcon className="text-success" icon={faCheck} /> <NumberFormat displayType="text" thousandSeparator={true} value={processingStatus.totalLineItems} /> transactions imported.</p>

          : (
          <p className="no-margin text-muted">Upload In Progress.</p>
        )}

      </div>
      <div>
        {processingStatus.processing || uploading ? null : <button disabled={processingStatus.processing} style={{ marginRight: 15 }} className="btn btn-default"><FontAwesomeIcon className="text-primary" spin={uploading} icon={faSync} /> Re-Upload Data</button>}

      </div>
    </div>;
  }

  const progressSection = (
    <div>
      {processingStatus.processing || uploading || hasUploaded ? <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 15, paddingLeft: 40, paddingRight: 40 }}>
        <h4 className="text-primary"><strong>Upload In Progress</strong></h4>
        <ProgressBar striped active={!processingStatus.processed} style={{ width: '100%', height: 20, marginBottom: 10 }} now={processingStatus.currentPercent} />
        <p className="text-muted">{processingStatus.currentStatus}</p>
        <p><strong>{processingStatus.userAction}</strong></p>
      </div> : null}
    </div>
  );

  const items = rows.map(r => r.items).reduce((p: any[], n: any[]) => p.concat(n), []);
  const totalRoyalties = items.map((i: any) => Number(i.amount_due)).reduce((p: number, n: number) => p + n, 0);

  const compliancePanel = r.dueDate.isSameOrAfter('4/30/2024') && processingStatus.processed && !uploading ? (
    <div className="panel panel-portal">
      <div className="panel-body">
        <h4>Compliance Review</h4>
        <div>
          {(processingStatus.warningIssues.length || processingStatus.requiredIssues.length) && processingStatus.processed && !uploading ? (
            <div>
              {processingStatus.requiredIssues.length ? (
                <div>
                  <h5 className="text-danger no-margin"><strong>
                    <NumberFormat
                      thousandSeparator={true}
                      displayType="text"
                      value={processingStatus.requiredIssuesCount}
                    /> transaction(s) with errors.

                  </strong></h5>
                  <p className="text-muted">These must be corrected to continue with report submission.</p>
                  <div style={{ paddingLeft: 40, marginBottom: 10 }}>
                    {processingStatus.requiredIssues.map(i => (<div style={{ marginTop: 10 }} key={i.issue.id}>
                      <strong>{i.issue.name}</strong>
                      <p className="text-muted no-margin">{i.issue.description}</p>
                      <p className="text-muted no-margin">
                        <NumberFormat
                          thousandSeparator={true}
                          displayType="text"
                          value={i.count}
                        /> transaction(s)
                      </p>
                    </div>))}

                  </div>
                </div>
              ) : null}
              {processingStatus.warningIssues.length && r.dueDate.isSameOrAfter('4/30/2024') ? (
                <div >

                  <h5 className="text-danger no-margin">
                    <strong><NumberFormat thousandSeparator={true} displayType="text" value={processingStatus.warningIssuesCount} /> transaction(s) with warnings.</strong>
                  </h5>
                  <p className="text-muted">
                    Review compliance warnings using the Compliance Review CSV Download linked below. Re-upload your data after making the necessary updates. { processingStatus.rejectedDesignIssuesFee > 0 || processingStatus.doubleRoyaltyIssuesFee > 0 ? 'If you are billed compliance fees, a separate compliance fees invoice will be generated after submission of your report.' : null}
                  </p>
                  <div style={{ paddingLeft: 40, marginTop: 20 }}>
                    {processingStatus.unmappedCategoryCount ? (<div style={{ marginTop: 10 }}>
                      <strong><NumberFormat thousandSeparator={true} displayType="text" value={processingStatus.unmappedCategoryCount} /> unmapped product categories</strong>
                      <p className="text-muted no-margin">Compliance fees will be available to review before submission</p>
                    </div>) : null}

                    {processingStatus.rejectedDesignIssues.length ? (<div style={{ marginTop: 10 }}>
                      <strong>{processingStatus.rejectedDesignIssuesFee > 0 ? <CurrencyLabel value={processingStatus.rejectedDesignIssuesFee} /> : ''} Rejected design { processingStatus.rejectedDesignIssuesFee > 0 ? 'fees' : null}</strong>
                      <p className="text-muted no-margin"><NumberFormat thousandSeparator={true} displayType="text" value={processingStatus.rejectedDesignIssues[0].count} /> transaction(s)</p>
                    </div>) : null}
                    {processingStatus.doubleRoyaltyIssues.length ? (<div style={{ marginTop: 10 }}>
                      <strong>{processingStatus.doubleRoyaltyIssuesFee > 0 ? <CurrencyLabel value={processingStatus.doubleRoyaltyIssuesFee} /> : ''} UPI compliance { processingStatus.doubleRoyaltyIssuesFee > 0 ? 'fees' : null}</strong>
                      <p className="text-muted no-margin">
                        <NumberFormat thousandSeparator={true} displayType="text" value={processingStatus.doubleRoyaltyIssues.reduce((p, n) => p + n.count , 0)} /> transaction(s)</p>
                      {processingStatus.doubleRoyaltyIssues.map(i => (<p className="text-muted no-margin"><NumberFormat thousandSeparator={true} displayType="text" value={i.count} /> {i.issue.name}</p>))}

                    </div>) : null}

                  </div>
                </div>
              ) : null}

            </div>
          ) : null}
          <p style={{ marginTop: 20 }}>Download the Compliance Review CSV below for detailed information. This sheet includes detailed errors and warnings for each sales data line-item and can be used to fix and reupload your sales data.</p>
          {processingStatus.processed && !uploading ? <div style={{ marginTop: 10 }}>
            {processingStatus.processingCSV ? <a href={processingStatus.processingCSV} target="_blank" className="btn btn-primary pull-left"><FontAwesomeIcon icon={faDownload} /> Download CSV</a> : null}
          </div> : null}
        </div>
      </div>
    </div>
  ) : null;

  const salesDataUploadPanel = (
    <div className="panel panel-portal">
      <div className="panel-body">
        <h4 className="text-primary">{r.submittedAt ? 'Resubmit Sales Data' : 'Upload Sales Data'}</h4>
        {r.submittedAt ?  <p className="text-muted">Click the re-upload sales data button or drag-and-drop your file into the box below to start the resubmission process.</p>
          : <p className="text-muted">Click the upload sales data button or drag-and-drop your file into the box below to start reporting.</p>}
        {r.submittedAt ? <p className="text-muted">If your resubmission results in a different royalty total and your invoice has already been paid, a member of our team will reach out with a revised invoice.</p> : null}
        <p className="">Your sales data spreadsheet must be formatted properly and contain all required data. All sales for this sales period should be reported in the Affinity sales data template. Download the most recent version of
          the <a href="https://fa53536668741c2c0fa9-ba78fd97d6ea44824b8e89aaeaef1437.ssl.cf1.rackcdn.com/Affinity-Sales-Data-Template.xlsx" target="_blank">sales data template
          </a> or <a href=" https://help.affinitylicensing.com/en/collections/1443709-royalty-reporting" target="_blank">learn more about sales data reporting</a>.</p>

        <AffinityDropzone disabled={uploading || processingStatus.processing} onDrop={(files) => {
          if (!uploading) {
            uploadFile(files[0]);
          }
        }}>
          {dropArea}

        </AffinityDropzone>
        {progressSection}

        {processingStatus.processed && !uploading ? <div style={{ marginTop: 10 }}>
          {processingStatus.requiredIssues.length === 0 ? <button onClick={(e) => {
            nextStep(r);
          }} className="button btn btn-primary btn-block">Continue<FontAwesomeIcon icon={faArrowRight} /></button> : <p className="text-danger text-center">Fix errors and re-upload to continue.</p>}
        </div> : null}

      </div>

    </div>

  );

  const aggregatePanel = (
    <div className="panel panel-portal">
      <div className="panel-body">
        <h4 className="text-primary">Aggregate Report</h4>
        <p className="text-muted">For your royalty report due July 30 (sales April 1 through June 30), you have the option to continue using the aggregate reporting method.</p>

        <p>
          All licensees will be required to report using the sales data reporting method beginning with the royalty report due October 30 (sales July 1 through September 30).
          Visit the <a target="_blank" href="https://help.affinitylicensing.com/en/collections/1443709-royalty-reporting">Help Center</a> to
          learn more about Sales Data reporting.

        </p>
        <Link style={{ marginTop: 15 }} to={`${report.reportId}/aggregate`} className="btn btn-primary">Continue Reporting <FontAwesomeIcon icon={faArrowRight} /></Link>
      </div>
    </div>
  );

  const dataTable = processingStatus.processing || uploading ? null : (
    <div className="panel panel-portal">
      {loadingRollups ? <LoadingSpinner /> : <ReportDataTable showAggregateDownload={!r.vendor.profile.sales_data_reporting_required} rows={rows} /> }
    </div>
  );

  const modals = (
    <div>
      <GatewayModal
        type={ModalType.Primary}
        onClose={() => setNoSalesModal(false)}
        shown={noSalesModal}
        title={'Submit No Sales Report'}
      >
        <Modal.Body>
          <p>
            You are about to submit a no sales report. By clicking submit, you confirm that there have been no sales bearing the marks of our clients for this quarter. Please check the box below to continue with the submission of your royalty report.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setNoSalesModal(false)}
            className="btn btn-default pull-left"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary pull-right"
          >
            Submit No Sales Report
          </button>
        </Modal.Footer>
      </GatewayModal>

      <GatewayModal
        type={ModalType.Danger}
        onClose={() => setValidationModal(false)}
        shown={validationModal}
        title="Sales Data Not Uploaded"
      >
        <Modal.Body>
          <p className="">
            Your Sales Data file has one or more issues and cannot be accepted. We have provided the first issue to resolve below (the file may have other issues). Please refer to our reporting guide for more information.
          </p>
          {uploadErrors.map((e, index) => <p key={index} className="text-danger"><strong>{e}</strong></p>)}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setValidationModal(false)}
            className="btn btn-danger pull-right"
          >
            Dismiss and Try Again
          </button>

        </Modal.Footer>
      </GatewayModal>
      <GatewayModal
        type={ModalType.Danger}
        onClose={() => setUploadModal(false)}
        shown={uploadModal}
        title="Upload Issue"
      >
        <Modal.Body>
          <p className="">
            Your Sales Data file has one or more issues and cannot be accepted. We have provided the first issue to resolve below (the file may have other issues). Please refer to our reporting guide for more information.
          </p>
          {uploadErrors.map((e, index) => <p key={index} className="text-danger"><strong>{e}</strong></p>)}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setUploadModal(false)}
            className="btn btn-danger pull-right"
          >
            Dismiss and Try Again
          </button>

        </Modal.Footer>
      </GatewayModal>
      <GatewayModal
        type={ModalType.Primary}
        onClose={() => setQueueModal(false)}
        shown={queueModal}
        title="Upload Queued"
      >
        <Modal.Body>
          <p className="">
            Your sales data report file has uploaded succesfully. Affinity will email you when your file is finished processing.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setQueueModal(false)}
            className="btn btn-default pull-right"
          >
            Dismiss
          </button>

        </Modal.Footer>
      </GatewayModal>
      <GatewayModal
        type={ModalType.Primary}
        onClose={() => setResubmissionModalShown(false)}
        shown={resubmissionModalShown}
        title={'Report Resubmitted'}
      >
        <Modal.Body>
          <p>
            Thank you for resubmitting your report. A member of our team will be reviewing your report shortly. If any changes need to be made to the royalty invoice, a member of our team will contact you with the revised invoice.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary pull-right"
            onClick={() => setResubmissionModalShown(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </GatewayModal>

    </div>
  );

  if (r.phase.id === 1) {
    const body = (
      <div>
        {r.vendor.profile.sales_data_reporting_required ? salesDataUploadPanel : null}
        {!r.vendor.profile.sales_data_reporting_required ? aggregatePanel : null}
        {compliancePanel}
        {totalRoyalties === 0 && !r.salesDataFile ? <NoSalesPanel onSubmit={console.log} /> : null}
        {modals}
      </div>
    );
    if (r.submittedAt) {
      const b = <div>
        {body}
        {dataTable}
      </div>;

      const sidebar = <div>
        {adminBox}
        <ReportSubmissionInformation onlyActions={true} report={r} />
      </div>;

      return <ContentWithSidebar noPadding={true} main={b} sidebar={sidebar} />;

    }
    if (user.type === 'admin') {
      return <ContentWithSidebar noPadding={true} main={body} sidebar={adminBox} />;
    }

    return <div>
      {body}
      </div>;
  }

  const sidebar = (
      <div>
        <ReportActionBox report={r} userType={user.type ? user.type : ''} />
        {adminBox}
        <ReportSubmissionInformation report={r} />
        <ReportOldFiles report={r} />
      </div>
  );

  const body = (
    <div>
      {r.vendor.profile.sales_data_reporting_required && user.type === 'admin' && r.phase.id === 3 ? salesDataUploadPanel : null}
      {compliancePanel}
      {dataTable}
      {modals}
    </div>
  );

  return (
    <div>
      <ContentWithSidebar noPadding={true} main={body} sidebar={sidebar} />
    </div>
  );

};
