import Axios from 'axios';
import { Field, Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getLicensors } from '../../../../api';
import { AffinityClient, MarketplacePostTag } from '../../../../shared';
import { LoadingSpinner } from '../../../shared';
import { PostTagTypeahead } from '../../Posts';

interface SitePagePostFields {
  keyword: string;
  clients: number[];
  tags: number[];
}

interface IProps {
  onSubmit: (values: string) => void;
  initialValues?: SitePagePostFields;
}

export const SitePagePostsForm = (props: IProps) => {
  const [clients, setClients] = React.useState<AffinityClient[]>([]);
  const [tags, setTags] = React.useState<MarketplacePostTag[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setup();
  },              []);

  const setup = async () => {
    const getClients = getLicensors();
    const getTags = Axios.get('/api/marketplace/posts/tags');

    const all = await Axios.all([getClients, getTags]);
    setClients(all[0].data.data.map((c: any) => new AffinityClient(c)));
    setTags(all[1].data.data);
    setLoading(false);
  };

  const selectedClients = (ids: number[]) => {
    return clients.filter(c => ids.includes(Number(c.id)));
  };

  const selectedTags = (ids: number[]) => {
    return tags.filter(t => ids.includes(t.id));
  };

  const initialValues: SitePagePostFields = props.initialValues ? props.initialValues : {
    keyword: '',
    clients: [],
    tags: [],
  };

  const handleSubmit = (values: SitePagePostFields) => {
    const s = {
      keyword: values.keyword ? values.keyword : null,
      client_ids: values.clients.length ? values.clients : null,
      tag_ids: values.tags.length ? values.tags : null,
    };
    const cleaned = omitBy(s, isNil);
    props.onSubmit(JSON.stringify(cleaned));

  };

  if (loading) {
    return (<LoadingSpinner />);
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} >
      {formProps => (
        <Form>
          <div className="form-group">
            <label>Keyword</label>
            <Field name="keyword" className="form-control" />
          </div>
          <div className="form-group">
            <label className="small">Organizations</label>
            <Typeahead
              id="insignia-typeahead"
              placeholder="Organizations"
              selected={selectedClients(formProps.values.clients)}
              onChange={c => formProps.setFieldValue('clients', c.map(n => n.id))}
              options={clients}
              multiple={true}
              labelKey={option => option.shortName}
            />
          </div>
          <div className="form-group">
            <label className="small">Tags</label>
            <PostTagTypeahead
              selected={selectedTags(formProps.values.tags)}
              onChange={t => formProps.setFieldValue('tags', t.map(t => t.id))}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="btn btn-primary">Save Page Item</button>
          </div>
        </Form>
      )}

    </Formik>
  );

};
