import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import { Filters } from '../../shared';
import { NoteThreadCategory } from '../../shared/Notes/NoteThreadCategory';
import User from '../../shared/User';

export class NotesFilters extends Filters {
  subject = '';
  note = '';
  noteCode = '';
  flagged = false;
  pinned = false;
  createdStart: string = '';
  createdEnd: string = '';
  users: User[] = [];
  categories: NoteThreadCategory[] = [];

  constructor(location?: string) {
    super();
    if (location) {
      this.setQueryParamsFromLocation(location);
    }

  }

  setQueryParamsFromLocation(location: string) {
    super.setQueryParamsFromLocation(location);
    const queryValues = queryString.parse(location);
    this.users = queryValues.users ? queryValues.users.split(',').map((u: any) => {
      const s = u.split('-');
      const user = new User();
      user.id = Number(s[0]);
      user.firstName = s[1];
      user.lastName = '';
      return user;
    }) : [];
    this.categories = queryValues.categories ? queryValues.categories.split(',').map((u: any) => {
      const s = u.split('-');
      const c = new NoteThreadCategory();
      c.id = Number(s[0]);
      c.name = s[1];
      return c;
    }) : [];
    this.sortBy = queryValues.sort ? queryValues.sort : this.sortBy;
    this.subject = queryValues.subject ? queryValues.subject : this.subject;
    this.note = queryValues.note ? queryValues.note : this.note;
    this.noteCode = queryValues.noteCode ? queryValues.noteCode : this.noteCode;
    this.flagged = queryValues.flagged ? true : false;
    this.pinned = queryValues.pinned ? true : false;
    this.createdStart = queryValues.createdStart ? queryValues.createdStart : this.createdStart;
    this.createdEnd = queryValues.createdEnd ? queryValues.createdEnd : this.createdEnd;

  }

  generateQueryParamString() {
    const queryParams = {
      search: this.search.length ? this.search : null,
      subject: this.subject.length ? this.subject : null,
      note: this.note.length ? this.note : null,
      noteCode: this.noteCode.length ? this.noteCode : null,
      flagged: this.flagged ? 1 : null,
      pinned: this.pinned ? 1 : null,
      createdStart: this.createdStart.length ? this.createdStart : null,
      createdEnd: this.createdEnd.length ? this.createdEnd : null,
      users: this.users.length ? this.users.map(u => `${u.id}-${u.fullName}`).join() : null,
      categories: this.categories.length ? this.categories.map(c => `${c.id}-${c.name}`).join() : null,
      sort: this.sortBy.length && this.sortBy !== 'default' ? this.sortBy : null,
      page: this.page !== 1 ? this.page : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  setFilters(filters: any) {
    super.setFilters(filters);
    const newFilters = {
      users: this.users,
      categories: this.categories,
      subject: this.subject,
      note: this.note,
      noteCode: this.noteCode,
      flagged: this.flagged,
      pinned: this.pinned,
      createdStart: this.createdStart,
      createdEnd: this.createdEnd,
      ...filters,
    };
    this.users = newFilters.users;
    this.categories = newFilters.categories;
    this.sortBy = newFilters.sortBy;
    this.subject = newFilters.subject;
    this.note = newFilters.note;
    this.noteCode = newFilters.noteCode;
    this.flagged = newFilters.flagged;
    this.pinned = newFilters.pinned;
    this.createdStart = newFilters.createdStart;
    this.createdEnd = newFilters.createdEnd;
    this.page = newFilters.page;
  }

  get filters() {
    return {
      page: this.page,
      sortBy: this.sortBy,
      search: this.search,
      users: this.users,
      categories: this.categories,
      subject: this.subject,
      note: this.note,
      noteCode: this.noteCode,
      flagged: this.flagged,
      pinned: this.pinned,
      createdStart: this.createdStart,
      createdEnd: this.createdEnd,
    };
  }
}
