import * as moment from 'moment';

export class ACHPaymentMethod {
  id: number;
  createdAt: moment.Moment;
  verificationSentAt: moment.Moment;
  routingNumber: string;
  accountNumber: string;
  bankName: string;
  isPrimary: boolean;
  isVerified: boolean;

  static fromApi(data: ACHPaymentMethodApiResponse) {
    const ach = new ACHPaymentMethod();
    ach.id = data.id;
    ach.routingNumber = data.routing_number;
    ach.accountNumber = data.account_number;
    ach.bankName =  data.bank_name.toUpperCase(); // Set to lower case to normailize names
    ach.isPrimary = data.is_primary;
    ach.isVerified = data.is_verified;
    ach.createdAt = moment(data.created_at);
    ach.verificationSentAt = moment(data.verification_sent_at);
    return ach;
  }

  get lastFour() {
    return this.accountNumber.length >= 4 ? this.accountNumber.slice(-4).toUpperCase() : this.accountNumber.toUpperCase();
  }

}

interface ACHPaymentMethodApiResponse {
  id: number;
  routing_number: string;
  account_number: string;
  bank_name: string;
  is_primary: boolean;
  is_verified: boolean;
  created_at: string;
  verification_sent_at: string;
}
