import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Vendor } from '../../shared';

interface IProps {
  vendor: Vendor;
  link?: string;
}

export class VendorInfo extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
  }

  statusColor(status: number) {
    if (status === 2) {
      return 'text-success';
    }
    if (status === 4 || status === 1) {
      return 'text-info';
    }
    if (status === 5) {
      return 'text-danger';
    }
    if (status === 3) {
      return 'text-warning';
    }
    return 'text-muted';

  }

  render() {
    const vendor = this.props.vendor;
    const link = this.props.link ? this.props.link : '';
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            <h5 className="text-muted">Vendor</h5>
            <h5 className="text-primary">
              <strong>
                <Link to={`/vendors/${vendor.id}${link}`}>{vendor.shortName}</Link>
              </strong>
            </h5>
            <h5>
              <FontAwesomeIcon
                className={this.statusColor(Number(vendor.status.id))}
                icon={faCircle}
              /> {vendor.status.display}
            </h5>
          </div>
          <img style={{ width: 75, height: 75 }} src={vendor.image.getSize('sm')} />
        </div>
        <hr />
      </div>

    );
  }

}
