import * as React from 'react';
import { Modal } from 'react-bootstrap';
import { isNumeric } from '../../utils';
import { GatewayModal, ModalType } from '../shared/modals';

export interface StandardRoyaltyParams {
  minimumRoyalty: string;
  royaltyRate: string;
}

interface IProps {
  shown: boolean;
  hideModal: () => void;
  standardRoyaltyParams: StandardRoyaltyParams | null;
  submitting: boolean;
  submit: () => void;
  onChange: (standardRoyaltyParams: StandardRoyaltyParams) => void;
}

class StandardScheduleModal extends React.PureComponent<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.onChangeRate = this.onChangeRate.bind(this);
    this.onChangeMinimum = this.onChangeMinimum.bind(this);
  }

  onChangeRate(event: React.ChangeEvent<HTMLInputElement>) {
    const params = this.props.standardRoyaltyParams;
    if (params === null) {
      return;
    }
    const royaltyRate = event.target.value;
    if (!royaltyRate || isNumeric(royaltyRate)) {
      this.props.onChange({
        royaltyRate,
        minimumRoyalty: params.minimumRoyalty,
      });
    }
  }

  onChangeMinimum(event: React.ChangeEvent<HTMLInputElement>) {
    const params = this.props.standardRoyaltyParams;
    if (params === null) {
      return;
    }

    const minimumRoyalty = event.target.value;
    if (!minimumRoyalty || isNumeric(minimumRoyalty)) {
      this.props.onChange({
        minimumRoyalty,
        royaltyRate: params.royaltyRate,
      });
    }
  }

  public render() {

    const royaltyRate = this.props.standardRoyaltyParams ? this.props.standardRoyaltyParams.royaltyRate : '';
    const minimumRoyalty = this.props.standardRoyaltyParams ? this.props.standardRoyaltyParams.minimumRoyalty : '';

    return (
      <GatewayModal
        type={ModalType.Primary}
        onClose={this.props.hideModal}
        shown={this.props.shown}
        title="Standard Schedule"
      >
        <Modal.Body>

          <form className="form-horizontal">
            <div className="form-group">
              <label htmlFor="royaltyRateInput" className="col-sm-3 control-label">Royalty Rate</label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="royaltyRateInput"
                    value={royaltyRate}
                    onChange={this.onChangeRate}
                   />
                  <span className="input-group-addon">%</span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="minimumRoyaltyInput" className="col-sm-3 control-label">Minimum Royalty</label>
              <div className="col-sm-6">
                <div className="input-group">
                  <span className="input-group-addon">$</span>
                  <input
                    type="text"
                    className="form-control"
                    id="minimumRoyaltyInput"
                    value={minimumRoyalty}
                    onChange={this.onChangeMinimum}
                  />
                </div>
              </div>
            </div>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.props.hideModal} className="btn btn-default pull-left">Cancel</button>
          <button className="btn btn-primary" disabled={this.props.submitting} onClick={this.props.submit}>Update</button>
        </Modal.Footer>
      </GatewayModal>
    );
  }
}

export default StandardScheduleModal;
