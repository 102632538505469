import * as React from 'react';
import { useParams } from 'react-router-dom';
import { MassUploadButton, MassUploadStatus, MassUploadStatusAlert } from './MassUploadButton';
export const NotePlaceholder = () => {
  const [massUploadStatus, setMassUploadStatus] = React.useState<MassUploadStatus>({ state: 'unsubmitted' });
  const { accountId } = useParams<any>();

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 15, paddingTop: 5 }}>
        {!accountId ? <MassUploadButton
          statusUpdateFn={(status) => { setMassUploadStatus(status); }}
          disabled={massUploadStatus.state === 'loading'}
        /> : null}
      </div>

      <MassUploadStatusAlert status={massUploadStatus} />
      <div
        style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
      >
        <img className="img-responsive" src="/assets/images/Notes-Placeholder.png" />
      </div>
    </div>
  );
};
