import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import { formattedFiscalYear, toUSD } from '../../utils';
import { FullContent } from '../ContentFrame';
import { LoadingSpinner } from '../shared';
import ExportButton from './submodules/ExportButton';

interface BenchmarkData {
  year: number;
  total: number;
  averageTotal: number;
}

export const FiscalYearsBenchmarkPage = () => {
  const [benchmarkData, setBenchmarkData] = useState<BenchmarkData[]>([]);
  const [averageData, setAverageData] = useState<BenchmarkData[]>([]);
  const [tableData, setTableData] = useState<BenchmarkData[]>([]);
  const params = useParams();
  const licensorId = params['licensorId'];
  const chartRef = useRef<any>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchData();
  },        []);

  const fetchData = async () => {
    const query = licensorId ? `?account_id=${licensorId}` : '';
    const url = `/api/performance-reports/benchmarks${query}`;
    try {
      const { data } = await Axios.get(url);

      const filteredBenchmarkData = data.individualData.filter((d: BenchmarkData) => d.total > 0);
      const filteredAverageData = data.averageData.filter((a: BenchmarkData) =>
        filteredBenchmarkData.some((b: BenchmarkData) => b.year === a.year),
      );

      setBenchmarkData(filteredBenchmarkData);
      setAverageData(filteredAverageData);
      mergeTableData(filteredBenchmarkData, filteredAverageData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching benchmark data:', error);
    }
  };

  const mergeTableData = (individualData: BenchmarkData[], averageData: BenchmarkData[]) => {
    const mergedData = individualData
      .filter(ind => ind.total > 0)
      .map((indData: BenchmarkData) => {
        const avgData = averageData.find(avg => avg.year === indData.year);
        return {
          ...indData,
          averageTotal: avgData ? avgData.averageTotal : 0,
        };
      })
      .sort((a, b) => b.year - a.year);

    setTableData(mergedData);
  };

  const lineData = {
    labels: benchmarkData.filter(d => d.total > 0).map(d => formattedFiscalYear(d.year)),
    datasets: [
      {
        label: 'Individual',
        data: benchmarkData.filter(d => d.total > 0).map(d => d.total),
        borderColor: '#4f6980',
        backgroundColor: 'rgba(79, 105, 128, 0.5)',
        fill: false,
        lineTension: 0.1,
        borderWidth: 2,
        pointRadius: 4,
        pointBackgroundColor: '#4f6980',
        pointBorderColor: '#4f6980',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#4f6980',
      },
      {
        label: 'Average',
        data: averageData.filter((_, index) => benchmarkData[index].total > 0).map(d => d.averageTotal),
        borderColor: '#d3d3d3',
        backgroundColor: 'rgba(211, 211, 211, 0.5)',
        fill: false,
        lineTension: 0.1,
        borderWidth: 2,
        pointRadius: 4,
        pointBackgroundColor: '#d3d3d3',
        pointBorderColor: '#d3d3d3',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#d3d3d3',
      },
    ],
  };

  const lineOptions = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          callback: value => toUSD(value).replace('.00', ''),
        },
      }],
    },
    maintainAspectRatio: false,
    tooltips: {
      mode: 'index',
      intersect: false,
      callbacks: {
        title(tooltipItems:any, data:any) {
          return data.labels[tooltipItems[0].index];
        },
        label(tooltipItem:any, data:any) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const value = dataset.data[tooltipItem.index];
          return `${dataset.label}: ${toUSD(value)}`;
        },
        footer(tooltipItems:any, data:any) {
          const individual = data.datasets[0].data[tooltipItems[0].index];
          const average = data.datasets[1].data[tooltipItems[0].index];
          const comparisonAmount = individual - average;
          const comparisonPercent = ((individual / average) * 100) - 100;
          return [
            `Comparison: ${toUSD(comparisonAmount)} (${comparisonPercent.toFixed(1)}%)`,
          ];
        },
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    elements: {
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 6,
      },
    },
    legend: {
      display: false,
    },
  };

  const rows = tableData ? tableData.map((d: BenchmarkData) => {
    const comparisonAmount = d.total - d.averageTotal;
    const comparisonPercent = ((d.total / d.averageTotal) * 100) - 100;

    let comparisonAmountIndicator = null;
    if (comparisonAmount > 0) {
      comparisonAmountIndicator = <FontAwesomeIcon icon={faCaretUp} className="text-success" style={{ verticalAlign: 'middle', marginRight: '5px' }} />;
    } else if (comparisonAmount < 0) {
      comparisonAmountIndicator = <FontAwesomeIcon icon={faCaretDown} className="text-danger" style={{ verticalAlign: 'middle', marginRight: '5px' }} />;
    }

    let comparisonPercentIndicator = null;
    if (comparisonPercent > 0) {
      comparisonPercentIndicator = <FontAwesomeIcon icon={faCaretUp} className="text-success" style={{ verticalAlign: 'middle', marginRight: '5px' }} />;
    } else if (comparisonPercent < 0) {
      comparisonPercentIndicator = <FontAwesomeIcon icon={faCaretDown} className="text-danger" style={{ verticalAlign: 'middle', marginRight: '5px' }} />;
    }

    return (
      <tr key={d.year} className="text-center">
        <td>{formattedFiscalYear(d.year)}</td>
        <td>{toUSD(d.total)}</td>
        <td>{toUSD(d.averageTotal)}</td>
        <td>
          <div className="clearfix">
            {comparisonAmountIndicator}
            <span style={{ verticalAlign: 'middle' }}>
            {toUSD(Math.abs(comparisonAmount))}
          </span>
          </div>
        </td>
        <td>
          <div className="clearfix">
            {comparisonPercentIndicator}
            <NumberFormat
              style={{ verticalAlign: 'middle' }}
              suffix="%"
              displayType="text"
              decimalScale={2}
              value={Math.abs(comparisonPercent)}
            />
          </div>
        </td>
      </tr>
    );
  }) : null;

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          {loading ? <LoadingSpinner /> :
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <h3><strong>Fiscal Year Benchmarks</strong></h3>
                <ExportButton chartRef={chartRef} tableRef={tableRef} />
              </div>
              <div style={{ height: 500, maxHeight: 500 }}>
                <Line ref={chartRef} data={lineData} options={lineOptions} />
              </div>
              <div className="table-responsive" style={{ marginTop: 10 }}>
                <table className="table table-bordered table-striped" ref={tableRef}>
                  <thead>
                  <tr className="text-center">
                    <th className="text-center">Year</th>
                    <th className="text-center">Royalties</th>
                    <th className="text-center">Benchmark Average</th>
                    <th className="text-center">$ Comparison</th>
                    <th className="text-center">% Comparison</th>
                  </tr>
                  </thead>
                  <tbody>
                  {rows}
                  </tbody>
                </table>
              </div>
            </div>
          }
        </div>
      </div>
    </FullContent>
  );
};
