import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { MarketplaceSite } from '../../../shared/MarketplaceSite';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';

export const SitesIndexPage = () => {

  const [loading, setLoading] = React.useState(true);
  const [sites, setSites] = React.useState<MarketplaceSite[]>([]);

  React.useEffect(() => {
    getSites();

  },              []);

  const getSites = async () => {
    setLoading(true);
    const s = await Axios.get('/api/marketplace/sites');
    setSites(s.data.data.map((site: any) => MarketplaceSite.fromApi(site)));

    setLoading(false);
  };

  let body;
  if (loading) {
    body = (<LoadingSpinner />);

  } else {
    const siteList = sites.map(s => (
      <div style={{ borderBottom: '1px solid #eee', borderTop: '1px solid #eee', padding: 20 }} className="row">
        <div className="col-md-3">
          <Link to={`/marketplace/sites/${s.id}`}>
            {s.title}
          </Link>
        </div>
        <div className="col-md-9">
          <a href={`https://${s.domain}`} target="_blank">
            <FontAwesomeIcon icon={faExternalLink} /> https://{s.domain}
          </a>
        </div>
      </div>
    ));
    body = (
      <div>
        <div style={{ padding: 20 }} className="row">
          <div className="col-md-3">
            <strong>Name</strong>

          </div>
          <div className="col-md-9">
            <strong>Domain</strong>
          </div>
        </div>
        {siteList}
      </div>
    );

  }

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-heading">
          <h3><strong>Sites</strong></h3>
        </div>
        <div className="panel-body">
          {body}
        </div>
      </div>
    </FullContent>

  );

};
