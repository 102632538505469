import * as React from 'react';
import { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';

export enum ModalType {
  Primary = 'primary',
  Info = 'info',
  Danger = 'danger',
}

interface IGatewayModalProps {
  type: ModalType;
  onClose: () => any;
  shown: boolean;
  title?: ReactNode;
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  backdrop?: 'static' | true | false;
}

export class GatewayModal extends React.Component<IGatewayModalProps, any> {

  static getModalTypeClass(type: ModalType): string {
    switch (type) {
      case ModalType.Primary:
        return 'modal-primary';
      case ModalType.Info:
        return 'modal-info';
      case ModalType.Danger:
        return 'modal-danger';
      default:
        return '';
    }
  }

  constructor(props: any) {
    super(props);
  }

  public render() {

    const className = `modal-portal ${GatewayModal.getModalTypeClass(this.props.type)}`;

    const title = this.props.title ? <Modal.Title>{this.props.title}</Modal.Title> : <></>;

    const size = this.props.size ? this.props.size : undefined;

    const backdrop = this.props.backdrop ? this.props.backdrop : undefined;

    return (
      <Modal
        show={this.props.shown}
        onHide={this.props.onClose}
        container={document.body}
        className={className}
        bsSize={size}
        backdrop={backdrop}
      >
        <Modal.Header>
          <button
            onClick={this.props.onClose}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {title}
        </Modal.Header>
        {this.props.children}
      </Modal>
    );
  }
}
