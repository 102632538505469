import * as Yup from 'yup';
import { AffinityClient } from './AffinityClient';
import { Design } from './Designs';
import { RoyaltySchedule } from './RoyaltySchedule';
import { Vendor } from './Vendor';

export class RoyaltyReportLineItem {
  id: number;
  licensor: AffinityClient;
  grossSales: number;
  invoiceID: string;
  invoiceDate: string;
  productCategory: {
    id: number;
    name: string;
  };
  uniqueProductID: string;
  schedule: RoyaltySchedule;
  availableSchedules: RoyaltySchedule[];
  royalty: number;
  quantity: number;
  pricePerUnit: string;
  channel: {
    id: number;
    name: string;
    description: string;
  };
  insignia: null | {
    id: number;
    title: string;
  };
  retailer: string;
  customer: string;
  shippingCountry: string;
  shippingPostalCode: string;
  missingSchedule: boolean;
  missingRoyalty: boolean;
  isReadOnly: boolean;

  retailerAccount: Vendor;

  design: Design;
  upi: string;

  postalCode: any;
  isDesignClient?: boolean;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.licensor = new AffinityClient(data.client);
      this.grossSales = data.sales;
      this.invoiceID = data.reported_invoice_id;
      this.invoiceDate = data.reported_date;
      this.productCategory = data.product_category;
      this.uniqueProductID = data.item_identifier;
      if (data.is_design_client !== undefined) {
        this.isDesignClient = data.is_design_client;
      } else {
        this.isDesignClient = true;
      }
      if (data.royalty_schedule) {
        this.schedule = new RoyaltySchedule(data.royalty_schedule);
      } else {
        this.missingSchedule = true;
      }
      if (data.available_schedules) {
        this.availableSchedules = data.available_schedules.map((s: any) => new RoyaltySchedule(s));
      } else {
        this.availableSchedules = [];
      }
      if (data.amount_due || data.amount_due === 0) {
        this.royalty = data.amount_due;
      } else {
        this.missingRoyalty = true;
      }

      if (data.insignia) {
        this.insignia = {
          id: data.insignia.id,
          title: data.insignia.title,
        };
      } else {
        this.insignia = null;
      }

      if (data.retailer_account) {
        this.retailerAccount = Vendor.fromApi(data.retailer_account);
      }

      this.quantity = data.quantity;
      this.pricePerUnit = data.price_per_unit;
      this.channel = data.distribution_channel;
      this.retailer = data.retailer;
      this.customer = data.customer;
      this.shippingCountry = data.shipping_country;
      this.shippingPostalCode = data.shipping_postal_code;
      this.isReadOnly = data.is_read_only;
      this.upi = data.upi;

      if (data.design) {
        this.design = Design.fromApi(data.design);
      }
      if (data.postal_code) {
        this.postalCode = data.postal_code;
      }
    }
  }

  isValidSalesDataRow() {
    if (this.missingSchedule) {
      return false;
    }
    if (!this.productCategory) {
      return false;
    }
    if (!this.royalty) {
      return false;
    }

    return true;

  }

  isValidAggregateRow() {
    if (this.schedule.usesGrossSales()) {
      if (!Yup.number().required().isValidSync(this.grossSales)) {
        return false;
      }
    }

    if (this.schedule.usesQuantity()) {
      if (!Yup.number().required().integer().isValidSync(this.quantity)) {
        return false;
      }
    }
    if (this.schedule.usesTotalRoyalties()) {
      if (!Yup.number().required().isValidSync(this.royalty)) {
        return false;
      }
    }
    return true;
  }
}
