import { uniqBy } from 'lodash';
import * as React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

interface ISelection {
  name: string;
}

interface IProps {
  id: string;
  placeholder?: string;
  selected: ISelection[];
  onUpdateSelections: (selections: ISelection[]) => any;
}

export const UserSetInput = (props: IProps) => {

  const typeahead = React.useRef<any>();

  const onChange = (selected: ISelection[]) => {

    const dedupe = (x: ISelection[]) => uniqBy<ISelection>(x, 'name');

    const reduceFn = (acc: ISelection[], current: ISelection) => {
      const items = current.name.split(',').filter(x => x).map(item => ({ name: item }));
      return acc.concat(items);
    };

    const newUpdated = dedupe(selected.reduce(reduceFn, []));

    props.onUpdateSelections(newUpdated);
  };

  const handleReturn = (event: KeyboardEvent) => {
    if (event.key === 'return'  || event.key === 'Enter') {
      handleManualChange();
    }
  };

  const handleManualChange = () => {
    const current = [...props.selected];
    const newValue = typeahead.current.getInput().value;
    // only submit if there is actually text
    if (newValue) {
      typeahead.current.getInstance().setState({ text: '' });
      onChange(current.concat([{ name: newValue }]));
    }

  };

  return (
    <Typeahead
        id={props.id}
        allowNew={true}
        options={[]}
        placeholder={props.placeholder ? props.placeholder : 'Separate options with a comma and press Enter'}
        onChange={onChange}
        selected={props.selected}
        labelKey ={'name'}
        multiple={true}
        paginate={false}
        ref={typeahead}
        renderMenu={() => null}
        onKeyDown={handleReturn}
        onBlur={handleManualChange}
      />

  );
};
