import * as queryString from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { FullContent } from '../ContentFrame';
import { PaymentManager } from '../Payments';

export const InvoicePaymentPage = (props: RouteComponentProps<any>) => {

  const vendorId = props.match.params['vendorId'];
  const invoiceId = props.match.params['id'];

  const search = queryString.parse(props.location.search);
  const partialAmount = search['payment'];

  const onPaymentSuccess = () => {
    props.history.push(`${vendorId ? `/vendors/${vendorId}` : ''}/invoices/${invoiceId}`);
  };

  return (
    <FullContent>
          <PaymentManager
            invoiceId={invoiceId}
            accountId={vendorId}
            onSuccess={onPaymentSuccess}
            partialAmount={partialAmount}
            modalMode={false}
          />
    </FullContent>
  );

};
