import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as React from 'react';
import { hot } from 'react-hot-loader';
import { GatewayContainer } from './components/Navigation';

import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { UserType } from './contexts';

interface AppProps {
  user: UserType;
}

const stripePromise = loadStripe(window['stripePublicKey']);

class App extends React.Component<AppProps, any> {

  public render() {
    const Gateway = withRouter(GatewayContainer);
    return (
      <div className="App flex-container">
        <Elements stripe={stripePromise}>
        <Router>
          <Gateway user={this.props.user} />
        </Router>
        </Elements>
      </div>
    );
  }
}

const HotApp = hot(module)(App);

export default HotApp;
