import { faCheckCircle, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { File } from '../../shared/File';

interface IProps {
  file: File | null;
  previewFile: File | null;
  formProps: FormikProps<any>;
}
export class ImageTypeForm extends React.Component<IProps, any> {

  fileRef: any;
  previewRef: any;

  constructor(props: IProps) {
    super(props);
    this.getPreviewImage = this.getPreviewImage.bind(this);
  }

  getPreviewImage(image: 'preview' | 'file') {
    const formFile = image === 'preview' ? this.props.formProps.values.previewFile : this.props.formProps.values.file;
    const propFile = image === 'preview' ? this.props.previewFile : this.props.file;
    if (formFile) {
      if (formFile.type.match(/(jpg|jpeg|gif|png|bmp)$/)) {
        const preview = URL.createObjectURL(formFile);
        return <img src={preview} className="img-responsive" />;
      }
      return (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
          className="text-success"
        >
          <h2><FontAwesomeIcon icon={faCheckCircle} /></h2>
          <h5 className="text-primary">{formFile.name}</h5>
        </div>
      );
    }

    if (propFile) {
      if (propFile.url.match(/\.(jpg|jpeg|gif|png|bmp)$/)) {
        return <img src={propFile.url} className="img-responsive" />;
      }
      return (
        <div
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
          className="text-success"
        >
          <h2><FontAwesomeIcon icon={faCheckCircle} /></h2>
          <h5 className="text-primary">{propFile.previousFileName}</h5>
        </div>
      );
    }
    return <h5 className="text-center text-muted">Drag-n-drop</h5>;

  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <label className="text-primary">File</label>
          <Dropzone
            ref={(c: any) => this.fileRef = c}
            onDrop={(files: any) => {
              this.props.formProps.setFieldValue('file', files[0]);
              this.props.formProps.validateForm();
            }}
            noDragEventsBubbling={true}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="brand-dnd"
                {...getRootProps()}
                style={this.props.formProps.errors.file && this.props.formProps.touched.file ? { borderColor: 'red' } : {}}
              >
                <input {...getInputProps()} />
                {this.getPreviewImage('file')}
              </div>
            )}
          </Dropzone>
          <button
            onClick={() => this.fileRef.open()}
            style={{ marginTop: 10 }}
            type="button"
            className="btn btn-block btn-primary"
          >
            <FontAwesomeIcon icon={faPlus} /> Upload File
          </button>
        </div>
        <div className="col-md-6">
          <label>Preview</label>
          <Dropzone
            ref={(c: any) => this.previewRef = c}
            onDrop={(files: any) => {
              this.props.formProps.setFieldValue('previewFile', files[0]);
            }}
            noDragEventsBubbling={true}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="brand-dnd"
                {...getRootProps()}
                style={this.props.formProps.errors.file && this.props.formProps.touched.file ? { borderColor: 'red' } : {}}
              >
                <input {...getInputProps()} />
                {this.getPreviewImage('preview')}
              </div>
            )}
          </Dropzone>
          <button
            onClick={() => this.previewRef.open()}
            style={{ marginTop: 10 }}
            type="button"
            className="btn btn-block btn-default"
          >
            <FontAwesomeIcon icon={faPlus} /> Upload File
          </button>
        </div>

      </div>

    );
  }
}
