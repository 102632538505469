import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export const PaymentReportsGuide = () => {

  return (
    <div className="panel panel-portal">
      <div className="panel-body">

        <FontAwesomeIcon icon={faInfoCircle} /> <strong>Report Guide</strong>

        <br /><br />

        <div className="row">
          <div className="col-md-3">
            <div className="text-primary"><strong>Fiscal Year</strong></div>
            <p className="text-muted">
              The fiscal year starts in July and ends June the following year.
                </p>
          </div>
          <div className="col-md-3">
            <div className="text-primary"><strong>Quarter Dates</strong></div>
            <div className="text-muted">
              <div>Q1: Sales from Jul-Sep</div>
              <div>Q2: Sales from Oct-Dec</div>
              <div>Q3: Sales from Jan-Mar</div>
              <div>Q4: Sales from Apr-Jun</div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="text-primary"><strong>Quarter On Quarter (QOQ)</strong></div>
            <p className="text-muted">
              Comparing this quarter to the same quarter from the previous year.
                </p>
          </div>
          <div className="">
            <div className="text-primary"><strong>Year Over Year (YOY)</strong></div>
            <p className="text-muted">
              Comparing this year to the previous year.
                </p>
          </div>
        </div>
      </div>
    </div>
  );

};
