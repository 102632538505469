import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { setRoyaltyReportAdmin } from '../../../api';
import { RoyaltyAdminsContext } from '../../../contexts';
import User from '../../../shared/User';

interface IProps {
  reportId: number;
  initialAdmin: User | null;
}

interface IState {
  submitting: boolean;
}

const defaultState = {
  submitting: false,
};

/*
 * This component treats the select as an uncontrolled component -
 * the component submits the request to assign the specified admin
 * to the report, and assumes that it succeeds. This allows us to
 * avoid pushing this responsibility up the component where the
 * row data is stored, and updating the row data on each request.
 */
class InlineAdminSelector extends React.Component<IProps, IState> {

  protected _submitAdminsSource: CancelTokenSource;

  static defaultProps = {
    initialAdmin: null,
  };

  public constructor(props: any) {
    super(props);
    this.state = defaultState;
    this.selectAdmin = this.selectAdmin.bind(this);
  }

  selectAdmin(event: React.ChangeEvent<HTMLSelectElement>) {
    const adminId = event.target.value;
    if (this._submitAdminsSource) {
      this._submitAdminsSource.cancel('Cancelled InlineAdminSelector:selectAdmin XHR due to new request.');
    }
    this._submitAdminsSource = axios.CancelToken.source();
    this.setState({ submitting: true });
    setRoyaltyReportAdmin(this.props.reportId, adminId, this._submitAdminsSource)
      .then((response: any) => {
        this.setState({ submitting: false });
      });
  }

  render() {
    const selectedAdmin = this.props.initialAdmin;
    const adminId = selectedAdmin ? String(selectedAdmin.id) : undefined;

    const adminOptions = (
      <RoyaltyAdminsContext.Consumer>
        { (admins) => {
          const selectableAdmins = admins.map(user => (
            <option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>
          ));

          const adminIds = admins.map(user => (String(user.id)));

          if (!selectedAdmin || (adminId && adminIds.indexOf(adminId) !== -1)) {
            return selectableAdmins;
          }
          return selectableAdmins.concat([(
            <option key={adminId} value={adminId}>
              {selectedAdmin.firstName} {selectedAdmin.lastName}
            </option>
          )]);
        }}
      </RoyaltyAdminsContext.Consumer>
    );

    return (
      <div>
      <select className="form-control" disabled={this.state.submitting} defaultValue={adminId} onChange={this.selectAdmin}>
        <option value={0}>-</option>
        {adminOptions}
      </select>
      </div>
    );
  }
}

export default InlineAdminSelector;
