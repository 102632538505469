import { faCogs } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { AffinityClient } from '../../shared';
import { PikadayReact } from './PikadayReact';

interface IQuarter {
  label: string;
  value: string;
}

interface IClassOverrides {
  search?: string|undefined;
  perPage?: string|undefined;
  quarter?: string|undefined;
  licensor?: string|undefined;
  date?: string|undefined;
}

interface IProps {
  updateFilters: (params: any, reload: boolean) => void;
  useSearch: boolean;
  search?: string;
  disableSearch?: boolean;
  usePerPage: boolean;
  perPage?: number;
  useQuarter: boolean;
  quarter?: string;
  quarters?: IQuarter[];
  useLicensor?: boolean;
  licensor?: AffinityClient | null;
  licensors?: AffinityClient[];
  useDates?: boolean;
  startDate?: string;
  endDate?: string;
  fieldClassOverrides?: IClassOverrides;
  containerClassOverride?: string;
  useAdvancedSearch?: boolean;
  advancedSearchOnClick?: () => void;
  isFiltered?: boolean;
}

const getWrapperClass = (field: string, overrides: IClassOverrides|undefined, defaultClass: string): string => {
  const override = getOverrideClass(field, overrides);
  if (override !== null) {
    return override;
  }

  return defaultClass;
};

const getOverrideClass = (field: string, overrides: IClassOverrides|undefined): string|null => {
  if (overrides === undefined || overrides[field] === undefined) {
    return null;
  }

  return overrides[field];
};

export class FilterBar extends React.Component<IProps, any> {
  public constructor(props: any) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.handleChangeQuarter = this.handleChangeQuarter.bind(this);
    this.handleLicensorChange = this.handleLicensorChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
  }

  public handleChange(items: any) {
    this.props.updateFilters(items, false);
  }

  public handleChangeAndReload(items: any) {
    this.props.updateFilters(items, true);
  }

  public handleSubmit(e: any) {
    e.preventDefault();
    this.handleChangeAndReload({});
  }

  public handleChangeSearchText(event: any) {
    this.handleChange({
      search: event.target.value,
    });
  }

  handleSearchKeyPress(event: any) {
    if (event.key  === 'Enter') {
      this.handleSubmit(event);
    }
  }

  public handleChangePageSize(event: any) {
    this.handleChangeAndReload({ perPage: parseInt(event.target.value, 10) });
  }

  public handleChangeQuarter(event: React.ChangeEvent<HTMLSelectElement>) {
    const quarter = event.target.value;
    this.handleChangeAndReload({ quarter });
  }
  public handleLicensorChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (this.props.licensors && event.target.value) {
      const licensor = this.props.licensors.find(licensor => Number(licensor.id) === Number(event.target.value));
      this.handleChangeAndReload({ licensor });
    } else {
      this.handleChangeAndReload({ licensor: null });
    }

  }

  public handleStartDateChange(date: string) {
    this.handleChangeAndReload({ startDate: date });

  }

  public handleEndDateChange(date: string) {
    this.handleChangeAndReload({ endDate: date });
  }

  public render() {

    const quarterOptions = this.props.quarters ? this.props.quarters.map((quarter: IQuarter) =>
      <option key={quarter.value} value={quarter.value}>{quarter.label}</option>,
    ) : [];
    const searchWrapperClass = getWrapperClass('search', this.props.fieldClassOverrides, 'col-lg-3 col-sm-3 col-xs-12');
    const searchInput = this.props.useSearch ? (
      <div className={searchWrapperClass}>
          {this.props.useAdvancedSearch ?
          <div className="input-group">
            <input
              type="text"
              className="form-control input-sm"
              value={this.props.search}
              onChange={this.handleChangeSearchText}
              onKeyPress={this.handleSearchKeyPress}
              placeholder="Search"
              disabled={this.props.disableSearch}
            />
            <span className="input-group-btn">
              <button onClick={this.props.advancedSearchOnClick} className="btn btn-default btn-sm" type="button">
                <FontAwesomeIcon className={this.props.isFiltered ? 'text-primary' : undefined} icon={faCogs} />
              </button>
            </span>
          </div>
          :  <input
            type="text"
            className="form-control input-sm"
            value={this.props.search}
            onChange={this.handleChangeSearchText}
            onKeyPress={this.handleSearchKeyPress}
            placeholder="Search"
            disabled={this.props.disableSearch}
          />}

      </div>
    ) : null;

    const perPageWrapperClass = getWrapperClass('perPage', this.props.fieldClassOverrides, 'col-lg-2 col-sm-2 col-xs-12');
    const perPageInput = this.props.usePerPage ? (
      <div className={perPageWrapperClass}>
        <select
          style={{ lineHeight: 1.5 }}
          className="form-control input-sm"
          value={this.props.perPage}
          onChange={this.handleChangePageSize}
        >
          <option value={8}>8 per page</option>
          <option value={24}>24 per page</option>
          <option value={48}>48 per page</option>
          <option value={96}>96 per page</option>
        </select>
      </div>

    ) : null;

    const quarterWrapperClass = getWrapperClass('quarter', this.props.fieldClassOverrides, 'col-lg-2 col-sm-2 col-xs-12');
    const quarterInput = this.props.useQuarter ? (
      <div className={quarterWrapperClass}>
        <select
          style={{ lineHeight: 1.5 }}
          className="form-control input-sm"
          value={this.props.quarter}
          onChange={this.handleChangeQuarter}
        >
          <option value="">Filter Quarter</option>
          {quarterOptions}
        </select>
      </div>
    ) : null;

    const licensorWrapperClass = getWrapperClass('quarter', this.props.fieldClassOverrides, 'col-lg-4 col-sm-4 col-xs-12');
    const licensorId = this.props.licensor ? this.props.licensor.id : '';
    const licensors = this.props.licensors ? this.props.licensors : [];
    const licensorInput = this.props.useLicensor ? (
      <div className={licensorWrapperClass}>
        <select
          style={{ lineHeight: 1.5 }}
          className="form-control input-sm"
          value={licensorId}
          onChange={this.handleLicensorChange}
        >
          <option value="">All Licensors</option>
          {licensors.map(licensor => <option value={licensor.id} key={licensor.id}>{licensor.name}</option>)}
      </select>
    </div>

    ) : null;

    const dateWrapperClass = getWrapperClass('quarter', this.props.fieldClassOverrides, 'col-lg-2 col-sm-2 col-xs-12');
    const startDate = this.props.useDates ? (
      <div className={dateWrapperClass}>
        <PikadayReact value={this.props.startDate} placeholder="Start Date" onChange={this.handleStartDateChange} />
      </div>

    ) : null;

    const endDate = this.props.useDates ? (
      <div className={dateWrapperClass}>
        <PikadayReact value={this.props.endDate} placeholder="End Date" onChange={this.handleEndDateChange} />
      </div>

    ) : null;

    return (
      <div className={this.props.containerClassOverride ? this.props.containerClassOverride : 'row'}>
        {searchInput}
        {licensorInput}
        {perPageInput}
        {startDate}
        {endDate}
        {quarterInput}
        {this.props.children}

      </div>
    );
  }
}
