import Axios from 'axios';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { FilterBar, LoadingSpinner, SortableHeader } from '../shared';

interface IState {
  licensors: any[];
  loading: boolean;
  search: string;
}

export class VendorBrandIndex extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);
    this.state = {
      licensors: [],
      loading: true,
      search: '',
    };
    this.getLicensors = this.getLicensors.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    this.getLicensors();

  }

  search(values: any) {
    if (values.search !== undefined) {
      this.setState({ search: values.search });
    }

  }

  getLicensors() {
    let vendorPath = '';
    if (this.props.match.params['vendorId']) {
      vendorPath = `/vendors/${this.props.match.params['vendorId']}`;
    }
    Axios.get(`/api/v1${vendorPath}/clients/licensed-with`)
      .then((response) => {
        const licensors = response.data.data.slice().sort((a: any, b: any) => {
          return a.short_name.localeCompare(b.short_name, 'en');
        });
        this.setState({ licensors, loading: false });
      });
  }

  sort() {

  }

  render() {
    const rows = this.state.licensors
    .filter(licensor => licensor.short_name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1)
    .map((licensor) => {

      return <tr key={licensor.id}>
        <td>
          <Link className="load-account" style={{ zIndex: 2 }} to={`/clients/${licensor.id}/brand/assets`} >
            <img className="pull-left hidden-sm hidden-xs" src={licensor.image.th} />
            <strong>{licensor.short_name}</strong>
          </Link>
        </td>
      </tr>;
    });
    let tableContent;
    if (this.state.loading) {
      tableContent = <LoadingSpinner />;
    } else {
      tableContent = (
        <div className="table-responsive">
          <table className="table table-portal table-hover">
            <thead>
              <tr>
                <SortableHeader title="Organization" sortSelectFn={this.sort} sortDir="desc" sortKey="account" />
              </tr>

            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
    );
    }
    return <DocumentTitle title={getAffinityPageTitle('Brand')}>
      <FullContent breadcrumbs={[{ name: 'Brand' }]}>
        <div>
          <div className="panel panel-portal">
            <div className="panel-body" style={{ background: '#f9f9f9', padding: '5px' }}>

              <FilterBar
                updateFilters={this.search}
                usePerPage={false}
                useQuarter={false}
                useSearch={true}
                search={this.state.search}

              />
            </div>
          </div>

        <div className="panel panel-portal">

          {tableContent}

        </div>
        </div>

      </FullContent>

    </DocumentTitle>;
  }

}
