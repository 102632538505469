import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import NumberFormat from 'react-number-format';

const defaultProps = {
  totalResults: 0,
  currentPage: 1,
  totalCount: 0,
  hasNext: false,
  showFirstLast: false,
  totalPages: 0,
};

interface IProps {
  totalResults: number;
  currentPage: number;
  hasNext: boolean;
  setPage: (i: number) => any;
  totalPages?: number;
  showFirstLast?: boolean;
}

export class PaginationFooter extends React.Component<IProps, any> {
  public static defaultProps = defaultProps;

  public constructor(props: any) {
    super(props);

    this.handleClickPrev = this.handleClickPrev.bind(this);
    this.handleClickNext = this.handleClickNext.bind(this);
    this.handleClickLast = this.handleClickLast.bind(this);
    this.handleClickFirst = this.handleClickFirst.bind(this);
  }

  public handleClickPrev() {
    this.props.setPage(this.props.currentPage - 1);
  }

  public handleClickNext() {
    this.props.setPage(this.props.currentPage + 1);
  }

  public handleClickLast() {
    if (this.props.totalPages) {
      this.props.setPage(this.props.totalPages);
    }
  }

  public handleClickFirst() {
    this.props.setPage(1);
  }

  public render() {

    const hasNext = this.props.hasNext;
    const hasPrev = this.props.currentPage > 1;

    return (
      <div className="row">
        <div className="col-xs-12">
          <div className="footer-actions">
            <div className="text-left">
              {hasPrev &&
                <button
                  onClick={this.handleClickPrev}
                  disabled={!hasPrev}
                  className="btn btn-default"
                >
                  <FontAwesomeIcon icon={faArrowLeft} style={{ marginLeft: -2, marginRight: 7 }} /> <span className="hidden-xs">Previous</span>
                </button>
              }
              {  this.props.showFirstLast && hasPrev &&
                <button
                  onClick={this.handleClickFirst}
                  disabled={!hasPrev}
                  className="btn btn-default"
                  style={{ marginLeft: 5 }}
                > First Page
                </button>
              }
            </div>
            <div
              className="text-center text-muted footer-center"
            >
              <span>
                Page <NumberFormat
                  thousandSeparator={true}
                  displayType="text"
                  value={this.props.currentPage}
                /> {this.props.totalPages ?
                  <NumberFormat
                    prefix="of "
                    thousandSeparator={true}
                    displayType="text"
                    value={this.props.totalPages}
                  /> : ''}
              </span>
              {this.props.totalResults ?
                <span className="hidden-xs" style={{ marginLeft: 5 }}>(<NumberFormat
                    thousandSeparator={true}
                    displayType="text"
                    value={this.props.totalResults}
                  /> total)</span> : null}
            </div>
            <div className="text-right">
              { (this.props.showFirstLast && this.props.totalPages) && (!hasPrev &&  this.props.totalPages > 1) ?
                <button
                  onClick={this.handleClickLast}
                  disabled={!hasNext}
                  className="btn btn-default"
                  style={{ marginRight: 5 }}
                >
                  Last Page
                </button> : null
              }
              { hasNext ?
                <button
                  onClick={this.handleClickNext}
                  disabled={!hasNext}
                  className="btn btn-default pull-right"
                >
                  <span className="hidden-xs">Next</span> <FontAwesomeIcon icon={faArrowRight} className="icon-right" />
                </button> : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
