import Axios from 'axios';
import { ChartOptions } from 'chart.js';
import * as React from 'react';
import { Pie } from 'react-chartjs-2';
import { useHistory, useLocation } from 'react-router';
import { DistributionChannel } from '../../../shared';
import { toUSD } from '../../../utils';
import { DistributionChannelsInfoModal } from '../../Royalties/RoyaltyAnalytics/DistributionChannelsInfoModal';
import { LoadingSpinner } from '../../shared';
import { ChartFooter } from '../ChartFooter';
import { ChartHeader } from '../ChartHeader';
import { ChartItemList } from '../ChartItemList';
import { usePerformanceQueryParams } from '../usePerformanceQueryParams';
import ExportButton from './ExportButton';

interface IProps {
  large: boolean;
  link?: string;
  channels: ChannelPerformanceStats[];
  loading: boolean;
  isAggregate: boolean;
  onDashboard?: boolean;
}

interface ChannelPerformanceStats {
  channel: {
    id: number,
    name: string,
  };
  averageRPU: number;
  units: number;
  royalties: number;
  percent: number;
  sales: number;
}

export const ChannelPerformance = (props: IProps) => {
  const [distChannels, setDistChannels] = React.useState<DistributionChannel[]>([]);
  const [modalShown, setModalShown] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const performanceQP = usePerformanceQueryParams();
  const tableRef = React.useRef<HTMLTableElement>(null);
  const chartRef = React.useRef<any>(null);

  React.useEffect(() => {
    getDistChannels();
  },              []);

  const getDistChannels = async () => {
    const d = await Axios.get('/api/distributionChannels');
    const c = d.data.data.map((channel: any) => new DistributionChannel(channel));
    setDistChannels(c);
  };

  const chartOptions: ChartOptions =  {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    tooltips: {
      mode: 'single',
      callbacks: {
        label: (tooltip: any, data: any) => {
          if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const category = data.labels[tooltip.index];
            return `${category}: ${toUSD(value)}`;
          }
          return '';
        },
        footer: (tooltipArray: any, data: any) => {
          const tooltip = tooltipArray[0];
          if (tooltip.datasetIndex !== undefined || tooltip.index !== undefined) {
            const totalData = data.datasets[tooltip.datasetIndex].data
              .reduce((total: number, d: number) => total + d, 0);
            const value = data.datasets[tooltip.datasetIndex].data[tooltip.index];
            const percent = ((value / totalData) * 100).toFixed(1);
            return `${percent}%`;
          }
          return '';
        },
      },
    },
    plugins: {
      colorschemes: {
        scheme: 'tableau.MillerStone11',
      },
    },
  };

  if (props.loading) {
    return (<LoadingSpinner />);
  }

  const list = props.channels.map((c) => {
    return {
      percent: c.percent,
      units: c.units,
      avgUnitSales: (c.sales / c.units).toFixed(2),
      avgUnitRoyalties: (c.royalties / c.units).toFixed(2),
      sales: c.sales,
      royalties: c.royalties,
      name: c.channel.name,
    };
  });

  const handleClick = (index: number) => {
    const channel = props.channels[index];
    if (channel) {
      const queryString = performanceQP.toQueryString([{ value: channel.channel.id, label: 'channel' }]);
      if (props.onDashboard) {
        history.push(`${location.pathname}/channels?${queryString}`);
      } else {
        history.push(`${location.pathname}?${queryString}`);
      }
    }
  };

  const labels: any = props.onDashboard ? [{ name: 'Name' }, { percent: '%' }] :
  [
      { name: '' },
      { percent: '%' },
      { units: 'Units' },
      { avgUnitSales: 'Avg Unit Sales' },
      { avgUnitRoyalties: 'Avg Unit Royalties' },
      { sales: 'Sales' },
      { royalties: 'Royalties' },
  ];

  if (props.large) {
    const pieData = {
      labels: props.channels.map(stats => stats.channel.name),
      datasets: [{
        data: props.channels.map(stats => stats.royalties),
      }],
    };

    return (
      <div>
        {
          props.channels.length > 1 ?
            <div style={{ height: 300, maxHeight: 300 }}>
              <Pie
                options={chartOptions}
                data={pieData}
                ref={chartRef}
                onElementsClick={e => e && e[0] && handleClick(e[0]._index)}
              />
            </div>
            : null
        }
        <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 10 }}>
          <ExportButton tableRef={tableRef} chartRef={chartRef} />
        </div>
        <ChartItemList
          listVisualStyle={'none'}
          items={list}
          itemLabels={labels}
          showLabels={true}
          onClick={handleClick}
          useShowAll={true}
          ref={tableRef}
        />
      </div>
    );
  }

  return (
    <div>
      { props.onDashboard ?
        <ChartHeader
          header="Channels"
          subHeader="Sales by distribution channel."
          info={true}
          infoOnClick={() => setModalShown(true)}
        />
        : <ChartHeader
          header="Channels"
          subHeader="Sales by distribution channel."
          info={true}
          infoOnClick={() => setModalShown(true)}
          tableRef={tableRef}
        />
      }

      <DistributionChannelsInfoModal onClose={() => setModalShown(false)} shown={modalShown} channels={distChannels} />
      <ChartItemList
        listVisualStyle={'none'}
        items={list}
        itemLabels={labels}
        showLabels={!props.onDashboard}
        onClick={handleClick}
        ref={tableRef}
      />
      {props.channels.length < 5 ? null : <ChartFooter isPartial={props.isAggregate} link={performanceQP.createLink('channels')} />}
    </div>
  );
};
