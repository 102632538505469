import Axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { NoteMethod } from '../../shared/Notes/NoteMethod';
import { LoadableStatuses } from '../../shared/RequestStatuses';

interface IProps {
  onSelectFn: (selected: NoteMethod|null) => any;
  value?: string;
}

type Status = LoadableStatuses<NoteMethod[], string>;

export const NoteMethodsSelect = (props: IProps) => {

  const [status, setStatus] = React.useState<Status>({ state: 'loading' });

  React.useEffect(() => {
    const getMethodsSource = Axios.CancelToken.source();
    getMethods(getMethodsSource);

    return () => {
      getMethodsSource.cancel();
    };
  },              []);

  const getMethods = async (cancelTokenSource: CancelTokenSource) => {
    return Axios.get('/api/note-methods', { cancelToken: cancelTokenSource.token })
      .then((response) => {
        setStatus({
          state: 'success',
          content: response.data.data.map((item: any) => NoteMethod.fromApi(item)),
        });
      }).catch(() => {
        setStatus({ state: 'failure', error: 'Methods failed to load' });
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (status.state !== 'success') {
      props.onSelectFn(null);
      return;
    }

    const selected = status.content.find(item => String(item.id) === e.target.value);

    props.onSelectFn(selected !== undefined ? selected : null);
  };

  const options = status.state === 'success' ? (
    status.content.map((item: NoteMethod) => (
      <option key={item.id} value={item.id}>
        {item.name}
      </option>
    ))
  ) : [];

  return (
    <select
      className="form-control"
      name="method"
      disabled={status.state !== 'success'}
      onChange={onChange}
      value={props.value}
    >
      <option value="">Select a contact method</option>
      {options}
    </select>
  );
};
