import { faCheck, faCube, faDollarSign, faDotCircle, faExclamationTriangle, faFlag, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isNil, omitBy } from 'lodash';
import * as queryString from 'query-string';
import * as React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AffinityMarket, Filters, LicenseApplication } from '../../../shared';
import { FullContent } from '../../ContentFrame';
import { AccountCell, DateCell, FilterBar, LoadingSpinner, MarketTypeahead, PaginationFooter, StatusFilterBar } from '../../shared';

export const ApplicationIndexPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [applications, setApplications] = React.useState<LicenseApplication[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [filters, setFilters] = React.useState(new ApplicationFilters(location.search));
  const [totalPages, setTotalPages] = React.useState(0);
  const [totalResults, setTotalResults] = React.useState(0);
  const [totalAdminReview, setTotalAdminReview] = React.useState(0);

  React.useEffect(() => {
    const f = new ApplicationFilters(location.search);
    setFilters(f);
    getApplications(f);
  },              [location.search]);

  React.useEffect(() => {
    getTotalAdminReview();
  },              []);

  const getApplications = async (f: ApplicationFilters) => {
    setLoading(true);
    const qp = f.generateQueryParamString();
    const response = await fetch(`/api/v2/licenses/applications?${qp}`);
    const data = await response.json();
    setTotalPages(data.meta.pagination.total_pages);
    setTotalResults(data.meta.pagination.total);
    setApplications(data.data.map((d: any) => LicenseApplication.fromApi(d)));
    setLoading(false);
  };

  const getTotalAdminReview = async () => {
    const response = await fetch('/api/v2/licenses/applications/pending-licenses');
    const data  = await response.json();
    setTotalAdminReview(data.count);
  };

  const applyFilters = (f: ApplicationFilters) => {
    const qp = f.generateQueryParamString();
    history.push(`${location.pathname}?${qp}`);
  };

  const statusAction = (status: number) => {
    const labelStyle: React.CSSProperties = {
      fontWeight: 'bold',
      fontSize: 12,
      padding: '10px 0px',
      lineHeight: 1,
      textAlign: 'center',
    };
    switch (status) {
      case 1:
        return (
          <div style={labelStyle} className="text-info"><FontAwesomeIcon icon={faDotCircle} /> In Progress</div>
        );
      case 2:
        return (
          <button className="btn btn-sm btn-block btn-info">Review Now</button>
        );
      case 3:
        return (
          <div style={labelStyle} className="text-info"><FontAwesomeIcon icon={faDotCircle} /> Pending Refresh</div>
        );
      case 4:
        return (
          <div style={labelStyle} className="text-success"><FontAwesomeIcon icon={faCheck} /> Approved</div>
        );
      case 5:
        return (
          <div style={labelStyle} className="text-danger"><FontAwesomeIcon icon={faTimes} /> Rejected</div>
        );
      default:
        return <></>;
    }

  };

  const rows = applications.map(a => (
    <tr key={a.id}>
      <td>
        {a.vendor ? <Link to={`/vendors/${a.vendor.id}/licenses/applications/${a.id}`}>
          <AccountCell name={a.vendor.legalName} image={a.vendor.image.getSize('th')} subtext={`${a.vendor.city}, ${a.vendor.state}`} />
        </Link> : null }
      </td>
      <td className="text-center" style={{ fontSize: 18 }}>{a.isRefresh ?
        <OverlayTrigger placement="top" overlay={(<Tooltip id={`${a.id}-refresh-tooltip`}>Refresh Application</Tooltip>)}>
          <span><FontAwesomeIcon className="text-info" icon={faExclamationTriangle} /></span>
        </OverlayTrigger>
        : null}
      </td>
      <td className="text-center" style={{ fontSize: 18 }}>
        {a.onHold ?
          <OverlayTrigger placement="top" overlay={(<Tooltip id={`${a.id}-hold-tooltip`}>{a.holdDescription}</Tooltip>)}>
            <span><FontAwesomeIcon className="text-danger" icon={faFlag} /></span>
          </OverlayTrigger>
        : null}
      </td>
      <td className="text-center" style={{ fontSize: 18 }}>
        <OverlayTrigger placement="top" overlay={(<Tooltip id={`${a.id}-sample-tooltip`}>{a.invoiceStatusLabel}</Tooltip>)}>
            <span><FontAwesomeIcon className={a.invoiceStatusLabelClass} icon={faDollarSign} /></span>
          </OverlayTrigger>

      </td>
      <td className="text-center" style={{ fontSize: 18 }}>
        <OverlayTrigger placement="top" overlay={(<Tooltip id={`${a.id}-sample-tooltip`}>Product Sample {!a.productSampleRecieved ? 'Not' : ''} Recieved</Tooltip>)}>
            <span className={a.productSampleRecieved ? 'text-success' : 'text-danger'}><FontAwesomeIcon  icon={faCube} /></span>
          </OverlayTrigger>
      </td>
      <td className="text-center text-muted" style={{ fontSize: 18 }}>
        {a.licensorIds.length}

      </td>
      <td style={{ minWidth: 95, width: 95 }}>
        <DateCell date={a.createdAt.toDate()} />
      </td>
      <td style={{ minWidth: 95, width: 95 }}>
        <DateCell date={a.updatedAt.toDate()} />
      </td>
      <td style={{ minWidth: 165, width: 165 }}>
        {a.vendor ? <Link to={`/vendors/${a.vendor.id}/licenses/applications/${a.id}`}>
          {statusAction(a.status)}
        </Link> : null}
      </td>
    </tr>
  ));

  return (
    <FullContent>
      <div className="row">
        <div className="col-md-7">
          <StatusFilterBar
            labels={[
              { id: null, count: null, alertClass: 'default', name: 'All' },
              { id: 1, count: null, alertClass: 'info', name: 'In Progress' },
              { id: 2, count: null, alertClass: 'info', name: 'Admin Review' },
              { id: 4, count: null, alertClass: 'success', name: 'Approved' },
              { id: 5, count: null, alertClass: 'danger', name: 'Rejected' },
            ]}
            selected={filters.status}
            selectFn={(selected) => {
              filters.setFilters({ status: selected, page: 1 });
              applyFilters(filters);
            }}
          />
        </div>
        <div className="col-md-5">
          <div style={{ padding: '10px 0', display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
            <div>
              <span className="label label-default">Applications in Admin Review</span>
              <span className="badge label-badge label-badge-default">{totalAdminReview}</span>
            </div>
          </div>

        </div>
      </div>

      <div className="panel panel-portal">
        <div className="panel-body" style={{ background: '#f9f9f9', padding: '5px' }}>
          <FilterBar
            useSearch={true}
            search={filters.search}
            usePerPage={false}
            useQuarter={false}
            useDates={true}
            startDate={filters.startDate}
            endDate={filters.endDate}
            updateFilters={(val, rel) => {
              filters.setFilters(val);
              if (rel) {
                filters.setFilters({ page: 1 });
                applyFilters(filters);
              } else {
                const f = new ApplicationFilters();
                f.setFilters(filters.filters);
                setFilters(f);
              }

            }}
          >
            <div className="col-lg-3 col-sm-3 col-xs-12">
              <div className="no-margin form-group">
                <select onChange={(e) => {
                  const v = e.target.value;
                  filters.setFilters({ marketType: v, page: 1 });
                  const f = new ApplicationFilters();
                  f.setFilters(filters.filters);
                  setFilters(f);
                  applyFilters(f);
                }} className="form-control input-sm" value={filters.marketType} >
                  <option value={''}>All Markets</option>
                  <option value={1}>Non-Collegiate</option>
                  <option value={2}>Collegiate</option>
                </select>
              </div>

            </div>
            <div className="col-lg-2 col-sm-2 col-xs-12">
              <div className="no-margin form-group">
                <select onChange={(e) => {
                  const v = e.target.value;
                  filters.setFilters({ sortBy: v, page: 1 });
                  const f = new ApplicationFilters();
                  f.setFilters(filters.filters);
                  setFilters(f);
                  applyFilters(f);
                }} className="form-control input-sm" value={filters.sortBy} >
                  <option value={'hold-asc'}>Hold (Asc)</option>
                  <option value={'hold'}>Hold (Desc)</option>
                  <option value={'payment-asc'}>Payment (Asc)</option>
                  <option value={'payment'}>Payment (Desc)</option>
                  <option value={'products-asc'}>Products (Asc)</option>
                  <option value={'products'}>Products (Desc)</option>
                  <option value={'org-asc'}>Organziations (Asc)</option>
                  <option value={'org'}>Organizations (Desc)</option>
                  <option value={'created-asc'}>Created (Asc)</option>
                  <option value={''}>Created (Desc)</option>
                  <option value={'updated-asc'}>Updated (Asc)</option>
                  <option value={'updated'}>Updated (Desc)</option>
                </select>
              </div>

            </div>

          </FilterBar>
        </div>

      </div>

      {loading ? <LoadingSpinner /> :
        <div className="panel panel-portal affinity-table">
          <div className="table-responsive">
            <table className="table table-hover table-portal">
              <thead>
                <tr>
                  <th style={{ width: 250 }}>Vendor</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0 }}>Refresh</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0 }}>Hold</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0 }}>Payment</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0 }}>Products</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0, width: 125, maxWidth: 125 }}>Organizations</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0 }}>Created</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0 }}>Updated</th>
                  <th style={{ textAlign: 'center', paddingLeft: 0 }}>Status</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {rows}

              </tbody>

            </table>

          </div>

      </div>
      }

      <PaginationFooter
        hasNext={filters.page < totalPages}
        totalPages={totalPages}
        totalResults={totalResults}
        currentPage={filters.page}
        setPage={(page) => {
          filters.setFilters({ page });
          setFilters(filters);
          applyFilters(filters);

        }} />

    </FullContent>

  );
};

class ApplicationFilters extends Filters {
  status: any = null;
  marketType: string = '';

  constructor(location?: string) {
    super();
    this.defaultFilters.status = null;
    if (location) {
      this.setQueryParamsFromLocation(location);
    }

  }

  setQueryParamsFromLocation(location: string) {
    super.setQueryParamsFromLocation(location);
    const queryValues = queryString.parse(location);
    this.marketType = queryValues.market_type ? queryValues.market_type : '';
    this.sortBy = queryValues.sort ? queryValues.sort : '';
  }

  generateQueryParamString() {
    const queryParams = {
      search: this.search.length ? this.search : null,
      start_date: this.startDate.length ? this.startDate : null,
      end_date: this.endDate.length ? this.endDate : null,
      page: this.page !== 1 ? this.page : null,
      status: this.status !== null ? this.status : null,
      sort: this.sortBy ? this.sortBy : null,
      market_type: this.marketType ? this.marketType : null,
    };
    const cleanParams = omitBy(queryParams, isNil);
    const qs = queryString.stringify(cleanParams);
    return qs;
  }

  setFilters(filters: any) {
    super.setFilters(filters);
    const newFilters = {
      marketType: this.marketType,
      sortBy: this.sortBy,
      ...filters,
    };
    this.marketType = newFilters.marketType;
    this.sortBy = newFilters.sortBy;
  }

  get filters() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      search: this.search,
      startDate: this.startDate,
      endDate: this.endDate,
      status: this.status,
      marketType : this.marketType,
    };
  }
}
