import { faExclamationTriangle, faTag, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { UserContext } from '../../contexts';
import { RoyaltySchedule } from '../../shared';
import RoyaltyScheduleTemplate from './RoyaltyScheduleTemplate';

interface IProps {
  schedule: RoyaltySchedule;
  onClickArchive: (schedule: RoyaltySchedule) => void;
  onClickUpdateVendors: (schedule: RoyaltySchedule) => void;
  onClickUpdateStandard: () => void;
}

export default class RoyaltyScheduleEntry extends React.PureComponent<IProps, any> {

  constructor(props: IProps) {
    super(props);
    this.updateVendors = this.updateVendors.bind(this);
  }

  updateVendors() {
    return this.props.onClickUpdateVendors(this.props.schedule);
  }

  public render() {

    const schedule = this.props.schedule;

    const archivedBadge = schedule.deletedAt !== null ? (
      <FontAwesomeIcon className="pull-right" icon={faTimesCircle} size="2x" title="Archived Schedule"/>
    ) : null;
    const customBadge = schedule.isCustom ? (
      <FontAwesomeIcon className="pull-right" icon={faTag} size="2x" title="Custom Schedule"/>
    ) : null;
    const exclusiveBadge = schedule.isExclusive ? (
      <FontAwesomeIcon className="pull-right" icon={faExclamationTriangle} size="2x" title="Exclusive Schedule"/>
    ) : null;

    const exclusiveVendorNames = schedule.exclusiveVendors.map(vendor => vendor.short_name);
    const vendorSpecificBox = schedule.isVendorSpecific ? (
      <div>
        <strong>Exclusive Vendors</strong>: {exclusiveVendorNames.join(', ')}
        <UserContext.Consumer>
          {user => user.type === 'admin' ? <button className="btn btn-link" onClick={this.updateVendors}>Update Vendors</button> : null}
        </UserContext.Consumer>

      </div>
    ) : null;

    const licenseIds = schedule.licenseIds.map((id, i) => (
      <span key={id}><a href={`/licenses/${id}`}>{id}</a>{i < (schedule.licenseIds.length - 1) ? ', ' : null}</span>
    ));
    const licensesBlock = !schedule.isDefaultSchedule ? (
      <div>
        <strong>Licenses: </strong>
        {licenseIds}
      </div>
    ) : null;

    const archiveButton = (!schedule.isDefaultSchedule && schedule.deletedAt === null) ? (
      <button
        className="btn btn-default"
        onClick={() => (this.props.onClickArchive(this.props.schedule))}
      >
        Archive
      </button>
    ) : null;

    const standardRoyaltyEdit = (schedule.isDefaultSchedule && schedule.deletedAt === null) ? (
      <button
        className="btn btn-default"
        onClick={this.props.onClickUpdateStandard}
      >
        Edit Standard Royalty
      </button>
    ) : null;

    return (
      <div>
        <div className="row">
          <div className="col-xs-8">
            <h4 className="ng-binding">{schedule.title}</h4>
          </div>
          <div className="col-xs-4">
            {archivedBadge}
            {customBadge}
            {exclusiveBadge}
            <span className="badge pull-right">{schedule.countMetadata.active_licenses}</span>
          </div>
        </div>
        <RoyaltyScheduleTemplate
          schedule={schedule}
          hasStandardRoyalty={true}
        />
        {/* <UserContext.Consumer>
          {user => user.type === 'admin' ?  standardRoyaltyEdit : null}
        </UserContext.Consumer> */}
        {vendorSpecificBox}
        {licensesBlock}
        <UserContext.Consumer>
          {user => user.type === 'admin' ? archiveButton : null}
        </UserContext.Consumer>
      </div>
    );
  }
}
