import { faArrowLeft, faCheckCircle, faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Prompt, RouteComponentProps } from 'react-router';
import { UserContext } from '../../../contexts';
import { AffinityClient, ProductCategory } from '../../../shared';
import { ContentColumnSizes, ContentWithSidebar, FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import {
  deleteDesign,
  getAvailableProducts, getCanExpedite,
  getClients,
  getInferredClient,
  IAvailableProductEntry, toggleDesignExpedite, updateDesign,
  uploadDesign,
  uploadDesignFile,
} from '../api';
import { DesignUploadPanel } from '../DesignUploadPanel';
import { DeleteDesignModal } from './DeleteDesignModal';
import { ExpediteDesignModal } from './ExpediteDesignModal';
import { IUploadItemFormValues, UploadItem } from './UploadItem';
import { UploadSection } from './UploadSection';

interface IState {
  loading: boolean;
  designsSubmitted: boolean;
  availableProducts: IAvailableProductEntry[];
  attemptedFiles: IUploadedDesignEntry[];
  licensors: AffinityClient[];
  canExpedite: boolean;
  expediteModalShown: boolean;
  hasExpeditedDesign: boolean;
  deleteModalShown: boolean;
  modalDesignId: number | null;
  isSavingAll: boolean;
}

export enum UploadStatus {
  UPLOADING,
  UPDATING,
  SUCCESS,
  REJECTED,
  FAILEDUPLOAD,
}

export interface IUploadedDesignEntry {
  file: File;
  status: UploadStatus;
  previewUrl: string;
  title: string;
  internalCode: string;
  productEntry: IAvailableProductEntry;
  categories?: ProductCategory[];
  organization: AffinityClient | null;
  designId: null | number;
  description: string;
  isExpedited: boolean;
  isExpediting: boolean;
  isDirty: boolean;
}

type DesignStateUpdate = Partial<IUploadedDesignEntry>;
const getUpdatedAttemptedFiles = (prevState: IState, designId: number, designEntryUpdate: DesignStateUpdate) => {
  const attemptedFiles = prevState.attemptedFiles.slice(0);
  const designIdx = attemptedFiles.findIndex(f => f.designId === designId);
  if (designIdx > -1) {
    attemptedFiles[designIdx] = Object.assign(attemptedFiles[designIdx], designEntryUpdate);
  }

  return { attemptedFiles };
};

const filenameWithoutExtension = (filename: string): string => {
  const dotIdx = filename.lastIndexOf('.');
  if (dotIdx === -1) {
    return filename;
  }
  return filename.substr(0, dotIdx);
};

const sidebarTop = (attemptedFiles: IUploadedDesignEntry[], designsSubmitted: boolean, onSaveAll: () => any) => {

  const statusFilterFn = (status: UploadStatus) => {
    return ((f: IUploadedDesignEntry) => f.status === status);
  };
  const savedCount = attemptedFiles.filter(statusFilterFn(UploadStatus.SUCCESS)).length;
  const uploadingCount = attemptedFiles.filter(statusFilterFn(UploadStatus.UPLOADING)).length;
  const updatingCount = attemptedFiles.filter(statusFilterFn(UploadStatus.UPDATING)).length;

  let inProgressMarkup = null;
  if (uploadingCount > 0) {
    inProgressMarkup = (
      <p>
        <strong className="text-danger">
          <FontAwesomeIcon icon={faCircleNotch} spin={true} /> Uploading
        </strong>
        - Please stay on this page until all uploads are complete.
      </p>
    );
  } else if (updatingCount > 0) {
    inProgressMarkup = (
      <p>
        <strong className="text-warning">
          <FontAwesomeIcon icon={faCircleNotch} spin={true} /> Processing
        </strong>
        - Please stay on this page until processing is complete.
      </p>
    );
  } else if (savedCount > 0) {
    inProgressMarkup = (
      <p>
        <strong className="text-primary">
          <FontAwesomeIcon icon={faCheckCircle} /> Submitted
        </strong>
        - Your designs are done uploading and have been submitted.
      </p>
    );
  }

  const saveButton = uploadingCount || updatingCount ? null : (
        <button className="btn btn-primary" onClick={onSaveAll}>Save and Submit All</button>
  );

  if (inProgressMarkup) {
    return (
      <div>
        <h4 className="text-primary">Design Submissions</h4>
        {inProgressMarkup}
        <hr />
        {saveButton}
      </div>
    );
  }

  const uploadingText = designsSubmitted ? (
    'All of your designs have been submitted'
  ) : (
    'The more information you provide now, the quicker we can review your designs'
  );

  return (
    <div>
      <FontAwesomeIcon icon={faArrowLeft} size={'3x'} className="text-primary" />
      <h3>
        Start uploading designs
      </h3>
      <span className="text-muted">
        You can drag and drop multiple files onto each category or click a category to upload separately.
      </span>
      <h4 style={{ paddingTop: 15 }}>
        <strong className="text-primary">{uploadingText}</strong>
      </h4>
    </div>
  );
};

export class DesignUploadPage extends React.Component <RouteComponentProps<any>, IState> {

  private _getLicensorSource: CancelTokenSource;

  constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = {
      loading: false,
      designsSubmitted: false,
      availableProducts: [],
      attemptedFiles: [],
      licensors: [],
      canExpedite: false,
      expediteModalShown: false,
      deleteModalShown: false,
      modalDesignId: null,
      hasExpeditedDesign: false,
      isSavingAll: false,
    };
    this.getCategories = this.getCategories.bind(this);
    this.onCategoryDrop = this.onCategoryDrop.bind(this);
    this.getLicensors = this.getLicensors.bind(this);
    this.getCanExpedite = this.getCanExpedite.bind(this);
    this.updateDesign = this.updateDesign.bind(this);
    this.expediteDesign = this.expediteDesign.bind(this);
    this.deleteDesign = this.deleteDesign.bind(this);
    this.uploadDesigns = this.uploadDesigns.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.expediteClicked = this.expediteClicked.bind(this);
    this.deleteClicked = this.deleteClicked.bind(this);
    this.saveAll = this.saveAll.bind(this);
    this.onSavedAllRequest = this.onSavedAllRequest.bind(this);
    this.clearItem = this.clearItem.bind(this);
  }

  componentDidMount() {
    this.getCategories();
    this.getLicensors();
    this.getCanExpedite();
  }

  componentWillUnmount() {
    if (this._getLicensorSource) {
      this._getLicensorSource.cancel();
    }
  }

  render() {

    const main =  this.state.loading ? (<LoadingSpinner />) : (
      <UploadSection
        vendorId={this.props.match.params['vendorId']}
        availableProducts={this.state.availableProducts}
        onCategoryDrop={this.onCategoryDrop}
      />
    );

    const sidebar = (
      <div>
        <div className="panel">
          <div className="panel-body">
            {sidebarTop(this.state.attemptedFiles, false, this.saveAll)}
          </div>
        </div>
        {this.state.attemptedFiles.map((attemptedFile, uploadItemIndex) => (
          <UploadItem
            key={uploadItemIndex}
            products={this.state.availableProducts}
            licensors={this.state.licensors}
            item={attemptedFile}
            isSavingAll={this.state.isSavingAll}
            onSubmitFn={this.updateDesign}
            onExpediteFn={this.expediteClicked}
            onDeleteFn={this.deleteClicked}
            onSavedAll={this.onSavedAllRequest}
          />
        ))}
      </div>
    );

    const columnSizes: ContentColumnSizes = {
      main: 'col-xl-3 col-lg-4 col-md-5',
      sidebar: 'col-xl-9 col-lg-8 col-md-7',
    };

    return (
      <UserContext.Consumer>
        {user => user.type === 'admin' || (user.canUploadDesigns && user.account.status.display === 'Current') ?
        (
            <FullContent>
              <Prompt
                when={this.state
                  .attemptedFiles
                  .some(x => x.status === UploadStatus.UPLOADING || x.status === UploadStatus.UPDATING)}
                message="Are you sure you want to leave? You have unsaved changes."
              />
              <ContentWithSidebar
                columnSizes={columnSizes}
                main={main}
                sidebar={sidebar}
              />
              <ExpediteDesignModal
                isShown={this.state.expediteModalShown}
                canExpedite={true}
                confirm={this.expediteDesign}
                close={this.closeModals}
              />
              <DeleteDesignModal
                isShown={this.state.deleteModalShown}
                confirm={this.deleteDesign}
                close={this.closeModals}
              />

            </FullContent>

        ) : (
          <FullContent>
              <DesignUploadPanel
                hasPermissions={user.canUploadDesigns}
                canMassUpload={user.canMassUploadDesigns}
                includeButton={false}
                isCurrent={user.account.status.display === 'Current'}
              />
            </FullContent>

        )
      }

      </UserContext.Consumer>

    );
  }

  onCategoryDrop(productEntry: IAvailableProductEntry, acceptedFiles: File[], rejectedFiles: File[]) {
    const fileMap = (initialStatus: UploadStatus.UPLOADING | UploadStatus.REJECTED) => {
      return (acceptedFile: File) => ({
        productEntry,
        file: acceptedFile,
        status: initialStatus,
        previewUrl: URL.createObjectURL(acceptedFile),
        title: filenameWithoutExtension(acceptedFile.name),
        internalCode: filenameWithoutExtension(acceptedFile.name),
        organization: null,
        designId: null,
        description: '',
        isExpedited: false,
        isExpediting: false,
        isDirty: false,
      });
    };

    const hasPdfs = acceptedFiles.some(file => file.type.indexOf('application/pdf') === 0);

    let allowPdfs = false;
    if (hasPdfs) {
      allowPdfs = confirm(
        'You are uploading a PDF file. Only the first page of your PDF file will be submitted and reviewed.'
        + ' If your PDF has multiple pages, additional pages after the first page will not be included in your'
        + ' submission or reviewed. Click \'OK\' to continue submitting the first page of this PDF for review.',
      );
    }

    let accepted = acceptedFiles.map(fileMap(UploadStatus.UPLOADING));
    if (!allowPdfs) {
      accepted = accepted.filter(entry => entry.file.type.indexOf('application/pdf') !== 0);
    } else {
      accepted = accepted.map((entry) => {
        entry.title = `${entry.title}  (PDF)`;
        entry.internalCode = `${entry.internalCode} (PDF)`;
        entry.previewUrl = '';
        return entry;
      });
    }

    const rejected = rejectedFiles.map(fileMap(UploadStatus.REJECTED));

    const newFiles: IUploadedDesignEntry[] = accepted.concat(rejected);

    this.setState({
      attemptedFiles: newFiles.concat(this.state.attemptedFiles),
    });
    this.uploadDesigns(accepted);
  }

  uploadDesigns(designs: IUploadedDesignEntry[]) {
    designs.forEach((design) => {

      const uploadFilePromise = uploadDesignFile(design.file);
      const inferNamePromise = getInferredClient(design.file.name);

      Promise.all([uploadFilePromise, inferNamePromise])
        .then(([uploadResponse, inferredClient]) => {
          const inferredClientId = inferredClient ? Number(inferredClient.id) : null;
          const designParams = {
            primaryClientId: inferredClientId,
            isAutotaggedClient: !!inferredClientId,
            fileId: uploadResponse.id,
            productCategoryId: design.productEntry.categoryId,
            internalCode: design.internalCode,
            title: design.title,
            description: design.description,
            vendorAccountId: this.props.match.params['vendorId'],
          };

          return uploadDesign(designParams);
        }).then((designUploadResponse) => {
          const updater = (prevState: IState, props: any) => {
            const attemptedFiles = prevState.attemptedFiles.slice(0);
            const designIdx = attemptedFiles.indexOf(design);
            if (designIdx === -1) {
              return { attemptedFiles };
            }

            let organization = null;
            if (designUploadResponse && designUploadResponse.primaryClientId) {
              organization = this.state.licensors.find(
                org => Number(org.id) === Number(designUploadResponse.primaryClientId),
              );
            }

            attemptedFiles[designIdx] = Object.assign({}, prevState.attemptedFiles[designIdx], {
              organization,
              status: (designUploadResponse === null ? UploadStatus.FAILEDUPLOAD : UploadStatus.SUCCESS),
              designId: designUploadResponse ? designUploadResponse.id : null,
              categories: designUploadResponse ? designUploadResponse.productCategories : [],
            });

            return { attemptedFiles };
          };

          this.setState(updater);
        });
    });
  }

  updateDesign(designId: number, design: IUploadItemFormValues): Promise<any> {

    const statusUpdater = (designId: number, status: UploadStatus) => {
      return (prevState: IState, props: any) => {
        return getUpdatedAttemptedFiles(prevState, designId, { status });
      };
    };
    this.setState(statusUpdater(designId, UploadStatus.UPDATING));

    return updateDesign({ designId, ...design }).then((succeeded) => {
      const status = succeeded ? UploadStatus.SUCCESS : UploadStatus.FAILEDUPLOAD;
      this.setState(statusUpdater(designId, status));
    });
  }

  expediteDesign(): Promise<boolean> {
    const designId = this.state.modalDesignId;

    if (designId === null) {
      throw 'Design ID is missing';
    }

    const isExpeditingFlagUpdater = (designId: number) => {
      return (prevState: IState, props: any) => {
        return getUpdatedAttemptedFiles(prevState, designId, {
          isExpediting: true,
        });
      };
    };
    this.setState(isExpeditingFlagUpdater(designId));
    this.closeModals();

    const design = this.state.attemptedFiles.find(f => f.designId === designId);
    if (!design) {
      throw 'Design is missing';
    }

    const expedite = !design.isExpedited;

    if (expedite && !this.state.hasExpeditedDesign) {
      this.setState({ hasExpeditedDesign : true });
    }

    return toggleDesignExpedite(designId, expedite).then((succeeded) => {
      const expediteFlagUpdater = (designId: number, expedite: boolean) => {
        return (prevState: IState, props: any) => {
          const attemptedFiles = prevState.attemptedFiles.slice(0);
          const designIdx = attemptedFiles.findIndex(f => f.designId === designId);
          if (designIdx > -1) {
            attemptedFiles[designIdx] = Object.assign(attemptedFiles[designIdx], {
              isExpedited: expedite,
              isExpediting: false,
            });
          }

          return { attemptedFiles };
        };
      };
      this.setState(expediteFlagUpdater(designId, expedite));
      return succeeded;
    });
  }

  deleteDesign(): Promise<boolean> {
    const designId = this.state.modalDesignId;

    if (designId === null) {
      throw 'Design ID is missing';
    }

    this.closeModals();
    return deleteDesign(designId).then((succeeded) => {
      const deleteDesignUpdater = (designId: number) => {
        return (prevState: IState, props: any) => {
          const designIdx = prevState.attemptedFiles.findIndex(f => f.designId === designId);
          this.setState({
            attemptedFiles: prevState.attemptedFiles
              .slice(0, designIdx).concat(prevState.attemptedFiles.slice(designIdx + 1)),
          });
        };
      };
      this.setState(deleteDesignUpdater(designId));
      return succeeded;
    });
  }

  expediteClicked(designId: number, expedite: boolean) {
    if (expedite && !this.state.hasExpeditedDesign) {
      this.setState({
        expediteModalShown: true,
        modalDesignId: designId,
      });
    } else {
      this.setState({ modalDesignId: designId }, () => {
        this.expediteDesign();
      });
    }
  }

  deleteClicked(designId: number) {
    this.setState({
      deleteModalShown: true,
      modalDesignId: designId,
    });
  }

  closeModals(): void {
    this.setState({
      expediteModalShown: false,
      deleteModalShown: false,
      modalDesignId: null,
    });
  }

  saveAll(): void {
    this.setState({ isSavingAll: true });

    // Since we don't know when "all" designs have finished saving,
    // just reset after 3s
    setTimeout(
      (() => {
        this.setState((prevState: IState, props: any) => {
          const attemptedFiles = prevState
            .attemptedFiles
            .filter(a => a.status === UploadStatus.UPDATING || a.status === UploadStatus.UPLOADING);
          return {
            attemptedFiles,
            isSavingAll: false,
          };
        });
      }),
      3000,
    );
  }

  onSavedAllRequest(designId: number): void {
    setTimeout(
      (() => {
        this.clearItem(designId);
      }),
      3000,
    );
  }

  clearItem(designId: number): void {
    const clearItemUpdater = (designId: number) => {
      return (prevState: IState, props: any) => {
        const attemptedFiles = prevState.attemptedFiles.slice(0);
        const designIdx = attemptedFiles.findIndex(f => f.designId === designId);
        if (designIdx > -1) {
          attemptedFiles.splice(designIdx, 1);
        }
        return { attemptedFiles };
      };
    };
    this.setState(clearItemUpdater(designId));
  }

  getLicensors() {
    this._getLicensorSource = axios.CancelToken.source();
    getClients(this._getLicensorSource, true).then((clients) => {
      this.setState({ licensors: clients });
    });
  }

  getCategories() {
    this.setState({ loading: true });
    const vendorId = this.props.match.params['vendorId'];
    getAvailableProducts(vendorId).then((availableProducts) => {
      this.setState({ availableProducts, loading: false });
    });
  }

  getCanExpedite() {
    const vendorId = this.props.match.params['vendorId'];
    getCanExpedite(vendorId).then((canExpedite) => {
      this.setState({ canExpedite });
    });
  }
}
