import { ErrorMessage, Field, Form, Formik } from 'formik';
import { isNil, omitBy } from 'lodash';
import * as React from 'react';
import * as Yup from 'yup';

interface SitePageImageFields {
  url: string;
  link: string;
  internal_link: 0 | 1;
  alt: string;
}

interface IProps {
  onSubmit: (changes: string) => void;
  initialValues?: SitePageImageFields;
}

export const SitePageImageForm = (props: IProps) => {

  const intialValues: SitePageImageFields = props.initialValues ? props.initialValues : {
    url: '',
    link: '',
    internal_link: 0,
    alt: '',
  };

  const handleSubmit = (values: SitePageImageFields) => {
    const s = {
      url: values.url,
      link: values.link ? values.link : null,
      internal_link: Number(values.internal_link) ? true : false,
      alt: values.alt ? values.alt : null,
    };
    const cleaned = omitBy(s, isNil);
    props.onSubmit(JSON.stringify(cleaned));
  };

  const validationSchema = Yup.object({
    url: Yup.string().url('Must be a valid URL').required('URL is required.'),
  });

  return (
    <Formik onSubmit={handleSubmit} initialValues={intialValues} validationSchema={validationSchema}>
      {props => (
        <Form>
          <div className="form-group">
            <label>Image URL</label>
            <Field placeholder="https://example.com/image.jpg" className="form-control" name="url" />
            <p className="text-danger">
              <ErrorMessage name="url" />
            </p>
          </div>
          <div className="form-group">
            <label>Link</label>
            <Field placeholder="https://example.com or /gallery" className="form-control" name="link" />
          </div>
          <div className="form-group">
            <label>Link Type</label>
            <Field component="select" className="form-control" name="internal_link" >
              <option value={0}>External</option>
              <option value={1}>Internal</option>
            </Field>
          </div>
          <div className="form-group">
            <label>Alt Text</label>
            <Field placeholder="Alt Text" className="form-control" name="alt" />
            <p className="help-block">Text that appears when you hover mouse.</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="btn btn-primary">Save Page Item</button>
          </div>
        </Form>
      )}
    </Formik>
  );

};
