import { File } from './File';

export class ReportSavedRoyalties {
  id: number;
  year: number;
  quarter: number;
  grossPayments: number;
  grossRoyalties: number;
  netRoyalties: number;
  affinityNet: number;
  nonSharing: number;
  nonSharingAdmin: number;
  nonSharingRoyalties: number;
  adminFees: number;
  sharesAdminFees: boolean;
  csv: File;
  pdf: File;
  adjustments: any[] = [];
  paymentCount: number;

  static fromApi(data: any) {
    const r =  new ReportSavedRoyalties();
    r.id = data.id;
    r.year = data.year;
    r.quarter = data.quarter;
    r.grossPayments = data.gross_payments;
    r.grossRoyalties = data.gross_royalties;
    r.netRoyalties = data.net_royalties;
    r.affinityNet = data.affinity_net;
    r.nonSharing = data.non_sharing;
    r.adminFees = data.admin_fees;
    r.sharesAdminFees = data.shares_admin_fees;
    r.nonSharingAdmin = data.non_sharing_admin;
    r.nonSharingRoyalties = data.non_sharing_royalties;
    if (data.csv) {
      r.csv = new File(data.csv);
    }
    if (data.pdf) {
      r.pdf = new File(data.pdf);
    }
    if (data.adjustments) {
      r.adjustments = data.adjustments;
    }
    r.paymentCount = data.payment_count;
    return r;
  }
}
