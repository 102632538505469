import * as React from 'react';
import { useLocation, useParams } from 'react-router';

export const GeolocationNoMapPerformance = () => {
  const location = useLocation();
  const routeParams = useParams();

  const createGeolocationLink = (params: string): string => {
    if (routeParams['vendorId']) {
      return `/vendors/${routeParams['vendorId']}/performance/geolocation?${params}`;
    }
    if (routeParams['licensorId']) {
      return `/clients/${routeParams['licensorId']}/performance/geolocation?${params}`;
    }
    return `/performance/geolocation?${params}`;
  };

  const params = location.search.substring(1);

  const openInNewTab = () => {
    const params = location.search.substring(1);
    window.open(createGeolocationLink(params), '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <div className="performance-chart-header">
        <div>
          <strong>Geolocation</strong>
          <p className="text-muted small">Sales by reported city and state.</p>
        </div>
      </div>
      <div className="text-center">
        <button onClick={openInNewTab} className="btn btn-primary" style={{ cursor: 'pointer' }}>
          View Geolocation Report
        </button>
      </div>
    </>
  );
};
