import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import * as React from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getAffinityPageTitle } from '../../../utils';
import { FullContent } from '../../ContentFrame';
import { LoadingSpinner } from '../../shared';
import { getCaseCounts, getCaseList } from '../Uses/api';

export const NoOpenCasesPage = () => {
  const routeMatch = useRouteMatch<{vendorId: string, clientId: string}>();
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = React.useState(true);
  const [closedCaseCount, setClosedCaseCount] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [routePrefix, setRoutePrefix] = React.useState('');

  React.useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      if (routeMatch && routeMatch.params['vendorId']) {
        setRoutePrefix(`/vendors/${routeMatch.params['vendorId']}`);
      }
      if (routeMatch && routeMatch.params['clientId']) {
        setRoutePrefix(`/clients/${routeMatch.params['clientId']}`);
      }
    }
    if (routeMatch && !routeMatch.params['vendorId'] &&  routeMatch.path.endsWith('/enforce')) {
      return history.replace(`${location.pathname}/cases`);
    }
    getCasesCount();
  },              []);

  const getCasesCount = async () => {
    let vendorId = null;
    if (routeMatch !== null && routeMatch.params['vendorId']) {
      vendorId = routeMatch.params['vendorId'];
    }
    const response = await getCaseCounts({ vendorId });
    const caseCounts = response.data.data;
    if (routeMatch && caseCounts.open > 0) {
      const cancelTokenSource = Axios.CancelToken.source();
      const c = await getCaseList({ vendorId, isOpen: 1 }, cancelTokenSource);
      if (c.cases.length > 0) {
        let routePrefix = '';
        routePrefix = `/vendors/${routeMatch.params['vendorId']}`;
        return history.replace(`${routePrefix}/enforce/cases/${c.cases[0].id}`);
      }
    }
    setClosedCaseCount(caseCounts.closed);
    setLoading(false);
  };

  return (
    <FullContent>
      <DocumentTitle title={getAffinityPageTitle('Cases')} />

      {loading ? <LoadingSpinner /> :
        <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3><strong>Cases</strong></h3>
        </div>
        <div style={{ marginTop: 15 }} className="panel panel-portal text-center">
          <div className="panel-body">
            <FontAwesomeIcon icon={faInfoCircle} className="text-primary fa-4x"/>
            <h3>No Open Cases</h3>
          </div>
          <div className="panel-body">
            <DropdownButton
              style={{ marginRight: 5 }}
              id="content-button"
              title="Open New Case"
              bsStyle="primary"
            >
              <MenuItem eventKey="1"><Link to={`${routePrefix}/enforce/uses/new`}> Internet Content</Link></MenuItem>
              <MenuItem eventKey="2"><Link to={`${routePrefix}/enforce/uses/manual-new`}> Other Content</Link></MenuItem>
            </DropdownButton>
            { closedCaseCount > 0 ?
              <Link className="btn btn-default" to={`${routePrefix}/enforce/cases/closed`} style={{ marginRight: 5 }}>
                View Closed Cases
              </Link> : null}
          </div>
        </div>
        </>}

    </FullContent>
  );
};
