import * as React from 'react';
import NumberFormat from 'react-number-format';

interface IProps {
  value: number | string;

}

export class CurrencyLabel extends React.Component<IProps, any> {

  constructor(props: IProps) {
    super(props);
  }

  render() {

    // Workaround for https://github.com/s-yadav/react-number-format/issues/314
    let numValue = this.props.value;
    if (typeof numValue === 'number' && numValue < 0 && numValue > -0.005) {
      numValue = 0;
    }
    if (typeof numValue === 'number') {
      numValue = numValue.toFixed(5);
    }

    return (
      <NumberFormat
        prefix={'$'}
        displayType={'text'}
        value={numValue}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator={true}
      />
    );

  }

}
