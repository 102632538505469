import * as React from 'react';
import { ChangeEvent } from 'react';
import User from '../../shared/User';

interface IQuarter {
  label: string;
  value: string;
}

interface IProps {
  changedFn: (params: any, reload: boolean) => void;
  search: string;
  perPage: number;
  quarter: string;
  startDate: string;
  endDate: string;
  quarters: IQuarter[];
  submitted: boolean | null;
  selectedAdminId: string | null;
  assignableAdmins: User[];
  unassignableAdmins: User[];
  showSubmittedFilter: boolean;
  showAdminFilter: boolean;
}

export class SearchFilterBar extends React.Component<IProps, any> {
  public constructor(props: any) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAndReload = this.handleChangeAndReload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handleChangePageSize = this.handleChangePageSize.bind(this);
    this.handleChangeQuarter = this.handleChangeQuarter.bind(this);
    this.handleChangeSubmitted = this.handleChangeSubmitted.bind(this);
    this.handleChangeAdmin = this.handleChangeAdmin.bind(this);
  }

  public handleChange(items: any) {
    this.props.changedFn(items, false);
  }

  public handleChangeAndReload(items: any) {
    this.props.changedFn(items, true);
  }

  public handleSubmit(e: any) {
    e.preventDefault();
    this.handleChangeAndReload({});
  }

  public handleChangeSearchText(event: ChangeEvent<HTMLInputElement>) {
    this.handleChange({
      search: event.target.value,
    });
  }

  public handleChangePageSize(event: ChangeEvent<HTMLSelectElement>) {
    this.handleChangeAndReload({ perPage: parseInt(event.target.value, 10) });
  }

  public handleChangeQuarter(event: ChangeEvent<HTMLSelectElement>) {
    const quarter = event.target.value;
    this.handleChangeAndReload({ quarter });
  }

  public handleChangeSubmitted(event: ChangeEvent<HTMLSelectElement>) {
    const submitted = event.target.value;

    let submittedBool = null;
    if (submitted === 'yes') {
      submittedBool = true;
    } else if (submitted === 'no') {
      submittedBool = false;
    }

    this.handleChangeAndReload({ submitted: submittedBool });
  }

  public handleChangeAdmin(event: ChangeEvent<HTMLSelectElement>) {
    const adminId = event.target.value ? event.target.value : null;

    this.handleChangeAndReload({ selectedAdminId: adminId });
  }

  public render() {

    const quarterOptions = this.props.quarters.map((quarter: IQuarter) =>
      <option key={quarter.value} value={quarter.value}>{quarter.label}</option>,
    );

    let submittedVal = '';
    if (this.props.submitted !== null) {
      submittedVal = (this.props.submitted) ? 'yes' : 'no';
    }

    const adminIdKey = this.props.selectedAdminId !== null ? this.props.selectedAdminId : '';

    const optionMapFn = (admin: User) => (
      <option key={admin.id} value={admin.id}>{admin.firstName} {admin.lastName}</option>
    );

    const adminOptions = (
      <>
        {this.props.assignableAdmins.map(admin => optionMapFn(admin))}
        <optgroup label="────" />
        {this.props.unassignableAdmins.map(admin => optionMapFn(admin))}
      </>
    );

    const submittedFilterSection = this.props.showSubmittedFilter ? (
      <div className="col-lg-2 col-sm-4 col-lg-push-0 col-sm-push-4 col-xs-12">
        <select className="form-control input-sm" value={submittedVal} onChange={this.handleChangeSubmitted}>
          <option value="">Filter Submitted</option>
          <option value="yes">Submitted Only</option>
          <option value="no">Not Submitted Only</option>
        </select>
      </div>
    ) : (
      null
    );

    const adminFilterSection = this.props.showAdminFilter ? (
      <div className="col-lg-2 col-sm-4 col-lg-push-0 col-sm-push-4 col-xs-12">
        <select className="form-control input-sm" value={adminIdKey} onChange={this.handleChangeAdmin}>
          <option value="">Filter Admins</option>
          {adminOptions}
        </select>
      </div>
    ) : (
      null
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-lg-4 col-sm-4 col-xs-12">
            <div className="input-group">
              <input
                type="text"
                className="form-control input-sm"
                value={this.props.search}
                onChange={this.handleChangeSearchText}
                placeholder="Search"
              />
              <span className="input-group-btn">
                <button className="btn btn-default btn-sm" type="submit" onClick={this.handleSubmit}>Search</button>
              </span>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 col-xs-12">
            <select className="form-control input-sm" value={this.props.perPage} onChange={this.handleChangePageSize}>
              <option value={8}>8 per page</option>
              <option value={24}>24 per page</option>
              <option value={48}>48 per page</option>
              <option value={96}>96 per page</option>
            </select>
          </div>
          <div className="col-lg-2 col-sm-4 col-xs-12">
            <select className="form-control input-sm" value={this.props.quarter} onChange={this.handleChangeQuarter}>
              <option value="">Filter Quarter</option>
              {quarterOptions}
            </select>
          </div>
          {submittedFilterSection}
          {adminFilterSection}
        </div>
      </form>
    );
  }
}
