import * as moment from 'moment';
import { AccountAlias, AccountAliasAPIResponse } from '.';
import { AccountFormValues } from '../components/Account';
import { AffinityAddress, AffinityAddressAPIResponse } from './AffinityAddress';
import { AffinityImage, ImageApiResponse } from './Image';
import User from './User';

export interface AccountProfileAPIResponse {
  id: number;
  name: string;
  short_name: string;
  public_short_name: string;
  account_type: any;
  status: { id: number, display: string };
  intercom_company_id: string;

  business_address: AffinityAddressAPIResponse;
  public_address: AffinityAddressAPIResponse;

  website: string;
  description: string;

  is_vendor: boolean;
  is_client: boolean;

  image: ImageApiResponse;

  header_image?: ImageApiResponse;

  account_dbas?: AccountAliasAPIResponse[];

  primary_contact: any;

  flag?: { id: number, message: string, at: string };

  admin_rep: any;

}

interface AccountFlag {
  id: number;
  message: string;
  at: moment.Moment;
}

export class AccountProfile {
  id: number;
  accountName: string; // To be used in the UI
  shortAccountName: string; // To be used in the UI
  legalName: string;
  type: any;
  status: { id: number, display: string };
  businessAddress: AffinityAddress;
  publicAddress: AffinityAddress;
  website: string;
  description: string;
  intercom: string;

  // Social Media maybe create AffinitySocialMedia?

  logo: AffinityImage;

  banner: AffinityImage;

  aliases: AccountAlias[] = [];

  primaryConact: User;

  accountType: 'vendor' | 'client';

  flag: AccountFlag;

  adminRep: User;

  static fromApi(data: AccountProfileAPIResponse) {
    const profile = new AccountProfile();
    profile.id = data.id;
    profile.legalName = data.name;
    profile.accountName = data.short_name;
    profile.shortAccountName = data.public_short_name;
    profile.type = data.account_type;
    profile.status = data.status;
    profile.businessAddress = AffinityAddress.fromApi(data.business_address);
    profile.publicAddress = AffinityAddress.fromApi(data.public_address);
    profile.intercom = data.intercom_company_id;
    // ensure website has http

    if (data.website) {
      profile.website = data.website;
      const hasHttp = profile.website.match(/^http/);
      if (!hasHttp) {
        profile.website = `http://${profile.website}`;
      }
    }

    profile.description = data.description;

    if (data.image) {
      profile.logo = AffinityImage.fromApi(data.image);
    } else {
      profile.logo = new AffinityImage();
    }

    if (data.account_dbas) {
      profile.aliases = data.account_dbas.map(alias => AccountAlias.fromApi(alias));
    }

    if (data.primary_contact) {
      profile.primaryConact = new User(data.primary_contact);
    }
    if (data.is_client) {
      profile.accountType = 'client';
    }
    if (data.is_vendor) {
      profile.accountType = 'vendor';
    }

    if (data.header_image) {
      profile.banner = AffinityImage.fromApi(data.header_image);
    } else {
      profile.banner = new AffinityImage();
    }

    if (data.flag) {
      profile.flag = {
        id: data.flag.id,
        message: data.flag.message,
        at: moment(data.flag.at),
      };
    }

    if (data.admin_rep) {
      profile.adminRep = new User(data.admin_rep);
    }

    return profile;
  }

  static formValuesToFormData(values: AccountFormValues) {
    const formData = new FormData();
    formData.append('name', values.legalBusinessName);
    formData.append('short_name', values.accountName);
    if (values.accountShortName) {
      formData.append('public_short_name', values.accountShortName);
    }
    formData.append('address_line1', values.businessLine1);
    formData.append('address_line2', values.businessLine2);
    formData.append('city', values.businessCity);
    formData.append('state', values.businessState);
    formData.append('zip', values.businessZip);
    formData.append('phone', values.businessPhone);
    formData.append('description', values.description ? values.description : '');
    formData.append('public_address_line1', values.publicLine1);
    formData.append('public_address_line2', values.publicLine2);
    formData.append('public_city', values.publicCity);
    formData.append('public_state', values.publicState);
    formData.append('public_zip', values.publicZip);
    formData.append('public_phone', values.publicPhone);

    if (values.logo) {
      formData.append('image', values.logo);
    }
    // puts priority on locally uploaded image vs an ID
    if (values.logoId && !values.logo) {
      formData.append('image_id', `${values.logoId}`);
    }
    if (values.banner) {
      formData.append('banner', values.banner);
    }

    return formData;
  }

  get statusColor() {
    const status = Number(this.status.id);
    if (status === 2) {
      return 'success';
    }
    if (status === 4 || status === 1) {
      return 'info';
    }
    if (status === 5) {
      return 'danger';
    }
    if (status === 3) {
      return 'warning';
    }
    return 'muted';

  }

  get intercomURL() {
    return `https://app.intercom.io/a/apps/${window['INTERCOM_APP_ID']}/companies/${this.intercom}`;
  }

  thirtyDayNotice() {
    if (this.flag) {
      const start = moment(this.flag.at).format('dddd, MMMM Do, YYYY');
      const end = moment(this.flag.at).add(30, 'days');
      return `Started on ${start} and ending on ${end.format('dddd, MMMM Do, YYYY')} (${end.fromNow()})`;
    }

    return null;

  }

}
