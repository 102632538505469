import * as moment from 'moment';
import User from './User';

interface IPhase {
  id: number;
  phase: string;
  approved: boolean;
}

export class RoyaltyReportAction {
  id: number;
  actionAt: moment.Moment;
  user: User | null;
  phase: IPhase;
  message: string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.actionAt = moment(data.action_at);
      this.user = data.user ? new User(data.user) : null;
      this.phase = data.phase;
      this.message = data.message === null ? '' : data.message;
    }
  }
}
