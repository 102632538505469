import Axios from 'axios';
import * as React from 'react';
import { SubmittableStatuses } from '../../shared/RequestStatuses';

export type MassUploadStatus = SubmittableStatuses<string, string>;

type StatusUpdateFn = (status: MassUploadStatus) => any;

interface IProps {
  statusUpdateFn: StatusUpdateFn;
  disabled?: boolean;
}

const massUpload = async (file: File): Promise<MassUploadStatus> => {
  const formData = new FormData();
  formData.append('notes', file);
  console.log(file, formData.getAll('notes'));
  const headers = { 'Content-Type': 'multipart/form-data' };
  const t = await Axios.post('/api/note-csv', formData, { headers })
    .catch(error => error.response);
  if (t.status === 200) {
    return {
      state: 'success',
      content: t.data.message,
    };
  }
  let errorMessage = 'An unknown failure occurred.';
  if (t.status === 422 && t.data.error) {
    errorMessage = t.data.error;
  }
  return {
    state: 'failure',
    error: errorMessage,
  };
};

export const MassUploadButton = (props: IProps) => {

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('handleClick', hiddenFileInput);
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.click();
    }
  };

  const selectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('selectFile', e);
    if (e.target.files && e.target.files.length > 0) {
      props.statusUpdateFn({ state: 'loading' });
      const resultStatus = await massUpload(e.target.files[0]);

      if (hiddenFileInput.current !== null) {
        hiddenFileInput.current.value = '';
      }
      props.statusUpdateFn(resultStatus);
    }
  };

  return (
    <>
      <button
        className="btn btn-default"
        onClick={handleClick}
        disabled={props.disabled === true}
      >
        Mass Upload
      </button>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        onChange={selectFile}
        accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.oasis.opendocument.spreadsheet"
      />
    </>
  );
};

interface IStatusAlertProps {
  status: MassUploadStatus;
}

export const MassUploadStatusAlert = (props: IStatusAlertProps) => {

  if (props.status.state === 'failure') {
    return (
      <div className="alert alert-danger">
        Failed to upload note: { props.status.error }
      </div>
    );
  }

  if (props.status.state === 'loading') {
    return (
      <div className="alert alert-info">
        Uploading notes...
      </div>
    );
  }

  if (props.status.state === 'success') {
    return (
      <div className="alert alert-success">
        { props.status.content }
      </div>
    );
  }

  return <></>;
};
