import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { CancelTokenSource } from 'axios';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import NumberFormat from 'react-number-format';
import { RouteComponentProps, withRouter } from 'react-router';
import { getAffinityPageTitle } from '../../utils';
import { FullContent } from '../ContentFrame';
import { GatewayModal, LoadingSpinner, ModalType } from '../shared';
import { getLicensorOverviews, ILicensorOverview } from './api';
import { LicensorOverviewFilters } from './LicensorOverviewFilters';

type IProps = RouteComponentProps<any>;

export interface IFilters {
  keyword: string;
  market: string;
}

interface IState {
  loading: boolean;
  licensorOverviews: ILicensorOverview[];
  filters: IFilters;
  filteredLicensorOverviews: ILicensorOverview[];
  modalShown: boolean;
}

const dollarFormat = (value: number) => {
  return <NumberFormat
    value={value}
    displayType="text"
    thousandSeparator={true}
    prefix="$"
    decimalScale={2}
    fixedDecimalScale={true}
  />;
};

class LicensorOverviewPage extends React.Component<IProps, IState> {

  protected _getLicensorOverviewSource: CancelTokenSource;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
      licensorOverviews: [],
      filters: {
        keyword: '',
        market: '',
      },
      filteredLicensorOverviews: [],
      modalShown: false,
    };

    this.getLicensorOverviews = this.getLicensorOverviews.bind(this);
    this.updateFilteredOverviews = this.updateFilteredOverviews.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount(): void {
    this.getLicensorOverviews();
  }

  componentWillUnmount(): void {
    if (this._getLicensorOverviewSource) {
      this._getLicensorOverviewSource.cancel('Cancelled getLicensorOverviews() due to unmount');
    }
  }

  getLicensorOverviews() {
    // const licensorId = this.props.match.params.licensorId ? this.props.match.params.licensorId : null;
    this.setState({ loading: true });
    this._getLicensorOverviewSource = axios.CancelToken.source();
    getLicensorOverviews(this._getLicensorOverviewSource).then((overviews: any) => {
      // Set full overviews list
      this.setState({
        licensorOverviews: overviews,
        filteredLicensorOverviews: overviews,
        loading: false,
      });
    });
  }

  formatPercent(percent: number) {
    const p = (percent * 100).toFixed(1);

    if (p.includes('00')) {
      const decimalIndex = p.indexOf('.');
      return parseFloat(p.slice(0, decimalIndex));
    }

    return parseFloat(p);

  }

  public updateFilteredOverviews(e: IFilters) {
    const filtered = this.state.licensorOverviews.filter((item) => {
      const search = e.keyword.toLowerCase();
      const market = e.market;
      if (search !== '' && item.name.toLowerCase().indexOf(search) === -1) {
        return false;
      }

      if (market !== '' && item.market.indexOf(market) === -1) {
        return false;
      }

      return true;
    });

    this.setState({
      filteredLicensorOverviews: filtered,
      filters: {
        keyword: e.keyword,
        market: e.market,
      },
    });
  }

  public showModal() {
    this.setState({ modalShown: true });
  }

  public hideModal() {
    this.setState({ modalShown: false });
  }

  public render() {

    const licensorRows = this.state.filteredLicensorOverviews.map((licensorOverview) => {

      const imageTag = licensorOverview.image ? (
        <img className="pull-left hidden-sm hidden-xs" src={licensorOverview.image.urls.or} />
      ) : (
        null
      );

      return (
        <tr key={licensorOverview.id}>
          <td style={{ fontWeight: 'bold' }}>
            <a className="load-account">
            { imageTag }
            <strong>{licensorOverview.name}</strong>
            </a>
          </td>
          <td>{licensorOverview.market}</td>
          <td>{dollarFormat(licensorOverview.applicationFee)}</td>
          <td>{dollarFormat(licensorOverview.minimumGuarantee)}</td>
          <td>
            <NumberFormat
              displayType={'text'}
              value={this.formatPercent(licensorOverview.standardRoyalty)}
              decimalScale={2}
              suffix={'%'}
            />
            </td>
          <td>{dollarFormat(licensorOverview.lateFee)}</td>
          <td>{dollarFormat(licensorOverview.renewalFee)}</td>
        </tr>
      );
    });

    const infoIcon = (
      <div style={{ float: 'left', marginRight: 5 }} onClick={this.showModal}>
        <FontAwesomeIcon className="text-primary" style={{ cursor: 'pointer' }} icon={faInfoCircle} />
      </div>
    );

    const licensingTable = this.state.loading ? (
      <LoadingSpinner />
    ) : (
      <div className="table-responsive">
        <table className="table table-hover table-portal table-with-links" style={{ margin: '0 auto' }}>
          <thead>
          <tr>
            <th scope="col" style={{ minWidth: 140 }}>Licensor</th>
            <th scope="col" style={{ maxWidth: 60 }}>Market</th>
            <th scope="col" style={{ maxWidth: 80 }}>{infoIcon} Application Fee</th>
            <th scope="col" style={{ maxWidth: 80 }}>{infoIcon} Minimum Royalty</th>
            <th scope="col" style={{ maxWidth: 80 }}>{infoIcon} Standard Royalty</th>
            <th scope="col" style={{ maxWidth: 80 }}>{infoIcon} Late Fee</th>
            <th scope="col" style={{ maxWidth: 80 }}>{infoIcon} Renewal Fee</th>
          </tr>
          </thead>
          <tbody>
          {licensorRows}
          </tbody>
        </table>
      </div>
    );

    const breadcrumbs = [
      { name: 'Licensing Fees' },
    ];

    const mainContent = (
      <DocumentTitle title={getAffinityPageTitle('Licensor Overview')}>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">
                <h3>Licensing Fees Overview</h3>
                <p>
                  This page includes an overview of the standard licensing fees and royalties for all current Affinity
                  licensors. Certain product categories, distribution channels, and/or non-standard relationships may
                  have a unique royalty. Please refer to your license agreement for actual required fees and royalties.
                </p>
                <div style={{ marginTop: 20 }}>
                <LicensorOverviewFilters filters={this.state.filters} submitFn={this.updateFilteredOverviews}/>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="panel panel-portal">
              <div className="panel-body">

                {licensingTable}
              </div>
            </div>
          </div>
        </div>
        <GatewayModal
          type={ModalType.Primary}
          onClose={this.hideModal}
          shown={this.state.modalShown}
          title="Fee Information"
        >
          <Modal.Body>
            <ul>
              <li>
                <strong>Application Fee</strong>:
                The application fee is due within 15 days of submission of your online application.
              </li>
              <li>
                <strong>Annual Renewal Fee</strong>: The renewal fee is due annually in July.
              </li>
              <li>
                <strong>Standard Royalty</strong>:
                Royalties are reported and paid on a quarterly basis.
              </li>
              <li>
                <strong>Minimum Royalty</strong>:
                This is the minimum royalty amount required to be paid each contract year.
              </li>
              <li>
                <strong>Late Fee</strong>:
                This is the fee charged for any royalty report not received within 7 days of the due date.
              </li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={this.hideModal} className="btn btn-default pull-left">Close</button>
          </Modal.Footer>
        </GatewayModal>
      </div>
      </DocumentTitle>
    );

    return (
      <FullContent breadcrumbs={breadcrumbs}>{mainContent}</FullContent>
    );
  }
}

const component = withRouter(LicensorOverviewPage);
export { component as LicensorOverviewPage };
