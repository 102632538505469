import Axios from 'axios';
import * as React from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useRouteMatch } from 'react-router-dom';
import { Trademark, TrademarkUse } from '../../../shared';
import {
  GatewayImage, GatewayModal,
  LicensorTypeahead, ModalType,
  SelectableRow,
  TrademarkTypeahead,
  UrlTypeTooltip,
} from '../../shared';
import { LicensorEditRow } from '../../shared/LicensorEditRow';

interface IProps {
  selected: number[];
  onSelect: (id: number, isChecked: boolean) => void;
  selectAll: () => void;
  items: TrademarkUse[];
}

export const UsesTable = (props: IProps) => {
  const selected = props.selected;
  const [items, setItems] = React.useState(props.items);
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [selectedTrademark, setSelectedTrademark] = React.useState<Trademark[]>([]);
  const [editModalLicensors, setEditModalLicensors] = React.useState([]);
  const [useToEdit, setUseToEdit] = React.useState();
  const [showNewLicensorRow, setShowNewLicensorRow] = React.useState(false);

  let routePrefix = '';
  const routeMatch = useRouteMatch<{vendorId: string, clientId: string}>();
  if (routeMatch && routeMatch.params['vendorId']) {
    routePrefix = `/vendors/${routeMatch.params['vendorId']}`;
  }

  const updateLicensorTrademark = async (licensorId, trademarks) => {
    setSaving(true);

    const trademarkIds = [];
    const useId = useToEdit;

    trademarks.forEach(t => trademarkIds.push(t.id));
    const params = {
      licensorId,
      trademarkIds,
    };

    const result = await Axios.post(`/api/trademark-uses/${useId}/trademarks`, params);
    if (result.data.data) {
      const tmp = items.map((i) => {
        if (i.id === result.data.data.id) {
          return TrademarkUse.fromApi(result.data.data);
        }
        return i;
      });
      setItems(tmp);
    }
    setSaving(false);
  };

  const deleteLicensorTrademark = async (licensorId) => {
    if (!licensorId) {
      setShowNewLicensorRow(false);
      return;
    }
    const useId = useToEdit;
    setEditModalLicensors(editModalLicensors.filter(l => l.id !== licensorId));

    const params = {
      licensorId,
    };

    const result = await Axios.delete(`/api/trademark-uses/${useId}/trademarks`, { params });
    if (result.data.data) {
      const tmp = items.map((i) => {
        if (i.id === result.data.data.id) {
          return TrademarkUse.fromApi(result.data.data);
        }
        return i;
      });
      setItems(tmp);
    }
    return result.data;
  };

  function openEditModal(licensors, useId) {
    setShowNewLicensorRow(false);
    setEditModalLicensors(licensors);
    setUseToEdit(useId);
    // setWebBotUrlId(urlId);
    setShowEditModal(true);
  }

  function addNewLicensorRow() {
    setShowNewLicensorRow(true);
  }

  function closeEditModal() {
    setShowEditModal(false);
    // setSelectedLicensor([]);
    setSelectedTrademark([]);
  }

  function updateSelectedLicensor(l) {
    // setSelectedLicensor(l[0]);
    setShowNewLicensorRow(false);
    if (l && l.length > 0) {
      const tmp = editModalLicensors;
      l[0].trademarks = [];
      tmp.push(l[0]);
      setEditModalLicensors(tmp);
      updateLicensorTrademark(l[0].id, []);
    }

  }

  function getTrademarkCell(licensors) {
    let total = 0;
    let name = '';
    let nullTrademark = false;
    for (const l of licensors) {
      if (l.trademarks && l.trademarks.length > 0) {
        for (const t of l.trademarks) {
          name = t.title;
          total += 1;
        }
      } else {
        nullTrademark = true;
      }

    }
    let innerText = '';
    if (total === 0) {
      innerText = 'No trademarks';
    }
    if (total === 1) {
      innerText = name;
    }
    if (total > 1) {
      innerText = `${total} trademarks`;
    }
    if (nullTrademark) {
      return <span className="text-danger">{innerText}</span>;
    }
    return innerText;
  }

  return (
    <div className="table-responsive">
      <table className="table table-portal">
        <thead>
          <tr>
            <th style={{ width: 60 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <input
                  checked={selected.length && selected.length === items.length ? true : false}
                  style={{ marginRight: 8 }}
                  type="checkbox"
                  onChange={props.selectAll}
                />

              </div>

            </th>
            <th>Status</th>
            <th style={{ width: 125 }}>

            </th>
            <th>Licensor</th>
            <th>Trademark</th>
            <th>Link</th>
            <th>Created</th>
            <th>Last Crawled</th>
          </tr>
        </thead>
        <tbody>
          {items.map(i => (
            <SelectableRow selected={selected.includes(i.id)} onToggle={isChecked => props.onSelect(i.id, isChecked)}>
              <>
                <td className="text-center">
                  <Link to={`${routePrefix}/enforce/uses/${i.id}`}>
                    <span className={`label label-${i.statusLabelClass}`}>{i.status}</span>
                  </Link>
                </td>
                <td style={{ width: 125 }}>
                  <Link to={`${routePrefix}/enforce/uses/${i.id}`}>
                    <GatewayImage image={i.image.isUsingDefault ? null : i.image} size={'sm'} width={75} height={75} />
                  </Link>
                </td>
                <td onClick={e => openEditModal(i.licensors, i.id)} style={{ cursor: 'pointer' }}>
                  { i.licensors && i.licensors.length > 0 ?
                      (i.licensors.length === 1 ?
                        i.licensors[0].shortName
                        : `${i.licensors.length} licensors`)
                      : 'No licensors' }
                </td>
                {/*<td>{i.trademark ? <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                <td onClick={e => openEditModal(i.licensors, i.id)} style={{ cursor: 'pointer' }}>{i.licensors && i.licensors.length > 0 ? <div style={{ display: 'flex', alignItems: 'center' }}>

                    { getTrademarkCell(i.licensors)}
                  </div> : ''}
                </td>
                <td>
                  <a target="_blank" href={i.url}>
                    <UrlTypeTooltip type={i.urlType} />
                  </a>
                </td>
                <td>
                  {
                    i.createdAt ?
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={(
                          <Tooltip id={`created-date-${i.id}`}>
                            <span>{i.createdAt.format('MMM DD, YYYY')}</span>
                          </Tooltip>
                        )}>
                        <span>{i.createdAt.fromNow()}</span>
                      </OverlayTrigger> : null
                  }
                </td>
                <td>
                  { i.linkCheckedAt ?
                    <OverlayTrigger
                      trigger="hover"
                      placement="top"
                      overlay={(
                        <Tooltip id={`checked-date-${i.id}`}>
                          <span>{i.linkCheckedAt.format('MMM DD, YYYY')}</span>
                        </Tooltip>
                      )}>
                      <span>{i.linkCheckedAt.fromNow()}</span>
                    </OverlayTrigger>
                    : 'Not Crawled'}
                </td>
              </>
            </SelectableRow>
          ))}

        </tbody>
      </table>
      <GatewayModal
        type={ModalType.Primary}
        shown={showEditModal}
        onClose={() => closeEditModal()}
        title={'Edit'}
        size={'large'}
      >
        <Modal.Body>
          <div className="row">
            <div className="col-lg-5" style={{ marginBottom: 10 }}>
              <strong>Licensor</strong>
            </div>
            <div className="col-lg-7" style={{ marginBottom: 10 }}>
              <strong>Trademarks</strong>
            </div>
          </div>
          {
            editModalLicensors && editModalLicensors.length > 0 ?
              editModalLicensors.map(l => (
                <LicensorEditRow licensor={l} updateLicensor={updateSelectedLicensor} updateTrademark={updateLicensorTrademark} deleteTrademark={deleteLicensorTrademark}/>
              ))
              : null
          }
          { showNewLicensorRow ?
            <LicensorEditRow licensor={null} updateLicensor={updateSelectedLicensor} updateTrademark={updateLicensorTrademark} deleteTrademark={deleteLicensorTrademark}/>
            : null}
          <div>
            <button onClick={() => addNewLicensorRow()} className="btn btn-primary">
              Add Licensor
            </button>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button onClick={() => setShowEditModal(false)} className="btn btn-default">
            Close
          </button>
        </Modal.Footer>
      </GatewayModal>
    </div>
  );

};
