import Axios from 'axios';
import { ErrorMessage, Field, Form, Formik, FormikActions, FormikValues } from 'formik';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import * as Yup from 'yup';
import { UserContext } from '../../../contexts';
import { FullContent } from '../../ContentFrame';

export const CreateKeyword = () => {
  const [saving, setSaving] = useState(false);
  const routeParams = useParams();
  const history = useHistory();
  const user = useContext(UserContext);

  function saveKeyword(values: FormikValues, formProps: FormikActions<FormikValues>) {
    if (values) {
      setSaving(true);

      const formData = {
        alias: values.alias,
        account_id: getAccountID(),
        is_regex: user.admin_type === '3' ? values.isRegex : false,
        is_excluded: values.isExcluded,
      };
      Axios.post('/api/keywords', formData)
        .then((response) => {
          if (user.type === 'admin') {
            history.push(getBackRoute());
          } else {
            history.push('/settings/keywords');
          }
          setSaving(false);
        })
        .catch((error) => {
          for (const index in error.response.data) {
            formProps.setFieldError(index, error.response.data[index][0]);
          }
          setSaving(false);
        });
    }
  }

  function getAccountID() {
    if (routeParams['vendorId']) {
      return routeParams['vendorId'];
    }
    if (routeParams['licensorId']) {
      return routeParams['licensorId'];
    }
    return '';
  }

  function getBackRoute() {
    if (routeParams['vendorId']) {
      return `/vendors/${getAccountID()}/settings/keywords`;
    }
    if (routeParams['licensorId']) {
      return `/clients/${getAccountID()}/settings/keywords`;
    }

    return '';
  }

  const initialValues = {
    alias:'',
    isRegex: false,
    isExcluded: false,
  };

  const validation = Yup.object({
    alias: Yup.string().min(1).required('A keyword is required'),
  });

  const submitButtonText = saving ? 'Adding Keyword...' : 'Submit';

  return (
    <FullContent>
      <div className="panel panel-portal">
        <div className="panel-body">
          <div className="col-xs-12 col-sm-8 col-sm-push-2">
            <div style={{ paddingBottom: 10 }}>
              <h3><strong>Add Keyword</strong></h3>
            </div>
              <Formik
                onSubmit={ (values, formProps) => saveKeyword(values, formProps)}
                validationSchema={validation}
                validateOnBlur={false}
                initialValues={initialValues}>
                {formProps =>
                  (
                    <div>
                      <Form id="keyword-form">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="alias">Keyword</label>
                            <Field placeholder="Keyword" name="alias" className="form-control"/>
                            <div className="checkbox">
                              <label>
                                <Field
                                  name="isRegex"
                                  type="checkbox"
                                /> Is Regex?
                              </label>
                            </div>
                          <div className="checkbox">
                            <label>
                              <Field
                                name="isExcluded"
                                type="checkbox"
                              /> Exluded Phrase?
                            </label>
                            </div>
                            <p className="text-danger">
                              <ErrorMessage name="alias" />
                            </p>
                          </div>
                        </div>
                        <div className="panel-footer text-right" style={{ display: 'flow-root', paddingLeft: 15, paddingRight: 15, paddingTop: 15 }}>
                          <button className="btn btn-primary" type="submit" disabled={saving}>{submitButtonText}</button>
                        </div>
                      </Form>
                    </div>
                  )}
              </Formik>
          </div>

        </div>
      </div>
    </FullContent>
  );

};
