import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as React from 'react';
import * as Yup from 'yup';

interface SitePageTextFields {
  body: string;
}

interface IProps {
  onSubmit: (values: string) => void;
  initialValues?: SitePageTextFields;
}

export const SitePageTextForm = (props: IProps) => {

  const initialValues = props.initialValues ? props.initialValues : {
    body: '',
  };
  const handleSubmit = (values: SitePageTextFields) => {
    props.onSubmit(JSON.stringify(values));
  };

  const validationSchema = Yup.object({
    body: Yup.string().required('Markdown is required.'),
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {props => (
        <Form>
          <div className="form-group">
            <label>Markdown</label>
            <Field component="textarea" placeholder="Markdown" name="body" className="form-control" />
            <p className="text-danger">
              <ErrorMessage name="body" />
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button className="btn btn-primary">Save Page Item</button>
          </div>
        </Form>
      )}
    </Formik>
  );

};
