import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Redirect, useHistory, useRouteMatch } from 'react-router';
import { NavLink, Switch } from 'react-router-dom';

export interface NavItemSection {
  title: string;
  links: {
    isBaseRoute: boolean;
    name: string;
    path: string;
    icon: IconProp;
  }[];
}

interface IProps {
  routes: JSX.Element[];
  navLinks: NavItemSection[];
  navTitle: string;
}

export const SubNavigation = (props: IProps) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [menuShown, setMenuShown] = React.useState(false);

  React.useEffect(() => {
    const histListener = history.listen((location, action) => setMenuShown(false));

    return () => {
      histListener();
    };

  },              []);
  if (!match) {
    return (<Redirect to="/" />);
  }

  const paddingBypass = `
    .main-content {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  `;
  const navLinks = props.navLinks.map((l, index) => {

    const title = l.title ? (<span className="secondary-nav-header text-muted">{l.title}</span>) : null;

    const subLinks = l.links.map((s) => {

      if (s.isBaseRoute) {
        return (
          <NavLink
            to={s.path}
            activeClassName="active"
            isActive={(path, location) => {
              return location.pathname === match.url;
            }}
          >
            <FontAwesomeIcon fixedWidth icon={s.icon} /> {s.name}
          </NavLink>
        );
      }
      return (
        <NavLink
          to={s.path}
          activeClassName="active"
        >
          <FontAwesomeIcon fixedWidth icon={s.icon} /> {s.name}
        </NavLink>
      );
    });

    return (
      <div className="secondary-nav-link-section">
        {title}
        {subLinks}
      </div>
    );
  });

  return (
    <div className="secondary-gateway-navigation-container">
      <style>
       {paddingBypass}
      </style>

      <div className={`secondary-gateway-navigation ${menuShown ? 'shown' : ''}`}>
        {navLinks}
      </div>
      <div className="secondary-gateway-navigation-content">
        <div className="secondary-gateway-navigation-menu visible-xs">
          <span className="nav-toggle" onClick={() => setMenuShown(!menuShown)} >
            <FontAwesomeIcon style={{ color: '#999', cursor: 'pointer' }} icon={faBars} />
          </span>
          <strong className="text-muted secondary-nav-title">
            {props.navTitle}
          </strong>
        </div>
        <Switch>
          {props.routes}

        </Switch>

      </div>
    </div>
  );
};
